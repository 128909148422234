import React from "react";
import ViewAdminCommunity from "../../components/pageComponents/adminDashboard/community/ViewAdminCommunity";
import AdminDashboardLayout from "./AdminDashboardLayout";

const AdminCommunity = () => {
  return (
    <AdminDashboardLayout>
      <ViewAdminCommunity />
    </AdminDashboardLayout>
  );
};

export default AdminCommunity;
