import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";

import SeoData from "../../utilities/seo/seoData.json";
import { addClientNotes } from "../../../AppRedux/actions/MentorMyClients";
import { FormatEditor } from "../../utilities/newFormInputs";
import MainHeader from "./MainHeader";
import {
  FormButton,
  Cliploader,
  successToast,
  ReactHelmet,
} from "../../utilities";

const AddNotesSection = ({ title }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [styledState, setStyledState] = useState(
    convertToRaw(editorState.getCurrentContent())
  );

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const id = params.getAll("id").toString();
  const [notesResponseInput, setNotesResponseInput] = useState("");
  const [formError, setFormError] = useState({});

  const { addClientNotesBegin, addClientNotesSuccessData } = useSelector(
    ({ mentorMyClients }) => mentorMyClients
  );

  useEffect(() => {
    setNotesResponseInput(editorState.getCurrentContent().getPlainText());
    setStyledState(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  useEffect(() => {
    if (addClientNotesSuccessData && addClientNotesSuccessData.success) {
      successToast("Note added successfully");
      navigate(`/mentor/dashboard?tab=notes&id=${id}`);
    }
  }, [addClientNotesSuccessData, id, navigate]);

  const customFormValidation = () => {
    const validationErrors = {};
    if (notesResponseInput?.trim() === "") {
      validationErrors.notesResponseInput = "This field is required";
    } else if (
      notesResponseInput?.trim() !== "" &&
      notesResponseInput.length < 4
    ) {
      validationErrors.notesResponseInput = "Please give your notes";
    }
    return validationErrors;
  };

  const handleOnResponseSubmit = (e) => {
    e.preventDefault();

    const validationErrors = customFormValidation();

    if (Object.keys(validationErrors).length === 0) {
      dispatch(addClientNotes(id, { note: styledState }));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <>
      <ReactHelmet data={SeoData.addNote} />
      <div className="bg-white rounded-10px p-4 min-h-80vh w-96%  mx-auto lg:w-1/2 lg:ml-18% mt-10 shadow-memberDashboard">
        <MainHeader title={title} />
        <div className="p-4">
          <h1 className="mt-14 font-bold text-2xl">Your Notes</h1>
          <form onSubmit={(e) => handleOnResponseSubmit(e)}>
            {/* Text Formatting  */}
            <div className="mb-0 mt-10">
              <FormatEditor
                editorState={editorState}
                setEditorState={setEditorState}
                errorMessage={formError?.notesResponseInput}
                setFormError={setFormError}
              />
            </div>
            <FormButton
              disabled={addClientNotesBegin}
              classNames={`bg-contact text-white text-xs sm:text-base w-1/4 mx-auto my-4`}
              style={{
                background:
                  "linear-gradient(204.67deg, #1A689C 9.43%, #2274AB 84.49%)",
              }}
            >
              Save
              {addClientNotesBegin && <Cliploader />}
            </FormButton>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddNotesSection;
