import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CliploaderBlue, ReactHelmet, Scaleloader } from "../../utilities";
import SeoData from "../../utilities/seo/seoData.json";
import {
  getClientAssessment,
  downloadAssessment,
} from "../../../AppRedux/actions/MentorMyClients";
import {
  DownloadAssessmentIcon,
  AccordionArrow,
  NoListToShow,
} from "../../utilities/svg";

const AssessmentTab = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const id = params.getAll("id").toString();
  const [clicked, setClicked] = useState(false);

  const {
    getClientAssessmentSuccessData,
    assessmentDownloadBegin,
    assessmentDownloadSuccessData,
    assessmentDownloadFailureData,
  } = useSelector(({ mentorMyClients }) => mentorMyClients);

  useEffect(() => {
    dispatch(getClientAssessment(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (assessmentDownloadSuccessData) {
      let anchor = document.createElement("a");
      let objectUrl = window.URL.createObjectURL(
        new Blob([assessmentDownloadSuccessData], { type: "application/pdf" })
      );
      anchor.href = objectUrl;
      anchor.download = "assessment.pdf";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    }
  }, [assessmentDownloadSuccessData, assessmentDownloadFailureData]);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <div className="bg-white text-brandDarkBlue p-4">
      <ReactHelmet data={SeoData.assessment} />
      <div
        className="flex justify-between items-center w-full p-2 rounded-t-7px "
        style={{ backgroundColor: "#FCF6ED" }}
      >
        <h1 className="pl-4 text-lg font-medium" style={{ color: "#013B5D" }}>
          Assessments
        </h1>
        <div
          className="bg-white flexCenter gap-4 p-2 dashboardListHover activeState"
          onClick={() => dispatch(downloadAssessment(id))}
        >
          <h1 className="font-bold">Download Asessments</h1>

          <DownloadAssessmentIcon
            className={`${assessmentDownloadBegin ? "animate-bounce" : ""}`}
          />
          {assessmentDownloadBegin && <CliploaderBlue />}
        </div>
      </div>

      <div className="member-table-row-items ">
        {getClientAssessmentSuccessData?.data.assessments ? (
          getClientAssessmentSuccessData?.data.assessments.length !== 0 ? (
            getClientAssessmentSuccessData?.data.assessments.map(
              ({ id, question, MemberAssessmentResponses }, index) => {
                return (
                  <div
                    key={id}
                    onClick={() => {
                      toggle(id);
                    }}
                    className={`dashboardListHover activeState member-table-row-item flex justify-between items-center text-base font-bold  ${
                      index ===
                      getClientAssessmentSuccessData?.data.assessments.length -
                        1
                        ? "border-none"
                        : ""
                    } ${clicked === id ? "bg-sky-50 py-2" : ""}`}
                  >
                    {clicked === id ? (
                      <div className="w-96% openeffect font-bold">
                        <h1 className="font-bold">{question}</h1>
                        <h1 className="my-2 font-bold">Response</h1>

                        {MemberAssessmentResponses.map(
                          ({ AssessmentQuestionOption }, index) => {
                            return (
                              <li key={index} className="list-disc font-bold">
                                {AssessmentQuestionOption.option}
                              </li>
                            );
                          }
                        )}
                      </div>
                    ) : (
                      <div className="w-7/12 oneLineContent closeeffect">
                        <p className="font-bold ">{question}</p>
                      </div>
                    )}
                    <AccordionArrow
                      className={`cursor-pointer ${
                        clicked === id ? "transform rotate-180 " : ""
                      }`}
                    />
                  </div>
                );
              }
            )
          ) : (
            <div className=" rounded-lg ">
              <p className="text-center text-brandBlue font-bold text-lg mt-10 py-6 ">
                Asessments have not yet been attempted
              </p>
              <NoListToShow className="w-1/2 mx-auto" />
            </div>
          )
        ) : (
          <Scaleloader />
        )}
      </div>
    </div>
  );
};

export default AssessmentTab;
