export const setupPasswordFormValidation = (passoword, confirmPassword) => {
  const validationErrors = {};
  if (passoword?.trim() === "") {
    validationErrors.password = "This field is required";
  }
  if (confirmPassword?.trim() === "") {
    validationErrors.confirmPassword = "This field is required";
  }

  if (
    passoword?.trim() !== "" &&
    confirmPassword?.trim() !== "" &&
    passoword !== confirmPassword
  ) {
    validationErrors.confirmPassword =
      "New Password and Confirm Password didn't match.";
  }
  if (
    passoword?.trim() !== "" &&
    confirmPassword?.trim() !== "" &&
    passoword === confirmPassword &&
    passoword.length < 8
  ) {
    validationErrors.confirmPassword =
      "* Your password must be at least 8 characters";
  }
  if (
    passoword?.trim() !== "" &&
    confirmPassword?.trim() !== "" &&
    passoword === confirmPassword &&
    passoword.length >= 8 &&
    passoword.search(/[a-z]/) < 0
  ) {
    validationErrors.confirmPassword =
      "Your password needs a lower case letter";
  }
  if (
    passoword?.trim() !== "" &&
    confirmPassword?.trim() !== "" &&
    passoword === confirmPassword &&
    passoword.length >= 8 &&
    passoword.search(/[a-z]/) >= 0 &&
    passoword.search(/[A-Z]/) < 0
  ) {
    validationErrors.confirmPassword =
      "Your password needs an upper case letter";
  }
  if (
    passoword?.trim() !== "" &&
    confirmPassword?.trim() !== "" &&
    passoword === confirmPassword &&
    passoword.length >= 8 &&
    passoword.search(/[a-z]/) >= 0 &&
    passoword.search(/[A-Z]/) >= 0 &&
    passoword.search(/[0-9]/) < 0
  ) {
    validationErrors.confirmPassword = "Your password needs a number";
  }
  if (
    passoword?.trim() !== "" &&
    confirmPassword?.trim() !== "" &&
    passoword === confirmPassword &&
    passoword.length >= 8 &&
    passoword.search(/[a-z]/) >= 0 &&
    passoword.search(/[A-Z]/) >= 0 &&
    passoword.search(/[0-9]/) >= 0 &&
    passoword.search(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/) < 0
  ) {
    validationErrors.confirmPassword = "Your password needs a symbol";
  }

  return validationErrors;
};

export const PasswordConditionMessage = () => (
  <ul className="messageContainer">
    <li>
      Password must contain at least 8 Characters, including Upper/Lowercase,
      Number and Symbol.
    </li>
  </ul>
);

export const handleInputOnchange = (e, formError, setFormError, setData) => {
  let sm = formError;
  delete sm[[e.target.name]];
  setFormError({ ...formError, ...sm });

  if (
    formError?.confirmPassword &&
    formError?.confirmPassword ===
      "New Password and Confirm Password didn't match."
  ) {
    setFormError({ ...formError, confirmPassword: "" });
  }

  setData((data) => ({
    ...data,
    [e.target.name]: e.target.value,
  }));
};
