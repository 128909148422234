import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import DayGridPlugin from "@fullcalendar/daygrid";
import InteractionPlugin from "@fullcalendar/interaction";
import moment from "moment-timezone";
import Cookies from 'universal-cookie';
import SeoData from "../../components/utilities/seo/seoData.json";
import MemberLeftSideProfileCard from "../../components/pageComponents/memberDashboard/MemberLeftSideProfileCard";
import MeetingBookingModal from "../../components/pageComponents/memberDashboard/MyCalendar/MeetingBookingModal";
import MeetingViewModal from "../../components/pageComponents/memberDashboard/MyCalendar/MeetingViewModal";
import data from "../../components/utilities/content/statusAndRoleManagement.json";
import MemberDashboardLayout from "./MemberDashboardLayout";
import {
  getMemberDashboardDataFromLocalStorage,
  ReactHelmet,
} from "../../components/utilities";
import {
  getMemberCalendarPreference,
  updateMemberMeetingStatus,
  viewMemberMeeting,
} from "../../AppRedux/actions/MemberCalendar";
import {
  functionAfterDateSet,
  DayCellContent,
  convertTimeTo12HoursFromat,
  CalendarEventContent,
  CalendarBackArrow,
  CalendarLoader,
  // utcTimeToLocalTime,
  localTime
} from "../../components/pageComponents/MyCalendarGeneralItems";

//Code spliting
// const FullCalendar = lazy(() => import("@fullcalendar/react"));

const Mycalendar = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    [showMeetBookingModal, setShowMeetBookingModal] = useState(false),
    [showMeetInformationModal, setShowMeetInformationModal] = useState(false),
    [selectedDayInfo, setSelectedDayInfo] = useState({}),
    [meetingInfo, setMeetingInfo] = useState(false),
    [memberPurchasedMinutes, setMemberPurchasedMinutes] = useState(),
    [calendarCurrentMonthAndYear, setCalendarCurrentMonthAndYear] = useState(),
    { mentor } = getMemberDashboardDataFromLocalStorage(),
    { getMemberCalendarPreferenceSuccessData, viewMemberMeetingSuccessData } =
      useSelector(({ memberCalendar }) => memberCalendar),
    { getMemberDashboardDetailsSuccessData } = useSelector(
      ({ memberDashboard }) => memberDashboard
    ),
    eventsDates = [
      ...new Set( //making unique array
        getMemberCalendarPreferenceSuccessData?.data?.map(({ date }) => date)
      ),
    ];


  useEffect(() => {
      const cookies = new Cookies(null, { path: '/' });
      if(cookies.get('geolocation')) {
        moment.tz.setDefault(cookies.get('geolocation'));
      }
  }, []);

  useEffect(() => {
    if (viewMemberMeetingSuccessData) {
      const meetings = viewMemberMeetingSuccessData.data;

      if (meetings.count !== 0) {
        const {
          id,
          meetingDate,
          toTime,
          fromTime,
          mentor,
          member,
          status,
          slotId,
        } = meetings.rows[0];

        const toLocalTime = localTime(toTime);
        const fromLocalTime = localTime(fromTime);

        const MeetingEndingTime = moment.utc(new Date(meetingDate))
          .hour(toLocalTime.split(":")[0])
          .minute(toLocalTime.split(":")[1]);
        const MeetingStartingTime = moment.utc(new Date(meetingDate))
          .hour(fromLocalTime.split(":")[0])
          .minute(fromLocalTime.split(":")[1]);


        var now = new Date();
        var durationToEndMeet = new Date(MeetingEndingTime) - now;
        if (durationToEndMeet < 0) {
          durationToEndMeet += 86400000;
        }


        setTimeout(function () {
          const updatedata = {
            status: data.MEETING.STATUS.COMPLETED,
          };
          dispatch(updateMemberMeetingStatus(id, updatedata));
          // setMeetingInfo(false);
        }, durationToEndMeet);

        // update meeting status to completed when meeting time is end
        console.log("MeetingEndingTime");
        console.log(moment(MeetingEndingTime).format("YYYY-MM-DD HH:mm:ss"));
        if (MeetingEndingTime.isBefore()) {
          const updatedata = {
            status: data.MEETING.STATUS.COMPLETED,
          };
          dispatch(updateMemberMeetingStatus(id, updatedata));
        } else {
          // setting upcoming meeting data
          const meetingData = [
            {
              id,
              toTime: toLocalTime,
              fromTime: fromLocalTime,
              meetingDate,
              status,
              slotId: slotId,
              userName: `${mentor.User.firstName} ${mentor.User.lastName}`,
              date: MeetingStartingTime.calendar(),
              duration: `${moment
                .duration(MeetingEndingTime.diff(MeetingStartingTime))
                .asMinutes()} mins`,
              mentorUid: mentor.User.uid,
              mentorProfilePhoto: mentor.User.profilePhoto,
              memberUid: member.uid,
              memberMinutes: memberPurchasedMinutes,
            },
          ];
          setMeetingInfo(meetingData);
        }
      } else {
        setMeetingInfo(false);
      }
    }
  }, [viewMemberMeetingSuccessData, dispatch, memberPurchasedMinutes]);

  useEffect(() => {
    // storing member minutes
    if (getMemberDashboardDetailsSuccessData) {
      setMemberPurchasedMinutes(
        getMemberDashboardDetailsSuccessData.data.member.minutes
      );
    }
  }, [getMemberDashboardDetailsSuccessData]);

  useEffect(() => {

    if (calendarCurrentMonthAndYear) {
      // if user changed curent month & year this will trigger
      dispatch(
        getMemberCalendarPreference(
          calendarCurrentMonthAndYear.month,
          calendarCurrentMonthAndYear.year,
          mentor.UserMentor.User.id
        )
      );
      dispatch(viewMemberMeeting(1, data.MEETING.STATUS.PENDING));
    }
  }, [calendarCurrentMonthAndYear, dispatch, mentor.UserMentor.User.id]);

  // current month mentor events

  const events = eventsDates.map((date) => {
    const eventDate = new Date(date).toISOString().split("T")[0];
    const nextMeetingDay =
      meetingInfo &&
      new Date(meetingInfo[0]?.meetingDate).toISOString().split("T")[0];

      // console.log("eventDate");
      // console.log(eventDate);
      // console.log("nextMeetingDay");
      // console.log( meetingInfo );

    if (nextMeetingDay && nextMeetingDay === eventDate) {
      return {
        title: meetingInfo[0].userName,
        date: nextMeetingDay,
        eventType: "meeting",
        color: "#2E94B6",
        extendedProps: {
          time: `${convertTimeTo12HoursFromat(
            (meetingInfo[0]?.fromTime)
          )} - ${convertTimeTo12HoursFromat(
            (meetingInfo[0]?.toTime)
          )}`,
        },
      };
    } else if (
      !moment(eventDate).isBefore() ||
      moment(eventDate).isSame(moment(), "day")
    ) {
      return {
        title: "Available",
        eventType: "slot_available",
        date: eventDate,
      };
    } else {
      return {};
    }
  });


  // adding not available card to days that have no events
  const addNotAvailableDays = () => {
    if (getMemberCalendarPreferenceSuccessData) {
      document.querySelectorAll(".fc-daygrid-day-events").forEach((event) => {
        if (event.firstChild.textContent !== "Available") {
          if (event.lastChild.textContent !== "Not Available") {
            if (
              //removing not available event from previous & next month box
              !event.parentElement.parentElement.classList.contains(
                "fc-day-disabled"
              )
            ) {
              //removing not available from meeting day
              if (event.firstChild.textContent === "") {
                event.style.backgroundColor = "#918c94";
                var node = document.createElement("p"); // Create a <p> node
                var textnode = document.createTextNode("Not Available"); // Create a text node
                node.appendChild(textnode);
                return event.appendChild(node);
              }
            } else {
              if (event.lastChild.textContent === "Not Available") {
                event.lastChild.remove(); // removing not avialble msg when mentor add timeslot to that day
              }
            }
          } else {
            if (
              event.parentElement.parentElement.classList.contains(
                "fc-day-disabled"
              )
            ) {
              event.style.backgroundColor = "#fff"; // removing not avilable msg from non-current month dates
              event.lastChild.remove();
            }
          }
        } else if (event.firstChild.textContent === "Available") {
          if (event.lastChild.textContent === "Not Available") {
            event.lastChild.remove(); // removing not avialble msg when mentor add timeslot to that day
          }
        }
      });
    }
  };

  const handleDayclick = (dayinfo) => {
    console.log("Day clicking");
    var today = new Date().toLocaleDateString("en-US").split("/");
    // creating date in YYYY-MM-DD format
    today = `${today[2]}-${today[0].length === 1 ? `0${today[0]}` : today[0]}-${today[1].length === 1 ? `0${today[1]}` : today[1]
      }`;

    // only for today and feature days
    // dayinfo.dateStr is the date when member click outside event container other is when member click inside event comtainer
    if (dayinfo.dateStr || dayinfo?.event?.startStr) {
      if (dayinfo.dateStr >= today || dayinfo?.event?.startStr >= today) {
        if (getMemberCalendarPreferenceSuccessData) {
          if (eventsDates.length !== 0) {
            eventsDates.forEach((eventDate, index) => {
              if (
                eventDate.split("T")[0] === dayinfo.dateStr ||
                eventDate.split("T")[0] === dayinfo?.event?.startStr
              ) {
                if (events[index].eventType === "meeting") {
                  setSelectedDayInfo(dayinfo);
                  setShowMeetInformationModal(true);
                } else if (events[index].eventType === "slot_available") {
                  setSelectedDayInfo(dayinfo);
                  setShowMeetBookingModal(true);
                }
                // return;
              }
              // else {
              //   alert("There is no events");
              //   return;
              // }
            });
          } else {
            // return alert("There is no events in this month");
          }
        }
      } else {
        // alert("past day");
      }
    }
  };

  return (
    <MemberDashboardLayout>
      <ReactHelmet data={SeoData.calendar} />
      <div className="flex gap-6 flex-col md:flex-row">
        <MemberLeftSideProfileCard />
        <div
          className={`overflow-x-scroll 850px:overflow-x-auto relative w-full rounded-xl h-auto bg-white p-6 animate-dashboardInitial  ${!getMemberCalendarPreferenceSuccessData && "opacity-80"
            }`}
        >
          <div>
            <CalendarBackArrow
              onClick={() =>
                navigate("/member/dashboard/?tab=my-path&cell=path")
              }
            />

            <FullCalendar
              plugins={[DayGridPlugin, InteractionPlugin]}
              headerToolbar={{ left: "", right: "prev,title,next" }}
              events={events}
              eventBackgroundColor="#71b48f"
              eventsSet={() => addNotAvailableDays()}
              eventContent={(event) => <CalendarEventContent event={event} />}
              datesSet={(dateInfo) =>
                functionAfterDateSet(dateInfo, setCalendarCurrentMonthAndYear)
              }
              selectable={true}
              dateClick={handleDayclick}
              eventClick={handleDayclick}
              showNonCurrentDates={false}
              fixedWeekCount={false}
              height="auto"
              dayCellContent={(dayInfo) => <DayCellContent dayInfo={dayInfo} />}
            />

            {!getMemberCalendarPreferenceSuccessData && ( //loader
              <CalendarLoader />
            )}
          </div>
        </div>
        {showMeetBookingModal && ( // modal when user click in days
          <MeetingBookingModal
            state={showMeetBookingModal}
            setState={setShowMeetBookingModal}
            dayInfo={selectedDayInfo}
            slotsData={getMemberCalendarPreferenceSuccessData}
            meetingBooked={meetingInfo}
            calendarCurrentMonthAndYear={calendarCurrentMonthAndYear}
            memberPurchasedMinutes={memberPurchasedMinutes}
          />
        )}
        {showMeetInformationModal && ( // modal when user click in meet days
          <MeetingViewModal
            dayInfo={selectedDayInfo}
            state={showMeetInformationModal}
            setState={setShowMeetInformationModal}
            meetingInfo={meetingInfo}
            setMeetingInfo={setMeetingInfo}
            calendarCurrentMonthAndYear={calendarCurrentMonthAndYear}
          />
        )}
      </div>
    </MemberDashboardLayout>
  );
};

export default Mycalendar;
