import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { convertFromRaw, EditorState, convertToRaw, Editor } from "draft-js";

import { url } from "../../utilities/generalItems";
import SeoData from "../../utilities/seo/seoData.json";
import MainHeader from "./MainHeader";
import {
  viewClientNotes,
  editClientNote,
  getClientExercise,
  viewClientTasks,
} from "../../../AppRedux/actions/MentorMyClients";
import {
  FormButton,
  successToast,
  Cliploader,
  Scaleloader,
  FormatEditor,
  ReactHelmet,
} from "../../utilities";

const MentorMyClientsResponsePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const paramid = params.getAll("id").toString();
  const id = params.getAll("noteid").toString();
  const questionId = params.getAll("questionId").toString();
  const tab = params.getAll("tab").toString();
  const action = params.getAll("action").toString();
  const mode = params.getAll("mode").toString();
  const pageNumber = params.getAll("page_no").toString();
  const [editClicked, setEditClicked] = useState(false);
  const [notesResponseInput, setNotesResponseInput] = useState({});
  const [validatenotesInput, setValidatenotesInput] = useState("");
  const [formError, setFormError] = useState({});

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [styledState, setStyledState] = useState(
    convertToRaw(editorState.getCurrentContent())
  );

  const {
    editClientNoteBegin,
    editClientNoteSuccessData,
    viewClientNotesBegin,
    viewClientNotesSuccessData,
    getClientExerciseSuccessData,
    viewClientTaskSuccessData,
  } = useSelector(({ mentorMyClients }) => mentorMyClients);

  useEffect(() => {
    setValidatenotesInput(editorState.getCurrentContent().getPlainText());
    setStyledState(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  useEffect(() => {
    if (action === "edit") {
      setEditClicked(true);
      dispatch(viewClientNotes(pageNumber, paramid));
    }
    if (action === "view-note") {
      setEditClicked(false);
      dispatch(viewClientNotes(pageNumber, paramid));
    }
    if (action === "view-exercise") {
      dispatch(getClientExercise(pageNumber, paramid));
    }
    if (action === "view-task") {
      dispatch(viewClientTasks(pageNumber, paramid));
    }
  }, [dispatch, paramid, action, pageNumber, mode]);

  useEffect(() => {
    if (viewClientNotesSuccessData) {
      setNotesResponseInput(
        viewClientNotesSuccessData?.data?.rows?.find(
          (value) => value.id === parseInt(id)
        )?.note
      );
      if (
        viewClientNotesSuccessData?.data &&
        (action === "edit" || action === "view-note") &&
        Object.keys(
          viewClientNotesSuccessData?.data?.rows?.find(
            (value) => value.id === parseInt(id)
          )?.note
        ).length === 2
      ) {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(
              viewClientNotesSuccessData?.data?.rows?.find(
                (value) => value.id === parseInt(id)
              )?.note
            )
          )
        );
      }
    }
  }, [
    viewClientNotesSuccessData,
    getClientExerciseSuccessData,
    id,
    setNotesResponseInput,
    notesResponseInput,
    setEditorState,
    action,
  ]);

  useEffect(() => {
    if (editClientNoteSuccessData && editClientNoteSuccessData?.success) {
      successToast("Note edited successfully");
      navigate(`/mentor/dashboard?tab=notes&id=${paramid}`);
    }
  }, [editClientNoteSuccessData, paramid, navigate]);

  const customFormValidation = () => {
    const validationErrors = {};
    if (validatenotesInput?.trim() === "") {
      validationErrors.validatenotesInput = "Response is required.";
    } else if (
      validatenotesInput?.trim() !== "" &&
      validatenotesInput.length < 4
    ) {
      validationErrors.validatenotesInput = "Please give a valid note";
    }
    return validationErrors;
  };

  const handleOnResponseSubmit = (e) => {
    e.preventDefault();
    const validationErrors = customFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      dispatch(editClientNote(id, { note: styledState }));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const cancelButtonClick = () => {
    navigate(-1);
  };

  const handleEditClicked = () => {
    setEditClicked(!editClicked);
    setParams({
      tab: tab,
      id: paramid,
      noteid: id,
      action: "edit",
      page_no: pageNumber,
    });
  };

  return (
    <div className="bg-white rounded-10px p-4 min-h-80vh w-5/6 mx-auto mt-6 shadow-memberDashboard">
      <ReactHelmet
        data={
          action === "view-note"
            ? SeoData.viewNote
            : action === "edit"
            ? SeoData.editNote
            : tab === "task"
            ? SeoData.viewTask
            : tab === "curriculum" && SeoData.viewExercise
        }
      />
      <MainHeader title="edit" />
      <div
        className={`${
          action === "edit" || action === "view-note"
            ? ""
            : action === "view-exercise"
            ? "grid grid-cols-1 "
            : "grid grid-cols-1 lg:grid-cols-2"
        } mt-5 sm:mt-10 gap-3 sm:gap-6 px-0 py-3 370px:py-0 370px:p-3 sm:p-6 min-h-90vh`}
      >
        <div
          className={`${
            action === "edit" || action === "view-note" ? "hidden" : ""
          }`}
        >
          <h6
            className="font-bold text-2xl mb-3 sm:mb-6"
            style={{ color: "#001C35" }}
          >
            Task
          </h6>

          <div className=" text-black mb-4 sm:mb-7">
            <h6 className="font-semibold text-lg">
              {tab === "curriculum" && getClientExerciseSuccessData ? (
                getClientExerciseSuccessData?.data.rows.find(
                  (value) => value.id === parseInt(questionId)
                )?.title
              ) : tab === "task" && viewClientTaskSuccessData ? (
                viewClientTaskSuccessData?.data.rows.find(
                  (value) => value.id === parseInt(questionId)
                )?.title
              ) : (
                <Scaleloader />
              )}
            </h6>
          </div>

          {tab === "curriculum" ? (
            <div className="font-medium text-base" style={{ color: "#181818" }}>
              {/* <p>
                {
                  getClientExerciseSuccessData?.data.rows.find(
                    (value) => value.id === parseInt(questionId)
                  )?.exercise
                }
              </p> */}
            </div>
          ) : tab === "task" && viewClientTaskSuccessData ? (
            <Editor
              editorState={EditorState.createWithContent(
                convertFromRaw(
                  viewClientTaskSuccessData?.data?.rows.find(
                    (value) => value.id === parseInt(questionId)
                  )?.description
                )
              )}
              readOnly={true}
            />
          ) : (
            ""
          )}
          {tab === "curriculum" || tab === "task" ? (
            <div>
              <h1 className="font-bold text-2xl mb-3 sm:mb-6 mt-10">
                Curriculum:{" "}
                <span>
                  {getClientExerciseSuccessData?.data.rows.find(
                    (value) => value.id === parseInt(questionId)
                  )?.emotional === true
                    ? "Emotional Inventory"
                    : getClientExerciseSuccessData?.data.rows.find(
                        (value) => value.id === parseInt(questionId)
                      )?.career === true
                    ? "Career Inventory"
                    : "Passion Project"}
                </span>
              </h1>

              {getClientExerciseSuccessData && tab === "curriculum" && (
                <>
                  <div className="w-full ">
                    <Editor
                      editorState={EditorState.createWithContent(
                        convertFromRaw(
                          getClientExerciseSuccessData?.data.rows.find(
                            (value) => value.id === parseInt(questionId)
                          )?.exercise
                        )
                      )}
                      readOnly={true}
                    />
                  </div>
                  <h1 className="font-semibold text-lg my-4">
                    Curriculum - Answers
                  </h1>
                  {getClientExerciseSuccessData?.data.rows
                    .find((value) => value.id === parseInt(questionId))
                    ?.questions?.map(({ question, answer }, index) => {
                      return (
                        <div key={index}>
                          <div className="overflow-hidden py-2 h-fit flex space-x-1">
                            <b>{index + 1}. </b>
                            <Editor
                              editorState={EditorState.createWithContent(
                                convertFromRaw(question)
                              )}
                              readOnly={true}
                            />
                          </div>
                          <h1 className="font-semibold text-lg my-2">
                            Response
                          </h1>
                          <div
                            className="p-2 h-fit mb-6"
                            style={{
                              border: "1px solid #B9B9BB",
                              borderRadius: "6px",
                            }}
                          >
                            <p> {answer?.answer}</p>
                          </div>
                          {answer?.attachment !== null &&
                            answer?.attachment !== "" && (
                              <div className="flex justify-end">
                                <a
                                  className="activeState dashboardHover border-2 border-brandBlue text-brandBlue px-2 animate-navbar rounded-xl text-sm"
                                  href={`${url}/file?path=${answer?.attachment}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View Attachment
                                </a>
                              </div>
                            )}
                        </div>
                      );
                    })}
                </>
              )}
              {getClientExerciseSuccessData?.data.rows
                .find((value) => value.id === parseInt(questionId))
                ?.tifQuestions[0]?.tifQuestions.map(
                  ({ question, answer }, index) => {
                    if (typeof answer[0] === "object") {
                      return (
                        <div>
                          <h1 className="font-semibold text-lg my-4">
                            Take it further - Answers
                          </h1>

                          <div className="overflow-hidden py-2 h-fit flex space-x-1">
                            <b>{index + 1}. </b>
                            <Editor
                              editorState={EditorState.createWithContent(
                                convertFromRaw(question)
                              )}
                              readOnly={true}
                            />
                          </div>
                          <h1 className="font-semibold text-lg my-2">
                            Response
                          </h1>
                          <div
                            className="p-2 h-fit mb-6"
                            style={{
                              border: "1px solid #B9B9BB",
                              borderRadius: "6px",
                            }}
                          >
                            <p> {answer[0]?.answer}</p>
                          </div>
                          {answer[0]?.attachment !== null &&
                            answer[0]?.attachment !== "" && (
                              <div className="flex justify-end">
                                <a
                                  className="activeState dashboardHover border-2 border-brandBlue text-brandBlue px-2 animate-navbar rounded-xl text-sm"
                                  href={`${url}/file?path=${answer[0]?.attachment}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View Attachment
                                </a>
                              </div>
                            )}
                        </div>
                      );
                    } else {
                      return "";
                    }
                  }
                )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className={` w-full  ${
            action === "edit" || action === "view-note"
              ? "950px:min-w-700px"
              : action === "view-exercise"
              ? "hidden "
              : " lg:pl-18%  lg:border-l"
          } min-h-full mb-7 `}
          style={{ borderColor: "#E9E9E9" }}
        >
          <div className=" flex justify-between items-center">
            <h1
              className="font-bold text-2xl mb-4"
              style={{ color: "#001C35" }}
            >
              {action === "edit" || action === "view-note"
                ? "My Note"
                : action === "view-task"
                ? "Response"
                : ""}
            </h1>
            {!viewClientNotesBegin && (
              <h1
                className={`${
                  action === "edit" || action === "view-note"
                    ? "text-base font-semibold cursor-pointer activeState"
                    : "hidden"
                }`}
                style={{ color: "#007CA5" }}
                onClick={() => handleEditClicked()}
              >
                {action === "view-note" && !editClicked && "Edit"}
              </h1>
            )}
          </div>
          {tab === "notes" ? (
            <div>
              {!viewClientNotesSuccessData ? (
                <p
                  className="font-medium text-base bg-faq rounded-10px shadow-memberDashboard p-6 xl:min-w-70%"
                  style={{ color: "#181818" }}
                >
                  <Scaleloader />
                </p>
              ) : (
                viewClientNotesSuccessData && (
                  <form onSubmit={(e) => handleOnResponseSubmit(e)}>
                    {!editClicked ? (
                      viewClientNotesSuccessData &&
                      Object.keys(notesResponseInput).length === 2 ? (
                        <div className="font-medium text-base bg-faq rounded-10px shadow-memberDashboard p-6 xl:min-w-70%">
                          <Editor
                            editorState={EditorState.createWithContent(
                              convertFromRaw(notesResponseInput)
                            )}
                            readOnly={true}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    ) : viewClientNotesSuccessData &&
                      Object.keys(notesResponseInput).length === 2 ? (
                      <div className="animate-navbar">
                        <FormatEditor
                          editorState={editorState}
                          setEditorState={setEditorState}
                          errorMessage={formError.validatenotesInput}
                          action={action}
                          setFormError={setFormError}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {editClicked && (
                      <div className="flex mt-10 gap-8 w-3/4 justify-start ml-auto ">
                        <FormButton
                          classNames={`bg-white text-brandBlue border border-brandBlue text-xs sm:text-base w-1/2`}
                          type="button"
                          onClick={() => cancelButtonClick()}
                        >
                          Cancel
                        </FormButton>
                        <FormButton
                          disabled={editClientNoteBegin}
                          classNames={`bg-contact text-white text-xs sm:text-base w-1/2`}
                          style={{
                            background:
                              "linear-gradient(204.67deg, #1A689C 9.43%, #2274AB 84.49%)",
                          }}
                        >
                          Save
                          {editClientNoteBegin && <Cliploader />}
                        </FormButton>
                      </div>
                    )}
                  </form>
                )
              )}
            </div>
          ) : tab === "task" ? (
            <p
              className=" font-medium text-base bg-faq rounded-10px shadow-memberDashboard p-6  lg:min-w-70%"
              style={{ color: "#181818" }}
            >
              {/* {tab === "exercise" ? (
                getClientExerciseSuccessData ? (
                  getClientExerciseSuccessData?.data.rows.find(
                    (value) => value.id === parseInt(questionId)
                  )?.CurriculumResponse.response
                ) : (
                  <Scaleloader />
                )
              ) : (
                ""
              )} */}

              {tab === "task" ? (
                viewClientTaskSuccessData ? (
                  viewClientTaskSuccessData?.data.rows.find(
                    (value) => value.id === parseInt(questionId)
                  )?.isResponded === false ? (
                    <h1 className=" text-base font-light text-gray-500">
                      Member has not yet responded to this task
                    </h1>
                  ) : (
                    viewClientTaskSuccessData?.data.rows.find(
                      (value) => value.id === parseInt(questionId)
                    )?.memberResponse
                  )
                ) : (
                  <Scaleloader />
                )
              ) : (
                ""
              )}
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorMyClientsResponsePage;
