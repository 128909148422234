import React, { useEffect, useRef, useState } from "react";

// Used for add click outside, closing functionality of modal
const ModalOuter = ({
  state,
  setState,
  children,
  style,
  classNames,
  blockOutsideClick,
}) => {
  const [clickedOutside, setClickedOutside] = useState(false),
    modalRef = useRef(),
    handleClickInside = () => setClickedOutside(false),
    handleClickOutside = (e) => {
      if (!modalRef.current?.contains(e.target)) {
        if (!blockOutsideClick) {
          setClickedOutside(true);
        }
      }
    };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (clickedOutside) {
      setState(false);
    }
  }, [clickedOutside, setState]);

  return (
    <>
      {state ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto " style={style}>
              {/*content*/}
              <div
                ref={modalRef}
                onClick={handleClickInside}
                className={`${classNames} text-black border-0 rounded-md relative flex flex-col w-full bg-white  outline-none focus:outline-none `}
                style={{
                  boxShadow: "0px 3.29846px 12.3692px rgba(0, 0, 0, 0.18)",
                }}
              >
                {children}
              </div>
            </div>
          </div>
          <div className="opacity-30 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalOuter;
