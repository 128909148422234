import { useSearchParams } from "react-router-dom";

import mentorPageData from "../../utilities/content/mentorDashboard.json";
import { scrollToTop } from "../../utilities";
import MainHeader from "./MainHeader";
import AssessmentTab from "./AssessmentTab";
import ExerciseTab from "./ExerciseTab";
import NotesTab from "./NotesTab";
import MentorSessionList from "./MentorSessionList";

const SubTabs = ({ selectedCell }) => {
  const [params, setParams] = useSearchParams();
  const id = params.getAll("id").toString();
  const pageNumber = params.getAll("page_no").toString();

  return (
    <div className="overflow-x-scroll 950px:overflow-x-auto bg-white flex-auto relative md:w-full rounded flex flex-col w-full">
      <div className="bg-white" style={{ minWidth: "600px" }}>
        <div className=" bg-white rounded-10px p-4 ">
          <MainHeader title="My Clients" />
        </div>
        <div className="bg-white rounded-tl-10px rounded-tr-10px mt-2 pt-4 px-4 w-full ">
          <div
            className={`flex justify-between items-center border-b border-solid border-faq pt-2`}
          >
            <div className="flex w-3/4 ">
              {mentorPageData.myClients.subNavbar.map(({ header }, index) => {
                return (
                  <div key={index}>
                    <p
                      className={`p-1 pb-2 rounded-sm font-medium blueText text-base xl:text-lg activeState mx-4 ${
                        selectedCell === header.toLowerCase()
                          ? " brandBlueText font-semibold "
                          : ""
                      }`}
                      key={index}
                      onClick={() =>
                        setParams({
                          tab: header.toLowerCase(),
                          id: id,
                          page_no: pageNumber,
                        })
                      }
                    >
                      {header}
                    </p>
                    {selectedCell === header.toLowerCase() && (
                      <div
                        className="h-1 xl:h-3px rounded-full"
                        style={{
                          width: "100%",
                          backgroundColor: "#007CA5",
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div>
              {selectedCell === "task" || selectedCell === "notes" ? (
                <button
                  className={`animate-navbar py-1 text-xs px-2 950px:px-4 activeState buttonHover xl:text-base bg-white brandBlueText border border-brandBlue`}
                  onClick={() => {
                    setParams({
                      tab: selectedCell,
                      id: id,
                      action:
                        selectedCell === "task" ? "assign-task" : "add-notes",
                    });
                    scrollToTop("50", "16.66");
                  }}
                >
                  {selectedCell === "task"
                    ? `Assign Task`
                    : selectedCell === "notes" && `Add Notes`}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* rendering tabs conditionally */}

        {selectedCell === "assessment" ? (
          <AssessmentTab />
        ) : selectedCell === "curriculum" ? (
          <ExerciseTab selectedCell={selectedCell} />
        ) : selectedCell === "task" ? (
          <ExerciseTab selectedCell={selectedCell} />
        ) : selectedCell === "notes" ? (
          <NotesTab />
        ) : (
          selectedCell === "session" && <MentorSessionList />
        )}
      </div>
    </div>
  );
};

export default SubTabs;
