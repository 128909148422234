import {
  UPDATE_MEMBER_PROFILE_DETAILS_BEGIN,
  UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS,
  UPDATE_MEMBER_PROFILE_DETAILS_FAILURE,
  RESET_UPDATE_MEMBER_PROFILE_DETAILS,
  GET_MEMBER_TRANSACTION_HISTORY_BEGIN,
  GET_MEMBER_TRANSACTION_HISTORY_SUCCESS,
  GET_MEMBER_TRANSACTION_HISTORY_FAILURE,
  RESET_GET_MEMBER_TRANSACTION_HISTORY,
  CANCEL_MEMBER_AUTO_RENEWAL_BEGIN,
  CANCEL_MEMBER_AUTO_RENEWAL_SUCCESS,
  CANCEL_MEMBER_AUTO_RENEWAL_FAILURE,
  RESET_CANCEL_MEMBER_AUTO_RENEWAL,
  CHECK_CURRENT_PASSWORD_BEGIN,
  CHECK_CURRENT_PASSWORD_SUCCESS,
  CHECK_CURRENT_PASSWORD_FAILURE,
  RESET_CHECK_CURRENT_PASSWORD,
  SET_NEW_PASSWORD_BEGIN,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  RESET_SET_NEW_PASSWORD,
} from "../constants/MemberProfile";

const initialState = {
  updateMemberProfileDetailsBegin: false,
  updateMemberProfileDetailsSuccess: false,
  updateMemberProfileDetailsSuccessData: null,
  updateMemberProfileDetailsFailure: false,
  updateMemberProfileDetailsFailureData: null,

  getMemberTransactionHistoryBegin: false,
  getMemberTransactionHistorySuccess: false,
  getMemberTransactionHistorySuccessData: null,
  getMemberTransactionHistoryFailure: false,
  getMemberTransactionHistoryFailureData: null,

  cancelMemberAutoRenewalBegin: false,
  cancelMemberAutoRenewalSuccess: false,
  cancelMemberAutoRenewalSuccessData: null,
  cancelMemberAutoRenewalFailure: false,
  cancelMemberAutoRenewalFailureData: null,

  checkCurrentPasswordBegin: false,
  checkCurrentPasswordSuccess: false,
  checkCurrentPasswordSuccessData: null,
  checkCurrentPasswordFailure: false,
  checkCurrentPasswordFailureData: null,

  setNewPasswordBegin: false,
  setNewPasswordSuccess: false,
  setNewPasswordSuccessData: null,
  setNewPasswordFailure: false,
  setNewPasswordFailureData: null,
};

export const memberProfile = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // update member profile information
    case UPDATE_MEMBER_PROFILE_DETAILS_BEGIN:
      return {
        ...state,
        updateMemberProfileDetailsBegin: true,
        updateMemberProfileDetailsSuccess: false,
        updateMemberProfileDetailsSuccessData: null,
        updateMemberProfileDetailsFailure: false,
        updateMemberProfileDetailsFailureData: null,
      };
    case UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        updateMemberProfileDetailsBegin: false,
        updateMemberProfileDetailsSuccess: true,
        updateMemberProfileDetailsSuccessData: data,
        updateMemberProfileDetailsFailure: false,
        updateMemberProfileDetailsFailureData: null,
      };
    case UPDATE_MEMBER_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        updateMemberProfileDetailsBegin: false,
        updateMemberProfileDetailsSuccess: false,
        updateMemberProfileDetailsSuccessData: null,
        updateMemberProfileDetailsFailure: true,
        updateMemberProfileDetailsFailureData: data,
      };
    case RESET_UPDATE_MEMBER_PROFILE_DETAILS:
      return {
        ...state,
        updateMemberProfileDetailsBegin: false,
        updateMemberProfileDetailsSuccess: false,
        updateMemberProfileDetailsSuccessData: null,
        updateMemberProfileDetailsFailure: false,
        updateMemberProfileDetailsFailureData: null,
      };

    // get member transaction history
    case GET_MEMBER_TRANSACTION_HISTORY_BEGIN:
      return {
        ...state,
        getMemberTransactionHistoryBegin: true,
        getMemberTransactionHistorySuccess: false,
        getMemberTransactionHistorySuccessData: null,
        getMemberTransactionHistoryFailure: false,
        getMemberTransactionHistoryFailureData: null,
      };

    case GET_MEMBER_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        getMemberTransactionHistoryBegin: false,
        getMemberTransactionHistorySuccess: true,
        getMemberTransactionHistorySuccessData: data,
        getMemberTransactionHistoryFailure: false,
        getMemberTransactionHistoryFailureData: null,
      };

    case GET_MEMBER_TRANSACTION_HISTORY_FAILURE:
      return {
        ...state,
        getMemberTransactionHistoryBegin: false,
        getMemberTransactionHistorySuccess: false,
        getMemberTransactionHistorySuccessData: null,
        getMemberTransactionHistoryFailure: true,
        getMemberTransactionHistoryFailureData: data,
      };

    case RESET_GET_MEMBER_TRANSACTION_HISTORY:
      return {
        ...state,
        getMemberTransactionHistoryBegin: false,
        getMemberTransactionHistorySuccess: false,
        // getMemberTransactionHistorySuccessData: null,
        getMemberTransactionHistoryFailure: false,
        getMemberTransactionHistoryFailureData: null,
      };

    //Cancel member auto renewal subscription
    case CANCEL_MEMBER_AUTO_RENEWAL_BEGIN:
      return {
        ...state,
        cancelMemberAutoRenewalBegin: true,
        cancelMemberAutoRenewalSuccess: false,
        cancelMemberAutoRenewalSuccessData: null,
        cancelMemberAutoRenewalFailure: false,
        cancelMemberAutoRenewalFailureData: null,
      };

    case CANCEL_MEMBER_AUTO_RENEWAL_SUCCESS:
      return {
        ...state,
        cancelMemberAutoRenewalBegin: false,
        cancelMemberAutoRenewalSuccess: true,
        cancelMemberAutoRenewalSuccessData: data,
        cancelMemberAutoRenewalFailure: false,
        cancelMemberAutoRenewalFailureData: null,
      };

    case CANCEL_MEMBER_AUTO_RENEWAL_FAILURE:
      return {
        ...state,
        cancelMemberAutoRenewalBegin: false,
        cancelMemberAutoRenewalSuccess: false,
        cancelMemberAutoRenewalSuccessData: null,
        cancelMemberAutoRenewalFailure: true,
        cancelMemberAutoRenewalFailureData: data,
      };

    case RESET_CANCEL_MEMBER_AUTO_RENEWAL:
      return {
        ...state,
        cancelMemberAutoRenewalBegin: false,
        cancelMemberAutoRenewalSuccess: false,
        cancelMemberAutoRenewalSuccessData: null,
        cancelMemberAutoRenewalFailure: false,
        cancelMemberAutoRenewalFailureData: null,
      };

    // check members existing password
    case CHECK_CURRENT_PASSWORD_BEGIN:
      return {
        ...state,
        checkCurrentPasswordBegin: true,
        checkCurrentPasswordSuccess: false,
        checkCurrentPasswordSuccessData: null,
        checkCurrentPasswordFailure: false,
        checkCurrentPasswordFailureData: null,
      };

    case CHECK_CURRENT_PASSWORD_SUCCESS:
      return {
        ...state,
        checkCurrentPasswordBegin: false,
        checkCurrentPasswordSuccess: true,
        checkCurrentPasswordSuccessData: data,
        checkCurrentPasswordFailure: false,
        checkCurrentPasswordFailureData: null,
      };

    case CHECK_CURRENT_PASSWORD_FAILURE:
      return {
        ...state,
        checkCurrentPasswordBegin: false,
        checkCurrentPasswordSuccess: false,
        checkCurrentPasswordSuccessData: null,
        checkCurrentPasswordFailure: true,
        checkCurrentPasswordFailureData: data,
      };

    case RESET_CHECK_CURRENT_PASSWORD:
      return {
        ...state,
        checkCurrentPasswordBegin: false,
        checkCurrentPasswordSuccess: false,
        checkCurrentPasswordSuccessData: null,
        checkCurrentPasswordFailure: false,
        checkCurrentPasswordFailureData: null,
      };

    // set up new password
    case SET_NEW_PASSWORD_BEGIN:
      return {
        ...state,
        setNewPasswordBegin: true,
        setNewPasswordSuccess: false,
        setNewPasswordSuccessData: null,
        setNewPasswordFailure: false,
        setNewPasswordFailureData: null,
      };

    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        setNewPasswordBegin: false,
        setNewPasswordSuccess: true,
        setNewPasswordSuccessData: data,
        setNewPasswordFailure: false,
        setNewPasswordFailureData: null,
      };

    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        setNewPasswordBegin: false,
        setNewPasswordSuccess: false,
        setNewPasswordSuccessData: null,
        setNewPasswordFailure: true,
        setNewPasswordFailureData: data,
      };

    case RESET_SET_NEW_PASSWORD:
      return {
        ...state,
        setNewPasswordBegin: false,
        setNewPasswordSuccess: false,
        setNewPasswordSuccessData: null,
        setNewPasswordFailure: false,
        setNewPasswordFailureData: null,
      };

    default:
      return state;
  }
};
