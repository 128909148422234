import React, { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import "rc-tooltip/assets/bootstrap_white.css";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { convertFromRaw, EditorState, Editor } from "draft-js";

import SeoData from "../../../components/utilities/seo/seoData.json";
import { ListHeader } from "../memberDashboard/tableComponents/ExercisesList";
import mentorPageData from "../../../components/utilities/content/mentorDashboard.json";
import OutsideClickCloseContainer from "../../utilities/OutsideClickCloseContainer";
import ConfirmModal from "../../utilities/modals/ConfirmModal";
import {
  Scaleloader,
  successToast,
  RcTooltip,
  ReactHelmet,
  scrollToTop,
} from "../../utilities";
import {
  PageNumbers,
  MoreIconContainer,
  NoListToShowContainer,
} from "../dashboardGeneralItems";
import {
  viewClientNotes,
  deleteClientNote,
} from "../../../AppRedux/actions/MentorMyClients";

const NotesTab = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const [notes, setNotes] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [clicked, setClicked] = useState(false);
  const paramid = params.getAll("id").toString();
  const headers = ["Note", "Date"];
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const {
    viewClientNotesBegin,
    viewClientNotesSuccessData,
    deleteClientNoteSuccessData,
  } = useSelector(({ mentorMyClients }) => mentorMyClients);

  useEffect(() => {
    if (viewClientNotesSuccessData) {
      setNotes(viewClientNotesSuccessData?.data.rows);
    }
  }, [viewClientNotesSuccessData, setNotes]);

  useEffect(() => {
    if (currentPage === 1) {
      dispatch(viewClientNotes(currentPage, paramid));
    }
  }, [dispatch, paramid, currentPage]);

  useEffect(() => {
    if (deleteClientNoteSuccessData) {
      successToast("Deleted Successfully");
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 2000);
      setShowConfirmDeleteModal(false);
      setNotes(notes.filter((singleNote) => singleNote.id !== clicked));
    }
  }, [deleteClientNoteSuccessData, notes, clicked]);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <section className="bg-white p-4 min-h-80vh relative pb-14">
      <ReactHelmet data={SeoData.notes} />
      <ListHeader
        headers={headers}
        gridClass="grid-cols-mentorMyClientsNotesHeader"
      />

      <div className="member-table-row-items border">
        {!viewClientNotesBegin ? (
          notes?.length !== 0 ? (
            notes?.map(({ id, note, createdAt }, index) => {
              const date = moment(new Date(createdAt)).format("MM-DD-YYYY");
              return (
                <div
                  key={index}
                  className={`dashboardListHover member-table-row-item text-sm 950px:text-base font-medium grid-cols-mentorMyClientsNotesList gap-1 ${
                    index === notes?.length - 1 ? "border-none" : ""
                  }`}
                  onClick={() => {
                    setParams({
                      tab: "notes",
                      id: paramid,
                      noteid: id,
                      action: "view-note",
                      page_no: currentPage,
                    });
                  }}
                >
                  <RcTooltip
                    content={
                      <Editor
                        editorState={EditorState.createWithContent(
                          convertFromRaw(note)
                        )}
                        readOnly={true}
                      />
                    }
                  >
                    <div className="oneLineContent w-9/12  ">
                      <Editor
                        editorState={EditorState.createWithContent(
                          convertFromRaw(note)
                        )}
                        readOnly={true}
                      />
                    </div>
                  </RcTooltip>

                  <div className="font-semibold">{date}</div>

                  <div className="relative">
                    <MoreIconContainer
                      onClick={(e) => {
                        e.stopPropagation(); //for prevent click function of it's parent
                        toggle(id);
                      }}
                      open={clicked === id}
                    />

                    {clicked === id && (
                      <OutsideClickCloseContainer setState={setClicked}>
                        <NotesListdropdown
                          noteid={id}
                          currentPage={currentPage}
                          clicked={clicked}
                          setClicked={setClicked}
                          showConfirmDeleteModal={showConfirmDeleteModal}
                          setShowConfirmDeleteModal={setShowConfirmDeleteModal}
                        />
                      </OutsideClickCloseContainer>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <NoListToShowContainer text="There are no notes assigned" />
          )
        ) : (
          <Scaleloader />
        )}
      </div>

      <PageNumbers
        count={viewClientNotesSuccessData?.data.count}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={viewClientNotesBegin}
        limit={10}
        onPageNumberClick={(number) => {
          dispatch(viewClientNotes(number, paramid));
        }}
      />
    </section>
  );
};

export default NotesTab;

const NotesListdropdown = ({
  noteid,
  currentPage,
  showConfirmDeleteModal,
  setShowConfirmDeleteModal,
}) => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const paramid = params.getAll("id").toString();

  const { deleteClientNoteBegin } = useSelector(
    ({ mentorMyClients }) => mentorMyClients
  );

  const deleteNoteModalData = {
    heading: "Are You Sure You Want To Delete ?",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      dispatch(deleteClientNote(noteid));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowConfirmDeleteModal(false);
    },
    buttonLoader: deleteClientNoteBegin,
  };

  const handleDropdownClick = (text) => {
    if (text === "Edit") {
      scrollToTop("50", "16.66");
      setParams({
        tab: "notes",
        id: paramid,
        noteid: noteid,
        action: "edit",
        page_no: currentPage,
      });
    } else {
      setShowConfirmDeleteModal(true);
    }
  };

  if (showConfirmDeleteModal) {
    return (
      <OutsideClickCloseContainer setState={setShowConfirmDeleteModal}>
        <ConfirmModal
          {...deleteNoteModalData}
          state={showConfirmDeleteModal}
          setState={setShowConfirmDeleteModal}
        />
      </OutsideClickCloseContainer>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`clientDropDownList py-4 `}
      style={{ minWidth: "150px" }}
    >
      {mentorPageData.myClients.notesTab.map(({ text }, index) => (
        <div
          key={index}
          className={`click text-sm font-normal px-5 py-1.5 cursor-pointer activeState dashboardHover flex justify-between`}
          onClick={(e) => {
            e.stopPropagation(); //for prevent click function of it's parent
            handleDropdownClick(text);
          }}
        >
          {text}
          {deleteClientNoteBegin}
        </div>
      ))}
    </div>
  );
};
