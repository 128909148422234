import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { utcTimeToLocalTime } from "../MyCalendarGeneralItems";
import SeoData from "../../utilities/seo/seoData.json";
import data from "../../utilities/content/statusAndRoleManagement.json";
import { viewMentorMeeting } from "../../../AppRedux/actions/MentorCalendar";
import { Scaleloader, ReactHelmet } from "../../utilities";
import { NoListToShowContainer, PageNumbers } from "../dashboardGeneralItems";
import { useSearchParams } from "react-router-dom";
import { updateMemberMeetingStatus } from "../../../AppRedux/actions/MemberCalendar";
import {
  SessionHeader,
  SessionList,
} from "../memberDashboard/tableComponents/SessionsList";

const MentorSessionList = () => {
  const dispatch = useDispatch();
  const [startSessionButton, setStartSessionButton] = useState(false);
  const [upcomingMeeting, setUpcomingMeeting] = useState(false);
  const [completedMeeting, setCompletedMeeting] = useState(false);
  const [completedMeetingCount, setCompletedMeetingCount] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [params] = useSearchParams();
  const id = params.getAll("id").toString();

  const { viewMentorMeetingSuccessData, viewMentorMeetingBegin } = useSelector(
    ({ mentorCalendar }) => mentorCalendar
  );
  const memberNextMeeting = viewMentorMeetingSuccessData?.data?.rows;

  useEffect(() => {
    dispatch(viewMentorMeeting(1, data.MEETING.STATUS.PENDING, id));
    dispatch(viewMentorMeeting(1, data.MEETING.STATUS.COMPLETED, id));
  }, [dispatch, id]);

  useEffect(() => {
    if (memberNextMeeting?.length > 0) {
      if (memberNextMeeting[0].status === data.MEETING.STATUS.PENDING) {
        const { id, meetingDate, fromTime, toTime } = memberNextMeeting[0];

        const toLocalTime = utcTimeToLocalTime(toTime);
        const fromLocalTime = utcTimeToLocalTime(fromTime);
        const MeetingStartingTime = moment(new Date(meetingDate))
          .hour(fromLocalTime.split(":")[0])
          .minute(fromLocalTime.split(":")[1]);
        const MeetingEndingTime = moment(new Date(meetingDate))
          .hour(toLocalTime.split(":")[0])
          .minute(toLocalTime.split(":")[1]);

        if (MeetingStartingTime.isBefore() && MeetingEndingTime.isAfter()) {
          setStartSessionButton(true);
        }

        // update meeting status to completed when meeting time is end
        if (MeetingEndingTime.isBefore()) {
          const updatedata = {
            status: data.MEETING.STATUS.COMPLETED,
          };
          dispatch(updateMemberMeetingStatus(id, updatedata));
          setUpcomingMeeting(false);
        }
      }
    }
  }, [dispatch, memberNextMeeting]);

  if (memberNextMeeting?.length > 0) {
    if (memberNextMeeting[0].status === data.MEETING.STATUS.PENDING) {
      const { id, meetingDate, fromTime, toTime } = memberNextMeeting[0];

      const toLocalTime = utcTimeToLocalTime(toTime);
      const fromLocalTime = utcTimeToLocalTime(fromTime);

      const MeetingStartingTime = moment(new Date(meetingDate))
        .hour(fromLocalTime.split(":")[0])
        .minute(fromLocalTime.split(":")[1]);
      const MeetingEndingTime = moment(new Date(meetingDate))
        .hour(toLocalTime.split(":")[0])
        .minute(toLocalTime.split(":")[1]);

      var now = new Date();
      var durationToStartMeet = new Date(MeetingStartingTime) - now;
      var durationToEndMeet = new Date(MeetingEndingTime) - now;

      if (durationToStartMeet < 0) {
        durationToStartMeet += 86400000;
      }
      if (durationToEndMeet < 0) {
        durationToEndMeet += 86400000;
      }

      setTimeout(function () {
        setStartSessionButton(true);
      }, durationToStartMeet);
      setTimeout(function () {
        const updatedata = {
          status: data.MEETING.STATUS.COMPLETED,
        };
        dispatch(updateMemberMeetingStatus(id, updatedata));
        setUpcomingMeeting(false);
      }, durationToEndMeet);
    }
  }

  useEffect(() => {
    if (memberNextMeeting?.length > 0) {
      if (memberNextMeeting[0].status === data.MEETING.STATUS.PENDING) {
        // setting upcoming meeting data

        const { id, member, mentor, meetingDate, fromTime, toTime, status } =
          memberNextMeeting[0];
        const toLocalTime = utcTimeToLocalTime(toTime);
        const fromLocalTime = utcTimeToLocalTime(fromTime);
        const MeetingStartingTime = moment(new Date(meetingDate))
          .hour(fromLocalTime.split(":")[0])
          .minute(fromLocalTime.split(":")[1]);
        const MeetingEndingingTime = moment(new Date(meetingDate))
          .hour(toLocalTime.split(":")[0])
          .minute(toLocalTime.split(":")[1]);

        const meetingData = [
          {
            id,
            toTime: toLocalTime,
            meetingDate,
            status,
            userName: `${member.firstName} ${member.lastName}`,
            date: MeetingStartingTime.calendar(),
            duration: `${moment
              .duration(MeetingEndingingTime.diff(MeetingStartingTime))
              .asMinutes()} mins`,
            mentorUid: mentor.User.uid,
            memberUid: member.uid,
          },
        ];
        setUpcomingMeeting(meetingData);
      } else {
        // setting completed meeting data
        const completedMeetingData = memberNextMeeting.map(
          ({ member, meetingDate, fromTime, toTime }) => {
            const toLocalTime = utcTimeToLocalTime(toTime);
            const fromLocalTime = utcTimeToLocalTime(fromTime);

            const MeetingStartingTime = moment(new Date(meetingDate))
              .hour(fromLocalTime.split(":")[0])
              .minute(fromLocalTime.split(":")[1]);
            const MeetingEndingingTime = moment(new Date(meetingDate))
              .hour(toLocalTime.split(":")[0])
              .minute(toLocalTime.split(":")[1]);

            return {
              userName: `${member.firstName} ${member.lastName}`,
              date: MeetingStartingTime.calendar(),
              duration: `${moment
                .duration(MeetingEndingingTime.diff(MeetingStartingTime))
                .asMinutes()} mins`,
            };
          }
        );
        setCompletedMeeting(completedMeetingData);
        setCompletedMeetingCount(viewMentorMeetingSuccessData?.data?.count);
      }
    }
  }, [memberNextMeeting, viewMentorMeetingSuccessData]);

  return (
    <div className=" px-4 min-h-80vh relative pb-14">
      <ReactHelmet data={SeoData.sessions} />
      {currentPage === 1 && (
        // Upcoming sessions
        <>
          <SessionHeader
            title="Upcoming sessions"
            headers={headers}
            startSessionButtons={startSessionButton}
          />
          {viewMentorMeetingBegin ? (
            <Scaleloader />
          ) : upcomingMeeting ? (
            <SessionList
              sessions={upcomingMeeting}
              startSessionButtons={startSessionButton}
              upcomingMeeting={upcomingMeeting}
              userType="mentor"
            />
          ) : (
            <NoListToShowContainer text="No upcoming meeting" small={true} />
          )}
        </>
      )}

      {/* completed sessions */}
      <SessionHeader title="Completed sessions" headers={headers} />
      {viewMentorMeetingBegin ? (
        <Scaleloader />
      ) : completedMeeting ? (
        <SessionList sessions={completedMeeting} />
      ) : (
        <NoListToShowContainer text="No completed meetings" small={true} />
      )}
      <PageNumbers
        count={completedMeetingCount}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={viewMentorMeetingBegin}
        limit={8}
        onPageNumberClick={(number) => {
          dispatch(viewMentorMeeting(number, 1, id));
        }}
      />
    </div>
  );
};

export default MentorSessionList;

const headers = ["Member", "Date ", "Duration", ""];
