import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CometChat } from "@cometchat-pro/chat";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import { utcTimeToLocalTime } from "../../../components/pageComponents/MyCalendarGeneralItems";
import SeoData from "../../../components/utilities/seo/seoData.json";
import pageData from "../../../components/utilities/content/pages.json";
import data from "../../../components/utilities/content/statusAndRoleManagement.json";
import { updateMemberMeetingStatus } from "../../../AppRedux/actions/MemberCalendar";
import SuccessModal from "../../../components/utilities/modals/SuccessModal";
import ConfirmModal from "../../../components/utilities/modals/ConfirmModal";
import { cometChatInitialising } from ".";
import RatingModal from "../../../components/pageComponents/memberDashboard/MyCalendar/RatingModal";
import {
  alertUserForReload,
  deafultToast,
  ReactHelmet,
} from "../../../components/utilities";

const MeetingVideo = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showMeetingStoppedModal, setshowMeetingStoppedModal] = useState(false);
  const [showMentorRatingModal, setShowMentorRatingModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const { getAppResourcesSuccessData } = useSelector(
    ({ homeReducer }) => homeReducer
  );

  const meetingEndConfirmData = {
    heading: "Do you want to leave Meeting ?",
    buttonText: "Rejoin",
    confirmClick: () => {
      setButtonLoader(true);
      cometChatInitialising(getAppResourcesSuccessData?.cometAppId);
      if (state?.userType === "member") {
        loginToCometChat(
          state?.meetingData[0]?.userName,
          state?.meetingData[0].memberUid
        );
      } else if (state?.userType === "mentor") {
        loginToCometChat(
          state?.meetingData[0]?.userName,
          state?.meetingData[0].mentorUid
        );
      }
    },
    cancelButtonText: "Yes",
    cancelButtonClick: () => {
      if (state?.userType === "member") {
        if (state?.meetingData[0].memberMinutes === 0) {
          setShowMentorRatingModal(true);
        } else {
          navigate("/member/dashboard");
        }
      } else if (state?.userType === "mentor") {
        window.CometChatWidget.logout().then(() => {
          document.getElementById("cometchat__widget")?.remove();
        });
        navigate("/mentor/dashboard");
      }
    },
    buttonLoader: buttonLoader,
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUserForReload);
    return () => {
      window.removeEventListener("beforeunload", alertUserForReload);
    };
  }, []);

  useEffect(() => {
    setshowMeetingStoppedModal(state?.callEnded);
  }, [state]);

  useEffect(() => {
    const refresh = async () => {
      await CometChat.getLoggedinUser().then(
        (user) => {
          console.log("user details:", { user });
        },
        () => {
          if (state?.userType === "member") {
            navigate("/member/dashboard?tab=my-path&cell=sessions");
          } else if (state?.userType === "mentor") {
            navigate("/mentor/dashboard");
          }
        }
      );
    };

    if (!state) {
      navigate("/member/dashboard?tab=my-path&cell=sessions");
    }
    refresh();
  }, [navigate, state]);

  useEffect(() => {
    if (state) {
      const { meetingData } = state;
      if (meetingData?.length > 0) {
        if (meetingData[0].status === data.MEETING.STATUS.PENDING) {
          const { id, meetingDate, toTime } = meetingData[0];
          const toLocalTime = utcTimeToLocalTime(toTime);
          console.log(toLocalTime);
          const MeetingEndingTime = moment(new Date(meetingDate))
            .hour(toLocalTime.split(":")[0])
            .minute(toLocalTime.split(":")[1]);

          var now = new Date();
          var durationToEndMeet = new Date(MeetingEndingTime) - now;
          var durationToLastFiveMinute =
            new Date(MeetingEndingTime.subtract(moment.duration({ m: 5 }))) -
            now;

          if (durationToEndMeet < 0) {
            durationToEndMeet += 86400000;
          }
          if (durationToLastFiveMinute < 0) {
            durationToLastFiveMinute += 86400000;
          }

          setTimeout(() => {
            deafultToast("This call will end with in 5 minutes");
          }, durationToLastFiveMinute);
          setTimeout(() => {
            toast.clearWaitingQueue();
          }, 2000);

          setTimeout(function () {
            let sessionID = `${meetingData[0].mentorUid}-${meetingData[0].memberUid}`;

            CometChat.endCall(sessionID).then(
              () => {
                const updatedata = {
                  status: data.MEETING.STATUS.COMPLETED,
                };
                dispatch(updateMemberMeetingStatus(id, updatedata));
              },
              (error) => {
                console.log("error", error);
              }
            );
          }, durationToEndMeet);
        }
      }
    }
  }, [state, dispatch]);

  // Login to comet chat FOR REJOIN
  const loginToCometChat = async (username, UID) => {
    const API_KEY = "13f5bc6ef89bb9725ec04558defd0bb00343fcbf";
    var user = new CometChat.User(UID);
    user.setName(username);
    const { userType, meetingData } = state;
    if (username) {
      CometChat.login(UID, API_KEY).then(
        () => {
          if (userType === "member") {
            navigate("/member/dashboard/meeting", {
              state: { meetingData, userType },
            });
          } else if (userType === "mentor") {
            navigate("/mentor/dashboard/meeting", {
              state: { meetingData, userType },
            });
          }
          startCall();
          setButtonLoader(false);
        },
        (error) => {
          console.log("Login failed with exception:", { error });
          setButtonLoader(false);
        }
      );
    } else {
      if (userType === "member") {
        navigate("/member/dashboard/meeting", {
          state: { meetingData, userType },
        });
      } else if (userType === "mentor") {
        navigate("/mentor/dashboard/meeting", {
          state: { meetingData, userType },
        });
      }
      startCall();
    }
  };

  // start call FOR REJOIN
  const startCall = () => {
    const { userType, meetingData } = state;
    let sessionID = `${meetingData[0].mentorUid}-${meetingData[0].memberUid}`;
    let audioOnly = false;
    let defaultLayout = true;
    setButtonLoader(false);

    let callSettings = new CometChat.CallSettingsBuilder()
      .enableDefaultLayout(defaultLayout)
      .setSessionID(sessionID)
      .setIsAudioOnlyCall(audioOnly)
      .startWithVideoMuted(true)
      .build();

    CometChat.startCall(
      callSettings,
      document.getElementById("callScreen"),
      new CometChat.OngoingCallListener({
        onCallEnded: () => {
          const { meetingDate, toTime } = meetingData[0];
          const MeetingEndingTime = moment(new Date(meetingDate))
            .hour(toTime.split(":")[0])
            .minute(toTime.split(":")[1]);

          if (MeetingEndingTime.isAfter()) {
            if (userType === "member") {
              navigate("/member/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callEnded: true,
                },
              });
            } else if (userType === "mentor") {
              navigate("/mentor/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callEnded: true,
                },
              });
            }
          } else {
            if (userType === "member") {
              navigate("/member/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callTimeEnded: true,
                },
              });
            } else if (userType === "mentor") {
              navigate("/mentor/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callTimeEnded: true,
                },
              });
            }
          }
        },
      })
    );
  };

  if (showMentorRatingModal) {
    return <RatingModal state={true} />;
  }

  if (state?.callEnded) {
    return (
      <ConfirmModal
        {...meetingEndConfirmData}
        state={showMeetingStoppedModal}
        setState={setshowMeetingStoppedModal}
        blockOutsideClick={true}
      />
    );
  }

  if (state?.callTimeEnded) {
    return (
      <SuccessModal
        content={
          state?.userType === "member"
            ? pageData?.memberCometChat.meetingEndModalContent
            : pageData?.mentorCometChat.meetingEndModalContent
        }
        btnOnClick={
          state?.userType === "member"
            ? state?.meetingData[0].memberMinutes === 0 &&
              setShowMentorRatingModal(true)
            : false
        }
      />
    );
  }

  return (
    <div  id="callScreen">
      <ReactHelmet data={SeoData.memberMeeting} />
    </div>
  );
};

export default MeetingVideo;
