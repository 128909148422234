import {
  GET_MANAGER_DETAILS_BEGIN,
  GET_MANAGER_DETAILS_SUCCESS,
  GET_MANAGER_DETAILS_FAILURE,
  RESET_GET_MANAGER_DETAILS,
  EDIT_MANAGER_NAME_BEGIN,
  EDIT_MANAGER_NAME_SUCCESS,
  EDIT_MANAGER_NAME_FAILURE,
  RESET_EDIT_MANAGER_NAME,
  GET_MANAGER_DASHBOARD_BEGIN,
  GET_MANAGER_DASHBOARD_SUCCESS,
  GET_MANAGER_DASHBOARD_FAILURE,
  RESET_GET_MANAGER_DASHBOARD,
  GET_MANAGER_DETAILS_BYID_BEGIN,
  GET_MANAGER_DETAILS_BYID_SUCCESS,
  GET_MANAGER_DETAILS_BYID_FAILURE,
  RESET_GET_MANAGER_DETAILS_BYID,
} from "../constants/AdminManager";

import { httpApp } from "../../axios/httpUser";

//get manager details
export function getManagerDetails() {
  return (dispatch) => {
    dispatch({
      type: GET_MANAGER_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/admin/manager/`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MANAGER_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MANAGER_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MANAGER_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MANAGER_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//edit manager
export function updateManagerDetails(data, id) {
  return (dispatch) => {
    dispatch({
      type: EDIT_MANAGER_NAME_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.patch(`/admin/manager/${id}`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_MANAGER_NAME_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_MANAGER_NAME,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_MANAGER_NAME_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_EDIT_MANAGER_NAME,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get manager details for manager dashboard
export function getManagerDashboard() {
  return (dispatch) => {
    dispatch({
      type: GET_MANAGER_DASHBOARD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/manager/dashboard`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MANAGER_DASHBOARD_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MANAGER_DASHBOARD,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MANAGER_DASHBOARD_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MANAGER_DASHBOARD,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function getManagerdetailsById(id) {
  return (dispatch) => {
    dispatch({
      type: GET_MANAGER_DETAILS_BYID_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/manager/getMemberList/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MANAGER_DETAILS_BYID_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MANAGER_DETAILS_BYID,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MANAGER_DETAILS_BYID_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MANAGER_DETAILS_BYID,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
