import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";

import { addClientTask } from "../../../AppRedux/actions/MentorMyClients";
import MainHeader from "./MainHeader";
import { FormatEditor } from "../../utilities/newFormInputs";
import {
  FormButton,
  InputGroup,
  Cliploader,
  successToast,
} from "../../utilities";

const AddTasksSection = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const id = params.getAll("id").toString();
  const tab = params.getAll("tab").toString();
  const [addTaskData, setAddTaskData] = useState({
    exercise_title: "",
  });
  const [description, setDescription] = useState("");
  const [formError, setFormError] = useState({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [styledState, setStyledState] = useState(
    convertToRaw(editorState.getCurrentContent())
  );

  const { addClientTaskBegin, addClientTaskSuccessData } = useSelector(
    ({ mentorMyClients }) => mentorMyClients
  );

  useEffect(() => {
    setDescription(editorState.getCurrentContent().getPlainText());
    setStyledState(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  useEffect(() => {
    if (addClientTaskSuccessData && addClientTaskSuccessData.success) {
      successToast("Task added successfully");
      navigate(`/mentor/dashboard?tab=task&id=${id}`);
    }
  }, [addClientTaskSuccessData, id, navigate]);

  const customFormValidation = () => {
    const { exercise_title } = addTaskData;

    const validationErrors = {};
    if (exercise_title?.trim() === "") {
      validationErrors.exercise_title = "Task title is required";
    }
    if (description?.trim() === "") {
      validationErrors.description = "Task description is required";
    }
    if (exercise_title?.trim() !== "" && exercise_title.length < 3) {
      validationErrors.exercise_title = "Please give a valid task title";
    }
    if (description?.trim() !== "" && description.length < 3) {
      validationErrors.description = "Please give a valid task description";
    }
    return validationErrors;
  };

  const handleOnResponseSubmit = (e) => {
    e.preventDefault();
    const validationErrors = customFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      dispatch(
        addClientTask(id, {
          title: addTaskData.exercise_title,
          description: styledState,
        })
      );
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const handleInputOnchange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z]/gi, "");
    }

    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setAddTaskData({
      ...addTaskData,
      [e.target.name]: value,
    });
  };

  return (
    <div className="py-10 bg-dashboardArrow">
      <div className=" bg-white rounded-10px p-4 min-h-80vh w-full sm:w-70% mx-auto 950px:w-1/2 950px:ml-18% shadow-memberDashboard">
        <MainHeader title={title} />
        <div className="p-4">
          <h1 className="mt-14 font-bold text-2xl mb-5">Create Exercise</h1>
          <form onSubmit={(e) => handleOnResponseSubmit(e)}>
            <InputGroup
              label="Enter Title"
              name="exercise_title"
              type="text"
              onChange={handleInputOnchange}
              value={addTaskData?.exercise_title}
              errorMessage={formError?.exercise_title}
            />

            <p className="text-base font-semibold text-deleteIcon mt-10 mb-2">
              Enter description
            </p>

            {/* Text Formatting  */}
            <div style={{ minHeight: "300px" }}>
              <FormatEditor
                tab={tab}
                editorState={editorState}
                setEditorState={setEditorState}
                errorMessage={formError?.description}
                setFormError={setFormError}
              />
            </div>
            <FormButton
              disabled={addClientTaskBegin}
              classNames={`bg-contact text-white text-xs sm:text-base w-1/4 mx-auto my-4`}
              style={{
                background:
                  "linear-gradient(204.67deg, #1A689C 9.43%, #2274AB 84.49%)",
              }}
            >
              Submit
              {addClientTaskBegin && <Cliploader />}
            </FormButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTasksSection;
