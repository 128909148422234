import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../../../utilities/styles/successAnimation.css";
import SeoData from "../../../utilities/seo/seoData.json";
import { 
  // ManagePasswordIcon
  AnimatedSuccess } from "../../../utilities/svg";
import {
  checkCurrentPassword,
  setUpNewPassword,
} from "../../../../AppRedux/actions/MemberProfile";
import {
  handleInputOnchange,
  PasswordConditionMessage,
  setupPasswordFormValidation,
} from "../../../utilities/SetUpPasswordForm";
import {
  InputGroupEye,
  FormButton,
  errorToast,
  Cliploader,
  alertUserForReload,
  scrollToTop,
  ReactHelmet,
} from "../../../utilities";

const ManagePasswordTab = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [memberCurrentPassword, setMemberCurrentPassword] = useState("");
  const [formError, setFormError] = useState({});
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [memberNewPasswordDetails, setMemberNewPasswordDetails] = useState({
    password: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = memberNewPasswordDetails;
  const passwordInputRef = useRef();
  const {
    checkCurrentPasswordBegin,
    checkCurrentPasswordSuccessData,
    setNewPasswordSuccessData,
    checkCurrentPasswordFailureData,
  } = useSelector(({ memberProfile }) => memberProfile);

  useEffect(() => {
    if (setNewPasswordSuccessData) {
      setShowAnimation(true);
      scrollToTop("50", "16.66");
    }
    if (checkCurrentPasswordSuccessData) {
      setShowNewPasswordField(true);
      scrollToTop("50", "16.66");
    }
    if (!showNewPasswordField && checkCurrentPasswordFailureData) {
      errorToast("The current password is not correct");
    }
  }, [
    showNewPasswordField,
    checkCurrentPasswordSuccessData,
    checkCurrentPasswordFailureData,
    setNewPasswordSuccessData,
  ]);

  useEffect(() => {
    passwordInputRef.current?.focus();
  }, [showNewPasswordField]);

  useEffect(() => {
    if (showNewPasswordField) {
      window.addEventListener("beforeunload", alertUserForReload);
    }
    return () => {
      if (showNewPasswordField) {
        window.removeEventListener("beforeunload", alertUserForReload);
      }
    };
  }, [showNewPasswordField]);

  useEffect(() => {
    if (showNewPasswordField && checkCurrentPasswordSuccessData) {
      errorToast("Old Password and New Password are same");
    }
    if (showNewPasswordField && checkCurrentPasswordFailureData) {
      dispatch(
        setUpNewPassword({
          newPassword: password,
          confirmPassword: confirmPassword,
        })
      );
    }
  }, [
    showNewPasswordField,
    checkCurrentPasswordSuccessData,
    checkCurrentPasswordFailureData,
    dispatch,
    confirmPassword,
    password,
  ]);

  const handleCurrentPaswordInputChange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z]/gi, " ");
    }

    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setMemberCurrentPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = setupPasswordFormValidation(
      password,
      confirmPassword
    );

    if (Object.keys(validationErrors).length === 0) {
      dispatch(checkCurrentPassword({ password: password }));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const handleContinue = () => {
    const validationErrors = {};
    if (memberCurrentPassword?.trim() === "") {
      validationErrors.memberCurrentPassword = "This field is required";
    }
    if (Object.keys(validationErrors).length === 0) {
      dispatch(checkCurrentPassword({ password: memberCurrentPassword }));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  //submit on enter key press

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleContinue();
    }
  };

  return (
    <div
      className="rounded-lg bg-white w-11/12 p-8 mt-12 mx-auto"
      style={{ boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.07)" }}
    >
      <ReactHelmet data={SeoData.managePassword} />
      {!showAnimation && (password !== "" || confirmPassword !== "") && (
        <PasswordConditionMessage />
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 space-x-10 animate-navbar text-brandDarkBlue">
        <div>
          <h1 className="text-2xl font-bold">Manage password</h1>
          <p className="font-semibold mb-10" style={{ color: "#6F6F6F" }}>
            Choose a unique password to protect your account
          </p>
          {showNewPasswordField ? (
            showAnimation ? (
              <div className="flex flex-col space-y-10 items-center">
                <AnimatedSuccess />
                <h1 className="animate-navbar text-base xl:text-lg text-green-700">
                  Password Updated Successfully
                </h1>
                {type !== "admin" ? (
                  <FormButton
                    classNames="bg-contact text-white mt-16"
                    onClick={() => navigate("/member/dashboard")}
                  >
                    Go back to dashboard
                  </FormButton>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <InputGroupEye
                  label="Enter your new Password"
                  placeholder="Enter new password"
                  name="password"
                  onChange={(e) =>
                    handleInputOnchange(
                      e,
                      formError,
                      setFormError,
                      setMemberNewPasswordDetails
                    )
                  }
                  value={password}
                  errorMessage={formError?.password}
                  refs={passwordInputRef}
                />
                <InputGroupEye
                  label="Confirm new Password"
                  placeholder="Confirm your new password"
                  name="confirmPassword"
                  onChange={(e) =>
                    handleInputOnchange(
                      e,
                      formError,
                      setFormError,
                      setMemberNewPasswordDetails
                    )
                  }
                  value={confirmPassword}
                  errorMessage={formError?.confirmPassword}
                />
                <FormButton
                  disabled={checkCurrentPasswordBegin}
                  classNames="bg-contact text-white mt-16"
                >
                  Change Password
                  {checkCurrentPasswordBegin && <Cliploader />}
                </FormButton>
              </form>
            )
          ) : (
            <>
              <InputGroupEye
                label="Enter Password"
                placeholder="Enter your current password"
                name="memberCurrentPassword"
                onChange={handleCurrentPaswordInputChange}
                value={memberCurrentPassword}
                errorMessage={formError?.memberCurrentPassword}
                refs={passwordInputRef}
                onKeyPress={handleKeyPress}
              />
              <FormButton
                disabled={checkCurrentPasswordBegin}
                classNames="bg-contact text-white mt-20 "
                onClick={() => handleContinue()}
              >
                Continue{checkCurrentPasswordBegin && <Cliploader />}
              </FormButton>
            </>
          )}
        </div>
        {/* <ManagePasswordIcon className="mt-20" /> */}
      </div>
    </div>
  );
};

export default ManagePasswordTab;
