export const UPDATE_MEMBER_PROFILE_DETAILS_BEGIN =
  "UPDATE_MEMBER_PROFILE_DETAILS_BEGIN";
export const UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS =
  "UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS";
export const UPDATE_MEMBER_PROFILE_DETAILS_FAILURE =
  "UPDATE_MEMBER_PROFILE_DETAILS_FAILURE";
export const RESET_UPDATE_MEMBER_PROFILE_DETAILS =
  "RESET_UPDATE_MEMBER_PROFILE_DETAILS";

export const GET_MEMBER_TRANSACTION_HISTORY_BEGIN =
  "GET_MEMBER_TRANSACTION_HISTORY_BEGIN";
export const GET_MEMBER_TRANSACTION_HISTORY_SUCCESS =
  "GET_MEMBER_TRANSACTION_HISTORY_SUCCESS";
export const GET_MEMBER_TRANSACTION_HISTORY_FAILURE =
  "GET_MEMBER_TRANSACTION_HISTORY_FAILURE";
export const RESET_GET_MEMBER_TRANSACTION_HISTORY =
  "RESET_GET_MEMBER_TRANSACTION_HISTORY";

export const CANCEL_MEMBER_AUTO_RENEWAL_BEGIN =
  "CANCEL_MEMBER_AUTO_RENEWAL_BEGIN";
export const CANCEL_MEMBER_AUTO_RENEWAL_SUCCESS =
  "CANCEL_MEMBER_AUTO_RENEWAL_SUCCESS";
export const CANCEL_MEMBER_AUTO_RENEWAL_FAILURE =
  "CANCEL_MEMBER_AUTO_RENEWAL_FAILURE";
export const RESET_CANCEL_MEMBER_AUTO_RENEWAL =
  "RESET_CANCEL_MEMBER_AUTO_RENEWAL";

export const CHECK_CURRENT_PASSWORD_BEGIN = "CHECK_CURRENT_PASSWORD_BEGIN";
export const CHECK_CURRENT_PASSWORD_SUCCESS = "CHECK_CURRENT_PASSWORD_SUCCESS";
export const CHECK_CURRENT_PASSWORD_FAILURE = "CHECK_CURRENT_PASSWORD_FAILURE";
export const RESET_CHECK_CURRENT_PASSWORD = "RESET_CHECK_CURRENT_PASSWORD";

export const SET_NEW_PASSWORD_BEGIN = "SET_NEW_PASSWORD_BEGIN";
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAILURE = "SET_NEW_PASSWORD_FAILURE";
export const RESET_SET_NEW_PASSWORD = "RESET_SET_NEW_PASSWORD";
