import {
  GET_MENTORS_LIST_BEGIN,
  GET_MENTORS_LIST_SUCCESS,
  GET_MENTORS_LIST_FAILURE,
  RESET_GET_MENTORS_LIST,
  GET_MENTOR_FEEDBACK_BEGIN,
  GET_MENTOR_FEEDBACK_SUCCESS,
  GET_MENTOR_FEEDBACK_FAILURE,
  RESET_GET_MENTOR_FEEDBACK,
  APPROVE_MENTOR_REGISTRATION_BEGIN,
  APPROVE_MENTOR_REGISTRATION_SUCCESS,
  APPROVE_MENTOR_REGISTRATION_FAILURE,
  RESET_APPROVE_MENTOR_REGISTRATION,
  REJECT_MENTOR_REGISTRATION_BEGIN,
  REJECT_MENTOR_REGISTRATION_SUCCESS,
  REJECT_MENTOR_REGISTRATION_FAILURE,
  RESET_REJECT_MENTOR_REGISTRATION,
  GET_SINGLE_MENTOR_DETAILS_BEGIN,
  GET_SINGLE_MENTOR_DETAILS_SUCCESS,
  GET_SINGLE_MENTOR_DETAILS_FAILURE,
  RESET_GET_SINGLE_MENTOR_DETAILS,
  GET_MENTOR_SESSION_DETAILS_BEGIN,
  GET_MENTOR_SESSION_DETAILS_SUCCESS,
  GET_MENTOR_SESSION_DETAILS_FAILURE,
  RESET_GET_MENTOR_SESSION_DETAILS,
  BLOCK_SINGLE_MENTOR_BEGIN,
  BLOCK_SINGLE_MENTOR_SUCCESS,
  BLOCK_SINGLE_MENTOR_FAILURE,
  RESET_BLOCK_SINGLE_MENTOR,
  GET_BLOCKED_MENTORS_BEGIN,
  GET_BLOCKED_MENTORS_SUCCESS,
  GET_BLOCKED_MENTORS_FAILURE,
  RESET_GET_BLOCKED_MENTORS,
  UNBLOCK_SINGLE_MENTOR_BEGIN,
  UNBLOCK_SINGLE_MENTOR_SUCCESS,
  UNBLOCK_SINGLE_MENTOR_FAILURE,
  RESET_UNBLOCK_SINGLE_MENTOR,
  APPROVE_MENTOR_BACKGROUND_VERIFICATION_BEGIN,
  APPROVE_MENTOR_BACKGROUND_VERIFICATION_SUCCESS,
  APPROVE_MENTOR_BACKGROUND_VERIFICATION_FAILURE,
  RESET_APPROVE_MENTOR_BACKGROUND_VERIFICATION,
  DELETE_MENTOR_BEGIN,
  DELETE_MENTOR_SUCCESS,
  DELETE_MENTOR_FAILURE,
  RESET_DELETE_MENTOR,
} from "../constants/AdminMentor";

import { httpApp } from "../../axios/httpUser";

//get mentor list

export function getMentorsList(status, currentPage = 1, limit = 10) {
  return (dispatch) => {
    dispatch({
      type: GET_MENTORS_LIST_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/admin/list/mentors?status=${status}&page=${currentPage}&active=1&limit=${limit}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTORS_LIST_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTORS_LIST,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTORS_LIST_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MENTORS_LIST,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get mentor feedback

export function getMentorFeedback(id, currentPage = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_MENTOR_FEEDBACK_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/admin/mentor/${id}/rating?page=${currentPage}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTOR_FEEDBACK_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_FEEDBACK,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTOR_FEEDBACK_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_FEEDBACK,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//approve mentor manually

export function approveMentor(id) {
  return (dispatch) => {
    dispatch({
      type: APPROVE_MENTOR_REGISTRATION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/admin/mentor/${id}/approve`);

      doRequest.then(
        (res) => {
          dispatch({
            type: APPROVE_MENTOR_REGISTRATION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_APPROVE_MENTOR_REGISTRATION,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: APPROVE_MENTOR_REGISTRATION_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_APPROVE_MENTOR_REGISTRATION,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//reject mentor

export function rejectMentor(id) {
  return (dispatch) => {
    dispatch({
      type: REJECT_MENTOR_REGISTRATION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/admin/mentor/${id}/reject`);

      doRequest.then(
        (res) => {
          dispatch({
            type: REJECT_MENTOR_REGISTRATION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_REJECT_MENTOR_REGISTRATION,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: REJECT_MENTOR_REGISTRATION_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_REJECT_MENTOR_REGISTRATION,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get single mentor details

export function getSingleMentorsDetails(id) {
  return (dispatch) => {
    dispatch({
      type: GET_SINGLE_MENTOR_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/mentor/${id}/profile`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_SINGLE_MENTOR_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_MENTOR_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_SINGLE_MENTOR_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_MENTOR_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get mentor session details

export function getMentorSessionDetails(id) {
  return (dispatch) => {
    dispatch({
      type: GET_MENTOR_SESSION_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/admin/mentor/${id}/session`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTOR_SESSION_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_SESSION_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTOR_SESSION_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_SESSION_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//block mentor

export function blockMentor(id) {
  return (dispatch) => {
    dispatch({
      type: BLOCK_SINGLE_MENTOR_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/admin/mentor/${id}/block`);

      doRequest.then(
        (res) => {
          dispatch({
            type: BLOCK_SINGLE_MENTOR_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_BLOCK_SINGLE_MENTOR,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: BLOCK_SINGLE_MENTOR_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_BLOCK_SINGLE_MENTOR,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get blocked mentors list

export function getBlockedMentors(currentPage = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_BLOCKED_MENTORS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/admin/mentor/blocked?page=${currentPage}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_BLOCKED_MENTORS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_BLOCKED_MENTORS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_BLOCKED_MENTORS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_BLOCKED_MENTORS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//unblock mentor

export function unblockMentor(id) {
  return (dispatch) => {
    dispatch({
      type: UNBLOCK_SINGLE_MENTOR_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/admin/mentor/${id}/unblock`);

      doRequest.then(
        (res) => {
          dispatch({
            type: UNBLOCK_SINGLE_MENTOR_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UNBLOCK_SINGLE_MENTOR,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UNBLOCK_SINGLE_MENTOR_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UNBLOCK_SINGLE_MENTOR,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//mentor thirdparty background verification

export function mentorBackgroundVerification(id) {
  return (dispatch) => {
    dispatch({
      type: APPROVE_MENTOR_BACKGROUND_VERIFICATION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/admin/mentor/${id}/backgroundcheck`);

      doRequest.then(
        (res) => {
          dispatch({
            type: APPROVE_MENTOR_BACKGROUND_VERIFICATION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_APPROVE_MENTOR_BACKGROUND_VERIFICATION,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: APPROVE_MENTOR_BACKGROUND_VERIFICATION_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_APPROVE_MENTOR_BACKGROUND_VERIFICATION,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function deleteMentor(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_MENTOR_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/admin/deleteMentor/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_MENTOR_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_MENTOR,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_MENTOR_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_MENTOR,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
