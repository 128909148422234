import React, { useState } from "react";

import PageData from "../utilities/content/pages.json";
import { FaqSearchIcon } from "../utilities/svg";

const FaqQuestionListing = () => {
  const { faqHeading, questions } = PageData.faqPageData;
  const [clicked, setClicked] = useState(false);
  const [query, setQuery] = useState("");
  const filteredQuestions = questions.filter((question) => {
    return question.title.toLowerCase().includes(query.toLowerCase());
  });

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const SinglQuestion = ({ question }) => {
    const { title, info, id } = question;
    return (
      <div
        onClick={() => {
          toggle(id);
        }}
        className=" px-2 sm:px-4 py-3 sm:py-6 m-2 rounded-r"
        style={{ background: "#fbfbfb" }}
      >
        <div className="flex justify-between items-center">
          <h4 className=" font-bold text-xs 370px:text-base md:text-xl">
            {title}
          </h4>
          <span
            style={{ color: "#202020" }}
            className={`text-xl cursor-pointer pr-2 ${
              clicked === id ? "animate-navbar" : ""
            }`}
          >
            {clicked === id ? "-" : "+"}
          </span>
        </div>
        {clicked === id && (
          <div className="animate-navbar">
            <div
              style={{ borderBottom: "0.5px solid #eeeeee" }}
              className="mt-2 sm:my-3"
            />
            <p className="text-brandGrey text-xs 370px:text-base md:text-lg">
              {info}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="sm:my-2 bg-white homepageWidth rounded-md 370px:mt-2 370px:rounded-lg py-3 370px:py-5 lg:pb-20 lg:pt-12 lg:w-11/12 2xl:w-4/6 z-10 ">
      <div className="homepageWidth sm:flex sm:flex-row justify-between sm:space-x-12 md:space-x-1 950px:gap-16">
        <h1 className="font-semibold 370px:text-lg 370px:text-center p-2 sm:text-sm 500px:text-base md:text-lg 950px:text-xl text-brandDarkBlue">
          {faqHeading}
        </h1>
        <div className="bg-faq flex justify-center px-2 mr-2 sm:w-5/12 rounded-lg">
          <FaqSearchIcon className="self-center" />
          <input
            className="focus:outline-none pl-4 py-2 w-full font-medium text-brandDarkBlue text-xs 500px:text-base md:text-xl bg-faq"
            type="text"
            placeholder="Search"
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
      </div>
      <div
        style={{ background: "#EEEEEE" }}
        className="homepageWidth border-b border-faq mt-1 lg:mt-3 mb-5 lg:mb-10"
      />
      <div className="homepageWidth border border-faq text-brandDarkBlue rounded-xl">
        {filteredQuestions.length > 0 ? (
          filteredQuestions.map((question) => (
            <SinglQuestion
              key={question.id}
              question={question}
              clicked={clicked}
              toggle={toggle}
            />
          ))
        ) : (
          <h1 className="text-center text-3xl animate-navbar">No Results...</h1>
        )}
      </div>
    </div>
  );
};

export default FaqQuestionListing;
