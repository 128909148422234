import React from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import ViewCurriculumDetailsCard from "../../components/pageComponents/adminDashboard/ViewCurriculumDetailsCard";

const AdminCurriculum = () => {
  return (
    <AdminDashboardLayout>
      <ViewCurriculumDetailsCard />
    </AdminDashboardLayout>
  );
};

export default AdminCurriculum;
