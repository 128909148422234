import { Suspense } from "react";
import { Link } from "react-router-dom";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import lazy from "react-lazy-named";

import PageData from "../utilities/content/pages.json";
import HomeBGOne from "../../assets/Images/HomeBGOne.webp";
import HomeBGTwo from "../../assets/Images/HomeBGTwo.webp";
import HomeBGThree from "../../assets/Images/HomeBGThree.webp";
import HomeBGFour from "../../assets/Images/HomeBGFour.webp";

const CustomCarousal = lazy(
  () => import("react-responsive-carousel"),
  "Carousel"
);

const HomeLandingSection = () => {
  const { banner, landingPageCarouselTexts } = PageData.homePageData;
  return (
    <section className="relative">
      {/* Carousel */}

      <AnimatedCustomCarousal
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        autoPlay={false}
        interval="5000"
        infiniteLoop={true}
      >
        <div className="relative">
          <img src={HomeBGOne} alt="" width="100%" height="100%" />
          <p className="absolute top-2/4 sm:top-1/3 font-extrabold left-2 md:left-36  sm:block text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-left sm:w-2/5 text-white">
            {landingPageCarouselTexts.heading1}
          </p>
        </div>
        <div className="relative">
          <img src={HomeBGTwo} alt="" width="100%" height="100%" />
          <p className="absolute top-2/4 left-2 sm:left-32 font-extrabold sm:block text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-5xl text-left sm:w-3/4 text-white">
            {/* {landingPageCarouselTexts.heading2} */}
            Merity’s Curated Reflective Practices: 
            <br />
            The Catalyst to Accelerated Personal and Professional Growth.
          </p>
        </div>
        <div className="relative">
          <img src={HomeBGThree} alt="" width="100%" height="100%" />
          <p className="absolute top-1/2 pr-4 font-extrabold left-2 md:pr-16 lg:pr-0 lg:left-36 sm:block text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-left px-2 lg:w-37% text-white">
            {landingPageCarouselTexts.heading3}
          </p>
        </div>
        <div className="relative">
          <img src={HomeBGFour} alt="" width="100%" height="100%" />
          <p className="absolute top-1/3 mt-2 sm:mt-0 md:top-1/3 font-extrabold left-2 md:left-36  sm:block text-sm sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-left sm:w-1/2 text-white">
            {/* {landingPageCarouselTexts.heading4} */}
            Discover Support, Community  and Accountability
            <br />
            with Merity's Exclusive  Online Member's Chat.
          </p>
        </div>
      </AnimatedCustomCarousal>

      {/* top shade */}
      <div className="absolute top-0 navbarGradient h-24 w-full" />

      {/* Banner */}
      <div className="bg-homePageBanner relative xl:py-2 md:py-5">
        <div className="homepageWidth flex items-center justify-between">
          <div
            style={{ color: "#FAFAFA" }}
            className="w-full md:w-4/5 lg:w-5/6 text-xs 500px:text-base xl:text-2xl lg:text-xl md:text-lg sm:text-sm xl:py-6 sm:pr-12 950px:pr-4 2xl:pr-0"
            dangerouslySetInnerHTML={{ __html: banner.heading }}
          />

          <Link
            to="/join"
            className="activeState w-42 h-42 sm:w-44 sm:h-44 md:w-46 md:h-46 950px:w-36 950px:h-36 lg:w-48 lg:h-48 xl:w-56 xl:h-56 2xl:w-48 2xl:h-48  bg-brandYellow hover:bg-brandGreen active:bg-brandGreen text-brandDarkBlue transition75 duration-200 md:absolute right-0 top-1/2 md:transform md:-translate-y-1/2 rounded-full flexCenter p-2 md:-translate-x-1/4 "
            >
              <p className="font-bold text-center text-sm 500px:text-base 950px:text-xl md:text-xl sm:text-xl">
                {banner.roundContent}
              </p>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HomeLandingSection;

const AnimatedCustomCarousal = (props) => (
  <Suspense
    fallback={
      <div
        style={{ minHeight: "400px" }}
        className="bg-homePageBanner relative"
      />
    }
  >
    <CustomCarousal {...props} />
  </Suspense>
);
