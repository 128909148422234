export const GET_CLIENT_LIST_BEGIN = "GET_CLIENT_LIST_BEGIN";
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS";
export const GET_CLIENT_LIST_FAILURE = "GET_CLIENT_LIST_FAILURE";
export const RESET_GET_CLIENT_LIST = "RESET_GET_CLIENT_LIST";

export const GET_CLIENT_EXERCISE_BEGIN = "GET_CLIENT_EXERCISE_BEGIN";
export const GET_CLIENT_EXERCISE_SUCCESS = "GET_CLIENT_EXERCISE_SUCCESS";
export const GET_CLIENT_EXERCISE_FAILURE = "GET_CLIENT_EXERCISE_FAILURE";
export const RESET_GET_CLIENT_EXERCISE = "RESET_GET_CLIENT_EXERCISE";

export const GET_CLIENT_ASSESSMENT_BEGIN = "GET_CLIENT_ASSESSMENT_BEGIN";
export const GET_CLIENT_ASSESSMENT_SUCCESS = "GET_CLIENT_ASSESSMENT_SUCCESS";
export const GET_CLIENT_ASSESSMENT_FAILURE = "GET_CLIENT_ASSESSMENT_FAILURE";
export const RESET_GET_CLIENT_ASSESSMENT = "RESET_GET_CLIENT_ASSESSMENT";

export const ADD_CLIENT_NOTES_BEGIN = "ADD_CLIENT_NOTES_BEGIN";
export const ADD_CLIENT_NOTES_SUCCESS = "ADD_CLIENT_NOTES_SUCCESS";
export const ADD_CLIENT_NOTES_FAILURE = "ADD_CLIENT_NOTES_FAILURE";
export const RESET_ADD_CLIENT_NOTES = "RESET_ADD_CLIENT_NOTES";

export const VIEW_CLIENT_NOTES_BEGIN = "VIEW_CLIENT_NOTES_BEGIN";
export const VIEW_CLIENT_NOTES_SUCCESS = "VIEW_CLIENT_NOTES_SUCCESS";
export const VIEW_CLIENT_NOTES_FAILURE = "VIEW_CLIENT_NOTES_FAILURE";
export const RESET_VIEW_CLIENT_NOTES = "RESET_VIEW_CLIENT_NOTES";

export const EDIT_CLIENT_NOTE_BEGIN = "EDIT_CLIENT_NOTE_BEGIN";
export const EDIT_CLIENT_NOTE_SUCCESS = "EDIT_CLIENT_NOTE_SUCCESS";
export const EDIT_CLIENT_NOTE_FAILURE = "EDIT_CLIENT_NOTE_FAILURE";
export const RESET_EDIT_CLIENT_NOTE = "RESET_EDIT_CLIENT_NOTE";

export const DELETE_CLIENT_NOTE_BEGIN = "DELETE_CLIENT_NOTE_BEGIN";
export const DELETE_CLIENT_NOTE_SUCCESS = "DELETE_CLIENT_NOTE_SUCCESS";
export const DELETE_CLIENT_NOTE_FAILURE = "DELETE_CLIENT_NOTE_FAILURE";
export const RESET_DELETE_CLIENT_NOTE = "DELETE_CLIENT_NOTE_RESET";

export const ADD_CLIENT_TASKS_BEGIN = "ADD_CLIENT_TASKS_BEGIN";
export const ADD_CLIENT_TASKS_SUCCESS = "ADD_CLIENT_TASKS_SUCCESS";
export const ADD_CLIENT_TASKS_FAILURE = "ADD_CLIENT_TASKS_FAILURE";
export const RESET_ADD_CLIENT_TASKS = "RESET_ADD_CLIENT_TASKS";

export const VIEW_CLIENT_TASKS_BEGIN = "VIEW_CLIENT_TASKS_BEGIN";
export const VIEW_CLIENT_TASKS_SUCCESS = "VIEW_CLIENT_TASKS_SUCCESS";
export const VIEW_CLIENT_TASKS_FAILURE = "VIEW_CLIENT_TASKS_FAILURE";
export const RESET_VIEW_CLIENT_TASKS = "RESET_VIEW_CLIENT_TASKS";

export const ASSESSMENT_DOWNLOAD_BEGIN = "ASSESSMENT_DOWNLOAD_BEGIN";
export const ASSESSMENT_DOWNLOAD_SUCCESS = "ASSESSMENT_DOWNLOAD_SUCCESS";
export const ASSESSMENT_DOWNLOAD_FAILURE = "ASSESSMENT_DOWNLOAD_FAILURE";
export const RESET_ASSESSMENT_DOWNLOAD = "RESET_ASSESSMENT_DOWNLOAD";