import React, { useState } from "react";

import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";
import {
  infoToast,
  mentorRegistrationPageData,
  scrollToTop,
} from "../../utilities";

const MentorAreaOfFocus = ({
  changeToNextPage,
  onBackButtonClick,
  continueButtonloader,
  mentorRegisterData,
}) => {

  window.location.hash = 'step4';
  const { options } = mentorRegistrationPageData;
  const [checkedOptions, setCheckedOptions] = useState(
    mentorRegisterData.newOptions
      ? mentorRegisterData.newOptions.map((option) => option.id)
      : []
  );

  const [others, setOthers] = useState(
    mentorRegisterData.newOptions?.find((option) => option.id === 6)
      ? mentorRegisterData.newOptions?.find((option) => option.id === 6)?.title
      : ""
  );

  const onContinueButtonClick = (e) => {
    e.preventDefault();

    if (checkedOptions.length < 1) {
      infoToast("Please select at-least one to continue");
    } else if (
      checkedOptions.length === 1 &&
      checkedOptions.some((option) => option === 6 && others.trim() === "")
    ) {
      infoToast("Please select at-least one to continue");
    } else {
      const selectedOptions = options?.filter((option) =>
        checkedOptions.includes(option.id)
      );
      let newOptions = selectedOptions.map((option) => {
        var returnValue = { ...option };
        if (option.id === 6) {
          returnValue.title = others;
        }
        return returnValue;
      });
      changeToNextPage("personal_statement_page", { newOptions });
      scrollToTop("50", "16.66");
    }
  };

  const AreaOfFocusMiddlePart = ({ options }) => {
    const onChange = (id) => {
      if (checkedOptions.includes(id)) {
        setCheckedOptions(
          checkedOptions.filter((optionId) => {
            return optionId !== id;
          })
        );
      } else {
        setCheckedOptions([...checkedOptions, id]);
      }
    };

    return (
      <>
        <div className="w-full grid gap-y-6 sm:gap-y-11 xl:gap-y-16 mt-10 sm:mt-20 mb-8 md:mb-16  gap-x-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">
          {options?.map(({ title, id }) => {
            const optionChecked = checkedOptions.includes(id);
            return (
              <div
                key={id}
                onClick={() => onChange(id)}
                className={` border cursor-pointer rounded-10px inline-flex items-center  transition75 active:scale-90 active:animate-pulse active:opacity-90 checkbox-group py-3 px-5 ${optionChecked ? "bg-memberAssessmentCard" : "hover:bg-gray-50"
                  }`}
                style={{
                  borderColor: optionChecked ? "#8DD4AC" : "#dddddd",
                  boxShadow: optionChecked
                    ? "0px 3px 30px rgba(0, 0, 0, 0.09)"
                    : "none",
                  transition: "all 0.5s ease",
                }}
              >
                <input
                  type="checkbox"
                  checked={optionChecked}
                  id={id}
                  onChange={() => onChange(id)}
                />

                <label
                  htmlFor={title}
                  className={` text-base cursor-pointer ${optionChecked ? "font-semibold" : "font-medium"
                    }`}
                >
                  {title}
                </label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <section className="w-96% sm:w-10/12 xl:w-9/12 mx-auto">
        <form id="mentor_form" onSubmit={(e) => onContinueButtonClick(e)}>
          <h1
            className="font-semibold text-3xl text-center sm:mt-8 mt-6"
            style={{ color: "#093B5D" }}
          >
            Area of Focus
          </h1>
          <AreaOfFocusMiddlePart options={options} />
          {checkedOptions.includes(6) && (
            <textarea
              placeholder="If these fields doesn’t match yours, type your area of focus"
              cols="80"
              rows="7"
              className="animate-navbar appInput mb-0 mt-2 w-full"
              value={others}
              onChange={(e) => setOthers(e.target.value)}
            />
          )}
          <div className="w-96% md:w-1/2 mx-auto my-12">
            <FormBackAndContinueButton
              onBackButtonClick={() => {
                onBackButtonClick("professional_information_page");
                scrollToTop("50", "16.66");
              }}
              actionBegin={continueButtonloader}
            />
          </div>
        </form>
      </section>
    </>
  );
};

export default MentorAreaOfFocus;
