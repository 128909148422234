import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { ReactHelmet } from "../../../utilities";
import SubscriptionPaymentTab from "./SubscriptionPaymentTab";
import SeoData from "../../../utilities/seo/seoData.json";

const SubNavbar = ({ id }) => {
  const [params, setParams] = useSearchParams();
  const subtab = params.getAll("subtab").toString();

  useEffect(() => {
    if (id && subtab === "") {
      setParams({
        tab: "payment",
        subtab: "subscription",
        id: id,
      });
    } else if (subtab === "") {
      setParams({
        tab: "payment",
        subtab: "subscription",
      });
    }
  }, [subtab, setParams, id]);

  return (
    <>
      <div className="overflow-x-auto 500px:overflow-x-hidden ">
        <div
          className=" font-normal flex pt-2  px-10"
          style={{ backgroundColor: "#FAFAFA", minWidth: "580px" }}
        >
          <ReactHelmet data={SeoData.memberPayment} />

          <div className="flex gap-10">
            {subtab === "subscription" || subtab === "purchased" ? (
              <PaymentTabs selectedTab={subtab} setParams={setParams} id={id} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div>
        <SubscriptionPaymentTab />
      </div>
    </>
  );
};

export default SubNavbar;

const PaymentTabs = ({ selectedTab, setParams, id }) =>
  cells.map((cell, index) => (
    <div className="mt-2" key={index}>
      <p
        className={`rounded-sm pb-1 px-4 font-medium text-memberProfileSubNavbar text-base xl:text-lg text-center cursor-pointer hover:text-gray-600 activeState ${selectedTab === cell.toLowerCase().split(" ")[0]
          ? "font-semibold text-memberProfileSubNavbar hover:opacity-90 hover:text-memberProfileSubNavbar"
          : ""
          }`}
        key={index}
        onClick={() => {
          if (id) {
            setParams({
              tab: "payment",
              subtab: cell.toLowerCase().split(" ")[0],
              id: id,
            });
          } else {
            setParams({
              tab: "payment",
              subtab: cell.toLowerCase().split(" ")[0],
            });
          }
        }}
      >
        {cell}
      </p>
      {selectedTab === cell.toLowerCase().split(" ")[0] && (
        <div
          className="h-1 xl:h-3px mt-0 rounded-full "
          style={{
            width: "100%",
            backgroundColor: "#7A7A7A",
          }}
        />
      )}
    </div>
  ));

const cells = ["Subscription Payment", "Purchased Minutes"];
