import { Cliploader, CliploaderBlue, FormButton } from "../../utilities";

const FormBackAndContinueButton = ({
  onBackButtonClick,
  actionBegin,
  buttonText,
  backButtonText,
  backButtonLoader,
  twoButtons,
  backButtonNotAllowed,
  buttonClass,
  onClick,
}) => (
  <div className="flex gap-6 mt-6 mb-8 lg:mb-0 flex-col lg:flex-row">
    {twoButtons === false ? (
      ""
    ) : (
      <a
        href="#!"
        disabled={backButtonNotAllowed}
        onClick={() =>
          !backButtonNotAllowed && !backButtonLoader && onBackButtonClick()
        }
        className={`${buttonClass} mx-auto flexCenter w-full gap-2 uppercase font-semibold py-3 bg-white text-brandBlue border border-brandBlue ${
          backButtonLoader ? "cursor-wait" : "buttonHover"
        } ${backButtonNotAllowed ? "cursor-not-allowed" : "activeState"} ${
          backButtonText !== "Go Back" ? `text-sm sm:text-lg` : `text-sm `
        } `}
      >
        {backButtonText ? backButtonText : "Go Back"}
        {backButtonLoader && <CliploaderBlue />}
      </a>
    )}
    <FormButton
      disabled={actionBegin}
      classNames={`${buttonClass} bg-brandBlue text-white ${
        actionBegin ? "cursor-wait hover:opacity-100" : "buttonHover"
      } `}
      onClick={onClick}
    >
      {buttonText ? buttonText : "Continue"} {actionBegin && <Cliploader />}{" "}
    </FormButton>
  </div>
);

export default FormBackAndContinueButton;
