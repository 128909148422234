import PuffLoader from "react-spinners/PuffLoader";
import RingLoader from "react-spinners/RingLoader";
import ClipLoader from "react-spinners/ClipLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import { NavbarSecondLogo } from "./svg";

export const Puffloader = () => (
  <span className="flexCenter mt-24">
    <PuffLoader color="#0C80A4" />
  </span>
);

export const Ringloader = () => (
  <span className="flexCenter">
    <RingLoader color="#0C80A4" />
  </span>
);

export const Cliploader = () => <ClipLoader color="#fff" size="18px" />;
export const CliploaderBlue = () => <ClipLoader color="#007CA5" size="18px" />;
export const ScaleloaderWhite = () => <ScaleLoader color="#fff" size="10px" />;
export const Scaleloader = () => (
  <span className="flexCenter my-6">
    <ScaleLoader color="#007CA5" size="10px" />
  </span>
);
export const NameLoader = () => <ScaleLoader color="#007CA5" size="4px" />;

export const ProgressLoader = () => {
  return (
    <div className="ipl-progress-indicator" id="ipl-progress-indicator">
      <div className="ipl-progress-indicator-head">
        <div className="first-indicator"></div>
        <div className="second-indicator"></div>
      </div>
      <div className="insp-logo-frame">
        <NavbarSecondLogo className="mx-auto w-1/12" />
      </div>
    </div>
  );
};

export const progressLoaderFunction = (
  // fake authentication Promise
  loadingFunction = function authenticate() {
    return new Promise((resolve) => setTimeout(resolve, 200));
  }
) => {
  loadingFunction().then(() => {
    const ele = document.getElementById("ipl-progress-indicator");
    if (ele) {
      // fade out
      ele.classList.add("available");
      setTimeout(() => {
        // remove from DOM
        ele.innerHTML = "";
      }, 200);
    }
  });
};
