import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setNewPassword } from "../../../AppRedux/actions/MemberLogin";
import PageData from "../../utilities/content/pages.json";
import { InputGroupEye } from "../../utilities/newFormInputs";
import { FormButton, errorToast, Cliploader } from "../../utilities";
import {
  handleInputOnchange,
  PasswordConditionMessage,
  setupPasswordFormValidation,
} from "../../utilities/SetUpPasswordForm";

const SetUpNewPasswordForm = ({ changeToNextPage, userForgotPassowrdData }) => {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState({});
  const [memberPasswordData, setMemberPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });
  const { heading } = PageData.memberLoginPageData.setUpNewPasswordForm;
  const {
    setNewPaswordBegin,
    setNewPasswordSuccessData,
    setNewPasswordFailureData,
  } = useSelector(({ memberLogin }) => memberLogin);

  useEffect(() => {
    document.querySelector(`input[name='password']`).focus();
  }, []);

  useEffect(() => {
    if (setNewPasswordFailureData) {
      errorToast(setNewPasswordFailureData);
    }
    if (setNewPasswordSuccessData) {
      changeToNextPage("reset_password_successfull");
    }
  }, [setNewPasswordSuccessData, setNewPasswordFailureData, changeToNextPage]);

  const onMentorPasswordSubmit = (e) => {
    e.preventDefault();

    const validationErrors = setupPasswordFormValidation(
      memberPasswordData.password,
      memberPasswordData.confirmPassword
    );
    if (Object.keys(validationErrors).length === 0) {
      const submittingData = {
        ...userForgotPassowrdData,
        password: memberPasswordData.confirmPassword,
      };
      dispatch(setNewPassword(submittingData));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <div
      className="lg:w-1/2 py-4 md:py-12 lg:py-36 px-4 sm:px-24"
      style={{ backgroundColor: "#fafafa" }}
    >
      <h2 className="font-medium text-2xl sm:text-28px mb-7">{heading}</h2>
      <form
        className="w-full xl:w-10/12 2xl:w-3/4"
        onSubmit={(e) => onMentorPasswordSubmit(e)}
      >
        {memberPasswordData.password !== "" ||
        memberPasswordData.confirmPassword !== "" ? (
          <PasswordConditionMessage />
        ) : null}

        <InputGroupEye
          label="Enter New password"
          name="password"
          value={memberPasswordData.password}
          onChange={(e) =>
            handleInputOnchange(
              e,
              formError,
              setFormError,
              setMemberPasswordData
            )
          }
          errorMessage={formError?.password}
        />

        <InputGroupEye
          label="Confirm New password"
          name="confirmPassword"
          value={memberPasswordData.confirmPassword}
          onChange={(e) =>
            handleInputOnchange(
              e,
              formError,
              setFormError,
              setMemberPasswordData
            )
          }
          errorMessage={formError?.confirmPassword}
        />

        <FormButton
          disabled={setNewPaswordBegin}
          classNames={`bg-brandBlue text-white mt-6 ${
            setNewPaswordBegin ? "cursor-wait" : "buttonHover"
          }`}
        >
          Change Password
          {<Cliploader /> && setNewPaswordBegin}
        </FormButton>
      </form>
    </div>
  );
};

export default SetUpNewPasswordForm;
