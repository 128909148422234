export const GET_COMMUNITY_LIST_BEGIN = "GET_COMMUNITY_LIST_BEGIN";
export const GET_COMMUNITY_LIST_SUCCESS = "GET_COMMUNITY_LIST_SUCCESS";
export const GET_COMMUNITY_LIST_FAILURE = "GET_COMMUNITY_LIST_FAILURE";
export const RESET_GET_COMMUNITY_LIST = "RESET_GET_COMMUNITY_LIST";

export const GENERATE_ZOOM_LINK_BEGIN = "GENERATE_ZOOM_LINK_BEGIN";
export const GENERATE_ZOOM_LINK_SUCCESS = "GENERATE_ZOOM_LINK_SUCCESS";
export const GENERATE_ZOOM_LINK_FAILURE = "GENERATE_ZOOM_LINK_FAILURE";
export const RESET_GENERATE_ZOOM_LINK = "RESET_GENERATE_ZOOM_LINK";

export const CREATE_COMMUNITY_SESSION_BEGIN = "CREATE_COMMUNITY_SESSION_BEGIN";
export const CREATE_COMMUNITY_SESSION_SUCCESS =
  "CREATE_COMMUNITY_SESSION_SUCCESS";
export const CREATE_COMMUNITY_SESSION_FAILURE =
  "CREATE_COMMUNITY_SESSION_FAILURE";
export const RESET_CREATE_COMMUNITY_SESSION = "RESET_CREATE_COMMUNITY_SESSION";

export const RESCHEDULE_COMMUNITY_SESSION_BEGIN =
  "RESCHEDULE_COMMUNITY_SESSION_BEGIN";
export const RESCHEDULE_COMMUNITY_SESSION_SUCCESS =
  "RESCHEDULE_COMMUNITY_SESSION_SUCCESS";
export const RESCHEDULE_COMMUNITY_SESSION_FAILURE =
  "RESCHEDULE_COMMUNITY_SESSION_FAILURE";
export const RESET_RESCHEDULE_COMMUNITY_SESSION =
  "RESET_RESCHEDULE_COMMUNITY_SESSION";

export const GET_SINGLE_COMMUNITY_BEGIN = "GET_SINGLE_COMMUNITY_BEGIN";
export const GET_SINGLE_COMMUNITY_SUCCESS = "GET_SINGLE_COMMUNITY_SUCCESS";
export const GET_SINGLE_COMMUNITY_FAILURE = "GET_SINGLE_COMMUNITY_FAILURE";
export const RESET_GET_SINGLE_COMMUNITY = "RESET_GET_SINGLE_COMMUNITY";

export const DELETE_COMMUNITY_BEGIN = "DELETE_COMMUNITY_BEGIN";
export const DELETE_COMMUNITY_SUCCESS = "DELETE_COMMUNITY_SUCCESS";
export const DELETE_COMMUNITY_FAILURE = "DELETE_COMMUNITY_FAILURE";
export const RESET_DELETE_COMMUNITY = "RESET_DELETE_COMMUNITY";
