import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { subscribeNewsLetter } from "../../../AppRedux/actions/NewsLetterAndContact";
import { Cliploader, errorToast, formResponseToasting } from "../../utilities";

const NewsLetterSubscriptionForm = () => {
  const [formData, setformData] = useState({
    user_subscription_name: "",
    user_subscription_lastName: "",
    user_subscription_email: "",
  });

  const dispatch = useDispatch();
  const newsLetterAndContactReducer = useSelector(
    ({ newsLetterAndContactReducer }) => newsLetterAndContactReducer
  );

  useEffect(() => {
    if (newsLetterAndContactReducer?.subscribeNewsLetterSuccessData) {
      setformData({
        user_subscription_name: "",
        user_subscription_email: "",
        user_subscription_lastName: "",
      });
    }
    formResponseToasting(
      newsLetterAndContactReducer?.subscribeNewsLetterSuccessData,
      newsLetterAndContactReducer?.subscribeNewsLetterFailureData
    );
  }, [newsLetterAndContactReducer]);

  const onNewsLetterSubmit = (e) => {
    e.preventDefault();
    const validation = customFormValidation();
    if (validation) {
      const submittingData = {
        name:
          formData.user_subscription_name +
          " " +
          formData.user_subscription_lastName,
        email: formData.user_subscription_email,
      };
      dispatch(subscribeNewsLetter(submittingData));
    }
    else {
      errorToast("Please fill all the required fields")
    }
  };

  const customFormValidation = () => {
    let {
      user_subscription_name,
      user_subscription_email,
      user_subscription_lastName,
    } = formData;
    user_subscription_name = user_subscription_name.trim();
    user_subscription_email = user_subscription_email.trim();
    user_subscription_lastName = user_subscription_lastName.trim();

    setTimeout(() => {
      toast.clearWaitingQueue();
    }, 2000);
    if (
      user_subscription_name === "" ||
      user_subscription_email === "" ||
      user_subscription_lastName === ""
    ) {
      errorToast("Please fill all the fields");
      document.querySelector("input[name='user_subscription_name']").focus();
      return false;
    } else if (
      user_subscription_name.length < 3 ||
      user_subscription_email.length < 3
    ) {
      errorToast("You have entered invalid data!");
      return false;
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        user_subscription_email
      )
    ) {
      errorToast("You have entered an invalid email address!");
      document.querySelector("input[name='user_subscription_email']").focus();
      return false;
    }
    return true;

  };

  const handleInputOnChange = (e) => {
    setformData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      id="newsletter_form"
      onSubmit={(e) => onNewsLetterSubmit(e)}
      className="sm:flex gap-6 justify-center w-11/12 lg:w-2/3 mx-auto"
    >
      <Input
        placeholder="Enter your first name"
        classNames=" w-1/2 md:w-2/4"
        name="user_subscription_name"
        value={formData.user_subscription_name}
        onChange={handleInputOnChange}
      />
      <Input
        placeholder="Enter your last name"
        classNames=" w-1/2 md:w-2/4"
        name="user_subscription_lastName"
        value={formData.user_subscription_lastName}
        onChange={handleInputOnChange}
      />
      <Input
        placeholder="Enter your email"
        classNames="w-1/2 md:w-2/4"
        name="user_subscription_email"
        value={formData.user_subscription_email}
        onChange={handleInputOnChange}
      />

      <button
        disabled={newsLetterAndContactReducer?.subscribeNewsLetterBegin}
        className={`mx-auto w-1/2 flexCenter gap-2 font-semibold text-lg bg-brandYellow hover:bg-brandGreen active:bg-brandGreen py-2 lg:py-3 px-8 transition75 duration-150 ${newsLetterAndContactReducer?.subscribeNewsLetterBegin
          ? "cursor-wait"
          : "activeState buttonHover"
          }`}
      >
        {newsLetterAndContactReducer?.subscribeNewsLetterBegin && (
          <Cliploader />
        )}
        LEARN MORE
      </button>
    </form>
  );
};

export default NewsLetterSubscriptionForm;

const Input = ({ placeholder, classNames, name, value, onChange }) => {
  return (
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={`block placeholder:text-brandDarkBlue text-brandDarkBlue text-sm md:text-base mb-5 sm:mb-0 w-10/12 mx-auto  font-medium px-3 py-2 sm:py-1 lg:py-2 outline-none border-none focus:ring-2 focus:ring-contact  ${classNames}`}
      style={{ backgroundColor: "#fffffff7" }}
    />
  );
};
