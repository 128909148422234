import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { convertFromRaw, EditorState, Editor, convertToRaw } from "draft-js";

import ConfirmModal from "../../utilities/modals/ConfirmModal";
import OutsideClickCloseContainer from "../../utilities/OutsideClickCloseContainer";
import { url } from "../../utilities/generalItems";
import { ListHeader } from "../memberDashboard/tableComponents/ExercisesList";
import { AdminCardContainer } from "./AdminGeneralComponents";
import { SaveChangesButton } from "../myInfoProfileGeneralItems";
import { VerifyRejectButtons } from "../adminDashboard/adminMentor/MentorsList";
import { PageNumbers } from "../dashboardGeneralItems";
import {
  scrollToTop,
  Scaleloader,
  FormatEditor,
  RcTooltip,
  successToast,
  InputGroup,
  FormButton,
} from "../../utilities";

import {
  createNewStory,
  getStories,
  publishStory,
} from "../../../AppRedux/actions/AdminDashboard";

import { EditIcon, AdminBlueArrow, UploadArrowIcon } from "../../utilities/svg";

const AdminCreateStories = () => {
  const dispatch = useDispatch();
  const [viewStories, setViewStories] = useState(false);
  const [storiesCount, setStoriesCount] = useState(null);
  const [storiesData, setStoriesData] = useState("");
  const [editClicked, setEditClicked] = useState(false);
  const [formError, setFormError] = useState({});
  const [currentPage, setcurrentPage] = useState(1);
  const [published, setPublished] = useState(false);
  const [checked, setChecked] = useState({ id: false, index: "" });
  const [showPublishConfirmModal, setShowPublishConfirmModal] = useState(false);
  const [showCheckedConfirmModal, setShowCheckedConfirmModal] = useState(false);
  const [attachment, setAttachment] = useState({
    file1: "",
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [styledState, setStyledState] = useState(
    convertToRaw(editorState.getCurrentContent())
  );
  const [formData, setformData] = useState({
    author: "",
    title: "",
    description: "",
    storyChecked: false,
  });

  const {
    saveStoryBegin,
    saveStorySuccessData,
    getStoriesBegin,
    publishStoryBegin,
    getStoriesSuccessData,
    publishStorySuccessData,
  } = useSelector(({ adminDashboard }) => adminDashboard);

  const confirmPublishModalData = {
    heading: `Are you sure you ${
      published ? "unpublish" : "publish"
    } this story?`,
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      if (published) {
        dispatch(publishStory(showPublishConfirmModal, { isPublished: false }));
      } else {
        dispatch(publishStory(showPublishConfirmModal, { isPublished: true }));
      }
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowPublishConfirmModal(false);
    },
    buttonLoader: publishStoryBegin,
  };

  const confirmCheckedModalData = {
    heading: "Are you sure you want display this in your home page",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      let story = storiesData?.find((value) => value.id === checked.id);
      if (story.storyChecked) {
        dispatch(publishStory(checked.id, { storyChecked: true }));
      } else {
        dispatch(publishStory(checked.id, { storyChecked: false }));
      }
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowCheckedConfirmModal(false);
      let story = storiesData?.find((value) => value.id === checked.id);
      story.storyChecked = false;
      let storiesArray = [...storiesData];
      storiesArray[checked.index] = story;
    },
    buttonLoader: publishStoryBegin,
  };

  useEffect(() => {
    setformData((formData) => ({
      ...formData,
      description: editorState.getCurrentContent().getPlainText(),
    }));
    setStyledState(convertToRaw(editorState.getCurrentContent()));
  }, [editorState]);

  useEffect(() => {
    dispatch(getStories());
  }, [dispatch]);

  useEffect(() => {
    if (getStoriesSuccessData) {
      setStoriesData(getStoriesSuccessData?.data?.rows);
      setStoriesCount(getStoriesSuccessData?.data?.count);
    }
    if (saveStorySuccessData) {
      successToast("Story created successfully");
      setformData((formData) => ({
        ...formData,
        author: "",
        title: "",
        description: "",
      }));

      setAttachment((attachment) => ({
        ...attachment,
        file1: "",
      }));
      setEditorState(() => EditorState.createEmpty());
      dispatch(getStories());
      setViewStories(false);
    }
    if (publishStorySuccessData) {
      successToast("Story updated successfully");
      setEditClicked(false);
      dispatch(getStories(currentPage));
      setViewStories(false);
      setShowPublishConfirmModal(false);
      setShowCheckedConfirmModal(false);
    }
  }, [
    dispatch,
    saveStorySuccessData,
    getStoriesSuccessData,
    publishStorySuccessData,
    currentPage,
  ]);

  const customMemberBasicFormValidation = () => {
    const { author, title, description } = formData;

    const validationErrors = {};
    if (author?.trim() === "") {
      validationErrors.author = "Author is required.";
    }
    if (author?.trim() !== "" && author.length < 3) {
      validationErrors.author = "please give a valid author.";
    }

    if (title?.trim() === "") {
      validationErrors.title = "Title is required.";
    }
    if (title?.trim() !== "" && title.length < 3) {
      validationErrors.title = "please give a valid title.";
    }

    if (description?.trim() === "") {
      validationErrors.description = "Description is required.";
    }
    if (description?.trim() !== "" && description.length < 3) {
      validationErrors.description = "please give a valid descriptions.";
    }

    return validationErrors;
  };

  const handleInputOnchange = (e) => {
    let value = e.target.value;
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setformData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleUploadImage = (e) => {
    //image validation
    var type = e.target.files[0].type.split("/").pop().toLowerCase();
    if (type !== "jpeg" && type !== "jpg" && type !== "png") {
      // infoToast("Please select a valid image file");
    } else {
      setAttachment({
        ...attachment,
        [e.target.name]: e.target.files[0] ? e.target.files[0] : "",
      });
    }
  };

  const handleSubmit = () => {
    const validationErrors = customMemberBasicFormValidation(formData);
    if (Object.keys(validationErrors).length === 0) {
      var formdata = new FormData();
      formdata.append("authorName", formData?.author);
      formdata.append("title", formData?.title);
      formdata.append("thumbnail", attachment?.file1);
      formdata.append("content", JSON.stringify(styledState));
      if (editClicked) {
        dispatch(publishStory(editClicked, formdata));
      } else {
        dispatch(createNewStory(formdata));
      }
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const uploadImageCallBack = async (file) => {
    const formdata = new FormData();
    formdata.append("image", file);
    return axios.post(`${url}/story/upload`, formdata).then((response) => {
      return {
        data: {
          link: response?.data?.data?.imageUrl,
        },
      };
    });
  };

  const handleCheckbox = (index, id, storyChecked) => {
    let story = storiesData?.find((value) => value.id === id);
    story.storyChecked = !storyChecked;
    let storiesArray = [...storiesData];
    storiesArray[index] = story;
    setStoriesData(storiesArray);
    setShowCheckedConfirmModal(true);
  };

  return (
    <AdminCardContainer>
      <div className="flex items-center space-x-4 shadow-adminMember rounded-full">
        {viewStories && (
          <div
            className="dashboardIcon text-brandBlue rounded-full min-w-36px min-h-36px flexCenter"
            style={{
              boxShadow:
                "0px 100px 80px rgba(0, 0, 0, 0.09), 0px 36.5016px 29.2013px rgba(0, 0, 0, 0.0620983), 0px 17.7209px 14.1767px rgba(0, 0, 0, 0.0500662), 0px 8.6871px 6.94968px rgba(0, 0, 0, 0.0399338), 0px 3.43489px 2.74791px rgba(0, 0, 0, 0.0279017)",
            }}
            onClick={() => setViewStories(false)}
          >
            <AdminBlueArrow className="stroke-current" />
          </div>
        )}
        <h1
          className="flex-1 font-semibold text-xl "
          style={{ color: "#002E3E" }}
        >
          {viewStories ? " Add New Story" : "Manage Stories"}
        </h1>
        {!viewStories && (
          <h1
            className="font-bold text-sm cursor-pointer"
            style={{ color: "#0282AD" }}
            onClick={() => {
              setformData((formData) => ({
                ...formData,
                author: "",
                title: "",
                description: "",
              }));
              setAttachment((attachment) => ({
                ...attachment,
                file1: "",
              }));
              setViewStories(!viewStories);
              setEditorState(() => EditorState.createEmpty());
            }}
          >
            Add new story
          </h1>
        )}
      </div>
      {!viewStories ? (
        <>
          <ListHeader
            headers={headers}
            gridClass="text-left grid-cols-4 mt-8"
          />
          {!getStoriesBegin && storiesData ? (
            <>
              {storiesData?.map(
                (
                  { id, authorName, title, content, isPublished, storyChecked },
                  index
                ) => (
                  <div
                    key={index}
                    className={`dashboardListHover member-table-row-item grid-cols-4 gap-2 font-medium text-sm xl:text-base items-center
              ${index === storiesData?.length - 1 ? "border-none" : ""}`}
                  >
                    <p>{authorName}</p>
                    <RcTooltip
                      content={<p className="oneLineContent">{title}</p>}
                    >
                      <p className="oneLineContent">{title}</p>
                    </RcTooltip>

                    <RcTooltip
                      content={
                        <Editor
                          editorState={EditorState.createWithContent(
                            convertFromRaw(content)
                          )}
                          readOnly={true}
                        />
                      }
                    >
                      <div className="oneLineContent">
                        <Editor
                          editorState={EditorState.createWithContent(
                            convertFromRaw(content)
                          )}
                          readOnly={true}
                        />
                      </div>
                    </RcTooltip>
                    <div className="flex justify-around">
                      <VerifyRejectButtons
                        functionality={isPublished ? "Unpublish" : "Publish"}
                        handleClick={(e) => {
                          e.stopPropagation(); //for prevent click function of it's parent
                          if (isPublished) {
                            setPublished(isPublished);
                            setShowPublishConfirmModal(id);
                          } else {
                            setPublished(isPublished);
                            setShowPublishConfirmModal(id);
                          }
                        }}
                      />
                      <div
                        className="dashboardIcon w-6 h-6"
                        onClick={(e) => {
                          e.stopPropagation(); //for prevent click function of it's parent
                          setEditClicked(id);
                          setViewStories(true);
                          let editStory = storiesData.find(
                            (value) => value.id === id
                          );
                          setformData({
                            ...formData,
                            author: editStory?.authorName,
                            title: editStory?.title,
                          });
                          setEditorState(
                            EditorState.createWithContent(
                              convertFromRaw(editStory?.content)
                            )
                          );
                          setAttachment({
                            ...attachment,
                            file1: editStory?.thumbnail,
                          });
                        }}
                      >
                        <EditIcon className="fill-current" />
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            e.stopPropagation(); //for prevent click function of it's parent
                            setChecked({ id, index });
                            handleCheckbox(index, id, storyChecked);
                          }}
                          name="checkbox"
                          checked={storyChecked}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ) : (
            <Scaleloader />
          )}
          <PageNumbers
            count={storiesCount}
            currentPage={currentPage}
            setcurrentPage={setcurrentPage}
            loading={getStoriesBegin}
            limit={10}
            onPageNumberClick={(number) => {
              dispatch(getStories(number));
            }}
          />
          {showPublishConfirmModal && (
            <OutsideClickCloseContainer setState={showPublishConfirmModal}>
              <ConfirmModal
                {...confirmPublishModalData}
                state={showPublishConfirmModal}
                setState={setShowPublishConfirmModal}
              />
            </OutsideClickCloseContainer>
          )}
          {showCheckedConfirmModal && (
            <OutsideClickCloseContainer setState={showCheckedConfirmModal}>
              <ConfirmModal
                {...confirmCheckedModalData}
                state={showCheckedConfirmModal}
                setState={setShowCheckedConfirmModal}
              />
            </OutsideClickCloseContainer>
          )}
        </>
      ) : (
        <>
          <p className="text-sm font-medium mt-4 " style={{ color: "#525252" }}>
            Fill in the details and upload the image to create a story
          </p>
          <div className=" w-3/4 mt-6">
            <InputGroup
              label="Enter author"
              name="author"
              onChange={(e) => handleInputOnchange(e)}
              value={formData.author}
              placeholder="Enter author for your story"
              errorMessage={formError?.author}
            />
            <InputGroup
              label="Enter title"
              name="title"
              onChange={(e) => handleInputOnchange(e)}
              value={formData.title}
              placeholder="Enter title for your story"
              errorMessage={formError?.title}
            />
            <div className="flex items-center gap-2">
              <FormButton
                type="button"
                classNames="mx-0 my-4 bg-brandBlue text-white w-1/2 lg:w-2/5 py-2 "
              >
                <UploadArrowIcon />
                Upload thumbnail
                <input
                  type="file"
                  name="file1"
                  accept="image/png, image/jpg, image/jpeg"
                  onChange={(e) => handleUploadImage(e)}
                  className="absolute w-full h-full opacity-0 "
                />
              </FormButton>
              {attachment?.file1?.name ? (
                <div className="flex justify-start">
                  <p className="activeState dashboardHover border-2 border-brandBlue text-brandBlue px-2 animate-navbar rounded-xl text-sm">
                    {attachment?.file1?.name}
                  </p>
                </div>
              ) : (
                typeof attachment.file1 === "object" && (
                  <>
                    <a
                      className="activeState dashboardHover border-2 border-brandBlue text-brandBlue px-2 animate-navbar rounded-xl text-sm"
                      href={`${url}/file?path=${attachment.file1}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Thumbnail
                    </a>
                  </>
                )
              )}
            </div>
            {/* <p>Enter story description</p> */}
            <FormatEditor
              editorState={editorState}
              setEditorState={setEditorState}
              errorMessage={formError?.description}
              setFormError={setFormError}
              limit={100000}
              tab="story"
              options={["inline", "list", "textAlign", "image", "link"]}
              uploadImageCallBack={uploadImageCallBack}
            />
          </div>
          <SaveChangesButton
            text="Publish"
            loader={saveStoryBegin || publishStoryBegin}
            onBackButtonClick={() => {
              scrollToTop("50", "16.66");
              setEditClicked(false);
              setViewStories(true);
              setFormError({});
              dispatch(getStories());
            }}
            onSubmitClick={handleSubmit}
          />
        </>
      )}
    </AdminCardContainer>
  );
};

export default AdminCreateStories;

const headers = ["Author", "Title", "Description"];
