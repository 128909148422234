import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import postalCodes from "postal-codes-js";

import { url } from "../../../utilities/generalItems";
import statusData from "../../../utilities/content/statusAndRoleManagement.json";
import SeoData from "../../../utilities/seo/seoData.json";
import { updateMemberDetails } from "../../../../AppRedux/actions/MemberProfile";
import PageData from "../../../utilities/content/pages.json";
import CancelAutoSubscriptionModal from "./CancelAutoSubscriptionModal";
import { userBasicDetailsValidation } from "../../formValidations";
import { getStates } from "../../../../AppRedux/actions/Home";
import { updateCometChatUser } from "../../../../AppRedux/actions/MemberCometChat";
import {
  ProfileCoverPhotos,
  BasicInformations,
  SaveChangesButton,
  ProfileEditButton,
} from "../../myInfoProfileGeneralItems";
import {
  Scaleloader,
  successToast,
  infoToast,
  scrollToTop,
  ReactHelmet,
  storeMemberDetailsInLocalStorage,
  getUserTokenFromLocalStorage,
} from "../../../utilities";
import {
  getMemberDashboardDetails,
  getMemberProfile,
} from "../../../../AppRedux/actions/MemberDashboard";
import { country } from "../../../utilities/content/country";

const ProfileInfo = () => {
  const dispatch = useDispatch();
  const [editIconfirstClick, setEditIconfirstClick] = useState(true);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [profiledData, setProfileData] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [autRenewalStatus, setAutoRenewalStatus] = useState(false);
  const [subscriptionType, setSubscriptionType] = useState(false);
  const [formError, setFormError] = useState({});
  const [checked, setChecked] = useState(false);
  const [userAvatarLetter, setUserAvatarLetter] = useState("");
  const [fileBeforeUpload, setFileBeforeUpload] = useState({
    profilePhoto: "",
    coverPhoto: "",
  });
  const [memberProfileData, setMemberProfileData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    profilePhoto: "",
    coverPhoto: "",
  });
  const { getMemberProfileBegin, getMemberProfileSuccessData } = useSelector(
    ({ memberDashboard }) => memberDashboard
  );
  const {
    updateMemberProfileDetailsBegin,
    updateMemberProfileDetailsSuccessData,
  } = useSelector(({ memberProfile }) => memberProfile);
  const { getStatesSuccessData } =
    useSelector(({ homeReducer }) => homeReducer);

  const { selectInput } = PageData.memberProfileInfoPageData;

  useEffect(() => {
    if (memberProfileData.country) {
      dispatch(getStates(memberProfileData.country));
    }
  }, [dispatch, memberProfileData.country]);

  useEffect(() => {
    if (getMemberProfileSuccessData) {
      const { profile, subscriptionDetails } =
        getMemberProfileSuccessData?.data;
      const {
        firstName,
        lastName,
        phone,
        email,
        address1,
        address2,
        country,
        state,
        city,
        zip,
        coverPhoto,
        profilePhoto,
        StripeMembers,
      } = profile;
      setProfileData(profile);
      setAutoRenewalStatus(StripeMembers[0]?.cancelAtPeriodEnd);
      setSubscriptionType(subscriptionDetails?.type);
      //comparing null for google and facebook users
      setMemberProfileData({
        firstName,
        lastName,
        email,
        phone: phone === null ? "" : phone,
        address1: address1 === null ? "" : address1,
        address2: address2 === null ? "" : address2,
        country: country === null ? "" : country,
        state: state === null ? "" : state,
        city: city === null ? "" : city,
        zip: zip === null ? "" : zip,
        coverPhoto,
        profilePhoto,
      });

      setUserAvatarLetter(firstName);
    }
  }, [getMemberProfileSuccessData]);

  useEffect(() => {
    if (updateMemberProfileDetailsSuccessData) {
      successToast("Details Updated");
      setInputDisabled(true);
      scrollToTop("50", "16.66");
      dispatch(getMemberProfile());
      dispatch(getMemberDashboardDetails());
      const { firstName, lastName, uid, email, phone, profilePhoto } =
        updateMemberProfileDetailsSuccessData.data;

      // update in cometchat
      const cometChatData = {
        uid: uid,
        name: `${firstName} ${lastName}`,
        avatar: `${url}/file?path=${profilePhoto}`,
        role: "1",
        metadata: {
          email: email,
          phoneNumber: phone,
        },
        withAuthToken: true,
      };
      dispatch(
        updateCometChatUser(
          "13f5bc6ef89bb9725ec04558defd0bb00343fcbf",
          uid,
          cometChatData
        )
      );
      storeMemberDetailsInLocalStorage({
        user: { ...updateMemberProfileDetailsSuccessData.data },
        token: getUserTokenFromLocalStorage(),
      });
    }
  }, [updateMemberProfileDetailsSuccessData, dispatch]);

  useEffect(() => {
    dispatch(getMemberProfile());
  }, [dispatch]);

  useEffect(() => {
    if (getStatesSuccessData) {
      selectInput[1].optionsArray = getStatesSuccessData?.states;
    }
  }, [getStatesSuccessData, selectInput]);

  // selectInput[0].optionsArray = getCountriesResourcesSuccessData?.countries;
  selectInput[0].optionsArray = country;

  const handleInputOnchange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z\s]/gi, "");
    }

    let sm = formError;
    delete sm[[e.target.name]];

    if (
      postalCodes.validate(memberProfileData.country, memberProfileData.zip) !==
      true
    ) {
      setFormError({ ...formError, ...sm, zip: "" });
    } else {
      setFormError({ ...formError, ...sm });
    }
    setMemberProfileData({
      ...memberProfileData,
      [e.target.name]: value,
    });
  };

  const handleUploadImage = (e) => {
    //image validation
    var type = e.target.files[0].type.split("/").pop().toLowerCase();
    if (type !== "jpeg" && type !== "jpg" && type !== "png") {
      infoToast("Please select a valid image file");
    } else {
      setImageLoaded(true);
      setProfileLoaded(true);
      setFileBeforeUpload({
        ...fileBeforeUpload,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      });
      setMemberProfileData({
        ...memberProfileData,
        [e.target.name]: e.target.files[0] ? e.target.files[0] : "",
      });
    }
  };

  const getUpdatedField = () => {
    const updatedData = {};
    const {
      firstName,
      lastName,
      phone,
      address1,
      address2,
      country,
      state,
      city,
      zip,
      coverPhoto,
      profilePhoto,
    } = memberProfileData;
    if (firstName !== profiledData?.firstName) {
      updatedData.firstName = firstName;
    }
    if (lastName !== profiledData?.lastName) {
      updatedData.lastName = lastName;
    }
    if (phone !== profiledData?.phone) {
      updatedData.phone = phone;
    }
    if (address1 !== profiledData?.address1) {
      updatedData.address1 = address1;
    }
    if (address2 !== profiledData?.address2) {
      updatedData.address2 = address2;
    }
    if (country !== profiledData?.country) {
      updatedData.country = country;
    }
    if (state !== profiledData?.state) {
      updatedData.state = state;
    }
    if (city !== profiledData?.city) {
      updatedData.city = city;
    }
    if (zip !== profiledData?.zip) {
      updatedData.zip = zip;
    }
    if (coverPhoto !== profiledData?.coverPhoto) {
      updatedData.coverPhoto = coverPhoto;
    }
    if (profilePhoto !== profiledData?.profilePhoto) {
      updatedData.profilePhoto = profilePhoto;
    }
    return updatedData;
  };

  const handleSubmit = () => {
    const validationErrors = userBasicDetailsValidation(memberProfileData);
    const updatedData = getUpdatedField();
    if (Object.keys(validationErrors).length === 0) {
      if (Object.keys(updatedData).length === 0) {
        infoToast("Please update atleast one field");
      } else {
        dispatch(updateMemberDetails(updatedData));
      }
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const onEditIconClick = () => {
    if (!updateMemberProfileDetailsBegin) {
      if (editIconfirstClick) {
        setFormError({});
        setInputDisabled(!inputDisabled);
        setEditIconfirstClick(!editIconfirstClick);
      } else {
        //second click
        setFormError({});
        setInputDisabled(!inputDisabled);
        dispatch(getMemberProfile());
        setEditIconfirstClick(!editIconfirstClick);
      }
    }
  };

  return (
    <div className=" relative 2xl:w-11/12 p-6 pt-0">
      <ReactHelmet data={SeoData.memberMyInfo} />

      <ProfileEditButton
        onEditIconClick={onEditIconClick}
        loader={updateMemberProfileDetailsBegin}
        inputDisabled={inputDisabled}
      />

      <ProfileCoverPhotos
        userAvatarLetter={userAvatarLetter}
        profileData={memberProfileData}
        fileBeforeUpload={fileBeforeUpload}
        imageLoaded={imageLoaded}
        setImageLoaded={setImageLoaded}
        profileLoaded={profileLoaded}
        setProfileLoaded={setProfileLoaded}
        getProfileBegin={getMemberProfileBegin}
        inputDisabled={inputDisabled}
        handleUploadImage={handleUploadImage}
      />

      {!getMemberProfileBegin && profiledData ? (
        <>
          <BasicInformations
            profileData={memberProfileData}
            setProfileData={setMemberProfileData}
            formError={formError}
            selectInput={selectInput}
            inputDisabled={inputDisabled}
            handleInputOnchange={handleInputOnchange}
          />
          {!inputDisabled && (
            <>
              {!autRenewalStatus &&
                subscriptionType === statusData.SUBSCRIPTION_TYPE.ONE_MONTH && (
                  <div>
                    <input
                      type="checkbox"
                      className="w-6 h-5 mr-2 align-middle rounded"
                      checked={checked}
                      onChange={(e) => setChecked(e.target.checked)}
                    />
                    <label className="text-deleteIcon font-normal">
                      Cancel auto-renewal of your subscription plan
                    </label>
                  </div>
                )}
              <SaveChangesButton
                loader={updateMemberProfileDetailsBegin}
                onBackButtonClick={() => {
                  scrollToTop("50", "16.66");
                  setInputDisabled(!inputDisabled);
                  setFormError({});
                  dispatch(getMemberProfile());
                }}
                onSubmitClick={handleSubmit}
              />
            </>
          )}
        </>
      ) : (
        <Scaleloader />
      )}

      {checked && (
        <CancelAutoSubscriptionModal
          state={checked}
          setState={setChecked}
          setAutoRenewalStatus={setAutoRenewalStatus}
        />
      )}
    </div>
  );
};

export default ProfileInfo;

export const GeneralImage = ({
  photo,
  beforeUpload,
  classNames,
  loaded,
  setLoaded,
  style,
}) => (
  <img
    src={typeof photo === "object" ? beforeUpload : `${url}/file?path=${photo}`}
    style={style}
    alt=""
    className={`object-fit border border-gray-200 shadow-sm  ${classNames} ${loaded ? "visible" : "hidden"
      }`}
    onLoad={() => setLoaded(true)}
  />
);
