import {
  UPDATE_MEMBER_PROFILE_DETAILS_BEGIN,
  UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS,
  UPDATE_MEMBER_PROFILE_DETAILS_FAILURE,
  RESET_UPDATE_MEMBER_PROFILE_DETAILS,
  GET_MEMBER_TRANSACTION_HISTORY_BEGIN,
  GET_MEMBER_TRANSACTION_HISTORY_SUCCESS,
  GET_MEMBER_TRANSACTION_HISTORY_FAILURE,
  RESET_GET_MEMBER_TRANSACTION_HISTORY,
  CANCEL_MEMBER_AUTO_RENEWAL_BEGIN,
  CANCEL_MEMBER_AUTO_RENEWAL_SUCCESS,
  CANCEL_MEMBER_AUTO_RENEWAL_FAILURE,
  RESET_CANCEL_MEMBER_AUTO_RENEWAL,
  CHECK_CURRENT_PASSWORD_BEGIN,
  CHECK_CURRENT_PASSWORD_SUCCESS,
  CHECK_CURRENT_PASSWORD_FAILURE,
  RESET_CHECK_CURRENT_PASSWORD,
  SET_NEW_PASSWORD_BEGIN,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  RESET_SET_NEW_PASSWORD,
} from "../constants/MemberProfile";

import { httpApp } from "../../axios/httpUser";

/**
 *  update member details
 */
export function updateMemberDetails(args) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_PROFILE_DETAILS_BEGIN,
    });

    var form = new FormData();
    Object.keys(args).map((i) => form.append(i, args[i]));

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.patch(`/member/profile`, form);

      doRequest.then(
        (res) => {
          dispatch({
            type: UPDATE_MEMBER_PROFILE_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UPDATE_MEMBER_PROFILE_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UPDATE_MEMBER_PROFILE_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UPDATE_MEMBER_PROFILE_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 *  get transaction history
 */

export function getMemberTransactions(type, pageNumber) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_TRANSACTION_HISTORY_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/member/transaction?type=${type}&pageNumber=${pageNumber}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_TRANSACTION_HISTORY_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_TRANSACTION_HISTORY,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_TRANSACTION_HISTORY_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_TRANSACTION_HISTORY,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 *  cancel auto renewal subscription
 */

export function cancelAutoRenewal() {
  return (dispatch) => {
    dispatch({
      type: CANCEL_MEMBER_AUTO_RENEWAL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post("/member/subscription/cancel");

      doRequest.then(
        (res) => {
          dispatch({
            type: CANCEL_MEMBER_AUTO_RENEWAL_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CANCEL_MEMBER_AUTO_RENEWAL,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CANCEL_MEMBER_AUTO_RENEWAL_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CANCEL_MEMBER_AUTO_RENEWAL,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 *  check members existing password
 */
export function checkCurrentPassword(data) {
  return (dispatch) => {
    dispatch({
      type: CHECK_CURRENT_PASSWORD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/password/current`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: CHECK_CURRENT_PASSWORD_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CHECK_CURRENT_PASSWORD,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CHECK_CURRENT_PASSWORD_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CHECK_CURRENT_PASSWORD,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 *  set up new password
 */
export function setUpNewPassword(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_PASSWORD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/password/change`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SET_NEW_PASSWORD_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SET_NEW_PASSWORD,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SET_NEW_PASSWORD_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SET_NEW_PASSWORD,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}



/**
 *  update member details
 */
export function updateNewUser(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_PASSWORD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {

      let doRequest = httpApp.post(`/member/setNewUser`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SET_NEW_PASSWORD_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SET_NEW_PASSWORD,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SET_NEW_PASSWORD_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SET_NEW_PASSWORD,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}