import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertFromRaw, EditorState, Editor } from "draft-js";
import AssignMentorModal from "./AssignMentorModal";

import { CardButton } from "../../../utilities";
import {
  getMemberProfileDetails,
  sendMemberAlert,
} from "../../../../AppRedux/actions/AdminMember";
import AdminMemberLeftSide from "./AdminMemberLeftSide";
import SubTabs from "./SubTabs";
import { Scaleloader, successToast, Cliploader } from "../../../utilities";
import SubNavbar from "../../memberDashboard/memberProfile/SubNavbar";

const SingleMemberDetailsPage = ({ tab, id, memId }) => {
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState("");
  const [assignMentorModal, setAssignMentorModal] = useState(false);
  const {
    getMemberProfileDataBegin,
    getMemberProfileDataSuccessData,
    sendMemberAlertBegin,
    sendMemberAlertSuccessData,
  } = useSelector(({ adminMember }) => adminMember);

  useEffect(() => {
    dispatch(getMemberProfileDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getMemberProfileDataSuccessData) {
      setProfileData(getMemberProfileDataSuccessData?.data);
    }
    if (sendMemberAlertSuccessData) {
      successToast(sendMemberAlertSuccessData?.message);
    }
  }, [getMemberProfileDataSuccessData, sendMemberAlertSuccessData]);

  const details = [
    {
      heading: "Mentor name",
      data:
        profileData?.mentorDatas === null
          ? "Mentor not assigned"
          : profileData?.mentorDatas?.UserMentor?.User.name,
    },
    {
      heading: "Purchase Min",
      data: `${
        profileData?.purchasedMinutes === null
          ? "0 Mins"
          : profileData?.purchasedMinutes?.purchasedMinute + " mins"
      }`,
    },
    {
      heading: "Consumed Min",
      data: `${profileData?.consumedMinutes} Mins`,
    },
    {
      heading: "Assigned exercises",
      data:
        profileData?.totalCounts < 10
          ? `0${profileData?.totalCounts}`
          : profileData?.totalCounts,
    },
    {
      heading: "Completed exercises",
      data:
        profileData?.exerciseCounts < 10
          ? `0${profileData?.exerciseCounts}`
          : profileData?.exerciseCounts,
    },
    {
      heading: "Pending exercises",
      data:
        profileData?.pendingCount < 10
          ? `0${profileData?.pendingCount}`
          : profileData?.pendingCount,
    },
  ];

  const OnAlertButtonClick = () => {
    const data = {
      pendingCount: profileData?.pendingCount,
    };

    dispatch(sendMemberAlert(id, data));
  };

  return (
    <div className="relative w-96% pt-7 pb-8 mx-4 500px:pb-16 ">
      <div className="flex gap-3 950px:gap-6">
        <AdminMemberLeftSide id={id} />
        <div className="overflow-x-scroll 950px:overflow-x-auto bg-white rounded-10px shadow-memberDashboard flex-auto py-4">
          <div style={{ minWidth: "520px" }}>
            <div className="flex items-center justify-between p-2">
              <SubTabs selectedTab={tab} cells={cells} type="admin" />
              <CardButton
                text="Assign Mentor"
                linearBg={true}
                classNames="font-semibold text-sm rounded-md w-1/3 justify-center"
                onClick={() => setAssignMentorModal(true)}
              />
            </div>

            {!getMemberProfileDataBegin && profileData ? (
              <>
                {tab === "profile" ? (
                  <div className="px-4">
                    <div className="mt-10 p-4 grid grid-cols-3 gap-4 gap-y-10">
                      {details.map(({ heading, data }, index) => (
                        <div key={index}>
                          <h1
                            className="text-sm mb-2"
                            style={{ color: "#0000007a" }}
                          >
                            {heading}
                          </h1>

                          <div className=" flex space-x-4">
                            <h1 className="text-xl font-medium">{data}</h1>
                            {heading === "Pending exercises" ? (
                              <button
                                onClick={() => OnAlertButtonClick()}
                                className="flexCenter activeState rounded-md p-1 w-1/3 text-white"
                                style={{
                                  height: "29px",
                                  background: "#F8B83E",
                                }}
                              >
                                Alert {sendMemberAlertBegin && <Cliploader />}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                      <div>
                        <h1
                          className="text-sm mt-6"
                          style={{ color: "#0000007a" }}
                        >
                          Curently working on
                        </h1>
                      </div>
                    </div>
                    <div
                      className="font-medium text-base bg-faq rounded-10px shadow-memberDashboard p-6  lg:min-w-70%"
                      style={{ color: "#181818" }}
                    >
                      {profileData?.currentExercises === null ? (
                        <h1 className="font-extrabold">
                          Member has completed all the reflections
                        </h1>
                      ) : (
                        <>
                          <h1 className="font-extrabold">
                            {profileData?.currentExercises?.title}
                          </h1>
                          {/* <p>{profileData?.currentExercises?.exercise}</p> */}
                          <div className="">
                            <Editor
                              editorState={EditorState.createWithContent(
                                convertFromRaw(
                                  profileData?.currentExercises?.exercise
                                )
                              )}
                              readOnly={true}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <SubNavbar id={id} />
                )}
              </>
            ) : (
              <Scaleloader />
            )}
          </div>
        </div>
      </div>
      {assignMentorModal && (
        <AssignMentorModal
          state={assignMentorModal}
          setState={setAssignMentorModal}
          mId={memId}
        />
      )}
    </div>
  );
};

export default SingleMemberDetailsPage;

const cells = ["Profile", "Payment"];
