import {
  Tell_A_FRIEND_BEGIN,
  Tell_A_FRIEND_SUCCESS,
  Tell_A_FRIEND_FAILURE,
  RESET_Tell_A_FRIEND,
  GET_FREE_LIST_PLAN_BEGIN,
  GET_FREE_PLAN_LIST_SUCCESS,
  GET_FREE_PLAN_LIST_FAILURE,
  RESET_GET_FREE_LIST_PLAN,
  CLAIM_SUBSCRIPTION_BEGIN,
  CLAIM_SUBSCRIPTION_SUCCESS,
  CLAIM_SUBSCRIPTION_FAILURE,
  RESET_CLAIM_SUBSCRIPTION,
} from "../constants/MemberReferal";
import { httpApp } from "../../axios/httpUser";
export function TellAfriend(data) {
  return (dispatch) => {
    dispatch({
      type: Tell_A_FRIEND_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/member/refer`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: Tell_A_FRIEND_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_Tell_A_FRIEND,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: Tell_A_FRIEND_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_Tell_A_FRIEND,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

// for getting the freeplanlist
export function GetFreePlanList() {
  return (dispatch) => {
    dispatch({
      type: GET_FREE_LIST_PLAN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/member/freePlanList`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_FREE_PLAN_LIST_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_FREE_LIST_PLAN,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_FREE_PLAN_LIST_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_FREE_LIST_PLAN,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

// for claimSubscription
export function ClaimSubscription(id) {
  return (dispatch) => {
    dispatch({
      type: CLAIM_SUBSCRIPTION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/member/claim-subscription/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: CLAIM_SUBSCRIPTION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CLAIM_SUBSCRIPTION,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CLAIM_SUBSCRIPTION_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_CLAIM_SUBSCRIPTION,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
