import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { localTime } from "../../MyCalendarGeneralItems";
import SeoData from "../../../utilities/seo/seoData.json";
import data from "../../../utilities/content/statusAndRoleManagement.json";
import { ListHeader } from "./ExercisesList";
import { RcTooltip, ReactHelmet, Scaleloader } from "../../../utilities";
import {
  updateMemberMeetingStatus,
  viewMemberMeeting,
} from "../../../../AppRedux/actions/MemberCalendar";
import {
  NoListToShowContainer,
  PageNumbers,
} from "../../dashboardGeneralItems";
import LoginToCometChatMeet from "../../../../pages/memberDashboard/cometChat/login";

const SessionsList = ({ memberMinutes }) => {
  const dispatch = useDispatch();
  const [upcomingMeeting, setUpcomingMeeting] = useState(false);
  const [completedMeeting, setCompletedMeeting] = useState(false);
  const [completedMeetingCount, setCompletedMeetingCount] = useState();
  const [startSessionButton, setStartSessionButton] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const headers = ["Mentor", "Date ", "Duration", ""];
  const { viewMemberMeetingSuccessData, viewMemberMeetingBegin } = useSelector(
    ({ memberCalendar }) => memberCalendar
  );
  // const memberMeetings = viewMemberMeetingSuccessData?.data?.rows;

  const [memberMeetings, setMemberMeetings] = useState("")

  useEffect(() => {
    dispatch(viewMemberMeeting(1, data.MEETING.STATUS.COMPLETED));
    dispatch(viewMemberMeeting(1, data.MEETING.STATUS.PENDING));
  }, [dispatch]);


  useEffect(() => {
    setMemberMeetings(viewMemberMeetingSuccessData?.data.rows)
  }, [viewMemberMeetingSuccessData])

  useEffect(() => {
    if (memberMeetings && memberMeetings.length > 0) {
      if (memberMeetings[0].status === data.MEETING.STATUS.PENDING) {
        const { id, meetingDate, fromTime, toTime } = memberMeetings[0];
        const toLocalTime = localTime(toTime);
        const fromLocalTime = localTime(fromTime);

        const MeetingStartingTime = moment(new Date(meetingDate))
          .hour(fromLocalTime.split(":")[0])
          .minute(fromLocalTime.split(":")[1]);
        const MeetingEndingTime = moment(new Date(meetingDate))
          .hour(toLocalTime.split(":")[0])
          .minute(toLocalTime.split(":")[1]);

        if (MeetingStartingTime.isBefore() && MeetingEndingTime.isAfter()) {
          setStartSessionButton(true);
        }

        // update meeting status to completed when meeting time is end
        if (MeetingEndingTime.isBefore()) {
          const updatedata = {
            status: data.MEETING.STATUS.COMPLETED,
          };
          dispatch(updateMemberMeetingStatus(id, updatedata));
          setUpcomingMeeting(false);
        }
      }
    }
  }, [memberMeetings, dispatch]);

  if (memberMeetings && memberMeetings?.length > 0) {
    if (memberMeetings[0].status === data.MEETING.STATUS.PENDING) {
      const { id, meetingDate, fromTime, toTime } = memberMeetings[0];
      const toLocalTime = localTime(toTime);
      const fromLocalTime = localTime(fromTime);

      const MeetingStartingTime = moment(new Date(meetingDate))
        .hour(fromLocalTime.split(":")[0])
        .minute(fromLocalTime.split(":")[1]);
      const MeetingEndingTime = moment(new Date(meetingDate))
        .hour(toLocalTime.split(":")[0])
        .minute(toLocalTime.split(":")[1]);

      var now = new Date();
      var durationToStartMeet = new Date(MeetingStartingTime) - now;
      var durationToEndMeet = new Date(MeetingEndingTime) - now;

      if (durationToStartMeet < 0) {
        durationToStartMeet += 86400000;
      }
      if (durationToEndMeet < 0) {
        durationToEndMeet += 86400000;
      }

      setTimeout(function () {
        setStartSessionButton(true);
      }, durationToStartMeet);
      setTimeout(function () {
        const updatedata = {
          status: data.MEETING.STATUS.COMPLETED,
        };
        dispatch(updateMemberMeetingStatus(id, updatedata));
        setUpcomingMeeting(false);
      }, durationToEndMeet);
    }
  }

  useEffect(() => {
    if (memberMeetings && memberMeetings.length > 0) {
      if (memberMeetings[0].status === data.MEETING.STATUS.PENDING) {
        // setting upcoming meeting data
        const { id, mentor, member, meetingDate, fromTime, toTime, status } =
          memberMeetings[0];
        const toLocalTime = localTime(toTime);
        const fromLocalTime = localTime(fromTime);
        const MeetingStartingTime = moment(new Date(meetingDate))
          .hour(fromLocalTime.split(":")[0])
          .minute(fromLocalTime.split(":")[1]);
        const MeetingEndingingTime = moment(new Date(meetingDate))
          .hour(toLocalTime.split(":")[0])
          .minute(toLocalTime.split(":")[1]);

        const meetingData = [
          {
            id,
            toTime: toLocalTime,
            meetingDate,
            status,
            userName: `${mentor.User.firstName} ${mentor.User.lastName}`,
            date: MeetingStartingTime.calendar(),
            duration: `${moment
              .duration(MeetingEndingingTime.diff(MeetingStartingTime))
              .asMinutes()} mins`,
            mentorUid: mentor.User.uid,
            memberUid: member.uid,
            memberMinutes: memberMinutes,
          },
        ];
        setUpcomingMeeting(meetingData);
      } else {
        // setting completed meeting data
        const completedMeetingData = memberMeetings.map(
          ({ mentor, meetingDate, fromTime, toTime }) => {
            const toLocalTime = localTime(toTime);
            const fromLocalTime = localTime(fromTime);
            const MeetingStartingTime = moment(new Date(meetingDate))
              .hour(fromLocalTime.split(":")[0])
              .minute(fromLocalTime.split(":")[1]);
            const MeetingEndingingTime = moment(new Date(meetingDate))
              .hour(toLocalTime.split(":")[0])
              .minute(toLocalTime.split(":")[1]);

            return {
              userName: `${mentor.User.firstName} ${mentor.User.lastName}`,
              date: MeetingStartingTime.format("MM-DD-YYYY"),
              duration: `${moment
                .duration(MeetingEndingingTime.diff(MeetingStartingTime))
                .asMinutes()} mins`,
            };
          }
        );
        setCompletedMeeting(completedMeetingData);
        setCompletedMeetingCount(viewMemberMeetingSuccessData?.data?.count);
      }
    }

  }, [memberMeetings, viewMemberMeetingSuccessData, memberMinutes]);

  return (
    <div className="px-1 950px:px-2 min-h-80vh relative pb-14">
      <ReactHelmet data={SeoData.sessions} />

      {currentPage === 1 && (
        // Upcoming sessions
        <>
          <SessionHeader
            title="Upcoming sessions"
            headers={headers}
            startSessionButtons={startSessionButton}
          />
          {viewMemberMeetingBegin ? (
            <Scaleloader />
          ) : upcomingMeeting ? (
            <SessionList
              sessions={upcomingMeeting}
              startSessionButtons={startSessionButton}
              upcomingMeeting={upcomingMeeting}
              userType="member"
            />
          ) : (
            <NoListToShowContainer text="No upcoming meeting" small={true} />
          )}
        </>
      )}

      {/* completed sessions */}
      <SessionHeader title="Completed sessions" headers={headers} />
      {viewMemberMeetingBegin ? (
        <div style={{ minHeight: "70vh" }}>
          <Scaleloader />
        </div>
      ) : completedMeeting ? (
        <SessionList sessions={completedMeeting} />
      ) : (
        <NoListToShowContainer text="No completed meetings" small={true} />
      )}
      <PageNumbers
        count={completedMeetingCount}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={viewMemberMeetingBegin}
        onPageNumberClick={(number) => {
          dispatch(viewMemberMeeting(number));
        }}
      />
    </div>
  );
};

export default SessionsList;

export const SessionHeader = ({ title, headers, startSessionButtons }) => (
  <>
    <h6 className="text-brandDarkBlue font-semibold text-lg pt-5 pb-4">
      {title}
    </h6>
    <ListHeader
      headers={headers}
      gridClass={`${startSessionButtons
        ? "grid-cols-memberSessionList"
        : "grid-cols-memberSessionListThreeItems"
        }`}
    />
  </>
);

export const SessionList = ({
  sessions,
  startSessionButtons,
  upcomingMeeting,
  userType,
}) => {

  return (
    <div className="member-table-row-items">
      {sessions.map(({ userName, date, duration }, index, sessions) => (
        <div
          key={index}
          className={`member-table-row-item  ${index === sessions.length - 1 ? "border-none" : ""
            } ${startSessionButtons
              ? "grid-cols-memberSessionList"
              : "grid-cols-memberSessionListThreeItems"
            }`}
        >
          <RcTooltip content={userName}>
            <p className="oneLineContent">{userName}</p>
          </RcTooltip>
          <p className="oneLineContent">{date}</p>
          <p>{duration}</p>
          {startSessionButtons && (
            <LoginToCometChatMeet
              meetingData={upcomingMeeting}
              userType={userType}
            />
          )}
        </div>
      ))}
    </div>
  );
};
