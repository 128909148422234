import {
  GET_CLIENT_LIST_BEGIN,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAILURE,
  RESET_GET_CLIENT_LIST,
  GET_CLIENT_EXERCISE_BEGIN,
  GET_CLIENT_EXERCISE_SUCCESS,
  GET_CLIENT_EXERCISE_FAILURE,
  RESET_GET_CLIENT_EXERCISE,
  GET_CLIENT_ASSESSMENT_BEGIN,
  GET_CLIENT_ASSESSMENT_SUCCESS,
  GET_CLIENT_ASSESSMENT_FAILURE,
  RESET_GET_CLIENT_ASSESSMENT,
  ADD_CLIENT_NOTES_BEGIN,
  ADD_CLIENT_NOTES_SUCCESS,
  ADD_CLIENT_NOTES_FAILURE,
  RESET_ADD_CLIENT_NOTES,
  VIEW_CLIENT_NOTES_BEGIN,
  VIEW_CLIENT_NOTES_SUCCESS,
  VIEW_CLIENT_NOTES_FAILURE,
  RESET_VIEW_CLIENT_NOTES,
  EDIT_CLIENT_NOTE_BEGIN,
  EDIT_CLIENT_NOTE_SUCCESS,
  EDIT_CLIENT_NOTE_FAILURE,
  RESET_EDIT_CLIENT_NOTE,
  DELETE_CLIENT_NOTE_BEGIN,
  DELETE_CLIENT_NOTE_SUCCESS,
  DELETE_CLIENT_NOTE_FAILURE,
  RESET_DELETE_CLIENT_NOTE,
  ADD_CLIENT_TASKS_BEGIN,
  ADD_CLIENT_TASKS_SUCCESS,
  ADD_CLIENT_TASKS_FAILURE,
  RESET_ADD_CLIENT_TASKS,
  VIEW_CLIENT_TASKS_BEGIN,
  VIEW_CLIENT_TASKS_SUCCESS,
  VIEW_CLIENT_TASKS_FAILURE,
  RESET_VIEW_CLIENT_TASKS,
  ASSESSMENT_DOWNLOAD_BEGIN,
  ASSESSMENT_DOWNLOAD_SUCCESS,
  ASSESSMENT_DOWNLOAD_FAILURE,
  RESET_ASSESSMENT_DOWNLOAD,
} from "../constants/MentorMyClients";

import { httpApp } from "../../axios/httpUser";

/**
 * get client lists
 */

export function getClientList(pageNumber = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_LIST_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/mentor/clients?page=${pageNumber}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_CLIENT_LIST_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_CLIENT_LIST,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_CLIENT_LIST_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_CLIENT_LIST,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * get client exercise by id
 */

export function getClientExercise(pageNumber, id) {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_EXERCISE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/mentor/clients/${id}/exercise?page=${pageNumber}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_CLIENT_EXERCISE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_CLIENT_EXERCISE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_CLIENT_EXERCISE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_CLIENT_EXERCISE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * get client assessment by id
 */

export function getClientAssessment(id) {
  return (dispatch) => {
    dispatch({
      type: GET_CLIENT_ASSESSMENT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/mentor/clients/${id}/assessments`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_CLIENT_ASSESSMENT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_CLIENT_ASSESSMENT,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_CLIENT_ASSESSMENT_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_CLIENT_ASSESSMENT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * add client notes
 */

export function addClientNotes(id, data) {
  return (dispatch) => {
    dispatch({
      type: ADD_CLIENT_NOTES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/mentor/clients/${id}/note`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: ADD_CLIENT_NOTES_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ADD_CLIENT_NOTES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ADD_CLIENT_NOTES_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_ADD_CLIENT_NOTES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * view client notes by id
 */

export function viewClientNotes(pageNumber = 1, id) {
  return (dispatch) => {
    dispatch({
      type: VIEW_CLIENT_NOTES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/mentor/clients/${id}/note?page=${pageNumber}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_CLIENT_NOTES_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_CLIENT_NOTES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_CLIENT_NOTES_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_CLIENT_NOTES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * view client notes by id
 */

export function editClientNote(id, data) {
  return (dispatch) => {
    dispatch({
      type: EDIT_CLIENT_NOTE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/mentor/clients/note/${id}`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_CLIENT_NOTE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_CLIENT_NOTE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_CLIENT_NOTE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_EDIT_CLIENT_NOTE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * delete clients note by id
 */

export function deleteClientNote(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_CLIENT_NOTE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/mentor/clients/note/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_CLIENT_NOTE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_CLIENT_NOTE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_CLIENT_NOTE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_CLIENT_NOTE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * add client tasks
 */
export function addClientTask(id, data) {
  return (dispatch) => {
    dispatch({
      type: ADD_CLIENT_TASKS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/mentor/clients/${id}/task`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: ADD_CLIENT_TASKS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ADD_CLIENT_TASKS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ADD_CLIENT_TASKS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_ADD_CLIENT_TASKS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * view client tasks
 */

export function viewClientTasks(pageNumber = 1, id) {
  return (dispatch) => {
    dispatch({
      type: VIEW_CLIENT_TASKS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/mentor/clients/${id}/task?page=${pageNumber}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_CLIENT_TASKS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_CLIENT_TASKS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_CLIENT_TASKS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_CLIENT_TASKS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * download assessment
 */
export function downloadAssessment(id) {
  return (dispatch) => {
    dispatch({
      type: ASSESSMENT_DOWNLOAD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/mentor/clients/${id}/assessments/download`,
        {
          headers: {
            "Content-Type": "application/pdf",
          },
          responseType: "arraybuffer",
        }
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: ASSESSMENT_DOWNLOAD_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ASSESSMENT_DOWNLOAD,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ASSESSMENT_DOWNLOAD_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_ASSESSMENT_DOWNLOAD,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
