import {
  GET_MEMBER_CALENDAR_PREFERENCE_BEGIN,
  GET_MEMBER_CALENDAR_PREFERENCE_SUCCESS,
  GET_MEMBER_CALENDAR_PREFERENCE_FAILURE,
  RESET_GET_MEMBER_CALENDAR_PREFERENCE,
  SCHEDULE_MEETING_WITH_MENTOR_BEGIN,
  SCHEDULE_MEETING_WITH_MENTOR_SUCCESS,
  SCHEDULE_MEETING_WITH_MENTOR_FAILURE,
  RESET_SCHEDULE_MEETING_WITH_MENTOR,
  VIEW_MEMBER_MEETING_BEGIN,
  VIEW_MEMBER_MEETING_SUCCESS,
  VIEW_MEMBER_MEETING_FAILURE,
  RESET_VIEW_MEMBER_MEETING,
  CANCEL_MEMBER_MEETING_BEGIN,
  CANCEL_MEMBER_MEETING_SUCCESS,
  CANCEL_MEMBER_MEETING_FAILURE,
  RESET_CANCEL_MEMBER_MEETING,
  UPDATE_MEMBER_MEETING_STATUS_BEGIN,
  UPDATE_MEMBER_MEETING_STATUS_SUCCESS,
  UPDATE_MEMBER_MEETING_STATUS_FAILURE,
  RESET_UPDATE_MEMBER_MEETING_STATUS,
} from "../constants/MemberCalendar";

import { httpApp } from "../../axios/httpUser";

//get member calendar preference
export function getMemberCalendarPreference(month, year, mentorId) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_CALENDAR_PREFERENCE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `mentor/${mentorId}/calendar?month=${month}&year=${year}`
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_CALENDAR_PREFERENCE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_CALENDAR_PREFERENCE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_CALENDAR_PREFERENCE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_CALENDAR_PREFERENCE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//schedule meeting with mentor
export function scheduleMeetingWithMentor(data) {

  return (dispatch) => {
    dispatch({
      type: SCHEDULE_MEETING_WITH_MENTOR_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`meeting/schedule`, data);
      doRequest.then(
        (res) => {
          dispatch({
            type: SCHEDULE_MEETING_WITH_MENTOR_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SCHEDULE_MEETING_WITH_MENTOR,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SCHEDULE_MEETING_WITH_MENTOR_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SCHEDULE_MEETING_WITH_MENTOR,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//view member meeting
export function viewMemberMeeting(pageNumber = 1, status = 1) {
  return (dispatch) => {
    dispatch({
      type: VIEW_MEMBER_MEETING_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `meeting/member?status=${status}&page=${pageNumber}&limit=10&orderBy=createdAt&order=desc`
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_MEMBER_MEETING_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_MEMBER_MEETING,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_MEMBER_MEETING_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_MEMBER_MEETING,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//cancel member meeting
export function cancelMemberMeeting(meetingId, slotId, timezone) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_MEMBER_MEETING_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(
        `/meeting/${meetingId}/cancel?slot=${slotId}&timezone=${timezone}`
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: CANCEL_MEMBER_MEETING_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CANCEL_MEMBER_MEETING,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CANCEL_MEMBER_MEETING_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CANCEL_MEMBER_MEETING,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//update member meeting status
export function updateMemberMeetingStatus(meetingId, data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_MEETING_STATUS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/meeting/status/${meetingId}`, data);
      doRequest.then(
        (res) => {
          dispatch({
            type: UPDATE_MEMBER_MEETING_STATUS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UPDATE_MEMBER_MEETING_STATUS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UPDATE_MEMBER_MEETING_STATUS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UPDATE_MEMBER_MEETING_STATUS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
