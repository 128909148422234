import {
  SUBMIT_MENTOR_REGISTRATION_FORM_BEGIN,
  SUBMIT_MENTOR_REGISTRATION_FORM_SUCCESS,
  SUBMIT_MENTOR_REGISTRATION_FORM_FAILURE,
  RESET_SUBMIT_MENTOR_REGISTRATION_FORM,
  MENTOR_TOKEN_VERIFICATION_BEGIN,
  MENTOR_TOKEN_VERIFICATION_FAILURE,
  MENTOR_TOKEN_VERIFICATION_SUCCESS,
  RESET_MENTOR_TOKEN_VERIFICATION,
} from "../constants/MentorRegistration";

import { httpApp } from "../../axios/httpUser";

//  submit mentor registration form
export function submitMentorRegistrationForm(data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MENTOR_REGISTRATION_FORM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/signup/mentor`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MENTOR_REGISTRATION_FORM_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MENTOR_REGISTRATION_FORM,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MENTOR_REGISTRATION_FORM_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SUBMIT_MENTOR_REGISTRATION_FORM,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//mentor onboard token verification

export function mentorVerifyToken(data) {
  return (dispatch) => {
    dispatch({
      type: MENTOR_TOKEN_VERIFICATION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/verify`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: MENTOR_TOKEN_VERIFICATION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_MENTOR_TOKEN_VERIFICATION,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: MENTOR_TOKEN_VERIFICATION_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_MENTOR_TOKEN_VERIFICATION,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
