import { useMemo, useEffect } from "react";

import Navbar from "../components/pageComponents/Navbar";
import LeftSideWelcomeSection from "../components/pageComponents/LeftSideWelcomeSection";
import LoginForm from "../components/pageComponents/Login/LoginForm";
import SeoData from "../components/utilities/seo/seoData.json";
import statusData from "../components/utilities/content/statusAndRoleManagement.json";
import {
  ReactHelmet,
  ProgressLoader,
  progressLoaderFunction,
  SunFooter,
} from "../components/utilities";

const MentorLogin = () => {
  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.mentorLogin} noShadow={true} />
          <ProgressLoader />
          <Navbar whiteNavbar={true} fixedNavbar={false} onlyTopBar={true} />
          <section className="w-full lg:flex min-h-90vh">
            <LeftSideWelcomeSection />
            <LoginForm role={statusData.ROLES.MENTOR} />
          </section>
          <SunFooter />
        </>
      ),
      []
    );

  return <Content />;
};

export default MentorLogin;
