import {
  GET_MENTORS_LIST_BEGIN,
  GET_MENTORS_LIST_SUCCESS,
  GET_MENTORS_LIST_FAILURE,
  RESET_GET_MENTORS_LIST,
  GET_MENTOR_FEEDBACK_BEGIN,
  GET_MENTOR_FEEDBACK_SUCCESS,
  GET_MENTOR_FEEDBACK_FAILURE,
  RESET_GET_MENTOR_FEEDBACK,
  APPROVE_MENTOR_REGISTRATION_BEGIN,
  APPROVE_MENTOR_REGISTRATION_SUCCESS,
  APPROVE_MENTOR_REGISTRATION_FAILURE,
  RESET_APPROVE_MENTOR_REGISTRATION,
  REJECT_MENTOR_REGISTRATION_BEGIN,
  REJECT_MENTOR_REGISTRATION_SUCCESS,
  REJECT_MENTOR_REGISTRATION_FAILURE,
  RESET_REJECT_MENTOR_REGISTRATION,
  GET_SINGLE_MENTOR_DETAILS_BEGIN,
  GET_SINGLE_MENTOR_DETAILS_SUCCESS,
  GET_SINGLE_MENTOR_DETAILS_FAILURE,
  RESET_GET_SINGLE_MENTOR_DETAILS,
  GET_MENTOR_SESSION_DETAILS_BEGIN,
  GET_MENTOR_SESSION_DETAILS_SUCCESS,
  GET_MENTOR_SESSION_DETAILS_FAILURE,
  RESET_GET_MENTOR_SESSION_DETAILS,
  BLOCK_SINGLE_MENTOR_BEGIN,
  BLOCK_SINGLE_MENTOR_SUCCESS,
  BLOCK_SINGLE_MENTOR_FAILURE,
  RESET_BLOCK_SINGLE_MENTOR,
  GET_BLOCKED_MENTORS_BEGIN,
  GET_BLOCKED_MENTORS_SUCCESS,
  GET_BLOCKED_MENTORS_FAILURE,
  RESET_GET_BLOCKED_MENTORS,
  UNBLOCK_SINGLE_MENTOR_BEGIN,
  UNBLOCK_SINGLE_MENTOR_SUCCESS,
  UNBLOCK_SINGLE_MENTOR_FAILURE,
  RESET_UNBLOCK_SINGLE_MENTOR,
  APPROVE_MENTOR_BACKGROUND_VERIFICATION_BEGIN,
  APPROVE_MENTOR_BACKGROUND_VERIFICATION_SUCCESS,
  APPROVE_MENTOR_BACKGROUND_VERIFICATION_FAILURE,
  RESET_APPROVE_MENTOR_BACKGROUND_VERIFICATION,
  DELETE_MENTOR_BEGIN,
  DELETE_MENTOR_SUCCESS,
  DELETE_MENTOR_FAILURE,
  RESET_DELETE_MENTOR,
} from "../constants/AdminMentor";

const initialState = {
  getMentorsListBegin: false,
  getMentorsListSuccess: false,
  getMentorsListSuccessData: null,
  getMentorsListFailure: false,
  getMentorsListFailureData: null,

  getMentorFeedbackBegin: false,
  getMentorFeedbackSuccess: false,
  getMentorFeedbackSuccessData: null,
  getMentorFeedbackFailure: false,
  getMentorFeedbackFailureData: null,

  approveMentorRegistrationBegin: false,
  approveMentorRegistrationSuccess: false,
  approveMentorRegistrationSuccessData: null,
  approveMentorRegistrationFailure: false,
  approveMentorRegistrationFailureData: null,

  rejectMentorRegistrationBegin: false,
  rejectMentorRegistrationSuccess: false,
  rejectMentorRegistrationSuccessData: null,
  rejectMentorRegistrationFailure: false,
  rejectMentorRegistrationFailureData: null,

  getSingleMentorDetailsBegin: false,
  getSingleMentorDetailsSuccess: false,
  getSingleMentorDetailsSuccessData: null,
  getSingleMentorDetailsFailure: false,
  getSingleMentorDetailsFailureData: null,

  getMentorSessionDetailsBegin: false,
  getMentorSessionDetailsSuccess: false,
  getMentorSessionDetailsSuccessData: null,
  getMentorSessionDetailsFailure: false,
  getMentorSessionDetailsFailureData: null,

  blockSingleMentorBegin: false,
  blockSingleMentorSuccess: false,
  blockSingleMentorSuccessData: null,
  blockSingleMentorFailure: false,
  blockSingleMentorFailureData: null,

  getBlockedMentorsBegin: false,
  getBlockedMentorsSuccess: false,
  getBlockedMentorsSuccessData: null,
  getBlockedMentorsFailure: false,
  getBlockedMentorsFailureData: null,

  unblockSingleMentorBegin: false,
  unblockSingleMentorSuccess: false,
  unblockSingleMentorSuccessData: null,
  unblockSingleMentorFailure: false,
  unblockSingleMentorFailureData: null,

  approveMentorBackroundVerificationBegin: false,
  approveMentorBackroundVerificationSuccess: false,
  approveMentorBackroundVerificationSuccessData: null,
  approveMentorBackroundVerificationFailure: false,
  approveMentorBackroundVerificationFailureData: null,

  deleteMentorBegin: false,
  deleteMentorSuccess: false,
  deleteMentorSuccessData: null,
  deleteMentorFailure: false,
  deleteMentorFailureData: null,
};

export const adminMentor = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // get mentors list
    case GET_MENTORS_LIST_BEGIN:
      return {
        ...state,
        getMentorsListBegin: true,
        getMentorsListSuccess: false,
        getMentorsListSuccessData: null,
        getMentorsListFailure: false,
        getMentorsListFailureData: null,
      };

    case GET_MENTORS_LIST_SUCCESS:
      return {
        ...state,
        getMentorsListBegin: false,
        getMentorsListSuccess: true,
        getMentorsListSuccessData: data,
        getMentorsListFailure: false,
        getMentorsListFailureData: null,
      };

    case GET_MENTORS_LIST_FAILURE:
      return {
        ...state,
        getMentorsListBegin: false,
        getMentorsListSuccess: false,
        getMentorsListSuccessData: null,
        getMentorsListFailure: true,
        getMentorsListFailureData: data,
      };

    case RESET_GET_MENTORS_LIST:
      return {
        ...state,
        getMentorsListBegin: false,
        getMentorsListSuccess: false,
        getMentorsListSuccessData: null,
        getMentorsListFailure: false,
        getMentorsListFailureData: null,
      };

    // get mentors list
    case GET_MENTOR_FEEDBACK_BEGIN:
      return {
        ...state,
        getMentorFeedbackBegin: true,
        getMentorFeedbackSuccess: false,
        getMentorFeedbackSuccessData: null,
        getMentorFeedbackFailure: false,
        getMentorFeedbackFailureData: null,
      };

    case GET_MENTOR_FEEDBACK_SUCCESS:
      return {
        ...state,
        getMentorFeedbackBegin: false,
        getMentorFeedbackSuccess: true,
        getMentorFeedbackSuccessData: data,
        getMentorFeedbackFailure: false,
        getMentorFeedbackFailureData: null,
      };

    case GET_MENTOR_FEEDBACK_FAILURE:
      return {
        ...state,
        getMentorFeedbackBegin: false,
        getMentorFeedbackSuccess: false,
        getMentorFeedbackSuccessData: null,
        getMentorFeedbackFailure: true,
        getMentorFeedbackFailureData: data,
      };

    case RESET_GET_MENTOR_FEEDBACK:
      return {
        ...state,
        getMentorFeedbackBegin: false,
        getMentorFeedbackSuccess: false,
        getMentorFeedbackSuccessData: null,
        getMentorFeedbackFailure: false,
        getMentorFeedbackFailureData: null,
      };

    //approve mentor manually
    case APPROVE_MENTOR_REGISTRATION_BEGIN:
      return {
        ...state,
        approveMentorRegistrationBegin: true,
        approveMentorRegistrationSuccess: false,
        approveMentorRegistrationSuccessData: false,
        approveMentorRegistrationFailure: false,
        approveMentorRegistrationFailureData: false,
      };

    case APPROVE_MENTOR_REGISTRATION_SUCCESS:
      return {
        ...state,
        approveMentorRegistrationBegin: false,
        approveMentorRegistrationSuccess: true,
        approveMentorRegistrationSuccessData: data,
        approveMentorRegistrationFailure: false,
        approveMentorRegistrationFailureData: false,
      };

    case APPROVE_MENTOR_REGISTRATION_FAILURE:
      return {
        ...state,
        approveMentorRegistrationBegin: false,
        approveMentorRegistrationSuccess: false,
        approveMentorRegistrationSuccessData: false,
        approveMentorRegistrationFailure: true,
        approveMentorRegistrationFailureData: data,
      };

    case RESET_APPROVE_MENTOR_REGISTRATION:
      return {
        ...state,
        approveMentorRegistrationBegin: false,
        approveMentorRegistrationSuccess: false,
        approveMentorRegistrationSuccessData: false,
        approveMentorRegistrationFailure: false,
        approveMentorRegistrationFailureData: false,
      };

    //reject mentor registration

    case REJECT_MENTOR_REGISTRATION_BEGIN:
      return {
        ...state,
        rejectMentorRegistrationBegin: true,
        rejectMentorRegistrationSuccess: false,
        rejectMentorRegistrationSuccessData: false,
        rejectMentorRegistrationFailure: false,
        rejectMentorRegistrationFailureData: null,
      };

    case REJECT_MENTOR_REGISTRATION_SUCCESS:
      return {
        ...state,
        rejectMentorRegistrationBegin: false,
        rejectMentorRegistrationSuccess: true,
        rejectMentorRegistrationSuccessData: data,
        rejectMentorRegistrationFailure: false,
        rejectMentorRegistrationFailureData: null,
      };

    case REJECT_MENTOR_REGISTRATION_FAILURE:
      return {
        ...state,
        rejectMentorRegistrationBegin: false,
        rejectMentorRegistrationSuccess: false,
        rejectMentorRegistrationSuccessData: false,
        rejectMentorRegistrationFailure: true,
        rejectMentorRegistrationFailureData: data,
      };

    case RESET_REJECT_MENTOR_REGISTRATION:
      return {
        ...state,
        rejectMentorRegistrationBegin: false,
        rejectMentorRegistrationSuccess: false,
        rejectMentorRegistrationSuccessData: null,
        rejectMentorRegistrationFailure: false,
        rejectMentorRegistrationFailureData: null,
      };

    //get single mentor details

    case GET_SINGLE_MENTOR_DETAILS_BEGIN:
      return {
        ...state,
        getSingleMentorDetailsBegin: true,
        getSingleMentorDetailsSuccess: false,
        getSingleMentorDetailsSuccessData: false,
        getSingleMentorDetailsFailure: false,
        getSingleMentorDetailsFailureData: false,
      };

    case GET_SINGLE_MENTOR_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleMentorDetailsBegin: false,
        getSingleMentorDetailsSuccess: true,
        getSingleMentorDetailsSuccessData: data,
        getSingleMentorDetailsFailure: false,
        getSingleMentorDetailsFailureData: false,
      };

    case GET_SINGLE_MENTOR_DETAILS_FAILURE:
      return {
        ...state,
        getSingleMentorDetailsBegin: false,
        getSingleMentorDetailsSuccess: false,
        getSingleMentorDetailsSuccessData: false,
        getSingleMentorDetailsFailure: true,
        getSingleMentorDetailsFailureData: data,
      };

    case RESET_GET_SINGLE_MENTOR_DETAILS:
      return {
        ...state,
        getSingleMentorDetailsBegin: false,
        getSingleMentorDetailsSuccess: false,
        getSingleMentorDetailsSuccessData: false,
        getSingleMentorDetailsFailure: false,
        getSingleMentorDetailsFailureData: false,
      };

    //get mentor session details

    case GET_MENTOR_SESSION_DETAILS_BEGIN:
      return {
        ...state,
        getMentorSessionDetailsBegin: true,
        getMentorSessionDetailsSuccess: false,
        getMentorSessionDetailsSuccessData: null,
        getMentorSessionDetailsFailure: false,
        getMentorSessionDetailsFailureData: null,
      };

    case GET_MENTOR_SESSION_DETAILS_SUCCESS:
      return {
        ...state,
        getMentorSessionDetailsBegin: false,
        getMentorSessionDetailsSuccess: true,
        getMentorSessionDetailsSuccessData: data,
        getMentorSessionDetailsFailure: false,
        getMentorSessionDetailsFailureData: null,
      };

    case GET_MENTOR_SESSION_DETAILS_FAILURE:
      return {
        ...state,
        getMentorSessionDetailsBegin: false,
        getMentorSessionDetailsSuccess: false,
        getMentorSessionDetailsSuccessData: null,
        getMentorSessionDetailsFailure: true,
        getMentorSessionDetailsFailureData: data,
      };

    case RESET_GET_MENTOR_SESSION_DETAILS:
      return {
        ...state,
        getMentorSessionDetailsBegin: false,
        getMentorSessionDetailsSuccess: false,
        getMentorSessionDetailsSuccessData: null,
        getMentorSessionDetailsFailure: false,
        getMentorSessionDetailsFailureData: null,
      };

    //block single mentor

    case BLOCK_SINGLE_MENTOR_BEGIN:
      return {
        ...state,
        blockSingleMentorBegin: true,
        blockSingleMentorSuccess: false,
        blockSingleMentorSuccessData: null,
        blockSingleMentorFailure: false,
        blockSingleMentorFailureData: null,
      };

    case BLOCK_SINGLE_MENTOR_SUCCESS:
      return {
        ...state,
        blockSingleMentorBegin: false,
        blockSingleMentorSuccess: true,
        blockSingleMentorSuccessData: data,
        blockSingleMentorFailure: false,
        blockSingleMentorFailureData: null,
      };

    case BLOCK_SINGLE_MENTOR_FAILURE:
      return {
        ...state,
        blockSingleMentorBegin: false,
        blockSingleMentorSuccess: false,
        blockSingleMentorSuccessData: null,
        blockSingleMentorFailure: true,
        blockSingleMentorFailureData: data,
      };

    case RESET_BLOCK_SINGLE_MENTOR:
      return {
        ...state,
        blockSingleMentorBegin: false,
        blockSingleMentorSuccess: false,
        blockSingleMentorSuccessData: null,
        blockSingleMentorFailure: false,
        blockSingleMentorFailureData: null,
      };

    //get blocked mentors list

    case GET_BLOCKED_MENTORS_BEGIN:
      return {
        ...state,
        getBlockedMentorsBegin: true,
        getBlockedMentorsSuccess: false,
        getBlockedMentorsSuccessData: null,
        getBlockedMentorsFailure: false,
        getBlockedMentorsFailureData: null,
      };

    case GET_BLOCKED_MENTORS_SUCCESS:
      return {
        ...state,
        getBlockedMentorsBegin: false,
        getBlockedMentorsSuccess: true,
        getBlockedMentorsSuccessData: data,
        getBlockedMentorsFailure: false,
        getBlockedMentorsFailureData: null,
      };

    case GET_BLOCKED_MENTORS_FAILURE:
      return {
        ...state,
        getBlockedMentorsBegin: false,
        getBlockedMentorsSuccess: false,
        getBlockedMentorsSuccessData: null,
        getBlockedMentorsFailure: true,
        getBlockedMentorsFailureData: data,
      };

    case RESET_GET_BLOCKED_MENTORS:
      return {
        ...state,
        getBlockedMentorsBegin: false,
        getBlockedMentorsSuccess: false,
        getBlockedMentorsSuccessData: null,
        getBlockedMentorsFailure: false,
        getBlockedMentorsFailureData: null,
      };

    //unblock mentor

    case UNBLOCK_SINGLE_MENTOR_BEGIN:
      return {
        ...state,
        unblockSingleMentorBegin: true,
        unblockSingleMentorSuccess: false,
        unblockSingleMentorSuccessData: null,
        unblockSingleMentorFailure: false,
        unblockSingleMentorFailureData: null,
      };

    case UNBLOCK_SINGLE_MENTOR_SUCCESS:
      return {
        ...state,
        unblockSingleMentorBegin: false,
        unblockSingleMentorSuccess: true,
        unblockSingleMentorSuccessData: data,
        unblockSingleMentorFailure: false,
        unblockSingleMentorFailureData: null,
      };

    case UNBLOCK_SINGLE_MENTOR_FAILURE:
      return {
        ...state,
        unblockSingleMentorBegin: false,
        unblockSingleMentorSuccess: false,
        unblockSingleMentorSuccessData: null,
        unblockSingleMentorFailure: true,
        unblockSingleMentorFailureData: data,
      };

    case RESET_UNBLOCK_SINGLE_MENTOR:
      return {
        ...state,
        unblockSingleMentorBegin: false,
        unblockSingleMentorSuccess: false,
        unblockSingleMentorSuccessData: null,
        unblockSingleMentorFailure: false,
        unblockSingleMentorFailureData: null,
      };

    //mentor background verification

    case APPROVE_MENTOR_BACKGROUND_VERIFICATION_BEGIN:
      return {
        ...state,
        approveMentorBackroundVerificationBegin: true,
        approveMentorBackroundVerificationSuccess: false,
        approveMentorBackroundVerificationSuccessData: null,
        approveMentorBackroundVerificationFailure: false,
        approveMentorBackroundVerificationFailureData: null,
      };

    case APPROVE_MENTOR_BACKGROUND_VERIFICATION_SUCCESS:
      return {
        ...state,
        approveMentorBackroundVerificationBegin: false,
        approveMentorBackroundVerificationSuccess: true,
        approveMentorBackroundVerificationSuccessData: data,
        approveMentorBackroundVerificationFailure: false,
        approveMentorBackroundVerificationFailureData: null,
      };

    case APPROVE_MENTOR_BACKGROUND_VERIFICATION_FAILURE:
      return {
        ...state,
        approveMentorBackroundVerificationBegin: false,
        approveMentorBackroundVerificationSuccess: false,
        approveMentorBackroundVerificationSuccessData: null,
        approveMentorBackroundVerificationFailure: true,
        approveMentorBackroundVerificationFailureData: data,
      };

    case RESET_APPROVE_MENTOR_BACKGROUND_VERIFICATION:
      return {
        ...state,
        approveMentorBackroundVerificationBegin: false,
        approveMentorBackroundVerificationSuccess: false,
        approveMentorBackroundVerificationSuccessData: null,
        approveMentorBackroundVerificationFailure: false,
        approveMentorBackroundVerificationFailureData: null,
      };

    //delete mentor
    case DELETE_MENTOR_BEGIN:
      return {
        ...state,
        deleteMentorBegin: true,
        deleteMentorSuccess: false,
        deleteMentorSuccessData: null,
        deleteMentorFailure: false,
        deleteMentorFailureData: null,
      };

    case DELETE_MENTOR_SUCCESS:
      return {
        ...state,
        deleteMentorBegin: false,
        deleteMentorSuccess: true,
        deleteMentorSuccessData: data,
        deleteMentorFailure: false,
        deleteMentorFailureData: null,
      };

    case DELETE_MENTOR_FAILURE:
      return {
        ...state,
        deleteMentorBegin: false,
        deleteMentorSuccess: false,
        deleteMentorSuccessData: null,
        deleteMentorFailure: true,
        deleteMentorFailureData: data,
      };

    case RESET_DELETE_MENTOR:
      return {
        ...state,
        deleteMentorBegin: false,
        deleteMentorSuccess: false,
        deleteMentorSuccessData: null,
        deleteMentorFailure: false,
        deleteMentorFailureData: null,
      };

    default:
      return state;
  }
};
