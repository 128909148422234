import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Scaleloader } from "../../utilities";
import ModalOuter from "../../utilities/modals/ModalOuter";
import { ModalHeader } from "../MyCalendarGeneralItems";

const ChatWidgetModal = ({
  state,
  setState,
  mentorUid,
  header,
  defaultUid,
}) => {
  const [loader, setLoader] = useState(true);
  const { getAppResourcesSuccessData } = useSelector(
    ({ homeReducer }) => homeReducer
  );

  useEffect(() => {
    window.CometChatWidget.init({
      appID: getAppResourcesSuccessData?.cometAppId,
      appRegion: "us",
      authKey: getAppResourcesSuccessData?.cometAuthKey,
    }).then(
      () => {
        window.CometChatWidget.login({
          uid: mentorUid,
        }).then(() => {
          // comet chat widget launch
          window.CometChatWidget.launch({
            widgetID: "5e724e26-111b-42bb-8610-4e255a89edad",
            alignment: "left", //left or right
            target: "#cometchatManager",
            roundedCorners: "true",
            height: "400px",
            width: "500px",
            defaultID: defaultUid,
            defaultType: "user",
          }).then((response) => {
            setLoader(false);
          });
        });
      },
      (error) => {
        console.log("Initialization failed with error:", error);
      }
    );
  }, [mentorUid, defaultUid, getAppResourcesSuccessData]);

  return (
    <ModalOuter
      state={state}
      setState={setState}
      classNames="p-7 py-4 text-left animate-modalInitial min-w-200px 500px:min-w-470px"
    >
      <ModalHeader heading={header} closeIcon={true} setState={setState} />
      {loader && (
        <div className="h-80">
          <Scaleloader />
        </div>
      )}
      <div id="cometchatManager" />
    </ModalOuter>
  );
};

export default ChatWidgetModal;
