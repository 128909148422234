import {
  SUBMIT_MEMBER_LOGIN_FORM_BEGIN,
  SUBMIT_MEMBER_LOGIN_FORM_SUCCESS,
  SUBMIT_MEMBER_LOGIN_FORM_FAILURE,
  RESET_SUBMIT_MEMBER_LOGIN_FORM,
  MEMBER_GOOGLE_LOGIN_BEGIN,
  MEMBER_GOOGLE_LOGIN_SUCCESS,
  MEMBER_GOOGLE_LOGIN_FAILURE,
  RESET_MEMBER_GOOGLE_LOGIN,
  VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_BEGIN,
  VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_SUCCESS,
  VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP_FAILURE,
  RESET_VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP,
  SET_NEW_PASSWORD_BEGIN,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  RESET_SET_NEW_PASSWORD,
  MEMBER_FACEBOOK_LOGIN_BEGIN,
  MEMBER_FACEBOOK_LOGIN_SUCCESS,
  MEMBER_FACEBOOK_LOGIN_FAILURE,
  RESET_MEMBER_FACEBOOK_LOGIN,
  SUBMIT_USER_LOGOUT_BEGIN,
  SUBMIT_USER_LOGOUT_SUCCESS,
  SUBMIT_USER_LOGOUT_FAILURE,
  RESET_SUBMIT_USER_LOGOUT,
  CANCEL_SUBSCRIPTION_BEGIN,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  RESET_CANCEL_SUBSCRIPTION,
} from "../constants/MemberLogin";

const initialState = {
  submitMemberLoginFormBegin: false,
  submitMemberLoginFormSuccess: false,
  submitMemberLoginFormSuccessData: null,
  submitMemberLoginFormFailure: false,
  submitMemberLoginFormFailureData: null,

  submitMemberGoogleLoginFormBegin: false,
  submitMemberGoogleLoginFormSuccess: false,
  submitMemberGoogleLoginFormSuccessData: null,
  submitMemberGoogleLoginFormFailure: false,
  submitMemberGoogleLoginFormFailureData: null,

  submitMemberFacebookLoginFormBegin: false,
  submitMemberFacebookLoginFormSuccess: false,
  submitMemberFacebookLoginFormSuccessData: null,
  submitMemberFacebookLoginFormFailure: false,
  submitMemberFacebookLoginFormFailureData: null,

  verifyMemberLoginForgetPasswordOtpBegin: false,
  verifyMemberLoginForgetPasswordOtpSuccess: false,
  verifyMemberLoginForgetPasswordOtpSuccessData: null,
  verifyMemberLoginForgetPasswordOtpFailure: false,
  verifyMemberLoginForgetPasswordOtpFailureData: null,

  setNewPaswordBegin: false,
  setNewPasswordSuccess: false,
  setNewPasswordSuccessData: null,
  setNewPasswordFailure: false,
  setNewPasswordFailureData: null,

  submitUserLogoutBegin: false,
  submitUserLogoutSuccess: false,
  submitUserLogoutSuccessData: null,
  submitUserLogoutFailure: false,
  submitUserLogoutFailureData: null,

  cancelSubscriptionBegin: false,
  cancelSubscriptionSuccess: false,
  cancelSubscriptionSuccessData: null,
  cancelSubscriptionFailure: false,
  cancelSubscriptionFailureData: null,
};

export const memberLogin = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // submitting member login form
    case SUBMIT_MEMBER_LOGIN_FORM_BEGIN:
      return {
        ...state,
        submitMemberLoginFormBegin: true,
        submitMemberLoginFormSuccess: false,
        submitMemberLoginFormSuccessData: null,
        submitMemberLoginFormFailure: false,
        submitMemberLoginFormFailureData: null,
      };
    case SUBMIT_MEMBER_LOGIN_FORM_SUCCESS:
      return {
        ...state,
        submitMemberLoginFormBegin: false,
        submitMemberLoginFormSuccess: true,
        submitMemberLoginFormSuccessData: data,
        submitMemberLoginFormFailure: false,
        submitMemberLoginFormFailureData: null,
      };
    case SUBMIT_MEMBER_LOGIN_FORM_FAILURE:
      return {
        ...state,
        submitMemberLoginFormBegin: false,
        submitMemberLoginFormSuccess: false,
        submitMemberLoginFormSuccessData: null,
        submitMemberLoginFormFailure: true,
        submitMemberLoginFormFailureData: data,
      };
    case RESET_SUBMIT_MEMBER_LOGIN_FORM:
      return {
        ...state,
        submitMemberLoginFormBegin: false,
        submitMemberLoginFormSuccess: false,
        submitMemberLoginFormSuccessData: null,
        submitMemberLoginFormFailure: false,
        submitMemberLoginFormFailureData: null,
      };

    // Google login
    case MEMBER_GOOGLE_LOGIN_BEGIN:
      return {
        ...state,
        submitMemberGoogleLoginFormBegin: true,
        submitMemberGoogleLoginFormSuccess: false,
        submitMemberGoogleLoginFormSuccessData: null,
        submitMemberGoogleLoginFormFailure: false,
        submitMemberGoogleLoginFormFailureData: null,
      };
    case MEMBER_GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        submitMemberGoogleLoginFormBegin: false,
        submitMemberGoogleLoginFormSuccess: true,
        submitMemberGoogleLoginFormSuccessData: data,
        submitMemberGoogleLoginFormFailure: false,
        submitMemberGoogleLoginFormFailureData: null,
      };
    case MEMBER_GOOGLE_LOGIN_FAILURE:
      return {
        ...state,
        submitMemberGoogleLoginFormBegin: false,
        submitMemberGoogleLoginFormSuccess: false,
        submitMemberGoogleLoginFormSuccessData: null,
        submitMemberGoogleLoginFormFailure: true,
        submitMemberGoogleLoginFormFailureData: data,
      };
    case RESET_MEMBER_GOOGLE_LOGIN:
      return {
        ...state,
        submitMemberGoogleLoginFormBegin: false,
        submitMemberGoogleLoginFormSuccess: false,
        submitMemberGoogleLoginFormSuccessData: null,
        submitMemberGoogleLoginFormFailure: false,
        submitMemberGoogleLoginFormFailureData: null,
      };

    //Facebook Login
    case MEMBER_FACEBOOK_LOGIN_BEGIN:
      return {
        ...state,
        submitMemberFacebookLoginFormBegin: true,
        submitMemberFacebookLoginFormSuccess: false,
        submitMemberFacebookLoginFormSuccessData: null,
        submitMemberFacebookLoginFormFailure: false,
        submitMemberFacebookLoginFormFailureData: null,
      };
    case MEMBER_FACEBOOK_LOGIN_SUCCESS:
      return {
        ...state,
        submitMemberFacebookLoginFormBegin: false,
        submitMemberFacebookLoginFormSuccess: true,
        submitMemberFacebookLoginFormSuccessData: data,
        submitMemberFacebookLoginFormFailure: false,
        submitMemberFacebookLoginFormFailureData: null,
      };
    case MEMBER_FACEBOOK_LOGIN_FAILURE:
      return {
        ...state,
        submitMemberFacebookLoginFormBegin: false,
        submitMemberFacebookLoginFormSuccess: true,
        submitMemberFacebookLoginFormSuccessData: null,
        submitMemberFacebookLoginFormFailure: true,
        submitMemberFacebookLoginFormFailureData: data,
      };
    case RESET_MEMBER_FACEBOOK_LOGIN:
      return {
        ...state,
        submitMemberFacebookLoginFormBegin: false,
        submitMemberFacebookLoginFormSuccess: false,
        submitMemberFacebookLoginFormSuccessData: null,
        submitMemberFacebookLoginFormFailure: false,
        submitMemberFacebookLoginFormFailureData: null,
      };

    // Verify forgot password otp
    case VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_BEGIN:
      return {
        ...state,
        verifyMemberLoginForgetPasswordOtpBegin: true,
        verifyMemberLoginForgetPasswordOtpSuccess: false,
        verifyMemberLoginForgetPasswordOtpSuccessData: null,
        verifyMemberLoginForgetPasswordOtpFailure: false,
        verifyMemberLoginForgetPasswordOtpFailureData: null,
      };
    case VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        verifyMemberLoginForgetPasswordOtpBegin: false,
        verifyMemberLoginForgetPasswordOtpSuccess: true,
        verifyMemberLoginForgetPasswordOtpSuccessData: data,
        verifyMemberLoginForgetPasswordOtpFailure: false,
        verifyMemberLoginForgetPasswordOtpFailureData: null,
      };
    case VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP_FAILURE:
      return {
        ...state,
        verifyMemberLoginForgetPasswordOtpBegin: false,
        verifyMemberLoginForgetPasswordOtpSuccess: false,
        verifyMemberLoginForgetPasswordOtpSuccessData: null,
        verifyMemberLoginForgetPasswordOtpFailure: true,
        verifyMemberLoginForgetPasswordOtpFailureData: data,
      };
    case RESET_VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP:
      return {
        ...state,
        verifyMemberLoginForgetPasswordOtpBegin: false,
        verifyMemberLoginForgetPasswordOtpSuccess: false,
        verifyMemberLoginForgetPasswordOtpSuccessData: null,
        verifyMemberLoginForgetPasswordOtpFailure: false,
        verifyMemberLoginForgetPasswordOtpFailureData: null,
      };

    // Set new password
    case SET_NEW_PASSWORD_BEGIN:
      return {
        ...state,
        setNewPaswordBegin: true,
        setNewPasswordSuccess: false,
        setNewPasswordSuccessData: null,
        setNewPasswordFailure: false,
        setNewPasswordFailureData: null,
      };
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        setNewPaswordBegin: false,
        setNewPasswordSuccess: true,
        setNewPasswordSuccessData: data,
        setNewPasswordFailure: false,
        setNewPasswordFailureData: null,
      };
    case SET_NEW_PASSWORD_FAILURE:
      return {
        ...state,
        setNewPaswordBegin: false,
        setNewPasswordSuccess: false,
        setNewPasswordSuccessData: null,
        setNewPasswordFailure: true,
        setNewPasswordFailureData: data,
      };
    case RESET_SET_NEW_PASSWORD:
      return {
        ...state,
        setNewPaswordBegin: false,
        setNewPasswordSuccess: false,
        setNewPasswordSuccessData: null,
        setNewPasswordFailure: false,
        setNewPasswordFailureData: null,
      };

    // user logout
    case SUBMIT_USER_LOGOUT_BEGIN:
      return {
        ...state,
        submitUserLogoutBegin: true,
        submitUserLogoutSuccess: false,
        submitUserLogoutSuccessData: null,
        submitUserLogoutFailure: false,
        submitUserLogoutFailureData: null,
      };
    case SUBMIT_USER_LOGOUT_SUCCESS:
      return {
        ...state,
        submitUserLogoutBegin: false,
        submitUserLogoutSuccess: true,
        submitUserLogoutSuccessData: data,
        submitUserLogoutFailure: false,
        submitUserLogoutFailureData: null,
      };
    case SUBMIT_USER_LOGOUT_FAILURE:
      return {
        ...state,
        submitUserLogoutBegin: false,
        submitUserLogoutSuccess: false,
        submitUserLogoutSuccessData: null,
        submitUserLogoutFailure: true,
        submitUserLogoutFailureData: data,
      };
    case RESET_SUBMIT_USER_LOGOUT:
      return {
        ...state,
        submitUserLogoutBegin: false,
        submitUserLogoutSuccess: false,
        submitUserLogoutSuccessData: null,
        submitUserLogoutFailure: false,
        submitUserLogoutFailureData: null,
      };

    //cancel Subscription
    case CANCEL_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        cancelSubscriptionBegin: true,
        cancelSubscriptionSuccess: false,
        cancelSubscriptionSuccessData: null,
        cancelSubscriptionFailure: false,
        cancelSubscriptionFailureData: null,
      };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        cancelSubscriptionBegin: false,
        cancelSubscriptionSuccess: true,
        cancelSubscriptionSuccessData: data,
        cancelSubscriptionFailure: false,
        cancelSubscriptionFailureData: null,
      };
    case CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        cancelSubscriptionBegin: false,
        cancelSubscriptionSuccess: false,
        cancelSubscriptionSuccessData: null,
        cancelSubscriptionFailure: true,
        cancelSubscriptionFailureData: data,
      };
    case RESET_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscriptionBegin: false,
        cancelSubscriptionSuccess: false,
        cancelSubscriptionSuccessData: null,
        cancelSubscriptionFailure: false,
        cancelSubscriptionFailureData: null,
      };

    default:
      return state;
  }
};
