import {
  SUBSCRIBE_NEWS_LETTER_BEGIN,
  SUBSCRIBE_NEWS_LETTER_SUCCESS,
  SUBSCRIBE_NEWS_LETTER_FAILURE,
  RESET_SUBSCRIBE_NEWS_LETTER,
  SUBMIT_CONTACT_FORM_BEGIN,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_FAILURE,
  RESET_SUBMIT_CONTACT_FORM,
} from "../constants/NewsLetterAndContact";

import { httpApp } from "../../axios/httpUser";

/**
 * For subscribe newsletter
 */
export function subscribeNewsLetter(data) {
  return (dispatch) => {
    dispatch({
      type: SUBSCRIBE_NEWS_LETTER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/home/newsletter`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBSCRIBE_NEWS_LETTER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBSCRIBE_NEWS_LETTER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBSCRIBE_NEWS_LETTER_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBSCRIBE_NEWS_LETTER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * Submit contact form
 */
export function submitContactForm(data = {}) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_CONTACT_FORM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/contactUs`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_CONTACT_FORM_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_CONTACT_FORM,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_CONTACT_FORM_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_CONTACT_FORM,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
