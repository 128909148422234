import {
  SUBMIT_MENTOR_ONBOARD_FORM_BEGIN,
  SUBMIT_MENTOR_ONBOARD_FORM_SUCCESS,
  SUBMIT_MENTOR_ONBOARD_FORM_FAILURE,
  RESET_SUBMIT_MENTOR_ONBOARD_FORM,
} from "../constants/MentorLogin";

const initialState = {
  submitMentorOnboardFormBegin: false,
  submitMentorOnboardFormSuccess: false,
  submitMentorOnboardFormSuccessData: null,
  submitMentorOnboardFormFailure: false,
  submitMentorOnboardFormFailureData: null,

};

export const mentorLogin = (state = initialState, action) => {
  const data = action.data;
  switch (action.type) {
    // Mentor onboard
    case SUBMIT_MENTOR_ONBOARD_FORM_BEGIN:
      return {
        ...state,
        submitMentorOnboardFormBegin: true,
        submitMentorOnboardFormSuccess: false,
        submitMentorOnboardFormSuccessData: null,
        submitMentorOnboardFormFailure: false,
        submitMentorOnboardFormFailureData: null,
      };
    case SUBMIT_MENTOR_ONBOARD_FORM_SUCCESS:
      return {
        ...state,
        submitMentorOnboardFormBegin: false,
        submitMentorOnboardFormSuccess: true,
        submitMentorOnboardFormSuccessData: data,
        submitMentorOnboardFormFailure: false,
        submitMentorOnboardFormFailureData: null,
      };
    case SUBMIT_MENTOR_ONBOARD_FORM_FAILURE:
      return {
        ...state,
        submitMentorOnboardFormBegin: false,
        submitMentorOnboardFormSuccess: false,
        submitMentorOnboardFormSuccessData: null,
        submitMentorOnboardFormFailure: true,
        submitMentorOnboardFormFailureData: data,
      };
    case RESET_SUBMIT_MENTOR_ONBOARD_FORM:
      return {
        ...state,
        submitMentorOnboardFormBegin: false,
        submitMentorOnboardFormSuccess: false,
        submitMentorOnboardFormSuccessData: null,
        submitMentorOnboardFormFailure: false,
        submitMentorOnboardFormFailureData: null,
      };

    default:
      return state;
  }
};
