import React from "react";
import { MentorThankYouSun } from "../../utilities/svg";

const MentorThankYouPage = () => {
  return (
    <section className="flexCenter flex-col homepageWidth min-h-90vh">
      <MentorThankYouSun />
      <h1 className="font-semibold text-brandDarkBlue text-2xl 370px:text-3xl sm:text-4xl  xl:text-5xl text-center w-96% lg:w-2/3 mx-auto my-6">
        Thank you for your interest in becoming a Merity mentor
      </h1>
      <p
        className="font-medium text-brandGrey text-lg md:text-2xl text-center w-96% 500px:w-3/4 lg:w-1/2
         mx-auto"
      >
        We look forward to learning more about you. You can expect to hear from
        us soon.
      </p>
    </section>
  );
};

export default MentorThankYouPage;
