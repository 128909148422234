import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SetUpNewPasswordForm from "../Login/SetUpNewPasswordForm";
import SuccessModal from "../../utilities/modals/SuccessModal";
import PageData from "../../utilities/content/pages.json";
import MemberRegistrationVerifyOTPForm from "../MemberRegisterForms/MemberRegistrationVerifyOTPForm";
import { resendMemberRegistrationOtp } from "../../../AppRedux/actions/MemberRegistration";
import {
  FormButton,
  Cliploader,
  formResponseToasting,
  InputGroup,
} from "../../utilities";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formError, setFormError] = useState({});
  const [changeToNextPage, setChangeToNextPage] = useState("");
  const { heading, data } = PageData.memberLoginPageData.forgotPasswordForm;
  const memberRegistration = useSelector(
    ({ memberRegistration }) => memberRegistration
  );
  const [userForgotPassowrdData, setUserForgotPassowrdData] = useState({
    email: "",
    otp: "",
  });
  const [member_email, setMember_email] = useState("");
  let { resendUserRegisterOtpBegin, resendUserRegisterOtpFailureData } =
    memberRegistration;

  useEffect(() => {
    document.querySelector(`input[name='member_register_email']`)?.focus();
  }, []);

  useEffect(() => {
    let { resendUserRegisterOtpSuccessData } = memberRegistration;

    if (resendUserRegisterOtpSuccessData) {
      setUserForgotPassowrdData({
        ...userForgotPassowrdData,
        email: resendUserRegisterOtpSuccessData?.data?.email,
      });
      resendUserRegisterOtpSuccessData = {
        ...resendUserRegisterOtpSuccessData,
        message: "Successfully sent OTP to your email address and mobile number",
      };

      setChangeToNextPage("verify_otp_page");
    }

    formResponseToasting(
      resendUserRegisterOtpSuccessData,
      resendUserRegisterOtpFailureData
    );
  }, [
    resendUserRegisterOtpFailureData,
    userForgotPassowrdData,
    memberRegistration,
  ]);

  const customFormValidation = () => {
    const validationErrors = {};

    if (member_email?.trim() === "") {
      validationErrors.member_email = "Email is required.";
      document.querySelector("input[name='member_email']").focus();
    }
    if (
      member_email?.trim() !== "" &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(member_email)
    ) {
      validationErrors.member_email = "Please enter a valid email address!";
      document.querySelector("input[name='member_email']").focus();
    }

    return validationErrors;
  };

  const onSubmitForgotPasswordForm = (e) => {
    e.preventDefault();
    const validationErrors = customFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      dispatch(resendMemberRegistrationOtp(member_email));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const handleInputOnchange = (e) => {
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setMember_email(e.target.value);
  };

  if (changeToNextPage === "verify_otp_page") {
    return (
      <MemberRegistrationVerifyOTPForm
        changeToNextPage={setChangeToNextPage}
        mail={userForgotPassowrdData.email}
        currentPage="member_forgot_Password"
        setOtp={setUserForgotPassowrdData}
        removeURLParam={true}
      />
    );
  }

  if (changeToNextPage === "set_new_password") {
    return (
      <SetUpNewPasswordForm
        changeToNextPage={setChangeToNextPage}
        userForgotPassowrdData={userForgotPassowrdData}
      />
    );
  }

  if (changeToNextPage === "reset_password_successfull") {
    return <SuccessModal  redirect={true} content={PageData.memberLoginPageData.successModal} />;
  }

  return (
    <div
      className="lg:w-1/2 py-4 md:py-12 lg:py-36 px-4 sm:px-24"
      style={{ backgroundColor: "#fafafa" }}
    >
      <h2 className="font-medium text-2xl sm:text-28px mb-2">{heading}</h2>
      <p style={{ color: "#828289" }} className="font-normal">
        {data}
      </p>
      <form
        className="w-full xl:w-10/12 2xl:w-3/4 mt-10"
        onSubmit={(e) => onSubmitForgotPasswordForm(e)}
      >
        <InputGroup
          label="Enter your registered Email"
          name="member_email"
          onChange={handleInputOnchange}
          value={member_email}
          errorMessage={formError?.member_email}
        />

        <div className="flex gap-6 mt-12 mb-8 lg:mb-0">
          <span
            onClick={() => navigate(-1)}
            className="activeState mx-auto cursor-pointer flexCenter w-full gap-2 font-semibold text-lg py-3 bg-white text-brandBlue border border-brandBlue"
          >
            Cancel
          </span>
          <FormButton
            disabled={resendUserRegisterOtpBegin}
            classNames={`bg-brandBlue  text-white ${
              resendUserRegisterOtpBegin ? "cursor-wait" : "buttonHover"
            }`}
          >
            Send OTP
            {resendUserRegisterOtpBegin && <Cliploader />}
          </FormButton>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
