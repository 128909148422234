import {
  CREATE_COMET_CHAT_USER_BEGIN,
  CREATE_COMET_CHAT_USER_SUCCESS,
  CREATE_COMET_CHAT_USER_FAILURE,
  RESET_CREATE_COMET_CHAT_USER,
  UPDATE_COMET_CHAT_USER_BEGIN,
  UPDATE_COMET_CHAT_USER_SUCCESS,
  UPDATE_COMET_CHAT_USER_FAILURE,
  RESET_UPDATE_COMET_CHAT_USER,
} from "../constants/MemberCometChat";

import { httpApp } from "../../axios/httpUser";

//create comet chat user
export function createCometChatUser(apiKey, data) {
  return (dispatch) => {
    dispatch({
      type: CREATE_COMET_CHAT_USER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(
        `https://2065716199de4935.api-us.cometchat.io/v3/users`,
        data,
        {
          headers: {
            apiKey: apiKey,
          },
        }
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: CREATE_COMET_CHAT_USER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CREATE_COMET_CHAT_USER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CREATE_COMET_CHAT_USER_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CREATE_COMET_CHAT_USER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//update comet chat user
export function updateCometChatUser(apiKey, uid, data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COMET_CHAT_USER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(
        `https://2065716199de4935.api-us.cometchat.io/v3/users/${uid}`,
        data,
        {
          headers: {
            apiKey: apiKey,
          },
        }
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: UPDATE_COMET_CHAT_USER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UPDATE_COMET_CHAT_USER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UPDATE_COMET_CHAT_USER_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UPDATE_COMET_CHAT_USER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
