import React from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import AdminCreateStories from "../../components/pageComponents/adminDashboard/AdminCreateStories";

const AdminStories = () => {
  return (
    <AdminDashboardLayout>
      <AdminCreateStories />
    </AdminDashboardLayout>
  );
};

export default AdminStories;
