import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import OutsideClickCloseContainer from "../../utilities/OutsideClickCloseContainer";
import { BellIconWithoutNotification } from "../../utilities/svg";
import { RcTooltip } from "../../utilities";
import NotificationDropdown from "./NotificationDropdown";
import {
  getMemberNotifications,
  unreadAllNotifications,
} from "../../../AppRedux/actions/MemberDashboard";

const MemberNotificationContainer = () => {
  const dispatch = useDispatch(),
    [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false),
    [notificationPageNumber, setNotificationPageNumber] = useState(1),
    [unreadMessageCount, setUnreadMessageCount] = useState(0),
    [notificationIconfirstClick, setNotificationIconfirstClick] =
      useState(true),
    { getMemberNotificationsSuccessData } = useSelector(
      ({ memberDashboard }) => memberDashboard
    );

  useEffect(() => {
    if (getMemberNotificationsSuccessData) {
      setUnreadMessageCount(getMemberNotificationsSuccessData?.data.unread);
    }
  }, [getMemberNotificationsSuccessData]);

  // on notification icon click
  const onBellIconClick = () => {
    setNotificationDropdownOpen(!notificationDropdownOpen);
    if (notificationIconfirstClick) {
      dispatch(getMemberNotifications(notificationPageNumber));
      setNotificationIconfirstClick(!notificationIconfirstClick);
    } else {
      // when close the notification modal will mark all notification as readed
      readAllNotifications();
    }
  };

  const readAllNotifications = () => {
    if (unreadMessageCount !== 0) {
      dispatch(unreadAllNotifications());
      setUnreadMessageCount(0);
    }
    setNotificationPageNumber(1);
    setNotificationIconfirstClick(!notificationIconfirstClick);
  };

  return (
    <OutsideClickCloseContainer
      setState={setNotificationDropdownOpen}
      OutsideClickFunction={
        notificationIconfirstClick ? null : readAllNotifications
      }
    >
      <RcTooltip content="Notifications">
        <div
          className={`mr-2 500px:mr-6 950px:mr-14 dashboardIcon ${
            notificationDropdownOpen ? "bg-brandBlue text-white" : ""
          }`}
          onClick={() => onBellIconClick()}
        >
          <BellIconWithoutNotification className="fill-current" />
          <div
            className={`absolute w-1.5 h-1.5 rounded-full top-2 right-10px ${
              unreadMessageCount > 0 ? "block" : "hidden"
            }`}
            style={{ backgroundColor: "#FF3F3F" }}
          />
        </div>
      </RcTooltip>
      {notificationDropdownOpen && (
        <NotificationDropdown
          readAllNotifications={readAllNotifications}
          unreadMessageCount={unreadMessageCount}
        />
      )}
    </OutsideClickCloseContainer>
  );
};

export default MemberNotificationContainer;
