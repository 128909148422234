import React, { useEffect, useMemo } from "react";

import Navbar from "../components/pageComponents/Navbar";
import Footer from "../components/pageComponents/footer";
import MentorAndAboutPagesLanding from "../components/pageComponents/MentorAndAboutPagesLanding";

import SeoData from "../components/utilities/seo/seoData.json";
import {
  ProgressLoader,
  progressLoaderFunction,
  ReactHelmet,
} from "../components/utilities";

const About = () => {
  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.aboutPageSeoData} />
          <ProgressLoader />
          <Navbar whiteNavbar={false} fixedNavbar={true} />
          <MentorAndAboutPagesLanding />
          <Footer />
        </>
      ),
      []
    );

  return <Content />;
};

export default About;
