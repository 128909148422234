export const GET_MENTORS_LIST_BEGIN = "GET_MENTORS_LIST_BEGIN";
export const GET_MENTORS_LIST_SUCCESS = "GET_MENTORS_LIST_SUCCESS";
export const GET_MENTORS_LIST_FAILURE = "GET_MENTORS_LIST_FAILURE";
export const RESET_GET_MENTORS_LIST = "RESET_GET_MENTORS_LIST";

export const GET_MENTOR_FEEDBACK_BEGIN = "GET_MENTOR_FEEDBACK_BEGIN";
export const GET_MENTOR_FEEDBACK_SUCCESS = "GET_MENTOR_FEEDBACK_SUCCESS";
export const GET_MENTOR_FEEDBACK_FAILURE = "GET_MENTOR_FEEDBACK_FAILURE";
export const RESET_GET_MENTOR_FEEDBACK = "RESET_GET_MENTOR_FEEDBACK";

export const APPROVE_MENTOR_REGISTRATION_BEGIN =
  "APPROVE_MENTOR_REGISTRATION_BEGIN";
export const APPROVE_MENTOR_REGISTRATION_SUCCESS =
  "APPROVE_MENTOR_REGISTRATION_SUCCESS";
export const APPROVE_MENTOR_REGISTRATION_FAILURE =
  "APPROVE_MENTOR_REGISTRATION_FAILURE";
export const RESET_APPROVE_MENTOR_REGISTRATION =
  "RESET_APPROVE_MENTOR_REGISTRATION";

export const REJECT_MENTOR_REGISTRATION_BEGIN =
  "REJECT_MENTOR_REGISTRATION_BEGIN";
export const REJECT_MENTOR_REGISTRATION_SUCCESS =
  "REJECT_MENTOR_REGISTRATION_SUCCESS";
export const REJECT_MENTOR_REGISTRATION_FAILURE =
  "REJECT_MENTOR_REGISTRATION_FAILURE";
export const RESET_REJECT_MENTOR_REGISTRATION =
  "RESET_REJECT_MENTOR_REGISTRATION";

export const GET_SINGLE_MENTOR_DETAILS_BEGIN =
  "GET_SINGLE_MENTOR_DETAILS_BEGIN";
export const GET_SINGLE_MENTOR_DETAILS_SUCCESS =
  "GET_SINGLE_MENTOR_DETAILS_SUCCESS";
export const GET_SINGLE_MENTOR_DETAILS_FAILURE =
  "GET_SINGLE_MENTOR_DETAILS_FAILURE";
export const RESET_GET_SINGLE_MENTOR_DETAILS =
  "RESET_GET_SINGLE_MENTOR_DETAILS";

export const GET_MENTOR_SESSION_DETAILS_BEGIN =
  "GET_MENTOR_SESSION_DETAILS_BEGIN";
export const GET_MENTOR_SESSION_DETAILS_SUCCESS =
  "GET_MENTOR_SESSION_DETAILS_SUCCESS";
export const GET_MENTOR_SESSION_DETAILS_FAILURE =
  "GET_MENTOR_SESSION_DETAILS_FAILURE";
export const RESET_GET_MENTOR_SESSION_DETAILS =
  "RESET_GET_MENTOR_SESSION_DETAILS";

export const BLOCK_SINGLE_MENTOR_BEGIN = "BLOCK_SINGLE_MENTOR_BEGIN";
export const BLOCK_SINGLE_MENTOR_SUCCESS = "BLOCK_SINGLE_MENTOR_SUCCESS";
export const BLOCK_SINGLE_MENTOR_FAILURE = "BLOCK_SINGLE_MENTOR_FAILURE";
export const RESET_BLOCK_SINGLE_MENTOR = "RESET_BLOCK_SINGLE_MENTOR";

export const GET_BLOCKED_MENTORS_BEGIN = "GET_BLOCKED_MENTORS_BEGIN";
export const GET_BLOCKED_MENTORS_SUCCESS = "GET_BLOCKED_MENTORS_SUCCESS";
export const GET_BLOCKED_MENTORS_FAILURE = "GET_BLOCKED_MENTORS_FAILURE";
export const RESET_GET_BLOCKED_MENTORS = "RESET_GET_BLOCKED_MENTORS";

export const UNBLOCK_SINGLE_MENTOR_BEGIN = "UNBLOCK_SINGLE_MENTOR_BEGIN";
export const UNBLOCK_SINGLE_MENTOR_SUCCESS = "UNBLOCK_SINGLE_MENTOR_SUCCESS";
export const UNBLOCK_SINGLE_MENTOR_FAILURE = "UNBLOCK_SINGLE_MENTOR_FAILURE";
export const RESET_UNBLOCK_SINGLE_MENTOR = "RESET_UNBLOCK_SINGLE_MENTOR";

export const APPROVE_MENTOR_BACKGROUND_VERIFICATION_BEGIN =
  "APPROVE_MENTOR_BACKGROUND_VERIFICATION_BEGIN";
export const APPROVE_MENTOR_BACKGROUND_VERIFICATION_SUCCESS =
  "APPROVE_MENTOR_BACKGROUND_VERIFICATION_SUCCESS";
export const APPROVE_MENTOR_BACKGROUND_VERIFICATION_FAILURE =
  "APPROVE_MENTOR_BACKGROUND_VERIFICATION_FAILURE";
export const RESET_APPROVE_MENTOR_BACKGROUND_VERIFICATION =
  "RESET_APPROVE_MENTOR_BACKGROUND_VERIFICATION";

export const DELETE_MENTOR_BEGIN = "DELETE_MENTOR_BEGIN";
export const DELETE_MENTOR_SUCCESS = "DELETE_MENTOR_SUCCESS";
export const DELETE_MENTOR_FAILURE = "DELETE_MENTOR_FAILURE";
export const RESET_DELETE_MENTOR = "RESET_DELETE_MENTOR";
