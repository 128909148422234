import { useMemo, useState, useEffect } from "react";

import Navbar from "../components/pageComponents/Navbar";
import LeftSideWelcomeSection from "../components/pageComponents/LeftSideWelcomeSection";
import MemberBasicInformationForm from "../components/pageComponents/MemberRegisterForms/MemberBasicInformationForm";
import MemberRegistrationVerifyOTPForm from "../components/pageComponents/MemberRegisterForms/MemberRegistrationVerifyOTPForm";
import SeoData from "../components/utilities/seo/seoData.json";

import {
  alertUserForReload,
  ReactHelmet,
  ProgressLoader,
  progressLoaderFunction,
  SunFooter,
} from "../components/utilities";

const Join = () => {
  const [page, setPage] = useState("basic_details_page");
  const [memberRegisterData, setMemberRegisterData] = useState({});
  const [, setContinueButtonloader] = useState(false);
  const [, setBackButtonLoader] = useState(false);


  useEffect(() => {
    progressLoaderFunction();
  });


  useEffect(() => {
    if (
      page !== "basic_details_page" &&
      page !== "member_registration_success_page"
    ) {
      window.addEventListener("beforeunload", alertUserForReload);
    }
    return () => {
      if (
        page !== "basic_details_page" &&
        page !== "member_registration_success_page"
      ) {
        window.removeEventListener("beforeunload", alertUserForReload);
      }
    };
  }, [page]);

  const onSubmitEachForm = (nextPage, pageData = {}) => {
    setContinueButtonloader(true);
    setMemberRegisterData({ ...memberRegisterData, ...pageData });
    setPage(nextPage);
    setContinueButtonloader(false);
  };

  const onBackButtonClick = (nextPage) => {
    setBackButtonLoader(true);
    setPage(nextPage);
    setBackButtonLoader(false);
  };


  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.joinPageSeoData} />
          <ProgressLoader />
          <Navbar
            whiteNavbar={true}
            fixedNavbar={false}
            noShadow={true}
            onlyTopBar={true}
          />
          <section className="w-full lg:flex min-h-90vh">
            <LeftSideWelcomeSection />
            {page === "basic_details_page" ? (
              <MemberBasicInformationForm
                changeToNextPage={onSubmitEachForm}
                memberRegisterData={memberRegisterData}
              />
            ) : page === "registration_verify_otp_page" && (
              <MemberRegistrationVerifyOTPForm
                changeToNextPage={onBackButtonClick}
                mail={memberRegisterData.member_register_email}
              />
            )}
          </section>
          <SunFooter />
        </>
      ),
      []
    );

  return <Content />;
};

export default Join;
