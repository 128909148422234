import React from "react";
import { useSearchParams } from "react-router-dom";

const SubTabs = ({ selectedTab, cells, type }) => {
  const [params, setParams] = useSearchParams();
  const id = params.getAll("id").toString();
  const cell = params.getAll("cell").toString();

  return (
    <div
      className={`border-b border-solid border-faq w-full flex justify-start pt-8 ${
        cell === "profile" ||
        cell === "feedback" ||
        cell === "sessions" ||
        cell === "subscription"
          ? "space-x-6"
          : "bg-white space-x-8 px-8"
      }`}
      style={{ color: "#013B5D" }}
    >
      {cells.map((singlecell, index) => (
        <div key={index}>
          <p
            onClick={() => {
              cell
                ? setParams({
                    cell: singlecell.toLowerCase(),
                    id: id,
                  })
                : setParams({
                    tab: singlecell.toLowerCase(),
                    id: id,
                  });
            }}
            className={`font-medium text-lg mx-4 cursor-pointer activeState transition duration-100 ease ${
              selectedTab === singlecell.toLocaleLowerCase()
                ? " font-bold text-adminProfileSelected hover:text-blue-400 "
                : ""
            }`}
          >
            {singlecell === "Payment" && type === "admin"
              ? "Subscription"
              : singlecell}
          </p>
          {selectedTab === singlecell.toLocaleLowerCase() && (
            <div
              className="rounded-sm mt-2"
              style={{
                width: "100%",
                borderColor: "#1079A8",
                backgroundColor: "#007CA5",
                borderWidth: "2px",
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SubTabs;
