export const GET_APP_STORIES_BEGIN = "GET_APP_STORIES_BEGIN";
export const GET_SET_OF_STORIES_SUCCESS = "GET_SET_OF_STORIES_SUCCESS";
export const GET_SET_OF_STORIES_FAILURE = "GET_SET_OF_STORIES_FAILURE";
export const RESET_APP_STORIES_DATA = "RESET_APP_STORIES_DATA";

export const GET_APP_RESOURCES_BEGIN = "GET_APP_RESOURCES_BEGIN";
export const GET_APP_RESOURCES_SUCCESS = "GET_APP_RESOURCES_SUCCESS";
export const GET_APP_RESOURCES_FAILURE = "GET_APP_RESOURCES_FAILURE";
export const RESET_GET_APP_RESOURCES = "RESET_GET_APP_RESOURCES";

export const GET_COUNTRIES_RESOURCES_BEGIN = "GET_COUNTRIES_RESOURCES_BEGIN";
export const GET_COUNTRIES_RESOURCES_SUCCESS =
  "GET_COUNTRIES_RESOURCES_SUCCESS";
export const GET_COUNTRIES_RESOURCES_FAILURE =
  "GET_COUNTRIES_RESOURCES_FAILURE";
export const RESET_GET_COUNTRIES_RESOURCES = "RESET_GET_COUNTRIES_RESOURCES";

export const GET_STATES_BEGIN = "GET_STATES_BEGIN";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";
export const RESET_GET_STATES = "RESET_GET_STATES";
