import React from "react";

import { url } from "../../../utilities/generalItems";
import { FormButton, Scaleloader } from "../../../utilities";
import { FileIcon, RatingIcon } from "../../../utilities/svg";
import { CommonLeftSideProfileCard } from "../AdminGeneralComponents";

const AdminMentorLeftSide = ({ data, loader }) => {
  return (
    <div
      className="rounded-10px shadow-memberDashboard bg-white member-leftside-profile-card"
      style={{ color: "#003547" }}
    >
      {!loader && data ? (
        <>
          <CommonLeftSideProfileCard data={data} />
          <div className="border-b-2 border-faq mt-3 mb-4" />
          <h6 className="mx-4 font-bold text-sm text-black lg:text-sm mb-2">
            Review
          </h6>
          <div className="flex items-center font-semibold mx-4 space-x-1 text-xs lg:text-sm">
            <RatingIcon className="" />
            {data?.mentorship?.rating === null ? (
              <span className="-mt-1">No ratings yet</span>
            ) : (
              <p className="-mt-1">
                {data?.mentorship?.rating.toFixed(2)}
                <span className="text-brandGrey">
                  ({data?.mentorship?.counts} Reviews)
                </span>
              </p>
            )}
          </div>
          <div className="border-b-2 border-faq mt-3 mb-4" />
          <h6 className="mx-4 font-semibold text-xs lg:text-sm">About</h6>
          <p className="p-4 text-xs lg:text-sm text-black font-medium">
            {data?.mentorship?.statement}
          </p>
          <div className="border-b-2 border-faq mt-3 mb-4" />
          <FormButton
            classNames={`bg-brandBlue mt-6 text-white w-4/5 text-sm my-8`}
            onClick={() =>
              window.open(
                `${url}/file?path=${data?.mentorship?.resume}`,
                "_blank"
              )
            }
          >
            <FileIcon />
            {`View ${data?.firstName}'s Resume`}
          </FormButton>
        </>
      ) : (
        <Scaleloader />
      )}
    </div>
  );
};

export default AdminMentorLeftSide;
