import React, { useState, useEffect } from "react";

import SeoData from "../../components/utilities/seo/seoData.json";
import MentorLeftSideProfileCard from "../../components/pageComponents/mentorDashboard/MentorLeftSideProfileCard";
import { CalendarBackArrow } from "../../components/pageComponents/MyCalendarGeneralItems";
import { useSearchParams, useNavigate } from "react-router-dom";
import MemberNavbar from "../../components/pageComponents/memberDashboard/MemberNavbar";
import mentorPageData from "../../components/utilities/content/mentorDashboard.json";
import Navbar from "../../components/pageComponents/memberDashboard/memberProfile/Navbar";
import MentorProfileInfo from "../../components/pageComponents/mentorDashboard/MentorProfileInfo";
import { ReactHelmet } from "../../components/utilities";

const MentorProfile = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const tab = params.getAll("tab").toString();
  const [dashboardData] = useState();

  useEffect(() => {
    if (tab === "") {
      setParams({
        tab: "my-info",
      });
    }
    if (tab !== "") {
      if (tab !== "my-info" && tab !== "banking-info") {
        setParams({
          tab: "my-info",
        });
      }
    }
  }, [tab, setParams]);



  return (
    <div className="min-h-screen" style={{ backgroundColor: "#f3f3f3" }}>
      <ReactHelmet data={SeoData.profile} />
      <MemberNavbar dropdownData={mentorPageData.navbar.dropdownList} />
      <div className="w-96% xl:w-10/12 mx-auto pt-7 pb-8 500px:pb-16 ">
        <div className="flex gap-6">
          <MentorLeftSideProfileCard />
          <div className="overflow-x-scroll 950px:overflow-x-auto relative bg-white rounded-10px shadow-memberDashboard w-full h-full">
            <div style={{ minWidth: "650px" }}>
              <div className="pt-4 pl-4">
                <CalendarBackArrow
                  onClick={() => navigate("/mentor/dashboard")}
                />
              </div>
              <Navbar
                selectedTab={tab}
                dashboardData={dashboardData}
                type="mentor"
              />
              {tab === "my-info" ? <MentorProfileInfo /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorProfile;
