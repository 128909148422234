import {
  SUBSCRIBE_MEMBER_PLAN_BEGIN,
  SUBSCRIBE_MEMBER_PLAN_SUCCESS,
  SUBSCRIBE_MEMBER_PLAN_FAILURE,
  RESET_SUBSCRIBE_MEMBER_PLAN,
  GET_SUBSCRIPTION_PALNS_BEGIN,
  GET_SUBSCRIPTION_PALNS_SUCCESS,
  GET_SUBSCRIPTION_PALNS_FAILURE,
  RESET_GET_SUBSCRIPTION_PALNS,
  VALIDATE_SUBSCRIPTION_COUPON_BEGIN,
  VALIDATE_SUBSCRIPTION_COUPON_SUCCESS,
  VALIDATE_SUBSCRIPTION_COUPON_FAILURE,
  RESET_VALIDATE_SUBSCRIPTION_COUPON,
} from "../constants/MemberPlanSubscription";

const initialState = {
  subscribeMemberPlanBegin: false,
  subscribeMemberPlanSuccess: false,
  subscribeMemberPlanSuccessData: null,
  subscribeMemberPlanFailure: false,
  subscribeMemberPlanFailureData: null,

  getSubscriptionPlanBegin: false,
  getSubscriptionPlanSuccess: false,
  getSubscriptionPlanSuccessData: null,
  getSubscriptionPlanFailure: false,
  getSubscriptionPlanFailureData: null,

  validateSubscriptionCouponBegin: false,
  validateSubscriptionCouponSuccess: false,
  validateSubscriptionCouponSuccessData: null,
  validateSubscriptionCouponFailure: false,
  validateSubscriptionCouponFailureData: null,
};

export const memberPlanSubscription = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // get subscribe plan data
    case GET_SUBSCRIPTION_PALNS_BEGIN:
      return {
        ...state,
        getSubscriptionPlanBegin: true,
        getSubscriptionPlanSuccess: false,
        getSubscriptionPlanSuccessData: null,
        getSubscriptionPlanFailure: false,
        getSubscriptionPlanFailureData: null,
      };
    case GET_SUBSCRIPTION_PALNS_SUCCESS:
      return {
        ...state,
        getSubscriptionPlanBegin: false,
        getSubscriptionPlanSuccess: true,
        getSubscriptionPlanSuccessData: data,
        getSubscriptionPlanFailure: false,
        getSubscriptionPlanFailureData: null,
      };
    case GET_SUBSCRIPTION_PALNS_FAILURE:
      return {
        ...state,
        getSubscriptionPlanBegin: false,
        getSubscriptionPlanSuccess: false,
        getSubscriptionPlanSuccessData: null,
        getSubscriptionPlanFailure: true,
        getSubscriptionPlanFailureData: data,
      };
    case RESET_GET_SUBSCRIPTION_PALNS:
      return {
        ...state,
        getSubscriptionPlanBegin: false,
        getSubscriptionPlanSuccess: false,
        getSubscriptionPlanFailure: false,
        getSubscriptionPlanFailureData: null,
      };

    // Subscribe plan
    case SUBSCRIBE_MEMBER_PLAN_BEGIN:
      return {
        ...state,
        subscribeMemberPlanBegin: true,
        subscribeMemberPlanSuccess: false,
        subscribeMemberPlanSuccessData: null,
        subscribeMemberPlanFailure: false,
        subscribeMemberPlanFailureData: null,
      };
    case SUBSCRIBE_MEMBER_PLAN_SUCCESS:
      return {
        ...state,
        subscribeMemberPlanBegin: false,
        subscribeMemberPlanSuccess: true,
        subscribeMemberPlanSuccessData: data,
        subscribeMemberPlanFailure: false,
        subscribeMemberPlanFailureData: null,
      };
    case SUBSCRIBE_MEMBER_PLAN_FAILURE:
      return {
        ...state,
        subscribeMemberPlanBegin: false,
        subscribeMemberPlanSuccess: false,
        subscribeMemberPlanSuccessData: null,
        subscribeMemberPlanFailure: true,
        subscribeMemberPlanFailureData: data,
      };
    case RESET_SUBSCRIBE_MEMBER_PLAN:
      return {
        ...state,
        subscribeMemberPlanBegin: false,
        subscribeMemberPlanSuccess: false,
        subscribeMemberPlanSuccessData: null,
        subscribeMemberPlanFailure: false,
        subscribeMemberPlanFailureData: null,
      };
    case VALIDATE_SUBSCRIPTION_COUPON_BEGIN:
      return {
        ...state,
        validateSubscriptionCouponBegin: true,
        validateSubscriptionCouponSuccess: false,
        validateSubscriptionCouponSuccessData: null,
        validateSubscriptionCouponFailure: false,
        validateSubscriptionCouponFailureData: null,
      };
    case VALIDATE_SUBSCRIPTION_COUPON_SUCCESS:
      return {
        ...state,
        validateSubscriptionCouponBegin: false,
        validateSubscriptionCouponSuccess: true,
        validateSubscriptionCouponSuccessData: data,
        validateSubscriptionCouponFailure: false,
        validateSubscriptionCouponFailureData: null,
      };
    case VALIDATE_SUBSCRIPTION_COUPON_FAILURE:
      return {
        ...state,
        validateSubscriptionCouponBegin: false,
        validateSubscriptionCouponSuccess: false,
        validateSubscriptionCouponSuccessData: null,
        validateSubscriptionCouponFailure: true,
        validateSubscriptionCouponFailureData: data,
      };
    case RESET_VALIDATE_SUBSCRIPTION_COUPON:
      return {
        ...state,
        validateSubscriptionCouponBegin: false,
        validateSubscriptionCouponSuccess: false,
        validateSubscriptionCouponSuccessData: null,
        validateSubscriptionCouponFailure: false,
        validateSubscriptionCouponFailureData: null,
      };

    default:
      return state;
  }
};
