import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import moment from 'moment-timezone';
import data from "../../../utilities/content/statusAndRoleManagement.json";
import FormBackAndContinueButton from "../../MemberRegisterForms/FormBackAndContinueButton";
import ModalOuter from "../../../utilities/modals/ModalOuter";
import { CancelTimeButton } from "../../../utilities/svg";
import {
  getMentorCalendarPreference,
  getMentorMeeting,
} from "../../../../AppRedux/actions/MentorCalendar";
import {
  AppTimePicker,
  ModalDateContainer,
  ModalHeader,
  convertTimeTo12HoursFromat,
  ModalErrorMessage,
  localTime,
  relativeTime
} from "../../MyCalendarGeneralItems";
import {
  addFormFields,
  submitMentorAvailableTimeSlot,
} from "./ScheduleTimeSlotsModalFunctions";
import {
  alertUserForReload,
  scrollToTop,
  successToast,
  DeleteIcon,
} from "../../../utilities";

import Cookies from 'universal-cookie';

const ScheduleTimeSlotModal = ({
  state,
  setState,
  dayInfo,
  calendarCurrentMonthAndYear,
}) => {
  useEffect(() => {
    window.addEventListener("beforeunload", alertUserForReload);
    return () => {
      window.removeEventListener("beforeunload", alertUserForReload);
    };
  }, []);

  return (
    <ModalOuter
      state={state}
      setState={setState}
      style={{ minWidth: "470px" }}
      classNames="p-7 text-left animate-modalInitial"
    >
      <ModalHeader
        heading="Availability"
        description="Schedule your free time available for members"
      />

      <ScheduleTimeModalInner
        dayInfo={dayInfo}
        setState={setState}
        calendarCurrentMonthAndYear={calendarCurrentMonthAndYear}
      />
    </ModalOuter>
  );
};

export default ScheduleTimeSlotModal;

export const ScheduleTimeModalInner = ({
  dayInfo,
  setState,
  calendarCurrentMonthAndYear,
  withoutDate,
  mentorMeetings,
}) => {
  const dispatch = useDispatch(),
    [formData, setFormData] = useState({
      fromTime: {},
      toTime: {},
    }),
    [formError, setFormError] = useState({
      fromTime: {
        from0: "",
      },
      toTime: {
        to0: "",
      },
    }),
    [inputCount, setInputCount] = useState([]),
    [modalFor, setModalFor] = useState("add"),
    [selectedDateAvailableSlots, setSelectedDateAvailableSlots] = useState([]),
    [SelectedDataAvailableSlotsID, setSelectedDataAvailableSlotsID] = useState(
      []
    ),
    [modalError, setModalError] = useState(""),
    {
      getMentorCalendarPreferenceSuccessData,
      submitMentorAddCalendarPreferenceBegin,
      updateMentorAvailableSlotsBegin,
      deleteMentorAvailableSlotsBegin,
      submitMentorAddCalendarPreferenceSuccessData,
      updateMentorAvailableSlotsSuccessData,
      deleteMentorAvailableSlotsSuccessData,
    } = useSelector(({ mentorCalendar }) => mentorCalendar);

  useEffect(() => {
    if (modalFor === "add") {
      // if modal is for add show input
      setInputCount([0]);
      setFormData({
        fromTime: {
          from0: "",
        },
        toTime: {
          to0: "",
        },
      });
    } else {
      // if there available slots hide input
      setInputCount([]);
      setFormData({
        fromTime: {},
        toTime: {},
      });
    }
  }, [modalFor]);

  useEffect(() => {
    // setting mentor availble time slots of selected date if there is any

    getMentorCalendarPreferenceSuccessData?.data?.forEach((event) => {
      if (
        event.date.includes(
          dayInfo.dateStr ? dayInfo.dateStr : dayInfo?.event?.startStr
        )
      ) {
        const { id, startTime, endTime } = event,
          data = { startTime, endTime, id };

        setSelectedDateAvailableSlots((selectedDateAvailableSlots) => [
          ...selectedDateAvailableSlots,
          data,
        ]);
        setSelectedDataAvailableSlotsID((SelectedDataAvailableSlotsID) => [
          ...SelectedDataAvailableSlotsID,
          id,
        ]);

        setModalFor("update");
      }
    });
  }, [
    getMentorCalendarPreferenceSuccessData,
    dayInfo,
    setSelectedDateAvailableSlots,
    setSelectedDataAvailableSlotsID,
  ]);

  useEffect(() => {
    if (
      submitMentorAddCalendarPreferenceSuccessData ||
      updateMentorAvailableSlotsSuccessData ||
      deleteMentorAvailableSlotsSuccessData
    ) {
      // close modal when user submitting
      setState(false);
      dispatch(
        getMentorCalendarPreference(
          calendarCurrentMonthAndYear.month,
          calendarCurrentMonthAndYear.year
        )
      );
      dispatch(getMentorMeeting(data.MEETING.STATUS.PENDING));
      scrollToTop("50", "16.66");
      successToast(
        submitMentorAddCalendarPreferenceSuccessData
          ? "Time slots added successfully"
          : "Time slots updated successfully"
      );
    }
  }, [
    submitMentorAddCalendarPreferenceSuccessData,
    updateMentorAvailableSlotsSuccessData,
    deleteMentorAvailableSlotsSuccessData,
    setState,
    dispatch,
    calendarCurrentMonthAndYear,
  ]);

  // on removing selected date timeslot
  const onRemoveCurrentTimeSlot = (slotId) => {

    setModalError("");
    if (mentorMeetings) {
      // checking mentor is deleting slot that have meetings
      const removingSlot = selectedDateAvailableSlots.find(
        ({ id }) => id === slotId
      );
      mentorMeetings.forEach(({ fromTime }) => {
        if (
          fromTime >= removingSlot.startTime ||
          fromTime >= removingSlot.endTime
        ) {
          setModalError(
            "You can't delete slot that have meeting...please cancel meetings of this time slot and try again!"
          );
        } else if (
          fromTime < removingSlot.startTime ||
          fromTime > removingSlot.endTime
        ) {
          setSelectedDateAvailableSlots(
            selectedDateAvailableSlots.filter(({ id }) => id !== slotId)
          );
        }
      });
    } else {
      setSelectedDateAvailableSlots(
        selectedDateAvailableSlots.filter(({ id }) => id !== slotId)
      );
    }
  };

  const cookies  = new Cookies(null, { path: '/' });
  const timezone = cookies.get('geolocation');

  moment.tz.setDefault(timezone);

  return (
    <>
      {modalFor === "update" && (
        <h1 className=" text-base font-semibold mb-2 text-brandDarkBlue">
          Available time slots
        </h1>
      )}
      <div className="grid grid-cols-2 gap-2 mb-6">
        {selectedDateAvailableSlots.map(({ startTime, endTime, id }) =>  {
         return <div 
                  key={id}
                  className=" rounded-2xl py-2 px-2 flex flex-row items-center justify-around buttonHover "
                  style={{ color: "white", backgroundColor: "#5F9778" }}
                >
                  {convertTimeTo12HoursFromat(localTime(startTime))} -{" "}
                  {convertTimeTo12HoursFromat(localTime(endTime))}
                  <div
                    className="dashboardIcon w-6 h-6"
                    onClick={() => onRemoveCurrentTimeSlot(id)}
                  >
                    <CancelTimeButton />
                  </div>
          </div>
        })}
      </div>
      {!withoutDate && (
        <ModalDateContainer dayInfo={dayInfo} setState={setState} />
      )}
      {modalError !== "" && (
        <ModalErrorMessage
          modalError={modalError}
          setModalError={setModalError}
        />
      )}
      <form
        onSubmit={(e) =>
          submitMentorAvailableTimeSlot(
            e,
            formData,
            setModalError,
            selectedDateAvailableSlots,
            dayInfo,
            modalFor,
            dispatch,
            setFormError,
            formError,
            SelectedDataAvailableSlotsID,
            setSelectedDateAvailableSlots
          )
        }
      >
        {inputCount.map((inputNumber, index) => {
          const currentFromInputKey = `from${inputNumber}`,
            currentFromValue = formData?.fromTime[currentFromInputKey],
            currentToInputKey = `to${inputNumber}`,
            currentToValue = formData?.toTime[currentToInputKey];

          const onChaneInput = () => {
            setFormError({
              ...formError,
              fromTime: {
                ...formError.fromTime,
                [currentFromInputKey]: "",
              },
              toTime: {
                ...formError.toTime,
                [currentToInputKey]: "",
              },
            });
            setModalError("");
          };

          return (
            <div
              key={index}
              className="flex flex-row items-center w-full mb-5 gap-3 animate-navbar"
            >
              <AppTimePicker
                value={currentFromValue}
                label="From :"
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    fromTime: {
                      ...formData.fromTime,
                      [currentFromInputKey]: value,
                    },
                  });
                  onChaneInput();
                }}
                focus={true}
                errorMessage={formError?.fromTime[currentFromInputKey]}
              />
              <AppTimePicker
                value={currentToValue}
                label="To :"
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    toTime: {
                      ...formData.toTime,
                      [currentToInputKey]: value,
                    },
                  });
                  onChaneInput();
                }}
                errorMessage={formError?.toTime[currentToInputKey]}
              />
              {modalFor === "update" || index >= 1 ? (
                <DeleteIcon
                  classNames="min-h-28px min-w-28px"
                  onClick={() => {
                    const { fromTime, toTime } = formData;
                    delete fromTime[[currentFromInputKey]];
                    delete toTime[[currentToInputKey]];
                    setInputCount(
                      inputCount.filter((count) => count !== inputNumber)
                    );
                    onChaneInput();
                  }}
                />
              ) : null}
            </div>
          );
        })}

        <button
          className="font-extrabold text-base mb-4 activeState buttonHover brandBlueText"
          type="button"
          onClick={() =>
            addFormFields(inputCount, setFormData, formData, setInputCount)
          }
        >
          + Add another time
        </button>

        <FormBackAndContinueButton
          buttonText={
            modalFor === "update" ? "Update Availability" : "Add Availability"
          }
          backButtonText="Cancel"
          onBackButtonClick={() => setState(false)}
          actionBegin={
            modalFor === "update"
              ? updateMentorAvailableSlotsBegin ||
              deleteMentorAvailableSlotsBegin
              : submitMentorAddCalendarPreferenceBegin
          }
          buttonClass="py-2 text-base"
        />
      </form>
    </>
  );
};
