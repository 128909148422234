import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminGeneralCards } from "./AdminGeneralComponents";
import { successToast } from "../../utilities";
import {
  getAdminDetails,
  updateAdminDetails,
} from "../../../AppRedux/actions/AdminSettings";

const AdminSettingsCard = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    id: "",
    name: "",
    email: "",
  });
  const [formError, setFormError] = useState({});
  const [editclicked, setEditClicked] = useState(false);
  const [adminDetails, setAdminDetails] = useState("");
  const [editIconfirstClick, setEditIconfirstClick] = useState(true);

  const {
    getAdminDetailsBegin,
    getAdminDetailsSuccessData,
    updateAdminDetailsBegin,
    updateAdminDetailsSuccessData,
  } = useSelector(({ adminSettings }) => adminSettings);

  useEffect(() => {
    dispatch(getAdminDetails());
  }, [dispatch]);

  useEffect(() => {
    if (getAdminDetailsSuccessData) {
      const { id, firstName, lastName, email } =
        getAdminDetailsSuccessData?.data;
      setData({
        ...data,
        id: id,
        name: `${firstName} ${lastName}`,
        email: email,
      });
      setAdminDetails(getAdminDetailsSuccessData?.data);
    }
  }, [getAdminDetailsSuccessData, setData, data]);

  useEffect(() => {
    if (updateAdminDetailsSuccessData) {
      successToast("Details updated successfully");
      dispatch(getAdminDetails());
      setEditClicked(false);
    }
  }, [dispatch, updateAdminDetailsSuccessData]);

  const validation = () => {
    const { name } = data;

    const validationErrors = {};
    if (name?.trim() === "") {
      validationErrors.name = "First name is required.";
    }
    if (name?.trim() !== "" && name.length < 3) {
      validationErrors.name = "* please give your valid name.";
    }

    return validationErrors;
  };

  const handleInputOnChange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z]/gi, " ");
    }
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = () => {
    const validationErrors = validation();

    if (Object.keys(validationErrors).length === 0) {
      let { name, email, id } = data;
      name = name.trim();
      let lastIndex = name.lastIndexOf(" ");

      const payload = {
        firstName: lastIndex === -1 ? name : name.slice(0, lastIndex),
        lastName: lastIndex === -1 ? "" : name.slice(lastIndex + 1),
        email: email,
      };

      dispatch(updateAdminDetails(payload, id));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const onEditIconClick = () => {
    if (!getAdminDetailsBegin) {
      if (editIconfirstClick) {
        setFormError({});
        setEditClicked(!editclicked);
        setEditIconfirstClick(!editIconfirstClick);
      } else {
        //second click
        setFormError({});
        setEditClicked(!editclicked);
        dispatch(getAdminDetails());
        setEditIconfirstClick(!editIconfirstClick);
      }
    }
  };

  return (
    <AdminGeneralCards
      loader={getAdminDetailsBegin}
      buttonLoader={updateAdminDetailsBegin}
      details={adminDetails}
      data={data}
      editclicked={editclicked}
      formError={formError}
      handleInputOnChange={handleInputOnChange}
      handleSubmit={handleSubmit}
      onEditIconClick={onEditIconClick}
      type="admin"
    />
  );
};

export default AdminSettingsCard;
