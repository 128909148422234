import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import {
  MentorAndAboutPageLandingData,
  Puffloader,
  mentorPageData,
} from "../utilities";

const MentorAndAboutPagesLanding = () => {
  const path = useLocation().pathname.replace("/", "");
  const [data, setData] = useState();

  useEffect(() => {
    setData(MentorAndAboutPageLandingData[path]);
  }, [path]);

  return (
    <>
      {data ? (
        <section className="relative">
          <img src={data.Image} className="w-full max-h-screen" alt="" />
          <div className=" absolute top-0 navbarGradient h-24 w-full" />
          <div className="relative">
            <div className="relative homepageWidth">
              <div
                className={`uppercase font-medium text-center text-white absolute right-0 bottom-0 translate-y-1/4 md:w-20vw md:h-20vw max-w-md max-h-448px hover:opacity-95 rounded-full flexCenter p-6  ${path === "about-us"
                    ? "w-40vw h-40vw text-10px 370px:text-xs 500px:text-sm sm:text-base md:text-xl 950px:text-2xl"
                    : "w-28 h-28 370px:w-28 370px:h-28 500px:w-25vw 500px:h-25vw text-10px 370px:text-xs 500px:text-base md:text-lg lg:text-2xl xl:text-3xl"
                  }`}
                style={{ backgroundColor: "#003b5cd9" }}
              >
                <p className="">
                  {data.roundText.map((text, index) => (
                    <span className="block" key={index}>
                      {text}
                    </span>
                  ))}

                  {data.boldRoundText}
                </p>
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="homepageWidth text-brandDarkBlue mt-8 500px:mt-20 950px:mt-28 mb-10 950px:mb-14 font-medium text-sm 370px:text-base 500px:text-lg sm:text-xl md:text-22px lg:text-3xl xl:text-4xl">
            {path === "about-us" ? (
              <>
                <h2 className="sectionHeader text-brandBlue text-lg 500px:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                  {data.heading}
                </h2>
                <div
                  className=" mb-10 text-justify font-medium text-lg text-brandDarkBlue"
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />

                <h2 className="sectionHeader text-brandBlue text-lg 500px:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                  {data.foundersHeading}
                </h2>
                {data.foundersData.map(
                  ({ photo, heading, description }, index) => (
                    <div
                      key={index}
                      className="flex flex-col sm:flex-row space-x-8 mt-4"
                    >
                      <img
                        src={photo}
                        alt="founders"
                        className=" rounded-full bg-blue w-44 h-44"
                      />
                      <div className="p-2">
                        <h1 className="text-2xl mb-4">{heading}</h1>
                        <div
                          className="text-lg text-justify"
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      </div>
                    </div>
                  )
                )}
              </>
            ) : (
              <>
                <h1 className="font-normal sectionHeader text-brandBlue text-lg 500px:text-xl md:text-2xl lg:text-3xl xl:text-4xl">
                  MERITY COACHES HELP DRIVE SUCCESS -{" "}
                  <span className="font-bold">ON YOUR TERMS</span>
                </h1>

                <p className="text-left font-medium text-lg text-brandDarkBlue">
                  Merity’s roster of highly qualified Coaches
                  are trained in the proprietary Merity program and must
                  maintain a consistent 4-star rating from members. Join today and 
                  get started with 30 free minutes of coaching.
                </p>
                {/* <div className="flex flex-col gap-4 mt-4">
                  {data.opportunities.map(({ title, description }, index) => (
                    <div className="flex gap-2" key={index}>
                      <FullYellowSmallSun
                        style={{ minWidth: "34px", minHeight: "34px" }}
                      />
                      <p
                        className="font-extrabold text-brandDarkBlue text-left text-base 500px:text-lg"
                        key={index}
                      >
                        <b className="font-bold">{title} </b>
                        <span className="font-medium">{description}</span>
                      </p>
                    </div>
                  ))}
                </div> */}
                <div className="grid lg:grid-cols-3 gap-4 mt-4">
                  {mentorPageData?.map(({ title, data, Icon }, index) => (
                    <div
                      className={`w-full 370px:w-11/12 500px:w-4/5 sm:w-auto border-5px bg-white relative border-brandGreen p-2`}
                      key={index}
                    >
                      <div className="flex items-center">
                        <img src={Icon} alt="" className="h-full w-1/3" />
                        <h6 className="text-brandDarkBlue font-semibold text-xl">
                          {title}
                        </h6>
                      </div>
                      <div className="flex flex-col mt-2 p-2 pr-6">
                        {data.map((value, index) => (
                          <div key={index} className="flex space-x-2">
                            <div className="border border-black w-1 h-1 mt-5 rounded-full bg-brandDarkBlue" />
                            <p className="text-lg my-2">{value}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </section>
      ) : (
        <Puffloader />
      )}
    </>
  );
};

export default MentorAndAboutPagesLanding;
