import React, { useEffect, useState } from "react";

import memberPageData from "../../components/utilities/content/memberDashboard.json";
import MemberNavbar from "../../components/pageComponents/memberDashboard/MemberNavbar";
import { useSelector } from "react-redux";


const MemberDashboardLayout = ({ children, tabFromParams }) => {
  const [userRegistrationType, setuserRegistrationType] = useState();
  const { navbar } = memberPageData;

  const { getMemberDashboardDetailsSuccessData } = useSelector(
    ({ memberDashboard }) => memberDashboard
  );

  useEffect(() => {
    if (getMemberDashboardDetailsSuccessData) {
      setuserRegistrationType(
        getMemberDashboardDetailsSuccessData?.data.member?.User.registrationType
      );
    }
  }, [getMemberDashboardDetailsSuccessData]);

  return (
    <div className="min-h-screen" style={{ backgroundColor: "#f3f3f3" }}>
      <MemberNavbar
        tabFromParams={tabFromParams}
        dropdownData={
          userRegistrationType === 1
            ? navbar.dropdownListForNormalUser
            : navbar.dropdownListForSocialUser
        }
      />
      <div className={`w-96% xl:w-10/12 mx-auto pt-7  pb-8 500px:pb-16 `}>
        {children}
      </div>
    </div>
  );
};

export default MemberDashboardLayout;
