import {
  GET_COMMUNITY_LIST_BEGIN,
  GET_COMMUNITY_LIST_SUCCESS,
  GET_COMMUNITY_LIST_FAILURE,
  RESET_GET_COMMUNITY_LIST,
  GENERATE_ZOOM_LINK_BEGIN,
  GENERATE_ZOOM_LINK_SUCCESS,
  GENERATE_ZOOM_LINK_FAILURE,
  RESET_GENERATE_ZOOM_LINK,
  CREATE_COMMUNITY_SESSION_BEGIN,
  CREATE_COMMUNITY_SESSION_SUCCESS,
  CREATE_COMMUNITY_SESSION_FAILURE,
  RESET_CREATE_COMMUNITY_SESSION,
  RESCHEDULE_COMMUNITY_SESSION_BEGIN,
  RESCHEDULE_COMMUNITY_SESSION_SUCCESS,
  RESCHEDULE_COMMUNITY_SESSION_FAILURE,
  RESET_RESCHEDULE_COMMUNITY_SESSION,
  GET_SINGLE_COMMUNITY_BEGIN,
  GET_SINGLE_COMMUNITY_SUCCESS,
  GET_SINGLE_COMMUNITY_FAILURE,
  RESET_GET_SINGLE_COMMUNITY,
  DELETE_COMMUNITY_BEGIN,
  DELETE_COMMUNITY_SUCCESS,
  DELETE_COMMUNITY_FAILURE,
  RESET_DELETE_COMMUNITY,
} from "../constants/AdminCommunity";

import { httpApp } from "../../axios/httpUser";

//get community session listing
export function getCommunityList(page) {
  return (dispatch) => {
    dispatch({
      type: GET_COMMUNITY_LIST_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/community?status=0&order=desc&orderBy=onDate&page=${page}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_COMMUNITY_LIST_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_COMMUNITY_LIST,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_COMMUNITY_LIST_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_COMMUNITY_LIST,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//generate zoom link
export function generateZoomLink(data) {
  return (dispatch) => {
    dispatch({
      type: GENERATE_ZOOM_LINK_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/community/generate/url`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: GENERATE_ZOOM_LINK_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GENERATE_ZOOM_LINK,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GENERATE_ZOOM_LINK_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GENERATE_ZOOM_LINK,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//create community session
export function createCommunitySession(args) {
  return (dispatch) => {
    dispatch({
      type: CREATE_COMMUNITY_SESSION_BEGIN,
    });

    var form = new FormData();
    Object.keys(args).map((i) => form.append(i, args[i]));
    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/community`, form);

      doRequest.then(
        (res) => {
          dispatch({
            type: CREATE_COMMUNITY_SESSION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CREATE_COMMUNITY_SESSION,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CREATE_COMMUNITY_SESSION_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CREATE_COMMUNITY_SESSION,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//reschedule community session

export function rescheduleCommunitySession(id, data) {
  return (dispatch) => {
    dispatch({
      type: RESCHEDULE_COMMUNITY_SESSION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/community/${id}`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: RESCHEDULE_COMMUNITY_SESSION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_RESCHEDULE_COMMUNITY_SESSION,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: RESCHEDULE_COMMUNITY_SESSION_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_RESCHEDULE_COMMUNITY_SESSION,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function getSingleCommunityDetails(id) {
  return (dispatch) => {
    dispatch({
      type: GET_SINGLE_COMMUNITY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/community/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_SINGLE_COMMUNITY_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_COMMUNITY,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_SINGLE_COMMUNITY_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_COMMUNITY,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
export function deleteCommunityById(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_COMMUNITY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/community/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_COMMUNITY_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_COMMUNITY,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_COMMUNITY_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_COMMUNITY,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
