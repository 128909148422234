import {
  GET_MEMBERS_LIST_BEGIN,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST_FAILURE,
  RESET_GET_MEMBERS_LIST,
  GET_SINGLE_MEMBER_DETAILS_BEGIN,
  GET_SINGLE_MEMBER_DETAILS_SUCCESS,
  GET_SINGLE_MEMBER_DETAILS_FAILURE,
  RESET_GET_SINGLE_MEMBER_DETAILS,
  GET_MEMBER_PROFILE_DETAILS_BEGIN,
  GET_MEMBER_PROFILE_DETAILS_SUCCESS,
  GET_MEMBER_PROFILE_DETAILS_FAILURE,
  RESET_GET_MEMBER_PROFILE_DETAILS,
  GET_SINGLE_MEMBER_PAYMENT_DETAILS_BEGIN,
  GET_SINGLE_MEMBER_PAYMENT_DETAILS_SUCCESS,
  GET_SINGLE_MEMBER_PAYMENT_DETAILS_FAILURE,
  RESET_GET_SINGLE_MEMBER_PAYMENT_DETAILS,
  SEND_MEMBER_ALERT_BEGIN,
  SEND_MEMBER_ALERT_SUCCESS,
  SEND_MEMBER_ALERT_FAILURE,
  RESET_SEND_MEMBER_ALERT,
  BLOCK_MEMBER_BEGIN,
  BLOCK_MEMBER_SUCCESS,
  BLOCK_MEMBER_FAILURE,
  RESET_BLOCK_MEMBER,
  UNBLOCK_MEMBER_BEGIN,
  UNBLOCK_MEMBER_SUCCESS,
  UNBLOCK_MEMBER_FAILURE,
  RESET_UNBLOCK_MEMBER,
  ASSIGN_MENTOR_BEGIN,
  ASSIGN_MENTOR_SUCCESS,
  ASSIGN_MENTOR_FAILURE,
  RESET_ASSIGN_MENTOR,
  DELETE_MEMBER_BYID_BEGIN,
  DELETE_MEMBER_BYID_SUCCESS,
  DELETE_MEMBER_BYID_FAILURE,
  RESET_DELETE_MEMBER_BYID,
} from "../constants/AdminMember";

import { httpApp } from "../../axios/httpUser";

export function getMembersList(pageNumber = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBERS_LIST_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/admin/list/members?page=${pageNumber}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBERS_LIST_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBERS_LIST,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBERS_LIST_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MEMBERS_LIST,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function getSingleMemberDetails(id) {
  return (dispatch) => {
    dispatch({
      type: GET_SINGLE_MEMBER_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/admin/member/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_SINGLE_MEMBER_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_MEMBER_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_SINGLE_MEMBER_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_MEMBER_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function getMemberProfileDetails(id) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_PROFILE_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/admin/member/${id}/curriculum`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_PROFILE_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_PROFILE_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_PROFILE_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_PROFILE_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function getSingleMemberPaymentDetails(id, type, pageNumber) {
  return (dispatch) => {
    dispatch({
      type: GET_SINGLE_MEMBER_PAYMENT_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/admin/member/${id}/transaction?type=${type}&limit=10&page=${pageNumber}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_SINGLE_MEMBER_PAYMENT_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_MEMBER_PAYMENT_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_SINGLE_MEMBER_PAYMENT_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_SINGLE_MEMBER_PAYMENT_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function sendMemberAlert(id, data) {
  return (dispatch) => {
    dispatch({
      type: SEND_MEMBER_ALERT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/admin/member/${id}/alert`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SEND_MEMBER_ALERT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SEND_MEMBER_ALERT,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SEND_MEMBER_ALERT_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SEND_MEMBER_ALERT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//block member
export function blockMember(id) {
  return (dispatch) => {
    dispatch({
      type: BLOCK_MEMBER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/user/${id}/block`);

      doRequest.then(
        (res) => {
          dispatch({
            type: BLOCK_MEMBER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_BLOCK_MEMBER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: BLOCK_MEMBER_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_BLOCK_MEMBER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//unblock member
export function unblockMember(id) {
  return (dispatch) => {
    dispatch({
      type: UNBLOCK_MEMBER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/user/${id}/unblock`);

      doRequest.then(
        (res) => {
          dispatch({
            type: UNBLOCK_MEMBER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UNBLOCK_MEMBER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UNBLOCK_MEMBER_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UNBLOCK_MEMBER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//assign mentor
export function assignMentor(data) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_MENTOR_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/admin/changeMentor`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: ASSIGN_MENTOR_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ASSIGN_MENTOR,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ASSIGN_MENTOR_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_ASSIGN_MENTOR,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//Delete  member
export function deleteMemberById(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_MEMBER_BYID_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/admin/member/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_MEMBER_BYID_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_MEMBER_BYID,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_MEMBER_BYID_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_MEMBER_BYID,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
