import {
  SUBMIT_MEMBER_LOGIN_FORM_BEGIN,
  SUBMIT_MEMBER_LOGIN_FORM_SUCCESS,
  SUBMIT_MEMBER_LOGIN_FORM_FAILURE,
  RESET_SUBMIT_MEMBER_LOGIN_FORM,
  MEMBER_GOOGLE_LOGIN_BEGIN,
  MEMBER_GOOGLE_LOGIN_SUCCESS,
  MEMBER_GOOGLE_LOGIN_FAILURE,
  RESET_MEMBER_GOOGLE_LOGIN,
  VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_BEGIN,
  VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_SUCCESS,
  VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP_FAILURE,
  RESET_VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP,
  SET_NEW_PASSWORD_BEGIN,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  RESET_SET_NEW_PASSWORD,
  MEMBER_FACEBOOK_LOGIN_BEGIN,
  MEMBER_FACEBOOK_LOGIN_SUCCESS,
  MEMBER_FACEBOOK_LOGIN_FAILURE,
  RESET_MEMBER_FACEBOOK_LOGIN,
  SUBMIT_USER_LOGOUT_BEGIN,
  SUBMIT_USER_LOGOUT_SUCCESS,
  SUBMIT_USER_LOGOUT_FAILURE,
  RESET_SUBMIT_USER_LOGOUT,
  CANCEL_SUBSCRIPTION_BEGIN,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION_FAILURE,
  RESET_CANCEL_SUBSCRIPTION,
} from "../constants/MemberLogin";

import { httpApp } from "../../axios/httpUser";

export function submitMemberLoginForm(data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MEMBER_LOGIN_FORM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/login`, data);
      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MEMBER_LOGIN_FORM_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_LOGIN_FORM,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MEMBER_LOGIN_FORM_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_LOGIN_FORM,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function memberGoogleLogin(data) {
  return (dispatch) => {
    dispatch({
      type: MEMBER_GOOGLE_LOGIN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/google-login`, data);
      doRequest.then(
        (res) => {
          dispatch({
            type: MEMBER_GOOGLE_LOGIN_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_MEMBER_GOOGLE_LOGIN,
          });
          resolve(res);
        },

        (err) => {
          dispatch({
            type: MEMBER_GOOGLE_LOGIN_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_MEMBER_GOOGLE_LOGIN,
          });
          reject(err);
        }
      );
    });

    return promise;
  };
}

//verify forgot password otp
export function verifyMemberForgotPasswordLoginOTP(data) {
  return (dispatch) => {
    dispatch({
      type: VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/otp/check`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//setting new password after otp verification
export function setNewPassword(data) {
  return (dispatch) => {
    dispatch({
      type: SET_NEW_PASSWORD_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/reset-password`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SET_NEW_PASSWORD_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SET_NEW_PASSWORD,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SET_NEW_PASSWORD_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SET_NEW_PASSWORD,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//Facebook Login
export function memberFacebookLogin(data) {
  return (dispatch) => {
    dispatch({
      type: MEMBER_FACEBOOK_LOGIN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/facebook-login`, data);
      doRequest.then(
        (res) => {
          dispatch({
            type: MEMBER_FACEBOOK_LOGIN_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_MEMBER_FACEBOOK_LOGIN,
          });
          resolve(res);
        },

        (err) => {
          dispatch({
            type: MEMBER_FACEBOOK_LOGIN_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_MEMBER_FACEBOOK_LOGIN,
          });
          reject(err);
        }
      );
    });

    return promise;
  };
}

// User logout
export function submitLogout() {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_USER_LOGOUT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/auth/logout`);
      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_USER_LOGOUT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_USER_LOGOUT,
          });
          resolve(res);
        },

        (err) => {
          dispatch({
            type: SUBMIT_USER_LOGOUT_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_USER_LOGOUT,
          });
          reject(err);
        }
      );
    });

    return promise;
  };
}

// Cancer Subscription
export function cancelSubscription(id) {
  return (dispatch) => {
    dispatch({
      type: CANCEL_SUBSCRIPTION_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/member/${id}`);
      doRequest.then(
        (res) => {
          dispatch({
            type: CANCEL_SUBSCRIPTION_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CANCEL_SUBSCRIPTION,
          });
          resolve(res);
        },

        (err) => {
          dispatch({
            type: CANCEL_SUBSCRIPTION_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_CANCEL_SUBSCRIPTION,
          });
          reject(err);
        }
      );
    });

    return promise;
  };
}
