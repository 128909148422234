import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone';

import LoginToCometChatMeet from "../../../../pages/memberDashboard/cometChat/login";
import data from "../../../utilities/content/statusAndRoleManagement.json";
import ModalOuter from "../../../utilities/modals/ModalOuter";
import { ScheduleTimeModalInner } from "./ScheduleTimeSlotModal";
import { cancelMemberMeeting } from "../../../../AppRedux/actions/MemberCalendar";
import { getMentorMeeting } from "../../../../AppRedux/actions/MentorCalendar";
import { Tab } from "../../dashboardGeneralItems.js";
import {
  deafultToast,
  UserAvatar,
  SmallButton,
  infoToast,
} from "../../../utilities";
import {
  AppTimePickerContainer,
  CancelMeetingTextBox,
  convertTimeTo12HoursFromat,
  ModalDateContainer,
  ModalHeader,
  localTime,
} from "../../MyCalendarGeneralItems";


const MeetingDayModal = ({
  state,
  setState,
  dayInfo,
  dayMeetings,
  calendarCurrentMonthAndYear,
}) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Meetings");
  const [mentorMeetings, setMentorMeetings] = useState();
  const [cancelOptionEnabled, setCancelOptionEnabled] = useState(false);
  const {
    cancelMemberMeetingBegin,
    cancelMemberMeetingSuccessData,
    cancelMemberMeetingFailureData,
  } = useSelector(({ memberCalendar }) => memberCalendar);

  useEffect(() => {
    if (dayMeetings) {
      setMentorMeetings(dayMeetings);
    }
  }, [dayMeetings]);

  useEffect(() => {
    if (cancelMemberMeetingSuccessData) {
      deafultToast("Meeting canceled");
      dispatch(getMentorMeeting(data.MEETING.STATUS.PENDING));
      setMentorMeetings(
        mentorMeetings.filter(
          ({ id: meetingId }) => cancelOptionEnabled !== meetingId
        )
      );
    }
    if (cancelMemberMeetingFailureData) {
      infoToast(cancelMemberMeetingFailureData.message);
      setMentorMeetings(
        mentorMeetings.filter(
          ({ id: meetingId }) => cancelOptionEnabled !== meetingId
        )
      );
    }
  }, [
    cancelMemberMeetingSuccessData,
    cancelMemberMeetingFailureData,
    dispatch,
    cancelOptionEnabled,
    mentorMeetings,
  ]);

  return (
    <ModalOuter
      state={state}
      setState={setState}
      style={{ minWidth: "470px" }}
      classNames="p-7 text-left animate-modalInitial"
    >
      {mentorMeetings?.length !== 0 && selectedTab === "Meetings" ? (
        <ModalHeader
          heading="Your meeting"
          description="See your meeting with Member"
        />
      ) : (
        <ModalHeader
          heading="Availability"
          description="Schedule your free time available for members"
        />
      )}

      <ModalDateContainer dayInfo={dayInfo} />

      {mentorMeetings?.length !== 0 && (
        <div className="flex w-10/12 mx-auto mb-4">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              tab={tab}
              selectedTab={selectedTab}
              onClick={() => setSelectedTab(tab)}
              classNames="pb-2"
            />
          ))}
        </div>
      )}

      {mentorMeetings?.length !== 0 ? (
        selectedTab === "Slots" ? (
          <div className="animate-dashboardInitial">
            <ScheduleTimeModalInner
              dayInfo={dayInfo}
              setState={setState}
              calendarCurrentMonthAndYear={calendarCurrentMonthAndYear}
              withoutDate={true}
              mentorMeetings={mentorMeetings}
            />
          </div>
        ) : (
          <div className="animate-modalInitial">
            {mentorMeetings?.map(
              ({
                id,
                member,
                fromTime,
                toTime,
                meetingDate,
                status,
                mentor,
                slotId,
              }) => {

              
                const toLocalTime = localTime(toTime);
                const fromLocalTime = localTime(fromTime);

                const MeetingStartingTime = moment(new Date(meetingDate))
                  .hour(fromLocalTime.split(":")[0])
                  .minute(fromLocalTime.split(":")[1]);
                const MeetingEndingTime = moment(new Date(meetingDate))
                  .hour(toLocalTime.split(":")[0])
                  .minute(toLocalTime.split(":")[1]);



                const meetingInfo = [
                  {
                    id,
                    toTime: toLocalTime,
                    fromTime: fromLocalTime,
                    meetingDate,
                    status,
                    userName: `${member.firstName} ${member.lastName}`,
                    date: MeetingStartingTime.calendar(),
                    duration: `${moment
                      .duration(MeetingEndingTime.diff(MeetingStartingTime))
                      .asMinutes()} mins`,
                    mentorUid: mentor.User.uid,
                    memberUid: member.uid,
                  },
                ];




                return (
                  <div key={id}>
                    <div className="flex justify-around items-center my-2">
                      <div className="flex flex-col items-center w-1/2 overflow-hidden">
                        <p
                          className="font-semibold text-sm mb-2"
                          style={{ color: "#828282" }}
                        >
                          Client Name
                        </p>
                        <div className="flex gap-2">
                          <UserAvatar
                            name={member.firstName}
                            classNames="w-5 h-5 text-sm"
                            style={{ minWidth: "20px", minHeight: "20px" }}
                            profilePhoto={member.profilePhoto}
                            imgClassNames="w-5 h-5"
                          />
                          <p
                            className="text-base font-bold"
                            style={{ color: "#003547" }}
                          >
                            {member.firstName} {member.lastName}
                          </p>
                        </div>
                      </div>

                      <div className="pl-2 w-1/2">
                        <h1
                          className=" text-base font-semibold mb-2"
                          style={{ color: "#0D0D0D" }}
                        >
                          Scheduled Meeting time
                        </h1>
                        <AppTimePickerContainer classNames="w-full" label="">
                          <p className="font-semibold text-base">
                            {convertTimeTo12HoursFromat(
                              (fromLocalTime)
                            )}{" "}
                            -{" "}
                            {convertTimeTo12HoursFromat(
                              (toLocalTime)
                            )}
                          </p>
                        </AppTimePickerContainer>
                      </div>
                    </div>
                    {cancelOptionEnabled === id ? (
                      <>
                        <div
                          className="border-b border-solid my-2"
                          style={{ borderColor: "#BCBCBC" }}
                        />
                        <CancelMeetingTextBox
                          NoClick={() => setCancelOptionEnabled(false)}
                          yesClick={() => {
                            dispatch(cancelMemberMeeting(id, slotId, ""));
                          }}
                          actionBegin={cancelMemberMeetingBegin}
                        />
                      </>
                    ) : (
                      <>
                        {MeetingStartingTime.isBefore() ? (
                          <div className="bg-brandBlue   text-white py-1 px-2 rounded-md">
                            <p className="animate-pulse text-center">Meeting started...</p>
                            <LoginToCometChatMeet
                              meetingData={meetingInfo}
                              userType="mentor"
                              text="Join to call"
                              classNames="underline"
                            />
                          </div>
                        ) : (
                          <div className="flex justify-end">
                            <SmallButton
                              onClick={() => setCancelOptionEnabled(id)}
                              text="Cancel Meeting"
                              classNames="bg-brandBlue"
                            />
                          </div>
                        )}
                      </>
                    )}

                    <div
                      className="border-b border-solid my-2"
                      style={{ borderColor: "#BCBCBC" }}
                    />
                  </div>
                );
              }
            )}
          </div>
        )
      ) : (
        <ScheduleTimeModalInner
          dayInfo={dayInfo}
          setState={setState}
          calendarCurrentMonthAndYear={calendarCurrentMonthAndYear}
          withoutDate={true}
        />
      )}
    </ModalOuter>
  );
};

export default MeetingDayModal;

const tabs = ["Slots", "Meetings"];
