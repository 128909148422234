import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import pageData from "../../utilities/content/mentorDashboard.json";
import { updateNewUser } from "../../../AppRedux/actions/MemberProfile";

import {
  getMemberDashboardDataFromLocalStorage,
  getUserDataFromLocalStorage,
} from "../../utilities";

const Navbar = ({ selectedTab = "" }) => {

  const dispatch  = useDispatch();
  const User      = getUserDataFromLocalStorage();


  const moodleLogin = async () => {

    const updatedData         = {};
    updatedData.isNewAccount  = true;
    dispatch(updateNewUser(updatedData));

    const API_URL = "https://merity-api.mymerity.com";

    try {
      const response = await fetch( API_URL + '/auth/sso', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: User.email, email: User.email, firstName: User.firstName, lastName: User.lastName , userRole : 'coach'}),
      });
      

      const tempdata = await response.json();
      // console.log(tempdata.samlResponse);

  
      // post the SAMlResponse to the moodle instance like form data
      const form        = document.createElement('form');
      form.method       = 'POST';
      form.action       = 'https://sessions.mymerity.com/auth/saml2/sp/saml2-acs.php/sessions.mymerity.com';
      const hiddenField = document.createElement('input');
      hiddenField.type  = 'hidden';
      hiddenField.name  = 'SAMLResponse';
      hiddenField.value = tempdata.data.samlResponse.context;

      form.appendChild(hiddenField);
      document.body.appendChild(form);
      form.submit();
    } catch (err) {
      console.log("Something went wrong");
    }
    
  }



  return (
    <div className="w-full pt-6 bg-brandBlue h-16 xl:h-20">
      <div className="w-10/12 xl:w-9/12 mx-auto flex justify-center sm:justify-start space-x-8 370px:space-x-16 sm:space-x-24 ">
        {pageData.dashboardTabs.map(({ header, tab }, index) => (

          <div key={index} className="flex flex-col ">

         
            { header == 'Moodle' ? 
              <div 
                className="font-semibold text-base xl:text-lg cursor-pointer text-white  hover:text-gray-200 activeState transition duration-100 ease"
                onClick={() => moodleLogin()}
              >
                Client Sessions
              </div>
              :
              <Link
              to={`/mentor/dashboard/${tab}`}
              className={`font-semibold text-base xl:text-lg cursor-pointer text-white  hover:text-gray-200 activeState transition duration-100 ease ${
                selectedTab === tab
                  ? " text-memberSelectedCell hover:text-yellow-400 "
                  : ""
              }`}
            >
              {header}
            </Link>

            }

            {selectedTab === tab && (
              <div
                className="h-1 xl:h-6px mt-3 xl:mt-6 rounded-full bg-memberSelectedCell"
                style={{
                  width: "125%",
                  marginLeft: "-10%",
                }}
              />
            )}
          </div>

        ))}
      </div>
    </div>
  );
};

export default Navbar;
