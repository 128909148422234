import {
  GET_ADMIN_DETAILS_BEGIN,
  GET_ADMIN_DETAILS_SUCCESS,
  GET_ADMIN_DETAILS_FAILURE,
  RESET_GET_ADMIN_DETAILS,
  UPDATE_ADMIN_DETAILS_BEGIN,
  UPDATE_ADMIN_DETAILS_SUCCESS,
  UPDATE_ADMIN_DETAILS_FAILURE,
  RESET_UPDATE_ADMIN_DETAILS,
  VIEW_PROMOCODE_DETAILS_BEGIN,
  VIEW_PROMOCODE_DETAILS_SUCCESS,
  VIEW_PROMOCODE_DETAILS_FAILURE,
  RESET_VIEW_PROMOCODE_DETAILS,
  CREATE_PROMOCODE_BEGIN,
  CREATE_PROMOCODE_SUCCESS,
  CREATE_PROMOCODE_FAILURE,
  RESET_CREATE_PROMOCODE,
  DELETE_PROMOCODE_BEGIN,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_FAILURE,
  RESET_DELETE_PROMOCODE,
} from "../constants/AdminSettings";

import { httpApp } from "../../axios/httpUser";

export function getAdminDetails() {
  return (dispatch) => {
    dispatch({
      type: GET_ADMIN_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/admin/profile`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_ADMIN_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_ADMIN_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_ADMIN_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_ADMIN_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function updateAdminDetails(data) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ADMIN_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/admin/profile`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: UPDATE_ADMIN_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UPDATE_ADMIN_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UPDATE_ADMIN_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UPDATE_ADMIN_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function getPromoCodeDetails() {
  return (dispatch) => {
    dispatch({
      type: VIEW_PROMOCODE_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/payment/coupons`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_PROMOCODE_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_PROMOCODE_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_PROMOCODE_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_PROMOCODE_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function createPromoCodes(data) {
  return (dispatch) => {
    dispatch({
      type: CREATE_PROMOCODE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/payment/coupons`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: CREATE_PROMOCODE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CREATE_PROMOCODE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CREATE_PROMOCODE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CREATE_PROMOCODE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function deletePromoCodes(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_PROMOCODE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/payment/coupons/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_PROMOCODE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_PROMOCODE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_PROMOCODE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_PROMOCODE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
