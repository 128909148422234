import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Popup } from "../../../AppRedux/actions/MemberRegistration";
import { Hamburger } from "../Navbar";
import MerityCommunityIconPNG from "../../../assets/Images/MerityCommunityIconPNG.png";
import ConfirmModal from "../../utilities/modals/ConfirmModal";
import OutsideClickCloseContainer from "../../utilities/OutsideClickCloseContainer";
import MemberNotificationContainer from "./MemberNotificationContainer";
import { getMemberNotifications } from "../../../AppRedux/actions/MemberDashboard";
import {
  LogoutModalData,
  UnsubscribeModalData,
} from "../dashboardGeneralItems";
import {
  currentAppUser,
  getMemberDashboardDataFromLocalStorage,
  storeMemberDashboardDetailsInLocalStorage,
  UserNameAndAvatar,
} from "../../utilities";
import {
  MemberNavbarDownArrow,
  NavbarSecondLogo,
  MemberDashboardNavbarSun,
} from "../../utilities/svg";
import UpdateProfileModal from "../../utilities/modals/UpdateModal";
import UnsubscribeModal from "../../utilities/modals/UnsubscribeModal";
import TellAFriendModal from "../../utilities/modals/TellAFriendModal";
import MerityBetaLogo from "../../../assets/Images/MerityBetaLogo.png";

const MemberNavbar = ({ dropdownData, tabFromParams }) => {
  const dispatch = useDispatch();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const { getMentorDashboardDetailsSuccessData } = useSelector(
    ({ mentorDashboard }) => mentorDashboard
  );
  const { showPopup } = useSelector(
    ({ memberRegistration }) => memberRegistration
  );
  const closeUpdateModal = () => {
    setUpdateModal(false);
    dispatch(Popup("UNSHOW_POP_UP"));
  };
  const { member } = getMemberDashboardDataFromLocalStorage();

  // for pop show
  useEffect(() => {
    if (showPopup) {
      setUpdateModal(true);
    }
  }, [showPopup]);

  useEffect(() => {
    // Mentor dashboard data
    if (getMentorDashboardDetailsSuccessData) {
      storeMemberDashboardDetailsInLocalStorage(
        getMentorDashboardDetailsSuccessData?.data
      );
    }
  }, [getMentorDashboardDetailsSuccessData]);

  const profilePhoto =
    currentAppUser() === "MEMBER_SUBSCRIBED"
      ? getMemberDashboardDataFromLocalStorage()?.member?.User.profilePhoto
      : currentAppUser() === "MENTOR" &&
      getMemberDashboardDataFromLocalStorage()?.name.profilePhoto;
  const userName =
    currentAppUser() === "MEMBER_SUBSCRIBED"
      ? getMemberDashboardDataFromLocalStorage()?.member?.User.firstName +
      " " +
      getMemberDashboardDataFromLocalStorage()?.member?.User.lastName
      : currentAppUser() === "MENTOR" &&
      getMemberDashboardDataFromLocalStorage()?.name.firstName +
      " " +
      getMemberDashboardDataFromLocalStorage()?.name.lastName;

  const { submitUserLogoutBegin, cancelSubscriptionBegin } = useSelector(
    ({ memberLogin }) => memberLogin
  );

  useEffect(() => {
    dispatch(getMemberNotifications());
  }, [dispatch]);

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false),
    [showLogoutModal, setshowLogoutModal] = useState(false),
    [showTellAFriendModal, setShowTellAFriendModal] = useState(false),
    [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  return (
    <div className="bg-white w-full relative">
      <div className="flex justify-between items-center shadow-memberDashboard mx-auto w-11/12 xl:w-10/12 py-2 pr-0 sm:pr-10 xl:pr-0">
        <Link to="/">
          <img src={MerityBetaLogo} className="w-40 sm:w-32 lg:w-48" alt="" />
        </Link>

        <Link
          className={`hidden 850px:flex  justify-end items-center w-1/3 opacity-0 pointer-events-none`}
          to={
            currentAppUser() === "MENTOR"
              ? "/mentor/dashboard?tab=feeds-chat"
              : "/member/dashboard?tab=feeds"
          }
        >
          <img
            style={{ minHeight: "64px", minWidth: "48px" }}
            src={MerityCommunityIconPNG}
            alt=""
            className="w-20 h-12"
          />
          <span
            className={` hidden 850px:block  ${(tabFromParams === "feeds" || tabFromParams === "feeds-chat") &&
              "bg-brandBlue text-white"
              } text-brandDarkBlue dashboardHover p-2 rounded-lg`}
          >
            {" "}
            Community Chat
          </span>
        </Link>
        <MemberNotificationContainer />
        <div className="hidden 850px:flex items-center gap-2 relative">
          <OutsideClickCloseContainer setState={setProfileDropdownOpen}>
            <div
              onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
              className=" cursor-pointer flex items-center gap-2 buttonHover activeState"
            >
              <UserNameAndAvatar name={userName} profilePhoto={profilePhoto} />
              <MemberNavbarDownArrow className="stroke-black mx-2" />
            </div>
            {profileDropdownOpen && (
              <ProfileDropdown
                data={dropdownData}
                setshowLogoutModal={setshowLogoutModal}
                setProfileDropdownOpen={setProfileDropdownOpen}
                setShowUnsubscribeModal={setShowUnsubscribeModal}
                setShowTellAFriendModal={setShowTellAFriendModal}
              />
            )}
          </OutsideClickCloseContainer>
        </div>
        <div className="850px:hidden">
          <Hamburger
            hamburgerOpen={hamburgerOpen}
            sethamburgerOpen={setHamburgerOpen}
            colorChange={true}
          />
        </div>
        {/* hamburger menu */}
        {hamburgerOpen && (
          <MemberHamburgerMenu
            profileDropdownOpen={profileDropdownOpen}
            setProfileDropdownOpen={setProfileDropdownOpen}
            setshowLogoutModal={setshowLogoutModal}
            setShowUnsubscribeModal={setShowUnsubscribeModal}
            setShowTellAFriendModal={setShowTellAFriendModal}
            dropdownData={dropdownData}
            userName={userName}
          />
        )}
      </div>

      <MemberDashboardNavbarSun className="absolute bottom-0 right-0 transform translate-y-1/2 hidden sm:block" />
      {showLogoutModal && (
        <ConfirmModal
          {...LogoutModalData(
            dispatch,
            setshowLogoutModal,
            submitUserLogoutBegin
          )}
          state={showLogoutModal}
          setState={setshowLogoutModal}
        />
      )}
      {updateModal ? (
        <UpdateProfileModal closeUpdateModal={closeUpdateModal} />
      ) : null}
      {showUnsubscribeModal && (
        <UnsubscribeModal
          {...UnsubscribeModalData(
            member.User.id,
            dispatch,
            setShowUnsubscribeModal,
            cancelSubscriptionBegin
          )}
          state={showUnsubscribeModal}
          setState={setShowUnsubscribeModal}
        />
      )}
      {showTellAFriendModal && (
        <TellAFriendModal
          state={showTellAFriendModal}
          setState={setShowTellAFriendModal}
        />
      )}
    </div>
  );
};

export default MemberNavbar;

// Profile dropdown
export const ProfileDropdown = ({
  type,
  data,
  setshowLogoutModal,
  setProfileDropdownOpen,
  setShowUnsubscribeModal,
  setShowTellAFriendModal,
}) => {
  const navigate = useNavigate();

  const onMemberDropdownItemClick = (listItem) => {
    setProfileDropdownOpen((state) => !state);
    if (listItem.text === "Logout") {
      setshowLogoutModal(true);
    } else if (listItem.link) {
      return navigate(listItem.link);
    } else if (listItem.text === "Tell a Friend") {
      setShowTellAFriendModal(true)

      // successToast("Link copied to clipboard");
    } else if (listItem.text === "Cancel Subscription") {
      setShowUnsubscribeModal(true);
    }
  };

  return (
    <div
      className={`z-50 ${type === "manager"
        ? "memberDropdownList right-10 top-3/4"
        : "850px:memberDropdownList "
        } `}
      style={{ minWidth: "150px" }}
    >
      {data?.map((listItem, index) => (
        <div
          className={`${listItem.text === "" ? "hidden" : "memberDropdownListItem"
            }`}
          onClick={(e) => {
            e.stopPropagation();

            onMemberDropdownItemClick(listItem);
          }}
          key={index}
        >
          {listItem.text}
        </div>
      ))}
    </div>
  );
};

const MemberHamburgerMenu = ({
  // profileDropdownOpen,
  setShowTellAFriendModal,
  setProfileDropdownOpen,
  setshowLogoutModal,
  setShowUnsubscribeModal,
  dropdownData,
  userName,
  profilePhoto,
}) => {
  const [navbarDropdownOpen, setNavbarDropdownOpen] = useState(false);

  return (
    <div className="850px:hidden flexCenter flex-col animate-mobileMenu bg-white w-full absolute transform pt-3 left-0 top-96% z-20 shadow-lg pb-3 ">
      <Link
        className={`flex justify-center items-center w-1/2 500px:w-1/3 mb-4 `}
        to={
          currentAppUser() === "MENTOR"
            ? "/mentor/dashboard?tab=feeds-chat"
            : "/member/dashboard?tab=feeds"
        }
      >
        <span
          className={
            "bg-brandBlue text-white p-2 rounded-lg text-sm 500px:text-lg"
          }
        >
          {" "}
          Community Chat
        </span>
      </Link>

      <div
        // onClick={() => setProfileDropdownOpen(!profileDropdownOpen)}
        onClick={() => setNavbarDropdownOpen(!navbarDropdownOpen)}
        className=" cursor-pointer flex items-center gap-2 buttonHover activeState"
      >
        <UserNameAndAvatar name={userName} profilePhoto={profilePhoto} />
        <MemberNavbarDownArrow className="stroke-black mx-2" />
      </div>
      {navbarDropdownOpen && (
        <ProfileDropdown
          data={dropdownData}
          setshowLogoutModal={setshowLogoutModal}
          setShowUnsubscribeModal={setShowUnsubscribeModal}
          setProfileDropdownOpen={setProfileDropdownOpen}
          setShowTellAFriendModal={setShowTellAFriendModal}
        />
      )}
    </div>
  );
};
