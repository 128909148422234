import React, { useEffect, useRef } from "react";

const OutsideClickCloseContainer = ({
  setState,
  children,
  OutsideClickFunction,
  classNames,
}) => {
  const dropdownRef = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const handleClickOutside = (e) => {
    if (!dropdownRef.current?.contains(e.target)) {
      OutsideClickFunction && OutsideClickFunction();
      setState && setState(false);
    }
  };

  return (
    <div ref={dropdownRef} className={classNames}>
      {children}
    </div>
  );
};

export default OutsideClickCloseContainer;
