import React, { useState, useEffect, useRef, useCallback } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

import { getMentorsList } from "../../../../AppRedux/actions/AdminMentor";
import ModalOuter from "../../../utilities/modals/ModalOuter";
import FormBackAndContinueButton from "../../MemberRegisterForms/FormBackAndContinueButton";
import { ModalHeader, AppTimePicker, utcDateTimeString } from "../../MyCalendarGeneralItems";
import {
  generateZoomLink,
  createCommunitySession,
  getCommunityList,
} from "../../../../AppRedux/actions/AdminCommunity";
import {
  InputGroup,
  TextArea,
  infoToast,
  Cliploader,
  successToast,
  errorToast,
  RcTooltip,
} from "../../../utilities";
import {
  CloudUploadIcon,
  ZoomIcon,
  CalendarIcon,
  AdminMentorDropdownSelectArrow,
} from "../../../utilities/svg";
import { toast } from "react-toastify";
import { Scaleloader } from "../../../utilities";
import { NoListToShowContainer } from "../../dashboardGeneralItems";

const CreateCommunityModal = ({ state, setState, currentPage }) => {
  const dispatch = useDispatch();
  const [onContinueClick, setOnContinueClick] = useState(false);
  const [calendarPick, setCalendarPick] = useState(false);
  const [formError, setFormError] = useState({});
  const drop = useRef(null);
  const [items, setItems] = useState([]);
  const [mentorsCount, setMentorsCount] = useState();
  const [mentorsListPageNumber, setMentorsListPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [mentorPick, setMentorPick] = useState(false);
  const [mentorName, setMentorName] = useState("");
  const [zoomLinkGenerated, setZoomLinkGenerated] = useState(false);
  const [formData, setformData] = useState({
    title: "",
    description: "",
    onDate: new Date(),
    mentorId: "",
    startTime: "",
    endTime: "",
    zoomJoinLink: "",
    zoomStartLink: "",
    banner: "",
  });

  const [thumbnailUploadedName, setThumbnailUploadedName] = useState("");

  const { getMentorsListBegin, getMentorsListSuccessData } = useSelector(
    ({ adminMentor }) => adminMentor
  );

  const {
    generateZoomLinkBegin,
    generateZoomLinkSuccessData,
    createCommunitySessionBegin,
    createCommunitySessionSuccessData,
    createCommunitySessionFailureData,
  } = useSelector(({ adminCommunity }) => adminCommunity);

  useEffect(() => {
    dispatch(getMentorsList(3));
  }, [dispatch]);

  //dropping file under the area
  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const { files } = e.dataTransfer;

      if (files && files.length) {
        let type = files[0].type.split("/").pop().toLowerCase();
        if (
          files[0].type.split("/").pop().toLowerCase() !== "jpeg" &&
          type !== "jpg" &&
          type !== "png"
        ) {
          infoToast("Please select a valid image file");
        } else {
          setformData({
            ...formData,
            banner: files[0] ? files[0] : "",
          });
          setThumbnailUploadedName(files[0] ? files[0]?.name : "");
        }
      }
    },
    [formData]
  );

  useEffect(() => {
    if (onContinueClick) {
      let dropvalue = drop.current;
      dropvalue.addEventListener("dragover", handleDragOver);
      dropvalue.addEventListener("drop", handleDrop);

      return () => {
        dropvalue.removeEventListener("dragover", handleDragOver);
        dropvalue.removeEventListener("drop", handleDrop);
      };
    }
  }, [onContinueClick, handleDrop]);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (getMentorsListSuccessData) {
      const mentorsList = getMentorsListSuccessData?.data;
      setItems((mentors) => [...mentors, ...mentorsList?.rows]);
      setMentorsCount(mentorsList?.count);
    }
  }, [getMentorsListSuccessData]);

  useEffect(() => {
    if (generateZoomLinkSuccessData) {
      successToast("Zoom link generated successfully");
      setformData({
        ...formData,
        zoomStartLink: generateZoomLinkSuccessData?.data?.zoomStartLink,
        zoomJoinLink: generateZoomLinkSuccessData?.data?.zoomJoinLink,
      });
      setZoomLinkGenerated(true);
    }
    if (createCommunitySessionSuccessData) {
      successToast("Community session created successfully");
      setState(!state);
      dispatch(getCommunityList(currentPage));
    }
    if (createCommunitySessionFailureData) {
      errorToast(createCommunitySessionFailureData?.message);
    }
    setTimeout(() => {
      toast.clearWaitingQueue();
    }, 2000);
  }, [
    generateZoomLinkSuccessData,
    formData,
    dispatch,
    currentPage,
    createCommunitySessionSuccessData,
    createCommunitySessionFailureData,
    setState,
    state,
  ]);

  const customBasicFormValidation = () => {
    const { title, description, mentorId, startTime, endTime } = formData;
    const validationErrors = {};
    if (title?.trim() === "") {
      validationErrors.title = "Title is required.";
    }
    if (title?.trim() !== "" && title.length < 3) {
      validationErrors.title = "please give a valid title.";
    }

    if (description?.trim() === "") {
      validationErrors.description = "Description is required.";
    }
    if (description?.trim() !== "" && description.length < 3) {
      validationErrors.description = "please give a valid description.";
    }
    if (mentorId === "") {
      validationErrors.mentorId = "Mentor selection is required.";
    }
    if (startTime?.trim() === "") {
      validationErrors.startTime = "This field is required.";
    }
    if (endTime?.trim() === "") {
      validationErrors.endTime = "This field is required.";
    }
    return validationErrors;
  };

  const handleInputOnchange = (e) => {
    let value = e.target.value;
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });
    setformData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleUploadImage = (e) => {
    //image validation
    var type = e.target.files[0].type.split("/").pop().toLowerCase();
    if (type !== "jpeg" && type !== "jpg" && type !== "png") {
      infoToast("Please select a valid image file");
    } else {
      setformData({
        ...formData,
        [e.target.name]: e.target.files[0] ? e.target.files[0] : "",
      });
      setThumbnailUploadedName(
        e.target.files[0] ? e.target.files[0]?.name : ""
      );
    }
  };

  const handleZoomButtonClick = () => {

    let newDate = moment(formData.onDate).format("YYYY-MM-DD")
    let datetimeA = moment(newDate + " " + formData.startTime).toISOString();

    const data = {
      title: formData?.title,
      start_time: datetimeA
    };
    dispatch(generateZoomLink(data));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const validationErrors = customBasicFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      setOnContinueClick(true);
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const handleFormFinalSubmit = () => {
    const { banner, zoomStartLink, zoomJoinLink } = formData;
    if (banner === "") {
      if (banner?.trim() === "") {
        infoToast("Please upload a thumbnail");
      }
    } else {
      if (banner?.name.trim() === "") {
        infoToast("Please upload a thumbnail");
      }
    }

    if (banner && zoomStartLink.trim() === "" && zoomJoinLink.trim() === "") {
      infoToast("Please generate zoom link");
    }
    setTimeout(() => {
      toast.clearWaitingQueue();
    }, 2000);

    const validationErrors = customBasicFormValidation();
    if (
      banner !== "" &&
      zoomStartLink.trim() !== "" &&
      zoomJoinLink.trim() !== ""
    ) {
      let payload = formData
      let newDate = moment(formData.onDate).format("YYYY-MM-DD")
      let datetimeA = moment(newDate + " " + formData.startTime).toISOString();

      payload = {
        ...payload,
        onDate: datetimeA,
        endTime: utcDateTimeString(formData.endTime),
        startTime: utcDateTimeString(formData.startTime)
      }

      dispatch(createCommunitySession(payload));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const getTotalNumberOfPages = () => {
    const totalPages = parseInt(mentorsCount / 10),
      leftColumns = mentorsCount - totalPages * 10;
    return leftColumns > 0 ? totalPages + 1 : totalPages;
  };

  const fetchMoreData = () => {
    if (mentorsListPageNumber >= getTotalNumberOfPages()) {
      setHasMore(false);
      return;
    }
    dispatch(getMentorsList(3, mentorsListPageNumber + 1));
    setMentorsListPageNumber(mentorsListPageNumber + 1);
  };

  return (
    <ModalOuter
      state={state}
      setState={setState}
      blockOutsideClick={false}
      classNames="text-left animate-modalInitial min-h-90vh overflow-y-hidden min-w-200px 500px:min-w-470px"
    >
      {onContinueClick === false ? (
        <>
          <ModalHeader
            heading="Create Event"
            closeIcon={true}
            setState={setState}
            hideBorder={true}
            classNames="text-2xl font-semibold px-6 py-4 mt-4  "
            iconClass="top-4 right-4"
          />
          <div
            className="border-b-2 border-solid "
            style={{ borderColor: "#ECECEC" }}
          />
          <div
            className="px-6 scrollbar"
            id="scrollbar"
            style={{
              minWidth: "250px",
              height: "70vh",
              overflow: "auto",
            }}
          >
            <form className="mt-2" onSubmit={(e) => handleFormSubmit(e)}>
              <InputGroup
                label="Enter title"
                name="title"
                onChange={(e) => handleInputOnchange(e)}
                value={formData.title}
                placeholder="Title"
                errorMessage={formError?.title}
              />
              <TextArea
                placeholder="Start typing here..."
                name="description"
                label="Enter description"
                value={formData.description}
                onChange={(e) => handleInputOnchange(e)}
                classNames="mb-0"
                rows="7"
                errorMessage={formError.description}
                setValue={(value) =>
                  setformData({
                    ...formData,
                    description: value,
                  })
                }
              />
              <div
                className="p-4 mt-4 flex justify-between items-center rounded cursor-pointer "
                onClick={() => {
                  setMentorPick(!mentorPick);
                }}
                style={{
                  border: mentorPick
                    ? "2px solid #007CA5"
                    : "1px solid #B9B9BB",
                }}
              >
                <h1>{mentorName === "" ? "Select Mentor" : mentorName} </h1>
                {mentorPick ? (
                  <AdminMentorDropdownSelectArrow className=" rotate-180 " />
                ) : (
                  <AdminMentorDropdownSelectArrow />
                )}
              </div>

              {mentorPick && (
                <>
                  {items?.length > 0 ? (
                    <div
                      className="relative animate-navbar"
                      style={{
                        minWidth: "250px",
                        height: "40vh",
                        overflow: "auto",
                        border: "1px solid #B9B9BB",
                      }}
                      id="mentorsScrollableDiv"
                    >
                      <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="mentorsScrollableDiv"
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            {/* <b>Yay! You have seen it all</b> */}
                          </p>
                        }
                      >
                        {items?.map(
                          ({ id, firstName, lastName, email }, index) => {
                            return (
                              <div
                                onClick={() => {
                                  let sm = formError;
                                  delete sm[["mentorId"]];
                                  setFormError({ ...formError, ...sm });
                                  setMentorName(firstName + " " + lastName);
                                  setformData({
                                    ...formData,
                                    mentorId: id,
                                  });
                                  setMentorPick(false);
                                }}
                                key={index}
                              >
                                {
                                  <h1 className="text-sm p-2 500px:text-base cursor-pointer hover:font-extrabold dashboardHover dashboardActive">
                                    {`${firstName} ${lastName} (${email})`}
                                  </h1>
                                }
                              </div>
                            );
                          }
                        )}
                      </InfiniteScroll>
                    </div>
                  ) : getMentorsListBegin ? (
                    <Scaleloader />
                  ) : (
                    <NoListToShowContainer text="Currently there are no mentors available!" />
                  )}
                </>
              )}
              <p className=" text-red-600 text-base mb-4">
                {formError?.mentorId}
              </p>
              {calendarPick && (
                <div
                  className="absolute w-70% bottom-1/3 left-16 z-50 "
                  style={{
                    boxShadow: "box-shadow: 0px 13px 50px rgba(0, 0, 0, 0.06)",
                    border: "2px solid #FFFFFF",
                    filter: "drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1))",
                  }}
                >
                  <Calendar
                    className={["p-2 rounded "]}
                    onChange={(date) => {
                      //comparing dates which are only after current date
                      var todaysDate = moment(new Date());
                      if (moment(date).diff(todaysDate, "days") >= 0) {
                        setformData({
                          ...formData,
                          onDate: date,
                        });
                        setCalendarPick(false);
                      } else {
                        infoToast("Please select a future date");
                      }
                    }}
                    value={formData.onDate}
                  />
                </div>
              )}
              <div
                className="flex justify-between items-center rounded w-full p-4 mb-2"
                style={{
                  border: calendarPick
                    ? "2px solid #007CA5"
                    : "1px solid #B9B9BB",
                }}
                onClick={() => setCalendarPick(!calendarPick)}
              >
                <h6>{moment(formData.onDate).format("Do MMMM YYYY")}</h6>
                <CalendarIcon className="cursor-pointer activeState" />
              </div>
              <div className="flex justify-between items-center mt-4 w-full">
                <div
                  className="flex justify-between items-center rounded px-4 w-1/2"
                  style={{
                    border: "1px solid #B9B9BB",
                  }}
                >
                  From
                  <AppTimePicker
                    required={false}
                    className="flex-1"
                    value={formData.startTime}
                    backgroundColor="#FFFFFF"
                    onChange={(value) => {
                      let sm = formError;
                      delete sm[["startTime"]];
                      setFormError({ ...formError, ...sm });
                      setformData({
                        ...formData,
                        startTime: value,
                      });
                    }}
                  />
                </div>
                <div
                  className="flex justify-between items-center rounded px-2 w-2/5 "
                  style={{
                    border: "1px solid #B9B9BB",
                  }}
                >
                  To
                  <AppTimePicker
                    required={false}
                    className="flex-1"
                    value={formData.endTime}
                    backgroundColor="#FFFFFF"
                    onChange={(value) => {
                      let sm = formError;
                      delete sm[["endTime"]];
                      setFormError({ ...formError, ...sm });
                      setformData({
                        ...formData,
                        endTime: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex space-x-24">
                <p className=" text-red-600 text-base mb-4 ">
                  {formError?.startTime}
                </p>
                <p className=" text-red-600 text-base mb-4 ">
                  {formError?.endTime}
                </p>
              </div>
              <FormBackAndContinueButton
                buttonText="Continue"
                backButtonText="Cancel"
                onBackButtonClick={() => setState(false)}
                buttonClass="py-2 mb-4 text-base"
              />
            </form>
          </div>
        </>
      ) : (
        <>
          <ModalHeader
            heading="Create Event"
            closeIcon={false}
            setState={setState}
            hideBorder={true}
            classNames="text-2xl font-semibold px-6 py-4 mt-4"
          />
          <div className="px-6 py-4">
            <h1>Upload your thumbnail</h1>

            <div
              ref={drop}
              className="py-12 px-4 my-4"
              style={{
                border: " 1px dashed #B9B9BB",
                background: "#F7F9FB",
                borderRadius: "4px",
              }}
            >
              <div className="relative flex flex-col justify-center items-center z-50 ">
                <CloudUploadIcon />
                <div>
                  Drop your files here or
                  <label
                    style={{ color: "#008DFF" }}
                    className="activeState hover:underline font-bold cursor-pointer"
                    htmlFor="uploadThumbnail"
                  >
                    {thumbnailUploadedName !== "" ? " Change" : " Browse"}
                  </label>
                  <input
                    type="file"
                    name="banner"
                    id="uploadThumbnail"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={(e) => handleUploadImage(e)}
                    className="hidden"
                  />
                  {thumbnailUploadedName !== "" && (
                    <div className="flexCenter">
                      <p className="border-2 border-brandBlue text-center text-brandBlue px-2 animate-navbar rounded-xl text-sm">
                        {thumbnailUploadedName}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <p className="text-red-600">
              Please note that the image to upload will be a banner for the
              events being hosted hence it should be in (.png or .jpeg) format.
            </p>
            <button
              className={`text-white flex flex-row justify-center items-center gap-2 p-2 activeState mt-4 mb-8 w-full ${zoomLinkGenerated ? "cursor-not-allowed" : ""
                }`}
              style={{ background: "#008DFF", borderRadius: "7px" }}
              onClick={() => (zoomLinkGenerated ? "" : handleZoomButtonClick())}
            >
              {zoomLinkGenerated ? (
                <RcTooltip content={formData?.zoomJoinLink}>
                  <p>{formData?.zoomJoinLink}</p>
                </RcTooltip>
              ) : (
                <>
                  <ZoomIcon className="mx-2" />
                  <p> Generate Zoom Link</p>
                </>
              )}
              {generateZoomLinkBegin && <Cliploader />}
            </button>
            <FormBackAndContinueButton
              buttonText="Continue"
              backButtonText="Go back"
              onBackButtonClick={() => setOnContinueClick(false)}
              actionBegin={createCommunitySessionBegin}
              buttonClass="py-2 text-base"
              onClick={() => handleFormFinalSubmit()}
            />
          </div>
        </>
      )}
    </ModalOuter>
  );
};

export default CreateCommunityModal;
