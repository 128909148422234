import { useMemo, useEffect } from "react";
import Navbar from "../components/pageComponents/Navbar";
import LeftSideWelcomeSection from "../components/pageComponents/LeftSideWelcomeSection";
import ForgotPasswordForm from "../components/pageComponents/Login/ForgotPasswordForm";
import SeoData from "../components/utilities/seo/seoData.json";
import {
  ReactHelmet,
  ProgressLoader,
  progressLoaderFunction,
  SunFooter,
} from "../components/utilities";

const ForgotPassword = () => {
  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.forgotPassword} />
          <ProgressLoader />
          <Navbar
            whiteNavbar={true}
            fixedNavbar={false}
            noShadow={true}
            onlyTopBar={true}
          />
          <section className="w-full lg:flex min-h-90vh">
            <LeftSideWelcomeSection />
            <ForgotPasswordForm />
          </section>
          <SunFooter />
        </>
      ),
      []
    );

  return <Content />;
};

export default ForgotPassword;
