import { CometChat } from "@cometchat-pro/chat";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { cometChatInitialising } from ".";
import "../../../components/utilities/styles/cometChat.css";
import { Cliploader } from "../../../components/utilities";
import { showChatWidget } from "../../../components/pageComponents/MyCalendarGeneralItems";

const LoginToCometChatMeet = ({ meetingData, userType, text, classNames }) => {
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);

  const { getAppResourcesSuccessData } = useSelector(
    ({ homeReducer }) => homeReducer
  );

  // Login to comet chat
  const loginToCometChat = async (username, UID) => {
    const API_KEY = "13f5bc6ef89bb9725ec04558defd0bb00343fcbf";
    var user = new CometChat.User(UID);
    user.setName(username);
    if (username) {
      CometChat.login(UID, API_KEY).then(
        () => {
          if (userType === "member") {
            navigate("/member/dashboard/meeting", {
              state: { meetingData, userType },
            });
          } else if (userType === "mentor") {
            navigate("/mentor/dashboard/meeting", {
              state: { meetingData, userType },
            });
          }
          startCall();
          setButtonLoader(false);
        },
        (error) => {
          console.log("Login failed with exception:", { error });
          setButtonLoader(false);
        }
      );
    } else {
      if (userType === "member") {
        navigate("/member/dashboard/meeting", {
          state: { meetingData, userType },
        });
      } else if (userType === "mentor") {
        navigate("/mentor/dashboard/meeting", {
          state: { meetingData, userType },
        });
      }
      startCall();
    }
  };

  // start call
  const startCall = () => {
    let sessionID = `${meetingData[0].mentorUid}-${meetingData[0].memberUid}`;
    let audioOnly = false;
    let defaultLayout = true;

    let callSettings = new CometChat.CallSettingsBuilder()
      .enableDefaultLayout(defaultLayout)
      .setSessionID(sessionID)
      .setIsAudioOnlyCall(audioOnly)
      .startWithVideoMuted(true)
      .build();

    CometChat.startCall(
      callSettings,
      document.getElementById("callScreen"),
      new CometChat.OngoingCallListener({
        onCallEnded: () => {
          const { meetingDate, toTime } = meetingData[0];

          const MeetingEndingTime = moment(new Date(meetingDate))
            .hour(toTime.split(":")[0])
            .minute(toTime.split(":")[1]);

          if (MeetingEndingTime.isAfter()) {
            if (userType === "member") {
              navigate("/member/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callEnded: true,
                },
              });
            } else if (userType === "mentor") {
              navigate("/mentor/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callEnded: true,
                },
              });
            }
          } else {
            if (userType === "member") {
              navigate("/member/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callTimeEnded: true,
                },
              });
            } else if (userType === "mentor") {
              navigate("/mentor/dashboard/meeting", {
                state: {
                  meetingData,
                  userType,
                  callTimeEnded: true,
                },
              });
            }
          }
        },
      })
    );
  };

  // on button click
  const startSessionOnClick = () => {
    setButtonLoader(true);
    cometChatInitialising(getAppResourcesSuccessData?.cometAppId);
    if (userType === "member") {
      loginToCometChat(meetingData[0].userName, meetingData[0].memberUid);
    } else if (userType === "mentor") {
      loginToCometChat(meetingData[0].userName, meetingData[0].mentorUid);

      showChatWidget(
        getAppResourcesSuccessData?.cometAppId,
        getAppResourcesSuccessData?.cometAuthKey,
        meetingData
      );
    }
  };

  return (
    <button
      className={`memberDashboardButton flexCenter gap-2 py-1 text-white bg-brandBlue activeState buttonHover ${classNames}`}
      onClick={() => startSessionOnClick()}
    >
      {text ? text : "Start Session"} {buttonLoader && <Cliploader />}
    </button>
  );
};

export default LoginToCometChatMeet;
