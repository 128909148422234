import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { submitMentorOnboardForm } from "../../../AppRedux/actions/MentorLogin";

import PageData from "../../utilities/content/pages.json";
import {
  SubscriptionPageBlueArrow,
  // LoginLockIcon
} from "../../utilities/svg";
// import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";
import {
  SelectDropdown,
  UserNameAndAvatar,
  FormButton,
  Cliploader,
  successToast,
  errorToast,
} from "../../utilities";
import { createCometChatUser } from "../../../AppRedux/actions/MemberCometChat";

const MentorClientSelection = ({
  // continueButtonloader,
  mentorOnboardData,
  // changeToNextPage,
  onBackButtonClick,
  mentorName,
  mentorToken,
}) => {
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [mentorClientSelectionData, setMentorClientSelectionData] = useState({
    mentor_client_selection: "",
    mentor_hour_selection: "",
    ...mentorOnboardData,
  });

  const { description, selectInput1, selectInput2 } =
    PageData.mentorOnboardPageData.clientSelectionDetailsForm;

  const {
    submitMentorOnboardFormSuccessData,
    submitMentorOnboardFormBegin,
    submitMentorOnboardFormFailureData,
  } = useSelector(({ mentorLogin }) => mentorLogin);
  const { createCometChatUserSuccessData } = useSelector(
    ({ memberCometChat }) => memberCometChat
  );

  useEffect(() => {
    if (submitMentorOnboardFormSuccessData?.success) {
      const { uid, firstName, lastName, email, phone } =
        submitMentorOnboardFormSuccessData.data.user;
      const cometChatData = {
        uid: uid,
        name: `${firstName} ${lastName}`,
        role: "2",
        metadata: {
          email: email,
          phoneNumber: phone,
        },
        withAuthToken: true,
      };
      dispatch(
        createCometChatUser(
          "13f5bc6ef89bb9725ec04558defd0bb00343fcbf",
          cometChatData
        )
      );
    }
    if (submitMentorOnboardFormFailureData?.success) {
      errorToast(submitMentorOnboardFormFailureData.message);
    }
  }, [
    submitMentorOnboardFormSuccessData,
    submitMentorOnboardFormFailureData,
    dispatch,
  ]);

  useEffect(() => {
    if (createCometChatUserSuccessData) {
      successToast("Successfully Completed the onboarding process");
      setRedirectToLogin(true);
    }
  }, [createCometChatUserSuccessData]);

  if (redirectToLogin) {
    return <Navigate to="/login/mentor" />;
  }

  const handleInputOnchange = (e) => {
    let value = e.target.value;
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setMentorClientSelectionData({
      ...mentorClientSelectionData,
      [e.target.name]: value,
    });
  };

  const customMentorBasicFormValidation = () => {
    const { mentor_client_selection, mentor_hour_selection } =
      mentorClientSelectionData;
    const validationErrors = {};
    if (mentor_client_selection?.trim() === "") {
      validationErrors.mentor_client_selection = "This Field is required.";
    }
    if (mentor_hour_selection?.trim() === "") {
      validationErrors.mentor_hour_selection = "This Field is required.";
    }

    return validationErrors;
  };

  const onMentorClientSelectionSubmit = (e) => {
    e.preventDefault();
    const validationErrors = customMentorBasicFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      const data = {
        password: mentorOnboardData.password,
        clientsLimit: mentorClientSelectionData.mentor_client_selection,
        availableHours: mentorClientSelectionData.mentor_hour_selection,
      };
      const token = `Bearer ${mentorToken}`;
      dispatch(submitMentorOnboardForm(token, data));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }

    // e.preventDefault();
    // const validationErrors = customMentorBasicFormValidation();
    // if (Object.keys(validationErrors).length === 0) {
    //   changeToNextPage("bank_information", mentorClientSelectionData);
    // } else {
    //   setFormError({ ...formError, ...validationErrors });
    // }
  };

  return (
    <section className=" relative homepadeWidth 500px:w-96% 950px:w-9/12 lg:w-70% xl:w-2/4 mx-auto flex flex-col ">
      <div className="flex flex-row px-12 mt-6">
        <div
          className="lg:absolute mb-6 lg:mb-0 lg:-left-10 xl:left-24 flex items-center gap-2  cursor-pointer hover:opacity-80  activeState"
          onClick={() => onBackButtonClick("setup_password")}
        >
          <span
            className="h-8 w-8 rounded-full flexCenter"
            style={{
              boxShadow:
                " 0px 100px 80px rgba(0, 0, 0, 0.09), 0px 36.5016px 29.2013px rgba(0, 0, 0, 0.0620983), 0px 17.7209px 14.1767px rgba(0, 0, 0, 0.0500662), 0px 8.6871px 6.94968px rgba(0, 0, 0, 0.0399338), 0px 3.43489px 2.74791px rgba(0, 0, 0, 0.0279017)",
            }}
          >
            <SubscriptionPageBlueArrow />
          </span>
          <p className="font-medium text-base text-brandGrey">
            Back to Password settings
          </p>
        </div>
      </div>
      <div className="flex flexCenter w-96% sm:w-4/6 mx-auto mb-16">
        <div
          className="w-full bg-white rounded-xl mt-6 sm:mt-14 px-3 500px:px-7 sm:px-8 py-6 xl:py-10 h-auto z-10"
          style={{ boxShadow: "0px 14px 49px rgba(0, 0, 0, 0.07)" }}
        >
          <h1 className="font-bold mb-8 mt-8 xl:mt-5 text-xl sm:text-2xl lg:text-3xl 1460px:text-4xl text-center">
            Welcome!
          </h1>
          <UserNameAndAvatar name={mentorName} />
          <p
            className="w-full text-sm font-medium 500px:text-base text-center mb-4 mt-8 "
            style={{ color: "#757575" }}
          >
            {description}
          </p>
          <form
            className=" px-4 w-full"
            onSubmit={(e) => onMentorClientSelectionSubmit(e)}
          >
            <SelectDropdown
              name={selectInput1.name}
              placeholder={selectInput1.label}
              value={mentorClientSelectionData.mentor_client_selection}
              onChange={handleInputOnchange}
              optionsArray={selectInput1.optionsArray}
              errorMessage={formError?.mentor_client_selection}
              classNames="selectInputGroupOnboard"
              style={{
                borderColor: "#141415",
                color: "#5A5A5A",
              }}
              placeholderDropdown={false}
            />
            <SelectDropdown
              name={selectInput2.name}
              placeholder={selectInput2.label}
              value={mentorClientSelectionData.mentor_hour_selection}
              onChange={handleInputOnchange}
              optionsArray={selectInput2.optionsArray}
              errorMessage={formError?.mentor_hour_selection}
              classNames="selectInputGroupOnboard"
              style={{
                borderColor: "#141415",
                color: "#5A5A5A",
              }}
              placeholderDropdown={false}
            />
            {/* <div className="my-14">
              <FormBackAndContinueButton
                actionBegin={continueButtonloader}
                buttonText="Submit"
                twoButtons={false}
              />
            </div> */}
            <div className="mt-8">
              <FormButton
                disabled={submitMentorOnboardFormBegin}
                classNames={`bg-brandBlue text-white ${
                  submitMentorOnboardFormBegin
                    ? "cursor-wait hover:opacity-100"
                    : "buttonHover"
                }`}
              >
                {/* <LoginLockIcon /> */}
                Submit
                {submitMentorOnboardFormBegin && <Cliploader />}
              </FormButton>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default MentorClientSelection;
