import {
  GET_MENTOR_PROFILE_DETAILS_BEGIN,
  GET_MENTOR_PROFILE_DETAILS_SUCCESS,
  GET_MENTOR_PROFILE_DETAILS_FAILURE,
  RESET_GET_MENTOR_PROFILE_DETAILS,
  UPDATE_MENTOR_PROFILE_DETAILS_BEGIN,
  UPDATE_MENTOR_PROFILE_DETAILS_SUCCESS,
  UPDATE_MENTOR_PROFILE_DETAILS_FAILURE,
  RESET_UPDATE_MENTOR_PROFILE_DETAILS,
} from "../constants/MentorProfile";

import { httpApp } from "../../axios/httpUser";

/**
 *  get transaction history
 */

export function getMentorInfo() {
  return (dispatch) => {
    dispatch({
      type: GET_MENTOR_PROFILE_DETAILS_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/mentor/profile`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTOR_PROFILE_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_PROFILE_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTOR_PROFILE_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_PROFILE_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 *  update mentor details
 */
export function updateMentorDetails(args) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MENTOR_PROFILE_DETAILS_BEGIN,
    });

    var form = new FormData();
    Object.keys(args).map((i) => form.append(i, args[i]));

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/mentor/profile`, form);

      doRequest.then(
        (res) => {
          dispatch({
            type: UPDATE_MENTOR_PROFILE_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UPDATE_MENTOR_PROFILE_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UPDATE_MENTOR_PROFILE_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UPDATE_MENTOR_PROFILE_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
