import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { successToast } from "../../../utilities";
import moment from "moment";

import { ReactHelmet } from "../../../utilities";
import SeoData from "../../../utilities/seo/seoData.json";
import { NoListToShowContainer } from "../../dashboardGeneralItems";
import { ListHeader } from "../tableComponents/ExercisesList";
import { GetFreePlanList, ClaimSubscription } from "../../../../AppRedux/actions/MemberReferal";
import { MobileResponsiveAccordion } from "../../../utilities/svg";
import { Scaleloader } from "../../../utilities";

const ReferralTab = () => {

    const dispatch = useDispatch();
    const [clicked, setClicked] = useState(false)
    const [rewards, setRewards] = useState("")
    const { getFreePlanListBegin, getFreePlanListSuccessData, claimSubscriptionPlanSuccessData } = useSelector(
        ({ TellAfriendReducer }) => TellAfriendReducer
    );

    useEffect(() => {
        dispatch(GetFreePlanList())
    }, [dispatch])

    useEffect(() => {
        if (getFreePlanListSuccessData)
            setRewards(getFreePlanListSuccessData.data)
        if (claimSubscriptionPlanSuccessData) {
            successToast("Reward Claimed Successfully")
            dispatch(GetFreePlanList())
        }
    }, [dispatch, getFreePlanListSuccessData, claimSubscriptionPlanSuccessData])

    const claimReward = (id) => {
        dispatch(ClaimSubscription(id))
    }
    const toggle = (index) => {
        if (clicked === index) {
            return setClicked(null);
        }
        setClicked(index);
    };

    return (
        <>
            <div className="overflow-x-auto 500px:overflow-x-hidden ">
                <div
                    className=" font-normal flex pt-2  px-10"
                    style={{ backgroundColor: "#FAFAFA", minWidth: "580px" }}
                >
                    <ReactHelmet data={SeoData.memberReferral} />
                </div>
            </div>
            <div>
                <section className="mt-4 px-4 950px:px-6 min-h-80vh relative pb-14">
                    <ListHeader
                        headers={headers}
                        gridClass="hidden md:grid text-center grid-cols-5"
                    />

                    <div className="member-table-row-items">
                        {!getFreePlanListBegin && rewards ? (
                            rewards?.length !== 0 ? (
                                rewards?.map(({ id, status, startDate, endDate }, index) => {

                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className={`hidden md:grid dashboardListHover text-center member-table-row-item grid-cols-5  gap-2 font-medium text-sm xl:text-base cursor-default ${index === rewards.length - 1 ? "border-none" : ""}`}
                                            >
                                                <p className="oneLineContent">{id}</p>
                                                <p className="oneLineContent">{status}</p>
                                                <p className="oneLineContent">{startDate ? moment(startDate).format("MM-DD-YYYY") : ""}</p>
                                                <p className="oneLineContent">{endDate ? moment(endDate).format("MM-DD-YYYY") : ""}</p>
                                                <p
                                                    className={`${status === "In-Progress" || status === "Claimed" ? "text-green-500 " : "hover:text-blue-400 cursor-pointer hover:underline"}`}
                                                    onClick={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            claimReward(id)
                                                        }
                                                    }

                                                >
                                                    {status === "In-Progress" || status === "Claimed" ? "Claimed" : "Click here to claim"}
                                                </p>



                                            </div>

                                            {/* Mobile Responsive      */}
                                            <div
                                                onClick={() => {
                                                    toggle(id);
                                                }}
                                                className="cursor-pointer  850px:hidden px-2 sm:px-4 py-3 sm:py-6 m-2 rounded-r"
                                                style={{ background: "#fbfbfb" }}
                                            >
                                                <div className="flex justify-between items-center">
                                                    <h1>Reward {index + 1}</h1>
                                                    <span
                                                        style={{ color: "#202020" }}
                                                        className={`text-xl cursor-pointer pr-2 ${clicked === id ? "animate-navbar" : ""
                                                            }`}
                                                    >
                                                        <MobileResponsiveAccordion
                                                            className={`cursor-pointer ${clicked === id ? "transform rotate-180 " : ""
                                                                }`}
                                                        />
                                                    </span>
                                                </div>
                                                {clicked === id && (
                                                    <div className="animate-navbar grid grid-cols-2 mt-2 gap-2 ">
                                                        <h1>Referral Id</h1>
                                                        <p className="text-xs 370px:text-base md:text-lg overflow-hidden">
                                                            {id}
                                                        </p>
                                                        <p>Status</p>
                                                        <p>{status}</p>
                                                        <p>Plan</p>
                                                        <p>Start Date</p>
                                                        <p className="oneLineContent">{startDate ? moment(startDate).format("MM-DD-YYYY") : ""}</p>
                                                        <p>End Date</p>
                                                        <p className="oneLineContent">{endDate ? moment(endDate).format("MM-DD-YYYY") : ""}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    );
                                })
                            ) : (
                                <NoListToShowContainer text="There are no rewards" />
                            )
                        ) : (
                            <Scaleloader />
                        )}
                    </div>

                </section>
            </div>
        </>
    );
};

export default ReferralTab;


const headers = [
    "Referral ID  ",
    "Status",
    "Start Date",
    "End Date",
    "Action"
];