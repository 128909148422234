import {
  SUBMIT_MENTOR_REGISTRATION_FORM_BEGIN,
  SUBMIT_MENTOR_REGISTRATION_FORM_SUCCESS,
  SUBMIT_MENTOR_REGISTRATION_FORM_FAILURE,
  RESET_SUBMIT_MENTOR_REGISTRATION_FORM,

  MENTOR_TOKEN_VERIFICATION_BEGIN,
  MENTOR_TOKEN_VERIFICATION_FAILURE,
  MENTOR_TOKEN_VERIFICATION_SUCCESS,
  RESET_MENTOR_TOKEN_VERIFICATION,
} from "../constants/MentorRegistration";

const initialState = {
  submitMentorRegistrationFormBegin: false,
  submitMentorRegistrationFormSuccess: false,
  submitMentorRegistrationFormSuccessData: null,
  submitMentorRegistrationFormFailure: false,
  submitMentorRegistrationFormFailureData: null,

  mentorTokenVerificationBegin: false,
  mentorTokenVerificationSuccess: false,
  mentorTokenVerificationSuccessData: null,
  mentorTokenVerificationFailure: false,
  mentorTokenVerificationFailureData: null,
};

export const mentorRegistration = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // Submit registration form
    case SUBMIT_MENTOR_REGISTRATION_FORM_BEGIN:
      return {
        ...state,
        submitMentorRegistrationFormBegin: true,
        submitMentorRegistrationFormSuccess: false,
        submitMentorRegistrationFormSuccessData: null,
        submitMentorRegistrationFormFailure: false,
        submitMentorRegistrationFormFailureData: null,
      };
    case SUBMIT_MENTOR_REGISTRATION_FORM_SUCCESS:
      return {
        ...state,
        submitMentorRegistrationFormBegin: false,
        submitMentorRegistrationFormSuccess: true,
        submitMentorRegistrationFormSuccessData: data,
        submitMentorRegistrationFormFailure: false,
        submitMentorRegistrationFormFailureData: null,
      };
    case SUBMIT_MENTOR_REGISTRATION_FORM_FAILURE:
      return {
        ...state,
        submitMentorRegistrationFormBegin: false,
        submitMentorRegistrationFormSuccess: false,
        submitMentorRegistrationFormSuccessData: null,
        submitMentorRegistrationFormFailure: true,
        submitMentorRegistrationFormFailureData: data,
      };
    case RESET_SUBMIT_MENTOR_REGISTRATION_FORM:
      return {
        ...state,
        submitMentorRegistrationFormBegin: false,
        submitMentorRegistrationFormSuccess: false,
        submitMentorRegistrationFormSuccessData: null,
        submitMentorRegistrationFormFailure: false,
        submitMentorRegistrationFormFailureData: null,
      };


          // token verification
    case MENTOR_TOKEN_VERIFICATION_BEGIN:
      return {
        ...state,
        mentorTokenVerificationBegin: true,
        mentorTokenVerificationSuccess: false,
        mentorTokenVerificationSuccessData: null,
        mentorTokenVerificationFailure: false,
        mentorTokenVerificationFailureData: null,
      };
    case MENTOR_TOKEN_VERIFICATION_SUCCESS:
      return {
        ...state,
        mentorTokenVerificationBegin: false,
        mentorTokenVerificationSuccess: true,
        mentorTokenVerificationSuccessData: data,
        mentorTokenVerificationFailure: false,
        mentorTokenVerificationFailureData: null,
      };
    case MENTOR_TOKEN_VERIFICATION_FAILURE:
      return {
        ...state,
        mentorTokenVerificationBegin: false,
        mentorTokenVerificationSuccess: false,
        mentorTokenVerificationSuccessData: null,
        mentorTokenVerificationFailure: true,
        mentorTokenVerificationFailureData: data,
      };
    case RESET_MENTOR_TOKEN_VERIFICATION:
      return {
        ...state,
        mentorTokenVerificationBegin: false,
        mentorTokenVerificationSuccess: false,
        mentorTokenVerificationSuccessData: null,
        mentorTokenVerificationFailure: false,
        mentorTokenVerificationFailureData: null,
      };
    default:
      return state;
  }
};
