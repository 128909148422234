import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import postalCodes from "postal-codes-js";
import "react-phone-number-input/style.css";
import { useNavigate } from "react-router-dom";

import { checkUserEmail } from "../../../AppRedux/actions/MemberRegistration";
import { getStates } from "../../../AppRedux/actions/Home";
import PageData from "../../utilities/content/pages.json";
import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";
import { userBasicDetailsValidation } from "../formValidations";
import {
  scrollToTop,
  InputGroup,
  PhoneNumberInput,
  SelectDropdown,
  errorToast,
} from "../../utilities";
import { country } from "../../utilities/content/country";

const MentorPersonalInformation = ({
  changeToNextPage,
  mentorRegisterData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formError, setFormError] = useState({});
  const [mentorPersonalInfoData, setMentorPersonalInfoData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    phone: "",
    ...mentorRegisterData,
  });

  window.location.hash = 'step1';

  const { heading2, selectInput1 } =
    PageData.mentorRegistrationFormData.addressDetailsFormData;

  const { getStatesSuccessData } =
    useSelector(({ homeReducer }) => homeReducer);

  useEffect(() => {
    if (mentorPersonalInfoData.country) {
      dispatch(getStates(mentorPersonalInfoData.country));
    }
  }, [dispatch, mentorPersonalInfoData.country]);

  useEffect(() => {
    if (getStatesSuccessData) {
      selectInput1[1].optionsArray = getStatesSuccessData?.states;
    }
  }, [getStatesSuccessData, selectInput1]);

  // selectInput1[0].optionsArray = getCountriesResourcesSuccessData?.countries;
  selectInput1[0].optionsArray = country;

  const {
    checkUserRegisterEmailBegin,
    checkUserRegisterEmailFailureData,
    checkUserRegisterEmailSuccessData,
  } = useSelector(({ memberRegistration }) => memberRegistration);

  useEffect(() => {
    if (checkUserRegisterEmailFailureData) {
      errorToast("Email Id Already Existing");
    }
  }, [checkUserRegisterEmailFailureData]);

  useEffect(() => {
    if (checkUserRegisterEmailSuccessData) {
      scrollToTop("50", "16.66");
      changeToNextPage("mentor_registration_features", mentorPersonalInfoData);
    }
  }, [
    changeToNextPage,
    checkUserRegisterEmailSuccessData,
    mentorPersonalInfoData,
  ]);

  const handleInputOnchange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z]/gi, "");
    }

    let sm = formError;
    delete sm[[e.target.name]];
    if (
      postalCodes.validate(
        mentorPersonalInfoData.country,
        mentorPersonalInfoData.zip
      ) !== true
    ) {
      setFormError({ ...formError, ...sm, zip: "" });
    } else {
      setFormError({ ...formError, ...sm });
    }

    setMentorPersonalInfoData({
      ...mentorPersonalInfoData,
      [e.target.name]: value,
    });
  };

  const onMentorPersonalDetailsSubmit = (e) => {
    e.preventDefault();
    const validationErrors = userBasicDetailsValidation(mentorPersonalInfoData);
    if (Object.keys(validationErrors).length === 0) {
      const emailData = {
        email: mentorPersonalInfoData.email,
      };
      dispatch(checkUserEmail(emailData));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <section className="homepageWidth w-full px-4 md:w-70% mx-auto pt-4 sm:mb-12 ">
      <h1 className="font-bold text-3xl text-center sm:mt-8 mt-4">
        Personal Information
      </h1>

      <form
        className="mt-8 sm:mt-20 "
        onSubmit={(e) => onMentorPersonalDetailsSubmit(e)}
        id="mentor_form"
      >
        <div className="relative md:grid md:grid-cols-1 950px:grid-cols-2 gap-y-2 950px:gap-x-14 950px:gap-y-6">
          <InputGroup
            label="First Name"
            name="firstName"
            onChange={handleInputOnchange}
            value={mentorPersonalInfoData?.firstName}
            errorMessage={formError?.firstName}
            alphabetsOnly={true}
          />
          <InputGroup
            label="Last Name"
            name="lastName"
            onChange={handleInputOnchange}
            value={mentorPersonalInfoData?.lastName}
            errorMessage={formError?.lastName}
            alphabetsOnly={true}
          />

          <InputGroup
            label="Email"
            name="email"
            onChange={handleInputOnchange}
            value={mentorPersonalInfoData?.email}
            errorMessage={formError?.email}
          />
          <PhoneNumberInput
            name="phone"
            value={mentorPersonalInfoData.phone}
            onChange={(value) => {
              formError.phone = "";
              value === undefined
                ? setMentorPersonalInfoData({
                  ...mentorPersonalInfoData,
                  phone: "",
                })
                : setMentorPersonalInfoData({
                  ...mentorPersonalInfoData,
                  phone: value,
                });
            }}
            errorMessage={formError?.phone}
          />

          <h1 className="font-semibold  text-2xl mt-4 950px:mt-0">
            {heading2}
          </h1>
          <br />
          <InputGroup
            label="Address 1"
            name="address1"
            onChange={handleInputOnchange}
            value={mentorPersonalInfoData?.address1}
            errorMessage={formError?.address1}
          />
          <InputGroup
            label="Address 2"
            name="address2"
            onChange={handleInputOnchange}
            value={mentorPersonalInfoData?.address2}
          // errorMessage={formError?.address2}
          />

          <SelectDropdown
            label={selectInput1[0].label}
            name={selectInput1[0].name}
            value={mentorPersonalInfoData?.country}
            onChange={(e) => {
              mentorPersonalInfoData.state = "";
              mentorPersonalInfoData.city = "";
              handleInputOnchange(e);
            }}
            optionsArray={selectInput1[0].optionsArray}
            errorMessage={formError?.country}
          />
          <SelectDropdown
            label={selectInput1[1].label}
            name={selectInput1[1].name}
            value={mentorPersonalInfoData?.state}
            onChange={(e) => {
              mentorPersonalInfoData.city = "";
              handleInputOnchange(e);
            }}
            optionsArray={selectInput1[1].optionsArray}
            errorMessage={formError?.state}
          />
          <InputGroup
            label={selectInput1[2].label}
            name={selectInput1[2].name}
            value={mentorPersonalInfoData?.city}
            onChange={handleInputOnchange}
            errorMessage={formError?.city}
          />
          <InputGroup
            label="Zip"
            name="zip"
            onChange={handleInputOnchange}
            value={mentorPersonalInfoData?.zip}
            errorMessage={formError?.zip}
          />
        </div>
        <div className="w-full md:w-3/4 mx-auto my-12">
          <FormBackAndContinueButton
            onBackButtonClick={() => navigate("/")}
            backButtonText="Go to main page"
            actionBegin={checkUserRegisterEmailBegin}
          />
        </div>
      </form>
    </section>
  );
};

export default MentorPersonalInformation;
