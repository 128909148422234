import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { url } from "../../utilities/generalItems";
import { storeMemberDashboardDetailsInLocalStorage } from "../../utilities";
import {
  getMentorDashboardDetails,
  getMentorProfile,
} from "../../../AppRedux/actions/MentorDashborad";
import MentorProfileCoverPicture from "../../../assets/Images/MentorProfileCoverPicture.png";
import { Scaleloader, UserAvatar } from "../../utilities";
import { MentorRatingContainer } from "../dashboardGeneralItems";
import ChatWidgetModal from "./ChatWidgetModal";

const MentorLeftSideProfileCard = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const id = params.getAll("id").toString();
  const [dashboardDetails, setDashboardDetails] = useState("");
  const [profileData, setprofileData] = useState("");
  const { getMentorProfileSuccessData } = useSelector(
    ({ mentorDashboard }) => mentorDashboard
  );
  const { getClientListSuccessData } = useSelector(
    ({ mentorMyClients }) => mentorMyClients
  );
  const {
    getMentorDashboardDetailsSuccessData,
    getMentorDashboardDetailsBegin,
  } = useSelector(({ mentorDashboard }) => mentorDashboard);

  const { getAppResourcesSuccessData } = useSelector(
    ({ homeReducer }) => homeReducer
  );

  useEffect(() => {
    dispatch(getMentorDashboardDetails());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getMentorProfile());
  }, [dispatch]);
  useEffect(() => {
    if (getMentorProfileSuccessData) {
      setprofileData(getMentorProfileSuccessData.data);
    }
  }, [getMentorProfileSuccessData]);

  useEffect(() => {
    if (getMentorDashboardDetailsSuccessData) {
      setDashboardDetails(getMentorDashboardDetailsSuccessData?.data);
      storeMemberDashboardDetailsInLocalStorage(
        getMentorDashboardDetailsSuccessData?.data
      );
    }
  }, [getMentorDashboardDetailsSuccessData]);

  useEffect(() => {
    if (id && getClientListSuccessData) {
      if (getMentorProfileSuccessData) {
        if (!document.getElementById("cometchat__widget")) {
          const { uid } = getMentorProfileSuccessData.data;

          window.CometChatWidget.init({
            appID: getAppResourcesSuccessData?.cometAppId,
            appRegion: "us",
            authKey: getAppResourcesSuccessData?.cometAuthKey,
          }).then(
            (response) => {
              window.CometChatWidget.login({
                uid: uid,
              }).then(() => {
                // comet chat widget launch
                window.CometChatWidget.launch({
                  widgetID: "5e724e26-111b-42bb-8610-4e255a89edad",
                  docked: "true",
                  alignment: "right", //left or right
                  target: "#cometchat",
                  roundedCorners: "true",
                  height: "400px",
                  width: "500px",
                  defaultID: getClientListSuccessData?.data.rows.find(
                    (value) =>
                      value.UserMember !== null &&
                      value.UserMember.User.id === parseInt(id)
                  ).UserMember.User.uid,
                  defaultType: "user",
                });
              });
            },
            (error) => {
              console.log("Initialization failed with error:", error);
            }
          );
        }
      }
    }
  }, [
    getMentorProfileSuccessData,
    getClientListSuccessData,
    id,
    getAppResourcesSuccessData,
  ]);

  return (
    <div
      className="rounded-10px shadow-memberDashboard bg-white member-leftside-profile-card pb-10 min-h-80vh"
      style={{ color: "#003547" }}
    >
      {!getMentorDashboardDetailsBegin && dashboardDetails ? (
        <MentorProfileInfo
          dashboardDetails={dashboardDetails}
          profileData={profileData}
        />
      ) : (
        <Scaleloader />
      )}
    </div>
  );
};

export default MentorLeftSideProfileCard;

const MentorProfileInfo = ({ dashboardDetails, profileData }) => {
  const [openManagerChatWidget, setopenManagerChatWidget] = useState(false);
  const colors = ["#FF7D7D", "#7AE49E", "#7AD8E4"];
  const {
    totalHours,
    totalSessionHoursTaken,
    totalSessionLeft,
    hourAmount,
    mentorManager,
    earnedAmount,
    name,
    mentorDetails,
  } = dashboardDetails;
  const myTracker = [
    {
      title: "Total session hours /week ",
      value: totalHours + " Hrs",
    },
    {
      title: "Total session hours taken /week ",
      value: totalSessionHoursTaken + " Hrs",
    },
    {
      title: "Total session hours left /week ",
      value: totalSessionLeft + " Hrs",
    },
    {
      title: "Per hour",
      value: hourAmount + " USD",
    },
  ];
  const { firstName, lastName, profilePhoto, coverPhoto } = name;
  const rating = dashboardDetails.rating.rows;

  const handleMessageManagerClick = () => {
    setopenManagerChatWidget(true);
  };

  return (
    <>
      <div className="relative">
        {coverPhoto ? (
          <img
            src={`${url}/file?path=${coverPhoto}`}
            alt=""
            className="rounded-t-10px max-h-32 w-full"
          />
        ) : (
          <img src={MentorProfileCoverPicture} alt="" />
        )}
        <UserAvatar
          name={firstName}
          classNames="absolute left-0 bottom-0 mx-3 transform translate-y-1/2 w-9 h-9 500px:w-14 500px:h-14 500px:text-2xl"
          profilePhoto={profilePhoto}
          imgClassNames="absolute left-0 bottom-0 mx-6 transform translate-y-1/2 h-16 w-16"
        />
      </div>
      <div className="px-4 w-full text-brandDarkBlue">
        <p className="pt-11 font-bold text-lg">
          {firstName} {lastName}
        </p>
        <MentorRatingContainer
          rating={rating}
          count={dashboardDetails.rating.count}
        />
        <div className="border-b border-solid border-loginCards mt-3 mb-2" />

        <h6 className="font-extrabold text-xs lg:text-sm">Specialization</h6>

        <div className="pt-3 flex flex-col justify-center">
          {mentorDetails?.focusArea.map(({ title }, index) => {
            return (
              <div key={index}>
                {index < 3 && (
                  <div
                    className="flex items-center gap-1 lg:gap-2 rounded-3xl px-1 lg:px-2 py-1"
                    style={{
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)",
                    }}
                  >
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{ backgroundColor: colors[index] }}
                    />
                    <p className="font-semibold text-xs lg:text-sm">{title}</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="border-b border-solid border-loginCards my-3" />

        <h6 className=" text-sm font-semibold">My Manager</h6>
        <div className="flex mt-3">
          <UserAvatar
            name={mentorManager[0]?.firstName}
            classNames="mr-2"
            style={{ minWidth: "40px", minHeight: "40px" }}
          />
          {mentorManager.map(({ firstName, lastName, email }, index) => (
            <div key={index}>
              <h6 className="font-semibold text-sm">
                {firstName} {lastName}
              </h6>
              <h6 className="font-semibold text-sm text-brandGrey">{email}</h6>
            </div>
          ))}
        </div>


        <div className="border-b border-solid border-loginCards my-3" />
        <p className=" text-sm font-extrabold mb-6">My Tracker</p>

        {myTracker.map(({ title, value }, index) => (
          <div
            key={index}
            className="w-full my-2 flex flex-row items-center justify-between"
          >
            <p
              className="text-10px lg:text-sm font-medium"
              style={{ color: "#588594" }}
            >
              {title}
            </p>
            <p
              className="text-xs lg:text-sm font-semibold"
              style={{ color: "#007CA5" }}
            >
              {value}
            </p>
          </div>
        ))}
        <div className="border-b border-solid border-loginCards my-3" />
        <div className="w-full my-2 text-sm flex flex-row items-center justify-between">
          <p className="font-medium text-sm" style={{ color: "#588594" }}>
            Earned Amount
          </p>
          <p className="font-semibold text-base" style={{ color: "#007CA5" }}>
            {earnedAmount} USD
          </p>
        </div>
      </div>
      {openManagerChatWidget && (
        <ChatWidgetModal
          state={openManagerChatWidget}
          setState={setopenManagerChatWidget}
          mentorUid={profileData.uid}
          header="Message Manager"
          defaultUid={mentorManager[0]?.uid}
        />
      )}
    </>
  );
};
