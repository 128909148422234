import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { cancelAutoRenewal } from "../../../../AppRedux/actions/MemberProfile";
import { CardButton, successToast, errorToast } from "../../../utilities";
import ModalOuter from "../../../utilities/modals/ModalOuter";

export default function CancelAutoSubscriptionModal({
  state,
  setState,
  setAutoRenewalStatus,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    cancelMemberAutoRenewalBegin,
    cancelMemberAutoRenewalSuccessData,
    cancelMemberAutoRenewalFailureData,
  } = useSelector(({ memberProfile }) => memberProfile);

  useEffect(() => {
    if (cancelMemberAutoRenewalSuccessData) {
      successToast(
        "Successfully cancelled auto renewal of your subscription plan"
      );
      setState(false);
      setAutoRenewalStatus(true);
    }
    if (cancelMemberAutoRenewalFailureData) {
      errorToast(cancelMemberAutoRenewalFailureData?.message);
    }
  }, [
    cancelMemberAutoRenewalSuccessData,
    navigate,
    cancelMemberAutoRenewalFailureData,
    setState,
    setAutoRenewalStatus,
  ]);

  const handleClick = () => {
    dispatch(cancelAutoRenewal());
  };

  return (
    <ModalOuter
      state={state}
      setState={setState}
      classNames="p-4 text-center animate-modalInitial w-full"
    >
      <h1 className="font-medium text-xl text-left p-2 ">
        Do you want to cancel auto-renewal of your subscription plan ?
      </h1>
      <div className="flex justify-around space-x-6 mt-2 w-full">
        <button
          onClick={() => setState(!state)}
          className="bg-white text-brandBlue font-semibold text-lg px-2 rounded-lg"
        >
          Cancel
        </button>
        <CardButton
          text="Yes"
          onClick={handleClick}
          loader={cancelMemberAutoRenewalBegin}
          classNames="bg-brandBlue py-1 px-4 font-semibold text-lg flex justify-between items-center mt-0"
        />
      </div>
    </ModalOuter>
  );
}
