import thunk from "redux-thunk";
import reduxLogger from "redux-logger";
import { persistStore } from "redux-persist";
import { applyMiddleware, createStore } from "redux";

import reducers from "../reducers";

const middleware = [thunk];
if (process.env.NODE_ENV === "development") middleware.push(reduxLogger);

export const store = createStore(reducers, applyMiddleware(...middleware));

export const persister = persistStore(store);
