import {
  GET_MENTOR_PROFILE_DETAILS_BEGIN,
  GET_MENTOR_PROFILE_DETAILS_SUCCESS,
  GET_MENTOR_PROFILE_DETAILS_FAILURE,
  RESET_GET_MENTOR_PROFILE_DETAILS,
  UPDATE_MENTOR_PROFILE_DETAILS_BEGIN,
  UPDATE_MENTOR_PROFILE_DETAILS_SUCCESS,
  UPDATE_MENTOR_PROFILE_DETAILS_FAILURE,
  RESET_UPDATE_MENTOR_PROFILE_DETAILS,
} from "../constants/MentorProfile";

const initialState = {
  getMentorProfileDetailsBegin: false,
  getMentorProfileDetailsSuccess: false,
  getMentorProfileDetailsSuccessData: null,
  getMentorProfileDetailsFailure: false,
  getMentorProfileDetailsFailureData: null,

  updateMentorProfileDetailsBegin: false,
  updateMentorProfileDetailsSuccess: false,
  updateMentorProfileDetailsSuccessData: null,
  updateMentorProfileDetailsFailure: false,
  updateMentorProfileDetailsFailureData: null,
};

export const mentorProfile = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // get mentor profile information
    case GET_MENTOR_PROFILE_DETAILS_BEGIN:
      return {
        ...state,
        getMentorProfileDetailsBegin: true,
        getMentorProfileDetailsSuccess: false,
        getMentorProfileDetailsSuccessData: null,
        getMentorProfileDetailsFailure: false,
        getMentorProfileDetailsFailureData: null,
      };

    case GET_MENTOR_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        getMentorProfileDetailsBegin: false,
        getMentorProfileDetailsSuccess: true,
        getMentorProfileDetailsSuccessData: data,
        getMentorProfileDetailsFailure: false,
        getMentorProfileDetailsFailureData: null,
      };

    case GET_MENTOR_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        getMentorProfileDetailsBegin: false,
        getMentorProfileDetailsSuccess: false,
        getMentorProfileDetailsSuccessData: null,
        getMentorProfileDetailsFailure: true,
        getMentorProfileDetailsFailureData: data,
      };

    case RESET_GET_MENTOR_PROFILE_DETAILS:
      return {
        ...state,
        getMentorProfileDetailsBegin: false,
        getMentorProfileDetailsSuccess: false,
        getMentorProfileDetailsSuccessData: null,
        getMentorProfileDetailsFailure: false,
        getMentorProfileDetailsFailureData: null,
      };

    //update mentor details

    case UPDATE_MENTOR_PROFILE_DETAILS_BEGIN:
      return {
        ...state,
        updateMentorProfileDetailsBegin: true,
        updateMentorProfileDetailsSuccess: false,
        updateMentorProfileDetailsSuccessData: null,
        updateMentorProfileDetailsFailure: false,
        updateMentorProfileDetailsFailureData: null,
      };

    case UPDATE_MENTOR_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        updateMentorProfileDetailsBegin: false,
        updateMentorProfileDetailsSuccess: true,
        updateMentorProfileDetailsSuccessData: data,
        updateMentorProfileDetailsFailure: false,
        updateMentorProfileDetailsFailureData: null,
      };

    case UPDATE_MENTOR_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        updateMentorProfileDetailsBegin: false,
        updateMentorProfileDetailsSuccess: false,
        updateMentorProfileDetailsSuccessData: null,
        updateMentorProfileDetailsFailure: true,
        updateMentorProfileDetailsFailureData: data,
      };

    case RESET_UPDATE_MENTOR_PROFILE_DETAILS:
      return {
        ...state,
        updateMentorProfileDetailsBegin: false,
        updateMentorProfileDetailsSuccess: false,
        updateMentorProfileDetailsSuccessData: null,
        updateMentorProfileDetailsFailure: false,
        updateMentorProfileDetailsFailureData: null,
      };

    default:
      return state;
  }
};
