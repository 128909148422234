import {
  SUBMIT_ADMIN_LOGIN_BEGIN,
  SUBMIT_ADMIN_LOGIN_SUCCESS,
  SUBMIT_ADMIN_LOGIN_FAILURE,
  RESET_SUBMIT_ADMIN_LOGIN,
} from "../constants/AdminAuth";

const initialState = {
  submitAdminLoginBegin: false,
  submitAdminLoginSuccess: false,
  submitAdminLoginSuccessData: null,
  submitAdminLoginFailure: false,
  submitAdminLoginFailureData: null,
};

export const adminAuth = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // login admin
    case SUBMIT_ADMIN_LOGIN_BEGIN:
      return {
        ...state,
        submitAdminLoginBegin: true,
        submitAdminLoginSuccess: false,
        submitAdminLoginSuccessData: null,
        submitAdminLoginFailure: false,
        submitAdminLoginFailureData: null,
      };
    case SUBMIT_ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        submitAdminLoginBegin: false,
        submitAdminLoginSuccess: true,
        submitAdminLoginSuccessData: data,
        submitAdminLoginFailure: false,
        submitAdminLoginFailureData: null,
      };
    case SUBMIT_ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        submitAdminLoginBegin: false,
        submitAdminLoginSuccess: false,
        submitAdminLoginSuccessData: null,
        submitAdminLoginFailure: true,
        submitAdminLoginFailureData: data,
      };
    case RESET_SUBMIT_ADMIN_LOGIN:
      return {
        ...state,
        submitAdminLoginBegin: false,
        submitAdminLoginSuccess: false,
        submitAdminLoginSuccessData: null,
        submitAdminLoginFailure: false,
        submitAdminLoginFailureData: null,
      };

    default:
      return state;
  }
};
