import React, { useState } from "react";

import { NavbarSecondLogo } from "../../components/utilities/svg";
import MentorSetUpPasswordForm from "../../components/pageComponents/MentorLoginForms/MentorSetupPasswordForm";
import LeftSideWelcomeSection from "../../components/pageComponents/LeftSideWelcomeSection";
import MentorClientSelection from "../../components/pageComponents/MentorLoginForms/MentorClientSelection";
// import MentorBankInformation from "../../components/pageComponents/MentorLoginForms/MentorBankInformation";
import SeoData from "../../components/utilities/seo/seoData.json";
import {
  ReactHelmet,
  scrollToTop,
  SunFooter,
} from "../../components/utilities";
import {
  MentorOnboardGreenSun,
  MentorOnboardOrangeSun,
} from "../../components/utilities/svg";

const MentorOnboard = ({ mentorTokenAndName }) => {
  const [page, setPage] = useState("setup_password");
  const [mentorOnboardData, setMentorOnboardData] = useState({});
  const [continueButtonloader, setContinueButtonloader] = useState(false);
  const [backButtonLoader, setBackButtonLoader] = useState(false);

  const onSubmitEachForm = (nextPage, pageData = {}) => {
    setContinueButtonloader(true);
    setMentorOnboardData({ ...mentorOnboardData, ...pageData });
    setPage(nextPage);
    setContinueButtonloader(false);
    scrollToTop("50", "16.66");
  };

  const onBackButtonClick = (nextPage) => {
    setBackButtonLoader(true);
    setPage(nextPage);
    setBackButtonLoader(false);
    scrollToTop("50", "16.66");
  };

  return (
    <>
      <ReactHelmet data={SeoData.mentorOnboard} />
      <NavBar />
      <section
        className={` w-full lg:flex min-h-90vh text-brandDarkBlue ${
          page === "client_selection" || "bank_information"
            ? "bg-lightGrey"
            : ""
        }`}
      >
        {page === "setup_password" ? (
          <>
            <LeftSideWelcomeSection />
            <MentorSetUpPasswordForm
              changeToNextPage={onSubmitEachForm}
              continueButtonloader={continueButtonloader}
              mentorOnboardData={mentorOnboardData}
            />
          </>
        ) : (
          page === "client_selection" && (
            <MentorClientSelection
              changeToNextPage={onSubmitEachForm}
              continueButtonloader={continueButtonloader}
              mentorOnboardData={mentorOnboardData}
              onBackButtonClick={onBackButtonClick}
              backButtonLoader={backButtonLoader}
              mentorName={mentorTokenAndName.name}
              mentorToken={mentorTokenAndName.token}
            />
          )
        )}
        {/* : (
          page === "bank_information" && (
            <MentorBankInformation
              mentorOnboardData={mentorOnboardData}
              mentorToken={mentorTokenAndName.token}
              onBackButtonClick={onBackButtonClick}
            />
          )
        )} */}

        {page === "client_selection" || page === "bank_information" ? (
          <>
            <MentorOnboardGreenSun className="absolute left-24 top-1/4" />
            <MentorOnboardOrangeSun className="absolute right-20 bottom-0 mb-10" />
          </>
        ) : (
          ""
        )}
      </section>

      <SunFooter />
    </>
  );
};
export default MentorOnboard;

const NavBar = () => {
  return (
    <div className="flex justify-between items-center mx-auto w-11/12 xl:w-10/12 py-4">
      <NavbarSecondLogo className="h-8 370px:h-11 sm:h-14 lg:h-16 xl:h-20 mt-1 sm:mt-0 w-40 sm:w-52 lg:w-72" />
    </div>
  );
};
