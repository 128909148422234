import React, { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { NoListToShowContainer } from "../components/pageComponents/dashboardGeneralItems";
import {
  CardButton,
  ProgressLoader,
  progressLoaderFunction,
} from "../components/utilities";

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ProgressLoader />
          <NoListToShowContainer text="There's nothing here!" />
          <CardButton
            onClick={() => navigate("/")}
            text="Go to Home"
            classNames="mx-auto bg-brandBlue"
          />
        </>
      ),
      []
    );

  return <Content />;
};

export default NotFound;
