import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";
import {
  scrollToTop,
  PhoneNumberInput,
  InputGroup,
  DeleteIcon,
} from "../../utilities";

const MentorProfessionalReference = ({
  changeToNextPage,
  onBackButtonClick,
  backButtonLoader,
  onSubmitMentorRegisterData,
}) => {
  window.location.hash = 'step6';
  const [formData, setFormData] = useState({
      names: {
        name0: "",
      },
      emails: {
        email0: "",
      },
      phones: {
        phone0: "",
      },
    }),
    [formError, setFormError] = useState({
      names: {
        name0: "",
      },
      emails: {
        email0: "",
      },
      phones: {
        phone0: "",
      },
    }),
    [referenceCount, setReferenceCount] = useState([0]),
    {
      submitMentorRegistrationFormSuccessData,
      submitMentorRegistrationFormBegin,
    } = useSelector(({ mentorRegistration }) => mentorRegistration);

  useEffect(() => {
    if (submitMentorRegistrationFormSuccessData) {
      changeToNextPage("thank_you_page");
      scrollToTop("50", "16.66");
    }
  }, [submitMentorRegistrationFormSuccessData, changeToNextPage]);

  const customeFormValidation = () => {
    const { names, emails, phones } = formData;
    let validationErrors = {};

    Object.values(names).forEach((value, index) => {
      const inputKey = `name${index}`;
      if (value === null || value.trim() === "") {
        validationErrors = {
          ...validationErrors,
          names: {
            ...validationErrors.names,
            [inputKey]: "Name is required.",
          },
        };
      }
      if (value?.trim() !== "" && value.length < 3) {
        validationErrors = {
          ...validationErrors,
          names: {
            ...validationErrors.names,
            [inputKey]: " please give your valid name.",
          },
        };
      }
    });

    Object.values(emails).forEach((value, index) => {
      const inputKey = `email${index}`;
      if (value === null || value.trim() === "") {
        validationErrors = {
          ...validationErrors,
          emails: {
            ...validationErrors.emails,
            [inputKey]: "Email is required.",
          },
        };
      }
      if (
        value?.trim() !== "" &&
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
      ) {
        validationErrors = {
          ...validationErrors,
          emails: {
            ...validationErrors.emails,
            [inputKey]: " Please enter a valid email address!",
          },
        };
      }
    });

    Object.values(phones).forEach((value, index) => {
      const inputKey = `phone${index}`;
      if (value === null || value.trim() === "") {
        validationErrors = {
          ...validationErrors,
          phones: {
            ...validationErrors.phones,
            [inputKey]: "Phone is required.",
          },
        };
      }
      if (value?.trim() !== "" && !isValidPhoneNumber(value)) {
        validationErrors = {
          ...validationErrors,
          phones: {
            ...validationErrors.phones,
            [inputKey]: " You have entered an invalid phone number!",
          },
        };
      } else if (value?.trim() !== "" && isValidPhoneNumber(value)) {
        delete validationErrors[inputKey];
      }
    });

    return validationErrors;
  };

  const addFormFields = () => {
    const count = Number(referenceCount.slice(-1)) + 1;
    setFormData({
      ...formData,
      names: {
        ...formData.names,
        [`name${count}`]: "",
      },
      emails: {
        ...formData.emails,
        [`email${count}`]: "",
      },
      phones: {
        ...formData.phones,
        [`phone${count}`]: "",
      },
    });
    setReferenceCount([...referenceCount, Number(count)]);
  };

  const onMentorProfessionalReferenceSubmit = (e) => {
    e.preventDefault();
    const validationErrors = customeFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      onSubmitMentorRegisterData(formData);
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const handleBackButtonClick = () => {
    onBackButtonClick("personal_statement_page");
    scrollToTop("50", "16.66");
  };

  const handleChangeInNameInput = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^a-zA-Z\s]*$/gi, "");
    }

    let sm = formError.names;
    delete sm[[e.target.name]];

    setFormData({
      ...formData,
      names: {
        ...formData.names,
        [e.target.name]: value,
      },
    });
  };

  const handleChangeInEmailInput = (e) => {
    let sm = formError.emails;
    delete sm[[e.target.name]];

    const { name, value } = e.target;
    setFormData({
      ...formData,
      emails: {
        ...formData.emails,
        [name]: value,
      },
    });
  };

  return (
    <div className=" homepageWidth 500px:w-11/12 sm:w-10/12 xl:w-9/12 mx-auto px-2">
      <form
        id="mentor_form"
        onSubmit={(e) => onMentorProfessionalReferenceSubmit(e)}
      >
        <div className="mx-auto flex flex-col md:w-3/4 lg:w-7/12 lg:px-14 ">
          <h1
            className="font-semibold text-3xl text-center sm:mt-8 mt-6"
            style={{ color: "#093B5D" }}
          >
            Professional Reference
          </h1>
          {referenceCount.map((refrenceNumber, index) => {
            const currentNameKey = `name${refrenceNumber}`,
              currentNameValue = formData?.names[currentNameKey],
              currentEmailKey = `email${refrenceNumber}`,
              currentEmailValue = formData?.emails[currentEmailKey],
              currentPhoneKey = `phone${refrenceNumber}`,
              currentPhoneValue = formData?.phones[currentPhoneKey];

            return (
              <div key={refrenceNumber} className="animate-navbar">
                <h1 className="text-lg font-medium mt-4 mb-2 relative">
                  Add Reference {index + 1}
                  {index >= 1 && (
                    <DeleteIcon
                      classNames="absolute top-1/2 right-0"
                      style={{ width: "20px", height: "20px" }}
                      onClick={() => {
                        const { names, emails, phones } = formData;
                        delete names[[currentNameKey]];
                        delete emails[[currentEmailKey]];
                        delete phones[[currentPhoneKey]];
                        setReferenceCount(
                          referenceCount.filter(
                            (count) => count !== refrenceNumber
                          )
                        );
                      }}
                    />
                  )}
                </h1>
                <InputGroup
                  label="Name"
                  name={currentNameKey}
                  value={currentNameValue}
                  onChange={(e, onlyLetters) =>
                    handleChangeInNameInput(e, onlyLetters)
                  }
                  errorMessage={formError.names[currentNameKey]}
                  alphabetsOnly={true}
                />
                <InputGroup
                  label="Email"
                  name={currentEmailKey}
                  onChange={(e) => handleChangeInEmailInput(e)}
                  value={currentEmailValue}
                  errorMessage={formError.emails[currentEmailKey]}
                />
                <PhoneNumberInput
                  name={currentPhoneKey}
                  value={currentPhoneValue}
                  onChange={(value) => {
                    let sm = formError.phones;
                    delete sm[[currentPhoneKey]];

                    setFormData({
                      ...formData,
                      phones: {
                        ...formData.phones,
                        [currentPhoneKey]: value,
                      },
                    });
                  }}
                  errorMessage={formError.phones[currentPhoneKey]}
                />
              </div>
            );
          })}

          <button
            className="text-left font-semibold text-sm mb-4 activeState buttonHover"
            style={{ color: "#0C80A4" }}
            type="button"
            onClick={() => addFormFields()}
          >
            + Add another reference
          </button>
        </div>

        <div className="w-96% md:w-1/2 px-auto mx-auto mb-20 px-4 ">
          <FormBackAndContinueButton
            onBackButtonClick={handleBackButtonClick}
            actionBegin={submitMentorRegistrationFormBegin}
            backButtonLoader={backButtonLoader}
            backButtonNotAllowed={submitMentorRegistrationFormBegin}
          />
        </div>
      </form>
    </div>
  );
};

export default MentorProfessionalReference;
