import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";

import { ProgressLoader } from "../utilities";
import Home from "../../pages/Home";
import Faq from "../../pages/Faq";
import Join from "../../pages/Join";
import Blog from "../../pages/Blog";
import About from "../../pages/About";
import Login from "../../pages/Login";
import MerityLogin from "../../pages/MerityLogin";
import NotFound from "../../pages/NotFound";
import MemberLogin from "../../pages/MemberLogin";
import MentorLogin from "../../pages/MentorLogin";
import MentorDashboard from "../../pages/mentorDashboard";
import MemberOnboard from "../../pages/mentorOnboard";
import Mycalendar from "../../pages/memberDashboard/Mycalendar";
import MentorRegistration from "../../pages/MentorRegistration";
import MemberProfile from "../../pages/memberDashboard/MemberProfile";
import ForgotPassword from "../../pages/ForgotPassword";
import MyCalendar from "../../pages/mentorDashboard/MyCalendar";
import { Toast, currentAppUser } from "../utilities";
import "../../components/utilities/styles/general.css";
import MentorProfile from "../../pages/mentorDashboard/MentorProfile";
import AdminLogin from "../../pages/AdminLogin";
import AdminCurriculum from "../../pages/adminDashboard/AdminCurriculum";
import AdminCommunity from "../../pages/adminDashboard/AdminCommunity";
import AdminMembers from "../../pages/adminDashboard/AdminMembers";
import AdminMentors from "../../pages/adminDashboard/AdminMentors";
import AdminManager from "../../pages/adminDashboard/AdminManager";
import AdminSettings from "../../pages/adminDashboard/AdminSettings";
import MentorManagerLogin from "../../pages/MentorManagerLogin";
import AdminStories from "../../pages/adminDashboard/AdminStories";
import FeedSection from "../../pages/FeedSection";
import AdminFeeds from "../../pages/adminDashboard/AdminFeeds";
import MeetingVideo from "../../pages/memberDashboard/cometChat/MeetingVideo";

//Code spliting
const MemberDashboard = lazy(() => import("../../pages/memberDashboard"));
const Story = lazy(() => import("../../pages/Story"));
const BecomeAMentor = lazy(() => import("../../pages/BecomeAMentor"));
// const MeetingVideo = lazy(() =>
//   import("../../pages/memberDashboard/cometChat/MeetingVideo")
// );
const PrivacyPolicy = lazy(() => import("../../pages/PrivacyPolicy"));
const Terms = lazy(() => import("../../pages/Terms"));
const Contact = lazy(() => import("../../pages/Contact"));
const SubscriptionPlans = lazy(() => import("../../pages/SubscriptionPlans"));
const AdminPrompts = lazy(() =>
  import("../../pages/adminDashboard/AdminPrompts")
);
const ManagerDashboard = lazy(() => import("../../pages/managerDashboard"));
const MemberAssessment = lazy(() =>
  import("../../pages/memberDashboard/MemberAssessment")
);

const Index = () => {
  const [params] = useSearchParams();
  const idFromParams = params.getAll("id").toString();

  useEffect(() => {
    if (currentAppUser() === "MENTOR") {
      if (!idFromParams) {
        if (document.getElementById("cometchat__widget")) {
          window.CometChatWidget.logout().then((response) => {
            document.getElementById("cometchat__widget")?.remove();
            // User successfully logged out.
            // Perform any clean up if required.
          });
        }
      }
    }
  }, [idFromParams]);

  return (
    <>
      <Toast />
      <Suspense fallback={<ProgressLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/stories" element={<Story />} />
          <Route path="/become-a-coach" element={<BecomeAMentor />} />
          <Route exact path="/become-a-mentor" element={
            <Navigate  to="/become-a-coach" />
          }/>
          <Route path="/faq" element={<Faq />} />
          <Route path="/subscription_plans" element={<SubscriptionPlans />} />
          <Route path="/join" element={<Join />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/merity" element={<MerityLogin />} />
          <Route path="/login/member" element={<MemberLogin />} />
          <Route path="/login/mentor" element={<MentorLogin />} />
          <Route path="/login/admin" element={<AdminLogin />} />
          <Route path="/login/manager" element={<MentorManagerLogin />} />
          <Route path="/login/forgot_password" element={<ForgotPassword />} />
          <Route
            path="become-a-coach/register"
            element={<MentorRegistration />}
          />
          <Route path="/mentor/onboard" element={<MemberOnboard />} />
          <Route path="*" element={<NotFound />} />

          {/* Member dashboard */}
          <Route
            path="/member/dashboard"
            element={
              <RequireAuth redirectTo="/login/member">
                <MemberDashboard />
              </RequireAuth>
            }
          />

          <Route
            path="/member/dashboard/assessment"
            element={
              <RequireAuth redirectTo="/login/member">
                <MemberAssessment />
              </RequireAuth>
            }
          />
          <Route
            path="/member/dashboard/my-calendar"
            element={
              <RequireAuth redirectTo="/login/member">
                <Mycalendar />
              </RequireAuth>
            }
          />
          <Route
            path="/member/dashboard/meeting"
            element={
              <RequireAuth redirectTo="/login/member">
                <MeetingVideo />
              </RequireAuth>
            }
          />
          <Route
            path="/member/dashboard/profile"
            element={
              <RequireAuth redirectTo="/login/member">
                <MemberProfile />
              </RequireAuth>
            }
          />
          <Route
            path="/member/dashboard/feeds"
            element={
              <RequireAuth redirectTo="/login/member">
                <FeedSection />
              </RequireAuth>
            }
          />

          {/* Mentor dashboard */}
          <Route
            path="/mentor/dashboard"
            element={
              <RequireAuth user="mentor" redirectTo="/login/mentor">
                <MentorDashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/mentor/dashboard/my-calendar"
            element={
              <RequireAuth user="mentor" redirectTo="/login/mentor">
                <MyCalendar />
              </RequireAuth>
            }
          />

          <Route
            path="/mentor/dashboard/meeting"
            element={
              <RequireAuth user="mentor" redirectTo="/login/mentor">
                <MeetingVideo />
              </RequireAuth>
            }
          />
          <Route
            path="/mentor/dashboard/profile"
            element={
              <RequireAuth user="mentor" redirectTo="/login/mentor">
                <MentorProfile />
              </RequireAuth>
            }
          />

          <Route
            path="/mentor/dashboard/feeds"
            element={
              <RequireAuth user="mentor" redirectTo="/login/mentor">
                <FeedSection />
              </RequireAuth>
            }
          />

          {/* Admin */}
          <Route
            path="/admin/dashboard"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminPrompts />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/dashboard/curriculum"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminCurriculum />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/dashboard/community"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminCommunity />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/dashboard/members"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminMembers />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/dashboard/mentors"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminMentors />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/dashboard/manager"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminManager />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/dashboard/settings"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminSettings />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/dashboard/stories"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminStories />
              </RequireAuth>
            }
          />

          <Route
            path="/admin/dashboard/feeds"
            element={
              <RequireAuth user="admin" redirectTo="/login/admin">
                <AdminFeeds />
              </RequireAuth>
            }
          />

          {/* Mentor manager */}
          <Route
            path="/manager/dashboard"
            element={
              <RequireAuth user="manager" redirectTo="/login/manager">
                <ManagerDashboard />
              </RequireAuth>
            }
          />
        </Routes>
      </Suspense>
    </>
  );
};

function RequireAuth({ children, redirectTo, user }) {
  return currentAppUser() ===
    (user === "mentor"
      ? "MENTOR"
      : user === "admin"
      ? "ADMIN"
      : user === "manager"
      ? "MANAGER"
      : "MEMBER_SUBSCRIBED") ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
}

export default Index;
