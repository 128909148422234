import React from "react";
import { Link } from "react-router-dom";

import PageData from "../../../utilities/content/AdminDashboard.json";

const Navbar = ({ selectedTab }) => {
  const { navbarContent } = PageData.adminMentorNavbarData;
  // const tab = params.getAll("tab").toString();

  return (
    <div
      className="rounded-lg flex justify-start bg-white "
      style={{ color: "#A2A2A2" }}
    >
      <div className="flex font-bold text-xs 950px:text-sm xl:text-base border-b border-solid border-faq w-full">
        {navbarContent.map(({ header, tab }, index) => (
          <div key={index}>
            <Link
              to={`/admin/dashboard/mentors?tab=${tab}`}
              className={` mx-4 cursor-pointer activeState transition duration-100 ease ${
                selectedTab === tab ? " font-bold brandBlueText" : ""
              }`}
            >
              {header}
            </Link>
            {selectedTab === tab && (
              <div
                className="rounded-sm mt-3"
                style={{
                  width: "100%",
                  borderColor: "#007CA5",
                  backgroundColor: "#007CA5",
                  borderWidth: "1.5px",
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
