export const SUBSCRIBE_MEMBER_PLAN_BEGIN = "SUBSCRIBE_MEMBER_PLAN_BEGIN";
export const SUBSCRIBE_MEMBER_PLAN_SUCCESS = "SUBSCRIBE_MEMBER_PLAN_SUCCESS";
export const SUBSCRIBE_MEMBER_PLAN_FAILURE = "SUBSCRIBE_MEMBER_PLAN_FAILURE";
export const RESET_SUBSCRIBE_MEMBER_PLAN = "RESET_SUBSCRIBE_MEMBER_PLAN";

export const GET_SUBSCRIPTION_PALNS_BEGIN = "GET_SUBSCRIPTION_PALNS_BEGIN";
export const GET_SUBSCRIPTION_PALNS_SUCCESS = "GET_SUBSCRIPTION_PALNS_SUCCESS";
export const GET_SUBSCRIPTION_PALNS_FAILURE = "GET_SUBSCRIPTION_PALNS_FAILURE";
export const RESET_GET_SUBSCRIPTION_PALNS = "RESET_GET_SUBSCRIPTION_PALNS";

export const VALIDATE_SUBSCRIPTION_COUPON_BEGIN =
  "VALIDATE_SUBSCRIPTION_COUPON_BEGIN";
export const VALIDATE_SUBSCRIPTION_COUPON_SUCCESS =
  "VALIDATE_SUBSCRIPTION_COUPON_SUCCESS";
export const VALIDATE_SUBSCRIPTION_COUPON_FAILURE =
  "VALIDATE_SUBSCRIPTION_COUPON_FAILURE";
export const RESET_VALIDATE_SUBSCRIPTION_COUPON =
  "RESET_VALIDATE_SUBSCRIPTION_COUPON";
