import { Cliploader } from "./Loader";

export const CardButton = ({
  classNames,
  disabled,
  text,
  style,
  id,
  loader,
  onClick,
  linearBg,
}) => (
  <button
    id={id}
    onClick={onClick}
    disabled={loader || disabled}
    className={` flex items-center gap-1 uppercase text-white py-2 px-12 mt-4 md:mt-0 md:min-w-min z-10 ${loader ? "cursor-wait" : "activeState buttonHover"
      } ${classNames}`}
    style={{
      background: linearBg
        ? "linear-gradient(179.92deg, #007CA5 36.77%, #009ACD 104.07%)"
        : "",
      boxShadow: linearBg
        ? "0px -5px 30px rgba(47, 125, 182, 0.1), 0px 5px 30px rgba(47, 125, 182, 0.1)"
        : "",
      ...style,
    }}
  >
    {text} {loader && <Cliploader />}
  </button>
);

export const FormButton = ({
  classNames,
  children,
  disabled,
  onClick,
  type,
  style,
}) => (
  <button
    type={type ? type : "submit"}
    onClick={onClick}
    disabled={disabled}
    style={{ ...style }}
    className={` ${classNames} uppercase activeState buttonHover mx-auto flexCenter w-full gap-2 font-semibold text-lg py-3`}
  >
    {children}
  </button>
);

export const SocialMediaButton = ({
  props,
  classNames,
  children,
  disabled,
  onClick,
}) => (
  <button
    {...props}
    onClick={onClick}
    disabled={disabled}
    className={`${classNames} bg-white flexCenter uppercase w-full gap-2 font-medium text-base py-4 border border-brandBlue `}
  >
    {children}
  </button>
);

export const SmallButton = ({ onClick, loader, text, classNames }) => (
  <button
    style={{ padding: "2px 12px" }}
    className={`flexCenter gap-2 rounded-md activeState buttonHover text-white ${classNames}`}
    onClick={onClick}
  >
    {text} {loader && <Cliploader />}
  </button>
);

export const CancelButton = ({ setState }) => (
  <button
    className="bg-white dashboardListHover p-2 text-brandDarkBlue font-semibold text-base px-2 cursor-pointer mt-4 md:mt-0 md:min-w-min z-10"
    onClick={() => setState((state) => !state)}
  >
    Cancel
  </button>
);
