import {
  SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_BEGIN,
  SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_SUCCESS,
  SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_FAILURE,
  RESET_MENTOR_ADD_CALENDAR_PREFERENCE,
  GET_MENTOR_CALENDAR_PREFERENCE_BEGIN,
  GET_MENTOR_CALENDAR_PREFERENCE_SUCCESS,
  GET_MENTOR_CALENDAR_PREFERENCE_FAILURE,
  RESET_GET_MENTOR_CALENDAR_PREFERENCE,
  UPDATE_MENTOR_AVAILABLE_SLOTS_BEGIN,
  UPDATE_MENTOR_AVAILABLE_SLOTS_SUCCESS,
  UPDATE_MENTOR_AVAILABLE_SLOTS_FAILURE,
  RESET_UPDATE_MENTOR_AVAILABLE_SLOTS,
  DELETE_MENTOR_AVAILABLE_SLOTS_BEGIN,
  DELETE_MENTOR_AVAILABLE_SLOTS_SUCCESS,
  DELETE_MENTOR_AVAILABLE_SLOTS_FAILURE,
  RESET_DELETE_MENTOR_AVAILABLE_SLOTS,
  VIEW_MENTOR_MEETING_BEGIN,
  VIEW_MENTOR_MEETING_SUCCESS,
  VIEW_MENTOR_MEETING_FAILURE,
  RESET_VIEW_MENTOR_MEETING,
  GET_MENTOR_MEETING_BEGIN,
  GET_MENTOR_MEETING_SUCCESS,
  GET_MENTOR_MEETING_FAILURE,
  RESET_GET_MENTOR_MEETING,
} from "../constants/MentorCalendar";

const initialState = {
  submitMentorAddCalendarPreferenceBegin: false,
  submitMentorAddCalendarPreferenceSuccess: false,
  submitMentorAddCalendarPreferenceSuccessData: null,
  submitMentorAddCalendarPreferenceFailure: false,
  submitMentorAddCalendarPreferenceFailureData: null,

  getMentorCalendarPreferenceBegin: false,
  getMentorCalendarPreferenceSuccess: false,
  getMentorCalendarPreferenceSuccessData: null,
  getMentorCalendarPreferenceFailure: false,
  getMentorCalendarPreferenceFailureData: null,

  updateMentorAvailableSlotsBegin: false,
  updateMentorAvailableSlotsSuccess: false,
  updateMentorAvailableSlotsSuccessData: null,
  updateMentorAvailableSlotsFailure: false,
  updateMentorAvailableSlotsFailureData: null,

  deleteMentorAvailableSlotsBegin: false,
  deleteMentorAvailableSlotsSuccess: false,
  deleteMentorAvailableSlotsSuccessData: null,
  deleteMentorAvailableSlotsFailure: false,
  deleteMentorAvailableSlotsFailureData: null,

  viewMentorMeetingBegin: false,
  viewMentorMeetingSuccess: false,
  viewMentorMeetingSuccessData: null,
  viewMentorMeetingFailure: false,
  viewMentorMeetingFailureData: null,

  getMentorMeetingListBegin: false,
  getMentorMeetingListSuccess: false,
  getMentorMeetingListSuccessData: null,
  getMentorMeetingListFailure: false,
  getMentorMeetingListFailureData: null,
};

export const mentorCalendar = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // submitting mentor calendar preference
    case SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_BEGIN:
      return {
        ...state,
        submitMentorAddCalendarPreferenceBegin: true,
        submitMentorAddCalendarPreferenceSuccess: false,
        submitMentorAddCalendarPreferenceSuccessData: null,
        submitMentorAddCalendarPreferenceFailure: false,
        submitMentorAddCalendarPreferenceFailureData: null,
      };
    case SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_SUCCESS:
      return {
        ...state,
        submitMentorAddCalendarPreferenceBegin: false,
        submitMentorAddCalendarPreferenceSuccess: true,
        submitMentorAddCalendarPreferenceSuccessData: data,
        submitMentorAddCalendarPreferenceFailure: false,
        submitMentorAddCalendarPreferenceFailureData: null,
      };
    case SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_FAILURE:
      return {
        ...state,
        submitMentorAddCalendarPreferenceBegin: false,
        submitMentorAddCalendarPreferenceSuccess: false,
        submitMentorAddCalendarPreferenceSuccessData: null,
        submitMentorAddCalendarPreferenceFailure: true,
        submitMentorAddCalendarPreferenceFailureData: data,
      };
    case RESET_MENTOR_ADD_CALENDAR_PREFERENCE:
      return {
        ...state,
        submitMentorAddCalendarPreferenceBegin: false,
        submitMentorAddCalendarPreferenceSuccess: false,
        submitMentorAddCalendarPreferenceSuccessData: null,
        submitMentorAddCalendarPreferenceFailure: false,
        submitMentorAddCalendarPreferenceFailureData: null,
      };

    //get calendar preferneces
    case GET_MENTOR_CALENDAR_PREFERENCE_BEGIN:
      return {
        ...state,
        getMentorCalendarPreferenceBegin: true,
        getMentorCalendarPreferenceSuccess: false,
        getMentorCalendarPreferenceSuccessData: null,
        getMentorCalendarPreferenceFailure: false,
        getMentorCalendarPreferenceFailureData: null,
      };
    case GET_MENTOR_CALENDAR_PREFERENCE_SUCCESS:
      return {
        ...state,
        getMentorCalendarPreferenceBegin: false,
        getMentorCalendarPreferenceSuccess: true,
        getMentorCalendarPreferenceSuccessData: data,
        getMentorCalendarPreferenceFailure: false,
        getMentorCalendarPreferenceFailureData: null,
      };
    case GET_MENTOR_CALENDAR_PREFERENCE_FAILURE:
      return {
        ...state,
        getMentorCalendarPreferenceBegin: false,
        getMentorCalendarPreferenceSuccess: false,
        getMentorCalendarPreferenceSuccessData: null,
        getMentorCalendarPreferenceFailure: true,
        getMentorCalendarPreferenceFailureData: data,
      };
    case RESET_GET_MENTOR_CALENDAR_PREFERENCE:
      return {
        ...state,
        getMentorCalendarPreferenceBegin: false,
        getMentorCalendarPreferenceSuccess: false,
        getMentorCalendarPreferenceFailure: false,
        getMentorCalendarPreferenceFailureData: null,
      };

    //update mentor available slots
    case UPDATE_MENTOR_AVAILABLE_SLOTS_BEGIN:
      return {
        ...state,
        updateMentorAvailableSlotsBegin: true,
        updateMentorAvailableSlotsSuccess: false,
        updateMentorAvailableSlotsSuccessData: null,
        updateMentorAvailableSlotsFailure: false,
        updateMentorAvailableSlotsFailureData: null,
      };
    case UPDATE_MENTOR_AVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        updateMentorAvailableSlotsBegin: false,
        updateMentorAvailableSlotsSuccess: true,
        updateMentorAvailableSlotsSuccessData: data,
        updateMentorAvailableSlotsFailure: false,
        updateMentorAvailableSlotsFailureData: null,
      };
    case UPDATE_MENTOR_AVAILABLE_SLOTS_FAILURE:
      return {
        ...state,
        updateMentorAvailableSlotsBegin: false,
        updateMentorAvailableSlotsSuccess: false,
        updateMentorAvailableSlotsSuccessData: null,
        updateMentorAvailableSlotsFailure: true,
        updateMentorAvailableSlotsFailureData: data,
      };
    case RESET_UPDATE_MENTOR_AVAILABLE_SLOTS:
      return {
        ...state,
        updateMentorAvailableSlotsBegin: false,
        updateMentorAvailableSlotsSuccess: false,
        updateMentorAvailableSlotsSuccessData: null,
        updateMentorAvailableSlotsFailure: false,
        updateMentorAvailableSlotsFailureData: null,
      };

    //delete mentor available slots
    case DELETE_MENTOR_AVAILABLE_SLOTS_BEGIN:
      return {
        ...state,
        deleteMentorAvailableSlotsBegin: true,
        deleteMentorAvailableSlotsSuccess: false,
        deleteMentorAvailableSlotsSuccessData: null,
        deleteMentorAvailableSlotsFailure: false,
        deleteMentorAvailableSlotsFailureData: null,
      };
    case DELETE_MENTOR_AVAILABLE_SLOTS_SUCCESS:
      return {
        ...state,
        deleteMentorAvailableSlotsBegin: false,
        deleteMentorAvailableSlotsSuccess: true,
        deleteMentorAvailableSlotsSuccessData: data,
        deleteMentorAvailableSlotsFailure: false,
        deleteMentorAvailableSlotsFailureData: null,
      };
    case DELETE_MENTOR_AVAILABLE_SLOTS_FAILURE:
      return {
        ...state,
        deleteMentorAvailableSlotsBegin: false,
        deleteMentorAvailableSlotsSuccess: false,
        deleteMentorAvailableSlotsSuccessData: null,
        deleteMentorAvailableSlotsFailure: true,
        deleteMentorAvailableSlotsFailureData: data,
      };
    case RESET_DELETE_MENTOR_AVAILABLE_SLOTS:
      return {
        ...state,
        deleteMentorAvailableSlotsBegin: false,
        deleteMentorAvailableSlotsSuccess: false,
        deleteMentorAvailableSlotsSuccessData: null,
        deleteMentorAvailableSlotsFailure: false,
        deleteMentorAvailableSlotsFailureData: null,
      };

    //view mentor meeting
    case VIEW_MENTOR_MEETING_BEGIN:
      return {
        ...state,
        viewMentorMeetingBegin: true,
        viewMentorMeetingSuccess: false,
        viewMentorMeetingSuccessData: null,
        viewMentorMeetingFailure: false,
        viewMentorMeetingFailureData: null,
      };
    case VIEW_MENTOR_MEETING_SUCCESS:
      return {
        ...state,
        viewMentorMeetingBegin: false,
        viewMentorMeetingSuccess: true,
        viewMentorMeetingSuccessData: data,
        viewMentorMeetingFailure: false,
        viewMentorMeetingFailureData: null,
      };
    case VIEW_MENTOR_MEETING_FAILURE:
      return {
        ...state,
        viewMentorMeetingBegin: false,
        viewMentorMeetingSuccess: false,
        viewMentorMeetingSuccessData: null,
        viewMentorMeetingFailure: true,
        viewMentorMeetingFailureData: data,
      };
    case RESET_VIEW_MENTOR_MEETING:
      return {
        ...state,
        viewMentorMeetingBegin: false,
        viewMentorMeetingSuccess: false,
        viewMentorMeetingSuccessData: null,
        viewMentorMeetingFailure: false,
        viewMentorMeetingFailureData: null,
      };

    //get mentor meeting list
    case GET_MENTOR_MEETING_BEGIN:
      return {
        ...state,
        getMentorMeetingListBegin: true,
        getMentorMeetingListSuccess: false,
        getMentorMeetingListSuccessData: null,
        getMentorMeetingListFailure: false,
        getMentorMeetingListFailureData: null,
      };
    case GET_MENTOR_MEETING_SUCCESS:
      return {
        ...state,
        getMentorMeetingListBegin: false,
        getMentorMeetingListSuccess: true,
        getMentorMeetingListSuccessData: data,
        getMentorMeetingListFailure: false,
        getMentorMeetingListFailureData: null,
      };
    case GET_MENTOR_MEETING_FAILURE:
      return {
        ...state,
        getMentorMeetingListBegin: false,
        getMentorMeetingListSuccess: false,
        getMentorMeetingListSuccessData: null,
        getMentorMeetingListFailure: true,
        getMentorMeetingListFailureData: data,
      };
    case RESET_GET_MENTOR_MEETING:
      return {
        ...state,
        getMentorMeetingListBegin: false,
        getMentorMeetingListSuccess: false,
        // getMentorMeetingListSuccessData: null,
        getMentorMeetingListFailure: false,
        getMentorMeetingListFailureData: null,
      };

    default:
      return state;
  }
};
