import React from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import AdminManageSettings from "../../components/pageComponents/adminDashboard/AdminManageSettings";

const AdminSettings = () => {
  return (
    <AdminDashboardLayout>
      <AdminManageSettings />
    </AdminDashboardLayout>
  );
};

export default AdminSettings;
