import {
  GET_APP_RESOURCES_BEGIN,
  GET_APP_RESOURCES_SUCCESS,
  GET_APP_RESOURCES_FAILURE,
  RESET_GET_APP_RESOURCES,
  GET_COUNTRIES_RESOURCES_BEGIN,
  GET_COUNTRIES_RESOURCES_SUCCESS,
  GET_COUNTRIES_RESOURCES_FAILURE,
  RESET_GET_COUNTRIES_RESOURCES,
  GET_STATES_BEGIN,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
  RESET_GET_STATES,
} from "../constants/Home";

import { httpApp } from "../../axios/httpUser";

/**
 * api to get app resources
 */
export function getAppResources() {
  return (dispatch) => {
    dispatch({
      type: GET_APP_RESOURCES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/resources/client`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_APP_RESOURCES_SUCCESS,
            data: res.data.data,
          });
          dispatch({
            type: RESET_GET_APP_RESOURCES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_APP_RESOURCES_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_APP_RESOURCES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * api to get countries
 */
export function getCountries() {
  return (dispatch) => {
    dispatch({
      type: GET_COUNTRIES_RESOURCES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/resources/fetch-countries`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_COUNTRIES_RESOURCES_SUCCESS,
            data: res.data.data,
          });
          dispatch({
            type: RESET_GET_COUNTRIES_RESOURCES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_COUNTRIES_RESOURCES_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_COUNTRIES_RESOURCES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * api to get states
 */
export function getStates(code) {
  return (dispatch) => {
    dispatch({
      type: GET_STATES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/resources/fetch-states/${code}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_STATES_SUCCESS,
            data: res.data.data,
          });
          dispatch({
            type: RESET_GET_STATES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_STATES_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_STATES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
