import React, { useState, useEffect } from "react";

import PageData from "../../utilities/content/pages.json";
import { InputGroupEye } from "../../utilities";
import {
  handleInputOnchange,
  PasswordConditionMessage,
  setupPasswordFormValidation,
} from "../../utilities/SetUpPasswordForm";
import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";

const MentorSetUpPasswordForm = ({
  changeToNextPage,
  mentorOnboardData,
  continueButtonloader,
  backButtonLoader,
}) => {
  const [formError, setFormError] = useState({}),
    { heading } = PageData.joinPageData.setUpPasswordForm,
    [mentorPasswordData, setMentorPasswordData] = useState({
      password: "",
      confirmPassword: "",
      ...mentorOnboardData,
    });


    window.gtag('event', 'page_view', {
      "page_title": "Member Account",
    });

  useEffect(() => {
    document.querySelector(`input[name='password']`).focus();
  }, []);

  const onMentorPasswordSubmit = (e) => {
    e.preventDefault();

    const validationErrors = setupPasswordFormValidation(
      mentorPasswordData.password,
      mentorPasswordData.confirmPassword
    );
    if (Object.keys(validationErrors).length === 0) {
      changeToNextPage("client_selection", mentorPasswordData);
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <div className="lg:w-1/2 py-4 md:py-12 lg:py-36 px-4 sm:px-24 bg-lightGrey">
      <h2 className="font-medium text-2xl sm:text-28px mb-7">{heading}</h2>
      <form
        className="w-full xl:w-10/12 2xl:w-3/4"
        onSubmit={(e) => onMentorPasswordSubmit(e)}
      >
        {mentorPasswordData.password !== "" ||
        mentorPasswordData.confirmPassword !== "" ? (
          <PasswordConditionMessage />
        ) : null}

        <InputGroupEye
          label="Enter your password"
          name="password"
          value={mentorPasswordData.password}
          onChange={(e) =>
            handleInputOnchange(
              e,
              formError,
              setFormError,
              setMentorPasswordData
            )
          }
          errorMessage={formError?.password}
        />

        <InputGroupEye
          label="Confirm your password"
          name="confirmPassword"
          value={mentorPasswordData.confirmPassword}
          onChange={(e) =>
            handleInputOnchange(
              e,
              formError,
              setFormError,
              setMentorPasswordData
            )
          }
          errorMessage={formError?.confirmPassword}
        />
        <FormBackAndContinueButton
          actionBegin={continueButtonloader}
          backButtonLoader={backButtonLoader}
          buttonText="Confirm Password"
          twoButtons={false}
        />
      </form>
    </div>
  );
};

export default MentorSetUpPasswordForm;
