import {
  FooterInstagram,
  FooterFacebook,
  // FooterTwitter,
  FooterLinkedIn,
  AssessmentIcon,
  CustomCurriculumIcon,
  // MemberJourneyIcon,
  // GoalCompletionIcon,
  // ContactPhone,
  ContactMail,
  ContactLoction,
  MentorCardIcon,
  // BecomeAMentorCardIcon,
  // AboutPageYellowSun,
  BecomeAMentorPageLandingSun,
  // LoginMemberIcon,
  // LoginMentorIcon,
  ContactInstagram,
  ContactFacebook,
  // ContactTwitter,
  ContactLinkedIn,
  AdminPromptsIcon,
  AdminCurriculumIcon,
  AdminCommunitytIcon,
  AdminMembersIcon,
  AdminMentorsIcon,
  AdminManagerIcon,
  GooglePaymentsSocial,
  GmailPaymentsSocial,
  FacebookPaymentsSocial,
  MentorMinutesIcon,
  JoinCommunityIcon,
} from "../utilities/svg";

//High resolution PNGs
import LoginMentorIconPNG from "../../assets/Images/LoginMentorIconPNG.png";
import LoginMentorIconWebp from "../../assets/Images/LoginMentorIconWebp.webp";
import LoginMemberIconPNG from "../../assets/Images/LoginMemberIconPNG.png";
import LoginMemberIconWebp from "../../assets/Images/LoginMemberIconWebp.webp";
// import AssessmentIconPNG from "../../assets/Images/AssessmentIconPNG.png";
// import CustomCurriculumIconPNG from "../../assets/Images/CustomCurriculumIconPNG.png";
import MentorMinutes from "../../assets/Images/MentorMinutesIcon.png";
import MerityCommunityIconPNG from "../../assets/Images/MerityCommunityIconPNG.png";
import MentorMonitorIcon from "../../assets/Images/MentorMonitorIcon.png";

// import MerityCommunityIcon from "../../assets/Images/MerityCommunityIcon.jpg";
import BecomeAMentor from "../../assets/Images/BecomeAMentor.webp";
import AboutUs from "../../assets/Images/AboutUs.jpg";
// import SharonImage from "../../assets/Images/SharonImage.webp";

//Founders photos

import FounderOne from "../../assets/Founders/Sandy.webp";
import FounderTwo from "../../assets/Founders/Rose.webp";
import FounderThree from "../../assets/Founders/Heidi.webp";
import FounderFour from "../../assets/Founders/Kristen.webp";

// For contact page
export const socialMedias = [
  {
    FooterIcon: FooterFacebook,
    ContactIcon: ContactFacebook,
    link: "https://www.facebook.com/MyMerity",
    Thumbnail: "Facebook",
  },
  // {
  //   FooterIcon: FooterTwitter,
  //   ContactIcon: ContactTwitter,
  //   link: "https://twitter.com/my_merity",
  //   Thumbnail: "Twitter",
  // },
  {
    FooterIcon: FooterInstagram,
    ContactIcon: ContactInstagram,
    link: "https://www.instagram.com/mymerity/",
    Thumbnail: "Instagram",
  },
  {
    FooterIcon: FooterLinkedIn,
    ContactIcon: ContactLinkedIn,
    link: "https://www.linkedin.com/company/merity-llc/",
    Thumbnail: "LinkedIn",
  },
];

// For contact page
export const contactData = [
  {
    Icon: ContactMail,
    text: "Merity@mymerity.com",
  },
  {
    Icon: ContactLoction,
    text: "Merity Headquaters is located in New Jersey, USA",
  },
];

// How we do FlowChart Content
export const HowWeDoBottomFlowChart = [
  {
    Icon: AssessmentIcon,
    title: "Activate Account & Complimentary Coaching Minutes",
    description: "Choose the appropriate account type and unlock your 30 minutes of complimentary coaching.",
  },
  {
    Icon: CustomCurriculumIcon,
    title: "DIVE INTO SELF-ASSESSMENT",
    description:
      "Begin the Reflective Exercises to help you better understand your strengths and capitalize on them.",
  },
  {
    Icon: MentorMinutesIcon,
    title: "ENGAGE WITH YOUR COACH",
    description:
      "Reconnect and collaborate with your coach to revisit your initial Reflections and Exercises,guiding your personal journey.",
    blueBorder: true,
  },
  {
    Icon: JoinCommunityIcon,
    title: "JOIN COMMUNITY EVENTS",
    description:
      "Participate in various online community activities to share,learn, and grow together.",
  },
];

// Landing section data
export const MentorAndAboutPageLandingData = {
  "become-a-coach": {
    Image: BecomeAMentor,
    SunIcon: BecomeAMentorPageLandingSun,
    roundText: ["Your first", "30 Minutes of 1:1 Coaching", "are Free"],
    content: [
      {
        first:
          "The shared mission of Merity and our mentors is to help women take actionable steps towards finding their moxie and achieving their goals.",
        second:
          "Our platform is designed for women committed to make personal and professional transformations, and we believe mentorship dramatically improves outcomes.",
      },
      {
        first:
          "As a professional coach or subject-matter expert, you will have an opportunity to develop real relationships, make an impact, and drive positive change for our members.",
        second:
          "Impart your knowledge. Share your know-how. Merity mentors are invited to submit approved proprietary content including workshops, webinars, toolkits, and/or bundles to be sold through the platform.",
      },
    ],
    opportunities: [
      {
        title: "Join a weekly, mentor-led curriculum support session",
        description:
          "to help guide you, answer questions, and gain clarity as you journey through.",
      },
      {
        title: "Sign up for Mentor Minutes, one-on-one sessions",
        description:
          "to help you better define your specific goals and create a realistic and actionable framework for achieving them",
      },
      {
        title:
          "Workshops, webinars, and toolkits specially created by our mentors",
        description:
          "and subject matter experts that will cover a wide range of topics",
      },
    ],
  },
  "about-us": {
    heading: "WHY WE CREATED MERITY",
    description:
      "The founding of Merity is the story of four women with very diverse backgrounds coming together around one common mission - to help women transform and change their lives. In January of 2020, we set out to better understand the types of challenges that women face, both personally and professionally, throughout their lives. We conducted countless interviews and surveys, and looked at many other organizations geared towards women’s success and empowerment. Our research told us two key things - the first being that women want and need help in defining and achieving their goals. The second being that there was no platform specifically designed to facilitate the accomplishment of these goals.<br><br> We have built a company that not only helps women identify their goals, but also provides a tactical process and roadmap for achieving them. We enlisted help from some of the best and brightest minds to help us craft our platform and our content. Each one of us has gone through our own changes and transitions, and we believe we each would have benefited greatly from a platform like Merity during these times in our own lives. Today, we are excited to bring Merity to the many other women around the world who will benefit from what it offers, and we look forward to the amazing results we will all get to see and experience together.<br><br> For partnership opportunities, media/press or other business related inquiries, please contact us at hello@mymerity.com",
    Image: AboutUs,
    // roundText: ["Merity", "Mentors", "make all the"],
    roundText: ["A passionate team dedicated to helping women thrive"],
    foundersHeading: "MEET THE FOUNDERS",
    foundersData: [
      {
        photo: FounderOne,
        heading: "Sandy Sabean, CEO",
        description:
          "Sandy is an advertising industry veteran having spent her career at award-winning agencies such as Doyle Dane Bernbach, Wells Rich Greene, Saatchi & Saatchi, Young & Rubicam, and Grey where she created campaigns for a long list of blue-chips: Chase Manhattan Bank, Johnson & Johnson, Sheraton Hotels, Hertz, ConAgra, and, a number of others.<br><br> In 2008, she co-founded Womenkind, an agency focused on marketing and advertising to women, after realizing the increasingly powerful role of the female consumer. Womenkind attracted the attention and business quite quickly from Fortune 1000 firms including Citibank, Post Foods, MasterCard, TD Ameritrade, Yum Brands, and Mutual of Omaha.  Their unique approach created authentic insights that connected with women, making them feel understood, enlightened, and respected.<br><br> In 2018, Sandy launched Grit and Soul with Rose LoCastro and Heidi Stamas, a blog focused on telling the powerful and inspiring stories of women who have manifested great change or transformation in their lives. Grit and Soul served as the springboard for the concept of Merity. <br><br> Sandy is a natural foodie, avid cook and traveler, and mother of two. She currently serves on the Board of Trustees of Xavier High School in New York City. Her time is split between Manhattan and Sag Harbor, New York with her husband, Richard.<br> ",
      },
      {
        photo: FounderTwo,
        heading: "Rose LoCastro",
        description:
          "Rose grew up as the eldest of three 1st generation siblings of an Italian American family in New Jersey.  As wonderful as it was to grow up as part of a large Italian family . . .strong family ties, great food, New Jersey! . . .starting school not able to speak English and being held to strict and fairly sexist rules at home, presented a unique set of challenges. But as it’s said, “what doesn’t kill you makes you stronger” and she developed an early, and strong, sense of self and determination to succeed.  After graduating Montclair State College in New Jersey in 1982 (my father believed I could go to college anywhere I wanted, if I came home every night), she began a sales career in the technology industry and never looked back.  She has worked for some of the most important technology companies in the world; IBM, Apple, Dell and Lenovo. <br><br> Rose was a partner in Grit and Soul with both Heidi and Sandy. She (still!) lives in NJ with her husband Tony and their two dogs, Kirbi and Lulu.  Her daughter Alex and husband Zach are living and working in the t.v. industry in Los Angeles and her son Nicholas is an attorney in NYC.",
      },
      {
        photo: FounderThree,
        heading: "Heidi Stamas",
        description:
          "After a career at Disney and Time Warmer, Heidi felt drawn to the nonprofit world. She has spent her most recent years working and volunteering at various nonprofits, including the Robin Hood Foundation, Goddard Riverside Community Center, Storefront Academy and La Guardia Community College. Her longest and current involvement is as a Board member (and former Board Chair) of Citizens’ Committee for Children, a multi-issue advocacy organization seeking to improve the lives of children and families in New York.  She is also a Board member of Education Through Music which provides music as a core subject in underserved New York City schools. She enjoys working with the inspiring students at LaGuardia Community College as part of the “Pushy Mom’s” program helping them to transfer to four-year colleges. Heidi has a BA from Brown University and MBA and MSW degrees from Columbia University. <br><br> Passionate about personal reinvention and helping women, Heidi is passionate about the ways in which Merity can transform lives. She is a native New Yorker and has two children, Ali, who works in education policy, and Sam who works in the media and entertainment industry. She is a lover of classical music, reading, travel and chocolate.",
      }
    ],
  },
};

// Become a mentor card data
export const becomeAMentorCardData = {
  text: "Become A Merity Coach Now",
  yellow: {
    Icon: LoginMentorIconPNG,
  },
  blue: {
    Icon: MentorCardIcon,
  },
};

export const loginPageData = [
  {
    title: "COACH",
    link: "/login/mentor",
    Icon: LoginMentorIconWebp,
    color: "yellow",
  },
  {
    title: "MEMBER",
    link: "/login/member",
    Icon: LoginMemberIconWebp,
    color: "blue",
  },
];

export const loginMerityPageData = [
  {
    title: "ADMIN",
    link: "/login/admin",
    Icon: LoginMentorIconPNG,
    color: "blue",
  },
  {
    title: "MANAGER",
    link: "/login/manager",
    Icon: LoginMemberIconPNG,
    color: "yellow",
  },
];

//mentor Registration
export const mentorRegistrationPageData = {
  landingPageTitle: "Become a Coach",
  landingPageDescription:
    "We are always looking for qualified professionals – life and career coaches and subject matters experts - to guide our members. Please complete the application below.",
  options: [
    { id: 1, title: "General Life Coaching" },
    { id: 2, title: "Career/Professional" },
    { id: 3, title: "Financial" },
    { id: 4, title: "Entrepreneurship" },
    { id: 5, title: "Wellness" },
    { id: 6, title: "Others" },
  ],
};

// full calendar
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

// Admin dashboard
export const adminLeftsideTabs = [
  {
    Icon: AdminPromptsIcon,
    title: "Prompts",
    link: "/admin/dashboard",
  },
  {
    Icon: AdminCurriculumIcon,
    title: "Curriculum",
    link: "/admin/dashboard/curriculum",
  },
  {
    Icon: AdminCommunitytIcon,
    title: "Community",
    link: "/admin/dashboard/community",
  },
  {
    Icon: AdminMembersIcon,
    title: "Members",
    link: "/admin/dashboard/members",
  },
  {
    Icon: AdminMentorsIcon,
    title: "Mentors",
    link: "/admin/dashboard/mentors",
  },
  {
    Icon: AdminManagerIcon,
    title: "Manager",
    link: "/admin/dashboard/manager",
  },
  {
    Icon: AdminCommunitytIcon,
    title: "Stories",
    link: "/admin/dashboard/stories",
  },
];

//for payments page signin

export const socialSignin = [
  {
    SocialIcon: FacebookPaymentsSocial,
    type: "Facebook",
  },
  {
    SocialIcon: GooglePaymentsSocial,
    type: "Google",
  },
  {
    SocialIcon: GmailPaymentsSocial,
    type: "Normal",
  },
];

export const mentorPageData = [
  {
    title: "WEEKLY COACH-LED GROUP SESSIONS",
    data: [
      "Collaborate with other members making life changes",
      "Exchange wisdom, ideas, and experiences",
      "Support and celebrate each other’s success",
    ],
    Icon: MerityCommunityIconPNG,
  },
  {
    title: "1:1 COACHING",
    data: [
      "Receive tailored individual support",
      "Gain accountability on your efforts",
      "Expedite your progress to your goals",
      "Affordable access to personal guidance",
    ],
    Icon: MentorMinutes,
  },
  {
    title: "ACCESS TO SPECIAL PROGRAMS",
    data: [
      "Participate in supplemental webinars and workshops",
      " Deepen knowledge on specific subjects like mindfulness, navigating life stages, personal finance",
      "Access an expertly designed, curated library of resources",
    ],
    Icon: MentorMonitorIcon,
  },
];
