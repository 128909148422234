import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import Data from "../utilities/content/navbarAndFooter.json";
import OutsideClickCloseContainer from "../utilities/OutsideClickCloseContainer";
import ConfirmModal from "../utilities/modals/ConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { submitLogout } from "../../AppRedux/actions/MemberLogin";
import {
  MemberNavbarDownArrow,
  NavbarFirstLogo,
  NavbarSecondLogo,
} from "../utilities/svg";
import {
  currentAppUser,
  getMemberDashboardDataFromLocalStorage,
  getUserDataFromLocalStorage,
  getUserTokenFromLocalStorage,
  UserNameAndAvatar,
} from "../utilities";
let { links } = Data.navbarData;

const Navbar = ({ whiteNavbar, fixedNavbar, noShadow, onlyTopBar }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [navbarDropdownOpen, setNavbarDropdownOpen] = useState(false);
  const [colorChange, setColorchange] = useState(whiteNavbar ? true : false);
  const [fixed, setFixed] = useState(fixedNavbar ? true : false);
  const [showLogoutModal, setshowLogoutModal] = useState(false);
  const { firstName, lastName } = getUserDataFromLocalStorage();
  const profilePhoto =
    getMemberDashboardDataFromLocalStorage()?.member?.User.profilePhoto;
  const { submitUserLogoutBegin } = useSelector(
    ({ memberLogin }) => memberLogin
  );
  const userName = firstName + " " + lastName;

  const logoutModalData = {
    heading: "Do you want to logout from Merity platform ?",
    buttonText: "Yes",
    confirmClick: () => {
      dispatch(submitLogout());
    },
    cancelButtonText: "Cancel",
    cancelButtonClick: () => {
      setshowLogoutModal(false);
    },
    buttonLoader: submitUserLogoutBegin,
  };

  if (!whiteNavbar) {
    window.addEventListener("scroll", function () {
      if (window.scrollY >= 80) {
        setColorchange(true);
      } else {
        setColorchange(false);
      }
    });
  }

  if (whiteNavbar && !fixedNavbar && !onlyTopBar) {
    window.addEventListener("scroll", function () {
      if (window.scrollY >= 120) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    });
  }

  const linkToRedirect =
    currentAppUser() === "MEMBER_SUBSCRIBED"
      ? "/member/dashboard"
      : currentAppUser() === "MEMBER_NOT_SUBSCRIBED"
      ? "/subscription_plans"
      : currentAppUser() === "ADMIN"
      ? "/admin/dashboard"
      : currentAppUser() === "MENTOR"
      ? "/mentor/dashboard"
      : "/manager/dashboard";

  return (
    <nav
      className={`z-50 w-full transition-all ease-in-out ${
        fixed ? "fixed animate-navbar" : ""
      } ${
        colorChange
          ? `bg-white  ${noShadow ? (fixed ? "shadow-lg" : "") : "shadow-lg"}`
          : hamburgerOpen
          ? "bg-white"
          : "bg-transparent"
      }`}
    >
      <div className="flex justify-between items-center mx-auto py-2 w-full  px-2 xl:px-0 xl:w-10/12">
        <Link to="/">
          {colorChange ? (
            <NavbarSecondLogo className="h-8 370px:h-11 sm:h-12 lg:h-12 xl:h-14 mt-1 sm:mt-0 w-40 sm:w-52 lg:w-60" />
          ) : hamburgerOpen ? (
            <NavbarSecondLogo className="h-8 370px:h-11 sm:h-12 lg:h-12 xl:h-14 mt-1 sm:mt-0 w-40 sm:w-52 lg:w-60" />
          ) : (
            <NavbarFirstLogo className="h-8 370px:h-11 sm:h-12 lg:h-12 xl:h-14 mt-1 sm:mt-0 w-40 sm:w-52 lg:w-60" />
          )}
        </Link>

        <div
          className={`justify-between items-center max-w-3xl hidden 950px:flex md:w-1/2 xl:w-3/5`}
        >

          {currentAppUser() === 'MEMBER_SUBSCRIBED' && 

            <NavSingleLink
              key="member_dasbboard"
              name="Dashboard"
              pathname="bg-none"
              link="/member/dashboard"
            />
            
          }          

          {links?.map((link, index) => (
            <NavSingleLink
              key={index}
              {...link}
              colorChange={colorChange}
              pathname={pathname}
            />
          ))}

          {getUserTokenFromLocalStorage() && firstName !== "" ? (
            currentAppUser() === "MEMBER_NOT_SUBSCRIBED" ? (
              <div className="relative">
                <OutsideClickCloseContainer setState={setNavbarDropdownOpen}>
                  <div
                    onClick={() => setNavbarDropdownOpen(!navbarDropdownOpen)}
                    className={`cursor-pointer flex items-center gap-2 buttonHover activeState ${
                      colorChange ? "text-brandGrey" : "text-white"
                    }`}
                  >
                    <UserNameAndAvatar
                      name={userName}
                      classNames={`activeState navbarLink ${
                        colorChange ? "scrollnavbarLink" : "initialnavbarLink"
                      }`}
                    />
                    <MemberNavbarDownArrow className=" stroke-current" />
                  </div>
                  {navbarDropdownOpen && (
                    <ProfileDropdown
                      data={dropdownData}
                      setshowLogoutModal={setshowLogoutModal}
                      setNavbarDropdownOpen={setNavbarDropdownOpen}
                    />
                  )}
                </OutsideClickCloseContainer>
              </div>
            ) : (
              <Link to={linkToRedirect}>
                <UserNameAndAvatar
                  profilePhoto={profilePhoto}
                  name={userName}
                  classNames={`activeState navbarLink ${
                    colorChange ? "scrollnavbarLink" : "initialnavbarLink"
                  }`}
                />
              </Link>
            )
          ) : (
            <div className="flex gap-4 items-center">
              <HeaderButton
                text="LOGIN"
                link="/login"
                outlineBtn={true}
                colorChange={colorChange}
              />
              <HeaderButton
                text="JOIN"
                link="/join"
                outlineBtn={false}
                colorChange={colorChange}
              />
            </div>
          )}
        </div>

        {/* hamburger */}
        <Hamburger
          hamburgerOpen={hamburgerOpen}
          sethamburgerOpen={setHamburgerOpen}
          colorChange={colorChange}
        />
      </div>

      {/* hamburger menu */}
      {hamburgerOpen && (
        <HamburgerMenu
          pathname={pathname}
          sethamburgerOpen={setHamburgerOpen}
          linkToRedirect={linkToRedirect}
          userName={userName}
          setshowLogoutModal={setshowLogoutModal}
        />
      )}

      {showLogoutModal && (
        <ConfirmModal
          {...logoutModalData}
          state={showLogoutModal}
          setState={setshowLogoutModal}
        />
      )}
    </nav>
  );
};

export default Navbar;

const NavSingleLink = ({ name, link, element, colorChange, pathname, target }) => {

  const BottonLine = () => (
    <div
      className={`h-1 w-full transition-all delay-100 ease-out ${
        link === pathname ? "bg-brandYellow" : ""
      }`}
    />
  );

  return (
    <>
      {element === "hyperlink" ? (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={`activeState navbarLink ${
            colorChange ? `scrollnavbarLink` : `initialnavbarLink`
          }`}
        >
          {name}
          <BottonLine />
        </a>
      ) : (
        <NavLink
          to={link}
          target={target}
          className={({ isActive }) =>
            `activeState navbarLink  ${
              colorChange ? `scrollnavbarLink` : `initialnavbarLink`
            } ${
              isActive
                ? colorChange
                  ? "text-brandBlue hover:text-brandYellow "
                  : "text-white"
                : ""
            }`
          }
        >
          {name}
          <BottonLine />
        </NavLink>
      )}
    </>
  );
};

export const Hamburger = ({ hamburgerOpen, sethamburgerOpen, colorChange }) => (
  <div
    className={` sm:p-1 sm:pr-2 pr-1 500px:mr-5 950px:hidden shadow-hamburger 
     ${
       hamburgerOpen
         ? colorChange
           ? "bg-gray-200"
           : "hover:opacity-70 ring-1 ring-gray-100 rounded-md"
         : colorChange
         ? "bg-gray-100 hover:bg-gray-200"
         : "bg-transparent hover:opacity-70 ring-1 ring-gray-100 rounded-md"
     }`}
    onClick={() => sethamburgerOpen(!hamburgerOpen)}
  >
    <div className=" relative sm:max-w-xl mx-auto">
      <nav>
        <button
          className={` w-10 h-10 relative focus:outline-none  ${
            !colorChange
              ? hamburgerOpen
                ? "text-gray-500"
                : "text-white"
              : "text-gray-500"
          }`}
        >
          <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <span
              aria-hidden="true"
              className={`hamburgerStick  ${
                hamburgerOpen ? "rotate-45" : "translate-y-1.5"
              }`}
            ></span>
            <span
              aria-hidden="true"
              className={`hamburgerStick ${hamburgerOpen ? "opacity-0" : ""}`}
            ></span>
            <span
              aria-hidden="true"
              className={`hamburgerStick  ${
                hamburgerOpen ? "-rotate-45" : "-translate-y-1.5"
              }`}
            ></span>
          </div>
        </button>
      </nav>
    </div>
  </div>
);

const HamburgerMenu = ({
  pathname,
  sethamburgerOpen,
  profilePhoto,
  linkToRedirect,
  userName,
  setshowLogoutModal,
}) => {
  const [navbarDropdownOpen, setNavbarDropdownOpen] = useState(false);

  return (
    <div className="flexCenter flex-col animate-mobileMenu bg-white w-full absolute z-20 transform pt-3 shadow-lg pb-3">
      {links.map(({ name, link, element }, index) => (
        <span key={index}>
          {element === "hyperlink" ? (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className="activeState block navbarLink scrollnavbarLink"
            >
              {name}
              {
                <div
                  className={`h-1 mx-auto transition-all delay-100 ease-out mb-4  ${
                    link === pathname ? "bg-brandYellow" : ""
                  }`}
                />
              }
            </a>
          ) : (
            <NavLink
              to={link}
              key={index}
              className={({ isActive }) =>
                `activeState navbarLink block scrollnavbarLink ${
                  isActive ? "text-brandBlue" : ""
                }`
              }
              onClick={() => sethamburgerOpen(false)}
            >
              {name}
              {
                <div
                  className={`h-1 mx-auto transition-all delay-100 ease-out mb-4  ${
                    link === pathname ? "bg-brandYellow" : ""
                  }`}
                />
              }
            </NavLink>
          )}
        </span>
      ))}
      {getUserTokenFromLocalStorage() && userName !== "" ? (
        currentAppUser() === "MEMBER_NOT_SUBSCRIBED" ? (
          <div className="relative">
            <OutsideClickCloseContainer setState={setNavbarDropdownOpen}>
              <div
                onClick={() => setNavbarDropdownOpen(!navbarDropdownOpen)}
                className={`cursor-pointer flex items-center gap-2 buttonHover activeState text-brandGrey`}
              >
                <UserNameAndAvatar
                  classNames="activeState navbarLink scrollnavbarLink"
                  name={userName}
                />
                <MemberNavbarDownArrow className="stroke-current" />
              </div>
              {navbarDropdownOpen && (
                <ProfileDropdown
                  data={dropdownData}
                  setshowLogoutModal={setshowLogoutModal}
                  setNavbarDropdownOpen={setNavbarDropdownOpen}
                />
              )}
            </OutsideClickCloseContainer>
          </div>
        ) : (
          <Link to={linkToRedirect}>
            <UserNameAndAvatar
              profilePhoto={profilePhoto}
              classNames="activeState navbarLink scrollnavbarLink"
              name={userName}
            />
          </Link>
        )
      ) : (
        <div className="w-full flex flex-col gap-4 items-center mb-6">
          <HeaderButton
            text="LOGIN"
            link="/login"
            outlineBtn={true}
            colorChange={true}
            hamburgerBtn={true}
          />
          <HeaderButton
            text="JOIN"
            link="/join"
            outlineBtn={false}
            colorChange={true}
            hamburgerBtn={true}
          />
        </div>
      )}
    </div>
  );
};

const HeaderButton = ({
  colorChange,
  outlineBtn,
  text,
  link,
  hamburgerBtn,
}) => (
  <Link
    to={link}
    className={`navbarButton hover:border-brandYellow active:border-brandYellow ${
      outlineBtn
        ? `hover:text-brandYellow active:text-brandYellow ${
            colorChange
              ? "text-brandBlue border-brandBlue"
              : "text-white border-white"
          }`
        : `hover:text-white hover:bg-brandYellow active:bg-brandYellow active:text-white ${
            colorChange
              ? "text-white bg-brandBlue border-brandBlue "
              : "text-brandBlue bg-white border-white"
          }`
    } ${hamburgerBtn ? "w-1/2" : ""} `}
  >
    {text}
  </Link>
);

// Profile dropdown
const ProfileDropdown = ({
  data,
  setshowLogoutModal,
  setNavbarDropdownOpen,
}) => {
  const navigate = useNavigate();

  const onMemberDropdownItemClick = (listItem) => {
    setNavbarDropdownOpen((state) => !state);
    if (listItem.text === "Logout") {
      setshowLogoutModal(true);
    } else if (listItem.link) {
      return navigate(listItem.link);
    }
  };

  return (
    <div className="memberDropdownList right-0" style={{ minWidth: "150px" }}>
      {data?.map((listItem, index) => (
        <div
          className={`${
            listItem.text === ""
              ? "hidden"
              : "memberDropdownListItem text-brandGrey"
          }`}
          onClick={() => onMemberDropdownItemClick(listItem)}
          key={index}
        >
          {listItem.text}
        </div>
      ))}
    </div>
  );
};

const dropdownData = [
  {
    text: "Subscription",
    link: "/subscription_plans",
  },
  {
    text: "Logout",
  },
];
