import {
  deleteMentorAvailableSlots,
  submitMentorCalendarPreference,
  updateMentorAvailableSlots,
} from '../../../../AppRedux/actions/MentorCalendar';

import moment from 'moment-timezone';


import { utcTime, localTime } from '../../MyCalendarGeneralItems';

import Cookies from 'universal-cookie';

// Add new time field
export const addFormFields = (
  inputCount,
  setFormData,
  formData,
  setInputCount,
) => {
  let count = Number(inputCount.slice(-1)) + 1;
  if (inputCount.length === 0) {
    count--;
  }
  setFormData({
    ...formData,
    fromTime: {
      ...formData.fromTime,
      [`from${count}`]: '',
    },
    toTime: {
      ...formData.toTime,
      [`to${count}`]: '',
    },
  });
  setInputCount([...inputCount, Number(count)]);
};

// on submit
export const submitMentorAvailableTimeSlot = (
  e,
  formData,
  setModalError,
  selectedDateAvailableSlots,
  dayInfo,
  modalFor,
  dispatch,
  setFormError,
  formError,
  SelectedDataAvailableSlotsID,
) => {
  e?.preventDefault();
  const validationErrors = customeFormValidation(
    formData,
    setModalError,
    selectedDateAvailableSlots,
  );

  if (Object.keys(validationErrors).length === 0) {
    const fromValues = Object.values(formData.fromTime), //converting values to array fromat
      toValues = Object.values(formData.toTime),
      times = Array(fromValues.length)
        .fill()
        .map((_, index) => {

          const fillStartTime = dayInfo.dateStr ? dayInfo.dateStr + ' ' + fromValues[index] : dayInfo?.event?.startStr + ' ' + fromValues[index];
          const fillEndTime   = dayInfo.dateStr ? dayInfo.dateStr + ' ' + toValues[index] : dayInfo?.event?.startStr + ' ' + toValues[index];

          return {
            startTime: moment(fillStartTime).toISOString(),
            endTime: moment(fillEndTime).toISOString(),
          }
        });

    let newTimes = [];

    selectedDateAvailableSlots.map(({ id, startTime, endTime }, index) => {
      newTimes[index] = {
        startTime,
        endTime,
        id: id,
      };
      return '';
    });

    const cookies  = new Cookies(null, { path: '/' });
    const timezone = cookies.get('geolocation');
    // const timezone = 'America/Los_Angeles';

    moment.tz.setDefault(timezone);

    const data = {
      id: 1,
      date: dayInfo.dateStr ? dayInfo.dateStr : dayInfo?.event?.startStr,
      times: [...times, ...newTimes],
      timezone: timezone
    };

    // checking modal is for update or submit
    if (modalFor === 'update') {
      if (data.times.length === 0) {
        let data = {
          ids: SelectedDataAvailableSlotsID,
          date: dayInfo.dateStr ? dayInfo.dateStr : dayInfo?.event?.startStr,
        };
        dispatch(deleteMentorAvailableSlots(data));
      } else {
       
        dispatch(updateMentorAvailableSlots(data));
      }
    } else {
      dispatch(submitMentorCalendarPreference(data));
    }
  } else {
    setFormError({ ...formError, ...validationErrors });
  }
};

const customeFormValidation = (
  formData,
  setModalError,
  selectedDateAvailableSlots,
) => {
  let { fromTime, toTime } = formData;
  let validationErrors = {};

  Object.values(fromTime).forEach((value, index) => {
    const inputKey = `from${index}`,
      toInputKey = `to${index}`;

    // require validation
    if (value === null || value.trim() === '') {
      validationErrors = {
        ...validationErrors,
        fromTime: {
          ...validationErrors.fromTime,
          [inputKey]: 'Time is required.',
        },
      };
    }

    // possible timing validation
    if (
      fromTime[Object.keys(fromTime)[index]] >
      toTime[Object.keys(toTime)[index]]
    ) {
      setModalError('Please Give Valid From and To Time Slots');
      validationErrors = {
        ...validationErrors,
        fromTime: {
          ...validationErrors.fromTime,
          [inputKey]: 'Give a valid from & to time',
        },
        toTime: {
          ...validationErrors.toTime,
          [toInputKey]: 'Give a valid from & to time',
        },
      };
    }

    // need minimum 15 minutes for each time slot
    if (
      fromTime[Object.keys(fromTime)[index]] ===
      toTime[Object.keys(toTime)[index]]
    ) {
      setModalError('Please add atleast 15 minutes in time slot');
      validationErrors = {
        ...validationErrors,
        fromTime: {
          ...validationErrors.fromTime,
          errorFound: true,
        },
      };
    } else if (
      toTime[Object.keys(toTime)[index]].split(':')[1] >
      fromTime[Object.keys(fromTime)[index]].split(':')[1]
    ) {
      if (
        toTime[Object.keys(toTime)[index]].split(':')[1] -
          fromTime[Object.keys(fromTime)[index]].split(':')[1] <
        15
      ) {
        if (
          toTime[Object.keys(toTime)[index]].split(':')[0] <=
          fromTime[Object.keys(fromTime)[index]].split(':')[0]
        ) {
          setModalError('Please add atleast 15 minutes in time slot');
          validationErrors = {
            ...validationErrors,
            fromTime: {
              ...validationErrors.fromTime,
              errorFound: true,
            },
          };
        }
      }
    } else {
      if (
        toTime[Object.keys(toTime)[index]].split(':')[1] <=
        fromTime[Object.keys(fromTime)[index]].split(':')[1]
      ) {
        if (
          fromTime[Object.keys(fromTime)[index]].split(':')[1] -
            toTime[Object.keys(toTime)[index]].split(':')[1] >
          45
        ) {
          if (
            toTime[Object.keys(toTime)[index]].split(':')[0] -
              fromTime[Object.keys(fromTime)[index]].split(':')[0] <=
            1
          ) {
            setModalError('Please add atleast 15 minutes in time slot');
            validationErrors = {
              ...validationErrors,
              fromTime: {
                ...validationErrors.fromTime,
                errorFound: true,
              },
            };
          }
        }
      }
    }

    // same time period validation
    Object.values(fromTime).forEach((_, indexTwo) => {
      if (index !== indexTwo) {
        if (
          fromTime[Object.keys(fromTime)[index]] ===
          fromTime[Object.keys(fromTime)[indexTwo]]
        ) {
          setModalError('You selected same time period more than one time');
          validationErrors = {
            ...validationErrors,
            fromTime: {
              ...validationErrors.fromTime,
              errorFound: true,
            },
          };
        }
      }
    });

    // validation for if one time period is time in-between another time-period
    Object.values(fromTime).forEach((_, fromIndexTwo) => {
      if (
        fromTime[Object.keys(fromTime)[fromIndexTwo]] >
          fromTime[Object.keys(fromTime)[index]] &&
        fromTime[Object.keys(fromTime)[fromIndexTwo]] <
          toTime[Object.keys(toTime)[index]]
      ) {
        setModalError('You selected same time period more than one time');
        validationErrors = {
          ...validationErrors,
          fromTime: {
            ...validationErrors.fromTime,
            errorFound: true,
          },
        };
      }
    });
  });

  // require validation
  Object.values(toTime).forEach((value, index) => {
    const inputKey = `to${index}`;
    if (value === null || value.trim() === '') {
      validationErrors = {
        ...validationErrors,
        toTime: {
          ...validationErrors.toTime,
          [inputKey]: 'Time is required.',
        },
      };
    }

    // same time period validation
    Object.values(toTime).forEach((_, indexTwo) => {
      if (index !== indexTwo) {
        if (
          toTime[Object.keys(toTime)[index]] ===
          toTime[Object.keys(toTime)[indexTwo]]
        ) {
          setModalError('You selected same time period more than one time');
          validationErrors = {
            ...validationErrors,
            toTime: {
              ...validationErrors.toTime,
              errorFound: true,
            },
          };
        }
      }
    });
  });


  //* Update modal validations
  let availableSlotsFromTime = selectedDateAvailableSlots.map(
      (slot) => slot.startTime,
    ),
    availableSlotsToTime = selectedDateAvailableSlots.map(
      (slot) => slot.endTime,
    );

  // checking input time with already added time slot (fromTime)
  availableSlotsFromTime.forEach((slotTime) => {
    Object.values(fromTime).forEach((inputFromTime) => {
      if (moment(slotTime).format('HH:mm') === inputFromTime) {
        setModalError('please add timeperiod that not same as other timeslot');
        validationErrors = {
          ...validationErrors,
          toTime: {
            ...validationErrors.toTime,
            errorFound: true,
          },
        };
      }
    });
  });

  // checking input time with already added time slot (toTime)
  availableSlotsToTime.forEach((slotTime) => {
    Object.values(toTime).forEach((inputToTime) => {
      if (moment(slotTime).format('HH:mm') === inputToTime) {
        setModalError('please add timeperiod that not same as other timeslot');
        validationErrors = {
          ...validationErrors,
          toTime: {
            ...validationErrors.toTime,
            errorFound: true,
          },
        };
      }
    });
  });

  // check if slot entered by the user overlaps the available slots
  availableSlotsFromTime.forEach((slotFromTime, slotIndex) => {
    Object.values(fromTime).forEach((inputFromTime, index) => {
      
      const availableSlotStartTime  = moment(slotFromTime).format('HH:mm');
      const availableSlotEndTime    = moment(availableSlotsToTime[slotIndex]).format('HH:mm');
      const endSlotTime             = Object.values(toTime)[index];

      if(inputFromTime <= availableSlotStartTime && endSlotTime >= availableSlotEndTime) {

        setModalError('please add timeperiod that not same as other timeslot !! ');
        validationErrors = {
          ...validationErrors,
          toTime: {
            ...validationErrors.toTime,
            errorFound: true,
          },
        };
      }

    });

  });

  return validationErrors;
};
