import { isValidPhoneNumber } from "react-phone-number-input";
import postalCodes from "postal-codes-js";

export const userBasicDetailsValidation = (userDetails) => {
  const {
    firstName,
    lastName,
    email,
    address1,
    // address2,
    country,
    state,
    zip,
    phone,
  } = userDetails;

  const validationErrors = {};
  if (firstName?.trim() === "") {
    validationErrors.firstName = "First name is required.";
  }
  if (firstName?.trim() !== "" && firstName.length < 3) {
    validationErrors.firstName = "* please give your valid name.";
  }

  if (lastName?.trim() === "") {
    validationErrors.lastName = "Last name is required.";
  }

  if (email?.trim() === "") {
    validationErrors.email = "Email is required.";
  }
  if (
    email?.trim() !== "" &&
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  ) {
    validationErrors.email = "* Please enter a valid email address!";
  }

  if (phone?.trim() === "") {
    validationErrors.phone = "Phone number is required.";
  }
  if (phone?.trim() !== "" && !isValidPhoneNumber(phone)) {
    validationErrors.phone = "* You have entered an invalid phone number!";
  } else if (phone?.trim() !== "" && isValidPhoneNumber(phone)) {
    delete validationErrors[phone];
  }

  if (address1?.trim() === "") {
    validationErrors.address1 = "Address is required.";
  }
  if (address1?.trim() !== "" && address1.length < 5) {
    validationErrors.address1 = "Enter your valid address.";
  }

  if (country?.trim() === "") {
    validationErrors.country = "Country is required.";
  }
  if (state?.trim() === "") {
    validationErrors.state = "State is required.";
  }
  if (zip?.trim() === "") {
    validationErrors.zip = "Zip is required.";
  }
  if (zip?.trim() !== "" && postalCodes.validate(country, zip) !== true) {
    validationErrors.zip = `Postal code ${zip} is not valid for country ${country}`;
  }
  if (zip?.trim() !== "" && zip.startsWith("00") === true) {
    validationErrors.zip = `Postal code ${zip} is not valid for country ${country}`;
  }

  return validationErrors;
};
