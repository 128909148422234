import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import { MobileResponsiveAccordion } from "../../../utilities/svg";
import { getSingleMemberPaymentDetails } from "../../../../AppRedux/actions/AdminMember";
import { getMemberTransactions } from "../../../../AppRedux/actions/MemberProfile";
import { ListHeader } from "../tableComponents/ExercisesList";
import { Scaleloader, RcTooltip } from "../../../utilities";
import {
  NoListToShowContainer,
  PageNumbers,
} from "../../dashboardGeneralItems";

const SubscriptionPaymentTab = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const subtab = params.getAll("subtab").toString();
  const id = params.getAll("id").toString();
  const [currentPage, setcurrentPage] = useState(1);
  const [transactionHistory, setTransactionHistory] = useState("");
  const [clicked, setClicked] = useState(false);
  const [commonLoader, setCommonLoader] = useState(true);
  const { getMemberTransactionHistorySuccessData } = useSelector(
    ({ memberProfile }) => memberProfile
  );

  const { getSingleMemberPaymentDetailsSuccessData } = useSelector(
    ({ adminMember }) => adminMember
  );

  useEffect(() => {
    if (subtab === "purchased") {
      headers[1] = "Mins Purchased";
      if (id) {
        setCommonLoader(false);
        dispatch(getSingleMemberPaymentDetails(id, 1, currentPage));
      } else {
        setCommonLoader(false);
        dispatch(getMemberTransactions(1, currentPage));
      }
    } else {
      headers[1] = "Plan";
      if (id) {
        setCommonLoader(false);
        dispatch(getSingleMemberPaymentDetails(id, 2, currentPage));
      } else {
        setCommonLoader(false);
        dispatch(getMemberTransactions(2, currentPage));
      }
    }
  }, [id, dispatch, subtab, currentPage]);

  useEffect(() => {
    if (getMemberTransactionHistorySuccessData) {
      setTransactionHistory(getMemberTransactionHistorySuccessData?.data);
      setCommonLoader(true);
    }
    if (getSingleMemberPaymentDetailsSuccessData) {
      setTransactionHistory(getSingleMemberPaymentDetailsSuccessData?.data);
      setCommonLoader(true);
    }
  }, [
    getMemberTransactionHistorySuccessData,
    getSingleMemberPaymentDetailsSuccessData,
  ]);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <section className="mt-4 px-4 950px:px-6 min-h-80vh relative pb-14">
      <ListHeader
        headers={headers}
        gridClass="hidden md:grid text-center grid-cols-lgmemberProfileTransactionHeader"
      />
      <div className="member-table-row-items">
        {commonLoader && transactionHistory ? (
          transactionHistory?.rows.length !== 0 ? (
            transactionHistory?.rows.map((props, index) => {
              const {
                id,
                transactionId,
                purchasedMinute,
                planDuration,
                createdAt,
                amountPaid,
                status,
              } = props;
              const createdDate = moment(new Date(createdAt)).format(
                "MM-DD-YYYY"
              );
              return (
                <>
                  <div
                    key={index}
                    className={`hidden md:grid dashboardListHover text-center member-table-row-item grid-cols-lgmemberProfileTransactionListing  gap-2 font-medium text-sm xl:text-base cursor-default
                ${
                  index === transactionHistory.length - 1 ? "border-none" : ""
                }`}
                  >
                    <RcTooltip content={transactionId}>
                      <h1 className="oneLineContent">{transactionId}</h1>
                    </RcTooltip>

                    <p className="oneLineContent">{`${
                      subtab === "purchased"
                        ? purchasedMinute + " mins"
                        : planDuration
                    }`}</p>

                    <RcTooltip content={`${createdDate}`}>
                      <p className="oneLineContent">{`${createdDate}`}</p>
                    </RcTooltip>

                    <p className="oneLineContent">$ {amountPaid}</p>
                    <PaymentStatus
                      status={status[0].toUpperCase() + status.substring(1)}
                    />
                  </div>

                  {/* Mobile Responsive      */}
                  <div
                    onClick={() => {
                      toggle(id);
                    }}
                    className="cursor-pointer  850px:hidden px-2 sm:px-4 py-3 sm:py-6 m-2 rounded-r"
                    style={{ background: "#fbfbfb" }}
                  >
                    <div className="flex justify-between items-center">
                      <h4 className=" font-bold text-xs 370px:text-base md:text-xl">
                        {`Amount Paid: $ ${amountPaid}`}
                      </h4>
                      <span
                        style={{ color: "#202020" }}
                        className={`text-xl cursor-pointer pr-2 ${
                          clicked === id ? "animate-navbar" : ""
                        }`}
                      >
                        <MobileResponsiveAccordion
                          className={`cursor-pointer ${
                            clicked === id ? "transform rotate-180 " : ""
                          }`}
                        />
                      </span>
                    </div>
                    {clicked === id && (
                      <div className="animate-navbar grid grid-cols-2 mt-2 gap-2 ">
                        <h1>Transaction Id</h1>
                        <p className="text-xs 370px:text-base md:text-lg overflow-hidden">
                          {transactionId}
                        </p>
                        <p>Status</p>
                        <PaymentStatus
                          status={status[0].toUpperCase() + status.substring(1)}
                        />
                        <p>Plan</p>
                        <p className="oneLineContent">{`${
                          subtab === "purchased"
                            ? purchasedMinute + " mins"
                            : planDuration
                        }`}</p>
                        <p>Date</p>
                        <p className="oneLineContent">{`${createdDate}`}</p>
                      </div>
                    )}
                  </div>
                </>
              );
            })
          ) : (
            <NoListToShowContainer text="There are no transactions" />
          )
        ) : (
          <Scaleloader />
        )}
      </div>
      <PageNumbers
        count={transactionHistory?.count}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={commonLoader}
        limit={10}
        onPageNumberClick={(number) => {
          if (subtab === "purchased") {
            if (id) {
              dispatch(getSingleMemberPaymentDetails(id, 1, number));
            } else {
              dispatch(getMemberTransactions(1, number));
            }
          } else if (subtab === "subscription") {
            if (id) {
              dispatch(getSingleMemberPaymentDetails(id, 1, number));
            } else {
              dispatch(getMemberTransactions(2, number));
            }
          }
        }}
      />
    </section>
  );
};

export default SubscriptionPaymentTab;

export const PaymentStatus = ({ status }) => {
  return (
    <div>
      <span
        className={`px-5 py-1 text-xs xl:text-sm rounded-3xl ${
          status === "Pending"
            ? "text-memberProfilePaymentHistoryPending bg-memberProfilePaymentHistoryPending"
            : "text-memberProfilePaymentHistoryDeclined bg-memberProfilePaymentHistoryDeclined"
        }`}
        style={{
          backgroundColor: status === "Complete" && "#DCF7EB",
          color: status === "Complete" && "#12BE76",
        }}
      >
        {status}
      </span>
    </div>
  );
};

const headers = [
  "Trans ID  ",
  "Mins Purchased",
  "Date",
  "Amount Paid",
  "Status",
];
