import {
  GET_MEMBER_CALENDAR_PREFERENCE_BEGIN,
  GET_MEMBER_CALENDAR_PREFERENCE_SUCCESS,
  GET_MEMBER_CALENDAR_PREFERENCE_FAILURE,
  RESET_GET_MEMBER_CALENDAR_PREFERENCE,
  SCHEDULE_MEETING_WITH_MENTOR_BEGIN,
  SCHEDULE_MEETING_WITH_MENTOR_SUCCESS,
  SCHEDULE_MEETING_WITH_MENTOR_FAILURE,
  RESET_SCHEDULE_MEETING_WITH_MENTOR,
  VIEW_MEMBER_MEETING_BEGIN,
  VIEW_MEMBER_MEETING_SUCCESS,
  VIEW_MEMBER_MEETING_FAILURE,
  RESET_VIEW_MEMBER_MEETING,
  CANCEL_MEMBER_MEETING_BEGIN,
  CANCEL_MEMBER_MEETING_SUCCESS,
  CANCEL_MEMBER_MEETING_FAILURE,
  RESET_CANCEL_MEMBER_MEETING,
  UPDATE_MEMBER_MEETING_STATUS_BEGIN,
  UPDATE_MEMBER_MEETING_STATUS_SUCCESS,
  UPDATE_MEMBER_MEETING_STATUS_FAILURE,
  RESET_UPDATE_MEMBER_MEETING_STATUS,
} from "../constants/MemberCalendar";

const initialState = {
  getMemberCalendarPreferenceBegin: false,
  getMemberCalendarPreferenceSuccess: false,
  getMemberCalendarPreferenceSuccessData: null,
  getMemberCalendarPreferenceFailure: false,
  getMemberCalendarPreferenceFailureData: null,

  scheduleMeetingWithMentorBegin: false,
  scheduleMeetingWithMentorSuccess: false,
  scheduleMeetingWithMentorSuccessData: null,
  scheduleMeetingWithMentorFailure: false,
  scheduleMeetingWithMentorFailureData: null,

  viewMemberMeetingBegin: false,
  viewMemberMeetingSuccess: false,
  viewMemberMeetingSuccessData: null,
  viewMemberMeetingFailure: false,
  viewMemberMeetingFailureData: null,

  cancelMemberMeetingBegin: false,
  cancelMemberMeetingSuccess: false,
  cancelMemberMeetingSuccessData: null,
  cancelMemberMeetingFailure: false,
  cancelMemberMeetingFailureData: null,

  updateMemberMeetingStatusBegin: false,
  updateMemberMeetingStatusSuccess: false,
  updateMemberMeetingStatusSuccessData: null,
  updateMemberMeetingStatusFailure: false,
  updateMemberMeetingStatusFailureData: null,
};

export const memberCalendar = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    //get calendar preferneces
    case GET_MEMBER_CALENDAR_PREFERENCE_BEGIN:
      return {
        ...state,
        getMemberCalendarPreferenceBegin: true,
        getMemberCalendarPreferenceSuccess: false,
        getMemberCalendarPreferenceSuccessData: null,
        getMemberCalendarPreferenceFailure: false,
        getMemberCalendarPreferenceFailureData: null,
      };
    case GET_MEMBER_CALENDAR_PREFERENCE_SUCCESS:
      return {
        ...state,
        getMemberCalendarPreferenceBegin: false,
        getMemberCalendarPreferenceSuccess: true,
        getMemberCalendarPreferenceSuccessData: data,
        getMemberCalendarPreferenceFailure: false,
        getMemberCalendarPreferenceFailureData: null,
      };
    case GET_MEMBER_CALENDAR_PREFERENCE_FAILURE:
      return {
        ...state,
        getMemberCalendarPreferenceBegin: false,
        getMemberCalendarPreferenceSuccess: false,
        getMemberCalendarPreferenceSuccessData: null,
        getMemberCalendarPreferenceFailure: true,
        getMemberCalendarPreferenceFailureData: data,
      };
    case RESET_GET_MEMBER_CALENDAR_PREFERENCE:
      return {
        ...state,
        getMemberCalendarPreferenceBegin: false,
        getMemberCalendarPreferenceSuccess: false,
        getMemberCalendarPreferenceFailure: false,
        getMemberCalendarPreferenceFailureData: null,
      };

    //schedule meeting with mentor
    case SCHEDULE_MEETING_WITH_MENTOR_BEGIN:
      return {
        ...state,
        scheduleMeetingWithMentorBegin: true,
        scheduleMeetingWithMentorSuccess: false,
        scheduleMeetingWithMentorSuccessData: null,
        scheduleMeetingWithMentorFailure: false,
        scheduleMeetingWithMentorFailureData: null,
      };
    case SCHEDULE_MEETING_WITH_MENTOR_SUCCESS:
      return {
        ...state,
        scheduleMeetingWithMentorBegin: false,
        scheduleMeetingWithMentorSuccess: true,
        scheduleMeetingWithMentorSuccessData: data,
        scheduleMeetingWithMentorFailure: false,
        scheduleMeetingWithMentorFailureData: null,
      };
    case SCHEDULE_MEETING_WITH_MENTOR_FAILURE:
      return {
        ...state,
        scheduleMeetingWithMentorBegin: false,
        scheduleMeetingWithMentorSuccess: false,
        scheduleMeetingWithMentorSuccessData: null,
        scheduleMeetingWithMentorFailure: true,
        scheduleMeetingWithMentorFailureData: data,
      };
    case RESET_SCHEDULE_MEETING_WITH_MENTOR:
      return {
        ...state,
        scheduleMeetingWithMentorBegin: false,
        scheduleMeetingWithMentorSuccess: false,
        scheduleMeetingWithMentorSuccessData: null,
        scheduleMeetingWithMentorFailure: false,
        scheduleMeetingWithMentorFailureData: null,
      };

    //view member meeting
    case VIEW_MEMBER_MEETING_BEGIN:
      return {
        ...state,
        viewMemberMeetingBegin: true,
        viewMemberMeetingSuccess: false,
        viewMemberMeetingSuccessData: null,
        viewMemberMeetingFailure: false,
        viewMemberMeetingFailureData: null,
      };
    case VIEW_MEMBER_MEETING_SUCCESS:
      return {
        ...state,
        viewMemberMeetingBegin: false,
        viewMemberMeetingSuccess: true,
        viewMemberMeetingSuccessData: data,
        viewMemberMeetingFailure: false,
        viewMemberMeetingFailureData: null,
      };
    case VIEW_MEMBER_MEETING_FAILURE:
      return {
        ...state,
        viewMemberMeetingBegin: false,
        viewMemberMeetingSuccess: false,
        viewMemberMeetingSuccessData: null,
        viewMemberMeetingFailure: true,
        viewMemberMeetingFailureData: data,
      };
    case RESET_VIEW_MEMBER_MEETING:
      return {
        ...state,
        viewMemberMeetingBegin: false,
        viewMemberMeetingSuccess: false,
        viewMemberMeetingSuccessData: null,
        viewMemberMeetingFailure: false,
        viewMemberMeetingFailureData: null,
      };

    //cancel member meeting
    case CANCEL_MEMBER_MEETING_BEGIN:
      return {
        ...state,
        cancelMemberMeetingBegin: true,
        cancelMemberMeetingSuccess: false,
        cancelMemberMeetingSuccessData: null,
        cancelMemberMeetingFailure: false,
        cancelMemberMeetingFailureData: null,
      };
    case CANCEL_MEMBER_MEETING_SUCCESS:
      return {
        ...state,
        cancelMemberMeetingBegin: false,
        cancelMemberMeetingSuccess: true,
        cancelMemberMeetingSuccessData: data,
        cancelMemberMeetingFailure: false,
        cancelMemberMeetingFailureData: null,
      };
    case CANCEL_MEMBER_MEETING_FAILURE:
      return {
        ...state,
        cancelMemberMeetingBegin: false,
        cancelMemberMeetingSuccess: false,
        cancelMemberMeetingSuccessData: null,
        cancelMemberMeetingFailure: true,
        cancelMemberMeetingFailureData: data,
      };
    case RESET_CANCEL_MEMBER_MEETING:
      return {
        ...state,
        cancelMemberMeetingBegin: false,
        cancelMemberMeetingSuccess: false,
        cancelMemberMeetingSuccessData: null,
        cancelMemberMeetingFailure: false,
        cancelMemberMeetingFailureData: null,
      };

    //update member meeting status
    case UPDATE_MEMBER_MEETING_STATUS_BEGIN:
      return {
        ...state,
        updateMemberMeetingStatusBegin: true,
        updateMemberMeetingStatusSuccess: false,
        updateMemberMeetingStatusSuccessData: null,
        updateMemberMeetingStatusFailure: false,
        updateMemberMeetingStatusFailureData: null,
      };
    case UPDATE_MEMBER_MEETING_STATUS_SUCCESS:
      return {
        ...state,
        updateMemberMeetingStatusBegin: false,
        updateMemberMeetingStatusSuccess: true,
        updateMemberMeetingStatusSuccessData: data,
        updateMemberMeetingStatusFailure: false,
        updateMemberMeetingStatusFailureData: null,
      };
    case UPDATE_MEMBER_MEETING_STATUS_FAILURE:
      return {
        ...state,
        updateMemberMeetingStatusBegin: false,
        updateMemberMeetingStatusSuccess: false,
        updateMemberMeetingStatusSuccessData: null,
        updateMemberMeetingStatusFailure: true,
        updateMemberMeetingStatusFailureData: data,
      };
    case RESET_UPDATE_MEMBER_MEETING_STATUS:
      return {
        ...state,
        updateMemberMeetingStatusBegin: false,
        updateMemberMeetingStatusSuccess: false,
        updateMemberMeetingStatusSuccessData: null,
        updateMemberMeetingStatusFailure: false,
        updateMemberMeetingStatusFailureData: null,
      };

    default:
      return state;
  }
};
