import { Helmet } from "react-helmet-async";

import SeoData from "./seoData.json";
import { useLayoutEffect, useState } from "react";

export const ReactHelmet = ({ data = SeoData.homePageSeoData }) => {
  const { title, description }            = data;
  const [canonicalLink, setCanonicalLink] = useState(null);

  useLayoutEffect(() => {
    setCanonicalLink(window.location.protocol + '//' + window.location.host + window.location.pathname);
  }, []);

  return (
    <Helmet defaultTitle="Merity">
      <html lang="en" amp />

      <title>{title}</title>

      {/* Dummy content */}
      <meta charSet="utf-8" />
      <meta property="title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:url" content="Merity" />
      <meta property="og:site_name" content="" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="fb:app_id" content="ID_APP_FACEBOOK" />
      
      <link rel="canonical" href={canonicalLink ? canonicalLink : 'https://mymerity.com'} />
    </Helmet>
  );
};
