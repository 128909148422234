import { Link } from "react-router-dom";

import NewsLetterSubscriptionForm from "./NewsLetterSubscriptionForm";
import { socialMedias, CustomTooltip, SunFooter } from "../../utilities";
import Data from "../../utilities/content/navbarAndFooter.json";
import { FooterLogo, HalfGreenSun } from "../../utilities/svg";
const { heading, paragraph, allRightsContent, foooterLinks } =
  Data.footerData;

const Footer = () => {
  return (
    <footer>
      {/* Blue Section */}
      <div className="bg-brandBlue text-white pt-4 500px:pt-8 lg:pt-12 text-center px-2">
        <h2 className="font-thin text-2xl sm:text-5xl lg:text-64px mb-5">
          {heading}
        </h2>
        <p className="paragraphSize font-medium w-11/12 lg:w-2/3 mx-auto ">
          {paragraph}
        </p>
        <div
          className="h-px w-11/12 lg:w-3/4 my-3 mx-auto"
          style={{ backgroundColor: "#ffffff0f" }}
        />
        {/* <p className="font-medium text-xl mb-3">{subscribeMessage}</p> */}
        <NewsLetterSubscriptionForm />
        <HalfGreenSun className="mt-4 500px:mt-8 mx-auto" />
      </div>

      {/* White section */}
      <div className="homepageWidth grid grid-cols-1 sm:grid-cols-2 950px:grid-cols-3 gap-4 py-6">
        <div>
          <Link to="/">
            <FooterLogo className="w-1/2" />
          </Link>
          <p className="font-normal text-base" style={{ color: "#818181" }}>
            {allRightsContent}
          </p>
        </div>

        <div
          className="flex justify-around sm:justify-between w-11/12 lg:w-4/5 xl:w-3/4 ml-auto my-auto font-medium text-base"
          style={{ color: "#292929" }}
        >
          <LinksRow start="0" end="2" />
          <LinksRow start="2" end="4" />
        </div>

        <div className=" w-3/4 lg:w-2/3 mx-auto sm:mx-0 sm:ml-auto flex justify-around sm:justify-between items-center transform sm:translate-x-1/2 950px:translate-x-0">
          {socialMedias.map(({ FooterIcon, link, Thumbnail }, index) => (
            <CustomTooltip
              key={index}
              content={Thumbnail}
              direction="top"
              color="black"
            >
              <a href={link} target="_blank" key={index} rel="noreferrer">
                <FooterIcon className="activeState fill-current blueText trasition75 duration-150 w-11/12 lg:w-full" />
              </a>
            </CustomTooltip>
          ))}
        </div>
      </div>
      <SunFooter />
    </footer>
  );
};

export default Footer;

const LinksRow = ({ start, end }) => (
  <div>
    {foooterLinks?.slice(start, end).map(({ name, link }, index) => (
      <div key={index} className="mb-4 activeState">
        <Link className="buttonHover text-brandDarkBlue hover:text-brandYellow" to={link}>
          {name}
        </Link>
      </div>
    ))}
  </div>
);
