import {
  GET_COMMUNITY_LIST_BEGIN,
  GET_COMMUNITY_LIST_SUCCESS,
  GET_COMMUNITY_LIST_FAILURE,
  RESET_GET_COMMUNITY_LIST,
  GENERATE_ZOOM_LINK_BEGIN,
  GENERATE_ZOOM_LINK_SUCCESS,
  GENERATE_ZOOM_LINK_FAILURE,
  RESET_GENERATE_ZOOM_LINK,
  CREATE_COMMUNITY_SESSION_BEGIN,
  CREATE_COMMUNITY_SESSION_SUCCESS,
  CREATE_COMMUNITY_SESSION_FAILURE,
  RESET_CREATE_COMMUNITY_SESSION,
  RESCHEDULE_COMMUNITY_SESSION_BEGIN,
  RESCHEDULE_COMMUNITY_SESSION_SUCCESS,
  RESCHEDULE_COMMUNITY_SESSION_FAILURE,
  RESET_RESCHEDULE_COMMUNITY_SESSION,
  GET_SINGLE_COMMUNITY_BEGIN,
  GET_SINGLE_COMMUNITY_SUCCESS,
  GET_SINGLE_COMMUNITY_FAILURE,
  RESET_GET_SINGLE_COMMUNITY,
  DELETE_COMMUNITY_BEGIN,
  DELETE_COMMUNITY_SUCCESS,
  DELETE_COMMUNITY_FAILURE,
  RESET_DELETE_COMMUNITY,
} from "../constants/AdminCommunity";

const initialState = {
  getCommunityListBegin: false,
  getCommunityListSuccess: false,
  getCommunityListSuccessData: null,
  getCommunityListFailure: false,
  getCommunityListFailureDate: null,

  generateZoomLinkBegin: false,
  generateZoomLinkSuccess: false,
  generateZoomLinkSuccessData: null,
  generateZoomLinkFailure: false,
  generateZoomLinkFailureData: null,

  createCommunitySessionBegin: false,
  createCommunitySessionSuccess: false,
  createCommunitySessionSuccessData: null,
  createCommunitySessionFailure: false,
  createCommunitySessionFailureData: null,

  rescheduleCommunitySessionBegin: false,
  rescheduleCommunitySessionSuccess: false,
  rescheduleCommunitySessionSuccessData: null,
  rescheduleCommunitySessionFailure: false,
  rescheduleCommunitySessionFailureData: null,

  getSingleCommunityDetailsBegin: false,
  getSingleCommunityDetailsSuccess: false,
  getSingleCommunityDetailsSuccessData: null,
  getSingleCommunityDetailsFailure: false,
  getSingleCommunityDetailsFailureData: null,

  deleteCommunityBegin: false,
  deleteCommunitySuccess: false,
  deleteCommunitySuccessData: null,
  deleteCommunityFailure: false,
  deleteCommunityFailureData: null,
};

export const adminCommunity = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    //get community listing
    case GET_COMMUNITY_LIST_BEGIN:
      return {
        ...state,
        getCommunityListBegin: true,
        getCommunityListSuccess: false,
        getCommunityListSuccessData: null,
        getCommunityListFailure: false,
        getCommunityListFailureDate: null,
      };

    case GET_COMMUNITY_LIST_SUCCESS:
      return {
        ...state,
        getCommunityListBegin: false,
        getCommunityListSuccess: true,
        getCommunityListSuccessData: data,
        getCommunityListFailure: false,
        getCommunityListFailureDate: null,
      };

    case GET_COMMUNITY_LIST_FAILURE:
      return {
        ...state,
        getCommunityListBegin: false,
        getCommunityListSuccess: false,
        getCommunityListSuccessData: null,
        getCommunityListFailure: true,
        getCommunityListFailureDate: data,
      };

    case RESET_GET_COMMUNITY_LIST:
      return {
        ...state,
        getCommunityListBegin: false,
        getCommunityListSuccess: false,
        getCommunityListSuccessData: null,
        getCommunityListFailure: false,
        getCommunityListFailureDate: null,
      };

    //generate zoom link

    case GENERATE_ZOOM_LINK_BEGIN:
      return {
        ...state,
        generateZoomLinkBegin: true,
        generateZoomLinkSuccess: false,
        generateZoomLinkSuccessData: null,
        generateZoomLinkFailure: false,
        generateZoomLinkFailureData: null,
      };

    case GENERATE_ZOOM_LINK_SUCCESS:
      return {
        ...state,
        generateZoomLinkBegin: false,
        generateZoomLinkSuccess: true,
        generateZoomLinkSuccessData: data,
        generateZoomLinkFailure: false,
        generateZoomLinkFailureData: null,
      };

    case GENERATE_ZOOM_LINK_FAILURE:
      return {
        ...state,
        generateZoomLinkBegin: false,
        generateZoomLinkSuccess: false,
        generateZoomLinkSuccessData: null,
        generateZoomLinkFailure: true,
        generateZoomLinkFailureData: data,
      };

    case RESET_GENERATE_ZOOM_LINK:
      return {
        ...state,
        generateZoomLinkBegin: false,
        generateZoomLinkSuccess: false,
        generateZoomLinkSuccessData: null,
        generateZoomLinkFailure: false,
        generateZoomLinkFailureData: null,
      };

    //create community session

    case CREATE_COMMUNITY_SESSION_BEGIN:
      return {
        ...state,
        createCommunitySessionBegin: true,
        createCommunitySessionSuccess: false,
        createCommunitySessionSuccessData: null,
        createCommunitySessionFailure: false,
        createCommunitySessionFailureData: null,
      };

    case CREATE_COMMUNITY_SESSION_SUCCESS:
      return {
        ...state,
        createCommunitySessionBegin: false,
        createCommunitySessionSuccess: true,
        createCommunitySessionSuccessData: data,
        createCommunitySessionFailure: false,
        createCommunitySessionFailureData: null,
      };

    case CREATE_COMMUNITY_SESSION_FAILURE:
      return {
        ...state,
        createCommunitySessionBegin: false,
        createCommunitySessionSuccess: false,
        createCommunitySessionSuccessData: null,
        createCommunitySessionFailure: true,
        createCommunitySessionFailureData: data,
      };

    case RESET_CREATE_COMMUNITY_SESSION:
      return {
        ...state,
        createCommunitySessionBegin: false,
        createCommunitySessionSuccess: false,
        createCommunitySessionSuccessData: null,
        createCommunitySessionFailure: false,
        createCommunitySessionFailureData: null,
      };

    //reschedule community session

    case RESCHEDULE_COMMUNITY_SESSION_BEGIN:
      return {
        ...state,
        rescheduleCommunitySessionBegin: true,
        rescheduleCommunitySessionSuccess: false,
        rescheduleCommunitySessionSuccessData: null,
        rescheduleCommunitySessionFailure: false,
        rescheduleCommunitySessionFailureData: null,
      };

    case RESCHEDULE_COMMUNITY_SESSION_SUCCESS:
      return {
        ...state,
        rescheduleCommunitySessionBegin: false,
        rescheduleCommunitySessionSuccess: true,
        rescheduleCommunitySessionSuccessData: data,
        rescheduleCommunitySessionFailure: false,
        rescheduleCommunitySessionFailureData: null,
      };

    case RESCHEDULE_COMMUNITY_SESSION_FAILURE:
      return {
        ...state,
        rescheduleCommunitySessionBegin: false,
        rescheduleCommunitySessionSuccess: false,
        rescheduleCommunitySessionSuccessData: null,
        rescheduleCommunitySessionFailure: true,
        rescheduleCommunitySessionFailureData: data,
      };

    case RESET_RESCHEDULE_COMMUNITY_SESSION:
      return {
        ...state,
        rescheduleCommunitySessionBegin: false,
        rescheduleCommunitySessionSuccess: false,
        rescheduleCommunitySessionSuccessData: null,
        rescheduleCommunitySessionFailure: false,
        rescheduleCommunitySessionFailureData: null,
      };

    //get community details by id

    case GET_SINGLE_COMMUNITY_BEGIN:
      return {
        ...state,
        getSingleCommunityDetailsBegin: true,
        getSingleCommunityDetailsSuccess: false,
        getSingleCommunityDetailsSuccessData: null,
        getSingleCommunityDetailsFailure: false,
        getSingleCommunityDetailsFailureData: null,
      };

    case GET_SINGLE_COMMUNITY_SUCCESS:
      return {
        ...state,
        getSingleCommunityDetailsBegin: false,
        getSingleCommunityDetailsSuccess: true,
        getSingleCommunityDetailsSuccessData: data,
        getSingleCommunityDetailsFailure: false,
        getSingleCommunityDetailsFailureData: null,
      };

    case GET_SINGLE_COMMUNITY_FAILURE:
      return {
        ...state,
        getSingleCommunityDetailsBegin: false,
        getSingleCommunityDetailsSuccess: false,
        getSingleCommunityDetailsSuccessData: null,
        getSingleCommunityDetailsFailure: true,
        getSingleCommunityDetailsFailureData: data,
      };

    case RESET_GET_SINGLE_COMMUNITY:
      return {
        ...state,
        getSingleCommunityDetailsBegin: false,
        getSingleCommunityDetailsSuccess: false,
        getSingleCommunityDetailsSuccessData: null,
        getSingleCommunityDetailsFailure: false,
        getSingleCommunityDetailsFailureData: null,
      };
    //delete Community from list
    case DELETE_COMMUNITY_BEGIN:
      return {
        ...state,
        deleteCommunityBegin: true,
        deleteCommunitySuccess: false,
        deleteCommunitySuccessData: null,
        deleteCommunityFailure: false,
        deleteCommunityFailureData: null,
      };
    case DELETE_COMMUNITY_SUCCESS:
      return {
        ...state,
        deleteCommunityBegin: false,
        deleteCommunitySuccess: true,
        deleteCommunitySuccessData: data,
        deleteCommunityFailure: false,
        deleteCommunityFailureData: null,
      };
    case DELETE_COMMUNITY_FAILURE:
      return {
        ...state,
        deleteCommunityBegin: false,
        deleteCommunitySuccess: false,
        deleteCommunitySuccessData: null,
        deleteCommunityFailure: true,
        deleteCommunityFailureData: data,
      };
    case RESET_DELETE_COMMUNITY:
      return {
        ...state,
        deleteCommunityBegin: false,
        deleteCommunitySuccess: false,
        deleteCommunitySuccessData: null,
        deleteCommunityFailure: false,
        deleteCommunityFailureData: null,
      };

    default:
      return state;
  }
};
