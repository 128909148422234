import {
  GET_MEMBERS_LIST_BEGIN,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST_FAILURE,
  RESET_GET_MEMBERS_LIST,
  GET_SINGLE_MEMBER_DETAILS_BEGIN,
  GET_SINGLE_MEMBER_DETAILS_SUCCESS,
  GET_SINGLE_MEMBER_DETAILS_FAILURE,
  RESET_GET_SINGLE_MEMBER_DETAILS,
  GET_MEMBER_PROFILE_DETAILS_BEGIN,
  GET_MEMBER_PROFILE_DETAILS_SUCCESS,
  GET_MEMBER_PROFILE_DETAILS_FAILURE,
  RESET_GET_MEMBER_PROFILE_DETAILS,
  GET_SINGLE_MEMBER_PAYMENT_DETAILS_BEGIN,
  GET_SINGLE_MEMBER_PAYMENT_DETAILS_SUCCESS,
  GET_SINGLE_MEMBER_PAYMENT_DETAILS_FAILURE,
  RESET_GET_SINGLE_MEMBER_PAYMENT_DETAILS,
  SEND_MEMBER_ALERT_BEGIN,
  SEND_MEMBER_ALERT_SUCCESS,
  SEND_MEMBER_ALERT_FAILURE,
  RESET_SEND_MEMBER_ALERT,
  BLOCK_MEMBER_BEGIN,
  BLOCK_MEMBER_SUCCESS,
  BLOCK_MEMBER_FAILURE,
  RESET_BLOCK_MEMBER,
  UNBLOCK_MEMBER_BEGIN,
  UNBLOCK_MEMBER_SUCCESS,
  UNBLOCK_MEMBER_FAILURE,
  RESET_UNBLOCK_MEMBER,
  ASSIGN_MENTOR_BEGIN,
  ASSIGN_MENTOR_SUCCESS,
  ASSIGN_MENTOR_FAILURE,
  RESET_ASSIGN_MENTOR,
  DELETE_MEMBER_BYID_BEGIN,
  DELETE_MEMBER_BYID_SUCCESS,
  DELETE_MEMBER_BYID_FAILURE,
  RESET_DELETE_MEMBER_BYID,
} from "../constants/AdminMember";

const initialState = {
  getMembersListBegin: false,
  getMembersListSuccess: false,
  getMembersListSuccessData: null,
  getMembersListFailure: false,
  getMembersListFailureData: null,

  getSingleMemberDetailsBegin: false,
  getSingleMemberDetailsSuccess: false,
  getSingleMemberDetailsSuccessData: null,
  getSingleMemberDetailsFailure: false,
  getSingleMemberDetailsFailureData: null,

  getMemberProfileDataBegin: false,
  getMemberProfileDataSuccess: false,
  getMemberProfileDataSuccessData: null,
  getMemberProfileDataFailure: false,
  getMemberProfileDataFailureData: null,

  getSingleMemberPaymentDetailsBegin: false,
  getSingleMemberPaymentDetailsSuccess: false,
  getSingleMemberPaymentDetailsSuccessData: null,
  getSingleMemberPaymentDetailsFailure: false,
  getSingleMemberPaymentDetailsFailureData: null,

  sendMemberAlertBegin: false,
  sendMemberAlertSuccess: false,
  sendMemberAlertSuccessData: null,
  sendMemberAlertFailure: false,
  sendMemberAlertFailureData: null,

  blockMemberBegin: false,
  blockMemberSuccess: false,
  blockMemberSuccessData: null,
  blockMemberFailure: false,
  blockMemberFailureData: null,

  unblockMemberBegin: false,
  unblockMemberSuccess: false,
  unblockMemberSuccessData: null,
  unblockMemberFailure: false,
  unblockMemberFailureData: null,

  assignMentorBegin: false,
  assignMentorSuccess: false,
  assignMentorSuccessData: null,
  assignMentorFailure: false,
  assignMentorFailureData: null,

  deleteMemberByIdBegin: false,
  deleteMemberByIdSuccess: false,
  deleteMemberByIdSuccessData: null,
  deleteMemberByIdFailure: false,
  deleteMemberByIdFailureData: null,
};

export const adminMember = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // get member list
    case GET_MEMBERS_LIST_BEGIN:
      return {
        ...state,
        getMembersListBegin: true,
        getMembersListSuccess: false,
        getMembersListSuccessData: null,
        getMembersListFailure: false,
        getMembersListFailureData: null,
      };

    case GET_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        getMembersListBegin: false,
        getMembersListSuccess: true,
        getMembersListSuccessData: data,
        getMembersListFailure: false,
        getMembersListFailureData: null,
      };
    case GET_MEMBERS_LIST_FAILURE:
      return {
        ...state,
        getMembersListBegin: false,
        getMembersListSuccess: false,
        getMembersListSuccessData: null,
        getMembersListFailure: true,
        getMembersListFailureData: data,
      };
    case RESET_GET_MEMBERS_LIST:
      return {
        ...state,
        getMembersListBegin: false,
        getMembersListSuccess: false,
        getMembersListSuccessData: null,
        getMembersListFailure: false,
        getMembersListFailureData: null,
      };

    // get single member details

    case GET_SINGLE_MEMBER_DETAILS_BEGIN:
      return {
        ...state,
        getSingleMemberDetailsBegin: true,
        getSingleMemberDetailsSuccess: false,
        getSingleMemberDetailsSuccessData: null,
        getSingleMemberDetailsFailure: false,
        getSingleMemberDetailsFailureData: null,
      };

    case GET_SINGLE_MEMBER_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleMemberDetailsBegin: false,
        getSingleMemberDetailsSuccess: true,
        getSingleMemberDetailsSuccessData: data,
        getSingleMemberDetailsFailure: false,
        getSingleMemberDetailsFailureData: null,
      };

    case GET_SINGLE_MEMBER_DETAILS_FAILURE:
      return {
        ...state,
        getSingleMemberDetailsBegin: false,
        getSingleMemberDetailsSuccess: false,
        getSingleMemberDetailsSuccessData: null,
        getSingleMemberDetailsFailure: true,
        getSingleMemberDetailsFailureData: data,
      };

    case RESET_GET_SINGLE_MEMBER_DETAILS:
      return {
        ...state,
        getSingleMemberDetailsBegin: false,
        getSingleMemberDetailsSuccess: false,
        getSingleMemberDetailsSuccessData: null,
        getSingleMemberDetailsFailure: false,
        getSingleMemberDetailsFailureData: null,
      };

    // get single member profile details

    case GET_MEMBER_PROFILE_DETAILS_BEGIN:
      return {
        ...state,
        getMemberProfileDataBegin: true,
        getMemberProfileDataSuccess: false,
        getMemberProfileDataSuccessData: null,
        getMemberProfileDataFailure: false,
        getMemberProfileDataFailureData: null,
      };

    case GET_MEMBER_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        getMemberProfileDataBegin: false,
        getMemberProfileDataSuccess: true,
        getMemberProfileDataSuccessData: data,
        getMemberProfileDataFailure: false,
        getMemberProfileDataFailureData: null,
      };

    case GET_MEMBER_PROFILE_DETAILS_FAILURE:
      return {
        ...state,
        getMemberProfileDataBegin: false,
        getMemberProfileDataSuccess: false,
        getMemberProfileDataSuccessData: null,
        getMemberProfileDataFailure: true,
        getMemberProfileDataFailureData: data,
      };

    case RESET_GET_MEMBER_PROFILE_DETAILS:
      return {
        ...state,
        getMemberProfileDataBegin: false,
        getMemberProfileDataSuccess: false,
        getMemberProfileDataSuccessData: null,
        getMemberProfileDataFailure: false,
        getMemberProfileDataFailureData: null,
      };

    //get single member payment details

    case GET_SINGLE_MEMBER_PAYMENT_DETAILS_BEGIN:
      return {
        ...state,
        getSingleMemberPaymentDetailsBegin: true,
        getSingleMemberPaymentDetailsSuccess: false,
        getSingleMemberPaymentDetailsSuccessData: null,
        getSingleMemberPaymentDetailsFailure: false,
        getSingleMemberPaymentDetailsFailureData: null,
      };

    case GET_SINGLE_MEMBER_PAYMENT_DETAILS_SUCCESS:
      return {
        ...state,
        getSingleMemberPaymentDetailsBegin: false,
        getSingleMemberPaymentDetailsSuccess: true,
        getSingleMemberPaymentDetailsSuccessData: data,
        getSingleMemberPaymentDetailsFailure: false,
        getSingleMemberPaymentDetailsFailureData: null,
      };

    case GET_SINGLE_MEMBER_PAYMENT_DETAILS_FAILURE:
      return {
        ...state,
        getSingleMemberPaymentDetailsBegin: false,
        getSingleMemberPaymentDetailsSuccess: false,
        getSingleMemberPaymentDetailsSuccessData: null,
        getSingleMemberPaymentDetailsFailure: true,
        getSingleMemberPaymentDetailsFailureData: data,
      };

    case RESET_GET_SINGLE_MEMBER_PAYMENT_DETAILS:
      return {
        ...state,
        getSingleMemberPaymentDetailsBegin: false,
        getSingleMemberPaymentDetailsSuccess: false,
        getSingleMemberPaymentDetailsSuccessData: null,
        getSingleMemberPaymentDetailsFailure: false,
        getSingleMemberPaymentDetailsFailureData: null,
      };

    //send member alert
    case SEND_MEMBER_ALERT_BEGIN:
      return {
        ...state,
        sendMemberAlertBegin: true,
        sendMemberAlertSuccess: false,
        sendMemberAlertSuccessData: null,
        sendMemberAlertFailure: false,
        sendMemberAlertFailureData: null,
      };

    case SEND_MEMBER_ALERT_SUCCESS:
      return {
        ...state,
        sendMemberAlertBegin: false,
        sendMemberAlertSuccess: true,
        sendMemberAlertSuccessData: data,
        sendMemberAlertFailure: false,
        sendMemberAlertFailureData: null,
      };

    case SEND_MEMBER_ALERT_FAILURE:
      return {
        ...state,
        sendMemberAlertBegin: false,
        sendMemberAlertSuccess: false,
        sendMemberAlertSuccessData: null,
        sendMemberAlertFailure: true,
        sendMemberAlertFailureData: data,
      };

    case RESET_SEND_MEMBER_ALERT:
      return {
        ...state,
        sendMemberAlertBegin: false,
        sendMemberAlertSuccess: false,
        sendMemberAlertSuccessData: null,
        sendMemberAlertFailure: false,
        sendMemberAlertFailureData: null,
      };

    //block member

    case BLOCK_MEMBER_BEGIN:
      return {
        ...state,
        blockMemberBegin: true,
        blockMemberSuccess: false,
        blockMemberSuccessData: null,
        blockMemberFailure: false,
        blockMemberFailureData: null,
      };

    case BLOCK_MEMBER_SUCCESS:
      return {
        ...state,
        blockMemberBegin: false,
        blockMemberSuccess: true,
        blockMemberSuccessData: data,
        blockMemberFailure: false,
        blockMemberFailureData: null,
      };

    case BLOCK_MEMBER_FAILURE:
      return {
        ...state,
        blockMemberBegin: false,
        blockMemberSuccess: false,
        blockMemberSuccessData: null,
        blockMemberFailure: true,
        blockMemberFailureData: data,
      };

    case RESET_BLOCK_MEMBER:
      return {
        ...state,
        blockMemberBegin: false,
        blockMemberSuccess: false,
        blockMemberSuccessData: null,
        blockMemberFailure: false,
        blockMemberFailureData: null,
      };

    //unblock member

    case UNBLOCK_MEMBER_BEGIN:
      return {
        ...state,
        unblockMemberBegin: true,
        unblockMemberSuccess: false,
        unblockMemberSuccessData: null,
        unblockMemberFailure: false,
        unblockMemberFailureData: null,
      };

    case UNBLOCK_MEMBER_SUCCESS:
      return {
        ...state,
        unblockMemberBegin: false,
        unblockMemberSuccess: true,
        unblockMemberSuccessData: data,
        unblockMemberFailure: false,
        unblockMemberFailureData: null,
      };

    case UNBLOCK_MEMBER_FAILURE:
      return {
        ...state,
        unblockMemberBegin: false,
        unblockMemberSuccess: false,
        unblockMemberSuccessData: null,
        unblockMemberFailure: true,
        unblockMemberFailureData: data,
      };

    case RESET_UNBLOCK_MEMBER:
      return {
        ...state,
        unblockMemberBegin: false,
        unblockMemberSuccess: false,
        unblockMemberSuccessData: null,
        unblockMemberFailure: false,
        unblockMemberFailureData: null,
      };

    // manual mentor assign

    case ASSIGN_MENTOR_BEGIN:
      return {
        ...state,
        assignMentorBegin: true,
        assignMentorSuccess: false,
        assignMentorSuccessData: null,
        assignMentorFailure: false,
        assignMentorFailureData: null,
      };

    case ASSIGN_MENTOR_SUCCESS:
      return {
        ...state,
        assignMentorBegin: false,
        assignMentorSuccess: true,
        assignMentorSuccessData: data,
        assignMentorFailure: false,
        assignMentorFailureData: null,
      };

    case ASSIGN_MENTOR_FAILURE:
      return {
        ...state,
        assignMentorBegin: false,
        assignMentorSuccess: false,
        assignMentorSuccessData: null,
        assignMentorFailure: true,
        assignMentorFailureData: data,
      };

    case RESET_ASSIGN_MENTOR:
      return {
        ...state,
        assignMentorBegin: false,
        assignMentorSuccess: false,
        assignMentorSuccessData: null,
        assignMentorFailure: false,
        assignMentorFailureData: null,
      };

    case DELETE_MEMBER_BYID_BEGIN:
      return {
        ...state,
        deleteMemberByIdBegin: true,
        deleteMemberByIdSuccess: false,
        deleteMemberByIdSuccessData: null,
        deleteMemberByIdFailure: false,
        deleteMemberByIdFailureData: null,
      };

    case DELETE_MEMBER_BYID_SUCCESS:
      return {
        ...state,
        deleteMemberByIdBegin: false,
        deleteMemberByIdSuccess: true,
        deleteMemberByIdSuccessData: data,
        deleteMemberByIdFailure: false,
        deleteMemberByIdFailureData: null,
      };
    case DELETE_MEMBER_BYID_FAILURE:
      return {
        ...state,
        deleteMemberByIdBegin: false,
        deleteMemberByIdSuccess: false,
        deleteMemberByIdSuccessData: null,
        deleteMemberByIdFailure: true,
        deleteMemberByIdFailureData: data,
      };
    case RESET_DELETE_MEMBER_BYID:
      return {
        ...state,
        deleteMemberByIdBegin: false,
        deleteMemberByIdSuccess: false,
        deleteMemberByIdSuccessData: null,
        deleteMemberByIdFailure: false,
        deleteMemberByIdFailureData: null,
      };

    default:
      return state;
  }
};
