import {
  GET_MEMBER_PROFILE_BEGIN,
  GET_MEMBER_PROFILE_SUCCESS,
  GET_MEMBER_PROFILE_FAILURE,
  RESET_GET_MEMBER_PROFILE,
  GET_ASSESSMENT_QUESTIONS_BEGIN,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_FAILURE,
  RESET_GET_ASSESSMENT_QUESTIONS,
  SUBMIT_SINGLE_ASSESSMENT_BEGIN,
  SUBMIT_SINGLE_ASSESSMENT_SUCCESS,
  SUBMIT_SINGLE_ASSESSMENT_FAILURE,
  RESET_SUBMIT_SINGLE_ASSESSMENT,
  VIEW_SUBMITTED_ASSESSMENT_OPTIONS_BEGIN,
  VIEW_SUBMITTED_ASSESSMENT_OPTIONS_SUCCESS,
  VIEW_SUBMITTED_ASSESSMENT_OPTIONS_FAILURE,
  RESET_VIEW_SUBMITTED_ASSESSMENT_OPTIONS,
  GET_MEMBER_CURRICULUM_BEGIN,
  GET_MEMBER_CURRICULUM_SUCCESS,
  GET_MEMBER_CURRICULUM_FAILURE,
  RESET_GET_MEMBER_CURRICULUM,
  VIEW_MEMBER_CURRICULUM_RESPONSE_BEGIN,
  VIEW_MEMBER_CURRICULUM_RESPONSE_SUCCESS,
  VIEW_MEMBER_CURRICULUM_RESPONSE_FAILURE,
  RESET_VIEW_MEMBER_CURRICULUM_RESPONSE,
  SUBMIT_MEMBER_CURRICULUM_RESPONSE_BEGIN,
  SUBMIT_MEMBER_CURRICULUM_RESPONSE_SUCCESS,
  SUBMIT_MEMBER_CURRICULUM_RESPONSE_FAILURE,
  RESET_SUBMIT_MEMBER_CURRICULUM_RESPONSE,
  PURCHASE_PAYMENT_MINUTES_BEGIN,
  PURCHASE_PAYMENT_MINUTES_SUCCESS,
  PURCHASE_PAYMENT_MINUTES_FAILURE,
  RESET_PURCHASE_PAYMENT_MINUTES,
  GET_MEMBER_DASHBOARD_DETAILS_BEGIN,
  GET_MEMBER_DASHBOARD_DETAILS_SUCCESS,
  GET_MEMBER_DASHBOARD_DETAILS_FAILURE,
  RESET_GET_MEMBER_DASHBOARD_DETAILS,
  ASSIGN_MENTOR_TO_MEMBER_BEGIN,
  ASSIGN_MENTOR_TO_MEMBER_SUCCESS,
  ASSIGN_MENTOR_TO_MEMBER_FAILURE,
  RESET_ASSIGN_MENTOR_TO_MEMBER,
  GET_MEMBER_NOTIFICATIONS_BEGIN,
  GET_MEMBER_NOTIFICATIONS_SUCCESS,
  GET_MEMBER_NOTIFICATIONS_FAILURE,
  RESET_GET_MEMBER_NOTIFICATIONS,
  UNREAD_ALL_MEMBER_NOTIFICATIONS_BEGIN,
  UNREAD_ALL_MEMBER_NOTIFICATIONS_SUCCESS,
  UNREAD_ALL_MEMBER_NOTIFICATIONS_FAILURE,
  RESET_UNREAD_ALL_MEMBER_NOTIFICATIONS,
  GET_MY_MENTOR_DETAILS_BEGIN,
  GET_MY_MENTOR_DETAILS_SUCCESS,
  GET_MY_MENTOR_DETAILS_FAILURE,
  RESET_GET_MY_MENTOR_DETAILS,
  GET_PURCHASE_MINUTES_PLAN_BEGIN,
  GET_PURCHASE_MINUTES_PLAN_SUCCESS,
  GET_PURCHASE_MINUTES_PLAN_FAILURE,
  RESET_GET_PURCHASE_MINUTES_PLAN,
  GET_MEMBER_TASK_BEGIN,
  GET_MEMBER_TASK_SUCCESS,
  GET_MEMBER_TASK_FAILURE,
  RESET_GET_MEMBER_TASK,
  VIEW_MEMBER_TASK_RESPONSE_BEGIN,
  VIEW_MEMBER_TASK_RESPONSE_SUCCESS,
  VIEW_MEMBER_TASK_RESPONSE_FAILURE,
  RESET_VIEW_MEMBER_TASK_RESPONSE,
  SUBMIT_MEMBER_TASK_RESPONSE_BEGIN,
  SUBMIT_MEMBER_TASK_RESPONSE_SUCCESS,
  SUBMIT_MEMBER_TASK_RESPONSE_FAILURE,
  RESET_SUBMIT_MEMBER_TASK_RESPONSE,
  GET_MEMBER_JOURNAL_BEGIN,
  GET_MEMBER_JOURNAL_SUCCESS,
  GET_MEMBER_JOURNAL_FAILURE,
  RESET_GET_MEMBER_JOURNAL,
  SUBMIT_MEMBER_JOURNAL_BEGIN,
  SUBMIT_MEMBER_JOURNAL_SUCCESS,
  RESET_SUBMIT_MEMBER_JOURNAL,
  SUBMIT_MEMBER_JOURNAL_FAILURE,
  VIEW_SINGLE_MEMBER_JOURNAL_BEGIN,
  VIEW_SINGLE_MEMBER_JOURNAL_SUCCESS,
  VIEW_SINGLE_MEMBER_JOURNAL_FAILURE,
  RESET_VIEW_SINGLE_MEMBER_JOURNAL,
  SUBMIT_MENTOR_RATING_BEGIN,
  SUBMIT_MENTOR_RATING_SUCCESS,
  SUBMIT_MENTOR_RATING_FAILURE,
  RESET_SUBMIT_MENTOR_RATING,
  SUBMIT_SINGLE_QUESTION_ANSWER_BEGIN,
  SUBMIT_SINGLE_QUESTION_ANSWER_SUCCESS,
  SUBMIT_SINGLE_QUESTION_ANSWER_FAILURE,
  RESET_SUBMIT_SINGLE_QUESTION_ANSWER,
  SUBMIT_BULK_ANSWERS_BEGIN,
  SUBMIT_BULK_ANSWERS_SUCCESS,
  SUBMIT_BULK_ANSWERS_FAILURE,
  RESET_SUBMIT_BULK_ANSWERS,
  EDIT_SINGLE_ANSWERS_BEGIN,
  EDIT_SINGLE_ANSWERS_SUCCESS,
  EDIT_SINGLE_ANSWERS_FAILURE,
  RESET_EDIT_SINGLE_ANSWERS,
  SUBMIT_SINGLE_TIF_ANSWER_BEGIN,
  SUBMIT_SINGLE_TIF_ANSWER_SUCCESS,
  SUBMIT_SINGLE_TIF_ANSWER_FAILURE,
  RESET_SUBMIT_SINGLE_TIF_ANSWER,
  EDIT_SINGLE_TIF_BEGIN,
  EDIT_SINGLE_TIF_SUCCESS,
  EDIT_SINGLE_TIF_FAILURE,
  RESET_EDIT_SINGLE_TIF,
  SUBMIT_BULK_TIF_BEGIN,
  SUBMIT_BULK_TIF_SUCCESS,
  SUBMIT_BULK_TIF_FAILURE,
  RESET_SUBMIT_BULK_TIF,
  SEND_WELCOME_EMAIL,
} from "../constants/MemberDashboard";

import { httpApp } from "../../axios/httpUser";

/**
 * api to get member profile
 */
export function getMemberProfile() {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_PROFILE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/member/profile`);



      doRequest.then(
        (res) => {
       
          dispatch({
            type: GET_MEMBER_PROFILE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_PROFILE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_PROFILE_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MEMBER_PROFILE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * api to get assessments
 */
export function getAssessmentQuestions() {
  return (dispatch) => {
    dispatch({
      type: GET_ASSESSMENT_QUESTIONS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/assessments`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_ASSESSMENT_QUESTIONS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_ASSESSMENT_QUESTIONS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_ASSESSMENT_QUESTIONS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_ASSESSMENT_QUESTIONS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * submit assessment
 */
export function submitSingleMemberAssessment(data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_SINGLE_ASSESSMENT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/member/assessments`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_SINGLE_ASSESSMENT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_ASSESSMENT_QUESTIONS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_SINGLE_ASSESSMENT_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_SINGLE_ASSESSMENT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * view submitted assessment options
 */
export function viewSubmittedAssessmentOptions(assesmentId) {
  return (dispatch) => {
    dispatch({
      type: VIEW_SUBMITTED_ASSESSMENT_OPTIONS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`member/assessments/${assesmentId}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_SUBMITTED_ASSESSMENT_OPTIONS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_SUBMITTED_ASSESSMENT_OPTIONS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_SUBMITTED_ASSESSMENT_OPTIONS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_VIEW_SUBMITTED_ASSESSMENT_OPTIONS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * get member curriculum
 */
export function getMemberCurriculum(pageNumber = 1, type = 1, limit = 10) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_CURRICULUM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `member/curriculum?page=${pageNumber}&limit=${limit}&type=${type}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_CURRICULUM_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_CURRICULUM,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_CURRICULUM_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MEMBER_CURRICULUM,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * get member journal
 */
export function getMemberJournal(pageNumber = 1, type = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_JOURNAL_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `prompt/journal?type=${type}&page=${pageNumber}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_JOURNAL_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_JOURNAL,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_JOURNAL_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MEMBER_JOURNAL,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * submit member journal
 */
export function submitMemberJournal(data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MEMBER_JOURNAL_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`prompt/journal`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MEMBER_JOURNAL_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_JOURNAL,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MEMBER_JOURNAL_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_JOURNAL,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * view member journal
 */
export function viewMemberJournal(id) {
  return (dispatch) => {
    dispatch({
      type: VIEW_SINGLE_MEMBER_JOURNAL_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`prompt/journal/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_SINGLE_MEMBER_JOURNAL_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_SINGLE_MEMBER_JOURNAL,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_SINGLE_MEMBER_JOURNAL_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_VIEW_SINGLE_MEMBER_JOURNAL,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * view member curriculum response
 */
export function viewMemberCurriculumResponse(id) {
  return (dispatch) => {
    dispatch({
      type: VIEW_MEMBER_CURRICULUM_RESPONSE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`curriculum/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_MEMBER_CURRICULUM_RESPONSE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_MEMBER_CURRICULUM_RESPONSE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_MEMBER_CURRICULUM_RESPONSE_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_VIEW_MEMBER_CURRICULUM_RESPONSE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * submit member curriculum response
 */
export function submitMemberCurriculumResponse(id, data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MEMBER_CURRICULUM_RESPONSE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`curriculum/${id}/response`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MEMBER_CURRICULUM_RESPONSE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_CURRICULUM_RESPONSE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MEMBER_CURRICULUM_RESPONSE_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_CURRICULUM_RESPONSE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * member purchase minutes
 */
export function memberPurchaseMinutes(data) {
  return (dispatch) => {
    dispatch({
      type: PURCHASE_PAYMENT_MINUTES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post("payment/minutes", data);

      doRequest.then(
        (res) => {
          dispatch({
            type: PURCHASE_PAYMENT_MINUTES_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_PURCHASE_PAYMENT_MINUTES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: PURCHASE_PAYMENT_MINUTES_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_PURCHASE_PAYMENT_MINUTES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * member dashboard details
 */
export function getMemberDashboardDetails() {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_DASHBOARD_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/member/dashboard`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_DASHBOARD_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_DASHBOARD_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_DASHBOARD_DETAILS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MEMBER_DASHBOARD_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * assign a mentor to member
 */
export function assignMentorToMember(User) {
  return (dispatch) => {
    dispatch({
      type: ASSIGN_MENTOR_TO_MEMBER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`member/assign-mentor`, User);

      doRequest.then(
        (res) => {
          dispatch({
            type: ASSIGN_MENTOR_TO_MEMBER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ASSIGN_MENTOR_TO_MEMBER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ASSIGN_MENTOR_TO_MEMBER_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_ASSIGN_MENTOR_TO_MEMBER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * member notification
 */
export function getMemberNotifications(page = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_NOTIFICATIONS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/notification?page=${page}&limit=20`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_NOTIFICATIONS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_NOTIFICATIONS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_NOTIFICATIONS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MEMBER_NOTIFICATIONS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 *unread all member notification
 */
export function unreadAllNotifications() {
  return (dispatch) => {
    dispatch({
      type: UNREAD_ALL_MEMBER_NOTIFICATIONS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/notification/unread`);

      doRequest.then(
        (res) => {
          dispatch({
            type: UNREAD_ALL_MEMBER_NOTIFICATIONS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UNREAD_ALL_MEMBER_NOTIFICATIONS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UNREAD_ALL_MEMBER_NOTIFICATIONS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_UNREAD_ALL_MEMBER_NOTIFICATIONS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * api to get mymentor details
 */
export function getMyMentorDetails() {
  return (dispatch) => {
    dispatch({
      type: GET_MY_MENTOR_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/member/profile/mentor`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MY_MENTOR_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MY_MENTOR_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MY_MENTOR_DETAILS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MY_MENTOR_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * api to get purchase minutes
 */

export function getPurchaseMinutes() {
  return (dispatch) => {
    dispatch({
      type: GET_PURCHASE_MINUTES_PLAN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/resources/minute-plans`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_PURCHASE_MINUTES_PLAN_SUCCESS,
            data: res.data.data,
          });
          dispatch({
            type: RESET_GET_PURCHASE_MINUTES_PLAN,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_PURCHASE_MINUTES_PLAN_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_PURCHASE_MINUTES_PLAN,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * get member tasks
 */
export function getMemberTasks(pageNumber = 1, type = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBER_TASK_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/member/task?page=${pageNumber}&limit=10&type=${type}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBER_TASK_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBER_TASK,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBER_TASK_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MEMBER_TASK,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * view member curriculum response
 */
export function viewMemberTaskResponse(id) {
  return (dispatch) => {
    dispatch({
      type: VIEW_MEMBER_TASK_RESPONSE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/member/task/${id}/view`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_MEMBER_TASK_RESPONSE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_MEMBER_TASK_RESPONSE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_MEMBER_TASK_RESPONSE_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_VIEW_MEMBER_TASK_RESPONSE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * submit member task response
 */
export function submitMemberTaskResponse(id, data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MEMBER_TASK_RESPONSE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/member/task/${id}/submit`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MEMBER_TASK_RESPONSE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_TASK_RESPONSE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MEMBER_TASK_RESPONSE_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_TASK_RESPONSE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * submit mentor rating
 */
export function submitMentorRating(data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MENTOR_RATING_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/meeting/rating`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MENTOR_RATING_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MENTOR_RATING,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MENTOR_RATING_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_MENTOR_RATING,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

// api to submit answer single curriculum question

export function answerSingleQuestion(id, data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_SINGLE_QUESTION_ANSWER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/curriculum/${id}/answer`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_SINGLE_QUESTION_ANSWER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_SINGLE_QUESTION_ANSWER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_SINGLE_QUESTION_ANSWER_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_SINGLE_QUESTION_ANSWER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

// api to submit answers bulkly

export function submitBulkAnswers(id, data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_BULK_ANSWERS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/curriculum/${id}/answer-all`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_BULK_ANSWERS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_BULK_ANSWERS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_BULK_ANSWERS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_BULK_ANSWERS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function editSingleAnswers(id, data) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SINGLE_ANSWERS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.patch(`/curriculum/${id}/answer`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_SINGLE_ANSWERS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_SINGLE_ANSWERS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_SINGLE_ANSWERS_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_EDIT_SINGLE_ANSWERS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//api to save tif

export function answerSingleTif(id, data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_SINGLE_TIF_ANSWER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/curriculum/${id}/tif-answer`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_SINGLE_TIF_ANSWER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_SINGLE_TIF_ANSWER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_SINGLE_TIF_ANSWER_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_SINGLE_TIF_ANSWER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//edit api
export function editSingleTif(id, data) {
  return (dispatch) => {
    dispatch({
      type: EDIT_SINGLE_TIF_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.patch(`/curriculum/${id}/tif-answer`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_SINGLE_TIF_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_SINGLE_TIF,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_SINGLE_TIF_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_EDIT_SINGLE_TIF,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//bulk submit tif

export function submitBulkTifs(id, data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_BULK_TIF_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/curriculum/${id}/tif-answer-all`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_BULK_TIF_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_BULK_TIF,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_BULK_TIF_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_SUBMIT_BULK_TIF,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}



// dispatch welcome email

export function welcomeEmail(data) {
  return (dispatch) => {
    dispatch({
      type: SEND_WELCOME_EMAIL,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/member/welcomeEmail`, data);

      console.log(data);

      doRequest.then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });

    return promise;
  };
}
