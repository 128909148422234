export const GET_MEMBER_CALENDAR_PREFERENCE_BEGIN =
  "GET_MEMBER_CALENDAR_PREFERENCE_BEGIN";
export const GET_MEMBER_CALENDAR_PREFERENCE_SUCCESS =
  "GET_MEMBER_CALENDAR_PREFERENCE_SUCCESS";
export const GET_MEMBER_CALENDAR_PREFERENCE_FAILURE =
  "GET_MEMBER_CALENDAR_PREFERENCE_FAILURE";
export const RESET_GET_MEMBER_CALENDAR_PREFERENCE =
  "RESET_GET_MEMBER_CALENDAR_PREFERENCE";

export const SCHEDULE_MEETING_WITH_MENTOR_BEGIN =
  "SCHEDULE_MEETING_WITH_MENTOR_BEGIN";
export const SCHEDULE_MEETING_WITH_MENTOR_SUCCESS =
  "SCHEDULE_MEETING_WITH_MENTOR_SUCCESS";
export const SCHEDULE_MEETING_WITH_MENTOR_FAILURE =
  "SCHEDULE_MEETING_WITH_MENTOR_FAILURE";
export const RESET_SCHEDULE_MEETING_WITH_MENTOR =
  "RESET_SCHEDULE_MEETING_WITH_MENTOR";

export const VIEW_MEMBER_MEETING_BEGIN = "VIEW_MEMBER_MEETING_BEGIN";
export const VIEW_MEMBER_MEETING_SUCCESS = "VIEW_MEMBER_MEETING_SUCCESS";
export const VIEW_MEMBER_MEETING_FAILURE = "VIEW_MEMBER_MEETING_FAILURE";
export const RESET_VIEW_MEMBER_MEETING = "RESET_VIEW_MEMBER_MEETING";

export const CANCEL_MEMBER_MEETING_BEGIN = "CANCEL_MEMBER_MEETING_BEGIN";
export const CANCEL_MEMBER_MEETING_SUCCESS = "CANCEL_MEMBER_MEETING_SUCCESS";
export const CANCEL_MEMBER_MEETING_FAILURE = "CANCEL_MEMBER_MEETING_FAILURE";
export const RESET_CANCEL_MEMBER_MEETING = "RESET_CANCEL_MEMBER_MEETING";

export const UPDATE_MEMBER_MEETING_STATUS_BEGIN =
  "UPDATE_MEMBER_MEETING_STATUS_BEGIN";
export const UPDATE_MEMBER_MEETING_STATUS_SUCCESS =
  "UPDATE_MEMBER_MEETING_STATUS_SUCCESS";
export const UPDATE_MEMBER_MEETING_STATUS_FAILURE =
  "UPDATE_MEMBER_MEETING_STATUS_FAILURE";
export const RESET_UPDATE_MEMBER_MEETING_STATUS =
  "RESET_UPDATE_MEMBER_MEETING_STATUS";
