import {
  GET_MENTOR_DASHBOARD_DETAILS_BEGIN,
  GET_MENTOR_DASHBOARD_DETAILS_SUCCESS,
  GET_MENTOR_DASHBOARD_DETAILS_FAILURE,
  RESET_GET_MENTOR_DASHBOARD_DETAILS,
  GET_MENTOR_PROFILE_BEGIN,
  GET_MENTOR_PROFILE_SUCCESS,
  GET_MENTOR_PROFILE_FAILURE,
  RESET_GET_MENTOR_PROFILE,
} from "../constants/MentorDashboard";

import { httpApp } from "../../axios/httpUser";

// api to get mentor profile
export function getMentorProfile() {
  return (dispatch) => {
    dispatch({
      type: GET_MENTOR_PROFILE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/mentor/profile`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTOR_PROFILE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_PROFILE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTOR_PROFILE_FAILURE,
            data: err.response,
          });
          dispatch({
            type: RESET_GET_MENTOR_PROFILE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//  get mentor dashboard details
export function getMentorDashboardDetails() {
  return (dispatch) => {
    dispatch({
      type: GET_MENTOR_DASHBOARD_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/mentor/dashboard`);

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTOR_DASHBOARD_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_DASHBOARD_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTOR_DASHBOARD_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_DASHBOARD_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
