import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import ModalOuter from "../../../utilities/modals/ModalOuter";
import { RatingHashStar, RatingYellowStar } from "../../../utilities/svg";
import { submitMentorRating } from "../../../../AppRedux/actions/MemberDashboard";
import { CardButton, successToast, TextArea } from "../../../utilities";

const RatingModal = ({ state }) => {
  const dispatch = useDispatch();
  const [redirectToDashboard, setRedirectToDashboard] = useState();
  const [formData, setFormData] = useState({
    rating: 2,
    feedback: "",
  });
  const [formError, setFormError] = useState({
    feedback: "",
  });
  const { submitMentoratingBegin, submitMentoratingSuccessData } = useSelector(
    ({ memberDashboard }) => memberDashboard
  );

  useEffect(() => {
    if (submitMentoratingSuccessData) {
      successToast("Rating submitted successfully!");
      setRedirectToDashboard(true);
    }
  }, [submitMentoratingSuccessData]);

  const customFormValidation = () => {
    const { feedback } = formData;

    const validationErrors = {};
    if (feedback?.trim() === "") {
      validationErrors.feedback = "Feedback is required.";
    }
    if (feedback?.trim() !== "" && feedback.length < 3) {
      validationErrors.feedback = "Please give a valid feedback.";
    }

    return validationErrors;
  };

  const onSubmitRating = () => {
    const validationErrors = customFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      const { rating, feedback } = formData;

      const data = {
        rating: rating,
        review: feedback,
      };

      dispatch(submitMentorRating(data));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  if (redirectToDashboard) {
    return <Navigate to="/member/dashboard" />;
  }

  return (
    <ModalOuter
      state={state}
      setState={() => console.log("done")}
      style={{ minWidth: "470px" }}
      classNames="p-7 py-4 text-left animate-modalInitial"
    >
      <h1 className="font-semibold text-2xl mb-1 text-brandDarkBlue">
        Rate your experience
      </h1>
      <p className="font-semibold text-base text-brandGrey">
        Rate your experience with our Mentor and share your feedback with us
      </p>
      <div className="flex items-center gap-2  mt-3 mb-5">
        {Array(5)
          .fill()
          .map((_, index) => {
            return (
              <div
                key={index}
                className="ratingStar cursor-pointer buttonHover activeState rounded-full border-4 border-transparent active:border-brandBlue active:scale-75"
                onClick={() => {
                  const stars = document.querySelectorAll(".ratingStar");
                  stars.forEach((element) =>
                    element.classList.add("star-animate")
                  );

                  setTimeout(() => {
                    stars.forEach((element) =>
                      element.classList.remove("star-animate")
                    );
                  }, 1000);

                  setFormData({ ...formData, rating: index + 1 });
                }}
              >
                {formData.rating >= index + 1 ? (
                  <RatingYellowStar />
                ) : (
                  <RatingHashStar />
                )}
              </div>
            );
          })}
      </div>

      <TextArea
        placeholder="Enter your detail feedback here"
        name="feedback"
        label="Share your feedback"
        value={formData.feedback}
        onChange={(e) => {
          setFormError({
            ...formError,
            feedback: "",
          });
          setFormData({
            ...formData,
            feedback: e.target.value,
          });
        }}
        classNames="mb-0"
        rows="5"
        setValue={(value) =>
          setFormData({
            ...formData,
            feedback: value,
          })
        }
        errorMessage={formError?.feedback}
      />
      <div className="flex justify-end mt-5 mb-6 gap-4">
        <CardButton
          onClick={() => onSubmitRating()}
          text="Submit"
          loader={submitMentoratingBegin}
          classNames="bg-brandBlue py-2 font-normal text-base flex "
        />
      </div>
    </ModalOuter>
  );
};

export default RatingModal;
