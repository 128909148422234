import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ModalOuter from "../../../utilities/modals/ModalOuter";

import { getMemberProfileDetails } from "../../../../AppRedux/actions/AdminMember";
import { assignMentor } from "../../../../AppRedux/actions/AdminMember";
import { CardButton, errorToast, successToast } from "../../../utilities";
import { getMentorsList } from "../../../../AppRedux/actions/AdminMentor";
import { ModalHeader } from "../../MyCalendarGeneralItems";

import { AdminMentorDropdownSelectArrow } from "../../../utilities/svg";
import { Scaleloader } from "../../../utilities";
import { NoListToShowContainer } from "../../dashboardGeneralItems";

const AssignMentorModal = ({ state, setState, mId }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [formError, setFormError] = useState({});
  const [mentorsCount, setMentorsCount] = useState();
  const [mentorsListPageNumber, setMentorsListPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [mentorPick, setMentorPick] = useState(false);
  const [mentorName, setMentorName] = useState("");
  const [formData, setformData] = useState({
    mentorId: "",
  });

  const { getMentorsListBegin, getMentorsListSuccessData } = useSelector(
    ({ adminMentor }) => adminMentor
  );

  const {
    assignMentorBegin,
    assignMentorSuccessData,
    assignMentorFailureData,
  } = useSelector(({ adminMember }) => adminMember);

  const [params] = useSearchParams();
  const memberId = params.getAll("id").toString();

  useEffect(() => {
    dispatch(getMentorsList(3));
  }, [dispatch]);

  useEffect(() => {
    if (getMentorsListSuccessData) {
      const mentorsList = getMentorsListSuccessData?.data;
      setItems((mentors) => [...mentors, ...mentorsList?.rows]);
      setMentorsCount(mentorsList?.count);
    }
    if (assignMentorSuccessData) {
      successToast("Mentor assigned successfully");
      dispatch(getMemberProfileDetails(memberId));
      setState(false);
    }
  }, [
    getMentorsListSuccessData,
    assignMentorSuccessData,
    memberId,
    dispatch,
    setState,
  ]);

  //failure data handling

  useEffect(() => {
    if (assignMentorFailureData) {
      errorToast(assignMentorFailureData?.data.message);
    }
  }, [assignMentorFailureData]);

  const getTotalNumberOfPages = () => {
    const totalPages = parseInt(mentorsCount / 10),
      leftColumns = mentorsCount - totalPages * 10;
    return leftColumns > 0 ? totalPages + 1 : totalPages;
  };

  const fetchMoreData = () => {
    if (mentorsListPageNumber >= getTotalNumberOfPages()) {
      setHasMore(false);
      return;
    }
    dispatch(getMentorsList(3, mentorsListPageNumber + 1));
    setMentorsListPageNumber(mentorsListPageNumber + 1);
  };

  const handleAssign = () => {
    const data = {
      memberId: mId,
      mentorId: formData.mentorId,
    };
    dispatch(assignMentor(data));
  };

  return (
    <ModalOuter
      state={state}
      setState={setState}
      blockOutsideClick={false}
      classNames="text-left animate-modalInitial min-h-90vh overflow-y-hidden min-w-200px 500px:min-w-470px"
    >
      <ModalHeader
        heading="Manually Assign Mentor"
        closeIcon={true}
        setState={setState}
        hideBorder={true}
        classNames="text-2xl font-semibold px-6 py-4 mt-4  "
        iconClass="top-4 right-4"
      />
      <div
        className="border-b-2 border-solid "
        style={{ borderColor: "#ECECEC" }}
      />
      <div className="px-6">
        <p className="my-2 font-bold">
          Select the Mentor to assign from the dropdown below
        </p>
        <div
          className="p-4 mt-4 flex justify-between items-center rounded cursor-pointer "
          onClick={() => {
            setMentorPick(!mentorPick);
          }}
          style={{
            border: mentorPick ? "2px solid #007CA5" : "1px solid #B9B9BB",
          }}
        >
          <h1>{mentorName === "" ? "Select Mentor to assign" : mentorName} </h1>
          {mentorPick ? (
            <AdminMentorDropdownSelectArrow className=" rotate-180 " />
          ) : (
            <AdminMentorDropdownSelectArrow />
          )}
        </div>

        {mentorPick && (
          <>
            {items?.length > 0 ? (
              <div
                className="relative animate-navbar"
                style={{
                  minWidth: "250px",
                  height: "40vh",
                  overflow: "auto",
                  border: "1px solid #B9B9BB",
                }}
                id="mentorsScrollableDiv"
              >
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="mentorsScrollableDiv"
                  endMessage={<p style={{ textAlign: "center" }}></p>}
                >
                  {items?.map(
                    ({ id, firstName, lastName, email, mentorship }, index) => {
                      return (
                        <div
                          onClick={() => {
                            let sm = formError;
                            delete sm[["mentorId"]];
                            setFormError({ ...formError, ...sm });
                            setMentorName(firstName + " " + lastName);
                            setformData({
                              ...formData,
                              mentorId: mentorship.id,
                            });
                            setMentorPick(false);
                          }}
                          key={index}
                        >
                          {
                            <h1 className="text-sm p-2 500px:text-base cursor-pointer hover:font-extrabold dashboardHover dashboardActive">
                              {`${firstName} ${lastName} (${email})`}
                            </h1>
                          }
                        </div>
                      );
                    }
                  )}
                </InfiniteScroll>
              </div>
            ) : getMentorsListBegin ? (
              <Scaleloader />
            ) : (
              <NoListToShowContainer text="Currently there are no mentors available!" />
            )}
          </>
        )}
        <p className=" text-red-600 text-base mb-4">{formError?.mentorId}</p>
        <CardButton
          text="Assign"
          linearBg={true}
          classNames="font-semibold text-sm rounded-md w-1/3 justify-center mx-auto"
          onClick={handleAssign}
          loader={assignMentorBegin}
        />
      </div>
    </ModalOuter>
  );
};

export default AssignMentorModal;
