import PageData from "../utilities/content/pages.json";
import { FullYellowSmallSun } from "../utilities/svg";

const WhoWeSection = () => {
  const { heading, bold, paragraph, features } =
    PageData.homePageData.whoWeSectionData;
  return (
    <section className="relative">
      <div className="homepageWidth pb-24 lg:pb-20">
        <h2 className="font-normal sectionHeader uppercase pageheaderFontSize text-brandDarkBlue">
          {heading}
        </h2>
        <p className="paragraphSize font-medium text-brandDarkBlue mb-5 lg:mb-8">
          <b>{bold}</b>
          {paragraph}
        </p>
        {/* Merity Features */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 lg:gap-y-6 mt-3">
          {features.map((feature, index) => (
            <div className="flex items-center gap-2 lg:gap-2" key={index}>
              <FullYellowSmallSun
                style={{ minWidth: "44px", minHeight: "44px" }}
              />
              <div
                className="font-normal text-lg lg:text-xl text-brandDarkBlue"
                key={index}
                dangerouslySetInnerHTML={{ __html: feature }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhoWeSection;
