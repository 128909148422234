import { useState } from "react";

import { url } from "./generalItems";
import MemberProfileCoverPicture from "../../assets/Images/MemberProfileBackBg.jpg";

export const UserNameAndAvatar = ({
  name,
  classNames,
  profilePhoto,
  style,
}) => (
  <div
    className={`flexCenter gap-1 500px:gap-2 font-medium text-center text-xs 500px:text-lg ${classNames}`}
  >
    <UserAvatar
      style={style}
      name={name}
      classNames="w-6 h-6 500px:w-9 500px:h-9"
      profilePhoto={profilePhoto}
      imgClassNames="w-6 h-6 500px:w-9 500px:h-9"
    />
    <p className="hidden 370px:block text-brandDarkBlue">{name}</p>
  </div>
);

export const UserAvatar = ({
  name,
  classNames,
  style,
  profilePhoto,
  imgClassNames,
}) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {profilePhoto ? (
        <img
          src={
            typeof photo === "object"
              ? MemberProfileCoverPicture
              : `${url}/file?path=${profilePhoto}`
          }
          alt=""
          className={`border border-gray-200 shadow-sm rounded-full ${imgClassNames} ${
            loaded ? "" : "bg-brandBlue"
          }`}
          onLoad={() => setLoaded(true)}
        />
      ) : (
        <div
          className={`bg-brandBlue text-white rounded-full flexCenter ${classNames}`}
          style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", ...style }}
        >
          {name?.charAt(0).toUpperCase()}
        </div>
      )}
    </>
  );
};
