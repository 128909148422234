import moment from "moment";
import {
  InputGroup,
  FormButton,
  UserAvatar,
  Cliploader,
  Scaleloader,
} from "../../utilities";
import {
  EditIcon,
  CrossIcon,
  LocationIcon,
  MailIcon,
  PhoneIcon,
} from "../../utilities/svg";

export const AdminCardContainer = ({ children }) => (
  <div
    id="scrollbar"
    className="overflow-x-scroll 950px:overflow-x-auto bg-white m-5 xl:m-10 shadow-memberDashboard rounded-10px px-3 lg:px-8 py-10 min-h-80vh relative pb-14"
  >
    <div style={{ minWidth: "620px" }}>{children}</div>
  </div>
);

export const FilterDropdown = ({ options, handleOnClick }) => (
  <div
    className="memberDropdownList right-0 min-w-max"
    style={{ minWidth: "150px" }}
  >
    {options?.map(({ text, params }, index) => (
      <div
        key={index}
        onClick={() => {
          handleOnClick(text, params);
        }}
        className="memberDropdownListItem"
      >
        {text}
      </div>
    ))}
  </div>
);

export const AdminGeneralCards = ({
  loader,
  buttonLoader,
  details,
  data,
  editclicked,
  formError,
  handleInputOnChange,
  handleSubmit,
  onEditIconClick,
  type,
}) => {
  return (
    <div
      className="relative rounded-10px bg-white min-w-400px w-37% mt-10 py-8 mx-6"
      style={{ boxShadow: "box-shadow: 0px 13px 50px rgba(0, 0, 0, 0.03)" }}
    >
      {!loader && details ? (
        <>
          <div className="flex flex-col items-center space-y-4">
            <UserAvatar
              classNames={`w-20 h-20 text-4xl mt-6`}
              name={type === "admin" ? details?.firstName : details?.name}
            />

            {editclicked ? (
              <div className="w-full p-2 animate-navbar">
                <InputGroup
                  placeholder="Enter name to update"
                  name="name"
                  onChange={handleInputOnChange}
                  value={data?.name}
                  errorMessage={formError?.name}
                />
                <InputGroup
                  disabled={true}
                  placeholder="Enter email to update"
                  name="email"
                  onChange={handleInputOnChange}
                  value={data?.email}
                  errorMessage={formError?.email}
                />
                <FormButton
                  disabled={buttonLoader}
                  classNames="bg-contact text-white "
                  onClick={() => handleSubmit()}
                >
                  Submit {buttonLoader && <Cliploader />}
                </FormButton>
              </div>
            ) : (
              <div className="text-center">
                <h1 className="text-2xl font-semibold">
                  {type === "admin"
                    ? `${details?.firstName} ${details?.lastName}`
                    : details?.name}
                </h1>
                <p
                  className="text-base font-medium"
                  style={{ color: "#00000066" }}
                >
                  {details?.email}
                </p>
              </div>
            )}
          </div>
          <div
            className="dashboardHover dashboardActive p-2 rounded-full  absolute top-2 right-2 cursor-pointer"
            onClick={() => onEditIconClick()}
          >
            {editclicked ? (
              <CrossIcon className="fill-current" />
            ) : (
              <EditIcon className="fill-current" />
            )}
          </div>
        </>
      ) : (
        <Scaleloader />
      )}
    </div>
  );
};

export const CommonLeftSideProfileCard = ({ data }) => {

  const addressGenerator = (data) => {
    if (data.address1 !== null) {
      return (<>
        {`${data?.address1 === null ? "" : data?.address1}${data?.address2 === null || data?.address2 === "" ? "" : `, ${data?.address2}`
          } ${data?.state === null ? "" : `, ${data?.state}`} ${data?.city === null ? "" : `, ${data?.city}`} ${data?.country === null ? "" : `, ${data?.country}`}${data?.zip === null ? "" : `, ${data?.zip}`}`}
      </>)

    }
    else {
      return (<>
        <p>No details given</p>
      </>)
    }

  }
  return (
    <>
      <div className="space-y-1 flex flex-col justify-center items-center mb-6">
        <UserAvatar
          name={data?.firstName}
          classNames="w-20 h-20 text-4xl mt-6"
          profilePhoto={data?.profilePhoto}
          imgClassNames="w-20 h-20 mt-6"
        />

        <h1 className="text-2xl font-medium">{`${data?.firstName} ${data?.lastName}`}</h1>
        <h1 className="text-sm font-semibold" style={{ color: "#0000007a" }}>
          {`Joined on ${moment(data?.createdAt).format("Do MMMM YYYY")}`}
        </h1>
      </div>
      <div className="border-b-2 border-faq mt-3 mb-4" />
      <div className="flex flex-col p-4 gap-4 text-black font-semibold text-xs xl:text-sm">
        <div className="flex items-center space-x-2">
          <div
            className="rounded-full w-5 h-5 p-1"
            style={{ background: "#F3F3F3" }}
          >
            <MailIcon />
          </div>
          <h1>{data?.email}</h1>
        </div>
        <div className="flex items-center space-x-2">
          <div
            className="rounded-full w-5 h-5 p-1"
            style={{ background: "#F3F3F3" }}
          >
            <PhoneIcon />
          </div>
          <h1>{data?.phone}</h1>
        </div>
        <div className="flex items-center space-x-2">
          <div
            className="rounded-full w-5 h-5 p-1"
            style={{ background: "#F3F3F3" }}
          >
            <LocationIcon />
          </div>
          <div className="flex flex-col">
            {addressGenerator(data)}

          </div>
        </div>
      </div>
    </>
  );
};
