import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { infoToast } from "../utilities";
import { submitLogout, cancelSubscription } from "../../AppRedux/actions/MemberLogin";
import {
  MemberCurriculumArrow,
  MemberDashboardBottomLeftArrow,
  NoListToShow,
  MoreIcon,
  RatingIcon,
  AdminBlueArrow,
} from "../utilities/svg";

// Bottom page numbers
export const PageNumbers = ({
  count = 0,
  currentPage,
  setcurrentPage,
  loading,
  limit = 10,
  onPageNumberClick,
}) => {
  const [pageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(1);
  const pages = [];

  for (let i = 1; i <= Math.ceil(count / limit); i++) {
    pages.push(i);
  }

  useEffect(() => {
    if (currentPage === 1) {
      setmaxPageNumberLimit(5);
      setminPageNumberLimit(1);
    }
  }, [currentPage]);

  const getTotalNumberOfPages = () => {
    const totalPages = parseInt(count / limit),
      leftColumns = count - totalPages * limit;
    return leftColumns > 0 ? totalPages + 1 : totalPages;
  };

  const handleClick = (number) => {
    setcurrentPage(number);
    onPageNumberClick(number);
  };

  const ArrowContainer = ({ children, onClick, classNames }) => (
    <div
      className={`bg-dashboardArrow w-5 h-5 flexCenter rounded-full ${classNames}`}
      onClick={onClick}
    >
      {children}
    </div>
  );

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number >= minPageNumberLimit) {
      return (
        <p
          key={number}
          className={`dashboardIcon cursor-pointer w-5 h-5 rounded-full flexCenter activeState buttonHover text-sm font-normal ${currentPage === number ? "bg-brandBlue  text-white" : "text-black"
            }`}
          onClick={() => handleClick(number)}
        >
          {number}
        </p>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    onPageNumberClick(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    onPageNumberClick(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit && currentPage > pages[0]) {
    pageIncrementBtn = (
      <li className="cursor-pointer" onClick={handleNextbtn}>
        &hellip;
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit > 1) {
    pageDecrementBtn = (
      <li className="cursor-pointer" onClick={handlePrevbtn}>
        &hellip;
      </li>
    );
  }

  return (
    <div
      className=" absolute bottom-0 left-1/2 transform -translate-x-1/2 flexCenter gap-6 py-4 mx-auto "
      style={{ minWidth: "33%" }}
    >
      <ul className="flex space-x-4">
        <ArrowContainer
          onClick={() => {
            if (currentPage !== 1 && !loading) {
              handlePrevbtn();
            }
          }}
          disabled={currentPage === pages[0] ? true : false}
          classNames={
            currentPage === 1 ? "cursor-not-allowed" : "dashboardIcon"
          }
        >
          <MemberDashboardBottomLeftArrow className="stroke-current" />
        </ArrowContainer>

        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}
        <ArrowContainer
          onClick={() => {
            currentPage !== getTotalNumberOfPages() &&
              getTotalNumberOfPages() !== 0 &&
              !loading &&
              handleNextbtn();
          }}
          disabled={currentPage === pages[pages.length - 1] ? true : false}
          classNames={
            currentPage === getTotalNumberOfPages() ||
              getTotalNumberOfPages() === 0
              ? "cursor-not-allowed"
              : "dashboardIcon"
          }
        >
          <MemberDashboardBottomLeftArrow className="transform rotate-180 stroke-current" />
        </ArrowContainer>
      </ul>
    </div>
  );
};

export const NoListToShowContainer = ({ text, small }) => (
  <div className={`${small ? "flex-col flexCenter" : ""} rounded-lg `}>
    <p
      className={`${small ? "mt-1 py-1" : "mt-10 py-6"
        } text-center text-brandBlue font-bold text-base `}
    >
      {text}
    </p>
    <NoListToShow className={`${small ? "w-2/12" : "w-3/12 mx-auto"}`} />
  </div>
);

export const PageBackArrow = ({ onClick }) => (
  <div
    className="w-11 h-11 rounded-lg flexCenter cursor-pointer bg-dashboardArrow text-black dashboardActive dashboardHover shadow-memberDashboard"
    onClick={onClick}
  >
    <MemberCurriculumArrow className="stroke-current" />
  </div>
);

export const Tab = ({ tab, selectedTab, onClick, classNames }) => (
  <p
    className={`rounded-sm border-b-3px font-semibold text-base xl:text-lg w-1/2 text-center cursor-pointer hover:text-gray-600 activeState ${selectedTab.toLowerCase() === tab.toLowerCase()
      ? "brandBlueText border-brandBlue hover:opacity-90"
      : "text-tabHash border-transparent"
      }  ${classNames}`}
    onClick={() => onClick()}
  >
    {tab}
  </p>
);

export const MoreIconContainer = ({ onClick, open }) => (
  <div
    onClick={onClick}
    className={`w-6 h-6 flexCenter rounded-full dashboardActive ml-auto dashboardHover  ${open ? "bg-brandBlue text-white" : "text-black"
      }`}
  >
    <MoreIcon className="fill-current" />
  </div>
);

export const MentorRatingContainer = ({ rating, count }) => {
  return (
    <div className="flex">
      <RatingIcon />
      <p className="font-bold text-sm text-brandDarkBlue">
        {rating.length === 0 ? (
          ""
        ) : (
          <>
            {Number(rating[0].avg).toFixed(2)}
            <span className="ml-1 text-brandGrey">{`(${count} reviews)`}</span>
          </>
        )}
      </p>
    </div>
  );
};

export const BackArrow = ({ link }) => {
  const navigate = useNavigate();

  return (
    <div
      className="dashboardIcon text-brandBlue rounded-full min-w-36px min-h-36px flexCenter"
      style={{
        boxShadow:
          "0px 100px 80px rgba(0, 0, 0, 0.09), 0px 36.5016px 29.2013px rgba(0, 0, 0, 0.0620983), 0px 17.7209px 14.1767px rgba(0, 0, 0, 0.0500662), 0px 8.6871px 6.94968px rgba(0, 0, 0, 0.0399338), 0px 3.43489px 2.74791px rgba(0, 0, 0, 0.0279017)",
      }}
      onClick={() => navigate(link)}
    >
      <AdminBlueArrow className="stroke-current" />
    </div>
  );
};

export const calendarBookingOnChange = (date, setValue) => {
  //comparing dates which are only after current date
  let todaysDate = moment(new Date());
  if (moment(date).diff(todaysDate, "days") >= 0) {
    setValue(date);
  } else {
    infoToast("Please select a future date");
  }
};

export const LogoutModalData = (dispatch, setState, loader) => {
  const data = {
    heading: "Do you want to logout from Merity platform ?",
    buttonText: "Yes",
    confirmClick: () => {
      dispatch(submitLogout());
      if (document.getElementById("cometchat__widget")) {
        window.CometChatWidget.logout().then((response) => {
          document.getElementById("cometchat__widget")?.remove();
          document.querySelectorAll("cometchatFeeds")?.remove();
        });
      }
    },
    cancelButtonText: "Cancel",
    cancelButtonClick: () => {
      setState(false);
    },
    buttonLoader: loader,
  };

  return data;
};

export const UnsubscribeModalData = (userId, dispatch, setState, loader) => {
  const data = {
    heading: "Do you want to unsubscribe from Merity platform ?",
    buttonText: "Yes",
    confirmClick: () => {

      dispatch(cancelSubscription(userId));
    },
    cancelButtonText: "Cancel",
    cancelButtonClick: () => {
      setState(false);
    },
    buttonLoader: loader,
  };

  return data;
};
