import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

import ConfirmModal from "../../../utilities/modals/ConfirmModal";
import OutsideClickCloseContainer from "../../../utilities/OutsideClickCloseContainer";
import BackgroundVerificationModal from "./BackgroundVerificationModal";
import MentorRejectedModal from "./MentorRejectedModal";
import {
  Scaleloader,
  scrollToTop,
  RcTooltip,
  DeleteIcon,
  successToast,
  errorToast,
} from "../../../utilities";
import {
  PageNumbers,
  NoListToShowContainer,
} from "../../dashboardGeneralItems";
import {
  BlockIcon,
  BackgroundCheckIcon,
  VerifiedStatusIcon,
  RejectedStatusIcon,
} from "../../../utilities/svg";
import {
  getMentorsList,
  rejectMentor,
  approveMentor,
  getBlockedMentors,
  blockMentor,
  unblockMentor,
  mentorBackgroundVerification,
  deleteMentor,
} from "../../../../AppRedux/actions/AdminMentor";

const MentorsList = ({ tab }) => {
  let flag = 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevTabRef = useRef();
  const [, setParams] = useSearchParams();
  const [mentorList, setMentorList] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [modal, setModal] = useState({ popup: false, user: "" });
  const [rejectedModal, setRejectedModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmBlockModal, setShowConfirmBlockModal] = useState(false);
  const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false);
  const [unblockedModal, setUnblockedModal] = useState(false);
  const [blockedModal, setBlockedModal] = useState(false);
  const [commonLoader, setCommonLoader] = useState(false);
  const [mentorId, setMentorId] = useState("");
  // const [rejectModal, setRejectModal] = useState(false);
  const {
    getMentorsListBegin,
    getMentorsListSuccessData,
    getBlockedMentorsSuccessData,
    rejectMentorRegistrationBegin,
    rejectMentorRegistrationSuccessData,
    blockSingleMentorBegin,
    blockSingleMentorSuccessData,
    unblockSingleMentorSuccessData,
    approveMentorRegistrationSuccessData,
    approveMentorBackroundVerificationSuccessData,
    deleteMentorBegin,
    deleteMentorSuccessData,
    deleteMentorFailureData,
  } = useSelector(({ adminMentor }) => adminMentor);

  const confirmDeleteModalData = {
    heading: "Are you sure you want to delete this mentor ?",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      // console.log("delete mentor ", mentorId);
      dispatch(deleteMentor(mentorId));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowDeleteModal(false);
    },
    buttonLoader: deleteMentorBegin,
  };

  const confirmBlockModalData = {
    heading: "Are you sure you want to block this mentor ?",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      dispatch(blockMentor(mentorId));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowConfirmBlockModal(false);
    },
    buttonLoader: blockSingleMentorBegin,
  };

  const confirmRejectModalData = {
    heading: "Are you sure you want to reject this mentor",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      dispatch(rejectMentor(mentorId));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowConfirmRejectModal(false);
    },
    buttonLoader: rejectMentorRegistrationBegin,
  };

  useEffect(() => {
    prevTabRef.current = tab;
  });

  if (prevTabRef.current !== tab) {
    flag = 1;
  }

  useEffect(() => {
    setCommonLoader(true);
    if (flag) {
      setcurrentPage(1);
    }
    if (tab === "active") {
      dispatch(getMentorsList(3, currentPage));
    }
    if (tab === "requests") {
      dispatch(getMentorsList(-1, currentPage));
    }
    if (tab === "background-check") {
      dispatch(getMentorsList(1, currentPage));
    }
    if (tab === "rejected") {
      dispatch(getMentorsList(4, currentPage));
    }
    if (tab === "blocked") {
      dispatch(getBlockedMentors(currentPage));
    }
  }, [tab, currentPage, dispatch, flag]);

  useEffect(() => {
    if (getMentorsListSuccessData) {
      setMentorList(getMentorsListSuccessData?.data);
    }
    if (getBlockedMentorsSuccessData) {
      setMentorList(getBlockedMentorsSuccessData?.data);
    }
    if (getBlockedMentorsSuccessData || getMentorsListSuccessData) {
      setCommonLoader(false);
    }
  }, [getMentorsListSuccessData, getBlockedMentorsSuccessData]);

  useEffect(() => {
    if (rejectMentorRegistrationSuccessData) {
      setShowConfirmRejectModal(false);
      setRejectedModal(true);
      navigate("/admin/dashboard/mentors?tab=rejected");
    }
    if (blockSingleMentorSuccessData) {
      setShowConfirmBlockModal(false);
      setBlockedModal(true);
      navigate("/admin/dashboard/mentors?tab=blocked");
    }
    if (unblockSingleMentorSuccessData) {
      setUnblockedModal(true);
      dispatch(getBlockedMentors(currentPage));
    }
    if (approveMentorRegistrationSuccessData) {
      setUnblockedModal(true);
      navigate("/admin/dashboard/mentors?tab=active");
    }

    if (approveMentorBackroundVerificationSuccessData) {
      dispatch(getMentorsList(1));
      navigate("/admin/dashboard/mentors?tab=background-check");
    }
  }, [
    approveMentorBackroundVerificationSuccessData,
    approveMentorRegistrationSuccessData,
    rejectMentorRegistrationSuccessData,
    blockSingleMentorSuccessData,
    unblockSingleMentorSuccessData,
    dispatch,
    navigate,
    currentPage,
  ]);

  //mentor delete success message handling

  useEffect(() => {
    if (deleteMentorSuccessData) {
      successToast("Mentor deleted successfully");
      dispatch(getMentorsList(3));
      navigate("/admin/dashboard/mentors?tab=active");
    }
    if (deleteMentorFailureData)
      errorToast(deleteMentorFailureData?.data.message);

    setShowDeleteModal(false);
  }, [dispatch, deleteMentorSuccessData, deleteMentorFailureData, navigate]);

  const handleMentorClick = (id) => {
    scrollToTop("50", "16.66");
    setParams({
      cell: "profile",
      id: id,
    });
  };

  return (
    <>
      <div className=" member-table-row-items">
        {!commonLoader && mentorList ? (
          <>
            {mentorList?.rows?.length !== 0 ? (
              <>
                {mentorList?.rows.map(
                  (
                    { id, firstName, lastName, email, createdAt, mentorship },
                    index
                  ) => {
                    return (
                      <div
                        key={index}
                        className={` relative dashboardListHover member-table-row-item gap-2 font-medium text-sm xl:text-base w-full
                    ${
                      index === mentorList?.rows?.length - 1
                        ? "border-none"
                        : ""
                    } ${
                          tab === "requests"
                            ? "grid-cols-adminMentorRequestList"
                            : tab === "active"
                            ? "grid-cols-adminMentorList"
                            : tab === "background-check"
                            ? "grid-cols-adminMentorBackgroundCheckList"
                            : "grid-cols-adminMentorBlockedList"
                        }`}
                        onClick={() => handleMentorClick(id)}
                      >
                        <RcTooltip content={`${firstName} ${lastName}`}>
                          <h1 className="oneLineContent">{`${firstName} ${lastName}`}</h1>
                        </RcTooltip>
                        <RcTooltip content={email}>
                          <h1 className="oneLineContent">{email}</h1>
                        </RcTooltip>
                        <h1>
                          {moment(new Date(createdAt)).format("MM-DD-YYYY")}
                        </h1>
                        {tab === "active" ? (
                          <ActiveStatus status="Approved" />
                        ) : tab === "background-check" ? (
                          <BackgroundStatus
                            status={mentorship?.approvalStatus}
                          />
                        ) : (
                          ""
                        )}

                        <div className="flex items-center space-x-6 ">
                          {tab !== "requests" && tab !== "background-check" ? (
                            <DeleteIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setMentorId(id);
                                setShowDeleteModal(true);
                              }}
                            />
                          ) : null}
                          {tab === "requests" ? (
                            <>
                              <VerifyRejectButtons
                                functionality="Verify"
                                handleClick={(e) => {
                                  e.stopPropagation(); //for prevent click function of it's parent
                                  setModal({
                                    popup: true,
                                    id: id,
                                    user: mentorship.id,
                                  });
                                }}
                              />
                              <VerifyRejectButtons
                                functionality="Reject"
                                handleClick={(e) => {
                                  e.stopPropagation(); //for prevent click function of it's parent
                                  // setRejectModal(true);
                                  setShowConfirmRejectModal(true);
                                  setMentorId(mentorship.id);
                                }}
                              />
                              <RcTooltip content={"Background run"}>
                                <BackgroundCheckIcon
                                  className="w-6 h-6 rounded-full p-1 activeState  hover:w-7 hover:h-7"
                                  onClick={(e) => {
                                    e.stopPropagation(); //for prevent click function of it's parent
                                    dispatch(mentorBackgroundVerification(id));
                                  }}
                                  style={{ backgroundColor: "#FFF6EB" }}
                                />
                              </RcTooltip>
                              <RcTooltip content={"Block"}>
                                <BlockIcon
                                  className="w-6 h-6 rounded-full p-1 activeState cursor-pointer  hover:w-7 hover:h-7"
                                  style={{
                                    backgroundColor: "#FFEACB",
                                    fill: "#FF9900",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation(); //for prevent click function of it's parent
                                    setShowConfirmBlockModal(true);
                                    setMentorId(id);
                                  }}
                                />
                              </RcTooltip>
                            </>
                          ) : (
                            <div className="flex items-center space-x-6 ">
                              {tab === "blocked" ? (
                                <RcTooltip content={"Unblock"}>
                                  <BlockIcon
                                    className="w-6 h-6 rounded-full p-1 activeState cursor-pointer  hover:w-7 hover:h-7"
                                    style={{
                                      backgroundColor: "#DEF7E5",
                                      fill: "#0AB06B",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); //for prevent click function of it's parent
                                      dispatch(unblockMentor(id));
                                    }}
                                  />
                                </RcTooltip>
                              ) : tab === "background-check" ? (
                                <div className="flex items-center space-x-6 ">
                                  <VerifyRejectButtons
                                    functionality="Verify"
                                    handleClick={(e) => {
                                      e.stopPropagation(); //for prevent click function of it's parent
                                      dispatch(approveMentor(mentorship.id));
                                    }}
                                  />
                                  <VerifyRejectButtons
                                    functionality="Reject"
                                    handleClick={(e) => {
                                      e.stopPropagation(); //for prevent click function of it's parent
                                      setShowConfirmRejectModal(true);
                                      dispatch(rejectMentor(mentorship.id));
                                    }}
                                  />
                                  <RcTooltip content={"Block"}>
                                    <BlockIcon
                                      className="w-6 h-6 rounded-full p-1 activeState cursor-pointer hover:w-7 hover:h-7"
                                      style={{
                                        backgroundColor: "#FFEACB",
                                        fill: "#FF9900",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation(); //for prevent click function of it's parent
                                        setShowConfirmBlockModal(true);
                                        setMentorId(id);
                                      }}
                                    />
                                  </RcTooltip>
                                </div>
                              ) : (
                                <RcTooltip content={"Block"}>
                                  <BlockIcon
                                    className="w-6 h-6 rounded-full p-1 activeState cursor-pointer hover:w-7 hover:h-7"
                                    style={{
                                      backgroundColor: "#FFEACB",
                                      fill: "#FF9900",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation(); //for prevent click function of it's parent
                                      setShowConfirmBlockModal(true);
                                      setMentorId(id);
                                    }}
                                  />
                                </RcTooltip>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <NoListToShowContainer
                text={`There are no ${
                  tab === "active"
                    ? "active Mentors"
                    : tab === "requests"
                    ? "requests"
                    : tab === "background-check"
                    ? "background checks"
                    : tab === "blocked"
                    ? "blocked mentors"
                    : tab === "rejected" && "rejected mentors"
                } currently`}
              />
            )}
          </>
        ) : (
          <Scaleloader />
        )}
      </div>
      <PageNumbers
        count={mentorList?.count}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={getMentorsListBegin}
        limit={10}
        onPageNumberClick={(number) => {
          dispatch(
            getMentorsList(
              tab === "active"
                ? 3
                : tab === "requests"
                ? -1
                : tab === "rejected"
                ? 4
                : tab === "background-check" && 1,
              number
            )
          );
        }}
      />
      {modal.popup && (
        <BackgroundVerificationModal state={modal} setState={setModal} />
      )}
      {rejectedModal && (
        <MentorRejectedModal
          text="Mentor Rejected"
          state={rejectedModal}
          setState={setRejectedModal}
          type={1}
        />
      )}
      {blockedModal && (
        <MentorRejectedModal
          text="Mentor Blocked"
          state={blockedModal}
          setState={setBlockedModal}
          type={1}
        />
      )}
      {unblockedModal && (
        <MentorRejectedModal
          text={tab === "active" ? "Mentor Verified" : "Mentor Unblocked"}
          state={unblockedModal}
          setState={setUnblockedModal}
          type={tab === "active" ? 2 : ""}
        />
      )}
      {showConfirmBlockModal && (
        <OutsideClickCloseContainer setState={setShowConfirmBlockModal}>
          <ConfirmModal
            {...confirmBlockModalData}
            state={showConfirmBlockModal}
            setState={setShowConfirmBlockModal}
          />
        </OutsideClickCloseContainer>
      )}
      {showConfirmRejectModal && (
        <OutsideClickCloseContainer setState={setShowConfirmBlockModal}>
          <ConfirmModal
            {...confirmRejectModalData}
            state={showConfirmRejectModal}
            setState={setShowConfirmRejectModal}
          />
        </OutsideClickCloseContainer>
      )}
      {showDeleteModal && (
        <OutsideClickCloseContainer setState={setShowDeleteModal}>
          <ConfirmModal
            {...confirmDeleteModalData}
            state={showDeleteModal}
            setState={setShowDeleteModal}
          />
        </OutsideClickCloseContainer>
      )}
    </>
  );
};

export default MentorsList;

export const ActiveStatus = ({ status }) => {
  return (
    <>
      <div>
        <span
          className={`px-2 py-1 text-xs xl:text-sm rounded-3xl `}
          style={{
            backgroundColor: status === "Approved" && "#DCF7EB",
            color: status === "Approved" && "#12BE76",
          }}
        >
          {status}
        </span>
      </div>
    </>
  );
};

export const VerifyRejectButtons = ({ functionality, handleClick }) => {
  return (
    <>
      <button
        className={`flex items-center px-2 text-xs xl:text-sm rounded-3xl activeState w-fit ${
          functionality === "Verify" ||
          functionality === "Unblock" ||
          functionality === "Publish"
            ? "hover:text-verifyText hover:bg-verifyButton hover:text-base"
            : "hover:text-rejectText hover:bg-rejectButton hover:text-base"
        }  `}
        onClick={handleClick}
      >
        {functionality}
      </button>
    </>
  );
};

export const BackgroundStatus = ({ status }) => {
  return (
    <>
      {status === 2 ? (
        <RcTooltip content={"Verified"}>
          <VerifiedStatusIcon
            className="rounded-full p-1 w-5 h-5"
            style={{ backgroundColor: "#E3FFEB" }}
          />
        </RcTooltip>
      ) : status === 4 ? (
        <RcTooltip content={"Rejected"}>
          <RejectedStatusIcon
            className="rounded-full p-1 w-5 h-5"
            style={{ backgroundColor: "#FFDEDE" }}
          />
        </RcTooltip>
      ) : status === 1 ? (
        <RcTooltip content={"In progress"}>
          <BackgroundCheckIcon
            className="rounded-full p-1 w-5 h-5"
            style={{ backgroundColor: "#FFEACB" }}
          />
        </RcTooltip>
      ) : (
        ""
      )}
    </>
  );
};
