import { useMemo, useEffect } from "react";

import { loginMerityPageData } from "../components/utilities";
import SeoData from "../components/utilities/seo/seoData.json";
import Navbar from "../components/pageComponents/Navbar";
import LoginCards from "../components/pageComponents/LoginCards";
import {
  ProgressLoader,
  progressLoaderFunction,
  ReactHelmet,
  SunFooter,
} from "../components/utilities";

const MerityLogin = () => {
  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.login} />
          <ProgressLoader />
          <Navbar whiteNavbar={true} noShadow={true} onlyTopBar={true} />
          <LoginCards loginPageData={loginMerityPageData} />
          <SunFooter />
        </>
      ),
      []
    );

  return <Content />;
};

export default MerityLogin;
