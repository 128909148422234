export const GET_MANAGER_DETAILS_BEGIN = "GET_MANAGER_DETAILS_BEGIN";
export const GET_MANAGER_DETAILS_SUCCESS = "GET_MANAGER_DETAILS_SUCCESS";
export const GET_MANAGER_DETAILS_FAILURE = "GET_MANAGER_DETAILS_FAILURE";
export const RESET_GET_MANAGER_DETAILS = "RESET_GET_MANAGER_DETAILS";

export const EDIT_MANAGER_NAME_BEGIN = "EDIT_MANAGER_NAME_BEGIN";
export const EDIT_MANAGER_NAME_SUCCESS = "EDIT_MANAGER_NAME_SUCCESS";
export const EDIT_MANAGER_NAME_FAILURE = "EDIT_MANAGER_NAME_FAILURE";
export const RESET_EDIT_MANAGER_NAME = "RESET_EDIT_MANAGER_NAME";

export const GET_MANAGER_DASHBOARD_BEGIN = "GET_MANAGER_DASHBOARD_BEGIN";
export const GET_MANAGER_DASHBOARD_SUCCESS = "GET_MANAGER_DASHBOARD_SUCCESS";
export const GET_MANAGER_DASHBOARD_FAILURE = "GET_MANAGER_DASHBOARD_FAILURE";
export const RESET_GET_MANAGER_DASHBOARD = "RESET_GET_MANAGER_DASHBOARD";

export const GET_MANAGER_DETAILS_BYID_BEGIN = "GET_MANAGER_DETAILS_BYID_BEGIN";
export const GET_MANAGER_DETAILS_BYID_SUCCESS =
  "GET_MANAGER_DETAILS_BYID_SUCCESS";
export const GET_MANAGER_DETAILS_BYID_FAILURE =
  "GET_MANAGER_DETAILS_BYID_FAILURE";
export const RESET_GET_MANAGER_DETAILS_BYID = "RESET_GET_MANAGER_DETAILS_BYID";
