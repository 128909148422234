import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import MentorRejectedModal from "../adminMentor/MentorRejectedModal";
import { AdminCardContainer } from "../AdminGeneralComponents";
import { ListHeader } from "../../memberDashboard/tableComponents/ExercisesList";
import SingleMemberDetailsPage from "./SingleMemberDetailsPage";
import { PageNumbers } from "../../dashboardGeneralItems";
import {
  Scaleloader,
  RcTooltip,
  scrollToTop,
  DeleteIcon,
  successToast,
  errorToast,
} from "../../../utilities";
import { BlockIcon } from "../../../utilities/svg";
import OutsideClickCloseContainer from "../../../utilities/OutsideClickCloseContainer";
import ConfirmModal from "../../../utilities/modals/ConfirmModal";
import {
  getMembersList,
  blockMember,
  unblockMember,
  deleteMemberById,
} from "../../../../AppRedux/actions/AdminMember";

const ViewMembersList = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const tab = params.getAll("tab").toString();
  const id = params.getAll("id").toString();
  const [currentPage, setcurrentPage] = useState(1);
  const [membersList, setMembersList] = useState("");
  const [blockedModal, setBlockedModal] = useState(false);
  const [unblockedModal, setUnblockedModal] = useState(false);
  const [showConfirmBlockModal, setShowConfirmBlockModal] = useState(false);
  const [unblockClicked, setUnblockedClicked] = useState(false);
  const [memberid, setMemberid] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    getMembersListBegin,
    deleteMemberByIdBegin,
    deleteMemberByIdSuccessData,
    deleteMemberByIdFailureData,
    getMembersListSuccessData,
    blockMemberBegin,
    blockMemberSuccessData,
    unblockMemberBegin,
    unblockMemberSuccessData,
  } = useSelector(({ adminMember }) => adminMember);

  const confirmDeleteModalData = {
    heading: "Are you sure you want to delete this member ?",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      dispatch(deleteMemberById(memberid));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowDeleteModal(false);
    },
    buttonLoader: deleteMemberByIdBegin,
  };

  const confirmBlockModalData = {
    heading: `Are you sure you want to ${unblockClicked ? "unblock" : "block"
      } this member ?`,
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      if (unblockClicked) {
        dispatch(unblockMember(memberid));
      } else {
        dispatch(blockMember(memberid));
      }
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowConfirmBlockModal(false);
    },
    buttonLoader: unblockClicked ? unblockMemberBegin : blockMemberBegin,
  };

  useEffect(() => {
    dispatch(getMembersList(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (getMembersListSuccessData) {
      setMembersList(getMembersListSuccessData?.data);
    }
    if (blockMemberSuccessData) {
      setShowConfirmBlockModal(false);
      setBlockedModal(true);
      dispatch(getMembersList(currentPage));
    }
    if (unblockMemberSuccessData) {
      setShowConfirmBlockModal(false);
      setUnblockedModal(true);
      setUnblockedClicked(false);
      dispatch(getMembersList(currentPage));
    }
  }, [
    getMembersListSuccessData,
    blockMemberSuccessData,
    unblockMemberSuccessData,
    dispatch,
    currentPage,
  ]);
  useEffect(() => {
    if (deleteMemberByIdSuccessData) {
      successToast("Member Deleted Successfully");
      setShowDeleteModal(false);
      dispatch(getMembersList(currentPage));
    }
    if (deleteMemberByIdFailureData)
      errorToast(deleteMemberByIdFailureData.message);
  }, [deleteMemberByIdSuccessData, deleteMemberByIdFailureData, currentPage, dispatch]);
  const handleClientClick = (id, memberId) => {
    scrollToTop("50", "16.66");
    setParams({
      tab: "profile",
      id: id,
    });
    setMemberid(memberId);
  };

  if (tab === "profile" || tab === "payment") {
    return <SingleMemberDetailsPage tab={tab} id={id} memId={memberid} />;
  }

  return (
    <AdminCardContainer>
      <h1 className="font-semibold text-xl text-brandDarkBlue">
        Manage Members
      </h1>
      <div className="w-full mt-4">
        <ListHeader headers={headers} gridClass="grid-cols-adminMemberList " />
        {!getMembersListBegin && membersList ? (
          <>
            {membersList?.rows?.map(
              (
                {
                  id,
                  firstName,
                  lastName,
                  email,
                  membership,
                  createdAt,
                  status,
                },
                index
              ) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleClientClick(id, membership.id)}
                    className={`relative member-table-row-item dashboardListHover member-table-row-item grid-cols-adminMemberList font-medium text-sm xl:text-base w-full
                      ${index === membersList?.rows?.length - 1
                        ? "border-none"
                        : ""
                      }`}
                  >
                    <RcTooltip content={`${firstName} ${lastName}`}>
                      <h1 className="oneLineContent">{`${firstName} ${lastName}`}</h1>
                    </RcTooltip>
                    <RcTooltip content={email}>
                      <h1 className="oneLineContent w-9/12">{email}</h1>
                    </RcTooltip>

                    <RcTooltip
                      content={
                        membership?.curriculumType === 1
                          ? "Career"
                          : membership?.curriculumType === 2
                            ? "Emotional"
                            : membership?.curriculumType === 3
                              ? "Universal"
                              : membership?.curriculumType === 4
                                ? "Passion"
                                : membership?.curriculumType === null &&
                                "Not yet assigned"
                      }
                    >
                      <h1 className="oneLineContent w-9/12">
                        {membership?.curriculumType === 1
                          ? "Career"
                          : membership?.curriculumType === 2
                            ? "Emotional"
                            : membership?.curriculumType === 3
                              ? "Universal"
                              : membership?.curriculumType === 4
                                ? "Passion"
                                : membership?.curriculumType === null &&
                                "Not yet assigned"}
                      </h1>
                    </RcTooltip>

                    <h1>{moment(new Date(createdAt)).format("MM-DD-YYYY")}</h1>
                    <div className="flex">
                      <DeleteIcon
                        classNames={`mr-2.5`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setMemberid(id);
                          setShowDeleteModal(true);
                        }}
                      />
                      {status === 1 ? (
                        <RcTooltip content={"Block"}>
                          <BlockIcon
                            className="w-6 h-6 rounded-full p-1 activeState cursor-pointer  hover:w-7 hover:h-7"
                            style={{
                              backgroundColor: "#FFEACB",
                              fill: "#FF9900",
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); //for prevent click function of it's parent
                              setShowConfirmBlockModal(true);
                              setMemberid(id);
                            }}
                          />
                        </RcTooltip>
                      ) : (
                        <RcTooltip content={"Unblock"}>
                          <BlockIcon
                            className="w-6 h-6 rounded-full p-1 activeState cursor-pointer  hover:w-7 hover:h-7"
                            style={{
                              backgroundColor: "#DEF7E5",
                              fill: "#0AB06B",
                            }}
                            onClick={(e) => {
                              e.stopPropagation(); //for prevent click function of it's parent
                              setMemberid(id);
                              setShowConfirmBlockModal(true);
                              setUnblockedClicked(true);
                            }}
                          />
                        </RcTooltip>
                      )}
                    </div>
                  </div>
                );
              }
            )}
          </>
        ) : (
          <Scaleloader />
        )}
      </div>
      <PageNumbers
        count={membersList?.count}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={getMembersListBegin}
        limit={10}
        onPageNumberClick={(number) => {
          dispatch(getMembersList(number));
        }}
      />

      {blockedModal && (
        <MentorRejectedModal
          text="Member Blocked"
          state={blockedModal}
          setState={setBlockedModal}
          type={1}
        />
      )}
      {unblockedModal && (
        <MentorRejectedModal
          text="Member Unblocked"
          state={unblockedModal}
          setState={setUnblockedModal}
        />
      )}
      {showConfirmBlockModal && (
        <OutsideClickCloseContainer setState={setShowConfirmBlockModal}>
          <ConfirmModal
            {...confirmBlockModalData}
            state={showConfirmBlockModal}
            setState={setShowConfirmBlockModal}
          />
        </OutsideClickCloseContainer>
      )}
      {showDeleteModal && (
        <OutsideClickCloseContainer setState={setShowDeleteModal}>
          <ConfirmModal
            {...confirmDeleteModalData}
            state={showDeleteModal}
            setState={setShowDeleteModal}
          />
        </OutsideClickCloseContainer>
      )}
    </AdminCardContainer>
  );
};

export default ViewMembersList;

const headers = ["Name", "E-mail", "Curriculum category", "Joined Date"];
