import faqBG from "../../assets/Images/FaqBG.png";

const FaqPageLanding = () => {
  return (
    <section className="relative">
      <img
        src={faqBG}
        className="w-full"
        alt=""
        loading="lazy"
        decoding="async"
      />
      <div className="absolute top-0 navbarGradient h-24 w-full" />
    </section>
  );
};

export default FaqPageLanding;
