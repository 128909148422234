import {
  VIEW_ALL_PROMPTS_BEGIN,
  VIEW_ALL_PROMPTS_SUCCESS,
  VIEW_ALL_PROMPTS_FAILURE,
  RESET_VIEW_ALL_PROMPTS,
  CREATE_PROMPTS_BEGIN,
  CREATE_PROMPTS_SUCCESS,
  CREATE_PROMPTS_FAILURE,
  RESET_CREATE_PROMPTS,
  VIEW_GROUPS_BEGIN,
  VIEW_GROUPS_SUCCESS,
  VIEW_GROUPS_FAILURE,
  RESET_VIEW_GROUPS,
  GET_MEMBERS_LIST_BEGIN,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST_FAILURE,
  RESET_GET_MEMBERS_LIST,
  CREATE_GROUP_BEGIN,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  RESET_CREATE_GROUP,
  VIEW_GROUP_PROMPT_BEGIN,
  VIEW_GROUP_PROMPT_SUCCESS,
  VIEW_GROUP_PROMPT_FAILURE,
  RESET_VIEW_GROUP_PROMPT,
  VIEW_GROUP_MEMBERS_BEGIN,
  VIEW_GROUP_MEMBERS_SUCCESS,
  VIEW_GROUP_MEMBERS_FAILURE,
  RESET_VIEW_GROUP_MEMBERS,
  DELETE_PROMPT_GROUP_BEGIN,
  DELETE_PROMPT_GROUP_SUCCESS,
  DELETE_PROMPT_GROUP_FAILURE,
  RESET_DELETE_PROMPT_GROUP,
  DELETE_GROUP_MEMBER_BEGIN,
  DELETE_GROUP_MEMBER_SUCCESS,
  DELETE_GROUP_MEMBER_FAILURE,
  RESET_DELETE_GROUP_MEMBER,
  VIEW_SCHEDULE_PROMPT_BEGIN,
  VIEW_SCHEDULE_PROMPT_SUCCESS,
  VIEW_SCHEDULE_PROMPT_FAILURE,
  RESET_VIEW_SCHEDULE_PROMPT,
  EDIT_PROMPT_GROUP_BEGIN,
  EDIT_PROMPT_GROUP_SUCCESS,
  EDIT_PROMPT_GROUP_FAILURE,
  RESET_EDIT_PROMPT_GROUP,
  SCHEDULE_PROMPT_BEGIN,
  SCHEDULE_PROMPT_SUCCESS,
  SCHEDULE_PROMPT_FAILURE,
  RESET_SCHEDULE_PROMPT,
  ADD_MEMBER_TO_GROUP_BEGIN,
  ADD_MEMBER_TO_GROUP_SUCCESS,
  ADD_MEMBER_TO_GROUP_FAILURE,
  RESET_ADD_MEMBER_TO_GROUP,
  RESCHEDULE_PROMPT_BEGIN,
  RESCHEDULE_PROMPT_SUCCESS,
  RESCHEDULE_PROMPT_FAILURE,
  RESET_RESCHEDULE_PROMPT,
  VIEW_PROMPT_RESPONSE_BEGIN,
  VIEW_PROMPT_RESPONSE_SUCCESS,
  VIEW_PROMPT_RESPONSE_FAILURE,
  RESET_VIEW_PROMPT_RESPONSE,
  VIEW_GROUP_DETAILS_BEGIN,
  VIEW_GROUP_DETAILS_SUCCESS,
  VIEW_GROUP_DETAILS_FAILURE,
  RESET_VIEW_GROUP_DETAILS,
  EDIT_PROMPT_BEGIN,
  EDIT_PROMPT_SUCCESS,
  EDIT_PROMPT_FAILURE,
  RESET_EDIT_PROMPT,
} from "../constants/AdminPrompts";

const initialState = {
  viewAllPromptsBegin: false,
  viewAllPromptsSuccess: false,
  viewAllPromptsSuccessData: null,
  viewAllPromptsFailure: false,
  viewAllPromptsFailureData: null,

  editPromptBegin: false,
  editPromptSuccess: false,
  editPromptSuccessData: null,
  editPromptFailure: false,
  editPromptFailureData: null,

  createPromptBegin: false,
  createPromptSuccess: false,
  createPromptSuccessData: null,
  createPromptFailure: false,
  createPromptFailureData: null,

  viewAllGroupsBegin: false,
  viewAllGroupsSuccess: false,
  viewAllGroupsSuccessData: null,
  viewAllGroupsFailure: false,
  viewAllGroupsFailureData: null,

  getMembersListBegin: false,
  getMembersListSuccess: false,
  getMembersListSuccessData: null,
  getMembersListFailure: false,
  getMembersListFailureData: null,

  createPromptGroupBegin: false,
  createPromptGroupSuccess: false,
  createPromptGroupSuccessData: null,
  createPromptGroupFailure: false,
  createPromptGroupFailureData: null,

  viewGroupPromptBegin: false,
  viewGroupPromptSuccess: false,
  viewGroupPromptSuccessData: null,
  viewGroupPromptFailure: false,
  viewGroupPromptFailureData: null,

  viewGroupMembersBegin: false,
  viewGroupMembersSuccess: false,
  viewGroupMembersSuccessData: null,
  viewGroupMembersFailure: false,
  viewGroupMembersFailureData: null,

  deletePromptGroupBegin: false,
  deletePromptGroupSuccess: false,
  deletePromptGroupSuccessData: null,
  deletePromptGroupFailure: false,
  deletePromptGroupFailureData: null,

  deleteGroupMemberBegin: false,
  deleteGroupMemberSuccess: false,
  deleteGroupMemberSuccessData: null,
  deleteGroupMemberFailure: false,
  deleteGroupMemberFailureData: null,

  viewSchedulePromptBegin: false,
  viewSchedulePromptSuccess: false,
  viewSchedulePromptSuccessData: null,
  viewSchedulePromptFailure: false,
  viewSchedulePromptFailureData: null,

  editPromptGroupBegin: false,
  editPromptGroupSuccess: false,
  editPromptGroupSuccessData: null,
  editPromptGroupFailure: false,
  editPromptGroupFailureData: null,

  schedulePromptBegin: false,
  schedulePromptSuccess: false,
  schedulePromptSuccessData: null,
  schedulePromptFailure: false,
  schedulePromptFailureData: null,

  reSchedulePromptBegin: false,
  reSchedulePromptSuccess: false,
  reSchedulePromptSuccessData: null,
  reSchedulePromptFailure: false,
  reSchedulePromptFailureData: null,

  addMemberToGroupBegin: false,
  addMemberToGroupSuccess: false,
  addMemberToGroupSuccessData: null,
  addMemberToGroupFailure: false,
  addMemberToGroupFailureData: null,

  viewPromptResponseBegin: false,
  viewPromptResponseSuccess: false,
  viewPromptResponseSuccessData: null,
  viewPromptResponseFailure: false,
  viewPromptResponseFailureData: null,

  viewGroupDetailsBegin: false,
  viewGroupDetailsSuccess: false,
  viewGroupDetailsSuccessData: null,
  viewGroupDetailsFailure: false,
  viewGroupDetailsFailureData: null,
};

export const AdminPrompts = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // view all propmts
    case VIEW_ALL_PROMPTS_BEGIN:
      return {
        ...state,
        viewAllPromptsBegin: true,
        viewAllPromptsSuccess: false,
        viewAllPromptsSuccessData: null,
        viewAllPromptsFailure: false,
        viewAllPromptsFailureData: null,
      };
    case VIEW_ALL_PROMPTS_SUCCESS:
      return {
        ...state,
        viewAllPromptsBegin: false,
        viewAllPromptsSuccess: true,
        viewAllPromptsSuccessData: data,
        viewAllPromptsFailure: false,
        viewAllPromptsFailureData: null,
      };
    case VIEW_ALL_PROMPTS_FAILURE:
      return {
        ...state,
        viewAllPromptsBegin: false,
        viewAllPromptsSuccess: false,
        viewAllPromptsSuccessData: null,
        viewAllPromptsFailure: true,
        viewAllPromptsFailureData: data,
      };
    case RESET_VIEW_ALL_PROMPTS:
      return {
        ...state,
        viewAllPromptsBegin: false,
        viewAllPromptsSuccess: false,
        viewAllPromptsSuccessData: null,
        viewAllPromptsFailure: false,
        viewAllPromptsFailureData: null,
      };

    // edit propmts
    case EDIT_PROMPT_BEGIN:
      return {
        ...state,
        editPromptBegin: true,
        editPromptSuccess: false,
        editPromptSuccessData: null,
        editPromptFailure: false,
        editPromptFailureData: null,
      };
    case EDIT_PROMPT_SUCCESS:
      return {
        ...state,
        editPromptBegin: false,
        editPromptSuccess: true,
        editPromptSuccessData: data,
        editPromptFailure: false,
        editPromptFailureData: null,
      };
    case EDIT_PROMPT_FAILURE:
      return {
        ...state,
        editPromptBegin: false,
        editPromptSuccess: false,
        editPromptSuccessData: null,
        editPromptFailure: true,
        editPromptFailureData: data,
      };
    case RESET_EDIT_PROMPT:
      return {
        ...state,
        editPromptBegin: false,
        editPromptSuccess: false,
        editPromptSuccessData: null,
        editPromptFailure: false,
        editPromptFailureData: null,
      };

    // add propmts
    case CREATE_PROMPTS_BEGIN:
      return {
        ...state,
        createPromptBegin: true,
        createPromptSuccess: false,
        createPromptSuccessData: null,
        createPromptFailure: false,
        createPromptFailureData: null,
      };
    case CREATE_PROMPTS_SUCCESS:
      return {
        ...state,
        createPromptBegin: false,
        createPromptSuccess: true,
        createPromptSuccessData: data,
        createPromptFailure: false,
        createPromptFailureData: null,
      };
    case CREATE_PROMPTS_FAILURE:
      return {
        ...state,
        createPromptBegin: false,
        createPromptSuccess: false,
        createPromptSuccessData: null,
        createPromptFailure: true,
        createPromptFailureData: data,
      };
    case RESET_CREATE_PROMPTS:
      return {
        ...state,
        createPromptBegin: false,
        createPromptSuccess: false,
        createPromptSuccessData: null,
        createPromptFailure: false,
        createPromptFailureData: null,
      };

    // view groups
    case VIEW_GROUPS_BEGIN:
      return {
        ...state,
        viewAllGroupsBegin: true,
        viewAllGroupsSuccess: false,
        viewAllGroupsSuccessData: null,
        viewAllGroupsFailure: false,
        viewAllGroupsFailureData: null,
      };
    case VIEW_GROUPS_SUCCESS:
      return {
        ...state,
        viewAllGroupsBegin: false,
        viewAllGroupsSuccess: true,
        viewAllGroupsSuccessData: data,
        viewAllGroupsFailure: false,
        viewAllGroupsFailureData: null,
      };
    case VIEW_GROUPS_FAILURE:
      return {
        ...state,
        viewAllGroupsBegin: false,
        viewAllGroupsSuccess: false,
        viewAllGroupsSuccessData: null,
        viewAllGroupsFailure: true,
        viewAllGroupsFailureData: data,
      };
    case RESET_VIEW_GROUPS:
      return {
        ...state,
        viewAllGroupsBegin: false,
        viewAllGroupsSuccess: false,
        viewAllGroupsSuccessData: null,
        viewAllGroupsFailure: false,
        viewAllGroupsFailureData: null,
      };

    // get members
    case GET_MEMBERS_LIST_BEGIN:
      return {
        ...state,
        getMembersListBegin: true,
        getMembersListSuccess: false,
        getMembersListSuccessData: null,
        getMembersListFailure: false,
        getMembersListFailureData: null,
      };
    case GET_MEMBERS_LIST_SUCCESS:
      return {
        ...state,
        getMembersListBegin: false,
        getMembersListSuccess: true,
        getMembersListSuccessData: data,
        getMembersListFailure: false,
        getMembersListFailureData: null,
      };
    case GET_MEMBERS_LIST_FAILURE:
      return {
        ...state,
        getMembersListBegin: false,
        getMembersListSuccess: false,
        getMembersListSuccessData: null,
        getMembersListFailure: true,
        getMembersListFailureData: data,
      };
    case RESET_GET_MEMBERS_LIST:
      return {
        ...state,
        getMembersListBegin: false,
        getMembersListSuccess: false,
        getMembersListSuccessData: null,
        getMembersListFailure: false,
        getMembersListFailureData: null,
      };

    // create prompt group
    case CREATE_GROUP_BEGIN:
      return {
        ...state,
        createPromptGroupBegin: true,
        createPromptGroupSuccess: false,
        createPromptGroupSuccessData: null,
        createPromptGroupFailure: false,
        createPromptGroupFailureData: null,
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        createPromptGroupBegin: false,
        createPromptGroupSuccess: true,
        createPromptGroupSuccessData: data,
        createPromptGroupFailure: false,
        createPromptGroupFailureData: null,
      };
    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        createPromptGroupBegin: false,
        createPromptGroupSuccess: false,
        createPromptGroupSuccessData: null,
        createPromptGroupFailure: true,
        createPromptGroupFailureData: data,
      };
    case RESET_CREATE_GROUP:
      return {
        ...state,
        createPromptGroupBegin: false,
        createPromptGroupSuccess: false,
        createPromptGroupSuccessData: null,
        createPromptGroupFailure: false,
        createPromptGroupFailureData: null,
      };

    // view group prompt
    case VIEW_GROUP_PROMPT_BEGIN:
      return {
        ...state,
        viewGroupPromptBegin: true,
        viewGroupPromptSuccess: false,
        viewGroupPromptSuccessData: null,
        viewGroupPromptFailure: false,
        viewGroupPromptFailureData: null,
      };
    case VIEW_GROUP_PROMPT_SUCCESS:
      return {
        ...state,
        viewGroupPromptBegin: false,
        viewGroupPromptSuccess: true,
        viewGroupPromptSuccessData: data,
        viewGroupPromptFailure: false,
        viewGroupPromptFailureData: null,
      };
    case VIEW_GROUP_PROMPT_FAILURE:
      return {
        ...state,
        viewGroupPromptBegin: false,
        viewGroupPromptSuccess: false,
        viewGroupPromptSuccessData: null,
        viewGroupPromptFailure: true,
        viewGroupPromptFailureData: data,
      };
    case RESET_VIEW_GROUP_PROMPT:
      return {
        ...state,
        viewGroupPromptBegin: false,
        viewGroupPromptSuccess: false,
        viewGroupPromptSuccessData: null,
        viewGroupPromptFailure: false,
        viewGroupPromptFailureData: null,
      };

    // view group members
    case VIEW_GROUP_MEMBERS_BEGIN:
      return {
        ...state,
        viewGroupMembersBegin: true,
        viewGroupMembersSuccess: false,
        viewGroupMembersSuccessData: null,
        viewGroupMembersFailure: false,
        viewGroupMembersFailureData: null,
      };
    case VIEW_GROUP_MEMBERS_SUCCESS:
      return {
        ...state,
        viewGroupMembersBegin: false,
        viewGroupMembersSuccess: true,
        viewGroupMembersSuccessData: data,
        viewGroupMembersFailure: false,
        viewGroupMembersFailureData: null,
      };
    case VIEW_GROUP_MEMBERS_FAILURE:
      return {
        ...state,
        viewGroupMembersBegin: false,
        viewGroupMembersSuccess: false,
        viewGroupMembersSuccessData: null,
        viewGroupMembersFailure: true,
        viewGroupMembersFailureData: data,
      };
    case RESET_VIEW_GROUP_MEMBERS:
      return {
        ...state,
        viewGroupMembersBegin: false,
        viewGroupMembersSuccess: false,
        viewGroupMembersSuccessData: null,
        viewGroupMembersFailure: false,
        viewGroupMembersFailureData: null,
      };

    // delete group
    case DELETE_PROMPT_GROUP_BEGIN:
      return {
        ...state,
        deletePromptGroupBegin: true,
        deletePromptGroupSuccess: false,
        deletePromptGroupSuccessData: null,
        deletePromptGroupFailure: false,
        deletePromptGroupFailureData: null,
      };
    case DELETE_PROMPT_GROUP_SUCCESS:
      return {
        ...state,
        deletePromptGroupBegin: false,
        deletePromptGroupSuccess: true,
        deletePromptGroupSuccessData: data,
        deletePromptGroupFailure: false,
        deletePromptGroupFailureData: null,
      };
    case DELETE_PROMPT_GROUP_FAILURE:
      return {
        ...state,
        deletePromptGroupBegin: false,
        deletePromptGroupSuccess: false,
        deletePromptGroupSuccessData: null,
        deletePromptGroupFailure: true,
        deletePromptGroupFailureData: data,
      };
    case RESET_DELETE_PROMPT_GROUP:
      return {
        ...state,
        deletePromptGroupBegin: false,
        deletePromptGroupSuccess: false,
        deletePromptGroupSuccessData: null,
        deletePromptGroupFailure: false,
        deletePromptGroupFailureData: null,
      };

    // delete group member
    case DELETE_GROUP_MEMBER_BEGIN:
      return {
        ...state,
        deleteGroupMemberBegin: true,
        deleteGroupMemberSuccess: false,
        deleteGroupMemberSuccessData: null,
        deleteGroupMemberFailure: false,
        deleteGroupMemberFailureData: null,
      };
    case DELETE_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        deleteGroupMemberBegin: false,
        deleteGroupMemberSuccess: true,
        deleteGroupMemberSuccessData: data,
        deleteGroupMemberFailure: false,
        deleteGroupMemberFailureData: null,
      };
    case DELETE_GROUP_MEMBER_FAILURE:
      return {
        ...state,
        deleteGroupMemberBegin: false,
        deleteGroupMemberSuccess: false,
        deleteGroupMemberSuccessData: null,
        deleteGroupMemberFailure: true,
        deleteGroupMemberFailureData: data,
      };
    case RESET_DELETE_GROUP_MEMBER:
      return {
        ...state,
        deleteGroupMemberBegin: false,
        deleteGroupMemberSuccess: false,
        deleteGroupMemberSuccessData: null,
        deleteGroupMemberFailure: false,
        deleteGroupMemberFailureData: null,
      };

    // view schedule prompt
    case VIEW_SCHEDULE_PROMPT_BEGIN:
      return {
        ...state,
        viewSchedulePromptBegin: true,
        viewSchedulePromptSuccess: false,
        viewSchedulePromptSuccessData: null,
        viewSchedulePromptFailure: false,
        viewSchedulePromptFailureData: null,
      };
    case VIEW_SCHEDULE_PROMPT_SUCCESS:
      return {
        ...state,
        viewSchedulePromptBegin: false,
        viewSchedulePromptSuccess: true,
        viewSchedulePromptSuccessData: data,
        viewSchedulePromptFailure: false,
        viewSchedulePromptFailureData: null,
      };
    case VIEW_SCHEDULE_PROMPT_FAILURE:
      return {
        ...state,
        viewSchedulePromptBegin: false,
        viewSchedulePromptSuccess: false,
        viewSchedulePromptSuccessData: null,
        viewSchedulePromptFailure: true,
        viewSchedulePromptFailureData: data,
      };
    case RESET_VIEW_SCHEDULE_PROMPT:
      return {
        ...state,
        viewSchedulePromptBegin: false,
        viewSchedulePromptSuccess: false,
        viewSchedulePromptSuccessData: null,
        viewSchedulePromptFailure: false,
        viewSchedulePromptFailureData: null,
      };

    // edit group
    case EDIT_PROMPT_GROUP_BEGIN:
      return {
        ...state,
        editPromptGroupBegin: true,
        editPromptGroupSuccess: false,
        editPromptGroupSuccessData: null,
        editPromptGroupFailure: false,
        editPromptGroupFailureData: null,
      };
    case EDIT_PROMPT_GROUP_SUCCESS:
      return {
        ...state,
        editPromptGroupBegin: false,
        editPromptGroupSuccess: true,
        editPromptGroupSuccessData: data,
        editPromptGroupFailure: false,
        editPromptGroupFailureData: null,
      };
    case EDIT_PROMPT_GROUP_FAILURE:
      return {
        ...state,
        editPromptGroupBegin: false,
        editPromptGroupSuccess: false,
        editPromptGroupSuccessData: null,
        editPromptGroupFailure: true,
        editPromptGroupFailureData: data,
      };
    case RESET_EDIT_PROMPT_GROUP:
      return {
        ...state,
        editPromptGroupBegin: false,
        editPromptGroupSuccess: false,
        editPromptGroupSuccessData: null,
        editPromptGroupFailure: false,
        editPromptGroupFailureData: null,
      };

    // schedule prompt
    case SCHEDULE_PROMPT_BEGIN:
      return {
        ...state,
        schedulePromptBegin: true,
        schedulePromptSuccess: false,
        schedulePromptSuccessData: null,
        schedulePromptFailure: false,
        schedulePromptFailureData: null,
      };
    case SCHEDULE_PROMPT_SUCCESS:
      return {
        ...state,
        schedulePromptBegin: false,
        schedulePromptSuccess: true,
        schedulePromptSuccessData: data,
        schedulePromptFailure: false,
        schedulePromptFailureData: null,
      };
    case SCHEDULE_PROMPT_FAILURE:
      return {
        ...state,
        schedulePromptBegin: false,
        schedulePromptSuccess: false,
        schedulePromptSuccessData: null,
        schedulePromptFailure: true,
        schedulePromptFailureData: data,
      };
    case RESET_SCHEDULE_PROMPT:
      return {
        ...state,
        schedulePromptBegin: false,
        schedulePromptSuccess: false,
        schedulePromptSuccessData: null,
        schedulePromptFailure: false,
        schedulePromptFailureData: null,
      };

    // reSchedule prompt
    case RESCHEDULE_PROMPT_BEGIN:
      return {
        ...state,
        reSchedulePromptBegin: true,
        reSchedulePromptSuccess: false,
        reSchedulePromptSuccessData: null,
        reSchedulePromptFailure: false,
        reSchedulePromptFailureData: null,
      };
    case RESCHEDULE_PROMPT_SUCCESS:
      return {
        ...state,
        reSchedulePromptBegin: false,
        reSchedulePromptSuccess: true,
        reSchedulePromptSuccessData: data,
        reSchedulePromptFailure: false,
        reSchedulePromptFailureData: null,
      };
    case RESCHEDULE_PROMPT_FAILURE:
      return {
        ...state,
        reSchedulePromptBegin: false,
        reSchedulePromptSuccess: false,
        reSchedulePromptSuccessData: null,
        reSchedulePromptFailure: true,
        reSchedulePromptFailureData: data,
      };
    case RESET_RESCHEDULE_PROMPT:
      return {
        ...state,
        reSchedulePromptBegin: false,
        reSchedulePromptSuccess: false,
        reSchedulePromptSuccessData: null,
        reSchedulePromptFailure: false,
        reSchedulePromptFailureData: null,
      };

    // add member
    case ADD_MEMBER_TO_GROUP_BEGIN:
      return {
        ...state,
        addMemberToGroupBegin: true,
        addMemberToGroupSuccess: false,
        addMemberToGroupSuccessData: null,
        addMemberToGroupFailure: false,
        addMemberToGroupFailureData: null,
      };
    case ADD_MEMBER_TO_GROUP_SUCCESS:
      return {
        ...state,
        addMemberToGroupBegin: false,
        addMemberToGroupSuccess: true,
        addMemberToGroupSuccessData: data,
        addMemberToGroupFailure: false,
        addMemberToGroupFailureData: null,
      };
    case ADD_MEMBER_TO_GROUP_FAILURE:
      return {
        ...state,
        addMemberToGroupBegin: false,
        addMemberToGroupSuccess: false,
        addMemberToGroupSuccessData: null,
        addMemberToGroupFailure: true,
        addMemberToGroupFailureData: data,
      };
    case RESET_ADD_MEMBER_TO_GROUP:
      return {
        ...state,
        addMemberToGroupBegin: false,
        addMemberToGroupSuccess: false,
        addMemberToGroupSuccessData: null,
        addMemberToGroupFailure: false,
        addMemberToGroupFailureData: null,
      };

    // view prompt response
    case VIEW_PROMPT_RESPONSE_BEGIN:
      return {
        ...state,
        viewPromptResponseBegin: true,
        viewPromptResponseSuccess: false,
        viewPromptResponseSuccessData: null,
        viewPromptResponseFailure: false,
        viewPromptResponseFailureData: null,
      };
    case VIEW_PROMPT_RESPONSE_SUCCESS:
      return {
        ...state,
        viewPromptResponseBegin: false,
        viewPromptResponseSuccess: true,
        viewPromptResponseSuccessData: data,
        viewPromptResponseFailure: false,
        viewPromptResponseFailureData: null,
      };
    case VIEW_PROMPT_RESPONSE_FAILURE:
      return {
        ...state,
        viewPromptResponseBegin: false,
        viewPromptResponseSuccess: false,
        viewPromptResponseSuccessData: null,
        viewPromptResponseFailure: true,
        viewPromptResponseFailureData: data,
      };
    case RESET_VIEW_PROMPT_RESPONSE:
      return {
        ...state,
        viewPromptResponseBegin: false,
        viewPromptResponseSuccess: false,
        viewPromptResponseSuccessData: null,
        viewPromptResponseFailure: false,
        viewPromptResponseFailureData: null,
      };

    // view group details
    case VIEW_GROUP_DETAILS_BEGIN:
      return {
        ...state,
        viewGroupDetailsBegin: true,
        viewGroupDetailsSuccess: false,
        viewGroupDetailsSuccessData: null,
        viewGroupDetailsFailure: false,
        viewGroupDetailsFailureData: null,
      };
    case VIEW_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        viewGroupDetailsBegin: false,
        viewGroupDetailsSuccess: true,
        viewGroupDetailsSuccessData: data,
        viewGroupDetailsFailure: false,
        viewGroupDetailsFailureData: null,
      };
    case VIEW_GROUP_DETAILS_FAILURE:
      return {
        ...state,
        viewGroupDetailsBegin: false,
        viewGroupDetailsSuccess: false,
        viewGroupDetailsSuccessData: null,
        viewGroupDetailsFailure: true,
        viewGroupDetailsFailureData: data,
      };
    case RESET_VIEW_GROUP_DETAILS:
      return {
        ...state,
        viewGroupDetailsBegin: false,
        viewGroupDetailsSuccess: false,
        viewGroupDetailsSuccessData: null,
        viewGroupDetailsFailure: false,
        viewGroupDetailsFailureData: null,
      };
    default:
      return state;
  }
};
