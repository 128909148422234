// Mentor available time slot click
export const onMentorAvailableTimeSlotClick = (
  id,
  setFormData,
  selectedTimeSlot,
  setSelectedTimeSlot
) => {
  setFormData({
    fromTime: "",
    toTime: "",
  });
  if (id === selectedTimeSlot) {
    setSelectedTimeSlot(false);
  } else {
    setSelectedTimeSlot(id);
  }
};

// get differnce between 24hr format time
export const getDifferenceBetweenTwoTimes = (fromTime, toTime) => {

  console.log(fromTime);
  console.log(toTime);

  if (fromTime === toTime) {
    return 0;
  } else {
    if (toTime.split(":")[1] > fromTime.split(":")[1]) {
      if (toTime.split(":")[0] === fromTime.split(":")[0]) {
        return toTime.split(":")[1] - fromTime.split(":")[1];
      } else {
        return (
          (toTime.split(":")[0] - fromTime.split(":")[0]) * 60 +
          (toTime.split(":")[1] - fromTime.split(":")[1])
        );
      }
    } else if (toTime.split(":")[1] < fromTime.split(":")[1]) {
      if (
        toTime.split(":")[0] >
        (fromTime.split(":")[0].length === 1
          ? `0${fromTime.split(":")[0]}`
          : fromTime.split(":")[0])
      ) {
        let hours = toTime.split(":")[0] - fromTime.split(":")[0];
        return hours > 1
          ? hours * 60
          : 0 +
              (60 - Number(fromTime.split(":")[1])) +
              Number(toTime.split(":")[1]);
      }
    } else if (toTime.split(":")[1] === fromTime.split(":")[1]) {
      if (
        toTime.split(":")[0] >
        (fromTime.split(":")[0].length === 1
          ? `0${fromTime.split(":")[0]}`
          : fromTime.split(":")[0])
      ) {
        return (toTime.split(":")[0] - fromTime.split(":")[0]) * 60;
      }
    }
  }
};
