import React, { useState } from "react";

import PageData from "../../utilities/content/pages.json";
import { scrollToTop, TextArea } from "../../utilities";
import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";

const MentorPersonalStatement = ({
  changeToNextPage,
  onBackButtonClick,
  continueButtonloader,
  mentorRegisterData,
}) => {
  window.location.hash = 'step5';
  const [formError, setFormError] = useState({});
  const [mentorStatement, setMentorStatement] = useState(
    mentorRegisterData.Statement ? mentorRegisterData.Statement : ""
  );
  const { heading, description } =
    PageData.mentorRegistrationFormData.personalStatementPage;

  const customFormValidation = () => {
    const validationErrors = {};
    if (mentorStatement?.trim() === "") {
      validationErrors.mentorStatement = "Statement is required.";
    } else if (mentorStatement?.trim() !== "" && mentorStatement.length < 4) {
      validationErrors.mentorStatement = "Please give your valuable statement.";
    }
    return validationErrors;
  };

  const onContinueButtonClick = (e) => {
    e.preventDefault();

    const validationErrors = customFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      changeToNextPage("professional_reference", {
        Statement: mentorStatement,
      });
      scrollToTop("50", "16.66");
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const handleBackButtonClick = () => {
    onBackButtonClick("area_of_focus_page");
    scrollToTop("50", "16.66");
  };

  const handleInputOnchange = (e) => {
    let sm = formError;
    delete sm[[e.target.name]];
    setMentorStatement(e.target.value);
  };

  return (
    <section className="homepageWidth md:w-70% mx-auto pt-4 mb-12 ">
      <form id="mentor_form" onSubmit={(e) => onContinueButtonClick(e)}>
        <h1
          className="font-semibold text-3xl text-center sm:mt-8 mt-6"
          style={{ color: "#093B5D" }}
        >
          {heading}
        </h1>
        <p className="mt-8 font-medium lg:text-2xl sm:text-xl text-lg">
          {description}
        </p>
        <TextArea
          placeholder="Enter your statement"
          name="mentorStatement"
          value={mentorStatement}
          onChange={(e) => handleInputOnchange(e)}
          classNames="mt-8 mb-0"
          setValue={setMentorStatement}
          errorMessage={formError.mentorStatement}
          limit={10000}
        />
        <div className="w-96% md:w-1/2 mx-auto my-12">
          <FormBackAndContinueButton
            onBackButtonClick={() => handleBackButtonClick()}
            actionBegin={continueButtonloader}
          />
        </div>
      </form>
    </section>
  );
};

export default MentorPersonalStatement;
