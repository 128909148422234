import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";

import Navbar from "../components/pageComponents/Navbar";
import MentorRegisterLandingSun from "../components/pageComponents/MentorRegisterForms/MentorRegisterLandingSun";
import MentorPersonalInformation from "../components/pageComponents/MentorRegisterForms/MentorPersonalInformation";
import MentorProfessionalInformation from "../components/pageComponents/MentorRegisterForms/MentorProfessionalInfoForm/MentorProfessionalInformation";
import MentorRegistrationFeatures from "../components/pageComponents/MentorRegisterForms/MentorRegistrationFeatures";
import MentorAreaOfFocus from "../components/pageComponents/MentorRegisterForms/MentorAreaOfFocus";
import MentorPersonalStatement from "../components/pageComponents/MentorRegisterForms/MentorPersonalStatement";
import MentorProfessionalReference from "../components/pageComponents/MentorRegisterForms/MentorProfessionalReference";
import MentorThankYouPage from "../components/pageComponents/MentorRegisterForms/MentorThankYouPage";
import SeoData from "../components/utilities/seo/seoData.json";
import { submitMentorRegistrationForm } from "../AppRedux/actions/MentorRegistration";
import {
  ReactHelmet,
  ProgressLoader,
  progressLoaderFunction,
  alertUserForReload,
  SunFooter,
} from "../components/utilities";

const MentorRegistration = () => {
  const dispatch = useDispatch(),
    [pageNumber, setPageNumber] = useState(1),
    [page, setPage] = useState("personal_information_page"),
    [mentorRegisterData, setMentorRegisterData] = useState({}),
    [continueButtonloader, setContinueButtonloader] = useState(false),
    [backButtonLoader, setBackButtonLoader] = useState(false);
  useEffect(() => {
    progressLoaderFunction();
  });

  useEffect(() => {
    if (page !== "personal_information_page" && page !== "thank_you_page") {
      window.addEventListener("beforeunload", alertUserForReload);
    }
    return () => {
      if (page !== "personal_information_page" && page !== "thank_you_page") {
        window.removeEventListener("beforeunload", alertUserForReload);
      }
    };
  }, [page]);

  const onSubmitEachForm = (nextPage, pageData = {}) => {
    setContinueButtonloader(true);
    setMentorRegisterData({ ...mentorRegisterData, ...pageData });
    setPage(nextPage);
    setContinueButtonloader(false);
    setPageNumber(pageNumber + 1);
  };

  const onBackButtonClick = (nextPage) => {
    setBackButtonLoader(true);
    setPage(nextPage);
    setBackButtonLoader(false);
    setPageNumber(pageNumber - 1);
  };

  const onSubmitMentorRegisterData = (pageData) => {
    let universityList = Object.values(mentorRegisterData.universities),
      degreeList = Object.values(mentorRegisterData.degrees),
      coachingProgramList = Object.values(mentorRegisterData.coachingPrograms),
      certificationList = Object.values(mentorRegisterData.certifications),
      referenceNames = Object.values(pageData.names),
      referenceEmails = Object.values(pageData.emails),
      referencePhones = Object.values(pageData.phones),
      formData = new FormData();

    const university = Array(universityList.length)
        .fill()
        .map((_, index) => ({
          university: universityList[index],
          program: degreeList[index],
        })),
      certification = Array(certificationList.length)
        .fill()
        .map((_, index) => ({
          certification: certificationList[index],
        })),
      coachingProgram = Array(coachingProgramList.length)
        .fill()
        .map((_, index) => ({
          program: coachingProgramList[index],
        })),
      references = Array(referenceNames.length)
        .fill()
        .map((_, index) => ({
          name: referenceNames[index],
          email: referenceEmails[index],
          phone: referencePhones[index],
        })),
      professionalInfo = {
        linkedin: mentorRegisterData.mentor_linkedin,
        website: mentorRegisterData.mentor_website,
      };

    formData.append("firstName", mentorRegisterData.firstName);
    formData.append("lastName", mentorRegisterData.lastName);
    formData.append("email", mentorRegisterData.email);
    formData.append("phone", mentorRegisterData.phone);
    formData.append("address1", mentorRegisterData.address1);
    formData.append("address2", mentorRegisterData.address2);
    formData.append("country", mentorRegisterData.country);
    formData.append("state", mentorRegisterData.state);
    formData.append("city", mentorRegisterData.city);
    formData.append("zip", mentorRegisterData.zip);
    formData.append("university", JSON.stringify(university));
    formData.append("certification", JSON.stringify(certification));
    formData.append("coachingProgram", JSON.stringify(coachingProgram));
    formData.append("professionalInfo", JSON.stringify(professionalInfo));
    formData.append("resume", mentorRegisterData.mentor_resume);
    formData.append("focusArea", JSON.stringify(mentorRegisterData.newOptions));
    formData.append("statement", mentorRegisterData.Statement);
    formData.append("references", JSON.stringify(references));

    dispatch(submitMentorRegistrationForm(formData));
  };

  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.mentorRegistration} />
          <ProgressLoader />
          <Navbar
            whiteNavbar={true}
            fixedNavbar={false}
            onlyTopBar={true}
            noShadow={true}
          />
          {page === "thank_you_page" ? (
            <MentorThankYouPage />
          ) : (
            <div className="min-h-90vh text-brandDarkBlue">
              <MentorRegisterLandingSun pageNumber={pageNumber} />
              {page === "personal_information_page" ? (
                <MentorPersonalInformation
                  changeToNextPage={onSubmitEachForm}
                  mentorRegisterData={mentorRegisterData}
                  continueButtonloader={continueButtonloader}
                />
              ) : page === "mentor_registration_features" ? (
                <MentorRegistrationFeatures
                  changeToNextPage={onSubmitEachForm}
                  continueButtonloader={continueButtonloader}
                  onBackButtonClick={onBackButtonClick}
                  backButtonLoader={backButtonLoader}
                />
              ) : page === "professional_information_page" ? (
                <MentorProfessionalInformation
                  changeToNextPage={onSubmitEachForm}
                  mentorRegisterData={mentorRegisterData}
                  continueButtonloader={continueButtonloader}
                  onBackButtonClick={onBackButtonClick}
                  backButtonLoader={backButtonLoader}
                />
              ) : page === "area_of_focus_page" ? (
                <MentorAreaOfFocus
                  changeToNextPage={onSubmitEachForm}
                  mentorRegisterData={mentorRegisterData}
                  continueButtonloader={continueButtonloader}
                  onBackButtonClick={onBackButtonClick}
                  backButtonLoader={backButtonLoader}
                />
              ) : page === "personal_statement_page" ? (
                <MentorPersonalStatement
                  changeToNextPage={onSubmitEachForm}
                  mentorRegisterData={mentorRegisterData}
                  continueButtonloader={continueButtonloader}
                  onBackButtonClick={onBackButtonClick}
                />
              ) : (
                page === "professional_reference" && (
                  <MentorProfessionalReference
                    changeToNextPage={onSubmitEachForm}
                    onSubmitMentorRegisterData={onSubmitMentorRegisterData}
                    backButtonLoader={backButtonLoader}
                    onBackButtonClick={onBackButtonClick}
                  />
                )
              )}
            </div>
          )}
          <SunFooter />
        </>
      ),
      []
    );

  return <Content />;
};

export default MentorRegistration;
