import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { MobileResponsiveAccordion } from "../../utilities/svg";
import { url } from "../../utilities/generalItems";
import pageData from "../../utilities/content/memberDashboard.json";
import MemberProfileCoverPicture from "../../../assets/Images/MemberProfileBackBg.jpg";
import Modal from "../../utilities/modals/DashboardModal";
import SuccessModal from "../../utilities/modals/SuccessModal";
import { getMemberDashboardDetails } from "../../../AppRedux/actions/MemberDashboard";
import { MentorRatingContainer } from "../dashboardGeneralItems";
import WarningModal from "../../utilities/modals/WarningModal";
import {
  infoToast,
  UserAvatar,
  deafultToast,
  storeMemberDashboardDetailsInLocalStorage,
  Scaleloader,
  getMemberDashboardDataFromLocalStorage,
} from "../../utilities";

const MemberLeftSideProfileCard = () => {
  const dispatch = useDispatch(),
    [dashboardData, setDashboardData] = useState(null),
    { member, mentor } = getMemberDashboardDataFromLocalStorage(),
    [success, setSuccess] = useState(false),
    [showmentor, setShowmentor] = useState(false),
    [sessionId, setSessionId] = useState(""),
    { getMemberDashboardDetailsSuccessData } = useSelector(
      ({ memberDashboard }) => memberDashboard
    ),
    { getAppResourcesSuccessData } = useSelector(
      ({ homeReducer }) => homeReducer
    );

  useEffect(() => {
    if (showmentor) {
      if (!document.getElementById("cometchat__widget")) {
        // comet chat initialising for comet chat widget
        window.CometChatWidget.init({
          appID: getAppResourcesSuccessData?.cometAppId,
          appRegion: "us",
          authKey: getAppResourcesSuccessData?.cometAuthKey,
        }).then(
          (response) => {
            console.log("Initialization completed successfully");
            //You can now call login function.
            window.CometChatWidget.login({
              uid: member?.User.uid,
            }).then(
              (response) => {
                // window.CometChatWidget.launch({
                //   widgetID: "5e724e26-111b-42bb-8610-4e255a89edad",
                //   docked: "true",
                //   alignment: "left", //left or right
                //   target: "#cometchat",
                //   roundedCorners: "true",
                //   height: "400px",
                //   width: "500px",
                //   defaultID: mentor?.UserMentor?.User.uid, //default UID (user) or GUID (group) to show,
                //   defaultType: "user", //user or group
                // });
              },
              (error) => {
                console.log("User login failed with error:", error);
                //Check the reason for error and take appropriate action.
              }
            );
          },
          (error) => {
            console.log("Initialization failed with error:", error);
            //Check the reason for error and take appropriate action.
          }
        );
      }
    }
  }, [
    member?.User.uid,
    mentor?.UserMentor?.User?.uid,
    getAppResourcesSuccessData,
    showmentor,
  ]);

  useEffect(() => {
    dispatch(getMemberDashboardDetails());
  }, [dispatch]);
  useEffect(() => {
    if (getMemberDashboardDetailsSuccessData) {
      setDashboardData(getMemberDashboardDetailsSuccessData.data);
      setShowmentor(
        getMemberDashboardDetailsSuccessData?.data?.minutesPurchased
      );
      storeMemberDashboardDetailsInLocalStorage(
        getMemberDashboardDetailsSuccessData?.data
      );
    }
  }, [getMemberDashboardDetailsSuccessData]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("purchase_minutes") && query.get("session_id")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
      dispatch(getMemberDashboardDetails());
    }
    if (query.get("purchase_minutes") === "false") {
      infoToast("Purchased minutes canceled");
      setSuccess(false);
    }
  }, [dispatch]);

  return (
    <div className="rounded-10px shadow-memberDashboard bg-white text-brandDarkBlue member-leftside-profile-card min-h-80vh ">
      {dashboardData ? (
        <MemberProfileData dashboardData={dashboardData} />
      ) : (
        <Scaleloader />
      )}

      {success && sessionId !== "" ? (
        <SuccessModal content={pageData.purchaseMinutesSuccessModalData} />
      ) : (
        ""
      )}
    </div>
  );
};

export default MemberLeftSideProfileCard;

const MemberProfileData = ({ dashboardData }) => {

  console.log(getMemberDashboardDataFromLocalStorage());

  const navigate = useNavigate();
  const [showmore, setShowmore] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [showPurchaseMinutesConfirmModal, setshowPurchaseMinutesConfirmModal] =
    useState(false);
  const {
    member: { minutes },
    assessments,
    mentor,
    rating,
  } = dashboardData;
  
  const { User: mentorUser, statement } = mentor?.UserMentor || {};
  const {
    member: {
      User: { coverPhoto, profilePhoto, firstName, lastName },
    },
  } = getMemberDashboardDataFromLocalStorage();

  const warningModalData = {
    heading: "⚠️ Please purchase minutes for scheduling mentor call",
    buttonText: "Ok",
    confirmClick: () => {
      setWarningModal(false);
      setshowPurchaseMinutesConfirmModal(true);
    },
  };

  const handlePurchaseMinutesClick = () => {
    if (minutes > 0) {
      deafultToast(" ⚠️ Please use existing minutes");
    } else {
      setshowPurchaseMinutesConfirmModal(true);
    }
  };
  const handleScheduleMentorCallClick = () => {
    if (minutes > 0) {
      navigate("/member/dashboard/my-calendar");
    } else {
      // deafultToast(" ⚠️ Please purchase minutes for scheduling mentor call");
      setWarningModal(true);
      // setshowPurchaseMinutesConfirmModal(true);
    }
  };

  // member shortest three goals
  const getMemberThreeGoalsLessWords = () => {
    let getsmallSectance = (a, b) => (a.length <= b.length ? a : b),
      arr = assessments.map(
        (assessment) => assessment.AssessmentQuestionOption.option
      ),
      firstGoal,
      secondGoal,
      thirdGoal;

    firstGoal = arr.reduce(getsmallSectance);
    arr = arr.filter((option) => option !== firstGoal);
    secondGoal = arr.reduce(getsmallSectance);
    arr = arr.filter((option) => option !== secondGoal);
    thirdGoal = arr.reduce(getsmallSectance);

    return {
      changeToOnebyOne:
        firstGoal.length > 10 ||
        secondGoal.length > 10 ||
        thirdGoal.length > 10,
      goals: [
        {
          goal: firstGoal,
          color: "#FF7D7D",
        },
        {
          goal: secondGoal,
          color: "#7AE49E",
        },
        {
          goal: thirdGoal,
          color: "#7AD8E4",
        },
      ],
    };
  };

  return (
    <>
      <div className="relative">
        {coverPhoto ? (
          <img
            src={`${url}/file?path=${coverPhoto}`}
            alt=""
            className="rounded-t-10px max-h-32 w-full"
          />
        ) : (
          <img
            className="rounded-t-10px w-full"
            src={MemberProfileCoverPicture}
            alt=""
          />
        )}

        <UserAvatar
          name={firstName}
          classNames="absolute left-0 bottom-0 mx-6 transform translate-y-1/2 w-9 h-9 500px:w-14 500px:h-14 500px:text-2xl"
          profilePhoto={profilePhoto}
          imgClassNames="relative -mt-8 mx-auto md:absolute left-0 bottom-0 md:mx-6 md:transform md:translate-y-1/2 h-16 w-16"
        />
      </div>
      <div className=" px-4 w-full">
        <p className="text-center md:text-left md:pt-9 font-semibold text-lg ">
          {firstName} {lastName}
        </p>
        {/* Dynamically rendering this content  */}

        <div className="mt-2 font-semibold text-xs lg:text-sm text-darkBlue ">
          <span className="font-bold text-lg"> Welcome {firstName}! </span>
          <br />
         
         <div className="font-bold text-lg mt-4"> Your Membership Includes: </div>
 
         <ul className="list-disc pl-4 mb-4 font-normal">
          <li>The Merity Reflective Practice</li>
          <li>Personalized Coaching</li>
          <li>Community Support</li>
         </ul>

         <div className="mb-2 font-bold text-lg">
          Quick Start Guide:
         </div>

        <div className="mb-2 font-normal"> <span className="font-bold">Step 1:</span> Navigate to "My Sessions" to start your Learning Journey.</div>
        <div className="mb-2 font-normal"> <span className="font-bold">Step 2:</span> Schedule your complimentary 30-minute session with your coach.</div>
        <div className="mb-2 font-normal"> <span className="font-bold">Step 3:</span> With your coach's support, continue the practice to find strategies and insights that overcome challenges and accelerate development.</div>
      </div>

        <div className="border-b border-solid border-loginCards mt-4 mb-2" />
        
        {showmore ? (
          <div>
            <div className="animate-navbar">
              <h6 className=" text-sm font-semibold">Coaching Minutes</h6>
              {minutes === null ? (
                <p className="text-xl  pt-1 ">
                  <strong>0</strong> mins
                </p>
              ) : (
                <p className="text-xl pt-1 ">
                  <strong>{minutes}</strong> mins
                </p>
              )}

              <div className="border-b border-solid my-3 border-loginCards" />
              {mentor === null ? (
                ""
              ) : (
                <>
                  {dashboardData?.minutesPurchased && (
                    <>
                      {" "}
                      <p className="text-sm font-semibold">Your coach</p>
                      <div
                        className={`flex mt-3 ${rating?.rows.length === 0 ? "items-center" : ""
                          }`}
                      >
                        <UserAvatar
                          name={mentorUser.firstName}
                          classNames="w-9 h-9 500px:w-12 500px:h-12"
                          profilePhoto={mentorUser.profilePhoto}
                          imgClassNames="w-9 h-9 500px:w-12 500px:h-12"
                        />

                        <div className="mx-2 ">
                          <h6 className="font-semibold text-sm ">
                            {mentorUser.firstName} {mentorUser.lastName}
                          </h6>
                          {rating?.rows.length !== 0 && (
                            <MentorRatingContainer
                              rating={rating.rows}
                              count={rating.count}
                            />
                          )}
                        </div>
                      </div>
                      <h6 className="mt-4 font-semibold text-xs lg:text-sm text-brandDarkBlue hidden">
                        {statement}
                      </h6>
                      <div className="border-b border-solid mt-9 mb-6 border-loginCards" />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <h6 className=" text-sm font-semibold">Coaching Minutes</h6>
            {minutes === null ? (
              <p className="text-xl  pt-1 ">
                <strong>0</strong> mins
              </p>
            ) : (
              <p className="text-xl pt-1 ">
                <strong>{minutes}</strong> mins
              </p>
            )}

            <div className="border-b border-solid my-3 border-loginCards" />
            {mentor === null ? (
              ""
            ) : (
              <div className="hidden md:block">
                {dashboardData?.minutesPurchased && (
                  <>
                    {" "}
                    <p className="text-sm font-semibold">Your coach</p>
                    <div
                      className={`flex mt-3 ${rating?.rows.length === 0 ? "items-center" : ""
                        }`}
                    >
                      <UserAvatar
                        name={mentorUser.firstName}
                        classNames="w-9 h-9 500px:w-12 500px:h-12"
                        profilePhoto={mentorUser.profilePhoto}
                        imgClassNames="w-9 h-9 500px:w-12 500px:h-12"
                      />

                      <div className="mx-2 ">
                        <h6 className="font-semibold text-sm ">
                          {mentorUser.firstName} {mentorUser.lastName}
                        </h6>
                        {rating?.rows.length !== 0 && (
                          <MentorRatingContainer
                            rating={rating.rows}
                            count={rating.count}
                          />
                        )}
                      </div>
                    </div>
                    <h6 className="mt-4 font-semibold text-xs lg:text-sm text-brandDarkBlue hidden">
                      {statement}
                    </h6>
                    <div className="border-b border-solid mt-9 mb-6 border-loginCards" />
                  </>
                )}
              </div>
            )}
          </>
        )}
        <div
          onClick={() => setShowmore(!showmore)}
          className="flex justify-center items-center space-x-2 my-4 font-semibold text-base md:hidden text-center cursor-pointer text-brandBlue"
        >
          <p> View {showmore ? " Less" : " More"}</p>
          <MobileResponsiveAccordion />
        </div>
        <div className=" w-full mx-auto pb-16">
          <button
            onClick={() => handlePurchaseMinutesClick()}
            className={`memberDashboardButton mb-5 text-brandBlue border-solid border border-brandBlue activeState buttonHover ${minutes > 0 ? "cursor-not-allowed" : ""
              }`}
          >
            Purchase Coaching Minutes
          </button>
          <button
            onClick={() => handleScheduleMentorCallClick()}
            className="memberDashboardButton flexCenter text-white bg-brandBlue activeState"
          >
            Schedule Coaching Session
          </button>
        </div>
      </div>
      {showPurchaseMinutesConfirmModal && (
        <Modal
          state={showPurchaseMinutesConfirmModal}
          setState={setshowPurchaseMinutesConfirmModal}
        />
      )}
      {warningModal && (
        <WarningModal
          {...warningModalData}
          state={warningModal}
          setState={setWarningModal}
        />
      )}
    </>
  );
};
