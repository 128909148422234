export const SUBMIT_MEMBER_REGISTRATION_FORM_BEGIN =
  "SUBMIT_MEMBER_REGISTRATION_FORM_BEGIN";
export const SUBMIT_MEMBER_REGISTRATION_FORM_SUCCESS =
  "SUBMIT_MEMBER_REGISTRATION_FORM_SUCCESS";
export const SUBMIT_MEMBER_REGISTRATION_FORM_FAILURE =
  "SUBMIT_MEMBER_REGISTRATION_FORM_FAILURE";
export const RESET_SUBMIT_MEMBER_REGISTRATION_FORM =
  "RESET_SUBMIT_MEMBER_REGISTRATION_FORM";

export const RESEND_USER_REGISTER_OTP_BEGIN = "RESEND_USER_REGISTER_OTP_BEGIN";
export const RESEND_USER_REGISTER_OTP_SUCCESS =
  "RESEND_USER_REGISTER_OTP_SUCCESS";
export const RESEND_USER_REGISTER_OTP_FAILURE =
  "RESEND_USER_REGISTER_OTP_FAILURE";
export const RESET_RESEND_USER_REGISTER_OTP = "RESET_RESEND_USER_REGISTER_OTP";

export const VERIFY_USER_REGISTER_OTP_BEGIN = "VERIFY_USER_REGISTER_OTP_BEGIN";
export const VERIFY_USER_REGISTER_OTP_SUCCESS =
  "VERIFY_USER_REGISTER_OTP_SUCCESS";
export const VERIFY_USER_REGISTER_OTP_FAILURE =
  "VERIFY_USER_REGISTER_OTP_FAILURE";
export const RESET_VERIFY_USER_REGISTER_OTP = "RESET_VERIFY_USER_REGISTER_OTP";

export const CHECK_USER_REGISTER_EMAIL_BEGIN =
  "CHECK_USER_REGISTER_EMAIL_BEGIN";
export const CHECK_USER_REGISTER_EMAIL_SUCCESS =
  "CHECK_USER_REGISTER_EMAIL_SUCCESS";
export const CHECK_USER_REGISTER_EMAIL_FAILURE =
  "CHECK_USER_REGISTER_EMAIL_FAILURE";
export const RESET_CHECK_USER_REGISTER_EMAIL =
  "RESET_CHECK_USER_REGISTER_EMAIL";

export const SHOW_POP_UP = "SHOW_POP_UP";
export const UNSHOW_POP_UP = "UNSHOW_POP_UP";
