import React, { useMemo, useEffect } from "react";
import Navbar from "../components/pageComponents/Navbar";
import {
  ProgressLoader,
  progressLoaderFunction,
} from "../components/utilities";

const Blog = () => {
  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ProgressLoader />
          <Navbar whiteNavbar={true} />
          <div className="w-full h-96  bg-gray-500 flex justify-center items-center">
            <h1 className="text-6xl text-white">Blog Page</h1>
          </div>
        </>
      ),
      []
    );

  return <Content />;
};

export default Blog;
