import {
  SUBMIT_MENTOR_ONBOARD_FORM_BEGIN,
  SUBMIT_MENTOR_ONBOARD_FORM_SUCCESS,
  SUBMIT_MENTOR_ONBOARD_FORM_FAILURE,
  RESET_SUBMIT_MENTOR_ONBOARD_FORM,
} from "../constants/MentorLogin";

import { httpApp } from "../../axios/httpUser";

export function submitMentorOnboardForm(token, data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MENTOR_ONBOARD_FORM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/mentor/onboard`, data, {
        headers: {
          Authorization: token,
        },
      });

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MENTOR_ONBOARD_FORM_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MENTOR_ONBOARD_FORM,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MENTOR_ONBOARD_FORM_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SUBMIT_MENTOR_ONBOARD_FORM,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
