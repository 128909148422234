import {
  SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_BEGIN,
  SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_SUCCESS,
  SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_FAILURE,
  RESET_MENTOR_ADD_CALENDAR_PREFERENCE,
  GET_MENTOR_CALENDAR_PREFERENCE_BEGIN,
  GET_MENTOR_CALENDAR_PREFERENCE_SUCCESS,
  GET_MENTOR_CALENDAR_PREFERENCE_FAILURE,
  RESET_GET_MENTOR_CALENDAR_PREFERENCE,
  UPDATE_MENTOR_AVAILABLE_SLOTS_BEGIN,
  UPDATE_MENTOR_AVAILABLE_SLOTS_SUCCESS,
  UPDATE_MENTOR_AVAILABLE_SLOTS_FAILURE,
  RESET_UPDATE_MENTOR_AVAILABLE_SLOTS,
  DELETE_MENTOR_AVAILABLE_SLOTS_BEGIN,
  DELETE_MENTOR_AVAILABLE_SLOTS_SUCCESS,
  DELETE_MENTOR_AVAILABLE_SLOTS_FAILURE,
  RESET_DELETE_MENTOR_AVAILABLE_SLOTS,
  VIEW_MENTOR_MEETING_BEGIN,
  VIEW_MENTOR_MEETING_SUCCESS,
  VIEW_MENTOR_MEETING_FAILURE,
  RESET_VIEW_MENTOR_MEETING,
  GET_MENTOR_MEETING_BEGIN,
  GET_MENTOR_MEETING_SUCCESS,
  GET_MENTOR_MEETING_FAILURE,
  RESET_GET_MENTOR_MEETING,
} from "../constants/MentorCalendar";

import { httpApp } from "../../axios/httpUser";

//submitting calendar preference
export function submitMentorCalendarPreference(data) {

  return (dispatch) => {
    dispatch({
      type: SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/mentor/calendar`, data);
      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_MENTOR_ADD_CALENDAR_PREFERENCE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_MENTOR_ADD_CALENDAR_PREFERENCE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get calendar preference
export function getMentorCalendarPreference(month, year) {
  return (dispatch) => {
    dispatch({
      type: GET_MENTOR_CALENDAR_PREFERENCE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/mentor/calendar?month=${month}&year=${year}`
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTOR_CALENDAR_PREFERENCE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_CALENDAR_PREFERENCE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTOR_CALENDAR_PREFERENCE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_CALENDAR_PREFERENCE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//update calendar preference
export function updateMentorAvailableSlots(data) {

  return (dispatch) => {
    dispatch({
      type: UPDATE_MENTOR_AVAILABLE_SLOTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/mentor/calendar`, data);
      doRequest.then(
        (res) => {
          dispatch({
            type: UPDATE_MENTOR_AVAILABLE_SLOTS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_UPDATE_MENTOR_AVAILABLE_SLOTS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: UPDATE_MENTOR_AVAILABLE_SLOTS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_UPDATE_MENTOR_AVAILABLE_SLOTS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//delete calendar preference by date
export function deleteMentorAvailableSlots(data) {
  return (dispatch) => {
    dispatch({
      type: DELETE_MENTOR_AVAILABLE_SLOTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/mentor/calendar/multiple`, {
        headers: {},
        data,
      });
      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_MENTOR_AVAILABLE_SLOTS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_MENTOR_AVAILABLE_SLOTS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_MENTOR_AVAILABLE_SLOTS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_MENTOR_AVAILABLE_SLOTS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//view mentor meeting
export function viewMentorMeeting(pageNumber = 1, status = 1, memberId) {
  return (dispatch) => {
    dispatch({
      type: VIEW_MENTOR_MEETING_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `meeting/mentor?status=${status}&memberId=${memberId}&page=${pageNumber}&limit=8&orderBy=updatedAt&order=desc`
      );
      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_MENTOR_MEETING_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_MENTOR_MEETING,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_MENTOR_MEETING_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_MENTOR_MEETING,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get mentor meeting list
export function getMentorMeeting(status) {
  return (dispatch) => {
    dispatch({
      type: GET_MENTOR_MEETING_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`meeting/mentor/list?status=${status}`);
      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MENTOR_MEETING_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_MEETING,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MENTOR_MEETING_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MENTOR_MEETING,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
