import React from "react";
import { convertFromRaw, EditorState, Editor } from "draft-js";

import ModalOuter from "../../../utilities/modals/ModalOuter";
import { NavbarSecondLogo } from "../../../utilities/svg";
import { ModalHeader } from "../../MyCalendarGeneralItems";

const ViewCurriculumModal = ({
  title,
  state,
  setState,
  detailedView,
  questionsView,
}) => {
  return (
    <ModalOuter
      state={state}
      setState={setState}
      classNames="p-7 text-left animate-modalInitial min-w-400px 500px:min-w-600px"
    >
      <NavbarSecondLogo
        className=""
        style={{ width: "100px", height: "50px" }}
      />
      <ModalHeader
        heading={"View Curriculum"}
        closeIcon={true}
        setState={setState}
        hideBorder={true}
      />

      <div
        className="scrollbar"
        id="scrollbar"
        style={{
          minWidth: "250px",
          height: "70vh",
          overflow: "auto",
        }}
      >
        <div>
          <h1 className="my-2 font-bold text-2xl">{title}</h1>
          <Editor
            editorState={EditorState.createWithContent(
              convertFromRaw(detailedView)
            )}
            readOnly={true}
          />
        </div>
        <div>
          {questionsView?.map(({ question }, index) => {
            return (
              <div key={index}>
                <div className="py-8 flex space-x-1">
                  <b>{index + 1}. </b>
                  <Editor
                    editorState={EditorState.createWithContent(
                      convertFromRaw(question)
                    )}
                    readOnly={true}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ModalOuter>
  );
};

export default ViewCurriculumModal;
