import React, { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { mentorVerifyToken } from "../../AppRedux/actions/MentorRegistration";
import MentorOnboard from "./MentorOnboard";
import {
  errorToast,
  removeUserDataFromLS,
  successToast,
} from "../../components/utilities";
import {
  ProgressLoader,
  progressLoaderFunction,
} from "../../components/utilities";

const Index = () => {
  const dispatch = useDispatch(),
    [params] = useSearchParams(),
    [mentorTokenAndName, setMentorTokenAndName] = useState(""),
    [redirect, setRedirect] = useState(false),
    {
      submitMentorOnboardFormSuccessData,
      submitMentorOnboardFormFailureData,
      mentorTokenVerificationSuccessData,
      mentorTokenVerificationFailureData,
    } = useSelector(({ mentorRegistration }) => mentorRegistration);

  useEffect(() => {
    const token = params.getAll("token").toString(),
      refid = params.getAll("refid").toString(),
      data = {
        refid: parseInt(refid),
        token: token,
      };
    dispatch(mentorVerifyToken(data));
  }, [params, dispatch]);

  useEffect(() => {
    progressLoaderFunction();
  });
  useEffect(() => {
    if (submitMentorOnboardFormSuccessData) {
      successToast("Successfully Completed the onboarding process");
    }
    if (submitMentorOnboardFormFailureData) {
      errorToast(submitMentorOnboardFormFailureData.message);
    }
  }, [submitMentorOnboardFormSuccessData, submitMentorOnboardFormFailureData]);

  useEffect(() => {
    if (mentorTokenVerificationFailureData) {
      errorToast(mentorTokenVerificationFailureData.message);
      setRedirect(false);
    }
  }, [mentorTokenVerificationFailureData]);

  useEffect(() => {
    if (mentorTokenVerificationSuccessData) {
      const { token, user } = mentorTokenVerificationSuccessData.data;
      removeUserDataFromLS();
      setMentorTokenAndName({
        token: token,
        name: user.firstName + " " + user.lastName,
      });
      setRedirect(true);
    }
  }, [mentorTokenVerificationSuccessData]);

  const Content = () =>
    useMemo(
      () => (
        <>
          {redirect && (
            <>
              <ProgressLoader />
              <MentorOnboard mentorTokenAndName={mentorTokenAndName} />
            </>
          )}
        </>
      ),
      []
    );
  return <Content />;
};

export default Index;
