import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

import { getAppResources, getCountries } from "./AppRedux/actions/Home";
import { Puffloader } from "./components/utilities";
import Layout from "./components/Layout";

const App = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const handleLoading = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => window.removeEventListener("load", handleLoading);
  }, []);

  const tagManagerArgs = {
    gtmId: "GTM-NQHK7FD",
  };

  useEffect(() => {
    dispatch(getAppResources());
    dispatch(getCountries());
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0); // for scroll to top on every path change
  }, [pathname]);

  TagManager.initialize(tagManagerArgs);

  return <>{!isLoading ? <Layout /> : <Puffloader />}</>;
};

export default App;
