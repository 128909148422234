export const GET_ADMIN_DETAILS_BEGIN = "GET_ADMIN_DETAILS_BEGIN";
export const GET_ADMIN_DETAILS_SUCCESS = "GET_ADMIN_DETAILS_SUCCESS";
export const GET_ADMIN_DETAILS_FAILURE = "GET_ADMIN_DETAILS_FAILURE";
export const RESET_GET_ADMIN_DETAILS = "RESET_GET_ADMIN_DETAILS";

export const UPDATE_ADMIN_DETAILS_BEGIN = "UPDATE_ADMIN_DETAILS_BEGIN";
export const UPDATE_ADMIN_DETAILS_SUCCESS = "UPDATE_ADMIN_DETAILS_SUCCESS";
export const UPDATE_ADMIN_DETAILS_FAILURE = "UPDATE_ADMIN_DETAILS_FAILURE";
export const RESET_UPDATE_ADMIN_DETAILS = "RESET_UPDATE_ADMIN_DETAILS";

export const VIEW_PROMOCODE_DETAILS_BEGIN = "VIEW_PROMOCODE_DETAILS_BEGIN";
export const VIEW_PROMOCODE_DETAILS_SUCCESS = "VIEW_PROMOCODE_DETAILS_SUCCESS";
export const VIEW_PROMOCODE_DETAILS_FAILURE = "VIEW_PROMOCODE_DETAILS_FAILURE";
export const RESET_VIEW_PROMOCODE_DETAILS = "RESET_VIEW_PROMOCODE_DETAILS";

export const CREATE_PROMOCODE_BEGIN = "CREATE_PROMOCODE_BEGIN";
export const CREATE_PROMOCODE_SUCCESS = "CREATE_PROMOCODE_SUCCESS";
export const CREATE_PROMOCODE_FAILURE = "CREATE_PROMOCODE_FAILURE";
export const RESET_CREATE_PROMOCODE = "RESET_CREATE_PROMOCODE";

export const DELETE_PROMOCODE_BEGIN = "DELETE_PROMOCODE_BEGIN";
export const DELETE_PROMOCODE_SUCCESS = "DELETE_PROMOCODE_SUCCESS";
export const DELETE_PROMOCODE_FAILURE = "DELETE_PROMOCODE_FAILURE";
export const RESET_DELETE_PROMOCODE = "RESET_DELETE_PROMOCODE";
