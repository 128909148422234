import ChangeImage from "../../assets/Images/Change.webp";
import PageData from "../utilities/content/pages.json";

const PlatformForChangeSection = () => {
  const { heading, paragraph } =
    PageData.homePageData.PlatformForChangeSectionData;
  return (
    <section className=" pt-10 lg:pt-20">
      <div className="homepageWidth text-brandDarkBlue">
        <h1 className="font-normal sectionHeader text-brandDarkBlue pageheaderFontSize">
          {heading}
        </h1>
        <div className="text-lg sm:text-22px leading-relaxed text-justify">
          {paragraph.map(({ bold, light }, index) => (
            <p key={index} className="inline">
              <span style={{ textShadow: "1px 0 #093E5D" }}>{bold}</span>
              <span>{light}</span>
              <span style={{ textShadow: "1px 0 #093E5D" }}>
                . Merity
              </span>
              <span> helps women take action and succeed.</span>
            </p>
          ))}
        </div>
      </div>

      <img
        src={ChangeImage}
        alt=""
        width="100%"
        height="100%"
        className="py-8 sm:py-12 lg:py-24 "
      />
    </section>
  );
};

export default PlatformForChangeSection;
