import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import SingleMentorDetailsPage from "./SingleMentorDetailsPage";
import { ListHeader } from "../../memberDashboard/tableComponents/ExercisesList";
import { AdminCardContainer } from "../AdminGeneralComponents";
import Navbar from "../adminMentor/Navbar.js";
import MentorsList from "./MentorsList";

const ViewMentorsList = () => {
  const [params, setParams] = useSearchParams();
  const id = params.getAll("id").toString();
  const tab = params.getAll("tab").toString();
  const cell = params.getAll("cell").toString();

  useEffect(() => {
    if (cell === "" && tab === "") {
      setParams({
        tab: "active",
      });
    }
  }, [tab, cell, setParams]);

  if (cell === "profile" || cell === "feedback" || cell === "sessions") {
    return <SingleMentorDetailsPage tab={cell} id={id} />;
  }

  return (
    <AdminCardContainer>
      <Navbar selectedTab={tab} />
      <ListHeader
        headers={
          tab === "active" || tab === "background-check"
            ? headers
            : headers.slice(0, headers.length - 1)
        }
        gridClass={`mt-4 ${
          tab === "active"
            ? "grid-cols-adminActiveMentorsHeader"
            : tab === "requests"
            ? "grid-cols-adminMentorHeader"
            : tab === "background-check"
            ? "grid-cols-adminBackgroundCheckHeader xl:grid-cols-xladminBackgroundCheckHeader"
            : "grid-cols-adminBlockedMentorsHeader"
        } `}
      />
      <MentorsList tab={tab} />
    </AdminCardContainer>
  );
};

export default ViewMentorsList;

const headers = ["Name", "E-mail", "Registered date", "Status"];
