import {
  Tell_A_FRIEND_BEGIN,
  Tell_A_FRIEND_SUCCESS,
  Tell_A_FRIEND_FAILURE,
  RESET_Tell_A_FRIEND,
  GET_FREE_LIST_PLAN_BEGIN,
  GET_FREE_PLAN_LIST_SUCCESS,
  GET_FREE_PLAN_LIST_FAILURE,
  RESET_GET_FREE_LIST_PLAN,
  CLAIM_SUBSCRIPTION_BEGIN,
  CLAIM_SUBSCRIPTION_SUCCESS,
  CLAIM_SUBSCRIPTION_FAILURE,
  RESET_CLAIM_SUBSCRIPTION,
} from "../constants/MemberReferal";
const initialState = {
  tellAfriendBegin: false,
  tellAfriendSuccess: false,
  tellAfriendSuccessData: null,
  tellAfriendFailure: false,
  tellAfriendFailureData: null,
  // for getting the free planlist
  getFreePlanListBegin: false,
  getFreePlanListSuccess: false,
  getFreePlanListSuccessData: null,
  getFreePlanListFailure: false,
  getFreePlanListFailureData: null,

  //for claiming the subscriptions plan

  claimSubscriptionPlanBegin: false,
  claimSubscriptionPlanSuccess: false,
  claimSubscriptionPlanSuccessData: null,
  claimSubscriptionPlanFailure: false,
  claimSubscriptionPlanFailureData: null,
};

export const TellAfriendReducer = (state = initialState, action) => {
  const data = action.data;
  switch (action.type) {
    // Subscribe newsletter
    case Tell_A_FRIEND_BEGIN:
      return {
        ...state,
        tellAfriendBegin: true,
        tellAfriendSuccess: false,
        tellAfriendSuccessData: null,
        tellAfriendFailure: false,
        tellAfriendFailureData: null,
      };
    case Tell_A_FRIEND_SUCCESS:
      return {
        ...state,
        tellAfriendBegin: false,
        tellAfriendSuccess: true,
        tellAfriendSuccessData: data,
        tellAfriendFailure: false,
        tellAfriendFailureData: null,
      };
    case Tell_A_FRIEND_FAILURE:
      return {
        ...state,
        tellAfriendBegin: false,
        tellAfriendSuccess: false,
        tellAfriendSuccessData: null,
        tellAfriendFailure: true,
        tellAfriendFailureData: data,
      };
    case RESET_Tell_A_FRIEND:
      return {
        ...state,
        tellAfriendBegin: false,
        tellAfriendSuccess: false,
        tellAfriendSuccessData: null,
        tellAfriendFailure: false,
        tellAfriendFailureData: null,
      };
    case GET_FREE_LIST_PLAN_BEGIN:
      return {
        ...state,
        getFreePlanListBegin: true,
        getFreePlanListSuccess: false,
        getFreePlanListSuccessData: null,
        getFreePlanListFailure: false,
        getFreePlanListFailureData: null,
      };
    case GET_FREE_PLAN_LIST_SUCCESS:
      return {
        ...state,
        getFreePlanListBegin: false,
        getFreePlanListSuccess: true,
        getFreePlanListSuccessData: data,
        getFreePlanListFailure: false,
        getFreePlanListFailureData: null,
      };
    case GET_FREE_PLAN_LIST_FAILURE:
      return {
        ...state,
        getFreePlanListBegin: false,
        getFreePlanListSuccess: false,
        getFreePlanListSuccessData: null,
        getFreePlanListFailure: true,
        getFreePlanListFailureData: data,
      };
    case RESET_GET_FREE_LIST_PLAN:
      return {
        ...state,
        getFreePlanListBegin: false,
        getFreePlanListSuccess: false,
        getFreePlanListSuccessData: null,
        getFreePlanListFailure: false,
        getFreePlanListFailureData: null,
      };
    case CLAIM_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        claimSubscriptionPlanBegin: true,
        claimSubscriptionPlanSuccess: false,
        claimSubscriptionPlanSuccessData: null,
        claimSubscriptionPlanFailure: false,
        claimSubscriptionPlanFailureData: null,
      };
    case CLAIM_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        claimSubscriptionPlanBegin: false,
        claimSubscriptionPlanSuccess: true,
        claimSubscriptionPlanSuccessData: data,
        claimSubscriptionPlanFailure: false,
        claimSubscriptionPlanFailureData: null,
      };
    case CLAIM_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        claimSubscriptionPlanBegin: false,
        claimSubscriptionPlanSuccess: false,
        claimSubscriptionPlanSuccessData: null,
        claimSubscriptionPlanFailure: true,
        claimSubscriptionPlanFailureData: data,
      };
    case RESET_CLAIM_SUBSCRIPTION:
      return {
        ...state,
        claimSubscriptionPlanBegin: false,
        claimSubscriptionPlanSuccess: false,
        claimSubscriptionPlanSuccessData: null,
        claimSubscriptionPlanFailure: false,
        claimSubscriptionPlanFailureData: null,
      };

    default:
      return state;
  }
};
