import React from "react";
import ModalOuter from "../../../utilities/modals/ModalOuter";
import "../../../utilities/styles/successAnimation.css";
import {
  CrossIcon,
  AnimatedRejected,
  AnimatedSuccess,
  SuccessModalTickIcon,
} from "../../../utilities/svg";

const MentorRejectedModal = ({ text, state, setState, type }) => {
  return (
    <ModalOuter
      state={state}
      setState={setState}
      classNames="relative p-4 text-center animate-modalInitial w-full"
    >
      <div className="flex flex-col items-center gap-4 p-10 animate-modalInitial z-50">
        {type === 1 ? (
          <AnimatedRejected className="z-40 w-1/2 h-1/2" />
        ) : type === 2 ? (
          <SuccessModalTickIcon />
        ) : (
          <AnimatedSuccess />
        )}
        <h1 className="font-bold text-xl" style={{ color: "#007DA6" }}>
          {text}
        </h1>
      </div>
      <div
        className="dashboardHover dashboardActive absolute z-50 right-3 top-2 cursor-pointer activeState p-2 rounded-full"
        onClick={() => {
          setState(false);
        }}
      >
        <CrossIcon className="fill-current " />
      </div>
    </ModalOuter>
  );
};

export default MentorRejectedModal;
