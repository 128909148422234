import {
  VIEW_ALL_EXERCISES_BEGIN,
  VIEW_ALL_EXERCISES_SUCCESS,
  VIEW_ALL_EXERCISES_FAILURE,
  RESET_VIEW_ALL_EXERCISES,
  ADD_NEW_EXERCISE_BEGIN,
  ADD_NEW_EXERCISE_SUCCESS,
  ADD_NEW_EXERCISE_FAILURE,
  RESET_ADD_NEW_EXERCISE,
  SAVE_STORY_BEGIN,
  SAVE_STORY_SUCCESS,
  SAVE_STORY_FAILURE,
  RESET_SAVE_STORY,
  PUBLISH_STORY_BEGIN,
  PUBLISH_STORY_SUCCESS,
  PUBLISH_STORY_FAILURE,
  RESET_PUBLISH_STORY,
  GET_STORIES_BEGIN,
  GET_STORIES_SUCCESS,
  GET_STORIES_FAILURE,
  RESET_GET_STORIES,
  EDIT_EXERCISE_BEGIN,
  EDIT_EXERCISE_SUCCESS,
  EDIT_EXERCISE_FAILURE,
  RESET_EDIT_EXERCISE,
  VIEW_ALL_TIF_BEGIN,
  VIEW_ALL_TIF_SUCCESS,
  VIEW_ALL_TIF_FAILURE,
  RESET_VIEW_ALL_TIF,
  ADD_NEW_TIF_BEGIN,
  ADD_NEW_TIF_SUCCESS,
  ADD_NEW_TIF_FAILURE,
  RESET_ADD_NEW_TIF,
  EDIT_TIF_BEGIN,
  EDIT_TIF_SUCCESS,
  EDIT_TIF_FAILURE,
  RESET_EDIT_TIF,
  DELETE_CURRICULUM_BEGIN,
  DELETE_CURRICULUM_SUCCESS,
  DELETE_CURRICULUM_FAILURE,
  RESET_DELETE_CURRICULUM,
} from "../constants/AdminDashboard";

import { httpApp } from "../../axios/httpUser";

export function viewAllExercise(page, params = "") {
  return (dispatch) => {
    dispatch({
      type: VIEW_ALL_EXERCISES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/curriculum?page=${page}&limit=10${params}&orderBy=number&order=desc`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_ALL_EXERCISES_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_ALL_EXERCISES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_ALL_EXERCISES_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_ALL_EXERCISES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function addNewExercise(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_NEW_EXERCISE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/curriculum`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: ADD_NEW_EXERCISE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ADD_NEW_EXERCISE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ADD_NEW_EXERCISE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_ADD_NEW_EXERCISE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//create new story

export function createNewStory(data) {
  return (dispatch) => {
    dispatch({
      type: SAVE_STORY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/story`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SAVE_STORY_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SAVE_STORY,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SAVE_STORY_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SAVE_STORY,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//publish story
export function publishStory(id, data) {
  return (dispatch) => {
    dispatch({
      type: PUBLISH_STORY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/story/${id}`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: PUBLISH_STORY_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_PUBLISH_STORY,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: PUBLISH_STORY_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_PUBLISH_STORY,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//get stories

export function getStories(page = 1, limit = 10, status = 0, order = "desc") {
  return (dispatch) => {
    dispatch({
      type: GET_STORIES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/story?page=${page}&status=${status}&limit=${limit}&order=${order}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_STORIES_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_STORIES,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_STORIES_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_STORIES,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//edit exercise

export function editExercise(data, id) {
  return (dispatch) => {
    dispatch({
      type: EDIT_EXERCISE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/curriculum/${id}/update`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_EXERCISE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_EXERCISE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_EXERCISE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_EDIT_EXERCISE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//view take it further questions

export function viewAllTakeItFurther(page, limit = 10, params = "") {
  return (dispatch) => {
    dispatch({
      type: VIEW_ALL_TIF_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/curriculum/tif-question?page=${page}&limit=${limit}${params}&order=desc`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_ALL_TIF_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_ALL_TIF,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_ALL_TIF_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_ALL_TIF,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//add new TIF

export function addNewTif(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_NEW_TIF_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/curriculum/tif-question`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: ADD_NEW_TIF_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ADD_NEW_TIF,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ADD_NEW_TIF_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_ADD_NEW_TIF,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//edit TIF

export function editTif(data, id) {
  return (dispatch) => {
    dispatch({
      type: EDIT_TIF_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/curriculum/tif-question/${id}`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_TIF_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_TIF,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_TIF_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_EDIT_TIF,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

//delete curriculum

export function deleteCurriculum(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_CURRICULUM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/curriculum/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_CURRICULUM_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_CURRICULUM,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_CURRICULUM_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_CURRICULUM,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
