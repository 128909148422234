import { Link } from "react-router-dom";

import { CardButton } from "../utilities";
import PageData from "../utilities/content/pages.json";

const SubscriptionPlanCard = () => {
  const { heading, paragraph } = PageData.homePageData.SubscriptionPlanCardData;
  return (
    <div className="homepageWidth py-5 lg:py-8">
      <div className="relative border-6px border-brandYellow bg-white text-brandDarkBlue py-5 lg:py-8 px-5 md:px-0 md:pr-12 md:pl-14">
        <div className=" md:flex items-center">
          <h3 className="pageheaderFontSize w-full uppercase font-semibold text-xl 500px:text-xl md:text-2xl xl:text-3xl text-brandBlue inline relative z-10">
            {heading}
          </h3>
          <div className="w-full md:w-2/5 ">
            <Link to="/join#step1">
              <CardButton
                text="GET STARTED"
                classNames="justify-center md:float-right min-w-full bg-brandBlue hover:bg-brandYellow active:bg-brandYellow"
              />
            </Link>
          </div>
        </div>
        <p className="font-medium text-base xl:text-xl inline-block relative text-brandBlue z-10">
          {paragraph}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionPlanCard;
