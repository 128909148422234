import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import MerityCommunityIconPNG from "../../../assets/Images/MerityCommunityIconPNG.png";
import { useDispatch, useSelector } from "react-redux";
import { viewGroupDetails } from "../../../AppRedux/actions/AdminPrompts";
import { BackArrow } from "../dashboardGeneralItems";
import {
  CliploaderBlue,
  getAdminDetailsInLocalStorage,
  storeAdminDetailsInLocalStorage,
  UserAvatar,
} from "../../utilities";

const AdminNavbar = () => {
  const { firstName, lastName } = getAdminDetailsInLocalStorage();
  const { getAdminDetailsSuccessData } = useSelector(
    ({ adminSettings }) => adminSettings
  );

  useEffect(() => {
    if (getAdminDetailsSuccessData) {
      storeAdminDetailsInLocalStorage(getAdminDetailsSuccessData.data);
    }
  }, [getAdminDetailsSuccessData]);

  return (
    <div className="bg-white w-full">
      <div className="flex flex-col-reverse 500px:flex-row items-center justify-between shadow-memberDashboard bg-white pl-9 pr-7 sm:pr-20 xl:pl-14 py-5 -ml-4">
        <NavbarHeader />
        <div className="flex items-center">
          <Link
            className="flex items-center text-brandDarkBlue "
            to="/admin/dashboard/feeds"
          >
            <img
              style={{ minWidth: "64px", minHeight: "48px" }}
              src={MerityCommunityIconPNG}
              alt=""
              className="w-16 h-12"
            />
            <span className="hidden md:block dashboardHover p-2 rounded-lg">
              Community Chat
            </span>
          </Link>
        </div>

        <Link
          to="/admin/dashboard/settings"
          className="cursor-pointer flex items-center gap-2 buttonHover activeState"
        >
          <UserAvatar
            name={firstName}
            classNames="w-6 h-6 500px:w-9 500px:h-9"
          />
          <p className="font-semibold text-base" style={{ color: "#013B5D" }}>
            {`${firstName} ${lastName}`}
          </p>
        </Link>
      </div>
    </div>
  );
};

export default AdminNavbar;

const NavbarHeader = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const { pathname } = useLocation();
  const [groupName, setgroupName] = useState(false);
  const tabFromParams = params.getAll("tab").toString();
  const actionFromParams = params.getAll("action").toString();
  const idFromParams = params.getAll("id").toString();
  const cellFromParams = params.getAll("cell").toString();
  const { viewGroupDetailsSuccessData } = useSelector(
    ({ AdminPrompts }) => AdminPrompts
  );

  useEffect(() => {
    if (actionFromParams && !groupName) {
      dispatch(viewGroupDetails(idFromParams));
    }
  }, [actionFromParams, dispatch, idFromParams, groupName]);

  useEffect(() => {
    if (viewGroupDetailsSuccessData) {
      setgroupName(viewGroupDetailsSuccessData.data.name);
    }
  }, [viewGroupDetailsSuccessData]);

  const TabsTitle = () => {
    const { backArrow, title } = tabsTitle.find(
      ({ tab }) => tab === tabFromParams
    );
    return (
      <div className=" flexCenter space-x-4 shadow-adminMember rounded-full">
        {actionFromParams ? (
          <>
            <BackArrow
              link={
                actionFromParams === "view-response"
                  ? `/admin/dashboard?tab=groups&id=${idFromParams}&action=view-prompts`
                  : "/admin/dashboard"
              }
            />
            <Title>
              {groupName ? (
                <span
                  className="font-extrabold text-lg text-brandDarkBlue"
                  style={{ color: "rgba(0, 46, 62, 0.24)" }}
                >
                  {actionFromParams === "view-response" && (
                    <span className="mx-2">Prompts &gt;</span>
                  )}
                  Groups &gt; <span className="mx-1">{groupName}</span>
                </span>
              ) : (
                <CliploaderBlue />
              )}
            </Title>
          </>
        ) : (
          <>
            {backArrow && <BackArrow link={backArrow} />}
            <Title>{title}</Title>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {tabFromParams ? (
        <TabsTitle />
      ) : cellFromParams ? (
        <div className=" flexCenter space-x-4 shadow-adminMember rounded-full">
          <BackArrow link={-1} />
          <Title>Mentor detail page</Title>
        </div>
      ) : (
        <Title>
          {NavbarHeaders.find(({ path }) => path === pathname)?.title}
        </Title>
      )}
    </>
  );
};

const Title = ({ children }) => (
  <p className="font-extrabold text-xl text-brandDarkBlue">{children}</p>
);

// font-medium text-base
const tabsTitle = [
  {
    tab: "prompts",
    title: "Prompts",
  },
  {
    tab: "groups",
    title: "Groups",
  },
  {
    tab: "details",
    title: "Community",
    backArrow: "/admin/dashboard/community",
  },
  {
    tab: "profile",
    title: "Member detail page",
    backArrow: "/admin/dashboard/members",
  },
  {
    tab: "payment",
    title: "Member detail page",
    backArrow: "/admin/dashboard/members",
  },
  {
    tab: "active",
    title: "Manage Mentors",
  },
  {
    tab: "requests",
    title: "Manage Mentors",
  },
  {
    tab: "background-check",
    title: "Manage Mentors",
  },
  {
    tab: "blocked",
    title: "Manage Mentors",
  },
  {
    tab: "rejected",
    title: "Manage Mentors",
  },
];

const NavbarHeaders = [
  {
    title: "Prompts",
    path: "/admin/dashboard",
  },
  {
    title: "Curriculum",
    path: "/admin/dashboard/curriculum",
  },
  {
    title: "Community",
    path: "/admin/dashboard/community",
  },
  {
    title: "Member Blog",
    path: "/admin/dashboard/blog",
  },
  {
    title: "Members",
    path: "/admin/dashboard/members",
  },
  {
    title: "Mentor Manager",
    path: "/admin/dashboard/manager",
  },
  {
    title: "Stories",
    path: "/admin/dashboard/stories",
  },
  {
    title: "Settings",
    path: "/admin/dashboard/settings",
  },
  {
    title: "Community Chat",
    path: "/admin/dashboard/feeds",
  },
];
