import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { InputGroup } from "../newFormInputs";

import { ModalHeader } from "../../pageComponents/MyCalendarGeneralItems";
import ModalOuter from "./ModalOuter";
import FormBackAndContinueButton from "../../pageComponents/MemberRegisterForms/FormBackAndContinueButton";
import { errorToast, successToast } from "../Toast";
import { TellAfriend } from "../../../AppRedux/actions/MemberReferal";

export default function TellAFriendModal({
  state,
  setState,
}) {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState({});
  const [formData, setFromData] = useState({
    promo: "",
  });

  const { tellAfriendbegin, tellAfriendSuccessData, tellAfriendFailureData } = useSelector(
    ({ TellAfriendReducer }) => TellAfriendReducer
  );


  useEffect(() => {
    if (tellAfriendSuccessData) {
      successToast(tellAfriendSuccessData?.message);
      setState(() => false);
    }
    if (tellAfriendFailureData) {
      errorToast(tellAfriendFailureData?.data.message);
    }

  }, [
    tellAfriendSuccessData,
    setState,
    tellAfriendFailureData,
  ]);


  const customFormValidation = () => {
    const { promo } = formData;

    const validationErrors = {};
    if (promo?.trim() === "") {
      validationErrors.promo = "Email is required.";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(promo)) {
      validationErrors.promo = "You have entered the invaid email address";
    }

    return validationErrors;
  };
  const handleInputOnChange = (e) => {
    let name = e.target.name;
    let sm = formError;
    delete sm[[name]];
    setFormError({ ...formError, ...sm });


    setFromData({
      [e.target.name]: e.target.value,
    });

  };

  const handleApplyPromocode = (e) => {
    e.preventDefault();

    const validationErrors = customFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      const data = {
        email: formData?.promo,
      };
      dispatch(TellAfriend(data));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <ModalOuter
      state={state}
      setState={setState}
      blockOutsideClick={false}
      classNames=" animate-modalInitial min-h-50vh overflow-y-hidden p-8 min-w-200px 500px:min-w-70% md:min-w-700px"
    >
      <ModalHeader
        heading="Refer a friend"
        description="Refer your friend to and receive one month of a Merity membership, for free-a savings of $29.99 for you!"
        closeIcon={true}
        setState={setState}
        hideBorder={false}
        classNames="text-3xl font-bold mt-2  "
        iconClass="top-0 right-2"
      />

      <form>
        <InputGroup
          name="promo"
          label="Enter email id"
          type="email"
          onChange={(e) => handleInputOnChange(e)}
          value={formData.promo}
          errorMessage={formError?.promo}
        />

        <FormBackAndContinueButton
          buttonText="Submit"
          backButtonText="Cancel"
          onBackButtonClick={() => setState(false)}
          actionBegin={tellAfriendbegin}
          buttonClass="py-2 text-base"
          onClick={(e) => handleApplyPromocode(e)}
        />
      </form>
    </ModalOuter>
  );
}
