import {
  GET_MANAGER_DETAILS_BEGIN,
  GET_MANAGER_DETAILS_SUCCESS,
  GET_MANAGER_DETAILS_FAILURE,
  RESET_GET_MANAGER_DETAILS,
  EDIT_MANAGER_NAME_BEGIN,
  EDIT_MANAGER_NAME_SUCCESS,
  EDIT_MANAGER_NAME_FAILURE,
  RESET_EDIT_MANAGER_NAME,
  GET_MANAGER_DASHBOARD_BEGIN,
  GET_MANAGER_DASHBOARD_SUCCESS,
  GET_MANAGER_DASHBOARD_FAILURE,
  RESET_GET_MANAGER_DASHBOARD,
  GET_MANAGER_DETAILS_BYID_BEGIN,
  GET_MANAGER_DETAILS_BYID_SUCCESS,
  GET_MANAGER_DETAILS_BYID_FAILURE,
  RESET_GET_MANAGER_DETAILS_BYID,
} from "../constants/AdminManager";

const initialState = {
  getManagerDetailsBegin: false,
  getManagerDetailsSuccess: false,
  getManagerDetailsSuccessData: null,
  getManagerDetailsFailure: false,
  getManagerDetailsFailureData: null,

  editManagerNameBegin: false,
  editManagerNameSuccess: false,
  editManagerNameSuccessData: null,
  editManagerNameFailure: false,
  editManagerNameFailureData: null,

  getManagerDashboardBegin: false,
  getManagerDashboardSuccess: false,
  getManagerDashboardSuccessData: null,
  getManagerDashboardFailure: false,
  getManagerDashboardFailureData: null,

  getManagerDetailsByIdBegin: false,
  getManagerDetailsByIdSuccess: false,
  getManagerDetailsByIdSuccessData: null,
  getManagerDetailsByIdFailure: false,
  getManagerDetailsByIdFailureData: null,
};

export const adminManager = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // get manager details
    case GET_MANAGER_DETAILS_BEGIN:
      return {
        ...state,
        getManagerDetailsBegin: true,
        getManagerDetailsSuccess: false,
        getManagerDetailsSuccessData: null,
        getManagerDetailsFailure: false,
        getManagerDetailsFailureData: null,
      };
    case GET_MANAGER_DETAILS_SUCCESS:
      return {
        ...state,
        getManagerDetailsBegin: false,
        getManagerDetailsSuccess: true,
        getManagerDetailsSuccessData: data,
        getManagerDetailsFailure: false,
        getManagerDetailsFailureData: null,
      };
    case GET_MANAGER_DETAILS_FAILURE:
      return {
        ...state,
        getManagerDetailsBegin: false,
        getManagerDetailsSuccess: false,
        getManagerDetailsSuccessData: null,
        getManagerDetailsFailure: true,
        getManagerDetailsFailureData: data,
      };
    case RESET_GET_MANAGER_DETAILS:
      return {
        ...state,
        getManagerDetailsBegin: false,
        getManagerDetailsSuccess: false,
        getManagerDetailsSuccessData: null,
        getManagerDetailsFailure: false,
        getManagerDetailsFailureData: null,
      };

    //edit manager
    case EDIT_MANAGER_NAME_BEGIN:
      return {
        ...state,
        editManagerNameBegin: true,
        editManagerNameSuccess: false,
        editManagerNameSuccessData: null,
        editManagerNameFailure: false,
        editManagerNameFailureData: null,
      };
    case EDIT_MANAGER_NAME_SUCCESS:
      return {
        ...state,
        editManagerNameBegin: false,
        editManagerNameSuccess: true,
        editManagerNameSuccessData: data,
        editManagerNameFailure: false,
        editManagerNameFailureData: null,
      };
    case EDIT_MANAGER_NAME_FAILURE:
      return {
        ...state,
        editManagerNameBegin: false,
        editManagerNameSuccess: false,
        editManagerNameSuccessData: null,
        editManagerNameFailure: true,
        editManagerNameFailureData: data,
      };
    case RESET_EDIT_MANAGER_NAME:
      return {
        ...state,
        editManagerNameBegin: false,
        editManagerNameSuccess: false,
        editManagerNameSuccessData: null,
        editManagerNameFailure: false,
        editManagerNameFailureData: null,
      };

    // get manager details
    case GET_MANAGER_DASHBOARD_BEGIN:
      return {
        ...state,
        getManagerDashboardBegin: true,
        getManagerDashboardSuccess: false,
        getManagerDashboardSuccessData: null,
        getManagerDashboardFailure: false,
        getManagerDashboardFailureData: null,
      };

    case GET_MANAGER_DASHBOARD_SUCCESS:
      return {
        ...state,
        getManagerDashboardBegin: false,
        getManagerDashboardSuccess: true,
        getManagerDashboardSuccessData: data,
        getManagerDashboardFailure: false,
        getManagerDashboardFailureData: null,
      };

    case GET_MANAGER_DASHBOARD_FAILURE:
      return {
        ...state,
        getManagerDashboardBegin: false,
        getManagerDashboardSuccess: false,
        getManagerDashboardSuccessData: null,
        getManagerDashboardFailure: true,
        getManagerDashboardFailureData: data,
      };

    case RESET_GET_MANAGER_DASHBOARD:
      return {
        ...state,
        getManagerDashboardBegin: false,
        getManagerDashboardSuccess: false,
        getManagerDashboardSuccessData: null,
        getManagerDashboardFailure: false,
        getManagerDashboardFailureData: null,
      };
    //get mentor details by id
    case GET_MANAGER_DETAILS_BYID_BEGIN:
      return {
        ...state,
        getManagerDetailsByIdBegin: true,
        getManagerDetailsByIdSuccess: false,
        getManagerDetailsByIdSuccessData: null,
        getManagerDetailsByIdFailure: false,
        getManagerDetailsByIdFailureData: null,
      };
    case GET_MANAGER_DETAILS_BYID_SUCCESS:
      return {
        ...state,
        getManagerDetailsByIdBegin: false,
        getManagerDetailsByIdSuccess: true,
        getManagerDetailsByIdSuccessData: data,
        getManagerDetailsByIdFailure: false,
        getManagerDetailsByIdFailureData: null,
      };
    case GET_MANAGER_DETAILS_BYID_FAILURE:
      return {
        ...state,
        getManagerDetailsByIdBegin: false,
        getManagerDetailsByIdSuccess: false,
        getManagerDetailsByIdSuccessData: null,
        getManagerDetailsByIdFailure: true,
        getManagerDetailsByIdFailureData: data,
      };
    case RESET_GET_MANAGER_DETAILS_BYID:
      return {
        ...state,
        getManagerDetailsByIdBegin: false,
        getManagerDetailsByIdSuccess: false,
        getManagerDetailsByIdSuccessData: null,
        getManagerDetailsByIdFailure: false,
        getManagerDetailsByIdFailureData: null,
      };
    default:
      return state;
  }
};
