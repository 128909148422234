import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import SeoData from "../../utilities/seo/seoData.json";
import { NoListToShowContainer, PageNumbers } from "../dashboardGeneralItems";
import { ListHeader } from "../memberDashboard/tableComponents/ExercisesList";
import {
  scrollToTop,
  Scaleloader,
  RcTooltip,
  ReactHelmet,
} from "../../utilities";
import {
  getClientExercise,
  viewClientTasks,
} from "../../../AppRedux/actions/MentorMyClients";

const ExerciseTab = ({ selectedCell }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const id = params.getAll("id").toString();
  const headers = ["Q.No", "Question", "Responded date", "Action"];
  const [currentPage, setcurrentPage] = useState(1);
  const {
    getClientExerciseBegin,
    getClientExerciseSuccessData,
    viewClientTaskSuccessData,
    viewClientTaskBegin,
  } = useSelector(({ mentorMyClients }) => mentorMyClients);

  const exerciseLength = getClientExerciseSuccessData?.data?.rows?.length;
  const exercises = getClientExerciseSuccessData?.data?.rows;
  const exercisesCount = getClientExerciseSuccessData?.data?.count;

  const taskLength = viewClientTaskSuccessData?.data?.rows?.length;
  const tasks = viewClientTaskSuccessData?.data?.rows;
  const taskCount = viewClientTaskSuccessData?.data?.count;

  useEffect(() => {
    if (selectedCell === "curriculum") {
      dispatch(getClientExercise(currentPage, id));
    }
    if (selectedCell === "task") {
      dispatch(viewClientTasks(currentPage, id));
    }
  }, [dispatch, id, currentPage, selectedCell]);

  const handleViewClick = (qid) => {
    setParams({
      tab: selectedCell,
      id: id,
      questionId: qid,
      action: selectedCell === "curriculum" ? "view-exercise" : "view-task",
      page_no: currentPage,
    });
  };

  const GeneralColumns = ({
    title,
    responseDate,
    isResponded,
    id,
    responseText,
  }) => (
    <>
      <RcTooltip content={title}>
        <h1 className="oneLineContent w-10/12 font-semibold cursor-default">
          {title}
        </h1>
      </RcTooltip>

      <p className="500px:w-7/12 w-1/2 whitespace-nowrap text-center mr-2 font-semibold">
        {isResponded && responseDate
          ? moment(new Date(responseDate)).format("MM-DD-YYYY")
          : "Not Responded"}
      </p>
      <p
        className="font-semibold dashboardLink"
        onClick={() => {
          scrollToTop("50", "16.66");
          handleViewClick(id);
        }}
      >
        {responseText}
      </p>
    </>
  );

  return (
    <section className="bg-white p-4 min-h-80vh relative pb-14">
      <ReactHelmet
        data={selectedCell === "curriculum" ? SeoData.exercise : SeoData.tasks}
      />
      <ListHeader
        headers={
          selectedCell === "curriculum"
            ? headers
            : headers.slice(1, headers.length)
        }
        gridClass={`${
          selectedCell === "curriculum"
            ? "grid-cols-mentorMyClientsExerciseHeader 2xl:grid-cols-xlmentorMyClientsExerciseHeader"
            : "grid-cols-mentorMyClientsTaskHeader"
        } `}
      />

      {/* List */}
      <div className="member-table-row-items ">
        {selectedCell === "curriculum" ? (
          <>
            {getClientExerciseSuccessData?.data ? (
              exerciseLength !== 0 ? (
                exercises.map(({ id, title, createdAt }, index) => {
                  return (
                    <div
                      key={index}
                      className={`grid-cols-mentorMyClientsExerciseList 2xl:grid-cols-lgmentorMyClientsExerciseList  member-table-row-item text-sm xl:text-base font-medium gap-1 ${
                        index === exerciseLength - 1 ? "border-none" : ""
                      } `}
                    >
                      <p className="font-semibold">
                        {index +
                          (currentPage > 1 && exercisesCount > 10
                            ? (currentPage - 1) * 10 + 1
                            : 1)}
                      </p>
                      <GeneralColumns
                        id={id}
                        title={title}
                        responseDate={createdAt}
                        responseText="View Response"
                        isResponded={true}
                      />
                    </div>
                  );
                })
              ) : (
                <NoListToShowContainer text="There are no exercises currently" />
              )
            ) : (
              <Scaleloader />
            )}
            <PageNumbers
              count={exercisesCount}
              currentPage={currentPage}
              setcurrentPage={setcurrentPage}
              loading={getClientExerciseBegin}
              limit={10}
              onPageNumberClick={(number) => {
                dispatch(getClientExercise(number, id));
              }}
            />
          </>
        ) : (
          <>
            {viewClientTaskSuccessData?.data ? (
              taskLength !== 0 ? (
                tasks?.map(({ id, title, isResponded, updatedAt }, index) => {
                  return (
                    <div
                      key={index}
                      className={`grid-cols-mentorMyClientsTaskHeader member-table-row-item gap-1 ${
                        index === taskLength - 1 ? "border-none" : ""
                      }`}
                    >
                      <GeneralColumns
                        id={id}
                        title={title}
                        responseDate={updatedAt}
                        responseText="View Task"
                        isResponded={isResponded}
                      />
                    </div>
                  );
                })
              ) : (
                <NoListToShowContainer text="There are no tasks currently" />
              )
            ) : (
              <Scaleloader />
            )}
            <PageNumbers
              count={taskCount}
              currentPage={currentPage}
              setcurrentPage={setcurrentPage}
              loading={viewClientTaskBegin}
              limit={10}
              onPageNumberClick={(number) => {
                dispatch(viewClientTasks(number, id));
              }}
            />
          </>
        )}
      </div>
    </section>
  );
};

export default ExerciseTab;
