import { mentorRegistrationPageData } from "../../utilities";
import {
  AssessmentPageSun,
  AssessmentPageLightSun,
  MentorRegistrationLandingSun,
} from "../../utilities/svg";

const MentorRegisterLandingSun = ({ pageNumber }) => {
  const { landingPageTitle, landingPageDescription } =
    mentorRegistrationPageData;
  return (
    <div>
      <section className="homepageWidth mx-auto pt-4 360px:pt-8 500px:pt-3 mb-12 ">
        <h1 className="font-semibold text-3xl 370px:text-4xl 500px::text-5xl text-center my-4 500px:my-6">
          {landingPageTitle}
        </h1>
        <p className="font-medium text-base 500px:text-lg text-center w-full lg:w-1/2 mx-auto">
          {landingPageDescription}
        </p>
      </section>
      <section className="relative">
        <div
          className="w-full h-1/4  px-12 md:px-4"
          style={{ backgroundColor: "#F1FBFF" }}
        >
          <div className="flexCenter w-full mb-3 md:mb-5 relative z-10">
            {Array(6)
              .fill()
              .map((_, index) => (
                <>
                  {pageNumber > index ? (
                    <AssessmentPageSun />
                  ) : (
                    <AssessmentPageLightSun />
                  )}
                  {index < 5 && (
                    <div
                      className="border-b-2 w-1/12"
                      style={{
                        borderColor:
                          pageNumber > index + 1 ? "#FFC556" : "#D5D9B0",
                      }}
                    />
                  )}
                </>
              ))}
          </div>
        </div>
        <MentorRegistrationLandingSun className="absolute bottom-1/2 transform translate-y-1/2 pr-14 md:pr-4" />
      </section>
    </div>
  );
};

export default MentorRegisterLandingSun;
