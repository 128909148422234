import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CliploaderBlue } from "../../../utilities";
import ModalOuter from "../../../utilities/modals/ModalOuter";
import { SuccessModalTickIcon, CrossIcon } from "../../../utilities/svg";
import {
  getMentorsList,
  approveMentor,
  // mentorBackgroundVerification,
} from "../../../../AppRedux/actions/AdminMentor";

export default function BackgroundVerificationModal({
  // mentorId,
  state,
  setState,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const {
    approveMentorRegistrationBegin,
    approveMentorRegistrationSuccessData,
    // approveMentorBackroundVerificationBegin,
    approveMentorBackroundVerificationSuccessData,
  } = useSelector(({ adminMentor }) => adminMentor);

  useEffect(() => {
    if (approveMentorRegistrationSuccessData) {
      setSuccess(true);
      dispatch(getMentorsList(-1));
    }
    if (approveMentorBackroundVerificationSuccessData) {
      dispatch(getMentorsList(1));
      setState(!state);
      navigate("/admin/dashboard/mentors?tab=background-check");
    }
  }, [
    approveMentorBackroundVerificationSuccessData,
    approveMentorRegistrationSuccessData,
    setSuccess,
    dispatch,
    state,
    setState,
    navigate,
  ]);

  return (
    <ModalOuter
      state={state.popup}
      setState={setState}
      classNames="relative p-4 text-center animate-modalInitial w-full"
    >
      {success === false ? (
        <>
          <h1 className="font-medium text-lg text-left p-2  ">
            Do you want to process the request manually or through background
            running ?
          </h1>
          <div className="flex space-x-4 mt-2 w-2/3 px-2  ">
            <button
              onClick={() => {
                dispatch(approveMentor(state.user));
              }}
              className="p-2 bg-white dashboardListHover rounded-lg text-brandBlue flex items-center font-semibold text-sm cursor-pointer mt-4 md:mt-0 md:min-w-min z-10 w-full"
            >
              Manually Approve
              {approveMentorRegistrationBegin && <CliploaderBlue />}
            </button>
            {/* <CardButton
              text="Background run"
              onClick={() => {
                dispatch(mentorBackgroundVerification(state.id));
              }}
              loader={approveMentorBackroundVerificationBegin}
              classNames="bg-brandBlue font-semibold px-0 pl-4 py-1/2 text-sm  flex items-center w-full"
            /> */}
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center gap-4 p-10 animate-modalInitial">
          <SuccessModalTickIcon />
          <h1 className="font-bold text-xl" style={{ color: "#007DA6" }}>
            Mentor Verified
          </h1>
        </div>
      )}
      <div
        className="dashboardHover dashboardActive absolute z-50 right-3 top-2 cursor-pointer activeState p-2 rounded-full"
        onClick={() => {
          setState(!state);
        }}
      >
        <CrossIcon className="fill-current " />
      </div>
    </ModalOuter>
  );
}
