import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import moment from "moment";
import { toast } from "react-toastify";

import "react-calendar/dist/Calendar.css";
import "../../../utilities/styles/reactCalendar.css";
import ModalOuter from "../../../utilities/modals/ModalOuter";
import { AppTimePicker, ModalHeader, utcDateTimeString } from "../../MyCalendarGeneralItems";
import { CardButton, infoToast, successToast } from "../../../utilities";
import {
  rescheduleCommunitySession,
  getCommunityList,
} from "../../../../AppRedux/actions/AdminCommunity";
import { calendarBookingOnChange } from "../../dashboardGeneralItems";

const RescheduleCommunityModal = ({
  communityList,
  communityId,
  state,
  setState,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("");
  const [toTime, setToTime] = useState("");
  const [formError, setFormError] = useState({});
  const {
    getCommunityListSuccessData,
    rescheduleCommunitySessionBegin,
    rescheduleCommunitySessionSuccessData,
  } = useSelector(({ adminCommunity }) => adminCommunity);

  useEffect(() => {
    if (state) {
      let singleCommunityEvent = communityList?.rows.find(
        (event) => event.id === communityId
      );
      let startTime = moment(singleCommunityEvent.startTime).format("HH:mm")
      let endTime = moment(singleCommunityEvent.endTime).format("HH:mm")
      setSelectedTime(startTime);
      setToTime(endTime);
      setValue(new Date(singleCommunityEvent?.onDate));

    }
  }, [state, communityId, communityList]);

  useEffect(() => {
    if (rescheduleCommunitySessionSuccessData) {
      successToast("Event rescheduled successfully");
      dispatch(getCommunityList(currentPage));
      setState(false);
    }
  }, [
    communityId,
    getCommunityListSuccessData,
    rescheduleCommunitySessionSuccessData,
    dispatch,
    setState,
    currentPage,
  ]);

  const validation = () => {
    const validationErrors = {};
    if (toTime === null || toTime?.trim() === "") {
      validationErrors.toTime = "This Field is required.";
    }
    if (selectedTime === null || selectedTime?.trim() === "") {
      validationErrors.selectedTime = "This Field is required.";
    }

    return validationErrors;
  };

  const handleRescheduleClick = () => {
    const validationErrors = validation();

    if (Object.keys(validationErrors).length === 0) {
      let currentDate = moment();
      let communitydate = moment(value);

      if (moment(communitydate).diff(currentDate, "days") < 0) {
        infoToast("Please select a future date");
        setTimeout(() => {
          toast.clearWaitingQueue();
        }, 2000);
      } else if (
        moment(currentDate).isSame(communitydate, "day") &&
        moment(selectedTime, "h:mma").isBefore(moment(new Date(), "h:mma"))
      ) {
        infoToast("Please select a proper time interval");
        setTimeout(() => {
          toast.clearWaitingQueue();
        }, 2000);
      } else {
        const data = {
          onDate: value.toISOString(),
          startTime: utcDateTimeString(selectedTime),
          endTime: utcDateTimeString(toTime),
        };
        dispatch(rescheduleCommunitySession(communityId, data));
      }
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <ModalOuter
      state={state}
      setState={setState}
      style={{ minWidth: "420px" }}
      classNames="p-7 py-4 text-left animate-modalInitial"
    >
      <ModalHeader
        heading="Reschedule date and time"
        closeIcon={true}
        setState={setState}
      />

      <Calendar
        onChange={(date) => calendarBookingOnChange(date, setValue)}
        value={value}
      />
      <div className="flex space-x-3 ">
        <div className="flex items-center flex-1 space-x-2">
          <p className="font-medium text-lg" style={{ color: "#333" }}>
            From
          </p>
          <AppTimePicker
            className="flex-1"
            value={selectedTime}
            required={true}
            onChange={(value) => {
              let sm = formError;
              delete sm[["selectedTime"]];
              setFormError({ ...formError, ...sm });
              setSelectedTime(value);
            }}
            onSelect={() => {
              setSelectedTime(null);
            }}
          />
        </div>
        <div className="flex items-center flex-1 space-x-2">
          <p className="font-medium text-lg" style={{ color: "#333" }}>
            To
          </p>

          <AppTimePicker
            className="flex-1"
            value={toTime}
            required={true}
            onChange={(value) => {
              let sm = formError;
              delete sm[["toTime"]];
              setFormError({ ...formError, ...sm });
              setToTime(value);
            }}
            onSelect={() => {
              setToTime(null);
            }}
          />
        </div>
      </div>
      <div className=" text-red-600 text-base mb-3">
        <p className="float-left">{formError?.selectedTime}</p>
        <p className="float-right">{formError?.toTime}</p>
      </div>

      <CardButton
        text="Reschedule date and time"
        linearBg={true}
        classNames="font-medium text-base rounded-md justify-between"
        onClick={() => handleRescheduleClick()}
        loader={rescheduleCommunitySessionBegin}
      />
    </ModalOuter>
  );
};

export default RescheduleCommunityModal;
