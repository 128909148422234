import React from "react";

import FeedSection from "../FeedSection";
import AdminDashboardLayout from "./AdminDashboardLayout";

const AdminFeeds = () => {
  return (
    <AdminDashboardLayout>
      <FeedSection isAdmin={true} />
    </AdminDashboardLayout>
  );
};

export default AdminFeeds;
