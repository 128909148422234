import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
import SeoData from "../../../components/utilities/seo/seoData.json";
import { getClientList } from "../../../AppRedux/actions/MentorMyClients";
import { ListHeader } from "../memberDashboard/tableComponents/ExercisesList";
import { NoListToShowContainer } from "../dashboardGeneralItems";
import mentorPageData from "../../../components/utilities/content/mentorDashboard.json";
import SubTabs from "./SubTabs";
import { PageNumbers } from "../dashboardGeneralItems";
import OutsideClickCloseContainer from "../../utilities/OutsideClickCloseContainer";
import { MoreIconContainer } from "../dashboardGeneralItems";
import { UserAvatar, Scaleloader, ReactHelmet } from "../../utilities";
import ChatWidgetModal from "./ChatWidgetModal";
import { getMentorProfile } from "../../../AppRedux/actions/MentorDashborad";
import { utcTimeToLocalTime } from "../MyCalendarGeneralItems";

const ClientList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);
  const [params, setParams] = useSearchParams();
  const tab = params.getAll("tab").toString();
  const id = params.getAll("id").toString();
  const [currentPage, setcurrentPage] = useState(1);
  const [mentorUid, setMentorUid] = useState("");
  const { getMentorProfileSuccessData } = useSelector(
    ({ mentorDashboard }) => mentorDashboard
  );
  const { getClientListBegin, getClientListSuccessData } = useSelector(
    ({ mentorMyClients }) => mentorMyClients
  );

  useEffect(() => {
    dispatch(getMentorProfile());
  }, [dispatch]);

  useEffect(() => {
    if (getMentorProfileSuccessData) {
      setMentorUid(getMentorProfileSuccessData.data.uid);
    }
  }, [getMentorProfileSuccessData]);
  useEffect(() => {
    if (getClientListSuccessData) {
      setClients(getClientListSuccessData.data.rows);
      setClientsCount(getClientListSuccessData.data.count);
    }
  }, [getClientListSuccessData]);

  useEffect(() => {
    if (tab === "feeds-chat") {
      setParams({
        tab: "feeds-chat",
      });
    }
    if (tab === "" || id === "") {
      navigate("/mentor/dashboard");
    }
    if (tab !== "") {
      if (
        tab !== "assessment" &&
        tab !== "curriculum" &&
        tab !== "task" &&
        tab !== "notes" &&
        tab !== "session"
      ) {
        setParams({
          tab: "assessment",
          id: id,
        });
      }
    } else {
      dispatch(getClientList(currentPage));
    }
  }, [tab, id, navigate, setParams, dispatch, currentPage]);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  useEffect(() => {
    const cookies = new Cookies(null, { path: '/' });
    if(cookies.get('geolocation')) {
      moment.tz.setDefault(cookies.get('geolocation'));
    }
  }, []);

  if (tab && id) {
    return <SubTabs selectedCell={tab} />;
  }

  return (
    <div className="overflow-x-scroll 950px:overflow-x-auto flex-auto min-h-80vh relative pb-14 md:w-full bg-white rounded-t-7px">
      <ReactHelmet data={SeoData.mentorClients} />
      <div className="" style={{ minWidth: "520px" }}>
        <div className="min-h-80vh relative pb-14">
          <ListHeader
            headers={headers}
            gridClass="grid-cols-3 xl:grid-cols-3"
          />
          {/*Client List */}
          <div className="member-table-row-items">
            {!getClientListBegin ? (
              clients.length !== 0 ? (
                clients.map(({ UserMember }, index) => {
                  const {
                    id,
                    firstName,
                    lastName,
                    Bookings,
                    CurriculumQuestionAnswers,
                    uid,
                    profilePhoto,
                  } = UserMember.User;

                  const nextMeetingDate =
                    Bookings.length > 0
                      ? moment(new Date(Bookings[0].meetingDate)).utc().hour(moment(Bookings[0].fromTime).hours()).minute(Bookings[0].fromTime.split(":")[1]).calendar()
                      : "No meeting scheduled";

                  if (UserMember !== null) {
                    return (
                      <div
                        key={index}
                        className={`dashboardListHover member-table-row-item grid-cols-myClientsList gap-2 font-medium text-sm xl:text-base
                    ${index === clients.length - 1 ? "border-none" : ""}`}
                      
                      >
                        <div className="flex justify-start items-center space-x-2 oneLineContent">
                          <UserAvatar
                            name={firstName}
                            classNames="w-9 h-9"
                            style={{ minWidth: "36px", minHeight: "36px" }}
                            profilePhoto={profilePhoto}
                            imgClassNames="w-9 h-9"
                          />
                          <p className="oneLineContent">
                            {firstName} {lastName}
                          </p>
                        </div>

                        {/* to find latest exercise */}
                        
                        <p
                          className={`whitespace-nowrap pt-2 ${Bookings.length > 0 ? "font-extrabold" : ""
                            }`}
                        >
                          {nextMeetingDate}
                        </p>

                        <div className="relative pt-3">
                          <MoreIconContainer
                            onClick={(e) => {
                              e.stopPropagation(); // for prevent click function of it's parent
                              toggle(UserMember.User.id);
                            }}
                            open={clicked === UserMember.User.id}
                          />

                          {clicked === UserMember.User.id && (
                            <OutsideClickCloseContainer setState={setClicked}>
                              <ClientDropdown
                                data={mentorPageData.myClients.dropdownList}
                                id={UserMember.User.id}
                                mentorUid={mentorUid}
                                memberUid={uid}
                              />
                            </OutsideClickCloseContainer>
                          )}
                        </div>
                      </div>
                    );
                  }
                  return "";
                })
              ) : (
                <div className=" rounded-lg ">
                  <NoListToShowContainer text=" There are no clients under you" />
                </div>
              )
            ) : (
              <Scaleloader />
            )}
          </div>
        </div>
      </div>
      <PageNumbers
        count={clientsCount}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={getClientListBegin}
        limit={10}
        onPageNumberClick={(number) => {
          dispatch(getClientList(number, id));
        }}
      />
    </div>
  );
};

export default ClientList;

export const ClientDropdown = ({ data, id, mentorUid, memberUid }) => {
  const [, setParams] = useSearchParams();
  const [openMemberChatModal, setOpenMemberChatModal] = useState(false);

  return (
    <div className="clientDropDownList" style={{ minWidth: "150px" }}>
      {data.map(({ text }, index) => (
        <div
          key={index}
          className="text-sm font-normal cursor-pointer activeState px-5 py-1.5 dashboardHover"
          onClick={(e) => {
            e.stopPropagation();
            if (text === "Send Message") {
              setOpenMemberChatModal(true);
            } else {
              setParams({
                tab: text === "Add Note" ? "notes" : "task",
                id: id,
                action: text === "Add Note" ? "add-notes" : "assign-task",
              });
            }
          }}
        >
          {text}
        </div>
      ))}
      {openMemberChatModal && (
        <ChatWidgetModal
          state={openMemberChatModal}
          setState={setOpenMemberChatModal}
          mentorUid={mentorUid}
          header="Message Member"
          defaultUid={memberUid}
        />
      )}
    </div>
  );
};

const headers = ["Name", "Next Meeting"];
