import React from "react";
import AdminDashboardLayout from "./AdminDashboardLayout";
import AdminManagerCard from "../../components/pageComponents/adminDashboard/AdminManagerCard";

const AdminManager = () => {
  return (
    <AdminDashboardLayout>
      <AdminManagerCard />
    </AdminDashboardLayout>
  );
};

export default AdminManager;
