import { CompositeDecorator } from "draft-js";

import { DeleteIcon as DeleteIconSvg } from "./svg";
import footerSun from "../../assets/Images/SunsFooter.png";
import config from "../../axios/configServer";

// scroll to top
export const scrollToTop = (scrollStepInPx, delayInMs) => {
  let intervalId = 0;
  const scrollStep = () => {
    if (window.pageYOffset === 0) {
      clearInterval(intervalId);
    }
    window.scroll(0, window.pageYOffset - scrollStepInPx);
  };
  const scrollToTop = () => {
    let intervalId2 = setInterval(scrollStep.bind(this), delayInMs);
    intervalId = intervalId2;
  };
  scrollToTop();
};

export const alertUserForReload = (e) => {
  e.preventDefault();
  e.returnValue = "";
};

export const DeleteIcon = ({ onClick, classNames, input }) => (
  <div
    className={`text-deleteIcon min-w-28px min-h-28px w-6 h-6 dashboardIcon border border-brandBlue bg-white ${classNames} ${
      input ? "absolute top-1/2 -right-6 -translate-y-1/2 m-2" : ""
    }`}
  >
    <DeleteIconSvg
      className="fill-current"
      style={{ width: "17px", height: "17px" }}
      onClick={(e) => onClick(e)}
    />
  </div>
);

export const SunFooter = () => (
  <img src={footerSun} alt="" width="100%" height="100%" />
);

//image url
export const url = config[process.env.NODE_ENV].BASE_API;

//editor stories links

const Link = ({ entityKey, contentState, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

export default createLinkDecorator;
