import React from "react";
import ViewMembersList from "../../components/pageComponents/adminDashboard/adminMember/ViewMembersList";
import AdminDashboardLayout from "./AdminDashboardLayout";

const AdminMembers = () => {
  return (
    <AdminDashboardLayout>
      <ViewMembersList />
    </AdminDashboardLayout>
  );
};

export default AdminMembers;
