import React from "react";
import ViewMentorsList from "../../components/pageComponents/adminDashboard/adminMentor/ViewMentorsList";
import AdminDashboardLayout from "./AdminDashboardLayout";

const AdminMentors = () => {
  return (
    <AdminDashboardLayout>
      <ViewMentorsList />
    </AdminDashboardLayout>
  );
};

export default AdminMentors;
