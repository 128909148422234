import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormButton } from "../../utilities/Button";
import { ModalSuccessGreenIcon, ModalSuccessGilts, ModalSuccessCouponValidated } from "../../utilities/svg";
import { useDispatch } from "react-redux";

import {
  assignMentorToMember,
} from "../../../AppRedux/actions/MemberDashboard";

import {
  getMemberDashboardDataFromLocalStorage,
  getUserDataFromLocalStorage
} from "../../utilities";

const SuccessModal = ({ content, btnOnClick, closeSuccessModal, moodle, redirect }) => {
  const { heading, button, icon } = content;
  const navigate = useNavigate();
  const [closeModal, setcloseModal] = useState(false);
  const User = getUserDataFromLocalStorage();
  const dispatch = useDispatch();

  const {
    mentor
  } = getMemberDashboardDataFromLocalStorage();

  useEffect(() => {
    if (moodle) {
      dispatch(assignMentorToMember(User));
      localStorage.setItem("assignMentor", true);
    }

    window.gtag('event', 'page_view', {
      "page_title": "Success Page",
    });

  }, []);

  useEffect(() => {

    if(redirect) {
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }

  }, []);  

  const moodleLogin = async () => {

    const User = getUserDataFromLocalStorage();

    const updatedData         = {};
    updatedData.isNewAccount  = true;

    const API_URL = "https://merity-api.mymerity.com";

    try {
      const response = await fetch( API_URL + '/auth/sso', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: User.email, email: User.email, firstName: User.firstName, lastName: User.lastName }),
      });
      

      const tempdata = await response.json();
      // console.log(tempdata.samlResponse);

  
      // post the SAMlResponse to the moodle instance like form data
      const form        = document.createElement('form');
      form.method       = 'POST';
      form.action       = 'https://sessions.mymerity.com/auth/saml2/sp/saml2-acs.php/sessions.mymerity.com';
      const hiddenField = document.createElement('input');
      hiddenField.type  = 'hidden';
      hiddenField.name  = 'SAMLResponse';
      hiddenField.value = tempdata.data.samlResponse.context;

      form.appendChild(hiddenField);
      document.body.appendChild(form);
      form.submit();
    } catch (err) {
      console.log("Something went wrong");
    }
    
  }

  return (
    <>
      {!closeModal ? (
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 animate-modalInitial">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="animate-pulse">
                <ModalSuccessGilts className="absolute animate-successModal transform -translate-y-full" />
                <ModalSuccessGilts className="absolute animate-successModalTwo transform -translate-y-full" />
              </div>
              <div className="p-3 370px:p-8 500px:p-14 flex flex-col justify-center items-center">

                { icon ? <ModalSuccessCouponValidated className="w-48 lg:w-72 relative z-10" /> :  <ModalSuccessGreenIcon className="mb-3 370px:mb-7 500px:mb-10 relative z-10" /> }
               
                <h1 className="font-medium text-lg 370px:text-xl 500px:text-lg md:text-xl text-center mb-12">

                  {heading.dark && (
                    <span   dangerouslySetInnerHTML={{ __html: heading.dark }}></span>
                  )}

                  {heading.blue && (
                    <span className="text-brandBlue">{heading.blue}</span>
                  )}
                </h1>
                {button.link === -1 ? (
                  <span className="block w-full" onClick={() => navigate(-1)}>
                   
                  </span>
                ) : btnOnClick ? (
                  <FormButton
                    onClick={() => {
                      setcloseModal(true);
                      btnOnClick();
                    }}
                    classNames="bg-brandBlue text-white"
                  >
                    {button.text}
                  </FormButton>
                ) : (
                  <>
                    {closeSuccessModal ? (
                      <FormButton
                        onClick={() => setcloseModal(true)}
                        classNames="bg-brandBlue text-white"
                      >
                        {button.text}
                      </FormButton>
                    ) :
                  
                     
                      moodle ? ( <Link
                        className="block w-full"
                        to={button.link}
                      >
                        <FormButton classNames="bg-brandBlue  text-white">
                          {button.text}
                        </FormButton>
                      </Link>
                      ) : (
                        <Link
                        className="block w-full"
                        onClick={() => setcloseModal(true)}
                        to={button.link}
                      >
                        <FormButton classNames="bg-brandBlue  text-white">
                          {button.text}
                        </FormButton>
                      </Link>
                      )

                    }

                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SuccessModal;
