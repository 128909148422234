import { UserAvatar, FormButton } from "./utilities";
import {
  LinkedinSmallIcon,
  GlobeIcon,
  RatingIcon,
  FileIcon,
} from "./utilities/svg";
import { url } from "./utilities/generalItems";

import MemberMentorDetailsPage from "./pageComponents/adminDashboard/adminManager/MemberMentorDetailsPage";
import { useState } from "react";

export const MentorResumePage = ({ data, type, MemberData, mentorId }) => {
  const [data1, SetData] = useState(false);
  const [id, setid] = useState("");

  const handleClientClick = (id) => {
    SetData(true);
    setid(id);
  };
  if (data1) {
    return <MemberMentorDetailsPage MemberData={id} />;
  }

  return (
    <div className="text-brandDarkBlue">
      {type !== "admin" && (
        <>
          <div className="flex items-center justify-start p-6">
            {/* Profile photo  */}
            <UserAvatar
              name={
                type === "manager"
                  ? `${data.firstName} ${data.lastName} `
                  : `${data?.name}`
              }
              classNames=" w-20 h-20 text-4xl "
              style={{
                boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
                border: "2px solid #FFFFFF",
              }}
              profilePhoto={data?.profilePhoto}
              imgClassNames="w-32 h-32 "
            />

            <div className="m-2">
              <h1 className="font-medium text-lg">{`${
                type === "manager"
                  ? `${data?.firstName} ${data?.lastName} `
                  : `${data?.name}`
              }`}</h1>
              <div className="flex -ml-3px">
                {data?.mentorship?.rating !== 0 && <RatingIcon />}
                <p className="font-semibold text-sm">
                  {data?.mentorship?.rating === 0 ? (
                    ""
                  ) : (
                    <>
                      {data?.mentorship?.rating?.toFixed(2)}
                      <span className="text-brandGrey">
                        ({data?.mentorship?.counts} reviews)
                      </span>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
          {type === "manager" && (
            <div className="p-6">
              {MemberData?.member?.count ? (
                <h1
                  className="font-bold text-lg mb-8"
                  style={{ color: "#272727" }}
                >
                  List of Members
                </h1>
              ) : (
                <h1
                  className="font-bold text-lg mb-8"
                  style={{ color: "#272727" }}
                >
                  No Member Available.
                </h1>
              )}
              <div className="flex flex-col justify-center">
                {MemberData?.member?.rows.map(({ key, UserMember }) => {
                  return (
                    <div
                      key={key}
                      className={`pt-2 relative ${
                        key < data?.UserMember?.rows.length
                          ? " border-l h-20"
                          : "ml-px"
                      }`}
                      style={{ borderColor: "#00AE4A" }}
                    >
                      {UserMember?.User?.firstName ? (
                        <div
                          className="absolute rounded-full w-2 h-2 mt-1"
                          style={{
                            backgroundColor: "#00AE4A",
                            width: "11px",
                            height: "11px",
                            left: "-6px",
                          }}
                        />
                      ) : (
                        ""
                      )}
                      <div
                        className="pl-3 mb-20px"
                        onClick={() => handleClientClick(UserMember)}
                      >
                        {UserMember?.User?.firstName ? (
                          <p className="font-medium text-sm cursor-pointer">
                            {UserMember?.User?.firstName +
                              " " +
                              UserMember?.User?.lastName}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="p-6">
            <h1 className="font-bold text-lg" style={{ color: "#272727" }}>
              Personal Information
            </h1>
            <div className="flex flex-col">
              <div className="mt-4 flex items-center space-x-2">
                <div
                  className="rounded-full w-2 h-2 "
                  style={{
                    backgroundColor: "#007CA5",
                    minWidth: "11px",
                    minHeight: "11px",
                  }}
                />
                <p className="font-medium text-sm">{data?.email}</p>
              </div>

        
            </div>
            <h1 className="my-4 font-extrabold text-black text-sm">About</h1>
            <p className="font-medium text-sm w-3/4 text-brandGrey">
              {data?.mentorship?.statement}
            </p>
          </div>
          <div className="font-normal border-b border-faq"></div>
        </>
      )}

      <div className="p-6">
        <h1 className="font-bold text-lg mb-8" style={{ color: "#272727" }}>
          Educational Information
        </h1>

        <div className="flex flex-col justify-center">
          {data?.mentorship?.university.map(({ id, university, program }) => {
            return (
              <div
                key={id}
                className={`relative ${
                  id < data?.mentorship?.university.length
                    ? " border-l h-20"
                    : "ml-px"
                }`}
                style={{ borderColor: "#00AE4A" }}
              >
                <div
                  className="absolute rounded-full w-2 h-2 "
                  style={{
                    backgroundColor: "#00AE4A",
                    width: "11px",
                    height: "11px",
                    left: "-6px",
                  }}
                />
                <div className="pl-3 -mt-10px">
                  <h1 className="font-medium text-lg">{university}</h1>
                  <h1 className="font-normal text-sm">{program}</h1>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="font-normal flex justify-between pt-2 border-b border-faq w-full"></div>
      <div className="p-6">
        <h1 className="font-bold text-lg" style={{ color: "#272727" }}>
          Certification
        </h1>

        <div className="flex mt-4 flex-col space-y-4 ">
          {data?.mentorship?.certification.map(({ id, certification }) => {
            return (
              <div key={id} className="flex space-x-2 ">
                <div
                  className="rounded-full w-2 h-2 mt-2  "
                  style={{
                    backgroundColor: "#007CA5",
                    width: "11px",
                    height: "11px",
                  }}
                />
                <div>
                  <p className="font-medium text-base">{certification}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="font-normal pt-2 border-b border-faq w-full"></div>
      {data?.mentorship?.coachingProgram ? (
        <div className="p-6">
          <h1 className="font-bold text-lg" style={{ color: "#272727" }}>
            Coaching Program
          </h1>

          <div className="flex mt-4 flex-col space-y-4 ">
            {data?.mentorship?.coachingProgram.map(({ id, program }) => {
              return (
                <div key={id} className="flex space-x-2 ">
                  <div
                    className="rounded-full w-2 h-2 mt-2  "
                    style={{
                      backgroundColor: "#007CA5",
                      width: "11px",
                      height: "11px",
                    }}
                  />
                  <div>
                    <p className="font-medium text-base">{program}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="font-normal pt-2 border-b border-faq w-full"></div>

      <div className="p-6">
        <h1 className="font-bold text-lg" style={{ color: "#272727" }}>
          Professional Information
        </h1>
        {data?.mentorship?.professionalInfo?.linkedin && 
        <div className="mt-4 flex items-center space-x-2">
          <div
            className="rounded-full w-2 h-2 "
            style={{
              backgroundColor: "#E6A324",
              width: "11px",
              height: "11px",
            }}
          />
          <LinkedinSmallIcon />
          <a
            href={`${data?.mentorship?.professionalInfo.linkedin}`}
            rel="noreferrer"
            target="_blank"
          >
            LinkedIn
          </a>
        </div>
        }
        

        {data?.mentorship?.professionalInfo?.website &&
          <div className="mt-4 flex items-center space-x-2">
            <div
              className="rounded-full w-2 h-2 "
              style={{
                backgroundColor: "#E6A324",
                width: "11px",
                height: "11px",
              }}
            />
            <GlobeIcon />
            <a
              href={`${data?.mentorship?.professionalInfo.website.indexOf('http') > -1 ? data?.mentorship?.professionalInfo.website : '//' + data?.mentorship?.professionalInfo.website}`}
              rel="noreferrer"
              target="_blank"
            >
              Website
            </a>
          </div>}
      </div>
      <div className="font-normal pt-2 border-b border-faq w-full"></div>

      <div className="p-6">
        <h1 className="font-bold text-lg" style={{ color: "#272727" }}>
          Area of Expertise
        </h1>

        <div className="flex flex-wrap gap-4">
          {data?.mentorship?.focusArea.map(({ id, title }) => {
            return (
              <div key={id} className=" flex items-start space-x-2">
                <div
                  className="rounded-full w-2 h-2 mt-2"
                  style={{
                    backgroundColor: "#0181AC",
                    minWidth: "11px",
                    minHeight: "11px",
                  }}
                />
                <p>{title}</p>
              </div>
            );
          })}
        </div>
      </div>
      {type === "manager" && (
        <>
          <div className="font-normal pt-2 border-b border-faq w-full "></div>
          <FormButton
            classNames={`bg-brandBlue mt-6 text-white w-1/3 text-sm my-8 mx-4`}
            onClick={() =>
              window.open(
                `${url}/file?path=${data?.mentorship?.resume}`,
                "_blank"
              )
            }
          >
            <FileIcon />
            {`View ${data?.firstName}'s Resume`}
          </FormButton>
        </>
      )}
    </div>
  );
};
