import {
  GET_ADMIN_DETAILS_BEGIN,
  GET_ADMIN_DETAILS_SUCCESS,
  GET_ADMIN_DETAILS_FAILURE,
  RESET_GET_ADMIN_DETAILS,
  UPDATE_ADMIN_DETAILS_BEGIN,
  UPDATE_ADMIN_DETAILS_SUCCESS,
  UPDATE_ADMIN_DETAILS_FAILURE,
  RESET_UPDATE_ADMIN_DETAILS,
  VIEW_PROMOCODE_DETAILS_BEGIN,
  VIEW_PROMOCODE_DETAILS_SUCCESS,
  VIEW_PROMOCODE_DETAILS_FAILURE,
  RESET_VIEW_PROMOCODE_DETAILS,
  CREATE_PROMOCODE_BEGIN,
  CREATE_PROMOCODE_SUCCESS,
  CREATE_PROMOCODE_FAILURE,
  RESET_CREATE_PROMOCODE,
  DELETE_PROMOCODE_BEGIN,
  DELETE_PROMOCODE_SUCCESS,
  DELETE_PROMOCODE_FAILURE,
  RESET_DELETE_PROMOCODE,
} from "../constants/AdminSettings";

const initialState = {
  getAdminDetailsBegin: false,
  getAdminDetailsSuccess: false,
  getAdminDetailsSuccessData: null,
  getAdminDetailsFailure: false,
  getAdminDetailsFailureData: null,

  updateAdminDetailsBegin: false,
  updateAdminDetailsSuccess: false,
  updateAdminDetailsSuccessData: null,
  updateAdminDetailsFailure: false,
  updateAdminDetailsFailureData: null,

  viewPromoCodeDetailsBegin: false,
  viewPromoCodeDetailsSuccess: false,
  viewPromoCodeDetailsSuccessData: null,
  viewPromoCodeDetailsFailure: false,
  viewPromoCodeDetailsFailureData: null,

  createPromoCodeBegin: false,
  createPromoCodeSuccess: false,
  createPromoCodeSuccessData: null,
  createPromoCodeFailure: false,
  createPromoCodeFailureData: null,

  deletePromoCodeBegin: false,
  deletePromoCodeSuccess: false,
  deletePromoCodeSuccessData: null,
  deletePromoCodeFailure: false,
  deletePromoCodeFailureData: null,
};

export const adminSettings = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // get admin details
    case GET_ADMIN_DETAILS_BEGIN:
      return {
        ...state,
        getAdminDetailsBegin: true,
        getAdminDetailsSuccess: false,
        getAdminDetailsSuccessData: null,
        getAdminDetailsFailure: false,
        getAdminDetailsFailureData: null,
      };
    case GET_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        getAdminDetailsBegin: false,
        getAdminDetailsSuccess: true,
        getAdminDetailsSuccessData: data,
        getAdminDetailsFailure: false,
        getAdminDetailsFailureData: null,
      };
    case GET_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        getAdminDetailsBegin: false,
        getAdminDetailsSuccess: false,
        getAdminDetailsSuccessData: null,
        getAdminDetailsFailure: true,
        getAdminDetailsFailureData: data,
      };

    case RESET_GET_ADMIN_DETAILS:
      return {
        ...state,
        getAdminDetailsBegin: false,
        getAdminDetailsSuccess: false,
        getAdminDetailsSuccessData: null,
        getAdminDetailsFailure: false,
        getAdminDetailsFailureData: null,
      };
    //update admin details

    case UPDATE_ADMIN_DETAILS_BEGIN:
      return {
        ...state,
        updateAdminDetailsBegin: true,
        updateAdminDetailsSuccess: false,
        updateAdminDetailsSuccessData: null,
        updateAdminDetailsFailure: false,
        updateAdminDetailsFailureData: null,
      };

    case UPDATE_ADMIN_DETAILS_SUCCESS:
      return {
        ...state,
        updateAdminDetailsBegin: false,
        updateAdminDetailsSuccess: true,
        updateAdminDetailsSuccessData: data,
        updateAdminDetailsFailure: false,
        updateAdminDetailsFailureData: null,
      };

    case UPDATE_ADMIN_DETAILS_FAILURE:
      return {
        ...state,
        updateAdminDetailsBegin: false,
        updateAdminDetailsSuccess: false,
        updateAdminDetailsSuccessData: null,
        updateAdminDetailsFailure: true,
        updateAdminDetailsFailureData: data,
      };

    case RESET_UPDATE_ADMIN_DETAILS:
      return {
        ...state,
        updateAdminDetailsBegin: false,
        updateAdminDetailsSuccess: false,
        updateAdminDetailsSuccessData: null,
        updateAdminDetailsFailure: false,
        updateAdminDetailsFailureData: null,
      };

    //view promo codes

    case VIEW_PROMOCODE_DETAILS_BEGIN:
      return {
        ...state,
        viewPromoCodeDetailsBegin: true,
        viewPromoCodeDetailsSuccess: false,
        viewPromoCodeDetailsSuccessData: null,
        viewPromoCodeDetailsFailure: false,
        viewPromoCodeDetailsFailureData: null,
      };

    case VIEW_PROMOCODE_DETAILS_SUCCESS:
      return {
        ...state,
        viewPromoCodeDetailsBegin: false,
        viewPromoCodeDetailsSuccess: true,
        viewPromoCodeDetailsSuccessData: data,
        viewPromoCodeDetailsFailure: false,
        viewPromoCodeDetailsFailureData: null,
      };

    case VIEW_PROMOCODE_DETAILS_FAILURE:
      return {
        ...state,
        viewPromoCodeDetailsBegin: false,
        viewPromoCodeDetailsSuccess: false,
        viewPromoCodeDetailsSuccessData: null,
        viewPromoCodeDetailsFailure: true,
        viewPromoCodeDetailsFailureData: data,
      };

    case RESET_VIEW_PROMOCODE_DETAILS:
      return {
        ...state,
        viewPromoCodeDetailsBegin: false,
        viewPromoCodeDetailsSuccess: false,
        viewPromoCodeDetailsSuccessData: null,
        viewPromoCodeDetailsFailure: false,
        viewPromoCodeDetailsFailureData: null,
      };

    //create promo code

    case CREATE_PROMOCODE_BEGIN:
      return {
        ...state,
        createPromoCodeBegin: true,
        createPromoCodeSuccess: false,
        createPromoCodeSuccessData: null,
        createPromoCodeFailure: false,
        createPromoCodeFailureData: null,
      };

    case CREATE_PROMOCODE_SUCCESS:
      return {
        ...state,
        createPromoCodeBegin: false,
        createPromoCodeSuccess: true,
        createPromoCodeSuccessData: data,
        createPromoCodeFailure: false,
        createPromoCodeFailureData: null,
      };

    case CREATE_PROMOCODE_FAILURE:
      return {
        ...state,
        createPromoCodeBegin: false,
        createPromoCodeSuccess: false,
        createPromoCodeSuccessData: null,
        createPromoCodeFailure: true,
        createPromoCodeFailureData: data,
      };

    case RESET_CREATE_PROMOCODE:
      return {
        ...state,
        createPromoCodeBegin: false,
        createPromoCodeSuccess: false,
        createPromoCodeSuccessData: null,
        createPromoCodeFailure: false,
        createPromoCodeFailureData: null,
      };

    //delete promo code

    case DELETE_PROMOCODE_BEGIN:
      return {
        ...state,
        deletePromoCodeBegin: true,
        deletePromoCodeSuccess: false,
        deletePromoCodeSuccessData: null,
        deletePromoCodeFailure: false,
        deletePromoCodeFailureData: null,
      };

    case DELETE_PROMOCODE_SUCCESS:
      return {
        ...state,
        deletePromoCodeBegin: false,
        deletePromoCodeSuccess: true,
        deletePromoCodeSuccessData: data,
        deletePromoCodeFailure: false,
        deletePromoCodeFailureData: null,
      };

    case DELETE_PROMOCODE_FAILURE:
      return {
        ...state,
        deletePromoCodeBegin: true,
        deletePromoCodeSuccess: false,
        deletePromoCodeSuccessData: null,
        deletePromoCodeFailure: true,
        deletePromoCodeFailureData: data,
      };

    case RESET_DELETE_PROMOCODE:
      return {
        ...state,
        deletePromoCodeBegin: false,
        deletePromoCodeSuccess: false,
        deletePromoCodeSuccessData: null,
        deletePromoCodeFailure: false,
        deletePromoCodeFailureData: null,
      };

    default:
      return state;
  }
};
