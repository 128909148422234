import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import { convertFromRaw, Editor } from "draft-js";
import InfiniteScroll from "react-infinite-scroll-component";

import { AdminMentorDropdownSelectArrow } from "../../utilities/svg";
import { errorToast, Scaleloader } from "../../utilities";
import { NoListToShowContainer } from "../dashboardGeneralItems";
import { DeleteIcon } from "../../utilities";
import ModalOuter from "../../utilities/modals/ModalOuter";
import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";
import { ModalHeader } from "../MyCalendarGeneralItems";
import { CheckBoxGroup } from "../../utilities/newFormInputs";
import {
  viewAllExercise,
  addNewExercise,
  editExercise,
  addNewTif,
  viewAllTakeItFurther,
  editTif,
} from "../../../AppRedux/actions/AdminDashboard";
import { InputGroup, successToast } from "../../utilities";
import { FormatEditor } from "../../utilities";

const CreateCurriculumModal = ({
  state,
  setState,
  setExercises,
  formData,
  setformData,
  editEditorState,
  editEditorStateList,
  editClicked,
  currentPage,
  takeItFurtherClicked,
  tifId,
  setTifId,
  tifTitle,
  setTifTitle,
  takeItFurther,
  setTakeItFurther,
}) => {
  const dispatch = useDispatch();
  const [continueClicked, setContinueClicked] = useState(false);
  const [formError, setFormError] = useState({});
  const [questions, setQuestions] = useState([{ question: "", subtitle: "" }]);
  const [displayQuestion, setDisplayQuestions] = useState([]);
  const [items, setItems] = useState([]);
  const [tifCount, setTifCount] = useState([]);
  const [curriculumPick, setCurriculumPick] = useState(false);
  const [isMandatory] = useState(false);
  const [tifListPageNumber, setTifListPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loader, setLoader] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [editorStateList, setEditorStateList] = useState(() => [
    EditorState.createEmpty(),
  ]);

  const [styledState, setStyledState] = useState(
    convertToRaw(editorState.getCurrentContent())
  );

  const [questionsformError, setQuestionsFormError] = useState({
    questions: {},
  });

  const {
    addNewExerciseSuccessData,
    addNewExerciseBegin,
    editExerciseBegin,
    editExerciseSuccessData,
    addNewTifSuccessData,
    viewAllTifBegin,
    viewAllTifSuccessData,
    editTifSuccessData,
  } = useSelector(({ adminDashboard }) => adminDashboard);

  useEffect(() => {
    if (!takeItFurtherClicked) dispatch(viewAllTakeItFurther(1, 100));
  }, [takeItFurtherClicked, dispatch]);

  useEffect(() => {
    if (viewAllTifSuccessData) {
      const tifList = viewAllTifSuccessData?.data;
      setItems((tifs) => [...tifs, ...tifList?.rows]);
      setTifCount(tifList?.count);
    }
  }, [viewAllTifSuccessData]);

  useEffect(() => {
    if (editClicked) {
      setEditorState(editEditorState);
      setEditorStateList(editEditorStateList);
    }
  }, [editClicked, editEditorState, editEditorStateList]);

  useEffect(() => {
    if (addNewExerciseSuccessData) {
      const id = addNewExerciseSuccessData.data.id;
      setState(false);
      successToast("New Curriculum Created");
      setExercises((exercises) => [{ ...formData, id }, ...exercises]);
      dispatch(viewAllExercise(currentPage));
    }
    if (editExerciseSuccessData) {
      successToast("Curriculum Edited Successfully");
      setState(false);
      dispatch(viewAllExercise(currentPage));
    }
    if (addNewTifSuccessData) {
      setLoader(false);
      successToast("New TIF Created");
      dispatch(viewAllTakeItFurther(currentPage));
      setState(false);
    }
    if (editTifSuccessData) {
      setLoader(false);
      successToast("TIF edited successfully");
      dispatch(viewAllTakeItFurther(currentPage));
      setState(false);
    }
  }, [
    addNewExerciseSuccessData,
    setState,
    setExercises,
    formData,
    state,
    editExerciseSuccessData,
    dispatch,
    currentPage,
    addNewTifSuccessData,
    editTifSuccessData,
  ]);

  useEffect(() => {
    setformData((formData) => ({
      ...formData,
      exercise: editorState.getCurrentContent().getPlainText(),
    }));
    setStyledState(convertToRaw(editorState.getCurrentContent()));
  }, [editorState, setformData]);

  const customMemberBasicFormValidation = () => {
    const { title, exercise, career, emotional, passion } = formData;

    const validationErrors = {};
    if (title?.trim() === "") {
      validationErrors.title = "Title is required.";
    }
    if (title?.trim() !== "" && title.length < 3) {
      validationErrors.title = "please give a valid title.";
    }

    if (exercise?.trim() === "") {
      validationErrors.exercise = "Exercise is required.";
    }
    if (exercise?.trim() !== "" && exercise.length < 3) {
      validationErrors.exercise = "please give a valid exercise.";
    }
    if (!takeItFurtherClicked) {
      if (!career && !emotional && !passion) {
        validationErrors.curriculumType = "Select any of one curriculum type.";
      }
    }

    return validationErrors;
  };


  const questionsFormValidation = (questions) => {
    let validationErrors = {};
    Object.values(questions).forEach(({ question }, index) => {
      if (typeof question === "string") {
        if (question === null || question.trim() === "") {
          const inputKey = `question${index}`;
          validationErrors = {
            ...validationErrors,
            questions: {
              ...validationErrors.questions,
              [inputKey]: `This field is required.`,
            },
          };
        }
      } else {
        if (editorStateList[index].getCurrentContent().getPlainText() === "") {
          const inputKey = `question${index}`;
          validationErrors = {
            ...validationErrors,
            questions: {
              ...validationErrors.questions,
              [inputKey]: `This field is required.`,
            },
          };
        }
      }
    });
    return validationErrors;
  };
  
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const validationErrors = customMemberBasicFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      if (takeItFurther && tifTitle === "") {
        errorToast("Please select a take it further")
      }
      else {
        setContinueClicked(true);
        if (editClicked) {
          let newQuestionArr = [...questions];

          editEditorStateList?.map((value, index) => {
            return (newQuestionArr[index] = {
              question: convertToRaw(value.getCurrentContent()),
              subtitle: "",
            });
          });

          setQuestions(newQuestionArr);
        }
        setformData({ ...formData, exercise: styledState });

      }
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const handleQuestionsSubmit = (e) => {
    e.preventDefault();
    const questionsValidations = questionsFormValidation(questions);
    if (Object.keys(questionsValidations).length === 0) {
      let newFormData = { ...formData, questions };
      if (editClicked) {
        if (takeItFurtherClicked) {
          let data = {
            title: formData?.title,
            exercise: formData?.exercise,
            questions,
          };

          dispatch(editTif(data, state));
          setLoader(true);
        } else {
          let data = { ...newFormData, tifQuestions: [tifId] };
          dispatch(editExercise(data, state));
        }
      } else {
        if (takeItFurtherClicked) {
          newFormData = { ...newFormData, isMandatory: true };
          dispatch(addNewTif(newFormData));
          setLoader(true);
        } else {
          let data = {
            ...newFormData,
            isMandatory,
            tifQuestions: tifId === "" ? [] : [tifId],
          };
          dispatch(addNewExercise(data));
        }
      }
    } else {
      setQuestionsFormError({
        ...questionsformError,
        ...questionsValidations,
      });
    }
  };

  const handleInputOnchange = (e) => {
    let value = e.target.value;
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setformData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleCheckBoxOnchange = (value, name) => {
    let sm = formError;
    delete sm[["curriculumType"]];
    setFormError({ ...formError, ...sm });

    setformData({
      ...formData,
      [name]: value,
    });
  };

  const getTotalNumberOfPages = () => {
    const totalPages = parseInt(tifCount / 10),
      leftColumns = tifCount - totalPages * 10;
    return leftColumns > 0 ? totalPages + 1 : totalPages;
  };

  const fetchMoreData = () => {
    if (tifListPageNumber >= getTotalNumberOfPages()) {
      setHasMore(false);
      return;
    }
    dispatch(viewAllTakeItFurther(tifListPageNumber + 1));
    setTifListPageNumber(tifListPageNumber + 1);
  };

  const handleDropdown = (id, title) => {
    setTifId(id);
    setTifTitle(title);
    setCurriculumPick(false);
  };

  return (
    <ModalOuter
      state={state}
      setState={setState}
      classNames="p-7 text-left animate-modalInitial min-w-200px 500px:min-w-70% md:min-w-700px"
    >
      <ModalHeader
        heading={`Create ${takeItFurtherClicked ? "Take it further question" : "Reflection"
          }`}
        closeIcon={true}
        setState={setState}
      />{" "}
      <div
        className="px-2 scrollbar"
        id="scrollbar"
        style={{
          minWidth: "250px",
          height: "70vh",
          overflow: "auto",
        }}
      >
        {continueClicked ? (
          <form
            className="relative  mt-3 w-96% min-h-400px "
            onSubmit={(e) => handleQuestionsSubmit(e)}
          >
            {editorStateList?.map((_, index) => {
              const currentQuestionInputKey = `question${index}`;

              return (
                <div key={index} className="relative animate-navbar mt-4">
                  <FormatEditor
                    name={`question${index}`}
                    editorState={editorStateList[index]}
                    state={editorStateList}
                    setEditorState={setEditorStateList}
                    errorMessage={
                      questionsformError?.questions[currentQuestionInputKey]
                    }
                    setFormError={setFormError}
                    limit={100000}
                    editorHeight="200px"
                    multipleEditors={true}
                    index={index}
                    questions={questions}
                    setQuestions={setQuestions}
                    tab="question"
                    questionsformError={questionsformError}
                    setQuestionsFormError={setQuestionsFormError}
                  />
                  {index >= 1 && (
                    <DeleteIcon
                      input={true}
                      onClick={() => {
                        let questionsArray = [...questions];
                        questionsArray.splice(index, 1);
                        setQuestions(questionsArray);
                        setDisplayQuestions(questionsArray);

                        let editorStateArray = [...editorStateList];
                        editorStateArray.splice(index, 1);
                        setEditorStateList(editorStateArray);
                      }}
                    />
                  )}
                </div>
              );
            })}
            <button
              className="text-left font-semibold text-base activeState buttonHover my-2 "
              style={{ color: "#0E79A7" }}
              type="button"
              onClick={() => {
                let questionsArray = [
                  ...questions,
                  { question: "", subtitle: "" },
                ];
                setQuestions(questionsArray);
                setDisplayQuestions(questionsArray);
                setEditorStateList([
                  ...editorStateList,
                  EditorState.createEmpty(),
                ]);
              }}
            >
              + Add question
            </button>
            {displayQuestion.length > 0 &&
              displayQuestion?.map(({ question }, index) => (
                <div key={index}>
                  {question === "" ? (
                    ""
                  ) : (
                    <div
                      className="animate-navbar p-1 mt-2 oneLineContent w-96%"
                      style={{
                        border: "1px solid #E9E9E9",
                        borderRadius: "4px",
                      }}
                    >
                      <Editor
                        editorState={EditorState.createWithContent(
                          convertFromRaw(question)
                        )}
                        readOnly={true}
                      />
                    </div>
                  )}
                </div>
              ))}

            <div>
              <FormBackAndContinueButton
                buttonText="Save"
                backButtonText="Cancel"
                onBackButtonClick={() => setState(false)}
                actionBegin={
                  takeItFurtherClicked
                    ? loader
                    : editClicked
                      ? editExerciseBegin
                      : addNewExerciseBegin
                }
                buttonClass="py-2 text-base"
              />
            </div>
          </form>
        ) : (
          <form className="mt-3" onSubmit={(e) => handleFormSubmit(e)}>
            <InputGroup
              label="Enter title"
              name="title"
              onChange={(e) => handleInputOnchange(e)}
              value={formData.title}
              placeholder="Title"
              errorMessage={formError?.title}
            />
            <FormatEditor
              editorState={editorState}
              setEditorState={setEditorState}
              errorMessage={formError?.exercise}
              setFormError={setFormError}
              limit={100000}
              tab="task"
            />
            {!takeItFurtherClicked && (
              <div className="flex flex-col md:flex-row md:items-center gap-5 my-2">
                <CheckBoxGroup
                  onClick={() =>
                    handleCheckBoxOnchange(!formData.emotional, "emotional")
                  }
                  state={formData.emotional}
                  name="emotional"
                  label="Emotional"
                />
                <CheckBoxGroup
                  onClick={() =>
                    handleCheckBoxOnchange(!formData.career, "career")
                  }
                  state={formData.career}
                  name="career"
                  label="Career"
                />
                <CheckBoxGroup
                  onClick={() =>
                    handleCheckBoxOnchange(!formData.passion, "passion")
                  }
                  state={formData.passion}
                  name="passion"
                  label="Passion Project"
                />
              </div>
            )}

            <p className="text-red-600 text-base mt-1">
              {formError?.curriculumType}
            </p>

            {/* <div className="mt-4">
              <CheckBoxGroup
                onClick={() => {
                  setIsMandatory(!isMandatory);
                }}
                state={isMandatory}
                name="isMandatory"
                label="Is Mandatory"
              />
            </div> */}
            {!takeItFurtherClicked && (
              <div className="mt-4">
                <CheckBoxGroup
                  onClick={() => {
                    setTifTitle("")
                    setTakeItFurther(!takeItFurther);
                  }}
                  state={takeItFurther}
                  name="passion"
                  label="Map this relection to a take it further reflection"
                />
              </div>
            )}
            {takeItFurther && items?.length > 0 && (
              <>
                <div
                  className="p-4 mt-4 flex justify-between items-center rounded cursor-pointer "
                  onClick={() => {
                    setCurriculumPick(!curriculumPick);

                  }}
                  style={{
                    border: curriculumPick
                      ? "2px solid #007CA5"
                      : "1px solid #B9B9BB",
                  }}
                >
                  <h1>{tifTitle === "" ? "Select TIF" : tifTitle} </h1>
                  {curriculumPick ? (
                    <AdminMentorDropdownSelectArrow className=" rotate-180 " />
                  ) : (
                    <AdminMentorDropdownSelectArrow />
                  )}
                </div>

                {curriculumPick && (
                  <>
                    {items?.length > 0 ? (
                      <div
                        className="relative animate-navbar"
                        style={{
                          minWidth: "250px",
                          height: "40vh",
                          overflow: "auto",
                          border: "1px solid #B9B9BB",
                        }}
                        id="mentorsScrollableDiv"
                      >
                        <InfiniteScroll
                          dataLength={items.length}
                          next={fetchMoreData}
                          hasMore={hasMore}
                          loader={<h4>Loading...</h4>}
                          scrollableTarget="mentorsScrollableDiv"
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              {/* <b>Yay! You have seen it all</b> */}
                            </p>
                          }
                        >
                          {items?.map(({ title, id }, index) => {
                            return (
                              <div
                                onClick={() => handleDropdown(id, title)}
                                key={index}
                              >
                                {
                                  <h1 className="text-sm p-2 500px:text-base cursor-pointer hover:font-extrabold dashboardHover dashboardActive">
                                    {`${title}`}
                                  </h1>
                                }
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    ) : viewAllTifBegin ? (
                      <Scaleloader />
                    ) : (
                      <NoListToShowContainer text="Currently there are no tifs available!" />
                    )}
                  </>
                )}
              </>
            )}
            <FormBackAndContinueButton
              buttonText="Continue"
              backButtonText="Cancel"
              onBackButtonClick={() => setState(false)}
              actionBegin={
                editClicked ? editExerciseBegin : addNewExerciseBegin
              }
              buttonClass="py-2 text-base"
            />
          </form>
        )}
      </div>
    </ModalOuter>
  );
};

export default CreateCurriculumModal;
