import {
  CREATE_COMET_CHAT_USER_BEGIN,
  CREATE_COMET_CHAT_USER_SUCCESS,
  CREATE_COMET_CHAT_USER_FAILURE,
  RESET_CREATE_COMET_CHAT_USER,
  UPDATE_COMET_CHAT_USER_BEGIN,
  UPDATE_COMET_CHAT_USER_SUCCESS,
  UPDATE_COMET_CHAT_USER_FAILURE,
  RESET_UPDATE_COMET_CHAT_USER,
} from "../constants/MemberCometChat";

const initialState = {
  createCometChatUserBegin: false,
  createCometChatUserSuccess: false,
  createCometChatUserSuccessData: null,
  createCometChatUserFailure: false,
  createCometChatUserFailureData: null,

  updateCometChatUserBegin: false,
  updateCometChatUserSuccess: false,
  updateCometChatUserSuccessData: null,
  updateCometChatUserFailure: false,
  updateCometChatUserFailureData: null,
};

export const memberCometChat = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    //create comet chat user
    case CREATE_COMET_CHAT_USER_BEGIN:
      return {
        ...state,
        createCometChatUserBegin: true,
        createCometChatUserSuccess: false,
        createCometChatUserSuccessData: null,
        createCometChatUserFailure: false,
        createCometChatUserFailureData: null,
      };
    case CREATE_COMET_CHAT_USER_SUCCESS:
      return {
        ...state,
        createCometChatUserBegin: false,
        createCometChatUserSuccess: true,
        createCometChatUserSuccessData: data,
        createCometChatUserFailure: false,
        createCometChatUserFailureData: null,
      };
    case CREATE_COMET_CHAT_USER_FAILURE:
      return {
        ...state,
        createCometChatUserBegin: false,
        createCometChatUserSuccess: false,
        createCometChatUserSuccessData: null,
        createCometChatUserFailure: true,
        createCometChatUserFailureData: data,
      };
    case RESET_CREATE_COMET_CHAT_USER:
      return {
        ...state,
        createCometChatUserBegin: false,
        createCometChatUserSuccess: false,
        createCometChatUserFailure: false,
        createCometChatUserFailureData: null,
      };

    //update comet chat user
    case UPDATE_COMET_CHAT_USER_BEGIN:
      return {
        ...state,
        updateCometChatUserBegin: true,
        updateCometChatUserSuccess: false,
        updateCometChatUserSuccessData: null,
        updateCometChatUserFailure: false,
        updateCometChatUserFailureData: null,
      };
    case UPDATE_COMET_CHAT_USER_SUCCESS:
      return {
        ...state,
        updateCometChatUserBegin: false,
        updateCometChatUserSuccess: true,
        updateCometChatUserSuccessData: data,
        updateCometChatUserFailure: false,
        updateCometChatUserFailureData: null,
      };
    case UPDATE_COMET_CHAT_USER_FAILURE:
      return {
        ...state,
        updateCometChatUserBegin: false,
        updateCometChatUserSuccess: false,
        updateCometChatUserSuccessData: null,
        updateCometChatUserFailure: true,
        updateCometChatUserFailureData: data,
      };
    case RESET_UPDATE_COMET_CHAT_USER:
      return {
        ...state,
        updateCometChatUserBegin: false,
        updateCometChatUserSuccess: false,
        updateCometChatUserSuccessData: null,
        updateCometChatUserFailure: false,
        updateCometChatUserFailureData: null,
      };

    default:
      return state;
  }
};
