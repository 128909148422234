import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";

import PageData from "../../utilities/content/pages.json";
import statusData from "../../utilities/content/statusAndRoleManagement.json";
import MemberRegistrationVerifyOTPForm from "../MemberRegisterForms/MemberRegistrationVerifyOTPForm";
import { getMemberDashboardDetails } from "../../../AppRedux/actions/MemberDashboard";
import { resendMemberRegistrationOtp } from "../../../AppRedux/actions/MemberRegistration";
import { GoogleIcon, FacebookIcon } from "../../utilities/svg";
import FacebookLoginButton from "../../utilities/FacebookLoginButton";
import { submitAdminLogin } from "../../../AppRedux/actions/AdminAuth";
import { getMentorDashboardDetails } from "../../../AppRedux/actions/MentorDashborad";
import {
  memberFacebookLogin,
  submitMemberLoginForm,
  memberGoogleLogin,
} from "../../../AppRedux/actions/MemberLogin";
import {
  Cliploader,
  errorToast,
  FormButton,
  SocialMediaButton,
  CliploaderBlue,
  storeMemberDetailsInLocalStorage,
  storeMemberDashboardDetailsInLocalStorage,
  storeMemberSignUpStatusInLocalStorage,
  InputGroup,
  InputGroupEye,
  storeAdminDetailsInLocalStorage,
} from "../../utilities";

const LoginForm = ({ role }) => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    [userEmail, setUserEmail] = useState(null),
    [googleLoginLoader, setGoogleLoginLoader] = useState(false),
    [formError, setFormError] = useState({}),
    [redirectToOtpVerificationPage, setRedirectToOtpVerificationPage] =
      useState(false),
    [redirectToSubscriptionPage, setRedirectToSubscriptionPage] =
      useState(false),
    [redirectToMentorDashboard, setRedirectToMentorDashboard] = useState(false),
    [assessmentCompleted, setAssessmentCompleted] = useState(false),
    [redirectToManagerDashboard, setRedirectToManagerDashboard] =
      useState(false),
    { heading, memberLoginCheckbox } = PageData.memberLoginPageData,
    [loginData, setLoginData] = useState({
      email: "",
      password: "",
      rememberPassword: false,
    });

  const {
    submitMemberLoginFormBegin,
    submitMemberLoginFormFailureData,
    submitMemberLoginFormSuccessData,

    submitMemberGoogleLoginFormBegin,
    submitMemberGoogleLoginFormSuccessData,
    submitMemberGoogleLoginFormFailureData,

    submitMemberFacebookLoginFormBegin,
    submitMemberFacebookLoginFormSuccessData,
    submitMemberFacebookLoginFormFailureData,
  } = useSelector(({ memberLogin }) => memberLogin);

  const { getAppResourcesSuccessData, getAppResourcesBegin } = useSelector(
    ({ homeReducer }) => homeReducer
  );
  const {
    getMemberDashboardDetailsSuccessData,
    getMemberDashboardDetailsBegin,
  } = useSelector(({ memberDashboard }) => memberDashboard);
  const {
    getMentorDashboardDetailsSuccessData,
    getMentorDashboardDetailsBegin,
  } = useSelector(({ mentorDashboard }) => mentorDashboard);
  const {
    submitAdminLoginBegin,
    submitAdminLoginSuccessData,
    submitAdminLoginFailureData,
  } = useSelector(({ adminAuth }) => adminAuth);

  useEffect(() => {
    if (getMemberDashboardDetailsSuccessData) {
      const data = getMemberDashboardDetailsSuccessData?.data;
      setAssessmentCompleted(data?.member.assessmentCompleted);
      storeMemberDashboardDetailsInLocalStorage(data);
    }
  }, [getMemberDashboardDetailsSuccessData]);
  useEffect(() => {
    if (getMentorDashboardDetailsSuccessData) {
      setRedirectToMentorDashboard(true);
      storeMemberDashboardDetailsInLocalStorage(
        getMentorDashboardDetailsSuccessData?.data
      );
    }
  }, [getMentorDashboardDetailsSuccessData]);

  useEffect(() => {
    submitMemberLoginFormFailureData?.message ===
      "User is not verified by email" && setRedirectToOtpVerificationPage(true);
  }, [submitMemberLoginFormFailureData]);

  useEffect(() => {
    // Google login
    if (submitMemberGoogleLoginFormFailureData) {
      setGoogleLoginLoader(false);
      if (
        submitMemberGoogleLoginFormFailureData?.message ===
        "User already exists with another role"
      ) {
        errorToast("Invalid User,no such member found");
      } else {
        errorToast(submitMemberGoogleLoginFormFailureData?.message);
      }
    }
    if (submitMemberGoogleLoginFormSuccessData) {
      storeMemberDetailsInLocalStorage(
        submitMemberGoogleLoginFormSuccessData.data
      );
      storeMemberSignUpStatusInLocalStorage(
        submitMemberGoogleLoginFormSuccessData.data.user.signupStatus
      );

      if (
        submitMemberGoogleLoginFormSuccessData?.data &&
        submitMemberGoogleLoginFormSuccessData?.data.user.signupStatus !==
          statusData.SIGNUP_STATUS.SUBSCRIBED
      ) {
        setRedirectToSubscriptionPage(true);
      } else if (
        submitMemberGoogleLoginFormSuccessData?.data.user.signupStatus ===
        statusData.SIGNUP_STATUS.SUBSCRIBED
      ) {
        dispatch(getMemberDashboardDetails());
      }
    }

    //Facebook Login
    if (submitMemberFacebookLoginFormFailureData) {
      const message = submitMemberFacebookLoginFormFailureData?.data?.message;
      if (message === "User already exists with another role") {
        errorToast("Invalid User,no such member found");
      } else {
        errorToast(message);
      }
    }
    if (submitMemberFacebookLoginFormSuccessData) {
      const signupStatus =
        submitMemberFacebookLoginFormSuccessData?.data?.user?.signupStatus;
      storeMemberDetailsInLocalStorage(
        submitMemberFacebookLoginFormSuccessData.data
      );
      storeMemberSignUpStatusInLocalStorage(signupStatus);

      if (
        submitMemberFacebookLoginFormSuccessData?.data &&
        signupStatus !== statusData.SIGNUP_STATUS.SUBSCRIBED
      ) {
        setRedirectToSubscriptionPage(true);
      } else if (signupStatus === statusData.SIGNUP_STATUS.SUBSCRIBED) {
        dispatch(getMemberDashboardDetails());
      }
    }

    // Normal login
    if (submitMemberLoginFormSuccessData) {
      const userData = submitMemberLoginFormSuccessData.data;
      const userRole = userData.user.role;

      // Mentor
      if (userRole === statusData.ROLES.MANAGER) {
        setRedirectToManagerDashboard(true);
        storeMemberDetailsInLocalStorage(userData);
      }
      if (userRole === statusData.ROLES.MENTOR) {
        if (role !== statusData.ROLES.MENTOR) {
          // if mentor try to login in member login page , will show this msg
          errorToast("Invalid User,no such member found");
        } else {
          dispatch(getMentorDashboardDetails());
          storeMemberDetailsInLocalStorage(userData);
        }
      } else if (userRole === statusData.ROLES.MEMBER) {
        if (role !== statusData.ROLES.MEMBER) {
          // if member try to login in mentor login page , will show this msg
          errorToast("Invalid User,no such mentor found");
        } else {
          const signupStatus = userData?.user?.signupStatus;
          storeMemberDetailsInLocalStorage(userData);
          storeMemberSignUpStatusInLocalStorage(signupStatus);
          if (signupStatus !== 2) {
            setRedirectToSubscriptionPage(true);
          } else if (signupStatus === 2) {
            dispatch(getMemberDashboardDetails());
          }
        }
      } else if (userRole === statusData.ROLES.ADMIN) {
        // If admin try to login in other login pages
        if (role === statusData.ROLES.MEMBER) {
          errorToast("Invalid User,no such member found");
        }
        if (role === statusData.ROLES.MENTOR) {
          errorToast("Invalid User,no such mentor found");
        }
      }
    }
    if (submitMemberLoginFormFailureData) {
      errorToast(submitMemberLoginFormFailureData?.message);
    }
  
  }, [
    submitMemberLoginFormFailureData,
    submitMemberLoginFormSuccessData,
    submitMemberGoogleLoginFormSuccessData,
    submitMemberGoogleLoginFormFailureData,
    submitMemberFacebookLoginFormFailureData,
    submitMemberFacebookLoginFormSuccessData,
    role,
    dispatch,
  ]);

  useEffect(() => {
    redirectToOtpVerificationPage &&
      dispatch(resendMemberRegistrationOtp(userEmail));
  }, [redirectToOtpVerificationPage, dispatch, userEmail]);

  // Admin Login
  useEffect(() => {
    if (submitAdminLoginSuccessData) {
      storeMemberDetailsInLocalStorage(submitAdminLoginSuccessData.data);
      storeAdminDetailsInLocalStorage(submitAdminLoginSuccessData.data.user);
      return navigate("/admin/dashboard");
    }
    if (submitAdminLoginFailureData) {
      errorToast("Invalid User,no such admin found");
    }
  }, [submitAdminLoginSuccessData, navigate, submitAdminLoginFailureData]);

  const customFormValidation = () => {
    let { email, password } = loginData;

    const validationErrors = {};

    if (email?.trim() === "") {
      validationErrors.email = "Email is required.";
    }
    if (
      email?.trim() !== "" &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
    ) {
      validationErrors.email = "Please enter a valid email address!.";
    }

    if (password?.trim() === "") {
      validationErrors.password = "Password is required.";
    }

    return validationErrors;
  };

  const onSubmitLoginForm = (e) => {
    e.preventDefault();
    const validationErrors = customFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      const passingData = {
        username: loginData.email,
        password: loginData.password,
        remember: loginData.rememberPassword,
      };
      if (role === statusData.ROLES.ADMIN) {
        dispatch(submitAdminLogin(passingData));
      } else {
        dispatch(submitMemberLoginForm(passingData));
      }
      setUserEmail(loginData.email);
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  // Google Login
  const responseGoogle = (response) => {
    const data = {
      googleToken: response.tokenId,
      role: role,
    };
    dispatch(memberGoogleLogin(data));
  };

  //Facebook Login
  const responseFacebook = (response) => {
    const data = {
      token: response.accessToken,
      id: response.userID,
      role: role,
    };
    dispatch(memberFacebookLogin(data));
  };

  const handleInputOnChange = (e) => {
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  if (redirectToOtpVerificationPage) {
    return (
      <MemberRegistrationVerifyOTPForm
        changeToNextPage={() => {
          navigate("/subscription_plans");
        }}
        mail={userEmail}
      />
    );
  }

  if (redirectToSubscriptionPage) {
    return <Navigate to="/subscription_plans" />;
  }
  if (redirectToMentorDashboard) {
    return <Navigate to="/mentor/dashboard" />;
  }
  if (redirectToManagerDashboard) {
    return <Navigate to="/manager/dashboard" />;
  }

  if (
    assessmentCompleted !== false &&
    assessmentCompleted !== 5 &&
    !getMemberDashboardDetailsBegin
  ) {
    return <Navigate to="/member/dashboard" />;
  }

  if (
    assessmentCompleted &&
    assessmentCompleted === 5 &&
    !getMemberDashboardDetailsBegin
  ) {
    return <Navigate to="/member/dashboard" />;
  }

  return (
    <div
      className={`lg:w-1/2 px-4 sm:px-24 bg-lightGrey text-brandDarkBlue  ${
        role === statusData.ROLES.MENTOR ? "py-4 sm:py-20" : "py-4 sm:py-12"
      }`}
    >
      <h2 className="font-medium text-2xl sm:text-28px">{heading}</h2>
      {role === statusData.ROLES.ADMIN || role === statusData.ROLES.MANAGER ? (
        <p className="w-full xl:w-8/12 2xl:w-3/5 font-medium text-base mt-3">
          Login to your Merity account with the Email and password provided
        </p>
      ) : (
        ""
      )}
      <div className="w-full xl:w-10/12 2xl:w-3/4 mt-7">
        <form onSubmit={(e) => onSubmitLoginForm(e)}>
          <InputGroup
            label="Email"
            name="email"
            onChange={handleInputOnChange}
            value={loginData?.email}
            errorMessage={formError?.email}
          />

          <InputGroupEye
            label="Password"
            name="password"
            value={loginData?.password}
            onChange={handleInputOnChange}
            errorMessage={formError?.password}
          />

          {role !== statusData.ROLES.ADMIN &&
            role !== statusData.ROLES.MANAGER && (
              <div className=" flex text-sm 370px:text-base justify-between py-2">
                <div>
                  <input
                    type="checkbox"
                    checked={loginData.rememberPassword}
                    onChange={(e) =>
                      setLoginData({
                        ...loginData,
                        rememberPassword: e.target.checked,
                      })
                    }
                    className="w-6 h-5 mr-2 align-middle rounded"
                  />
                  <label
                    htmlFor={memberLoginCheckbox.name}
                    className="text-deleteIcon font-normal"
                  >
                    {memberLoginCheckbox.label}
                  </label>
                </div>

                <Link
                  to="/login/forgot_password"
                  className="font-semibold buttonHover activeState"
                >
                  Forgot Password?
                </Link>
              </div>
            )}
          <FormButton
            disabled={submitMemberLoginFormBegin}
            classNames={`bg-brandBlue mt-6 text-white ${
              submitMemberLoginFormBegin ? "cursor-wait" : "buttonHover"
            }`}
          >
            Login
            {submitMemberLoginFormBegin ||
            submitAdminLoginBegin ||
            getMentorDashboardDetailsBegin ? (
              <Cliploader />
            ) : (
              ""
            )}
          </FormButton>
        </form>
        {role === statusData.ROLES.MEMBER && !getAppResourcesBegin && (
          <>
            {" "}
            <span className="mt-4 relative flexCenter">
              <p className="bg-lightGrey font-medium text-base px-5 z-10">Or</p>
              <div
                className="absolute top-1/2 left-0 w-full border-b "
                style={{ borderColor: "#e4e4e4" }}
              />
            </span>
            <GoogleLogin
              clientId={getAppResourcesSuccessData?.googleClientId}
              render={(renderProps) => (
                <SocialMediaButton
                  disabled={submitMemberGoogleLoginFormBegin}
                  classNames={`mt-6 ${
                    submitMemberGoogleLoginFormBegin
                      ? "cursor-wait"
                      : "activeState buttonHover"
                  }`}
                  onClick={() => {
                    setGoogleLoginLoader(true);
                    renderProps.onClick();
                  }}
                >
                  <GoogleIcon />
                  Login with Google
                  {googleLoginLoader || submitMemberGoogleLoginFormBegin ? (
                    <CliploaderBlue />
                  ) : (
                    ""
                  )}
                </SocialMediaButton>
              )}
              onSuccess={responseGoogle}
              onFailure={() => setGoogleLoginLoader(false)}
              cookiePolicy={"single_host_origin"}
            />
            <FacebookLoginButton
              appId={getAppResourcesSuccessData?.fbAppId}
              onLoginSuccess={(response) => {
                responseFacebook(response);
              }}
            >
              <SocialMediaButton
                disabled={submitMemberFacebookLoginFormBegin}
                classNames={`mt-6 ${
                  submitMemberFacebookLoginFormBegin
                    ? "cursor-wait"
                    : "activeState buttonHover"
                }`}
              >
                <FacebookIcon />
                Login with Facebook
                {submitMemberFacebookLoginFormBegin ? <CliploaderBlue /> : ""}
              </SocialMediaButton>
            </FacebookLoginButton>
            <div className="font-semibold text-base mt-6 flex gap-1">
              Not registered yet?
              <p className="buttonHover activeState">
                <Link to="/join" className=" text-brandBlue">
                  Create an Account
                </Link>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
