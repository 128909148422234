import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Cookies from 'universal-cookie';
import SeoData from "../../components/utilities/seo/seoData.json";
import ClientList from "../../components/pageComponents/mentorDashboard/ClientList";
import MemberNavbar from "../../components/pageComponents/memberDashboard/MemberNavbar";
import Navbar from "../../components/pageComponents/mentorDashboard/Navbar";
import MentorLeftSideProfileCard from "../../components/pageComponents/mentorDashboard/MentorLeftSideProfileCard";
import mentorPageData from "../../components/utilities/content/mentorDashboard.json";
import AddNotesSection from "../../components/pageComponents/mentorDashboard/AddNotesSection";
import AddTasksSection from "../../components/pageComponents/mentorDashboard/AddTasksSection";
import MentorMyClientsResponsePage from "../../components/pageComponents/mentorDashboard/MentorMyClientsResponsePage";
import { ReactHelmet } from "../../components/utilities";
import FeedSection from "../FeedSection";

const MyClient = () => {
  const [params] = useSearchParams();
  const action = params.getAll("action").toString();
  const tab = params.getAll("tab").toString();
  const [mentorUid, setMentorUid] = useState("");

  const { getMentorProfileSuccessData } = useSelector(
    ({ mentorDashboard }) => mentorDashboard
  );

  useEffect(() => {
    if (getMentorProfileSuccessData) {
      setMentorUid(getMentorProfileSuccessData?.data.uid);
    }
  }, [getMentorProfileSuccessData]);

  const [ipData ,setIpData] = useState(false);

  useEffect(() => {
    const cookies = new Cookies(null, { path: '/' });
    fetch('https://api.ipgeolocation.io/getip')
    .then(response => response.json())
    .then(data => {
      setIpData(data);
    });

  },[]);

  useEffect(() => {

    if(ipData) {
      let apiKey    = 'fedb7d47ed89400fbfcfad81e99d4569';
      let apiDevKey = '689d9f5de95341a086320edefc75d4a2';
      const cookies = new Cookies(null, { path: '/' });
    
      if(cookies.get('geolocation') == undefined) {
        fetch(`https://api.ipgeolocation.io/timezone?apiKey=${apiKey}&ip=${ipData.ip}`)
        .then(response => response.json())
        .then(data => {
          cookies.set('geolocation', data?.timezone);
        });
      }  
    }
    
  },[ipData]);

  return (
    <div className="min-h-screen" style={{ backgroundColor: "#f3f3f3" }}>
      <ReactHelmet data={SeoData.dashboard} />

      <MemberNavbar
        tabFromParams={tab}
        dropdownData={mentorPageData.navbar.dropdownList}
      />
      {action === "add-notes" ? (
        <AddNotesSection title="Your Notes" />
      ) : action === "assign-task" ? (
        <AddTasksSection title="Create Exercise" />
      ) : action === "view-exercise" ||
        action === "view-task" ||
        action === "view-note" ||
        action === "edit" ? (
        <MentorMyClientsResponsePage />
      ) : tab === "feeds-chat" ? (
        <FeedSection mentorUid={mentorUid} isMentor={true} />
      ) : (
        <>
          <Navbar />
          <div className="w-96% xl:w-10/12 mx-auto pt-10 pb-8 500px:pb-16 ">
            <div className="flex gap-3 950px:gap-6">
              <MentorLeftSideProfileCard />
              <ClientList />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MyClient;
