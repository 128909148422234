import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import SeoData from "../../../utilities/seo/seoData.json";
import pageData from "../../../utilities/content/memberDashboard.json";
import { getMemberCurriculum } from "../../../../AppRedux/actions/MemberDashboard";
import {
  scrollToTop,
  Scaleloader,
  RcTooltip,
  ReactHelmet,
} from "../../../utilities";
import {
  NoListToShowContainer,
  PageNumbers,
} from "../../dashboardGeneralItems";
import { MobileResponsiveAccordion } from "../../../utilities/svg";

const ExercisesList = ({ filtering }) => {
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [exercises, setExercises] = useState("");
  const [exercisesCount, setExercisesCount] = useState(false);
  const { getMemberCurriculumSuccessData, getMemberCurriculumBegin } =
    useSelector(({ memberDashboard }) => memberDashboard);

  useEffect(() => {
    if (currentPage === 1 && !filtering) {
      dispatch(getMemberCurriculum());
    }
  }, [dispatch, currentPage, filtering]);

  useEffect(() => {
    if (getMemberCurriculumSuccessData) {
      const { rows, count } = getMemberCurriculumSuccessData.data;
      setExercises(rows);
      setExercisesCount(count);
    }
  }, [getMemberCurriculumSuccessData]);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <div className="pt-4 bg-mobileBackground md:bg-white">
      <div className="mt-4 px-1 950px:px-2 min-h-80vh relative pb-14 ">
        <ReactHelmet data={SeoData.memberCurriculum} />

        <ListHeader
          headers={headers}
          gridClass="hidden 850px:grid grid-cols-smMemberExerciseListHeader xl:grid-cols-memberExerciseList"
        />

        {/* List */}

        <div className="member-table-row-items  ">
          {!getMemberCurriculumBegin && exercises ? (
            exercises.length > 0 ? (
              exercises?.map(({ id, title, responded }, index, exercises) => {
                return (
                  <div key={index}>
                    <div
                      key={index}
                      className={`hidden 850px:grid member-table-row-item member-table-row-item grid-cols-smMemberExerciseList xl:grid-cols-memberExerciseList gap-1 ${index === exercises.length - 1 ? "border-none" : ""
                        }`}
                    >
                      <p>
                        {index +
                          (currentPage > 1 && exercisesCount > 10
                            ? (currentPage - 1) * 10 + 1
                            : 1)}
                      </p>
                      <RcTooltip content={title}>
                        <p className="oneLineContent w-11/12">{title}</p>
                      </RcTooltip>
                      <StatusPillAndLink
                        actionClickParams={{
                          tab: "my-path",
                          cell: "path",
                          inpage: "curriculum",
                          action: responded === false ? "give" : "view",
                          id: id,
                          page_no: currentPage,
                        }}
                        status={
                          responded === false ? "Not Responded" : "Responded"
                        }
                        action={
                          responded === false
                            ? "Give Response"
                            : "View Response"
                        }
                      />
                    </div>
                    {/* Mobile Responsive      */}
                    <div
                      onClick={() => {
                        toggle(id);
                      }}
                      className="cursor-pointer  850px:hidden px-2 sm:px-4 py-3 sm:py-6 m-2 rounded-r"
                      style={{ background: "#fbfbfb" }}
                    >
                      <div className="flex justify-between items-center">
                        <h4 className=" font-bold text-xs 370px:text-base md:text-xl">
                          {clicked === id
                            ? `Reflection.No ${index +
                            (currentPage > 1 && exercisesCount > 10
                              ? (currentPage - 1) * 10 + 1
                              : 1)
                            }`
                            : title}
                        </h4>
                        <span
                          style={{ color: "#202020" }}
                          className={`text-xl cursor-pointer pr-2 ${clicked === id ? "animate-navbar" : ""
                            }`}
                        >
                          <MobileResponsiveAccordion
                            className={`cursor-pointer ${clicked === id ? "transform rotate-180 " : ""
                              }`}
                          />
                        </span>
                      </div>
                      {clicked === id && (
                        <div className="animate-navbar grid grid-cols-2 mt-2 gap-2 ">
                          <h1>Reflection</h1>
                          <p className="text-xs 370px:text-base md:text-lg">
                            {title}
                          </p>
                          <p>Status</p>
                          <StatusPillAndLink
                            actionClickParams={{
                              tab: "my-path",
                              cell: "path",
                              inpage: "curriculum",
                              action: responded === false ? "give" : "view",
                              id: id,
                              page_no: currentPage,
                            }}
                            status={
                              responded === false
                                ? "Not Responded"
                                : "Responded"
                            }
                            action={
                              responded === false
                                ? "Give Response"
                                : "View Response"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              exercises.length === 0 && (
                <NoListToShowContainer
                  text={`${filtering === 1 || !filtering
                      ? "There are currently no exercises"
                      : `There is no exercises that you  
             ${pageData.filterDropdown.find(({ type }) => type === filtering)
                        ?.text
                      }`
                    }`}
                />
              )
            )
          ) : (
            <Scaleloader />
          )}
        </div>

        <PageNumbers
          count={exercisesCount}
          currentPage={currentPage}
          setcurrentPage={setcurrentPage}
          loading={getMemberCurriculumBegin}
          onPageNumberClick={(number) => {
            dispatch(getMemberCurriculum(number, filtering));
          }}
        />
      </div>
    </div>
  );
};

export default ExercisesList;

// List header
export const ListHeader = ({ headers, gridClass, smFont }) => (
  <div
    className={`grid rounded-t-7px px-2 950px:px-4 1460px:px-10 ${gridClass}`}
    style={{ backgroundColor: "#FCF6ED" }}
  >
    {headers.map((header, index) => (
      <p
        key={index}
        className={`font-semibold text-brandDarkBlue  pt-2 pb-1 xl:text-lg ${smFont ? "text-xs 850px:text-sm" : "text-sm"
          }`}
        style={{ color: "#013B5D" }}
      >
        {header}
      </p>
    ))}
  </div>
);

// Status and link
export const StatusPillAndLink = ({ status, action, actionClickParams }) => {
  let [, setParams] = useSearchParams();

  const handleActionLinkClick = () => {
    setParams({
      ...actionClickParams,
    });
  };

  return (
    <>
      <Pill status={status} color={status === "Responded" ? "green" : "red"} />
      <p
        className={`text-xs xl:text-sm cursor-pointer activeState ${action === "View Response"
            ? "text-memberViewResponseGray dashboardLink"
            : "dashboardLink"
          }`}
        onClick={() => {
          scrollToTop("50", "16.66");
          handleActionLinkClick();
        }}
      >
        {action}
      </p>
    </>
  );
};

export const Pill = ({ status, color }) => (
  <div>
    <span
      className="px-3 py-1 text-xs xl:text-sm rounded-3xl"
      style={{
        backgroundColor: color === "green" ? "#DCF7EB" : "#FDEAEA",
        color: color === "green" ? "#12BE76" : "#E73A3A",
      }}
    >
      {status}
    </span>
  </div>
);

const headers = ["No.", "Reflections", "Status", "Action"];
