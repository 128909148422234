export const GET_MEMBER_PROFILE_BEGIN = "GET_MEMBER_PROFILE_BEGIN";
export const GET_MEMBER_PROFILE_SUCCESS = "GET_MEMBER_PROFILE_SUCCESS";
export const GET_MEMBER_PROFILE_FAILURE = "GET_MEMBER_PROFILE_FAILURE";
export const RESET_GET_MEMBER_PROFILE = "RESET_GET_MEMBER_PROFILE";

export const GET_ASSESSMENT_QUESTIONS_BEGIN = "GET_ASSESSMENT_QUESTIONS_BEGIN";
export const GET_ASSESSMENT_QUESTIONS_SUCCESS =
  "GET_ASSESSMENT_QUESTIONS_SUCCESS";
export const GET_ASSESSMENT_QUESTIONS_FAILURE =
  "GET_ASSESSMENT_QUESTIONS_FAILURE";
export const RESET_GET_ASSESSMENT_QUESTIONS = "RESET_GET_ASSESSMENT_QUESTIONS";

export const SUBMIT_SINGLE_ASSESSMENT_BEGIN = "SUBMIT_SINGLE_ASSESSMENT_BEGIN";
export const SUBMIT_SINGLE_ASSESSMENT_SUCCESS =
  "SUBMIT_SINGLE_ASSESSMENT_SUCCESS";
export const SUBMIT_SINGLE_ASSESSMENT_FAILURE =
  "SUBMIT_SINGLE_ASSESSMENT_FAILURE";
export const RESET_SUBMIT_SINGLE_ASSESSMENT = "RESET_SUBMIT_SINGLE_ASSESSMENT";

export const VIEW_SUBMITTED_ASSESSMENT_OPTIONS_BEGIN =
  "VIEW_SUBMITTED_ASSESSMENT_OPTIONS_BEGIN";
export const VIEW_SUBMITTED_ASSESSMENT_OPTIONS_SUCCESS =
  "VIEW_SUBMITTED_ASSESSMENT_OPTIONS_SUCCESS";
export const VIEW_SUBMITTED_ASSESSMENT_OPTIONS_FAILURE =
  "VIEW_SUBMITTED_ASSESSMENT_OPTIONS_FAILURE";
export const RESET_VIEW_SUBMITTED_ASSESSMENT_OPTIONS =
  "RESET_VIEW_SUBMITTED_ASSESSMENT_OPTIONS";

export const PURCHASE_PAYMENT_MINUTES_BEGIN = "PURCHASE_PAYMENT_MINUTES_BEGIN";
export const PURCHASE_PAYMENT_MINUTES_SUCCESS =
  "PURCHASE_PAYMENT_MINUTES_SUCCESS";
export const PURCHASE_PAYMENT_MINUTES_FAILURE =
  "PURCHASE_PAYMENT_MINUTES_FAILURE";
export const RESET_PURCHASE_PAYMENT_MINUTES = "RESET_PURCHASE_PAYMENT_MINUTES";

export const GET_MEMBER_CURRICULUM_BEGIN = "GET_MEMBER_CURRICULUM_BEGIN";
export const GET_MEMBER_CURRICULUM_SUCCESS = "GET_MEMBER_CURRICULUM_SUCCESS";
export const GET_MEMBER_CURRICULUM_FAILURE = "GET_MEMBER_CURRICULUM_FAILURE";
export const RESET_GET_MEMBER_CURRICULUM = "RESET_GET_MEMBER_CURRICULUM";

export const GET_MEMBER_JOURNAL_BEGIN = "GET_MEMBER_JOURNAL_BEGIN";
export const GET_MEMBER_JOURNAL_SUCCESS = "GET_MEMBER_JOURNAL_SUCCESS";
export const GET_MEMBER_JOURNAL_FAILURE = "GET_MEMBER_JOURNAL_FAILURE";
export const RESET_GET_MEMBER_JOURNAL = "RESET_GET_MEMBER_JOURNAL";

export const SUBMIT_MEMBER_JOURNAL_BEGIN = "SUBMIT_MEMBER_JOURNAL_BEGIN";
export const SUBMIT_MEMBER_JOURNAL_SUCCESS = "SUBMIT_MEMBER_JOURNAL_SUCCESS";
export const SUBMIT_MEMBER_JOURNAL_FAILURE = "SUBMIT_MEMBER_JOURNAL_FAILURE";
export const RESET_SUBMIT_MEMBER_JOURNAL = "RESET_SUBMIT_MEMBER_JOURNAL";

export const VIEW_SINGLE_MEMBER_JOURNAL_BEGIN =
  "VIEW_SINGLE_MEMBER_JOURNAL_BEGIN";
export const VIEW_SINGLE_MEMBER_JOURNAL_SUCCESS =
  "VIEW_SINGLE_MEMBER_JOURNAL_SUCCESS";
export const VIEW_SINGLE_MEMBER_JOURNAL_FAILURE =
  "VIEW_SINGLE_MEMBER_JOURNAL_FAILURE";
export const RESET_VIEW_SINGLE_MEMBER_JOURNAL =
  "RESET_VIEW_SINGLE_MEMBER_JOURNAL";

export const VIEW_MEMBER_CURRICULUM_RESPONSE_BEGIN =
  "VIEW_MEMBER_CURRICULUM_RESPONSE_BEGIN";
export const VIEW_MEMBER_CURRICULUM_RESPONSE_SUCCESS =
  "VIEW_MEMBER_CURRICULUM_RESPONSE_SUCCESS";
export const VIEW_MEMBER_CURRICULUM_RESPONSE_FAILURE =
  "VIEW_MEMBER_CURRICULUM_RESPONSE_FAILURE";
export const RESET_VIEW_MEMBER_CURRICULUM_RESPONSE =
  "RESET_GET_MEMBER_CURRICULUM";

export const SUBMIT_MEMBER_CURRICULUM_RESPONSE_BEGIN =
  "SUBMIT_MEMBER_CURRICULUM_RESPONSE_BEGIN";
export const SUBMIT_MEMBER_CURRICULUM_RESPONSE_SUCCESS =
  "SUBMIT_MEMBER_CURRICULUM_RESPONSE_SUCCESS";
export const SUBMIT_MEMBER_CURRICULUM_RESPONSE_FAILURE =
  "SUBMIT_MEMBER_CURRICULUM_RESPONSE_FAILURE";
export const RESET_SUBMIT_MEMBER_CURRICULUM_RESPONSE =
  "RESET_SUBMIT_MEMBER_CURRICULUM_RESPONSE";

export const GET_MEMBER_DASHBOARD_DETAILS_BEGIN =
  "GET_MEMBER_DASHBOARD_DETAILS_BEGIN";
export const GET_MEMBER_DASHBOARD_DETAILS_SUCCESS =
  "GET_MEMBER_DASHBOARD_DETAILS_SUCCESS";
export const GET_MEMBER_DASHBOARD_DETAILS_FAILURE =
  "GET_MEMBER_DASHBOARD_DETAILS_FAILURE";
export const RESET_GET_MEMBER_DASHBOARD_DETAILS =
  "RESET_GET_MEMBER_DASHBOARD_DETAILS";

export const ASSIGN_MENTOR_TO_MEMBER_BEGIN = "ASSIGN_MENTOR_TO_MEMBER_BEGIN";
export const ASSIGN_MENTOR_TO_MEMBER_SUCCESS =
  "ASSIGN_MENTOR_TO_MEMBER_SUCCESS";
export const ASSIGN_MENTOR_TO_MEMBER_FAILURE =
  "ASSIGN_MENTOR_TO_MEMBER_FAILURE";
export const RESET_ASSIGN_MENTOR_TO_MEMBER = "RESET_ASSIGN_MENTOR_TO_MEMBER";

export const GET_MEMBER_NOTIFICATIONS_BEGIN = "GET_MEMBER_NOTIFICATIONS_BEGIN";
export const GET_MEMBER_NOTIFICATIONS_SUCCESS =
  "GET_MEMBER_NOTIFICATIONS_SUCCESS";
export const GET_MEMBER_NOTIFICATIONS_FAILURE =
  "GET_MEMBER_NOTIFICATIONS_FAILURE";
export const RESET_GET_MEMBER_NOTIFICATIONS = "RESET_GET_MEMBER_NOTIFICATIONS";

export const UNREAD_ALL_MEMBER_NOTIFICATIONS_BEGIN =
  "UNREAD_ALL_MEMBER_NOTIFICATIONS_BEGIN";

export const UNREAD_ALL_MEMBER_NOTIFICATIONS_SUCCESS =
  "UNREAD_ALL_MEMBER_NOTIFICATIONS_SUCCESS";

export const UNREAD_ALL_MEMBER_NOTIFICATIONS_FAILURE =
  "UNREAD_ALL_MEMBER_NOTIFICATIONS_FAILURE";

export const RESET_UNREAD_ALL_MEMBER_NOTIFICATIONS =
  "RESET_UNREAD_ALL_MEMBER_NOTIFICATIONS";

export const GET_MY_MENTOR_DETAILS_BEGIN = "GET_MY_MENTOR_DETAILS_BEGIN";
export const GET_MY_MENTOR_DETAILS_SUCCESS = "GET_MY_MENTOR_DETAILS_SUCCESS";
export const GET_MY_MENTOR_DETAILS_FAILURE = "GET_MY_MENTOR_DETAILS_FAILURE";
export const RESET_GET_MY_MENTOR_DETAILS = "RESET_GET_MY_MENTOR_DETAILS";

export const GET_PURCHASE_MINUTES_PLAN_BEGIN =
  "GET_PURCHASE_MINUTES_PLAN_BEGIN";
export const GET_PURCHASE_MINUTES_PLAN_SUCCESS =
  "GET_PURCHASE_MINUTES_PLAN_SUCCESS";
export const GET_PURCHASE_MINUTES_PLAN_FAILURE =
  "GET_PURCHASE_MINUTES_PLAN_FAILURE";
export const RESET_GET_PURCHASE_MINUTES_PLAN =
  "RESET_GET_PURCHASE_MINUTES_PLAN";

export const GET_MEMBER_TASK_BEGIN = "GET_MEMBER_TASK_BEGIN";
export const GET_MEMBER_TASK_SUCCESS = "GET_MEMBER_TASK_SUCCESS";
export const GET_MEMBER_TASK_FAILURE = "GET_MEMBER_TASK_FAILURE";
export const RESET_GET_MEMBER_TASK = "RESET_GET_MEMBER_TASK";

export const VIEW_MEMBER_TASK_RESPONSE_BEGIN =
  "VIEW_MEMBER_TASK_RESPONSE_BEGIN";
export const VIEW_MEMBER_TASK_RESPONSE_SUCCESS =
  "VIEW_MEMBER_TASK_RESPONSE_SUCCESS";
export const VIEW_MEMBER_TASK_RESPONSE_FAILURE =
  "VIEW_MEMBER_TASK_RESPONSE_FAILURE";
export const RESET_VIEW_MEMBER_TASK_RESPONSE =
  "RESET_VIEW_MEMBER_TASK_RESPONSE";

export const SUBMIT_MEMBER_TASK_RESPONSE_BEGIN =
  "SUBMIT_MEMBER_TASK_RESPONSE_BEGIN";
export const SUBMIT_MEMBER_TASK_RESPONSE_SUCCESS =
  "SUBMIT_MEMBER_TASK_RESPONSE_SUCCESS";
export const SUBMIT_MEMBER_TASK_RESPONSE_FAILURE =
  "SUBMIT_MEMBER_TASK_RESPONSE_FAILURE";
export const RESET_SUBMIT_MEMBER_TASK_RESPONSE =
  "RESET_SUBMIT_MEMBER_TASK_RESPONSE";

export const SUBMIT_MENTOR_RATING_BEGIN = "SUBMIT_MENTOR_RATING_BEGIN";
export const SUBMIT_MENTOR_RATING_SUCCESS = "SUBMIT_MENTOR_RATING_SUCCESS";
export const SUBMIT_MENTOR_RATING_FAILURE = "SUBMIT_MENTOR_RATING_FAILURE";
export const RESET_SUBMIT_MENTOR_RATING = "RESET_SUBMIT_MENTOR_RATING";

export const SUBMIT_SINGLE_QUESTION_ANSWER_BEGIN =
  "SUBMIT_SINGLE_QUESTION_ANSWER_BEGIN";
export const SUBMIT_SINGLE_QUESTION_ANSWER_SUCCESS =
  "SUBMIT_SINGLE_QUESTION_ANSWER_SUCCESS";
export const SUBMIT_SINGLE_QUESTION_ANSWER_FAILURE =
  "SUBMIT_SINGLE_QUESTION_ANSWER_FAILURE";
export const RESET_SUBMIT_SINGLE_QUESTION_ANSWER =
  "RESET_SUBMIT_SINGLE_QUESTION_ANSWER";

export const SUBMIT_BULK_ANSWERS_BEGIN = "SUBMIT_BULK_ANSWERS_BEGIN";
export const SUBMIT_BULK_ANSWERS_SUCCESS = "SUBMIT_BULK_ANSWERS_SUCCESS";
export const SUBMIT_BULK_ANSWERS_FAILURE = "SUBMIT_BULK_ANSWERS_FAILURE";
export const RESET_SUBMIT_BULK_ANSWERS = "RESET_SUBMIT_BULK_ANSWERS";

export const EDIT_SINGLE_ANSWERS_BEGIN = "EDIT_SINGLE_ANSWERS_BEGIN";
export const EDIT_SINGLE_ANSWERS_SUCCESS = "EDIT_SINGLE_ANSWERS_SUCCESS";
export const EDIT_SINGLE_ANSWERS_FAILURE = "EDIT_SINGLE_ANSWERS_FAILURE";
export const RESET_EDIT_SINGLE_ANSWERS = "RESET_EDIT_SINGLE_ANSWERS";

export const SUBMIT_SINGLE_TIF_ANSWER_BEGIN = "SUBMIT_SINGLE_TIF_ANSWER_BEGIN";
export const SUBMIT_SINGLE_TIF_ANSWER_SUCCESS =
  "SUBMIT_SINGLE_TIF_ANSWER_SUCCESS";
export const SUBMIT_SINGLE_TIF_ANSWER_FAILURE =
  "SUBMIT_SINGLE_TIF_ANSWER_FAILURE";
export const RESET_SUBMIT_SINGLE_TIF_ANSWER = "RESET_SUBMIT_SINGLE_TIF_ANSWER";

export const EDIT_SINGLE_TIF_BEGIN = "EDIT_SINGLE_TIF_BEGIN";
export const EDIT_SINGLE_TIF_SUCCESS = "EDIT_SINGLE_TIF_SUCCESS";
export const EDIT_SINGLE_TIF_FAILURE = "EDIT_SINGLE_TIF_FAILURE";
export const RESET_EDIT_SINGLE_TIF = "RESET_EDIT_SINGLE_TIF";

export const SUBMIT_BULK_TIF_BEGIN = "SUBMIT_BULK_TIF_BEGIN";
export const SUBMIT_BULK_TIF_SUCCESS = "SUBMIT_BULK_TIF_SUCCESS";
export const SUBMIT_BULK_TIF_FAILURE = "SUBMIT_BULK_TIF_FAILURE";
export const RESET_SUBMIT_BULK_TIF = "RESET_SUBMIT_BULK_TIF";

export const SEND_WELCOME_EMAIL = "SEND_WELCOME_EMAIL";
