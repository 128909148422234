import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { verifyMemberForgotPasswordLoginOTP } from "../../../AppRedux/actions/MemberLogin";
import {
  resendMemberRegistrationOtp,
  verifyUserRegistrationOtp,
} from "../../../AppRedux/actions/MemberRegistration";
import {
  FormButton,
  CliploaderBlue,
  formResponseToasting,
  Cliploader,
  storeMemberDetailsInLocalStorage,
} from "../../utilities";

const MemberRegistrationVerifyOTPForm = ({
  changeToNextPage,
  mail,
  currentPage,
  setOtp,
  removeURLParam
}) => {

  const navigate = useNavigate();

  if(!removeURLParam) {
    window.location.hash = "step2";
  }
  
  const [otpValue, setOtpValue] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
  });
  const [otpWaitingSeconds, setOtpWaitingSeconds] = useState(0);
  const [minutes, setMinutes] = useState(5);
  const dispatch = useDispatch();
  const memberRegistration = useSelector(
    ({ memberRegistration }) => memberRegistration
  );
  const memberLogin = useSelector(({ memberLogin }) => memberLogin);

  const {
    verifyMemberLoginForgetPasswordOtpBegin,
    verifyMemberLoginForgetPasswordOtpSuccessData,
    verifyMemberLoginForgetPasswordOtpFailureData,
  } = memberLogin;
  const {
    resendUserRegisterOtpBegin,
    verifyUserRegisterOtpBegin,
    verifyUserRegisterOtpSuccessData,
    verifyUserRegisterOtpFailureData,
  } = memberRegistration;

  useEffect(() => {
    document.getElementById("digit-1").focus();
    
    window.gtag('event', 'page_view', {
      "page_title": "Member OTP",
    });

  }, []);

  // for verify otp
  useEffect(() => {
    formResponseToasting(
      verifyUserRegisterOtpSuccessData,
      verifyUserRegisterOtpFailureData
    );
    if (verifyUserRegisterOtpSuccessData) {
      navigate('/subscription_plans')
      storeMemberDetailsInLocalStorage(verifyUserRegisterOtpSuccessData.data);
    }
  }, [
    verifyUserRegisterOtpSuccessData,
    verifyUserRegisterOtpFailureData,
    changeToNextPage,
    navigate
  ]);

  useEffect(() => {
    formResponseToasting(
      verifyMemberLoginForgetPasswordOtpSuccessData,
      verifyMemberLoginForgetPasswordOtpFailureData
    );
    verifyMemberLoginForgetPasswordOtpSuccessData &&
      changeToNextPage("set_new_password");
  }, [
    verifyMemberLoginForgetPasswordOtpSuccessData,
    verifyMemberLoginForgetPasswordOtpFailureData,
    changeToNextPage,
  ]);

  const onSubmitOTP = (e) => {
    e.preventDefault();

    const userTypedOtp = Object.keys(otpValue)
      .map((key) => otpValue[key])
      .toString()
      .replaceAll(",", "");

    const data = {
      email: mail,
      otp: userTypedOtp,
    };
    if (currentPage === "member_forgot_Password") {
      setOtp((data) => {
        return { ...data, otp: userTypedOtp };
      });
      dispatch(verifyMemberForgotPasswordLoginOTP(data));
    } else {
      dispatch(verifyUserRegistrationOtp(data));
    }
  };

  const onResendOtpLinkClick = () => {
    if (otpWaitingSeconds === 0) {
      dispatch(resendMemberRegistrationOtp(mail));
      setTimeout(() => {
        setMinutes(4);
        setOtpWaitingSeconds(59);
      }, 1000);
    }
  };

  return (
    <div className="lg:w-1/2 py-4 md:py-12 xl:py-36 px-4 sm:px-24 bg-lightGrey">
      <div className="w-full lg:w-4/5  text-center">
        <h2 className="font-medium text-2xl sm:text-28px mb-4 text-brandDarkBlue uppercase">
          Verify OTP
        </h2>
        <p
          className="font-semibold text-base mb-10"
          style={{ color: "#A1A19C" }}
        >
          For security and validation purposes, please enter the verification code sent to the mobile number you provided.
        </p>
        <form
          className="w-full xl:w-10/12 2xl:w-3/4 mx-auto"
          autoComplete="off"
          onSubmit={onSubmitOTP}
          id="member_form"
        >
          <OtpInput otpValue={otpValue} setOtpValue={setOtpValue} />

          <p
            className="font-semibold text-base my-4"
            style={{ color: "#A1A19C" }}
          >
            Haven’t received OTP yet?{" "}
            <span
              onClick={() => {
                !resendUserRegisterOtpBegin && onResendOtpLinkClick();
              }}
              className={`buttonHover inline-flex ${resendUserRegisterOtpBegin
                ? "text-brandDarkBlue cursor-wait"
                : ` ${otpWaitingSeconds !== 0
                  ? "text-opacity-80 text-brandBlue cursor-not-allowed"
                  : "text-brandBlue cursor-pointer"
                }`
                } `}
            >
              Resend OTP {"  "}
              {resendUserRegisterOtpBegin && <CliploaderBlue />}
              <Timer
                minutes={minutes}
                setMinutes={setMinutes}
                initialSeconds={otpWaitingSeconds}
                setOtpWaitingSeconds={setOtpWaitingSeconds}
              />
            </span>
          </p>
          <FormButton
            disabled={verifyUserRegisterOtpBegin}
            classNames={`bg-brandBlue mt-6 text-white ${verifyUserRegisterOtpBegin ? "cursor-wait" : "buttonHover"
              }`}
          >
            Verify & Continue{verifyUserRegisterOtpBegin && <Cliploader />}{" "}
            {verifyMemberLoginForgetPasswordOtpBegin && <Cliploader />}
          </FormButton>
        </form>
      </div>
    </div>
  );
};

export default MemberRegistrationVerifyOTPForm;

const Timer = ({
  minutes,
  setMinutes,
  initialSeconds,
  setOtpWaitingSeconds,
}) => {
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (initialSeconds > 0) {
        setOtpWaitingSeconds(initialSeconds - 1);
      }
      if (initialSeconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setOtpWaitingSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <>
      {minutes === 0 && initialSeconds === 0 ? null : (
        <span className="text-brandDarkBlue ml-2">
          0{minutes} :{" "}
          {initialSeconds < 10 ? `0${initialSeconds}` : initialSeconds}
        </span>
      )}
    </>
  );
};

const OtpInput = ({ otpValue, setOtpValue }) => {
  const { first, second, third, fourth } = otpValue;

  const handleInputOnchange = (e) => {
    const { name, value } = e.target;
    setOtpValue({ ...otpValue, [name]: value });
    if (value !== "") {
      e.target.dataset.next &&
        document.getElementById(e.target.dataset.next).focus();
    }
  };

  const handleKeyDown = (e) => {
    let key = Number(e.key);
    if (
      (isNaN(key) || e.key === null || e.key === " ") &&
      e.key !== "Backspace"
    ) {
      e.preventDefault();
    } else {
      if (e.key === "Backspace") {
        if (otpValue[e.target.name]) {
          setOtpValue({ ...otpValue, [e.target.name]: "" });
        } else {
          e.target.dataset.previous &&
            document.getElementById(e.target.dataset.previous).focus();
        }
        e.preventDefault();
      }
    }
  };

  return (
    <div className="flex justify-center gap-5">
      <input
        maxLength={1}
        type="text"
        className="verifyOtpInput"
        value={first}
        onChange={handleInputOnchange}
        onKeyDown={handleKeyDown}
        id="digit-1"
        name="first"
        data-next="digit-2"
      />
      <input
        maxLength={1}
        type="text"
        className="verifyOtpInput"
        value={second}
        onChange={handleInputOnchange}
        onKeyDown={handleKeyDown}
        id="digit-2"
        name="second"
        data-next="digit-3"
        data-previous="digit-1"
      />
      <input
        maxLength={1}
        type="text"
        className="verifyOtpInput"
        value={third}
        onChange={handleInputOnchange}
        onKeyDown={handleKeyDown}
        id="digit-3"
        name="third"
        data-next="digit-4"
        data-previous="digit-2"
      />
      <input
        maxLength={1}
        type="text"
        className="verifyOtpInput"
        value={fourth}
        onChange={handleInputOnchange}
        onKeyDown={handleKeyDown}
        id="digit-4"
        name="fourth"
        data-previous="digit-3"
      />
    </div>
  );
};
