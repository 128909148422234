import React from "react";
import { Link, useLocation } from "react-router-dom";

import { adminLeftsideTabs } from "../../utilities/Data";
import {
  AdminDashboardLogo,
  AdminLeftsideBarSun,
  AdminSettingsIcon,
} from "../../utilities/svg";

const AdminLeftSidebar = () => {
  const { pathname } = useLocation();

  const Tab = ({ title, Icon, link }) => (
    <Link
      to={link}
      className={`relative flex items-center gap-3 rounded-lg py-3 px-4 mt-2 cursor-pointer transition75 hover:scale-95 active:bg-memberSelectedCell active:scale-90  ${
        pathname === link
          ? "text-memberSelectedCell bg-adminLeftsideTab font-extrabold active:text-white"
          : "font-medium hover:bg-adminLeftsideTab"
      }`}
      style={{
        boxShadow:
          pathname === link ? "0px 7px 10px rgba(0, 60, 80, 0.12)" : "",
      }}
    >
      <Icon className="fill-current" />
      <p className="text-lg">{title}</p>
      {pathname === link && (
        <div
          className="absolute right-3 rounded-lg bg-memberSelectedCell"
          style={{
            minHeight: "27px",
            minWidth: "4px",
          }}
        />
      )}
    </Link>
  );

  return (
    <div
      className="relative rounded-r-2xl min-h-screen text-adminLeftSideTab admin-leftside-bar"
      style={{
        background: "linear-gradient(180deg, #0493C2 0%, #007CA5 100%)",
      }}
    >
      <div className=" w-10/12 mx-auto flex flex-col justify-between gap-10 min-h-screen pt-5 pb-10">
        <Link to="/">
          <AdminDashboardLogo className="mt-3 ml-6 cursor-pointer" />
        </Link>
        <AdminLeftsideBarSun className="absolute left-0 top-5" />

        <div>
          {adminLeftsideTabs.map((tabDetails, index) => (
            <Tab key={index} {...tabDetails} />
          ))}
        </div>

        <Tab
          title="Settings"
          Icon={AdminSettingsIcon}
          link="/admin/dashboard/settings"
        />
      </div>
    </div>
  );
};

export default AdminLeftSidebar;
