import {
  SUBMIT_ADMIN_LOGIN_BEGIN,
  SUBMIT_ADMIN_LOGIN_SUCCESS,
  SUBMIT_ADMIN_LOGIN_FAILURE,
  RESET_SUBMIT_ADMIN_LOGIN,
} from "../constants/AdminAuth";

import { httpApp } from "../../axios/httpUser";

export function submitAdminLogin(data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_ADMIN_LOGIN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/admin`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_ADMIN_LOGIN_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_ADMIN_LOGIN,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_ADMIN_LOGIN_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SUBMIT_ADMIN_LOGIN,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
