import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getSingleMemberDetails } from "../../../../AppRedux/actions/AdminMember";
import { Scaleloader } from "../../../utilities";
import { CommonLeftSideProfileCard } from "../AdminGeneralComponents";

const AdminMemberLeftSide = ({ id }) => {
  const dispatch = useDispatch();
  const [memberData, setMemberData] = useState("");
  const { getSingleMemberDetailsBegin, getSingleMemberDetailsSuccessData } =
    useSelector(({ adminMember }) => adminMember);

  useEffect(() => {
    dispatch(getSingleMemberDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getSingleMemberDetailsSuccessData) {
      setMemberData(getSingleMemberDetailsSuccessData?.data);
    }
  }, [getSingleMemberDetailsSuccessData, memberData]);

  const getMemberThreeGoalsLessWords = () => {
    let getsmallSectance = (a, b) => (a.length <= b.length ? a : b),
      arr = memberData?.assessments.map(
        (assessment) => assessment.AssessmentQuestionOption.option
      ),
      firstGoal,
      secondGoal,
      thirdGoal;

    firstGoal = arr?.reduce(getsmallSectance);
    arr = arr?.filter((option) => option !== firstGoal);
    secondGoal = arr?.reduce(getsmallSectance);
    arr = arr?.filter((option) => option !== secondGoal);
    thirdGoal = arr?.reduce(getsmallSectance);

    return {
      changeToOnebyOne:
        firstGoal.length > 10 ||
        secondGoal.length > 10 ||
        thirdGoal.length > 10,
      goals: [
        {
          goal: firstGoal,
          color: "#FF7D7D",
        },
        {
          goal: secondGoal,
          color: "#7AE49E",
        },
        {
          goal: thirdGoal,
          color: "#7AD8E4",
        },
      ],
    };
  };

  return (
    <div
      className="rounded-10px shadow-memberDashboard bg-white member-leftside-profile-card min-h-90vh"
      style={{ color: "#003547" }}
    >
      {!getSingleMemberDetailsBegin && memberData ? (
        <>
          <CommonLeftSideProfileCard data={memberData} />
          <div className="border-b-2 border-faq mt-3 mb-4" />
          <h6 className="mx-4 font-semibold text-sm lg:text-sm">Goal</h6>
          {memberData?.assessments.length !== 0 ? (
            <>
              <div
                className={`px-2 pt-3 ${
                  getMemberThreeGoalsLessWords().changeToOnebyOne
                    ? "mb-6"
                    : "flex justify-between"
                }`}
              >
                {getMemberThreeGoalsLessWords()?.goals.map(
                  ({ goal, color }, index) => {
                    return (
                      <div
                        key={index}
                        className={` flex  rounded-3xl px-1 py-1  ${
                          getMemberThreeGoalsLessWords().changeToOnebyOne
                            ? "items-center mb-3 gap-2"
                            : "w-1/3 flexCenter gap-1"
                        }`}
                        style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.03)" }}
                      >
                        <div
                          className="rounded-full"
                          style={{
                            backgroundColor: color,
                            minWidth: "8px",
                            minHeight: "8px",
                          }}
                        />
                        <p
                          className={` font-semibold text-xs lg:text-sm ${
                            getMemberThreeGoalsLessWords().changeToOnebyOne
                              ? ""
                              : "oneWordContent"
                          }`}
                        >
                          {goal}
                        </p>
                      </div>
                    );
                  }
                )}
              </div>
              <div
                className={` my-3 ${
                  getMemberThreeGoalsLessWords().changeToOnebyOne
                    ? ""
                    : "border-b border-solid border-loginCards"
                }`}
              />
            </>
          ) : (
            <div className="px-4 text-black font-medium text-sm">
              No Details Found
            </div>
          )}
        </>
      ) : (
        <Scaleloader />
      )}
    </div>
  );
};

export default AdminMemberLeftSide;
