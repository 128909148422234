import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AdminGeneralCards } from "./AdminGeneralComponents";
import { successToast } from "../../utilities";
import {
  getManagerDetails,
  updateManagerDetails,
} from "../../../AppRedux/actions/AdminManager";

const AdminManagerCard = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: "",
    email: "",
  });
  const [formError, setFormError] = useState({});
  const [editclicked, setEditClicked] = useState(false);
  const [managerDetails, setManagerDetails] = useState("");
  const [editIconfirstClick, setEditIconfirstClick] = useState(true);

  const {
    getManagerDetailsBegin,
    getManagerDetailsSuccessData,
    editManagerNameBegin,
    editManagerNameSuccessData,
  } = useSelector(({ adminManager }) => adminManager);

  useEffect(() => {
    dispatch(getManagerDetails());
  }, [dispatch]);

  useEffect(() => {
    if (getManagerDetailsSuccessData) {
      setManagerDetails(getManagerDetailsSuccessData?.data?.rows[0]);
      setData(getManagerDetailsSuccessData?.data?.rows[0]);
    }
    if (editManagerNameSuccessData) {
      successToast("Details updated successfully");
      dispatch(getManagerDetails());
      setEditClicked(false);
    }
  }, [
    dispatch,
    getManagerDetailsSuccessData,
    editManagerNameSuccessData,
    setData,
    data,
  ]);

  const validation = () => {
    const { name } = data;

    const validationErrors = {};
    if (name?.trim() === "") {
      validationErrors.name = "First name is required.";
    }
    if (name?.trim() !== "" && name.length < 3) {
      validationErrors.name = "* please give your valid name.";
    }

    return validationErrors;
  };

  const handleInputOnChange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z]/gi, " ");
    }
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });
    setData({
      ...data,
      [e.target.name]: value,
    });
  };

  const handleSubmit = () => {
    const validationErrors = validation();

    if (Object.keys(validationErrors).length === 0) {
      const { name, email, id } = data;
      dispatch(updateManagerDetails({ name: name, email: email }, id));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  const onEditIconClick = () => {
    if (!getManagerDetailsBegin) {
      if (editIconfirstClick) {
        setFormError({});
        setEditClicked(!editclicked);
        setEditIconfirstClick(!editIconfirstClick);
      } else {
        //second click
        setFormError({});
        setEditClicked(!editclicked);
        dispatch(getManagerDetails());
        setEditIconfirstClick(!editIconfirstClick);
      }
    }
  };

  return (
    <div className="overflow-x-scroll 950px:overflow-x-auto m-5 rounded-10px lg:px-8 min-h-80vh relative pb-14">
      <div style={{ minWidth: "650px" }}>
        <AdminGeneralCards
          loader={getManagerDetailsBegin}
          buttonLoader={editManagerNameBegin}
          details={managerDetails}
          data={data}
          editclicked={editclicked}
          formError={formError}
          handleInputOnChange={handleInputOnChange}
          handleSubmit={handleSubmit}
          onEditIconClick={onEditIconClick}
        />
      </div>
    </div>
  );
};

export default AdminManagerCard;
