import {
  SUBSCRIBE_NEWS_LETTER_BEGIN,
  SUBSCRIBE_NEWS_LETTER_SUCCESS,
  SUBSCRIBE_NEWS_LETTER_FAILURE,
  RESET_SUBSCRIBE_NEWS_LETTER,
  SUBMIT_CONTACT_FORM_BEGIN,
  SUBMIT_CONTACT_FORM_SUCCESS,
  SUBMIT_CONTACT_FORM_FAILURE,
  RESET_SUBMIT_CONTACT_FORM,
} from "../constants/NewsLetterAndContact";

const initialState = {
  subscribeNewsLetterBegin: false,
  subscribeNewsLetterSuccess: false,
  subscribeNewsLetterSuccessData: null,
  subscribeNewsLetterFailure: false,
  subscribeNewsLetterFailureData: null,

  submitContactFormBegin: false,
  submitContactFormSuccess: false,
  submitContactFormSuccessData: null,
  submitContactFormFailure: false,
  submitContactFormFailureData: null,
};

export const newsLetterAndContactReducer = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // Subscribe newsletter
    case SUBSCRIBE_NEWS_LETTER_BEGIN:
      return {
        ...state,
        subscribeNewsLetterBegin: true,
        subscribeNewsLetterSuccess: false,
        subscribeNewsLetterSuccessData: null,
        subscribeNewsLetterFailure: false,
        subscribeNewsLetterFailureData: null,
      };
    case SUBSCRIBE_NEWS_LETTER_SUCCESS:
      return {
        ...state,
        subscribeNewsLetterBegin: false,
        subscribeNewsLetterSuccess: true,
        subscribeNewsLetterSuccessData: data,
        subscribeNewsLetterFailure: false,
        subscribeNewsLetterFailureData: null,
      };
    case SUBSCRIBE_NEWS_LETTER_FAILURE:
      return {
        ...state,
        subscribeNewsLetterBegin: false,
        subscribeNewsLetterSuccess: false,
        subscribeNewsLetterSuccessData: null,
        subscribeNewsLetterFailure: true,
        subscribeNewsLetterFailureData: data,
      };
    case RESET_SUBSCRIBE_NEWS_LETTER:
      return {
        ...state,
        subscribeNewsLetterBegin: false,
        subscribeNewsLetterSuccess: false,
        subscribeNewsLetterSuccessData: null,
        subscribeNewsLetterFailure: false,
        subscribeNewsLetterFailureData: null,
      };

    // submit contact form
    case SUBMIT_CONTACT_FORM_BEGIN:
      return {
        ...state,
        submitContactFormBegin: true,
        submitContactFormSuccess: false,
        submitContactFormSuccessData: null,
        submitContactFormFailure: false,
        submitContactFormFailureData: null,
      };
    case SUBMIT_CONTACT_FORM_SUCCESS:
      return {
        ...state,
        submitContactFormBegin: false,
        submitContactFormSuccess: true,
        submitContactFormSuccessData: data,
        submitContactFormFailure: false,
        submitContactFormFailureData: null,
      };
    case SUBMIT_CONTACT_FORM_FAILURE:
      return {
        ...state,
        submitContactFormBegin: false,
        submitContactFormSuccess: false,
        submitContactFormSuccessData: null,
        submitContactFormFailure: true,
        submitContactFormFailureData: data,
      };
    case RESET_SUBMIT_CONTACT_FORM:
      return {
        ...state,
        submitContactFormBegin: false,
        submitContactFormSuccess: false,
        submitContactFormSuccessData: null,
        submitContactFormFailure: false,
        submitContactFormFailureData: null,
      };

    default:
      return state;
  }
};
