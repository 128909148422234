export const SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_BEGIN =
  "SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_BEGIN";
export const SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_SUCCESS =
  "SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_SUCCESS";
export const SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_FAILURE =
  "SUBMIT_MENTOR_ADD_CALENDAR_PREFERENCE_FAILURE";
export const RESET_MENTOR_ADD_CALENDAR_PREFERENCE =
  "RESET_MENTOR_ADD_CALENDAR_PREFERENCE";

export const GET_MENTOR_CALENDAR_PREFERENCE_BEGIN =
  "GET_MENTOR_CALENDAR_PREFERENCE_BEGIN";
export const GET_MENTOR_CALENDAR_PREFERENCE_SUCCESS =
  "GET_MENTOR_CALENDAR_PREFERENCE_SUCCESS";
export const GET_MENTOR_CALENDAR_PREFERENCE_FAILURE =
  "GET_MENTOR_CALENDAR_PREFERENCE_FAILURE";
export const RESET_GET_MENTOR_CALENDAR_PREFERENCE =
  "RESET_GET_MENTOR_CALENDAR_PREFERENCE";

export const UPDATE_MENTOR_AVAILABLE_SLOTS_BEGIN =
  "UPDATE_MENTOR_AVAILABLE_SLOTS_BEGIN";
export const UPDATE_MENTOR_AVAILABLE_SLOTS_SUCCESS =
  "UPDATE_MENTOR_AVAILABLE_SLOTS_SUCCESS";
export const UPDATE_MENTOR_AVAILABLE_SLOTS_FAILURE =
  "UPDATE_MENTOR_AVAILABLE_SLOTS_FAILURE";
export const RESET_UPDATE_MENTOR_AVAILABLE_SLOTS =
  "RESET_UPDATE_MENTOR_AVAILABLE_SLOTS";

export const DELETE_MENTOR_AVAILABLE_SLOTS_BEGIN =
  "DELETE_MENTOR_AVAILABLE_SLOTS_BEGIN";
export const DELETE_MENTOR_AVAILABLE_SLOTS_SUCCESS =
  "DELETE_MENTOR_AVAILABLE_SLOTS_SUCCESS";
export const DELETE_MENTOR_AVAILABLE_SLOTS_FAILURE =
  "DELETE_MENTOR_AVAILABLE_SLOTS_FAILURE";
export const RESET_DELETE_MENTOR_AVAILABLE_SLOTS =
  "RESET_DELETE_MENTOR_AVAILABLE_SLOTS";

export const VIEW_MENTOR_MEETING_BEGIN = "VIEW_MENTOR_MEETING_BEGIN";
export const VIEW_MENTOR_MEETING_SUCCESS = "VIEW_MENTOR_MEETING_SUCCESS";
export const VIEW_MENTOR_MEETING_FAILURE = "VIEW_MENTOR_MEETING_FAILURE";
export const RESET_VIEW_MENTOR_MEETING = "RESET_VIEW_MENTOR_MEETING";
export const GET_MENTOR_MEETING_BEGIN = "GET_MENTOR_MEETING_BEGIN";
export const GET_MENTOR_MEETING_SUCCESS = "GET_MENTOR_MEETING_SUCCESS";
export const GET_MENTOR_MEETING_FAILURE = "GET_MENTOR_MEETING_FAILURE";
export const RESET_GET_MENTOR_MEETING = "RESET_GET_MENTOR_MEETING";
