import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";

import { getMemberNotifications } from "../../../AppRedux/actions/MemberDashboard";
import { Scaleloader } from "../../utilities";
import { NoListToShowContainer } from "../dashboardGeneralItems";

const NotificationDropdown = ({ readAllNotifications, unreadMessageCount }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [showUnReadIndicator, setShowUnReadIndicator] = useState(true);
  const [notificationPageNumber, setNotificationPageNumber] = useState(1);
  const [notificationCount, setNotificationCount] = useState();
  const [unreadList, setUnreadList] = useState([]);
  const { getMemberNotificationsSuccessData, getMemberNotificationsBegin } =
    useSelector(({ memberDashboard }) => memberDashboard);

  useEffect(() => {
    if (getMemberNotificationsSuccessData) {
      const userNotifications = getMemberNotificationsSuccessData?.data;
      setItems((notificationItems) => [
        ...notificationItems,
        ...userNotifications.notifications.rows,
      ]);
      setNotificationCount(userNotifications.notifications.count);

      getMemberNotificationsSuccessData?.data?.notifications.rows.map(
        ({ id, status }) => {
          if (status === 1) {
            setUnreadList((options) => [...options, id]);
          }
          return "";
        }
      );
    }
  }, [getMemberNotificationsSuccessData]);

  const getTotalNumberOfPages = () => {
    const totalPages = parseInt(notificationCount / 20),
      leftColumns = notificationCount - totalPages * 20;
    return leftColumns > 0 ? totalPages + 1 : totalPages;
  };

  const fetchMoreData = () => {
    if (notificationPageNumber >= getTotalNumberOfPages()) {
      setHasMore(false);
      return;
    }

    dispatch(getMemberNotifications(notificationPageNumber + 1));
    setNotificationPageNumber(notificationPageNumber + 1);
  };

  const OnShowUnReadIndicatorClick = (id) => {
    if (unreadList.includes(id)) {
      setUnreadList(
        unreadList.filter((optionId) => {
          return optionId !== id;
        })
      );
    }
  };

  return (
    <div className="notificationDropdownList text-brandDarkBlue">
      <div className="flex items-center justify-between ">
        <h1 className="text-left text-xl font-semibold">Notification Center</h1>
        {unreadMessageCount ? (
          <button
            className=" font-bold dashboardLink"
            onClick={() => {
              setShowUnReadIndicator(false);
              readAllNotifications();
            }}
          >
            Mark all as read
          </button>
        ) : (
          ""
        )}
      </div>
      <div
        className="border-b-2 border-solid mt-3 "
        style={{ borderColor: "#ECECEC" }}
      />
      {items?.length > 0 ? (
        <div
          style={{
            minWidth: "250px",
            height: "50vh",
            overflow: "auto",
          }}
          id="notificationScrollableDiv"
        >
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            scrollableTarget="notificationScrollableDiv"
            endMessage={
              <p style={{ textAlign: "center" }}>
                {/* <b>Yay! You have seen it all</b> */}
              </p>
            }
          >
            {items?.map(
              ({ id, title, createdAt, status }, index, allNotifications) => {
                const calendarFormat = (date) =>
                  moment(date).calendar(null, {
                    sameDay: "[Today]",
                    nextDay: "[Tomorrow]",
                    nextWeek: "dddd",
                    lastDay: "[Yesterday]",
                    lastWeek: "[Last] dddd",
                    sameElse: "DD/MM/YYYY",
                  });

                const date = () => {
                  if (index >= 1) {
                    if (
                      calendarFormat(createdAt) !==
                      calendarFormat(allNotifications[index - 1].createdAt)
                    ) {
                      return calendarFormat(createdAt);
                    } else {
                      return null;
                    }
                  } else {
                    return calendarFormat(createdAt);
                  }
                };

                return (
                  <div key={index}>
                    {date() && (
                      <h1
                        className="text-sm 500px:text-base font-semibold my-2"
                        style={{ color: "#4E4E4E" }}
                      >
                        {date()}
                      </h1>
                    )}
                    <p
                      className="bg-lightGrey pt-2 px-2"
                      style={{ color: "#525252" }}
                    >
                      {status === 1 && showUnReadIndicator && (
                        <span
                          className={`w-2 h-2 inline-block mx-2 rounded-full cursor-pointer activeState
                          ${unreadList.includes(id) ? "visible" : "hidden"}
                          `}
                          style={{ backgroundColor: "#12BE76" }}
                          onClick={() => {
                            OnShowUnReadIndicatorClick(id);
                          }}
                        />
                      )}
                      {title}

                      <span
                        className="border-b block border-solid py-1"
                        style={{ borderColor: "#ECECEC" }}
                      />
                    </p>
                  </div>
                );
              }
            )}
          </InfiniteScroll>
        </div>
      ) : getMemberNotificationsBegin ? (
        <Scaleloader />
      ) : (
        <NoListToShowContainer text="There are no notifications for you!" />
      )}
    </div>
  );
};

export default NotificationDropdown;
