import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import { submitLogout } from "../../../AppRedux/actions/MemberLogin";
import { setupPasswordFormValidation } from "../../utilities/SetUpPasswordForm";
import PageData from "../../utilities/content/pages.json";
import { FormButton, Cliploader, errorToast, infoToast } from "../../utilities";
import { PasswordTickIcon } from "../../utilities/svg";
import { checkUserEmail, submitMemberRegistrationForm } from "../../../AppRedux/actions/MemberRegistration";
import {
  InputGroup,
  PhoneNumberInput,
  InputGroupEye,
} from "../../utilities/newFormInputs";

import { removeUserDataFromLS, getUserDataFromLocalStorage } from "../../utilities";

const MemberBasicInformationForm = ({
  changeToNextPage,
  memberRegisterData,
}) => {
  window.location.hash = "step1";

  const navigate = useNavigate()
  const [params] = useSearchParams();
  const refId = params.getAll("refId").toString();


  const { submitUserLogoutSuccessData } = useSelector(
    ({ memberLogin }) => memberLogin
  );

  const [formError, setFormError] = useState({}),
    dispatch = useDispatch(),
    [passwordConditon, setPasswordCondition] = useState({
      conditionOne: false,
      conditionTwo: false,
      conditionThree: false,
    }),
    [memberBasicRegistrationData, setMemberBasicRegistrationData] = useState({
      member_register_firstName: "",
      member_register_lastName: "",
      member_register_email: "",
      password: "",
      confirmPassword: "",
      ...memberRegisterData,
    }),
    [member_register_phoneNumber, setMember_register_phoneNumber] = useState(
      memberRegisterData.member_register_phoneNumber
        ? memberRegisterData.member_register_phoneNumber
        : ""
    ),
    { heading } = PageData.joinPageData.basicDetailsForm;


  const {
    checkUserRegisterEmailBegin,
    checkUserRegisterEmailFailureData,
    checkUserRegisterEmailSuccessData,
    submitMemberRegistrationFormBegin,
    submitMemberRegistrationFormSuccessData,
    submitMemberRegistrationFormFailureData,
  } = useSelector(({ memberRegistration }) => memberRegistration);


  useEffect(() => {
    const { role } = getUserDataFromLocalStorage()
    if (role) {
      dispatch(submitLogout());
    }
  }, [dispatch]);

  useEffect(() => {
    if (submitUserLogoutSuccessData) {
      removeUserDataFromLS();
      infoToast("Already existing user logged out successfully")
      navigate(`/join?refId=${refId}#step1`)
    }

  }, [navigate, refId, submitUserLogoutSuccessData])

  useEffect(() => {
    if (submitMemberRegistrationFormSuccessData) {
      changeToNextPage(
        "registration_verify_otp_page",
        memberBasicRegistrationData
      );
    }
    if (submitMemberRegistrationFormFailureData)
      errorToast(submitMemberRegistrationFormFailureData.data.message)


  }, [submitMemberRegistrationFormSuccessData, submitMemberRegistrationFormFailureData, changeToNextPage,
    memberBasicRegistrationData])

  useEffect(() => {
    if (checkUserRegisterEmailSuccessData) {

      const payload =
      {
        firstName: memberBasicRegistrationData.member_register_firstName,
        lastName: memberBasicRegistrationData.member_register_lastName,
        email: memberBasicRegistrationData.member_register_email,
        phone: member_register_phoneNumber,
        password: memberBasicRegistrationData.password,
        refId,
      }

      dispatch(submitMemberRegistrationForm(payload))
    }
    if (checkUserRegisterEmailFailureData) {
      errorToast(checkUserRegisterEmailFailureData.message)
    }
  }, [dispatch,
    checkUserRegisterEmailSuccessData,
    checkUserRegisterEmailFailureData,
    memberBasicRegistrationData,
    changeToNextPage,
    member_register_phoneNumber,
    refId
  ]);

  const customMemberBasicFormValidation = () => {
    const { member_register_firstName, member_register_lastName, member_register_email } =
      memberBasicRegistrationData;

    const validationErrors = {};
    if (member_register_firstName?.trim() === "") {
      validationErrors.member_register_firstName = "First name is required.";
    }
    if (
      member_register_firstName?.trim() !== "" &&
      member_register_firstName.length < 3
    ) {
      validationErrors.member_register_firstName =
        "* please give your valid name.";
    }

    if (member_register_lastName?.trim() === "") {
      validationErrors.member_register_lastName = "Last name is required.";
    }

    if (member_register_email?.trim() === "") {
      validationErrors.member_register_email = "Email is required.";
    }
    if (
      member_register_email?.trim() !== "" &&
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(member_register_email)
    ) {
      validationErrors.member_register_email =
        "* Please enter a valid email address!";
    }

    if (member_register_phoneNumber?.trim() === "") {
      validationErrors.member_register_phoneNumber =
        "Phone number is required.";
    }
    if (
      member_register_phoneNumber?.trim() !== "" &&
      !isValidPhoneNumber(member_register_phoneNumber)
    ) {
      validationErrors.member_register_phoneNumber =
        "* You have entered an invalid phone number!";
    } else if (
      member_register_phoneNumber?.trim() !== "" &&
      isValidPhoneNumber(member_register_phoneNumber)
    ) {
      delete validationErrors[member_register_phoneNumber];
    }

    return validationErrors;
  };

  const conditions = [
    {
      check: passwordConditon.conditionOne,
      title: "Contains at least 8 characters",
    },
    {
      check: passwordConditon.conditionTwo,
      title: "Contains both lower (a-z) and upper case letters (A-Z)",
    },
    {
      check: passwordConditon.conditionThree,
      title: "Contains at least one number (0-9) or a symbol",
    },
  ];

  const checkConditions = (value) => {
    var lowerAndUpperCase = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
    var specialCharacter = new RegExp("^(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$");
    if (value?.trim() === "" || value.length < 8) {
      setPasswordCondition({
        ...passwordConditon,
        conditionOne: false,
        conditionTwo: false,
        conditionThree: false,
      });
    }
    if (value?.trim() !== "" && value.length >= 8) {
      setPasswordCondition({ conditionOne: true });
    }
    if (
      value?.trim() !== "" &&
      value.length >= 8 &&
      lowerAndUpperCase.test(value)
    ) {
      setPasswordCondition({
        ...passwordConditon,
        conditionOne: true,
        conditionTwo: true,
      });
    }
    if (
      value?.trim() !== "" &&
      value.length >= 8 &&
      specialCharacter.test(value)
    ) {
      setPasswordCondition({
        ...passwordConditon,
        conditionOne: true,
        conditionTwo: true,
        conditionThree: true,
      });
    }
  };

  const onMemberBasicDetailsSubmit = (e) => {
    e.preventDefault();
    const validationErrors = customMemberBasicFormValidation();
    const passwordvalidationErrors = setupPasswordFormValidation(
      memberBasicRegistrationData.password,
      memberBasicRegistrationData.confirmPassword
    );

    if (Object.keys(validationErrors).length === 0 && Object.keys(passwordvalidationErrors).length === 0) {
      const emailData = {
        email: memberBasicRegistrationData.member_register_email,
      };
      dispatch(checkUserEmail(emailData));
    } else {
      setFormError({ ...formError, ...validationErrors });
      setFormError({
        ...formError,
        ...validationErrors,
        ...passwordvalidationErrors,
      });
    }
  };

  const handleInputOnchange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z]/gi, "");
    }

    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setMemberBasicRegistrationData({
      ...memberBasicRegistrationData,
      [e.target.name]: value,
    });
  };

  const handlePasswordChange = (e) => {
    let value = e.target.value;

    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });

    setMemberBasicRegistrationData({
      ...memberBasicRegistrationData,
      [e.target.name]: value,
    });
    checkConditions(value);
  };

  return (
    <div className="lg:w-1/2 py-4 sm:py-12 px-4 sm:px-24 bg-lightGrey">
      <h2 className="font-medium text-2xl sm:text-28px mb-7 text-brandDarkBlue uppercase">
        {heading}
      </h2>
      <form
        className="w-full xl:w-10/12 2xl:w-3/4"
        onSubmit={(e) => onMemberBasicDetailsSubmit(e)}
        id="member_form"
      >
        <div className="flex lg:flex-row flex-col lg:space-x-4 ">
          <InputGroup
            label="First Name"
            name="member_register_firstName"
            onChange={handleInputOnchange}
            value={memberBasicRegistrationData?.member_register_firstName}
            errorMessage={formError?.member_register_firstName}
            alphabetsOnly={true}
          />
          <InputGroup
            label="Last Name"
            name="member_register_lastName"
            onChange={handleInputOnchange}
            value={memberBasicRegistrationData?.member_register_lastName}
            errorMessage={formError?.member_register_lastName}
            alphabetsOnly={true}
          />
        </div>
        <InputGroup
          label="Email"
          name="member_register_email"
          onChange={handleInputOnchange}
          value={memberBasicRegistrationData?.member_register_email}
          errorMessage={formError?.member_register_email}
        />

        <PhoneNumberInput
          label="Mobile Number"
          name="member_register_phoneNumber"
          infoIcon={true}
          value={member_register_phoneNumber}
          onChange={(value) => {
            formError.member_register_phoneNumber = "";
            setMember_register_phoneNumber(value);
          }}
          errorMessage={formError?.member_register_phoneNumber}
        />

        <InputGroupEye
          label="Setup password"
          name="password"
          value={memberBasicRegistrationData.password}
          onChange={(e) => handlePasswordChange(e)}
          errorMessage={formError?.password}
        />
        {memberBasicRegistrationData.password !== "" &&
          <div className="flex flex-col my-2">
            {conditions.map(({ check, title }, index) => (
              <React.Fragment key={index}>

                <div className="animate-navbar flex items-center space-x-2">
                  {check ? (<PasswordTickIcon />) : (<p className="text-red-600">X</p>)}
                  <p>{title}</p>
                </div>

              </React.Fragment>
            ))}
          </div>}


        <InputGroupEye
          label="Confirm Password"
          name="confirmPassword"
          value={memberBasicRegistrationData.confirmPassword}
          onChange={(e) => handlePasswordChange(e)}
          errorMessage={formError?.confirmPassword}
        />

        <FormButton classNames="bg-brandBlue mt-6 text-white buttonHover">
          Continue{(checkUserRegisterEmailBegin || submitMemberRegistrationFormBegin) && <Cliploader />}
        </FormButton>
      </form>
      <div className="font-semibold text-base mt-4 flex gap-1">
        Already have an account?
        <p className="buttonHover activeState">
          <Link to="/login/member" className="text-brandBlue">
            Login.
          </Link>
        </p>
      </div>
    </div>
  );
};

export default MemberBasicInformationForm;
