import React, { useState } from "react";

import FormBackAndContinueButton from "../../MemberRegisterForms/FormBackAndContinueButton";
import { UploadFileIcon, UploadArrowIcon } from "../../../utilities/svg";
import { FormButton, scrollToTop, InputGroup } from "../../../utilities";
import {
  CertificationInputContainer,
  CoachingProgramInputContainer,
  handleInputOnchange,
  onMentorProfessionalDetailsSubmit,
  UniversityInputsContainer,
} from "./formFunctions";

const MentorProfessionalInformation = ({
  changeToNextPage,
  onBackButtonClick,
  continueButtonloader,
  backButtonLoader,
  mentorRegisterData,
}) => {
  window.location.hash = 'step3';
  const [numberOfUniversityInputGroup, setNumberOfUniversityInputGroup] =
    useState(
      mentorRegisterData.universities
        ? Object.keys(mentorRegisterData.universities)?.length
        : 1
    );
  const [numberOfCertificationInput, setNumberOfCertificationInput] = useState(
    mentorRegisterData.certifications
      ? Object.keys(mentorRegisterData.certifications)?.length
      : 1
  );
  const [numberOfCoachingProgramInput, setNumberOfCoachingProgramInput] =
    useState(
      mentorRegisterData.coachingPrograms
        ? Object.keys(mentorRegisterData.coachingPrograms)?.length
        : 1
    );

  const [formData, setFormData] = useState({
    universities: {
      university0: "",
      ...mentorRegisterData.universities,
    },
    degrees: {
      degree0: "",
      ...mentorRegisterData.degrees,
    },
    certifications: {
      certification0: "",
      ...mentorRegisterData.certifications,
    },
    coachingPrograms: {
      coachingProgram0: "",
      ...mentorRegisterData.coachingPrograms,
    },
    mentor_linkedin: mentorRegisterData.mentor_linkedin
      ? mentorRegisterData.mentor_linkedin
      : "",
    mentor_website: mentorRegisterData.mentor_website
      ? mentorRegisterData.mentor_website
      : "",
    mentor_resume: mentorRegisterData.mentor_resume
      ? mentorRegisterData.mentor_resume
      : "",
  });
  const [mentorUploadedResumeName, setMentorUploadedResumeName] = useState(
    mentorRegisterData.mentor_resume
      ? mentorRegisterData.mentor_resume.name
      : ""
  );

  const [formError, setFormError] = useState({
    universities: {
      university0: "",
    },
    degrees: {
      degree0: "",
    },
    certifications: {
      certification0: "",
    },
    coachingPrograms: {
      coachingProgram0: "",
    },
  });

  const handleBackButtonClick = () => {
    onBackButtonClick("mentor_registration_features");
    scrollToTop("50", "16.66");
  };

  return (
    <section className="homepageWidth md:w-70% mx-auto pt-4 mb-12 ">
      <h1
        className="font-bold text-3xl text-center sm:mt-8 mt-4"
        style={{ color: "#093B5D" }}
      >
        Professional Information
      </h1>

      <h1 className="mt-8 sm:mt-20 font-semibold sm:text-2xl text-xl mb-5">
        Educational Information
      </h1>
      <form
        id="mentor_form"
        onSubmit={(e) =>
          onMentorProfessionalDetailsSubmit(
            e,
            formData,
            changeToNextPage,
            setFormError,
            formError
          )
        }
        className="px-2"
      >
        <UniversityInputsContainer
          numberOfUniversityInputGroup={numberOfUniversityInputGroup}
          formData={formData}
          formError={formError}
          setFormData={setFormData}
          setNumberOfUniversityInputGroup={setNumberOfUniversityInputGroup}
        />
        <div className="md:grid md:grid-cols-1 950px:grid-cols-2 950px:gap-x-14 950px:gap-y-6">
          <div className="flex flex-col">
            <CertificationInputContainer
              numberOfCertificationInput={numberOfCertificationInput}
              formData={formData}
              formError={formError}
              setFormData={setFormData}
              setNumberOfCertificationInput={setNumberOfCertificationInput}
            />
          </div>
          <div className="flex flex-col">
            <CoachingProgramInputContainer
              numberOfCoachingProgramInput={numberOfCoachingProgramInput}
              formData={formData}
              formError={formError}
              setFormData={setFormData}
              setNumberOfCoachingProgramInput={setNumberOfCoachingProgramInput}
            />
          </div>
          <h1 className="font-semibold sm:text-2xl text-xl mt-6 950px:mt-2">
            Professional Information
          </h1>
          <br></br>
          <InputGroup
            label="LinkedIn url"
            name="mentor_linkedin"
            onChange={(e) =>
              handleInputOnchange(e, formError, setFormData, formData)
            }
            value={formData?.mentor_linkedin}
            errorMessage={formError?.mentor_linkedin}
          />
          <InputGroup
            label="Website url"
            name="mentor_website"
            onChange={(e) =>
              handleInputOnchange(e, formError, setFormData, formData)
            }
            value={formData?.mentor_website}
            errorMessage={formError?.mentor_website}
          />
          <div className="appInput flexCenter flex-col h-full relative activeState buttonHover">
            <div
              className="rounded-full w-14 h-14 flexCenter"
              style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)" }}
            >
              <UploadFileIcon />
            </div>
            {mentorUploadedResumeName !== "" && (
              <p className="border-2 border-brandBlue text-brandBlue px-2 animate-navbar rounded-xl text-sm">
                {mentorUploadedResumeName}
              </p>
            )}
            <h1 style={{ color: "#A0A0A0" }} className="my-4 font-medium">
              Drag & Drop your resume here
            </h1>
            <FormButton
              type="button"
              classNames="bg-brandBlue text-white w-2/5 py-2"
            >
              <UploadArrowIcon />
              {mentorUploadedResumeName !== "" ? "Change" : "Upload"}
            </FormButton>
            <input
              type="file"
              name="mentor_resume"
              accept="application/pdf, image/png, image/jpg, image/jpeg, application/msword, .docx"
              onChange={(e) => {
                setMentorUploadedResumeName(
                  e.target.files[0] ? e.target.files[0]?.name : ""
                );
                handleInputOnchange(
                  e,
                  formError,
                  setFormData,
                  formData,
                  "file"
                );
              }}
              className="absolute w-full h-full cursor-pointer opacity-0"
            />
            <p className="text-red-600 text-base">{formError.mentor_resume}</p>
          </div>
        </div>
        <div className="w-96% md:w-1/2 mx-auto my-12">
          <FormBackAndContinueButton
            onBackButtonClick={handleBackButtonClick}
            actionBegin={continueButtonloader}
            backButtonLoader={backButtonLoader}
          />
        </div>
      </form>
    </section>
  );
};

export default MentorProfessionalInformation;
