import React, { useEffect } from "react";

const FacebookLoginButton = (props) => {
  const {
    className = "",
    scope = "email",
    appId,
    children,
    onLoginSuccess = () => {},
    onLoginFailure = () => {},
  } = props;

  const onLoad = () => {
    window.FB.init({
      appId: appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: "v7.0",
    });
  };

  const loadScript = (d, s, id, jsSrc, cb) => {
    const idElement = d.getElementById(id);
    if (!idElement) {
      // only load js, if it is not already loaded
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      js = d.createElement(s);
      js.id = id;
      js.src = jsSrc;
      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      } else {
        d.head.appendChild(js);
      }
      js.onload = cb;
    } else {
      cb && cb();
    }
  };

  useEffect(() => {
    loadScript(
      document,
      "script",
      "facebook-jssdk",
      "https://connect.facebook.net/en_US/sdk.js"
    );
    window.fbAsyncInit = onLoad;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkLoginState = () => {
    // if user is logged in then logout him
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        if (response.status === "connected") {
          window.FB.logout(() => {
            resolve(true);
          });
        } else {
          resolve(true);
        }
      });
    });
  };

  const login = async () => {
    const response = await checkLoginState();
    if (response) {
      window.FB.login(onStatusChange, {
        scope: scope,
        auth_type: "reauthenticate",
      });
    }
  };

  const onStatusChange = (response) => {
    if (response.status === "connected") {
      onLoginSuccess(response.authResponse);
    } else {
      onLoginFailure(response);
    }
  };

  return (
    <span className={`FacebookLoginButton ${className}`} onClick={login}>
      {children}
    </span>
  );
};

export default FacebookLoginButton;
