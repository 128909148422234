import statusData from "./content/statusAndRoleManagement.json";
var CryptoJS = require("crypto-js");

export const storeMemberDetailsInLocalStorage = (userData) => {
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(userData),
    "secret key myMerity"
  ).toString();
  localStorage.setItem("MemberUserData", ciphertext);
};

export const storeTrialKeyInLocalStorage = (trialCode) => {
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(trialCode),
    "secret key myMerity"
  ).toString();
  localStorage.setItem("MemberTrialCode", ciphertext);

};


export const getTrialKeyInLocalStorage = () => {
  const ciphertext = localStorage.getItem("MemberTrialCode");
  if (!ciphertext) {
    return false;
  } else {
    var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key myMerity");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    if (decryptedData) {
      if(decryptedData.indexOf("TRIAL") > -1) {
        localStorage.removeItem("MemberTrialCode");
        return true; 
      }
      return false;
    } else {
      return false;
    }
  }
};

export const storeAdminDetailsInLocalStorage = (userData) => {
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(userData),
    "secret key myMerity"
  ).toString();
  localStorage.setItem("AdminUserData", ciphertext);
};


export const getAdminDetailsInLocalStorage = () => {
  const ciphertext = localStorage.getItem("AdminUserData");
  if (!ciphertext) {
    return false;
  } else {
    var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key myMerity");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (decryptedData) {
      return decryptedData;
    } else {
      return false;
    }
  }
};

export const getUserTokenFromLocalStorage = () => {
  const ciphertext = localStorage.getItem("MemberUserData");
  if (!ciphertext) {
    return false;
  } else {
    var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key myMerity");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (decryptedData.token) {
      return decryptedData.token;
    } else {
      return false;
    }
  }
};

export const getUserDataFromLocalStorage = () => {
  const ciphertext = localStorage.getItem("MemberUserData");
  if (!ciphertext) {
    return false;
  } else {
    var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key myMerity");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (decryptedData.user) {
      return decryptedData.user;
    } else {
      return false;
    }
  }
};

export const storeMemberSignUpStatusInLocalStorage = (status) => {
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    status.toString(),
    "secret key myMerity"
  ).toString();
  localStorage.setItem("memberSignStat", ciphertext);
};

export const getMemberSignupStatusFromLocalStorage = () => {
  const ciphertext = localStorage.getItem("memberSignStat");
  if (!ciphertext) {
    return false;
  } else {
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key myMerity");
    var status = bytes.toString(CryptoJS.enc.Utf8);
    if (status) {
      return status;
    } else {
      return false;
    }
  }
};

export const currentAppUser = () => {
  const signupStatus = getMemberSignupStatusFromLocalStorage();
  const { role } = getUserDataFromLocalStorage();

  let isSubscribed =
    signupStatus === statusData.SIGNUP_STATUS.SUBSCRIBED.toString();
  let isMemberData = role === statusData.ROLES.MEMBER;

  if (isMemberData && !isSubscribed) {
    return "MEMBER_NOT_SUBSCRIBED";
  }
  if (isMemberData && isSubscribed) {
    return "MEMBER_SUBSCRIBED";
  }
  if (role === statusData.ROLES.MENTOR) {
    return "MENTOR";
  }
  if (role === statusData.ROLES.ADMIN) {
    return "ADMIN";
  }
  if (role === statusData.ROLES.MANAGER) {
    return "MANAGER";
  }
};

// store get dashboard details
export const storeMemberDashboardDetailsInLocalStorage = (userData) => {
  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(userData),
    "secret key myMerity"
  ).toString();
  localStorage.setItem("MemberDashboardData", ciphertext);
};

export const getMemberDashboardDataFromLocalStorage = () => {
  const ciphertext = localStorage.getItem("MemberDashboardData");
  if (!ciphertext) {
    return false;
  } else {
    var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key myMerity");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    if (decryptedData) {
      return decryptedData;
    } else {
      return false;
    }
  }
};

export const removeUserDataFromLS = () => {
  localStorage.removeItem("MemberUserData");
  localStorage.removeItem("memberSignStat");
  localStorage.removeItem("MemberDashboardData");
};
