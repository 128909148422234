import {
  VIEW_ALL_EXERCISES_BEGIN,
  VIEW_ALL_EXERCISES_SUCCESS,
  VIEW_ALL_EXERCISES_FAILURE,
  RESET_VIEW_ALL_EXERCISES,
  ADD_NEW_EXERCISE_BEGIN,
  ADD_NEW_EXERCISE_SUCCESS,
  ADD_NEW_EXERCISE_FAILURE,
  RESET_ADD_NEW_EXERCISE,
  SAVE_STORY_BEGIN,
  SAVE_STORY_SUCCESS,
  SAVE_STORY_FAILURE,
  RESET_SAVE_STORY,
  PUBLISH_STORY_BEGIN,
  PUBLISH_STORY_SUCCESS,
  PUBLISH_STORY_FAILURE,
  RESET_PUBLISH_STORY,
  GET_STORIES_BEGIN,
  GET_STORIES_SUCCESS,
  GET_STORIES_FAILURE,
  RESET_GET_STORIES,
  EDIT_EXERCISE_BEGIN,
  EDIT_EXERCISE_SUCCESS,
  EDIT_EXERCISE_FAILURE,
  RESET_EDIT_EXERCISE,
  VIEW_ALL_TIF_BEGIN,
  VIEW_ALL_TIF_SUCCESS,
  VIEW_ALL_TIF_FAILURE,
  RESET_VIEW_ALL_TIF,
  ADD_NEW_TIF_BEGIN,
  ADD_NEW_TIF_SUCCESS,
  ADD_NEW_TIF_FAILURE,
  RESET_ADD_NEW_TIF,
  EDIT_TIF_BEGIN,
  EDIT_TIF_SUCCESS,
  EDIT_TIF_FAILURE,
  RESET_EDIT_TIF,
  DELETE_CURRICULUM_BEGIN,
  DELETE_CURRICULUM_SUCCESS,
  DELETE_CURRICULUM_FAILURE,
  RESET_DELETE_CURRICULUM,
} from "../constants/AdminDashboard";

const initialState = {
  viewAllExercisesBegin: false,
  viewAllExercisesSuccess: false,
  viewAllExercisesSuccessData: null,
  viewAllExercisesFailure: false,
  viewAllExercisesFailureData: null,

  addNewExerciseBegin: false,
  addNewExerciseSuccess: false,
  addNewExerciseSuccessData: null,
  addNewExerciseFailure: false,
  addNewExerciseFailureData: null,

  saveStoryBegin: false,
  saveStorySuccess: false,
  saveStorySuccessData: null,
  saveStoryFailure: false,
  saveStoryFailureData: null,

  publishStoryBegin: false,
  publishStorySuccess: false,
  publishStorySuccessData: null,
  publishStoryFailure: false,
  publishStoryFailureData: null,

  getStoriesBegin: false,
  getStoriesSuccess: false,
  getStoriesSuccessData: null,
  getStoriesFailure: false,
  getStoriesFaiilureData: null,

  editExerciseBegin: false,
  editExerciseSuccess: false,
  editExerciseSuccessData: null,
  editExcerciseFailure: false,
  editExcerciseFailureData: null,

  viewAllTifBegin: false,
  viewAllTifSuccess: false,
  viewAllTifSuccessData: null,
  viewAllTifFailure: false,
  viewAllTifFailureData: null,

  addNewTifBegin: false,
  addNewTifSuccess: false,
  addNewTifSuccessData: null,
  addNewTifFailure: false,
  addNewTifFailureData: null,

  editTifBegin: false,
  editTifSuccess: false,
  editTifSuccessData: null,
  editTifFailure: false,
  editTifFailureData: null,

  deleteCurriculumBegin: false,
  deleteCurriculumSuccess: false,
  deleteCurriculumSuccessData: null,
  deleteCurriculumFailure: false,
  deleteCurriculumFailureData: null,
};

export const adminDashboard = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // view all exercises
    case VIEW_ALL_EXERCISES_BEGIN:
      return {
        ...state,
        viewAllExercisesBegin: true,
        viewAllExercisesSuccess: false,
        viewAllExercisesSuccessData: null,
        viewAllExercisesFailure: false,
        viewAllExercisesFailureData: null,
      };
    case VIEW_ALL_EXERCISES_SUCCESS:
      return {
        ...state,
        viewAllExercisesBegin: false,
        viewAllExercisesSuccess: true,
        viewAllExercisesSuccessData: data,
        viewAllExercisesFailure: false,
        viewAllExercisesFailureData: null,
      };
    case VIEW_ALL_EXERCISES_FAILURE:
      return {
        ...state,
        viewAllExercisesBegin: false,
        viewAllExercisesSuccess: false,
        viewAllExercisesSuccessData: null,
        viewAllExercisesFailure: true,
        viewAllExercisesFailureData: data,
      };
    case RESET_VIEW_ALL_EXERCISES:
      return {
        ...state,
        viewAllExercisesBegin: false,
        viewAllExercisesSuccess: false,
        viewAllExercisesSuccessData: null,
        viewAllExercisesFailure: false,
        viewAllExercisesFailureData: null,
      };

    // add new exercise
    case ADD_NEW_EXERCISE_BEGIN:
      return {
        ...state,
        addNewExerciseBegin: true,
        addNewExerciseSuccess: false,
        addNewExerciseSuccessData: null,
        addNewExerciseFailure: false,
        addNewExerciseFailureData: null,
      };
    case ADD_NEW_EXERCISE_SUCCESS:
      return {
        ...state,
        addNewExerciseBegin: false,
        addNewExerciseSuccess: true,
        addNewExerciseSuccessData: data,
        addNewExerciseFailure: false,
        addNewExerciseFailureData: null,
      };
    case ADD_NEW_EXERCISE_FAILURE:
      return {
        ...state,
        addNewExerciseBegin: false,
        addNewExerciseSuccess: false,
        addNewExerciseSuccessData: null,
        addNewExerciseFailure: true,
        addNewExerciseFailureData: data,
      };
    case RESET_ADD_NEW_EXERCISE:
      return {
        ...state,
        addNewExerciseBegin: false,
        addNewExerciseSuccess: false,
        addNewExerciseSuccessData: null,
        addNewExerciseFailure: false,
        addNewExerciseFailureData: null,
      };

    //save stories

    case SAVE_STORY_BEGIN:
      return {
        ...state,
        saveStoryBegin: true,
        saveStorySuccess: false,
        saveStorySuccessData: null,
        saveStoryFailure: false,
        saveStoryFailureData: null,
      };

    case SAVE_STORY_SUCCESS:
      return {
        ...state,
        saveStoryBegin: false,
        saveStorySuccess: true,
        saveStorySuccessData: data,
        saveStoryFailure: false,
        saveStoryFailureData: null,
      };

    case SAVE_STORY_FAILURE:
      return {
        ...state,
        saveStoryBegin: false,
        saveStorySuccess: false,
        saveStorySuccessData: null,
        saveStoryFailure: true,
        saveStoryFailureData: data,
      };

    case RESET_SAVE_STORY:
      return {
        ...state,
        saveStoryBegin: false,
        saveStorySuccess: false,
        saveStorySuccessData: null,
        saveStoryFailure: false,
        saveStoryFailureData: null,
      };

    //publish stories

    case PUBLISH_STORY_BEGIN:
      return {
        ...state,
        publishStoryBegin: true,
        publishStorySuccess: false,
        publishStorySuccessData: null,
        publishStoryFailure: false,
        publishStoryFailureData: null,
      };

    case PUBLISH_STORY_SUCCESS:
      return {
        ...state,
        publishStoryBegin: false,
        publishStorySuccess: true,
        publishStorySuccessData: data,
        publishStoryFailure: false,
        publishStoryFailureData: null,
      };

    case PUBLISH_STORY_FAILURE:
      return {
        ...state,
        publishStoryBegin: false,
        publishStorySuccess: false,
        publishStorySuccessData: null,
        publishStoryFailure: true,
        publishStoryFailureData: data,
      };

    case RESET_PUBLISH_STORY:
      return {
        ...state,
        publishStoryBegin: false,
        publishStorySuccess: false,
        publishStorySuccessData: null,
        publishStoryFailure: false,
        publishStoryFailureData: null,
      };

    // get stories

    case GET_STORIES_BEGIN:
      return {
        ...state,
        getStoriesBegin: true,
        getStoriesSuccess: false,
        getStoriesSuccessData: null,
        getStoriesFailure: false,
        getStoriesFaiilureData: null,
      };

    case GET_STORIES_SUCCESS:
      return {
        ...state,
        getStoriesBegin: false,
        getStoriesSuccess: true,
        getStoriesSuccessData: data,
        getStoriesFailure: false,
        getStoriesFaiilureData: null,
      };

    case GET_STORIES_FAILURE:
      return {
        ...state,
        getStoriesBegin: true,
        getStoriesSuccess: false,
        getStoriesSuccessData: null,
        getStoriesFailure: true,
        getStoriesFaiilureData: data,
      };

    case RESET_GET_STORIES:
      return {
        ...state,
        getStoriesBegin: false,
        getStoriesSuccess: false,
        getStoriesSuccessData: null,
        getStoriesFailure: false,
        getStoriesFaiilureData: null,
      };

    //edit exercise

    case EDIT_EXERCISE_BEGIN:
      return {
        ...state,
        editExerciseBegin: true,
        editExerciseSuccess: false,
        editExerciseSuccessData: null,
        editExcerciseFailure: false,
        editExcerciseFailureData: null,
      };

    case EDIT_EXERCISE_SUCCESS:
      return {
        ...state,
        editExerciseBegin: false,
        editExerciseSuccess: true,
        editExerciseSuccessData: data,
        editExcerciseFailure: false,
        editExcerciseFailureData: null,
      };

    case EDIT_EXERCISE_FAILURE:
      return {
        ...state,
        editExerciseBegin: false,
        editExerciseSuccess: false,
        editExerciseSuccessData: null,
        editExcerciseFailure: true,
        editExcerciseFailureData: data,
      };

    case RESET_EDIT_EXERCISE:
      return {
        ...state,
        editExerciseBegin: false,
        editExerciseSuccess: false,
        editExerciseSuccessData: null,
        editExcerciseFailure: false,
        editExcerciseFailureData: null,
      };

    // view all tif begin
    case VIEW_ALL_TIF_BEGIN:
      return {
        ...state,
        viewAllTifBegin: true,
        viewAllTifSuccess: false,
        viewAllTifSuccessData: null,
        viewAllTifFailure: false,
        viewAllTifFailureData: null,
      };

    case VIEW_ALL_TIF_SUCCESS:
      return {
        ...state,
        viewAllTifBegin: false,
        viewAllTifSuccess: true,
        viewAllTifSuccessData: data,
        viewAllTifFailure: false,
        viewAllTifFailureData: null,
      };

    case VIEW_ALL_TIF_FAILURE:
      return {
        ...state,
        viewAllTifBegin: false,
        viewAllTifSuccess: false,
        viewAllTifSuccessData: null,
        viewAllTifFailure: true,
        viewAllTifFailureData: data,
      };

    case RESET_VIEW_ALL_TIF:
      return {
        ...state,
        viewAllTifBegin: false,
        viewAllTifSuccess: false,
        viewAllTifSuccessData: null,
        viewAllTifFailure: false,
        viewAllTifFailureData: null,
      };

    //add new Tif

    case ADD_NEW_TIF_BEGIN:
      return {
        ...state,
        addNewTifBegin: true,
        addNewTifSuccess: false,
        addNewTifSuccessData: null,
        addNewTifFailure: false,
        addNewTifFailureData: null,
      };

    case ADD_NEW_TIF_SUCCESS:
      return {
        ...state,
        addNewTifBegin: false,
        addNewTifSuccess: true,
        addNewTifSuccessData: data,
        addNewTifFailure: false,
        addNewTifFailureData: null,
      };

    case ADD_NEW_TIF_FAILURE:
      return {
        ...state,
        addNewTifBegin: false,
        addNewTifSuccess: false,
        addNewTifSuccessData: null,
        addNewTifFailure: true,
        addNewTifFailureData: data,
      };
    case RESET_ADD_NEW_TIF:
      return {
        ...state,
        addNewTifBegin: false,
        addNewTifSuccess: false,
        addNewTifSuccessData: null,
        addNewTifFailure: false,
        addNewTifFailureData: null,
      };

    //edit Tif

    case EDIT_TIF_BEGIN:
      return {
        ...state,
        editTifBegin: true,
        editTifSuccess: false,
        editTifSuccessData: null,
        editTifFailure: false,
        editTifFailureData: null,
      };

    case EDIT_TIF_SUCCESS:
      return {
        ...state,
        editTifBegin: false,
        editTifSuccess: true,
        editTifSuccessData: data,
        editTifFailure: false,
        editTifFailureData: null,
      };

    case EDIT_TIF_FAILURE:
      return {
        ...state,
        editTifBegin: false,
        editTifSuccess: false,
        editTifSuccessData: null,
        editTifFailure: true,
        editTifFailureData: data,
      };

    case RESET_EDIT_TIF:
      return {
        ...state,
        editTifBegin: false,
        editTifSuccess: false,
        editTifSuccessData: null,
        editTifFailure: false,
        editTifFailureData: null,
      };

    //delete curriculum

    case DELETE_CURRICULUM_BEGIN:
      return {
        ...state,
        deleteCurriculumBegin: true,
        deleteCurriculumSuccess: false,
        deleteCurriculumSuccessData: null,
        deleteCurriculumFailure: false,
        deleteCurriculumFailureData: null,
      };

    case DELETE_CURRICULUM_SUCCESS:
      return {
        ...state,
        deleteCurriculumBegin: false,
        deleteCurriculumSuccess: true,
        deleteCurriculumSuccessData: data,
        deleteCurriculumFailure: false,
        deleteCurriculumFailureData: null,
      };

    case DELETE_CURRICULUM_FAILURE:
      return {
        ...state,
        deleteCurriculumBegin: false,
        deleteCurriculumSuccess: false,
        deleteCurriculumSuccessData: null,
        deleteCurriculumFailure: true,
        deleteCurriculumFailureData: data,
      };

    case RESET_DELETE_CURRICULUM:
      return {
        ...state,
        deleteCurriculumBegin: false,
        deleteCurriculumSuccess: false,
        deleteCurriculumSuccessData: null,
        deleteCurriculumFailure: false,
        deleteCurriculumFailureData: null,
      };

    default:
      return state;
  }
};
