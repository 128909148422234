import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Popup } from "../../../AppRedux/actions/MemberRegistration";
import { FormButton } from "../../utilities/Button";
import { UpdateProfile, CrossIconBlack } from "../../utilities/svg";


const UpdateProfileModal = (props) => {

    const { closeUpdateModal } = props
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return (
        <div
            className="fixed z-10 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 animate-modalInitial">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                ></div>
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div className="relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[340px]">
                    <div className="p-[35px] flex flex-col justify-center items-center relative">
                        <div className="absolute top-[14px] right-[16px] cursor-pointer" onClick={() => closeUpdateModal()}>
                            <CrossIconBlack />
                        </div>
                        <UpdateProfile className="mb-[21px] relative z-10" />
                        <h1 className="font-semibold text-[24px] text-center mb-2">
                            Profile details
                        </h1>
                        <p className="font-medium text-[14px] text-center mb-[30px]">
                            You can update your profile details now from your Info profile section
                        </p>
                        <span className="block w-full" onClick={() => {
                            dispatch(Popup("UNSHOW_POP_UP"))
                            navigate(`/member/dashboard/profile?tab=my-info`)
                        }}
                        >
                            <FormButton classNames="bg-brandBlue text-white">
                                Update Profile
                            </FormButton>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProfileModal;
