import {
  GET_MEMBER_PROFILE_BEGIN,
  GET_MEMBER_PROFILE_SUCCESS,
  GET_MEMBER_PROFILE_FAILURE,
  RESET_GET_MEMBER_PROFILE,
  GET_ASSESSMENT_QUESTIONS_BEGIN,
  GET_ASSESSMENT_QUESTIONS_SUCCESS,
  GET_ASSESSMENT_QUESTIONS_FAILURE,
  RESET_GET_ASSESSMENT_QUESTIONS,
  SUBMIT_SINGLE_ASSESSMENT_BEGIN,
  SUBMIT_SINGLE_ASSESSMENT_SUCCESS,
  SUBMIT_SINGLE_ASSESSMENT_FAILURE,
  RESET_SUBMIT_SINGLE_ASSESSMENT,
  VIEW_SUBMITTED_ASSESSMENT_OPTIONS_BEGIN,
  VIEW_SUBMITTED_ASSESSMENT_OPTIONS_SUCCESS,
  VIEW_SUBMITTED_ASSESSMENT_OPTIONS_FAILURE,
  RESET_VIEW_SUBMITTED_ASSESSMENT_OPTIONS,
  PURCHASE_PAYMENT_MINUTES_BEGIN,
  PURCHASE_PAYMENT_MINUTES_SUCCESS,
  PURCHASE_PAYMENT_MINUTES_FAILURE,
  RESET_PURCHASE_PAYMENT_MINUTES,
  GET_MEMBER_CURRICULUM_BEGIN,
  GET_MEMBER_CURRICULUM_SUCCESS,
  GET_MEMBER_CURRICULUM_FAILURE,
  RESET_GET_MEMBER_CURRICULUM,
  VIEW_MEMBER_CURRICULUM_RESPONSE_BEGIN,
  VIEW_MEMBER_CURRICULUM_RESPONSE_SUCCESS,
  VIEW_MEMBER_CURRICULUM_RESPONSE_FAILURE,
  RESET_VIEW_MEMBER_CURRICULUM_RESPONSE,
  SUBMIT_MEMBER_CURRICULUM_RESPONSE_BEGIN,
  SUBMIT_MEMBER_CURRICULUM_RESPONSE_SUCCESS,
  SUBMIT_MEMBER_CURRICULUM_RESPONSE_FAILURE,
  RESET_SUBMIT_MEMBER_CURRICULUM_RESPONSE,
  GET_MEMBER_DASHBOARD_DETAILS_BEGIN,
  GET_MEMBER_DASHBOARD_DETAILS_SUCCESS,
  GET_MEMBER_DASHBOARD_DETAILS_FAILURE,
  RESET_GET_MEMBER_DASHBOARD_DETAILS,
  ASSIGN_MENTOR_TO_MEMBER_BEGIN,
  ASSIGN_MENTOR_TO_MEMBER_SUCCESS,
  ASSIGN_MENTOR_TO_MEMBER_FAILURE,
  RESET_ASSIGN_MENTOR_TO_MEMBER,
  GET_MEMBER_NOTIFICATIONS_BEGIN,
  GET_MEMBER_NOTIFICATIONS_SUCCESS,
  GET_MEMBER_NOTIFICATIONS_FAILURE,
  RESET_GET_MEMBER_NOTIFICATIONS,
  UNREAD_ALL_MEMBER_NOTIFICATIONS_BEGIN,
  UNREAD_ALL_MEMBER_NOTIFICATIONS_SUCCESS,
  UNREAD_ALL_MEMBER_NOTIFICATIONS_FAILURE,
  RESET_UNREAD_ALL_MEMBER_NOTIFICATIONS,
  GET_MY_MENTOR_DETAILS_BEGIN,
  GET_MY_MENTOR_DETAILS_SUCCESS,
  GET_MY_MENTOR_DETAILS_FAILURE,
  RESET_GET_MY_MENTOR_DETAILS,
  GET_PURCHASE_MINUTES_PLAN_BEGIN,
  GET_PURCHASE_MINUTES_PLAN_SUCCESS,
  GET_PURCHASE_MINUTES_PLAN_FAILURE,
  RESET_GET_PURCHASE_MINUTES_PLAN,
  GET_MEMBER_TASK_BEGIN,
  GET_MEMBER_TASK_SUCCESS,
  GET_MEMBER_TASK_FAILURE,
  RESET_GET_MEMBER_TASK,
  VIEW_MEMBER_TASK_RESPONSE_BEGIN,
  VIEW_MEMBER_TASK_RESPONSE_SUCCESS,
  VIEW_MEMBER_TASK_RESPONSE_FAILURE,
  RESET_VIEW_MEMBER_TASK_RESPONSE,
  SUBMIT_MEMBER_TASK_RESPONSE_BEGIN,
  SUBMIT_MEMBER_TASK_RESPONSE_SUCCESS,
  SUBMIT_MEMBER_TASK_RESPONSE_FAILURE,
  RESET_SUBMIT_MEMBER_TASK_RESPONSE,
  GET_MEMBER_JOURNAL_BEGIN,
  GET_MEMBER_JOURNAL_SUCCESS,
  GET_MEMBER_JOURNAL_FAILURE,
  RESET_GET_MEMBER_JOURNAL,
  SUBMIT_MEMBER_JOURNAL_BEGIN,
  SUBMIT_MEMBER_JOURNAL_SUCCESS,
  RESET_SUBMIT_MEMBER_JOURNAL,
  SUBMIT_MEMBER_JOURNAL_FAILURE,
  VIEW_SINGLE_MEMBER_JOURNAL_BEGIN,
  VIEW_SINGLE_MEMBER_JOURNAL_SUCCESS,
  VIEW_SINGLE_MEMBER_JOURNAL_FAILURE,
  RESET_VIEW_SINGLE_MEMBER_JOURNAL,
  SUBMIT_MENTOR_RATING_BEGIN,
  SUBMIT_MENTOR_RATING_SUCCESS,
  SUBMIT_MENTOR_RATING_FAILURE,
  RESET_SUBMIT_MENTOR_RATING,
  SUBMIT_SINGLE_QUESTION_ANSWER_BEGIN,
  SUBMIT_SINGLE_QUESTION_ANSWER_SUCCESS,
  SUBMIT_SINGLE_QUESTION_ANSWER_FAILURE,
  RESET_SUBMIT_SINGLE_QUESTION_ANSWER,
  SUBMIT_BULK_ANSWERS_BEGIN,
  SUBMIT_BULK_ANSWERS_SUCCESS,
  SUBMIT_BULK_ANSWERS_FAILURE,
  RESET_SUBMIT_BULK_ANSWERS,
  EDIT_SINGLE_ANSWERS_BEGIN,
  EDIT_SINGLE_ANSWERS_SUCCESS,
  EDIT_SINGLE_ANSWERS_FAILURE,
  RESET_EDIT_SINGLE_ANSWERS,
  SUBMIT_SINGLE_TIF_ANSWER_BEGIN,
  SUBMIT_SINGLE_TIF_ANSWER_SUCCESS,
  SUBMIT_SINGLE_TIF_ANSWER_FAILURE,
  RESET_SUBMIT_SINGLE_TIF_ANSWER,
  EDIT_SINGLE_TIF_BEGIN,
  EDIT_SINGLE_TIF_SUCCESS,
  EDIT_SINGLE_TIF_FAILURE,
  RESET_EDIT_SINGLE_TIF,
  SUBMIT_BULK_TIF_BEGIN,
  SUBMIT_BULK_TIF_SUCCESS,
  SUBMIT_BULK_TIF_FAILURE,
  RESET_SUBMIT_BULK_TIF,
} from "../constants/MemberDashboard";

const initialState = {
  getMemberProfileBegin: false,
  getMemberProfileSuccess: false,
  getMemberProfileSuccessData: null,
  getMemberProfileFailure: false,
  getMemberProfileFailureData: null,

  getAssessmentQuestionsBegin: false,
  getAssessmentQuestionsSuccess: false,
  getAssessmentQuestionsSuccessData: [],
  getAssessmentQuestionsFailure: false,
  getAssessmentQuestionsFailureData: null,

  submitSingleAssessmentBegin: false,
  submitSingleAssessmentSuccess: false,
  submitSingleAssessmentSuccessData: [],
  submitSingleAssessmentFailure: false,
  submitSingleAssessmentFailureData: null,

  viewSubmittedAssessmentOptionsBegin: false,
  viewSubmittedAssessmentOptionsSuccess: false,
  viewSubmittedAssessmentOptionsSuccessData: null,
  viewSubmittedAssessmentOptionsFailure: false,
  viewSubmittedAssessmentOptionsFailureData: null,

  purchaseMentorMinutesBegin: false,
  purchaseMentorMinutesSuccess: false,
  purchaseMentorMinutesSuccessData: null,
  purchaseMentorMinutesFailure: false,
  purchaseMentorMinutesFailureData: null,

  getMemberCurriculumBegin: false,
  getMemberCurriculumSuccess: false,
  getMemberCurriculumSuccessData: null,
  getMemberCurriculumFailure: false,
  getMemberCurriculumFailureData: null,

  getMemberJournalBegin: false,
  getMemberJournalSuccess: false,
  getMemberJournalSuccessData: null,
  getMemberJournalFailure: false,
  getMemberJournalFailureData: null,

  submitMemberJournalBegin: false,
  submitMemberJournalSuccess: false,
  submitMemberJournalSuccessData: null,
  submitMemberJournalFailure: false,
  submitMemberJournalFailureData: null,

  viewSingleMemberJournalBegin: false,
  viewSingleMemberJournalSuccess: false,
  viewSingleMemberJournalSuccessData: null,
  viewSingleMemberJournalFailure: false,
  viewSingleMemberJournalFailureData: null,

  viewMemberCurriculumResponseBegin: false,
  viewMemberCurriculumResponseSuccess: false,
  viewMemberCurriculumResponseSuccessData: null,
  viewMemberCurriculumResponseFailure: false,
  viewMemberCurriculumResponseFailureData: null,

  submitMemberCurriculumResponseBegin: false,
  submitMemberCurriculumResponseSuccess: false,
  submitMemberCurriculumResponseSuccessData: null,
  submitMemberCurriculumResponseFailure: false,
  submitMemberCurriculumResponseFailureData: null,

  getMemberDashboardDetailsBegin: false,
  getMemberDashboardDetailsSuccess: false,
  getMemberDashboardDetailsSuccessData: null,
  getMemberDashboardDetailsFailure: false,
  getMemberDashboardDetailsFailureData: null,

  assignMentorToMemberBegin: false,
  assignMentorToMemberSuccess: false,
  assignMentorToMemberSuccessData: null,
  assignMentorToMemberFailure: false,
  assignMentorToMemberFailureData: null,

  getMemberNotificationsBegin: false,
  getMemberNotificationsSuccess: false,
  getMemberNotificationsSuccessData: null,
  getMemberNotificationsFailure: false,
  getMemberNotificationsFailureData: null,

  unreadAllMemberNotificationBegin: false,
  unreadAllMemberNotificationSuccess: false,
  unreadAllMemberNotificationSuccessData: null,
  unreadAllMemberNotificationFailure: false,
  unreadAllMemberNotificationFailureData: null,

  getMyMentorDetailsBegin: false,
  getMyMentorDetailsSuccess: false,
  getMyMentorDetailsSuccessData: null,
  getMyMentorDetailsFailure: false,
  getMyMentorDetailsFailureData: null,

  getPurchaseMinutesPlanBegin: false,
  getPurchaseMinutesPlanSuccess: false,
  getPurchaseMinutesPlanSuccessData: null,
  getPurchaseMinutesPlanFailure: false,
  getPurchaseMinutesPlanFailureData: null,

  getMemberTaskBegin: false,
  getMemberTaskSuccess: false,
  getMemberTaskSuccessData: null,
  getMemberTaskFailure: false,
  getMemberTaskFailureData: null,

  viewMemberTaskResponseBegin: false,
  viewMemberTaskResponseSuccess: false,
  viewMemberTaskResponseSuccessData: null,
  viewMemberTaskResponseFailure: false,
  viewMemberTaskResponseFailureData: null,

  submitMemberTaskResponseBegin: false,
  submitMemberTaskResponseSuccess: false,
  submitMemberTaskResponseSuccessData: null,
  submitMemberTaskResponseFailure: false,
  submitMemberTaskResponseFailureData: null,

  submitMentoratingBegin: false,
  submitMentoratingSuccess: false,
  submitMentoratingSuccessData: null,
  submitMentoratingFailure: false,
  submitMentoratingFailureData: null,

  submitSingleQuestionAnswerBegin: false,
  submitSingleQuestionAnswerSuccess: false,
  submitSingleQuestionAnswerSuccessData: null,
  submitSingleQuestionAnswerFailure: false,
  submitSingleQuestionAnswerFailureData: null,

  submitBulkAnswersBegin: false,
  submitBulkAnswersSuccess: false,
  submitBulkAnswersSuccessData: null,
  submitBulkAnswersFailure: false,
  submitBulkAnswersFailureData: null,

  editSingleAnswerBegin: false,
  editSingleAnswerSuccess: false,
  editSingleAnswerSuccessData: null,
  editSingleAnswerFailure: false,
  editSingleAnswerFailureData: null,

  submitSingleTifAnswerBegin: false,
  submitSingleTifAnswerSuccess: false,
  submitSingleTifAnswerSuccessData: null,
  submitSingleTifAnswerFailure: false,
  submitSingleTifAnswerFailureData: null,

  editSingleTifBegin: false,
  editSingleTifSuccess: false,
  editSingleTifSuccessData: null,
  editSingleTifFailure: false,
  editSingleTifFailureData: null,

  submitBulkTifBegin: false,
  submitBulkTifSuccess: false,
  submitBulkTifSuccessData: null,
  submitBulkTifFailure: false,
  submitBulkTifFailureData: null,
};

export const memberDashboard = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // Get member profile
    case GET_MEMBER_PROFILE_BEGIN:
      return {
        ...state,
        getMemberProfileBegin: true,
        getMemberProfileSuccess: false,
        getMemberProfileSuccessData: null,
        getMemberProfileFailure: false,
        getMemberProfileFailureData: null,
      };
    case GET_MEMBER_PROFILE_SUCCESS:
      return {
        ...state,
        getMemberProfileBegin: false,
        getMemberProfileSuccess: true,
        getMemberProfileSuccessData: data,
        getMemberProfileFailure: false,
        getMemberProfileFailureData: null,
      };
    case GET_MEMBER_PROFILE_FAILURE:
      return {
        ...state,
        getMemberProfileBegin: false,
        getMemberProfileSuccess: false,
        getMemberProfileSuccessData: null,
        getMemberProfileFailure: true,
        getMemberProfileFailureData: data,
      };
    case RESET_GET_MEMBER_PROFILE:
      return {
        ...state,
        getMemberProfileBegin: false,
        getMemberProfileSuccess: false,
        getMemberProfileSuccessData: null,
        getMemberProfileFailure: false,
        getMemberProfileFailureData: null,
      };

    // Get assessment questions
    case GET_ASSESSMENT_QUESTIONS_BEGIN:
      return {
        ...state,
        getAssessmentQuestionsBegin: true,
        getAssessmentQuestionsSuccess: false,
        getAssessmentQuestionsSuccessData: [],
        getAssessmentQuestionsFailure: false,
        getAssessmentQuestionsFailureData: null,
      };
    case GET_ASSESSMENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        getAssessmentQuestionsBegin: false,
        getAssessmentQuestionsSuccess: true,
        getAssessmentQuestionsSuccessData: data,
        getAssessmentQuestionsFailure: false,
        getAssessmentQuestionsFailureData: null,
      };
    case GET_ASSESSMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        getAssessmentQuestionsBegin: false,
        getAssessmentQuestionsSuccess: false,
        getAssessmentQuestionsSuccessData: [],
        getAssessmentQuestionsFailure: true,
        getAssessmentQuestionsFailureData: data,
      };
    case RESET_GET_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        getAssessmentQuestionsBegin: false,
        getAssessmentQuestionsSuccess: false,
        getAssessmentQuestionsFailure: false,
      };

    // submit assessment
    case SUBMIT_SINGLE_ASSESSMENT_BEGIN:
      return {
        ...state,
        submitSingleAssessmentBegin: true,
        submitSingleAssessmentSuccess: false,
        submitSingleAssessmentSuccessData: [],
        submitSingleAssessmentFailure: false,
        submitSingleAssessmentFailureData: null,
      };
    case SUBMIT_SINGLE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        submitSingleAssessmentBegin: false,
        submitSingleAssessmentSuccess: true,
        submitSingleAssessmentSuccessData: data,
        submitSingleAssessmentFailure: false,
        submitSingleAssessmentFailureData: null,
      };
    case SUBMIT_SINGLE_ASSESSMENT_FAILURE:
      return {
        ...state,
        submitSingleAssessmentBegin: false,
        submitSingleAssessmentSuccess: false,
        submitSingleAssessmentSuccessData: [],
        submitSingleAssessmentFailure: true,
        submitSingleAssessmentFailureData: data,
      };
    case RESET_SUBMIT_SINGLE_ASSESSMENT:
      return {
        ...state,
        submitSingleAssessmentBegin: false,
        submitSingleAssessmentSuccess: false,
        submitSingleAssessmentSuccessData: [],
        submitSingleAssessmentFailure: false,
        submitSingleAssessmentFailureData: null,
      };

    // view submitted assessment options
    case VIEW_SUBMITTED_ASSESSMENT_OPTIONS_BEGIN:
      return {
        ...state,
        viewSubmittedAssessmentOptionsBegin: true,
        viewSubmittedAssessmentOptionsSuccess: false,
        viewSubmittedAssessmentOptionsSuccessData: null,
        viewSubmittedAssessmentOptionsFailure: false,
        viewSubmittedAssessmentOptionsFailureData: null,
      };
    case VIEW_SUBMITTED_ASSESSMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        viewSubmittedAssessmentOptionsBegin: false,
        viewSubmittedAssessmentOptionsSuccess: true,
        viewSubmittedAssessmentOptionsSuccessData: data,
        viewSubmittedAssessmentOptionsFailure: false,
        viewSubmittedAssessmentOptionsFailureData: null,
      };
    case VIEW_SUBMITTED_ASSESSMENT_OPTIONS_FAILURE:
      return {
        ...state,
        viewSubmittedAssessmentOptionsBegin: false,
        viewSubmittedAssessmentOptionsSuccess: false,
        viewSubmittedAssessmentOptionsSuccessData: null,
        viewSubmittedAssessmentOptionsFailure: true,
        viewSubmittedAssessmentOptionsFailureData: data,
      };
    case RESET_VIEW_SUBMITTED_ASSESSMENT_OPTIONS:
      return {
        ...state,
        viewSubmittedAssessmentOptionsBegin: false,
        viewSubmittedAssessmentOptionsSuccess: false,
        viewSubmittedAssessmentOptionsSuccessData: null,
        viewSubmittedAssessmentOptionsFailure: false,
        viewSubmittedAssessmentOptionsFailureData: null,
      };

    //member purchase minutes
    case PURCHASE_PAYMENT_MINUTES_BEGIN:
      return {
        ...state,
        purchaseMentorMinutesBegin: true,
        purchaseMentorMinutesSuccess: false,
        purchaseMentorMinutesSuccessData: null,
        purchaseMentorMinutesFailure: false,
        purchaseMentorMinutesFailureData: null,
      };
    case PURCHASE_PAYMENT_MINUTES_SUCCESS:
      return {
        ...state,
        purchaseMentorMinutesBegin: false,
        purchaseMentorMinutesSuccess: true,
        purchaseMentorMinutesSuccessData: data,
        purchaseMentorMinutesFailure: false,
        purchaseMentorMinutesFailureData: null,
      };
    case PURCHASE_PAYMENT_MINUTES_FAILURE:
      return {
        ...state,
        purchaseMentorMinutesBegin: false,
        purchaseMentorMinutesSuccess: false,
        purchaseMentorMinutesSuccessData: null,
        purchaseMentorMinutesFailure: true,
        purchaseMentorMinutesFailureData: data,
      };
    case RESET_PURCHASE_PAYMENT_MINUTES:
      return {
        ...state,
        purchaseMentorMinutesBegin: false,
        purchaseMentorMinutesSuccess: false,
        purchaseMentorMinutesSuccessData: null,
        purchaseMentorMinutesFailure: false,
        purchaseMentorMinutesFailureData: null,
      };

    // Get member CURRICULUM
    case GET_MEMBER_CURRICULUM_BEGIN:
      return {
        ...state,
        getMemberCurriculumBegin: true,
        getMemberCurriculumSuccess: false,
        getMemberCurriculumSuccessData: null,
        getMemberCurriculumFailure: false,
        getMemberCurriculumFailureData: null,
      };
    case GET_MEMBER_CURRICULUM_SUCCESS:
      return {
        ...state,
        getMemberCurriculumBegin: false,
        getMemberCurriculumSuccess: true,
        getMemberCurriculumSuccessData: data,
        getMemberCurriculumFailure: false,
        getMemberCurriculumFailureData: null,
      };
    case GET_MEMBER_CURRICULUM_FAILURE:
      return {
        ...state,
        getMemberCurriculumBegin: false,
        getMemberCurriculumSuccess: false,
        getMemberCurriculumSuccessData: null,
        getMemberCurriculumFailure: true,
        getMemberCurriculumFailureData: data,
      };
    case RESET_GET_MEMBER_CURRICULUM:
      return {
        ...state,
        getMemberCurriculumBegin: false,
        getMemberCurriculumSuccess: false,
        getMemberCurriculumSuccessData: null,
        getMemberCurriculumFailure: false,
        getMemberCurriculumFailureData: null,
      };

    // Get member journal
    case GET_MEMBER_JOURNAL_BEGIN:
      return {
        ...state,
        getMemberJournalBegin: true,
        getMemberJournalSuccess: false,
        getMemberJournalSuccessData: null,
        getMemberJournalFailure: false,
        getMemberJournalFailureData: null,
      };
    case GET_MEMBER_JOURNAL_SUCCESS:
      return {
        ...state,
        getMemberJournalBegin: false,
        getMemberJournalSuccess: true,
        getMemberJournalSuccessData: data,
        getMemberJournalFailure: false,
        getMemberJournalFailureData: null,
      };
    case GET_MEMBER_JOURNAL_FAILURE:
      return {
        ...state,
        getMemberJournalBegin: false,
        getMemberJournalSuccess: false,
        getMemberJournalSuccessData: null,
        getMemberJournalFailure: true,
        getMemberJournalFailureData: data,
      };
    case RESET_GET_MEMBER_JOURNAL:
      return {
        ...state,
        getMemberJournalBegin: false,
        getMemberJournalSuccess: false,
        getMemberJournalSuccessData: null,
        getMemberJournalFailure: false,
        getMemberJournalFailureData: null,
      };

    // Submit member journal
    case SUBMIT_MEMBER_JOURNAL_BEGIN:
      return {
        ...state,
        submitMemberJournalBegin: true,
        submitMemberJournalSuccess: false,
        submitMemberJournalSuccessData: null,
        submitMemberJournalFailure: false,
        submitMemberJournalFailureData: null,
      };
    case SUBMIT_MEMBER_JOURNAL_SUCCESS:
      return {
        ...state,
        submitMemberJournalBegin: false,
        submitMemberJournalSuccess: true,
        submitMemberJournalSuccessData: data,
        submitMemberJournalFailure: false,
        submitMemberJournalFailureData: null,
      };
    case SUBMIT_MEMBER_JOURNAL_FAILURE:
      return {
        ...state,
        submitMemberJournalBegin: false,
        submitMemberJournalSuccess: false,
        submitMemberJournalSuccessData: null,
        submitMemberJournalFailure: true,
        submitMemberJournalFailureData: data,
      };
    case RESET_SUBMIT_MEMBER_JOURNAL:
      return {
        ...state,
        submitMemberJournalBegin: false,
        submitMemberJournalSuccess: false,
        submitMemberJournalSuccessData: null,
        submitMemberJournalFailure: false,
        submitMemberJournalFailureData: null,
      };

    // view member journal
    case VIEW_SINGLE_MEMBER_JOURNAL_BEGIN:
      return {
        ...state,
        viewSingleMemberJournalBegin: true,
        viewSingleMemberJournalSuccess: false,
        viewSingleMemberJournalSuccessData: null,
        viewSingleMemberJournalFailure: false,
        viewSingleMemberJournalFailureData: null,
      };
    case VIEW_SINGLE_MEMBER_JOURNAL_SUCCESS:
      return {
        ...state,
        viewSingleMemberJournalBegin: false,
        viewSingleMemberJournalSuccess: true,
        viewSingleMemberJournalSuccessData: data,
        viewSingleMemberJournalFailure: false,
        viewSingleMemberJournalFailureData: null,
      };
    case VIEW_SINGLE_MEMBER_JOURNAL_FAILURE:
      return {
        ...state,
        viewSingleMemberJournalBegin: false,
        viewSingleMemberJournalSuccess: false,
        viewSingleMemberJournalSuccessData: null,
        viewSingleMemberJournalFailure: true,
        viewSingleMemberJournalFailureData: data,
      };
    case RESET_VIEW_SINGLE_MEMBER_JOURNAL:
      return {
        ...state,
        viewSingleMemberJournalBegin: false,
        viewSingleMemberJournalSuccess: false,
        viewSingleMemberJournalSuccessData: null,
        viewSingleMemberJournalFailure: false,
        viewSingleMemberJournalFailureData: null,
      };

    // View member curriculum response
    case VIEW_MEMBER_CURRICULUM_RESPONSE_BEGIN:
      return {
        ...state,
        viewMemberCurriculumResponseBegin: true,
        viewMemberCurriculumResponseSuccess: false,
        viewMemberCurriculumResponseSuccessData: null,
        viewMemberCurriculumResponseFailure: false,
        viewMemberCurriculumResponseFailureData: null,
      };
    case VIEW_MEMBER_CURRICULUM_RESPONSE_SUCCESS:
      return {
        ...state,
        viewMemberCurriculumResponseBegin: false,
        viewMemberCurriculumResponseSuccess: true,
        viewMemberCurriculumResponseSuccessData: data,
        viewMemberCurriculumResponseFailure: false,
        viewMemberCurriculumResponseFailureData: null,
      };
    case VIEW_MEMBER_CURRICULUM_RESPONSE_FAILURE:
      return {
        ...state,
        viewMemberCurriculumResponseBegin: false,
        viewMemberCurriculumResponseSuccess: false,
        viewMemberCurriculumResponseSuccessData: null,
        viewMemberCurriculumResponseFailure: true,
        viewMemberCurriculumResponseFailureData: data,
      };
    case RESET_VIEW_MEMBER_CURRICULUM_RESPONSE:
      return {
        ...state,
        viewMemberCurriculumResponseBegin: false,
        viewMemberCurriculumResponseSuccess: false,
        viewMemberCurriculumResponseFailure: false,
      };

    // submit member curriculum response
    case SUBMIT_MEMBER_CURRICULUM_RESPONSE_BEGIN:
      return {
        ...state,
        submitMemberCurriculumResponseBegin: true,
        submitMemberCurriculumResponseSuccess: false,
        submitMemberCurriculumResponseSuccessData: null,
        submitMemberCurriculumResponseFailure: false,
        submitMemberCurriculumResponseFailureData: null,
      };
    case SUBMIT_MEMBER_CURRICULUM_RESPONSE_SUCCESS:
      return {
        ...state,
        submitMemberCurriculumResponseBegin: false,
        submitMemberCurriculumResponseSuccess: true,
        submitMemberCurriculumResponseSuccessData: data,
        submitMemberCurriculumResponseFailure: false,
        submitMemberCurriculumResponseFailureData: null,
      };
    case SUBMIT_MEMBER_CURRICULUM_RESPONSE_FAILURE:
      return {
        ...state,
        submitMemberCurriculumResponseBegin: false,
        submitMemberCurriculumResponseSuccess: false,
        submitMemberCurriculumResponseSuccessData: null,
        submitMemberCurriculumResponseFailure: true,
        submitMemberCurriculumResponseFailureData: data,
      };
    case RESET_SUBMIT_MEMBER_CURRICULUM_RESPONSE:
      return {
        ...state,
        submitMemberCurriculumResponseBegin: false,
        submitMemberCurriculumResponseSuccess: false,
        submitMemberCurriculumResponseSuccessData: null,
        submitMemberCurriculumResponseFailure: false,
        submitMemberCurriculumResponseFailureData: null,
      };

    //Get member dashboard details
    case GET_MEMBER_DASHBOARD_DETAILS_BEGIN:
      return {
        ...state,
        getMemberDashboardDetailsBegin: true,
        getMemberDashboardDetailsSuccess: false,
        getMemberDashboardDetailsSuccessData: null,
        getMemberDashboardDetailsFailure: false,
        getMemberDashboardDetailsFailureData: null,
      };
    case GET_MEMBER_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        getMemberDashboardDetailsBegin: false,
        getMemberDashboardDetailsSuccess: true,
        getMemberDashboardDetailsSuccessData: data,
        getMemberDashboardDetailsFailure: false,
        getMemberDashboardDetailsFailureData: null,
      };
    case GET_MEMBER_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state,
        getMemberDashboardDetailsBegin: false,
        getMemberDashboardDetailsSuccess: false,
        getMemberDashboardDetailsSuccessData: null,
        getMemberDashboardDetailsFailure: true,
        getMemberDashboardDetailsFailureData: data,
      };
    case RESET_GET_MEMBER_DASHBOARD_DETAILS:
      return {
        ...state,
        getMemberDashboardDetailsBegin: false,
        getMemberDashboardDetailsSuccess: false,
        getMemberDashboardDetailsSuccessData: null,
        getMemberDashboardDetailsFailure: false,
        getMemberDashboardDetailsFailureData: null,
      };

    //assign a mentor to member
    case ASSIGN_MENTOR_TO_MEMBER_BEGIN:
      return {
        ...state,
        assignMentorToMemberBegin: true,
        assignMentorToMemberSuccess: false,
        assignMentorToMemberSuccessData: null,
        assignMentorToMemberFailure: false,
        assignMentorToMemberFailureData: null,
      };
    case ASSIGN_MENTOR_TO_MEMBER_SUCCESS:
      return {
        ...state,
        assignMentorToMemberBegin: false,
        assignMentorToMemberSuccess: true,
        assignMentorToMemberSuccessData: data,
        assignMentorToMemberFailure: false,
        assignMentorToMemberFailureData: null,
      };
    case ASSIGN_MENTOR_TO_MEMBER_FAILURE:
      return {
        ...state,
        assignMentorToMemberBegin: false,
        assignMentorToMemberSuccess: false,
        assignMentorToMemberSuccessData: null,
        assignMentorToMemberFailure: true,
        assignMentorToMemberFailureData: data,
      };
    case RESET_ASSIGN_MENTOR_TO_MEMBER:
      return {
        ...state,
        assignMentorToMemberBegin: false,
        assignMentorToMemberSuccess: false,
        // assignMentorToMemberSuccessData: null,
        assignMentorToMemberFailure: false,
        assignMentorToMemberFailureData: null,
      };

    //get member notifications
    case GET_MEMBER_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        getMemberNotificationsBegin: true,
        getMemberNotificationsSuccess: false,
        getMemberNotificationsSuccessData: null,
        getMemberNotificationsFailure: false,
        getMemberNotificationsFailureData: null,
      };

    case GET_MEMBER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getMemberNotificationsBegin: false,
        getMemberNotificationsSuccess: true,
        getMemberNotificationsSuccessData: data,
        getMemberNotificationsFailure: false,
        getMemberNotificationsFailureData: null,
      };

    case GET_MEMBER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        getMemberNotificationsBegin: false,
        getMemberNotificationsSuccess: false,
        getMemberNotificationsSuccessData: null,
        getMemberNotificationsFailure: true,
        getMemberNotificationsFailureData: data,
      };

    case RESET_GET_MEMBER_NOTIFICATIONS:
      return {
        ...state,
        getMemberNotificationsBegin: false,
        getMemberNotificationsSuccess: false,
        getMemberNotificationsSuccessData: null,
        getMemberNotificationsFailure: false,
        getMemberNotificationsFailureData: null,
      };

    case UNREAD_ALL_MEMBER_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        unreadAllMemberNotificationBegin: true,
        unreadAllMemberNotificationSuccess: false,
        unreadAllMemberNotificationSuccessData: null,
        unreadAllMemberNotificationFailure: false,
        unreadAllMemberNotificationFailureData: null,
      };
    case UNREAD_ALL_MEMBER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unreadAllMemberNotificationBegin: false,
        unreadAllMemberNotificationSuccess: true,
        unreadAllMemberNotificationSuccessData: data,
        unreadAllMemberNotificationFailure: false,
        unreadAllMemberNotificationFailureData: null,
      };
    case UNREAD_ALL_MEMBER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        unreadAllMemberNotificationBegin: false,
        unreadAllMemberNotificationSuccess: false,
        unreadAllMemberNotificationSuccessData: null,
        unreadAllMemberNotificationFailure: true,
        unreadAllMemberNotificationFailureData: data,
      };
    case RESET_UNREAD_ALL_MEMBER_NOTIFICATIONS:
      return {
        ...state,
        unreadAllMemberNotificationBegin: false,
        unreadAllMemberNotificationSuccess: false,
        unreadAllMemberNotificationSuccessData: null,
        unreadAllMemberNotificationFailure: false,
        unreadAllMemberNotificationFailureData: null,
      };

    // Get my mentor details
    case GET_MY_MENTOR_DETAILS_BEGIN:
      return {
        ...state,
        getMyMentorDetailsBegin: true,
        getMyMentorDetailsSuccess: false,
        getMyMentorDetailsSuccessData: null,
        getMyMentorDetailsFailure: false,
        getMyMentorDetailsFailureData: null,
      };

    case GET_MY_MENTOR_DETAILS_SUCCESS:
      return {
        ...state,
        getMyMentorDetailsBegin: false,
        getMyMentorDetailsSuccess: true,
        getMyMentorDetailsSuccessData: data,
        getMyMentorDetailsFailure: false,
        getMyMentorDetailsFailureData: null,
      };

    case GET_MY_MENTOR_DETAILS_FAILURE:
      return {
        ...state,
        getMyMentorDetailsBegin: false,
        getMyMentorDetailsSuccess: false,
        getMyMentorDetailsSuccessData: null,
        getMyMentorDetailsFailure: true,
        getMyMentorDetailsFailureData: data,
      };

    case RESET_GET_MY_MENTOR_DETAILS:
      return {
        ...state,
        getMyMentorDetailsBegin: false,
        getMyMentorDetailsSuccess: false,
        // getMyMentorDetailsSuccessData: null,
        getMyMentorDetailsFailure: false,
        getMyMentorDetailsFailureData: null,
      };

    //get purchase payment minutes

    case GET_PURCHASE_MINUTES_PLAN_BEGIN:
      return {
        ...state,
        getPurchaseMinutesPlanBegin: false,
        getPurchaseMinutesPlanSuccess: false,
        getPurchaseMinutesPlanSuccessData: null,
        getPurchaseMinutesPlanFailure: false,
        getPurchaseMinutesPlanFailureData: null,
      };

    case GET_PURCHASE_MINUTES_PLAN_SUCCESS:
      return {
        ...state,
        getPurchaseMinutesPlanBegin: false,
        getPurchaseMinutesPlanSuccess: true,
        getPurchaseMinutesPlanSuccessData: data,
        getPurchaseMinutesPlanFailure: false,
        getPurchaseMinutesPlanFailureData: null,
      };

    case GET_PURCHASE_MINUTES_PLAN_FAILURE:
      return {
        ...state,
        getPurchaseMinutesPlanBegin: false,
        getPurchaseMinutesPlanSuccess: false,
        getPurchaseMinutesPlanSuccessData: null,
        getPurchaseMinutesPlanFailure: true,
        getPurchaseMinutesPlanFailureData: data,
      };

    case RESET_GET_PURCHASE_MINUTES_PLAN:
      return {
        ...state,
        getPurchaseMinutesPlanBegin: false,
        getPurchaseMinutesPlanSuccess: false,
        getPurchaseMinutesPlanSuccessData: null,
        getPurchaseMinutesPlanFailure: false,
        getPurchaseMinutesPlanFailureData: null,
      };

    // Get member task assigned by mentor
    case GET_MEMBER_TASK_BEGIN:
      return {
        ...state,
        getMemberTaskBegin: true,
        getMemberTaskSuccess: false,
        getMemberTaskSuccessData: null,
        getMemberTaskFailure: false,
        getMemberTaskFailureData: null,
      };

    case GET_MEMBER_TASK_SUCCESS:
      return {
        ...state,
        getMemberTaskBegin: false,
        getMemberTaskSuccess: true,
        getMemberTaskSuccessData: data,
        getMemberTaskFailure: false,
        getMemberTaskFailureData: null,
      };
    case GET_MEMBER_TASK_FAILURE:
      return {
        ...state,
        getMemberTaskBegin: false,
        getMemberTaskSuccess: false,
        getMemberTaskSuccessData: null,
        getMemberTaskFailure: true,
        getMemberTaskFailureData: data,
      };
    case RESET_GET_MEMBER_TASK:
      return {
        ...state,
        getMemberTaskBegin: false,
        getMemberTaskSuccess: false,
        getMemberTaskSuccessData: null,
        getMemberTaskFailure: false,
        getMemberTaskFailureData: null,
      };

    // View member task response
    case VIEW_MEMBER_TASK_RESPONSE_BEGIN:
      return {
        ...state,
        viewMemberTaskResponseBegin: true,
        viewMemberTaskResponseSuccess: false,
        viewMemberTaskResponseSuccessData: null,
        viewMemberTaskResponseFailure: false,
        viewMemberTaskResponseFailureData: null,
      };
    case VIEW_MEMBER_TASK_RESPONSE_SUCCESS:
      return {
        ...state,
        viewMemberTaskResponseBegin: false,
        viewMemberTaskResponseSuccess: true,
        viewMemberTaskResponseSuccessData: data,
        viewMemberTaskResponseFailure: false,
        viewMemberTaskResponseFailureData: null,
      };
    case VIEW_MEMBER_TASK_RESPONSE_FAILURE:
      return {
        ...state,
        viewMemberTaskResponseBegin: false,
        viewMemberTaskResponseSuccess: false,
        viewMemberTaskResponseSuccessData: null,
        viewMemberTaskResponseFailure: true,
        viewMemberTaskResponseFailureData: data,
      };
    case RESET_VIEW_MEMBER_TASK_RESPONSE:
      return {
        ...state,
        viewMemberTaskResponseBegin: false,
        viewMemberTaskResponseSuccess: false,
        viewMemberTaskResponseSuccessData: null,
        viewMemberTaskResponseFailure: false,
        viewMemberTaskResponseFailureData: null,
      };

    // submit member task response
    case SUBMIT_MEMBER_TASK_RESPONSE_BEGIN:
      return {
        ...state,
        submitMemberTaskResponseBegin: true,
        submitMemberTaskResponseSuccess: false,
        submitMemberTaskResponseSuccessData: null,
        submitMemberTaskResponseFailure: false,
        submitMemberTaskResponseFailureData: null,
      };
    case SUBMIT_MEMBER_TASK_RESPONSE_SUCCESS:
      return {
        ...state,
        submitMemberTaskResponseBegin: false,
        submitMemberTaskResponseSuccess: true,
        submitMemberTaskResponseSuccessData: data,
        submitMemberTaskResponseFailure: false,
        submitMemberTaskResponseFailureData: null,
      };
    case SUBMIT_MEMBER_TASK_RESPONSE_FAILURE:
      return {
        ...state,
        submitMemberTaskResponseBegin: false,
        submitMemberTaskResponseSuccess: false,
        submitMemberTaskResponseSuccessData: null,
        submitMemberTaskResponseFailure: true,
        submitMemberTaskResponseFailureData: data,
      };
    case RESET_SUBMIT_MEMBER_TASK_RESPONSE:
      return {
        ...state,
        submitMemberTaskResponseBegin: false,
        submitMemberTaskResponseSuccess: false,
        submitMemberTaskResponseSuccessData: null,
        submitMemberTaskResponseFailure: false,
        submitMemberTaskResponseFailureData: null,
      };

    // submit mentor rating
    case SUBMIT_MENTOR_RATING_BEGIN:
      return {
        ...state,
        submitMentoratingBegin: true,
        submitMentoratingSuccess: false,
        submitMentoratingSuccessData: null,
        submitMentoratingFailure: false,
        submitMentoratingFailureData: null,
      };
    case SUBMIT_MENTOR_RATING_SUCCESS:
      return {
        ...state,
        submitMentoratingBegin: false,
        submitMentoratingSuccess: true,
        submitMentoratingSuccessData: data,
        submitMentoratingFailure: false,
        submitMentoratingFailureData: null,
      };
    case SUBMIT_MENTOR_RATING_FAILURE:
      return {
        ...state,
        submitMentoratingBegin: false,
        submitMentoratingSuccess: false,
        submitMentoratingSuccessData: null,
        submitMentoratingFailure: true,
        submitMentoratingFailureData: data,
      };
    case RESET_SUBMIT_MENTOR_RATING:
      return {
        ...state,
        submitMentoratingBegin: false,
        submitMentoratingSuccess: false,
        submitMentoratingSuccessData: null,
        submitMentoratingFailure: false,
        submitMentoratingFailureData: null,
      };

    //submit individual curriculum questions

    case SUBMIT_SINGLE_QUESTION_ANSWER_BEGIN:
      return {
        ...state,
        submitSingleQuestionAnswerBegin: true,
        submitSingleQuestionAnswerSuccess: false,
        submitSingleQuestionAnswerSuccessData: null,
        submitSingleQuestionAnswerFailure: false,
        submitSingleQuestionAnswerFailureData: null,
      };

    case SUBMIT_SINGLE_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        submitSingleQuestionAnswerBegin: false,
        submitSingleQuestionAnswerSuccess: true,
        submitSingleQuestionAnswerSuccessData: data,
        submitSingleQuestionAnswerFailure: false,
        submitSingleQuestionAnswerFailureData: null,
      };

    case SUBMIT_SINGLE_QUESTION_ANSWER_FAILURE:
      return {
        ...state,
        submitSingleQuestionAnswerBegin: false,
        submitSingleQuestionAnswerSuccess: false,
        submitSingleQuestionAnswerSuccessData: null,
        submitSingleQuestionAnswerFailure: true,
        submitSingleQuestionAnswerFailureData: data,
      };

    case RESET_SUBMIT_SINGLE_QUESTION_ANSWER:
      return {
        ...state,
        submitSingleQuestionAnswerBegin: false,
        submitSingleQuestionAnswerSuccess: false,
        submitSingleQuestionAnswerSuccessData: null,
        submitSingleQuestionAnswerFailure: false,
        submitSingleQuestionAnswerFailureData: null,
      };

    //submit answers bulkly

    case SUBMIT_BULK_ANSWERS_BEGIN:
      return {
        ...state,
        submitBulkAnswersBegin: true,
        submitBulkAnswersSuccess: false,
        submitBulkAnswersSuccessData: null,
        submitBulkAnswersFailure: false,
        submitBulkAnswersFailureData: null,
      };

    case SUBMIT_BULK_ANSWERS_SUCCESS:
      return {
        ...state,
        submitBulkAnswersBegin: false,
        submitBulkAnswersSuccess: true,
        submitBulkAnswersSuccessData: data,
        submitBulkAnswersFailure: false,
        submitBulkAnswersFailureData: null,
      };

    case SUBMIT_BULK_ANSWERS_FAILURE:
      return {
        ...state,
        submitBulkAnswersBegin: false,
        submitBulkAnswersSuccess: false,
        submitBulkAnswersSuccessData: null,
        submitBulkAnswersFailure: true,
        submitBulkAnswersFailureData: data,
      };

    case RESET_SUBMIT_BULK_ANSWERS:
      return {
        ...state,
        submitBulkAnswersBegin: false,
        submitBulkAnswersSuccess: false,
        submitBulkAnswersSuccessData: null,
        submitBulkAnswersFailure: false,
        submitBulkAnswersFailureData: null,
      };

    case EDIT_SINGLE_ANSWERS_BEGIN:
      return {
        ...state,
        editSingleAnswerBegin: true,
        editSingleAnswerSuccess: false,
        editSingleAnswerSuccessData: null,
        editSingleAnswerFailure: false,
        editSingleAnswerFailureData: null,
      };

    case EDIT_SINGLE_ANSWERS_SUCCESS:
      return {
        ...state,
        editSingleAnswerBegin: false,
        editSingleAnswerSuccess: true,
        editSingleAnswerSuccessData: data,
        editSingleAnswerFailure: false,
        editSingleAnswerFailureData: null,
      };

    case EDIT_SINGLE_ANSWERS_FAILURE:
      return {
        ...state,
        editSingleAnswerBegin: false,
        editSingleAnswerSuccess: false,
        editSingleAnswerSuccessData: null,
        editSingleAnswerFailure: true,
        editSingleAnswerFailureData: data,
      };

    case RESET_EDIT_SINGLE_ANSWERS:
      return {
        ...state,
        editSingleAnswerBegin: false,
        editSingleAnswerSuccess: false,
        editSingleAnswerSuccessData: null,
        editSingleAnswerFailure: false,
        editSingleAnswerFailureData: null,
      };

    //save single tif
    case SUBMIT_SINGLE_TIF_ANSWER_BEGIN:
      return {
        ...state,
        submitSingleTifAnswerBegin: true,
        submitSingleTifAnswerSuccess: false,
        submitSingleTifAnswerSuccessData: null,
        submitSingleTifAnswerFailure: false,
        submitSingleTifAnswerFailureData: null,
      };
    case SUBMIT_SINGLE_TIF_ANSWER_SUCCESS:
      return {
        ...state,
        submitSingleTifAnswerBegin: false,
        submitSingleTifAnswerSuccess: true,
        submitSingleTifAnswerSuccessData: data,
        submitSingleTifAnswerFailure: false,
        submitSingleTifAnswerFailureData: null,
      };
    case SUBMIT_SINGLE_TIF_ANSWER_FAILURE:
      return {
        ...state,
        submitSingleTifAnswerBegin: false,
        submitSingleTifAnswerSuccess: false,
        submitSingleTifAnswerSuccessData: null,
        submitSingleTifAnswerFailure: true,
        submitSingleTifAnswerFailureData: data,
      };
    case RESET_SUBMIT_SINGLE_TIF_ANSWER:
      return {
        ...state,
        submitSingleTifAnswerBegin: false,
        submitSingleTifAnswerSuccess: false,
        submitSingleTifAnswerSuccessData: null,
        submitSingleTifAnswerFailure: false,
        submitSingleTifAnswerFailureData: null,
      };

    case EDIT_SINGLE_TIF_BEGIN:
      return {
        ...state,
        editSingleTifBegin: true,
        editSingleTifSuccess: false,
        editSingleTifSuccessData: null,
        editSingleTifFailure: false,
        editSingleTifFailureData: null,
      };

    case EDIT_SINGLE_TIF_SUCCESS:
      return {
        ...state,
        editSingleTifBegin: false,
        editSingleTifSuccess: true,
        editSingleTifSuccessData: data,
        editSingleTifFailure: false,
        editSingleTifFailureData: null,
      };

    case EDIT_SINGLE_TIF_FAILURE:
      return {
        ...state,
        editSingleTifBegin: false,
        editSingleTifSuccess: false,
        editSingleTifSuccessData: null,
        editSingleTifFailure: true,
        editSingleTifFailureData: data,
      };

    case RESET_EDIT_SINGLE_TIF:
      return {
        ...state,
        editSingleTifBegin: false,
        editSingleTifSuccess: false,
        editSingleTifSuccessData: null,
        editSingleTifFailure: false,
        editSingleTifFailureData: null,
      };

    //bulk submit
    case SUBMIT_BULK_TIF_BEGIN:
      return {
        ...state,
        submitBulkTifBegin: true,
        submitBulkTifSuccess: false,
        submitBulkTifSuccessData: null,
        submitBulkTifFailure: false,
        submitBulkTifFailureData: null,
      };

    case SUBMIT_BULK_TIF_SUCCESS:
      return {
        ...state,
        submitBulkTifBegin: false,
        submitBulkTifSuccess: true,
        submitBulkTifSuccessData: data,
        submitBulkTifFailure: false,
        submitBulkTifFailureData: null,
      };

    case SUBMIT_BULK_TIF_FAILURE:
      return {
        ...state,
        submitBulkTifBegin: false,
        submitBulkTifSuccess: false,
        submitBulkTifSuccessData: null,
        submitBulkTifFailure: true,
        submitBulkTifFailureData: data,
      };

    case RESET_SUBMIT_BULK_TIF:
      return {
        ...state,
        submitBulkTifBegin: false,
        submitBulkTifSuccess: false,
        submitBulkTifSuccessData: null,
        submitBulkTifFailure: false,
        submitBulkTifFailureData: null,
      };
    default:
      return state;
  }
};
