import {
  GET_MENTOR_DASHBOARD_DETAILS_BEGIN,
  GET_MENTOR_DASHBOARD_DETAILS_SUCCESS,
  GET_MENTOR_DASHBOARD_DETAILS_FAILURE,
  RESET_GET_MENTOR_DASHBOARD_DETAILS,
  GET_MENTOR_PROFILE_BEGIN,
  GET_MENTOR_PROFILE_SUCCESS,
  GET_MENTOR_PROFILE_FAILURE,
  RESET_GET_MENTOR_PROFILE,
} from "../constants/MentorDashboard";

const initialState = {
  getMentorDashboardDetailsBegin: false,
  getMentorDashboardDetailsSuccess: false,
  getMentorDashboardDetailsSuccessData: null,
  getMentorDashboardDetailsFailure: false,
  getMentorDashboardDetailsFailureData: null,

  getMentorProfileBegin: false,
  getMentorProfileSuccess: false,
  getMentorProfileSuccessData: null,
  getMentorProfileFailure: false,
  getMentorProfileFailureData: null,
};

export const mentorDashboard = (state = initialState, action) => {
  const data = action.data;
  switch (action.type) {
    //Get mentor dashboard details
    case GET_MENTOR_DASHBOARD_DETAILS_BEGIN:
      return {
        ...state,
        getMentorDashboardDetailsBegin: true,
        getMentorDashboardDetailsSuccess: false,
        getMentorDashboardDetailsSuccessData: null,
        getMentorDashboardDetailsFailure: false,
        getMentorDashboardDetailsFailureData: null,
      };
    case GET_MENTOR_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        getMentorDashboardDetailsBegin: false,
        getMentorDashboardDetailsSuccess: true,
        getMentorDashboardDetailsSuccessData: data,
        getMentorDashboardDetailsFailure: false,
        getMentorDashboardDetailsFailureData: null,
      };
    case GET_MENTOR_DASHBOARD_DETAILS_FAILURE:
      return {
        ...state,
        getMentorDashboardDetailsBegin: false,
        getMentorDashboardDetailsSuccess: false,
        getMentorDashboardDetailsSuccessData: null,
        getMentorDashboardDetailsFailure: true,
        getMentorDashboardDetailsFailureData: data,
      };
    case RESET_GET_MENTOR_DASHBOARD_DETAILS:
      return {
        ...state,
        getMentorDashboardDetailsBegin: false,
        getMentorDashboardDetailsSuccess: false,
        getMentorDashboardDetailsSuccessData: null,
        getMentorDashboardDetailsFailure: false,
        getMentorDashboardDetailsFailureData: null,
      };

    //Get mentor prfile
    case GET_MENTOR_PROFILE_BEGIN:
      return {
        ...state,
        getMentorProfileBegin: true,
        getMentorProfileSuccess: false,
        getMentorProfileSuccessData: null,
        getMentorProfileFailure: false,
        getMentorProfileFailureData: null,
      };
    case GET_MENTOR_PROFILE_SUCCESS:
      return {
        ...state,
        getMentorProfileBegin: false,
        getMentorProfileSuccess: true,
        getMentorProfileSuccessData: data,
        getMentorProfileFailure: false,
        getMentorProfileFailureData: null,
      };
    case GET_MENTOR_PROFILE_FAILURE:
      return {
        ...state,
        getMentorProfileBegin: false,
        getMentorProfileSuccess: false,
        getMentorProfileSuccessData: null,
        getMentorProfileFailure: true,
        getMentorProfileFailureData: data,
      };
    case RESET_GET_MENTOR_PROFILE:
      return {
        ...state,
        getMentorProfileBegin: false,
        getMentorProfileSuccess: false,
        getMentorProfileSuccessData: null,
        getMentorProfileFailure: false,
        getMentorProfileFailureData: null,
      };

    default:
      return state;
  }
};
