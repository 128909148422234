export const VIEW_ALL_PROMPTS_BEGIN = "VIEW_ALL_PROMPTS_BEGIN";
export const VIEW_ALL_PROMPTS_SUCCESS = "VIEW_ALL_PROMPTS_SUCCESS";
export const VIEW_ALL_PROMPTS_FAILURE = "VIEW_ALL_PROMPTS_FAILURE";
export const RESET_VIEW_ALL_PROMPTS = "RESET_VIEW_ALL_PROMPTS";

export const EDIT_PROMPT_BEGIN = "EDIT_PROMPT_BEGIN";
export const EDIT_PROMPT_SUCCESS = "EDIT_PROMPT_SUCCESS";
export const EDIT_PROMPT_FAILURE = "EDIT_PROMPT_FAILURE";
export const RESET_EDIT_PROMPT = "RESET_EDIT_PROMPT";

export const CREATE_PROMPTS_BEGIN = "CREATE_PROMPTS_BEGIN";
export const CREATE_PROMPTS_SUCCESS = "CREATE_PROMPTS_SUCCESS";
export const CREATE_PROMPTS_FAILURE = "CREATE_PROMPTS_FAILURE";
export const RESET_CREATE_PROMPTS = "RESET_CREATE_PROMPTS";

export const VIEW_GROUPS_BEGIN = "VIEW_GROUPS_BEGIN";
export const VIEW_GROUPS_SUCCESS = "VIEW_GROUPS_SUCCESS";
export const VIEW_GROUPS_FAILURE = "VIEW_GROUPS_FAILURE";
export const RESET_VIEW_GROUPS = "RESET_VIEW_GROUPS";

export const GET_MEMBERS_LIST_BEGIN = "GET_MEMBERS_LIST_BEGIN";
export const GET_MEMBERS_LIST_SUCCESS = "GET_MEMBERS_LIST_SUCCESS";
export const GET_MEMBERS_LIST_FAILURE = "GET_MEMBERS_LIST_FAILURE";
export const RESET_GET_MEMBERS_LIST = "RESET_GET_MEMBERS_LIST";

export const CREATE_GROUP_BEGIN = "CREATE_GROUP_BEGIN";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";
export const RESET_CREATE_GROUP = "RESET_CREATE_GROUP";

export const VIEW_GROUP_PROMPT_BEGIN = "VIEW_GROUP_PROMPT_BEGIN";
export const VIEW_GROUP_PROMPT_SUCCESS = "VIEW_GROUP_PROMPT_SUCCESS";
export const VIEW_GROUP_PROMPT_FAILURE = "VIEW_GROUP_PROMPT_FAILURE";
export const RESET_VIEW_GROUP_PROMPT = "RESET_VIEW_GROUP_PROMPT";

export const VIEW_GROUP_MEMBERS_BEGIN = "VIEW_GROUP_MEMBERS_BEGIN";
export const VIEW_GROUP_MEMBERS_SUCCESS = "VIEW_GROUP_MEMBERS_SUCCESS";
export const VIEW_GROUP_MEMBERS_FAILURE = "VIEW_GROUP_MEMBERS_FAILURE";
export const RESET_VIEW_GROUP_MEMBERS = "RESET_VIEW_GROUP_MEMBERS";

export const DELETE_PROMPT_GROUP_BEGIN = "DELETE_PROMPT_GROUP_BEGIN";
export const DELETE_PROMPT_GROUP_SUCCESS = "DELETE_PROMPT_GROUP_SUCCESS";
export const DELETE_PROMPT_GROUP_FAILURE = "DELETE_PROMPT_GROUP_FAILURE";
export const RESET_DELETE_PROMPT_GROUP = "RESET_DELETE_PROMPT_GROUP";

export const DELETE_GROUP_MEMBER_BEGIN = "DELETE_GROUP_MEMBER_BEGIN";
export const DELETE_GROUP_MEMBER_SUCCESS = "DELETE_GROUP_MEMBER_SUCCESS";
export const DELETE_GROUP_MEMBER_FAILURE = "DELETE_GROUP_MEMBER_FAILURE";
export const RESET_DELETE_GROUP_MEMBER = "RESET_DELETE_GROUP_MEMBER";

export const VIEW_SCHEDULE_PROMPT_BEGIN = "VIEW_SCHEDULE_PROMPT_BEGIN";
export const VIEW_SCHEDULE_PROMPT_SUCCESS = "VIEW_SCHEDULE_PROMPT_SUCCESS";
export const VIEW_SCHEDULE_PROMPT_FAILURE = "VIEW_SCHEDULE_PROMPT_FAILURE";
export const RESET_VIEW_SCHEDULE_PROMPT = "RESET_VIEW_SCHEDULE_PROMPT";

export const EDIT_PROMPT_GROUP_BEGIN = "EDIT_PROMPT_GROUP_BEGIN";
export const EDIT_PROMPT_GROUP_SUCCESS = "EDIT_PROMPT_GROUP_SUCCESS";
export const EDIT_PROMPT_GROUP_FAILURE = "EDIT_PROMPT_GROUP_FAILURE";
export const RESET_EDIT_PROMPT_GROUP = "RESET_EDIT_PROMPT_GROUP";

export const SCHEDULE_PROMPT_BEGIN = "SCHEDULE_PROMPT_BEGIN";
export const SCHEDULE_PROMPT_SUCCESS = "SCHEDULE_PROMPT_SUCCESS";
export const SCHEDULE_PROMPT_FAILURE = "SCHEDULE_PROMPT_FAILURE";
export const RESET_SCHEDULE_PROMPT = "RESET_SCHEDULE_PROMPT";

export const RESCHEDULE_PROMPT_BEGIN = "RESCHEDULE_PROMPT_BEGIN";
export const RESCHEDULE_PROMPT_SUCCESS = "RESCHEDULE_PROMPT_SUCCESS";
export const RESCHEDULE_PROMPT_FAILURE = "RESCHEDULE_PROMPT_FAILURE";
export const RESET_RESCHEDULE_PROMPT = "RESET_RESCHEDULE_PROMPT";

export const ADD_MEMBER_TO_GROUP_BEGIN = "ADD_MEMBER_TO_GROUP_BEGIN";
export const ADD_MEMBER_TO_GROUP_SUCCESS = "ADD_MEMBER_TO_GROUP_SUCCESS";
export const ADD_MEMBER_TO_GROUP_FAILURE = "ADD_MEMBER_TO_GROUP_FAILURE";
export const RESET_ADD_MEMBER_TO_GROUP = "RESET_ADD_MEMBER_TO_GROUP";

export const VIEW_PROMPT_RESPONSE_BEGIN = "VIEW_PROMPT_RESPONSE_BEGIN";
export const VIEW_PROMPT_RESPONSE_SUCCESS = "VIEW_PROMPT_RESPONSE_SUCCESS";
export const VIEW_PROMPT_RESPONSE_FAILURE = "VIEW_PROMPT_RESPONSE_FAILURE";
export const RESET_VIEW_PROMPT_RESPONSE = "RESET_VIEW_PROMPT_RESPONSE";

export const VIEW_GROUP_DETAILS_BEGIN = "VIEW_GROUP_DETAILS_BEGIN";
export const VIEW_GROUP_DETAILS_SUCCESS = "VIEW_GROUP_DETAILS_SUCCESS";
export const VIEW_GROUP_DETAILS_FAILURE = "VIEW_GROUP_DETAILS_FAILURE";
export const RESET_VIEW_GROUP_DETAILS = "RESET_VIEW_GROUP_DETAILS";
