export const VIEW_ALL_EXERCISES_BEGIN = "VIEW_ALL_EXERCISES_BEGIN";
export const VIEW_ALL_EXERCISES_SUCCESS = "VIEW_ALL_EXERCISES_SUCCESS";
export const VIEW_ALL_EXERCISES_FAILURE = "VIEW_ALL_EXERCISES_FAILURE";
export const RESET_VIEW_ALL_EXERCISES = "RESET_VIEW_ALL_EXERCISES";

export const ADD_NEW_EXERCISE_BEGIN = "ADD_NEW_EXERCISE_BEGIN";
export const ADD_NEW_EXERCISE_SUCCESS = "ADD_NEW_EXERCISE_SUCCESS";
export const ADD_NEW_EXERCISE_FAILURE = "ADD_NEW_EXERCISE_FAILURE";
export const RESET_ADD_NEW_EXERCISE = "RESET_ADD_NEW_EXERCISE";

export const SAVE_STORY_BEGIN = "SAVE_STORY_BEGIN";
export const SAVE_STORY_SUCCESS = "SAVE_STORY_SUCCESS";
export const SAVE_STORY_FAILURE = "SAVE_STORY_FAILURE";
export const RESET_SAVE_STORY = "RESET_SAVE_STORY";

export const PUBLISH_STORY_BEGIN = "PUBLISH_STORY_BEGIN";
export const PUBLISH_STORY_SUCCESS = "PUBLISH_STORY_SUCCESS";
export const PUBLISH_STORY_FAILURE = "PUBLISH_STORY_FAILURE";
export const RESET_PUBLISH_STORY = "RESET_PUBLISH_STORY";

export const GET_STORIES_BEGIN = "GET_STORIES_BEGIN";
export const GET_STORIES_SUCCESS = "GET_STORIES_SUCCESS";
export const GET_STORIES_FAILURE = "GET_STORIES_FAILURE";
export const RESET_GET_STORIES = "RESET_GET_STORIES";

export const EDIT_EXERCISE_BEGIN = "EDIT_EXERCISE_BEGIN";
export const EDIT_EXERCISE_SUCCESS = "EDIT_EXERCISE_SUCCESS";
export const EDIT_EXERCISE_FAILURE = "EDIT_EXERCISE_FAILURE";
export const RESET_EDIT_EXERCISE = "RESET_EDIT_EXERCISE";

export const VIEW_ALL_TIF_BEGIN = "VIEW_ALL_TIF_BEGIN";
export const VIEW_ALL_TIF_SUCCESS = "VIEW_ALL_TIF_SUCCESS";
export const VIEW_ALL_TIF_FAILURE = "VIEW_ALL_TIF_FAILURE";
export const RESET_VIEW_ALL_TIF = "RESET_VIEW_ALL_TIF";

export const ADD_NEW_TIF_BEGIN = "ADD_NEW_TIF_BEGIN";
export const ADD_NEW_TIF_SUCCESS = "ADD_NEW_TIF_SUCCESS";
export const ADD_NEW_TIF_FAILURE = "ADD_NEW_TIF_FAILURE";
export const RESET_ADD_NEW_TIF = "RESET_ADD_NEW_TIF";

export const EDIT_TIF_BEGIN = "EDIT_TIF_BEGIN";
export const EDIT_TIF_SUCCESS = "EDIT_TIF_SUCCESS";
export const EDIT_TIF_FAILURE = "EDIT_TIF_FAILURE";
export const RESET_EDIT_TIF = "RESET_EDIT_TIF";

export const DELETE_CURRICULUM_BEGIN = "DELETE_CURRICULUM_BEGIN";
export const DELETE_CURRICULUM_SUCCESS = "DELETE_CURRICULUM_SUCCESS";
export const DELETE_CURRICULUM_FAILURE = "DELETE_CURRICULUM_FAILURE";
export const RESET_DELETE_CURRICULUM = "RESET_DELETE_CURRICULUM";
