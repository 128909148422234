import {
  VIEW_ALL_PROMPTS_BEGIN,
  VIEW_ALL_PROMPTS_SUCCESS,
  VIEW_ALL_PROMPTS_FAILURE,
  RESET_VIEW_ALL_PROMPTS,
  CREATE_PROMPTS_BEGIN,
  CREATE_PROMPTS_SUCCESS,
  CREATE_PROMPTS_FAILURE,
  RESET_CREATE_PROMPTS,
  VIEW_GROUPS_BEGIN,
  VIEW_GROUPS_SUCCESS,
  VIEW_GROUPS_FAILURE,
  RESET_VIEW_GROUPS,
  GET_MEMBERS_LIST_BEGIN,
  GET_MEMBERS_LIST_SUCCESS,
  GET_MEMBERS_LIST_FAILURE,
  RESET_GET_MEMBERS_LIST,
  CREATE_GROUP_BEGIN,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  RESET_CREATE_GROUP,
  VIEW_GROUP_PROMPT_BEGIN,
  VIEW_GROUP_PROMPT_SUCCESS,
  VIEW_GROUP_PROMPT_FAILURE,
  RESET_VIEW_GROUP_PROMPT,
  VIEW_GROUP_MEMBERS_BEGIN,
  VIEW_GROUP_MEMBERS_SUCCESS,
  VIEW_GROUP_MEMBERS_FAILURE,
  RESET_VIEW_GROUP_MEMBERS,
  DELETE_PROMPT_GROUP_BEGIN,
  DELETE_PROMPT_GROUP_SUCCESS,
  DELETE_PROMPT_GROUP_FAILURE,
  RESET_DELETE_PROMPT_GROUP,
  DELETE_GROUP_MEMBER_BEGIN,
  DELETE_GROUP_MEMBER_SUCCESS,
  DELETE_GROUP_MEMBER_FAILURE,
  RESET_DELETE_GROUP_MEMBER,
  VIEW_SCHEDULE_PROMPT_BEGIN,
  VIEW_SCHEDULE_PROMPT_SUCCESS,
  VIEW_SCHEDULE_PROMPT_FAILURE,
  RESET_VIEW_SCHEDULE_PROMPT,
  EDIT_PROMPT_GROUP_BEGIN,
  EDIT_PROMPT_GROUP_SUCCESS,
  EDIT_PROMPT_GROUP_FAILURE,
  RESET_EDIT_PROMPT_GROUP,
  SCHEDULE_PROMPT_BEGIN,
  SCHEDULE_PROMPT_SUCCESS,
  SCHEDULE_PROMPT_FAILURE,
  RESET_SCHEDULE_PROMPT,
  ADD_MEMBER_TO_GROUP_BEGIN,
  ADD_MEMBER_TO_GROUP_SUCCESS,
  ADD_MEMBER_TO_GROUP_FAILURE,
  RESET_ADD_MEMBER_TO_GROUP,
  RESCHEDULE_PROMPT_BEGIN,
  RESCHEDULE_PROMPT_SUCCESS,
  RESCHEDULE_PROMPT_FAILURE,
  RESET_RESCHEDULE_PROMPT,
  VIEW_PROMPT_RESPONSE_BEGIN,
  VIEW_PROMPT_RESPONSE_SUCCESS,
  VIEW_PROMPT_RESPONSE_FAILURE,
  RESET_VIEW_PROMPT_RESPONSE,
  VIEW_GROUP_DETAILS_BEGIN,
  VIEW_GROUP_DETAILS_SUCCESS,
  VIEW_GROUP_DETAILS_FAILURE,
  RESET_VIEW_GROUP_DETAILS,
  EDIT_PROMPT_BEGIN,
  EDIT_PROMPT_SUCCESS,
  EDIT_PROMPT_FAILURE,
  RESET_EDIT_PROMPT,
} from "../constants/AdminPrompts";

import { httpApp } from "../../axios/httpUser";

export function viewAllPrompts(page) {
  return (dispatch) => {
    dispatch({
      type: VIEW_ALL_PROMPTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/prompt?pageNumber=${page}&limit=10`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_ALL_PROMPTS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_ALL_PROMPTS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_ALL_PROMPTS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_ALL_PROMPTS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function createPrompt(data) {
  return (dispatch) => {
    dispatch({
      type: CREATE_PROMPTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/prompt`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: CREATE_PROMPTS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CREATE_PROMPTS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CREATE_PROMPTS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CREATE_PROMPTS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function editPrompt(id, data) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PROMPT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.put(`/prompt/${id}`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_PROMPT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_PROMPT,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_PROMPT_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_EDIT_PROMPT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function viewGroups(page) {
  return (dispatch) => {
    dispatch({
      type: VIEW_GROUPS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(`/prompt/group?limit=10&pageNumber=${page}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_GROUPS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_GROUPS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_GROUPS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_GROUPS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function getMembersList(searchQuery = "", page = 1) {
  return (dispatch) => {
    dispatch({
      type: GET_MEMBERS_LIST_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/prompt/member?searchQuery=${searchQuery}&limit=2000&page=${page}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: GET_MEMBERS_LIST_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_GET_MEMBERS_LIST,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: GET_MEMBERS_LIST_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_GET_MEMBERS_LIST,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function createPromptGroup(data) {
  return (dispatch) => {
    dispatch({
      type: CREATE_GROUP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/prompt/group`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: CREATE_GROUP_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CREATE_GROUP,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CREATE_GROUP_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CREATE_GROUP,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function viewGroupPrompt(id, page) {
  return (dispatch) => {
    dispatch({
      type: VIEW_GROUP_PROMPT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/prompt/group/${id}/prompt?page=${page}&limit=10`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_GROUP_PROMPT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_GROUP_PROMPT,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_GROUP_PROMPT_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_GROUP_PROMPT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function viewGroupMembers(page, id, searchQuery = "") {
  return (dispatch) => {
    dispatch({
      type: VIEW_GROUP_MEMBERS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/prompt/group/${id}?limit=10&pageNumber=${page}&searchQuery=${searchQuery}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_GROUP_MEMBERS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_GROUP_MEMBERS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_GROUP_MEMBERS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_GROUP_MEMBERS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function deletePromptGroup(id) {
  return (dispatch) => {
    dispatch({
      type: DELETE_PROMPT_GROUP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/prompt/group/${id}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_PROMPT_GROUP_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_PROMPT_GROUP,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_PROMPT_GROUP_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_PROMPT_GROUP,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function deleteGroupMember(data) {
  return (dispatch) => {
    dispatch({
      type: DELETE_GROUP_MEMBER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.delete(`/prompt/member/group`, {
        headers: {},
        data,
      });

      doRequest.then(
        (res) => {
          dispatch({
            type: DELETE_GROUP_MEMBER_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_DELETE_GROUP_MEMBER,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: DELETE_GROUP_MEMBER_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_DELETE_GROUP_MEMBER,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function viewSchedulePromptList(id, page = 1) {
  return (dispatch) => {
    dispatch({
      type: VIEW_SCHEDULE_PROMPT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `prompt/schedule/${id}?page=${page}&limit=10&orderBy=updatedAt&order=desc`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_SCHEDULE_PROMPT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_SCHEDULE_PROMPT,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_SCHEDULE_PROMPT_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_SCHEDULE_PROMPT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function editPromptGroup(data) {
  return (dispatch) => {
    dispatch({
      type: EDIT_PROMPT_GROUP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.patch(`prompt/group`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: EDIT_PROMPT_GROUP_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_EDIT_PROMPT_GROUP,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: EDIT_PROMPT_GROUP_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_EDIT_PROMPT_GROUP,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function schedulePromptToGroup(data) {
  return (dispatch) => {
    dispatch({
      type: SCHEDULE_PROMPT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`prompt/schedule`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SCHEDULE_PROMPT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SCHEDULE_PROMPT,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SCHEDULE_PROMPT_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SCHEDULE_PROMPT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function reSchedulePromptToGroup(data) {
  return (dispatch) => {
    dispatch({
      type: RESCHEDULE_PROMPT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.patch(`prompt/schedule`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: RESCHEDULE_PROMPT_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_RESCHEDULE_PROMPT,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: RESCHEDULE_PROMPT_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_RESCHEDULE_PROMPT,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function AddMemberToGroup(data) {
  return (dispatch) => {
    dispatch({
      type: ADD_MEMBER_TO_GROUP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`prompt/group/member`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: ADD_MEMBER_TO_GROUP_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_ADD_MEMBER_TO_GROUP,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: ADD_MEMBER_TO_GROUP_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_ADD_MEMBER_TO_GROUP,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function viewPromptResponse(
  groupId,
  promptId,
  page = 1,
  searchQuery = ""
) {
  return (dispatch) => {
    dispatch({
      type: VIEW_PROMPT_RESPONSE_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.get(
        `/prompt/group/${groupId}/prompt/${promptId}?page=${page}&limit=10&searchQuery=${searchQuery}`
      );

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_PROMPT_RESPONSE_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_PROMPT_RESPONSE,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_PROMPT_RESPONSE_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_PROMPT_RESPONSE,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

export function viewGroupDetails(groupId) {
  return (dispatch) => {
    dispatch({
      type: VIEW_GROUP_DETAILS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.patch(`/prompt/group/${groupId}`);

      doRequest.then(
        (res) => {
          dispatch({
            type: VIEW_GROUP_DETAILS_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VIEW_GROUP_DETAILS,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VIEW_GROUP_DETAILS_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VIEW_GROUP_DETAILS,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}
