export const Tell_A_FRIEND_BEGIN = "Tell_A_FRIEND_BEGIN";
export const Tell_A_FRIEND_SUCCESS = "Tell_A_FRIEND_SUCCESS";
export const Tell_A_FRIEND_FAILURE = "Tell_A_FRIEND_FAILURE";
export const RESET_Tell_A_FRIEND = "RESET_Tell_A_FRIEND";

export const GET_FREE_LIST_PLAN_BEGIN = "GET_FREE_LIST_BEGIN";
export const GET_FREE_PLAN_LIST_SUCCESS = "GET_FREE_PLAN_LIST_SUCCESS";
export const GET_FREE_PLAN_LIST_FAILURE = "GET_FREE_PLAN_LIST_FAILURE";
export const RESET_GET_FREE_LIST_PLAN = "RESET_GET_FREE_LIST_PLAN";

// for claiming the subscriptions
export const CLAIM_SUBSCRIPTION_BEGIN = "CLAIM_SUBSCRIPTION_BEGIN";
export const CLAIM_SUBSCRIPTION_SUCCESS = "CLAIM_SUBSCRIPTION_SUCCESS";
export const CLAIM_SUBSCRIPTION_FAILURE = "CLAIM_SUBSCRIPTION_FAILURE";
export const RESET_CLAIM_SUBSCRIPTION = "RESET_CLAIM_SUBSCRIPTION";
