import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../utilities/modals/ConfirmModal";

import "../../utilities/styles/customToggle.css";
import {
  getPromoCodeDetails,
  createPromoCodes,
  deletePromoCodes,
} from "../../../AppRedux/actions/AdminSettings";
import { CardButton, InputGroup, successToast } from "../../utilities";
import { ReactHelmet } from "../../utilities";
import SeoData from "../../utilities/seo/seoData.json";
import { ListHeader } from "../memberDashboard/tableComponents/ExercisesList";
import { Scaleloader } from "../../utilities";
import { AdminDeleteIcon, AdminBlueArrow } from "../../utilities/svg";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const AdminCreatePromocode = () => {
  const dispatch = useDispatch();
  const [viewPromoCodes, setViewPromoCodes] = useState(false);
  const [promoCodeDetails, setPromoCodeDetails] = useState("");
  const [formError, setFormError] = useState({});
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [startDate, setStartDate] = useState(null);

  const [formData, setformData] = useState({
    name: "",
    id: "",
    percentOff: "",
    durationMonths: "",
  });

  const {
    viewPromoCodeDetailsBegin,
    viewPromoCodeDetailsSuccessData,
    createPromoCodeBegin,
    createPromoCodeSuccessData,
    deletePromoCodeBegin,
    deletePromoCodeSuccessData,
  } = useSelector(({ adminSettings }) => adminSettings);

  const deleteGroupModalData = {
    heading: "Are You Sure You Want To Delete ?",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      dispatch(deletePromoCodes(showDeleteConfirmModal));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowDeleteConfirmModal(false);
    },
    buttonLoader: deletePromoCodeBegin,
  };

  useEffect(() => {
    dispatch(getPromoCodeDetails());
  }, [dispatch]);

  useEffect(() => {
    if (viewPromoCodeDetailsSuccessData) {
      setPromoCodeDetails(viewPromoCodeDetailsSuccessData?.data?.rows);
    }
    if (deletePromoCodeSuccessData) {
      setShowDeleteConfirmModal(false);
      successToast("Promocode deleted successfully");
      dispatch(getPromoCodeDetails());
    }
    if (createPromoCodeSuccessData) {
      setViewPromoCodes(false);
      successToast("Promocode created successfully");

      setformData((formData) => ({
        ...formData,
        name: "",
        id: "",
        percentOff: "",
        durationMonths : ""
      }));
      dispatch(getPromoCodeDetails());
    }
  }, [
    viewPromoCodeDetailsSuccessData,
    dispatch,
    deletePromoCodeSuccessData,
    createPromoCodeSuccessData,
  ]);

  const customPromoCodeBasicFormValidation = () => {
    const { name, id, percentOff, durationMonths } = formData;

    const validationErrors = {};
    if (name?.trim() === "") {
      validationErrors.name = "Promocode name is required.";
    }
    if (name?.trim() !== "" && name.length < 3) {
      validationErrors.name = "please give a valid Promocode name.";
    }

    if (id?.trim() === "") {
      validationErrors.id = "Code is required.";
    }
    if (id?.trim() !== "" && id.length < 3) {
      validationErrors.id = "please give a valid code.";
    }
    if (percentOff?.trim() === "") {
      validationErrors.percentOff = "Discount is required.";
    }
    if (percentOff?.trim() !== "" && Number(percentOff) <= 0) {
      validationErrors.percentOff = "please give a valid Discount.";
    }

    if (durationMonths?.trim() == "") {
      validationErrors.durationMonths = "Duration is required";
    }

    return validationErrors;
  };

  const handleInputOnChange = (e) => {
    let value = e.target.value;
    let sm = formError;
    delete sm[[e.target.name]];
    setFormError({ ...formError, ...sm });
    setformData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const onSubmitPromocodeForm = (e) => {
    e.preventDefault();
    const validationErrors = customPromoCodeBasicFormValidation();
    if (Object.keys(validationErrors).length === 0) {
      let data = {
        name            : formData?.name,
        id              : formData?.id,
        percent_off     : Number(formData?.percentOff),
        duration_months : Number(formData?.durationMonths),
        redeem_date     : startDate
      };
      dispatch(createPromoCodes(data));
    } else {
      setFormError({ ...formError, ...validationErrors });
    }
  };

  return (
    <div
      className="rounded-lg bg-white w-11/12 p-8 mt-12 mx-auto"
      style={{ boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.07)" }}
    >
      <ReactHelmet data={SeoData.promoCreate} />
      <div className="flex items-center space-x-4 shadow-adminMember rounded-full">
        {viewPromoCodes && (
          <div
            className="dashboardIcon text-brandBlue rounded-full min-w-36px min-h-36px flexCenter"
            style={{
              boxShadow:
                "0px 100px 80px rgba(0, 0, 0, 0.09), 0px 36.5016px 29.2013px rgba(0, 0, 0, 0.0620983), 0px 17.7209px 14.1767px rgba(0, 0, 0, 0.0500662), 0px 8.6871px 6.94968px rgba(0, 0, 0, 0.0399338), 0px 3.43489px 2.74791px rgba(0, 0, 0, 0.0279017)",
            }}
            onClick={() => setViewPromoCodes(false)}
          >
            <AdminBlueArrow className="stroke-current" />
          </div>
        )}
        <h1
          className="flex-1 font-semibold text-xl "
          style={{ color: "#002E3E" }}
        >
          {viewPromoCodes ? "Create activation code" : "Manage Coupons"}
        </h1>
        {!viewPromoCodes && (
          <h1
            className="font-bold text-sm cursor-pointer"
            style={{ color: "#0282AD" }}
            onClick={() => setViewPromoCodes(!viewPromoCodes)}
          >
            Create activation code
          </h1>
        )}
      </div>
      {!viewPromoCodes ? (
        <>
          <ListHeader
            headers={headers}
            gridClass="text-left grid-cols-adminPromptList mt-8"
          />
          {!viewPromoCodeDetailsBegin && promoCodeDetails ? (
            <>
              {promoCodeDetails?.map(
                ({ id, name, code, percentOff }, index) => (
                  <div
                    key={index}
                    className={`dashboardListHover member-table-row-item grid-cols-adminPromptList gap-2 font-medium text-sm xl:text-base items-center
              ${index === promoCodeDetails?.length - 1 ? "border-none" : ""}`}
                  >
                    <p>{name}</p>
                    <p>{code}</p>
                    <p>{percentOff}%</p>
                    <div
                      className="text-slate-900 dashboardIcon"
                      onClick={() => setShowDeleteConfirmModal(id)}
                    >
                      <AdminDeleteIcon className="fill-current" />
                    </div>
                  </div>
                )
              )}
            </>
          ) : (
            <Scaleloader />
          )}
          {showDeleteConfirmModal && (
            <ConfirmModal
              {...deleteGroupModalData}
              state={showDeleteConfirmModal}
              setState={setShowDeleteConfirmModal}
            />
          )}
        </>
      ) : (
        <form
          className="flex flex-col mt-4 w-full xl:w-2/3 px-12 "
          onSubmit={(e) => onSubmitPromocodeForm(e)}
        >
          <div className="relative ">
            <InputGroup
              label="Name"
              name="name"
              onChange={handleInputOnChange}
              value={formData?.name}
              errorMessage={formError?.name}
            />

            <p
              className="font-medium"
              style={{ color: "#8D8D8D", fontSize: "14px" }}
            >
              This will be on customers receipts and invoices
            </p>
            <InputGroup
              label="Coupon Code"
              name="id"
              onChange={handleInputOnChange}
              value={formData?.id}
              errorMessage={formError?.id}
            />

            <p
              className="font-medium"
              style={{ color: "#8D8D8D", fontSize: "14px" }}
            >
              This certifies this coupon in API.
            </p>

            <InputGroup
              label="Percentage Off"
              name="percentOff"
              type="number"
              onChange={handleInputOnChange}
              value={formData?.percentOff}
              errorMessage={formError?.percentOff}
              showPercentage={true}
            />

            <InputGroup
              label="Duration in months"
              name="durationMonths"
              onChange={handleInputOnChange}
              value={formData?.durationMonths}
              errorMessage={formError?.durationMonths}
            />

            <p className="text-base font-semibold text-brandGrey mb-1">
              Redeem by date
            </p>

            <div 
              className="flex flex-col mb-4 border border-black py-3 px-4"
              style={{ borderColor: 'rgb(185, 185, 187)' }}
            >
              <DatePicker 
                minDate={new Date()} 
                selected={startDate} 
                onChange={(date) => {setStartDate(date)}} 
              />
            </div>

            <p
              className="font-medium mb-12"
              style={{ color: "#8D8D8D", fontSize: "14px" }}
            >
              Enter a date, if the discount should expire after that time
            </p>

            <CardButton
              text="Create Promo"
              linearBg={true}
              classNames="w-55% justify-center 370px:mx-0 font-semibold text-sm px-6 rounded-md mt-12"
              loader={createPromoCodeBegin}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default AdminCreatePromocode;
const headers = ["Name", "Code", "Percent Off"];
