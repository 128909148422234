import axios from "axios";
import Promise from "promise";
import { toast } from "react-toastify";

import config from "./configServer";
import { getUserTokenFromLocalStorage } from "../components/utilities";
const env = process.env.NODE_ENV || "development";

export const httpApp = axios.create({
  baseURL: config[env].BASE_API,
});
httpApp.interceptors.request.use(
  function (config) {
    if (getUserTokenFromLocalStorage()) {
      let token = getUserTokenFromLocalStorage();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  function (error) {
    return Promise.resolve({ error });
  }
);
httpApp.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise(function (resolve, reject) {
      if (err.response && err.response.status === 403) {
        console.error(err.response.data.error);
        if (err.response.data.error === "invalid_token") {
          toast.error(err.response.data.error);
          localStorage.clear();
          setTimeout(() => {
            window.location.replace("/");
          });
          return false;
        }
      } else if (err.response && err.response.status === 404) {
        // toast.error("Invalid Endpoint. Try again");
        // return false;
      } else if (err.response && err.response.status === 500) {
        // toast.error("Internal Server Error");
        console.error("Internal Server Error");
        return false;
      } else if (err.response && err.response.status === 401) {
        if (
          err.response.data.message ===
          "You are not authorized to perform this operation"
        ) {
          localStorage.clear();
          setTimeout(() => {
            window.location.replace("/");
          });
          return false;
        }
      } else if (
        (typeof err === "object" || typeof err === "function") &&
        err !== null
      ) {
        if (!err.response) {
          toast.error(
            err.response.data.message
              ? err.response.data.message
              : "Something went wrong!, Try again"
          );
          return false;
        }
      }

      throw err;
    });
  }
);

