import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import DayGridPlugin from "@fullcalendar/daygrid";
import InteractionPlugin from "@fullcalendar/interaction";
import moment from 'moment-timezone';
import Cookies from 'universal-cookie';
import { convertTimeTo12HoursFromat, localTime } from "../../components/pageComponents/MyCalendarGeneralItems";
import SeoData from "../../components/utilities/seo/seoData.json";
import data from "../../components/utilities/content/statusAndRoleManagement.json";
import mentorPageData from "../../components/utilities/content/mentorDashboard.json";
import MemberNavbar from "../../components/pageComponents/memberDashboard/MemberNavbar";
import Navbar from "../../components/pageComponents/mentorDashboard/Navbar";
import MentorLeftSideProfileCard from "../../components/pageComponents/mentorDashboard/MentorLeftSideProfileCard";
import ScheduleTimeSlotModal from "../../components/pageComponents/mentorDashboard/MyCalendar/ScheduleTimeSlotModal";
import MeetingDayModal from "../../components/pageComponents/mentorDashboard/MyCalendar/MeetingDayModal";
import { ReactHelmet } from "../../components/utilities";
import "../../components/utilities/styles/mentorMyCalendar.css";
import {
  getMentorCalendarPreference,
  getMentorMeeting,
} from "../../AppRedux/actions/MentorCalendar";
import {
  functionAfterDateSet,
  DayCellContent,
  CalendarEventContent,
  CalendarBackArrow,
  CalendarLoader,
} from "../../components/pageComponents/MyCalendarGeneralItems";



const MyCalendar = () => {
  const navigate = useNavigate(),
    dispatch = useDispatch(),
    [showAvailabilityModal, setShowAvailabilityModal] = useState(false),
    [showMeetingDayModal, setShowMeetingDayModal] = useState(false),
    [bookedMeetingsInfo, setBookedMeetingsInfo] = useState([]),
    [calendarCurrentMonthAndYear, setCalendarCurrentMonthAndYear] = useState(),
    [selectedDayInfo, setSelectedDayInfo] = useState({}),
    [clickedDayMeetings, setClickedDayMeetings] = useState([]),
    {
      getMentorCalendarPreferenceSuccessData,
      getMentorMeetingListSuccessData,
    } = useSelector(({ mentorCalendar }) => mentorCalendar);


    useEffect(() => {
      const cookies = new Cookies(null, { path: '/' });
      if(cookies.get('geolocation')) {
        moment.tz.setDefault(cookies.get('geolocation'));
      }
    }, []);

 

  useEffect(() => {
    if (getMentorMeetingListSuccessData) {
      // Need in future - hide mentor completed meetings
      getMentorMeetingListSuccessData?.data.rows.forEach(
        ({ toTime, meetingDate }, index, meetingInfo) => {

          let eventDate = new Date(meetingDate).toISOString().split("T")[0];
          let eventTime = moment(toTime).format("HH:mm");
          let datetimeA = moment(eventDate + " " + eventTime).toISOString();

          if (moment(new Date(datetimeA))
            .isAfter()) {
            setBookedMeetingsInfo([...meetingInfo]);
          }
        }
      );
    }
  }, [getMentorMeetingListSuccessData]);

 


  useEffect(() => {
    if (calendarCurrentMonthAndYear) {
      // if user changed curent month & year this will trigger
      dispatch(
        getMentorCalendarPreference(
          calendarCurrentMonthAndYear.month,
          calendarCurrentMonthAndYear.year
        )
      );
      dispatch(getMentorMeeting(data.MEETING.STATUS.PENDING));
    }
  }, [calendarCurrentMonthAndYear, dispatch]);

  // current month mentor events
  const eventsDates = [
    ...new Set( //making unique array
      getMentorCalendarPreferenceSuccessData?.data?.map(({ date }) => date)
    ),
  ];

  // current month mentor events
  const events = eventsDates.map((date) => {

    const eventDate = new Date(date).toISOString().split("T")[0];
    let event;
    // adding meeting info if there is any
    if (bookedMeetingsInfo.length >= 1) {
      bookedMeetingsInfo.forEach((meetingInfo) => {
        const { member, toTime, fromTime, meetingDate } = meetingInfo;
        const meetingDay =
          meetingInfo && new Date(meetingDate).toISOString().split("T")[0]

        if (meetingDay && meetingDay === eventDate) {
          event = {
            title: `${member?.firstName} ${member?.lastName} `,
            date: meetingDay,
            eventType: "meeting",
            color: "#2E94B6",
            extendedProps: {
              time: `${convertTimeTo12HoursFromat(localTime(
                fromTime),
              )} - ${convertTimeTo12HoursFromat(localTime(toTime))}`,
            },
          };
        }
      });
    }

    if (event) {
      return event;
    } else {
      return (event = {
        title: "Available",
        eventType: "normal_day",
        date: eventDate,
      });
    }
  });

  // adding not available card to days that have no events
  const addNotAvailableDays = () => {
    
    if (getMentorCalendarPreferenceSuccessData) {
      document.querySelectorAll(".fc-daygrid-day-events").forEach((event) => {
        if (event.firstChild.textContent !== "Available") {
          if (event.lastChild.textContent !== "Not Available") {
            if (
              //removing not available event from previous & next month box
              !event.parentElement.parentElement.classList.contains(
                "fc-day-disabled"
              )
            ) {
              event.style.backgroundColor = "#918c94";
              var node = document.createElement("p"); // Create a <p> node
              var textnode = document.createTextNode("Not Available"); // Create a text node
              node.appendChild(textnode);
              return event.appendChild(node);
            } else {
              if (event.lastChild.textContent === "Not Available") {
                event.lastChild.remove(); // removing not avialble msg when mentor add timeslot to that day
              }
            }
          } else {
            if (
              event.parentElement.parentElement.classList.contains(
                "fc-day-disabled"
              )
            ) {
              event.style.backgroundColor = "#fff"; // removing not avilable msg from non-current month dates
              event.lastChild.remove();
            }
          }
        } else if (event.firstChild.textContent === "Available") {
          if (event.lastChild.textContent === "Not Available") {
            event.lastChild.remove(); // removing not avialble msg when mentor add timeslot to that day
          }
        }
      });
    }
  };

  const handleDayclick = (dayinfo) => {
    const dateString = dayinfo.dateStr
      ? dayinfo.dateStr
      : dayinfo?.event?.startStr;
    var today = new Date().toLocaleDateString("en-US").split("/");
    // creating date in YYYY-MM-DD format
    today = `${today[2]}-${today[0].length === 1 ? `0${today[0]}` : today[0]}-${today[1].length === 1 ? `0${today[1]}` : today[1]
      }`;

    if (dayinfo.dateStr >= today || dayinfo?.event?.startStr >= today) {
      if (getMentorCalendarPreferenceSuccessData) {
        if (eventsDates.length !== 0) {
          let eventDay = false;
          eventsDates.forEach((eventDate, index) => {
            if (eventDate.split("T")[0] === dateString) {
              eventDay = events[index].eventType;
            }
          });

          if (eventDay) {
            if (eventDay === "meeting") {
              setSelectedDayInfo(dayinfo);
              setShowMeetingDayModal(true);

              // storing clicked day meetings
              let clickedDateMeetings = [];
              bookedMeetingsInfo.forEach((meetingInfo) => {
                const meetingDay = new Date(meetingInfo.meetingDate).toISOString().split("T")[0]
                if (meetingDay === dateString) {
                  clickedDateMeetings.push(meetingInfo);
                }
              });
              setClickedDayMeetings(clickedDateMeetings);
            } else if (eventDay === "normal_day") {
              setSelectedDayInfo(dayinfo);
              setShowAvailabilityModal(true);
            }
          } else {
            setSelectedDayInfo(dayinfo);
            setShowAvailabilityModal(true);
          }
        } else {
          setSelectedDayInfo(dayinfo);
          setShowAvailabilityModal(true);
        }
      }
    } else {
      // Past day
    }
  };

  return (
    <div className="min-h-screen" style={{ backgroundColor: "#f3f3f3" }}>
      <ReactHelmet data={SeoData.calendar} />
      <MemberNavbar dropdownData={mentorPageData.navbar.dropdownList} />
      <Navbar selectedTab="my-calendar" />
      <div className="w-96% xl:w-10/12 mx-auto pt-10 pb-8 500px:pb-16">
        <div className="flex gap-6 flex-col md:flex-row">
          <MentorLeftSideProfileCard />
          <div
            className={`overflow-x-scroll 850px:overflow-x-auto relative w-full rounded-xl h-auto bg-white p-6 animate-dashboardInitial  ${!getMentorCalendarPreferenceSuccessData && "opacity-80"
              }`}
          >
            <div>
              <CalendarBackArrow
                onClick={() => navigate("/mentor/dashboard")}
              />

              <FullCalendar
                plugins={[DayGridPlugin, InteractionPlugin]}
                headerToolbar={{ left: "", right: "prev,title,next" }}
                events={events}
                eventBackgroundColor="#71b48f"
                // eventsSet={() => addNotAvailableDays()}
                eventContent={(event) => <CalendarEventContent event={event} />}
                datesSet={(dateInfo) =>
                  functionAfterDateSet(dateInfo, setCalendarCurrentMonthAndYear)
                }
                selectable={true}
                dateClick={handleDayclick}
                eventClick={handleDayclick}
                showNonCurrentDates={false}
                fixedWeekCount={false}
                height="auto"
                dayCellContent={(dayInfo) => (
                  <DayCellContent dayInfo={dayInfo} />
                )}
              />

              {!getMentorCalendarPreferenceSuccessData && ( //loader
                <CalendarLoader />
              )}
            </div>
          </div>
          {showAvailabilityModal && ( // modal when user click in days
            <ScheduleTimeSlotModal
              dayInfo={selectedDayInfo}
              state={showAvailabilityModal}
              setState={setShowAvailabilityModal}
              addNotAvailableDays={addNotAvailableDays}
              calendarCurrentMonthAndYear={calendarCurrentMonthAndYear}
              getMentorCalendarPreferenceSuccessData={
                getMentorCalendarPreferenceSuccessData
              }
            />
          )}

          {showMeetingDayModal && ( // modal when user click in meet days
            <MeetingDayModal
              dayInfo={selectedDayInfo}
              state={showMeetingDayModal}
              setState={setShowMeetingDayModal}
              calendarCurrentMonthAndYear={calendarCurrentMonthAndYear}
              dayMeetings={clickedDayMeetings}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MyCalendar;
