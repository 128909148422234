import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { MentorResumePage } from "../../../MyMentorResumePage";
import AdminMentorLeftSide from "./AdminMentorLeftSide";
import SubTabs from "../adminMember/SubTabs";
import { ListHeader } from "../../memberDashboard/tableComponents/ExercisesList";
import { UserAvatar, Scaleloader, RcTooltip } from "../../../utilities";
import { RatingIcon } from "../../../utilities/svg";
import {
  NoListToShowContainer,
  PageNumbers,
} from "../../dashboardGeneralItems";
import {
  getMentorFeedback,
  getSingleMentorsDetails,
  getMentorSessionDetails,
} from "../../../../AppRedux/actions/AdminMentor";

const SingleMentorDetailsPage = ({ tab, id }) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const cell = params.getAll("cell").toString();
  const [mentorFeedbacks, setMentorFeedbacks] = useState("");
  const [mentorProfileDetials, setMentorProfileDetails] = useState("");
  const [mentorSessionDetails, setMentorSessionDetails] = useState("");
  const [currentPage, setcurrentPage] = useState(1);

  const {
    getSingleMentorDetailsBegin,
    getSingleMentorDetailsSuccessData,
    getMentorFeedbackBegin,
    getMentorFeedbackSuccessData,
    getMentorSessionDetailsBegin,
    getMentorSessionDetailsSuccessData,
  } = useSelector(({ adminMentor }) => adminMentor);

  useEffect(() => {
    dispatch(getSingleMentorsDetails(id));
    if (cell === "feedback") {
      dispatch(getMentorFeedback(id, currentPage));
    }
    if (cell === "sessions") {
      dispatch(getMentorSessionDetails(id));
    }
  }, [dispatch, cell, currentPage, id]);

  useEffect(() => {
    if (getSingleMentorDetailsSuccessData) {
      setMentorProfileDetails(getSingleMentorDetailsSuccessData?.data);
    }
    if (getMentorFeedbackSuccessData) {
      setMentorFeedbacks(getMentorFeedbackSuccessData?.data);
    }
    if (getMentorSessionDetailsSuccessData) {
      setMentorSessionDetails(getMentorSessionDetailsSuccessData?.data);
    }
  }, [
    getMentorFeedbackSuccessData,
    getSingleMentorDetailsSuccessData,
    getMentorSessionDetailsSuccessData,
  ]);

  const details = [
    {
      heading: "Total hours",
      data: `${mentorSessionDetails?.totalHours}`,
    },
    {
      heading: "Total session hours taken",
      data: `${mentorSessionDetails?.totalSessionHoursTaken}`,
    },
    {
      heading: "Total hours left",
      data: `${mentorSessionDetails?.totalSessionLeft}`,
    },
    {
      heading: "Amount earned",
      data: `${mentorSessionDetails?.earnedAmount} $`,
    },
  ];

  return (
    <div className="w-96% pb-8 mx-8 500px:pb-16">
      <SubTabs selectedTab={tab} cells={cells} />
      <div className="flex gap-3 950px:gap-6 mt-6">
        {cell === "profile" ? (
          <AdminMentorLeftSide
            data={mentorProfileDetials}
            loader={getSingleMentorDetailsBegin}
          />
        ) : (
          ""
        )}
        <div className="overflow-x-scroll 950px:overflow-x-auto bg-white rounded-10px shadow-memberDashboard flex-auto py-4  ">
          <div style={{ minWidth: "520px" }}>
            {cell === "profile" ? (
              <>
                {!getSingleMentorDetailsBegin && mentorProfileDetials ? (
                  <MentorResumePage data={mentorProfileDetials} type="admin" />
                ) : (
                  <Scaleloader />
                )}
              </>
            ) : cell === "feedback" ? (
              <>
                <div className="px-4">
                  {!getMentorFeedbackBegin && mentorFeedbacks ? (
                    <>
                      <div className="flex flex-row items-center space-x-4 w-full">
                        <UserAvatar
                          name={mentorProfileDetials?.firstName}
                          classNames="w-9 h-9 xl:text-xl"
                          profilePhoto={mentorProfileDetials.profilePhoto}
                          imgClassNames="w-9 h-9"
                        />

                        <h1
                          className="font-semibold text-lg xl:text-2xl "
                          style={{ color: "#656565" }}
                        >
                          {`${mentorProfileDetials?.firstName} ${mentorProfileDetials?.lastName}`}
                        </h1>
                      </div>
                      <ListHeader
                        headers={headers}
                        gridClass="mt-4 grid-cols-adminMentorFeedbackListing"
                      />
                      <div className="member-table-row-items">
                        {mentorFeedbacks?.rows?.length !== 0 ? (
                          mentorFeedbacks?.rows.map(
                            ({ review, rating, UserMember }, index) => {
                              const {
                                firstName,
                                lastName,
                                email,
                                profilePhoto,
                              } = UserMember.User;

                              if (UserMember !== null) {
                                return (
                                  <div
                                    key={index}
                                    className={`dashboardListHover cursor-default member-table-row-item grid-cols-adminMentorFeedbackListing items-center gap-2 font-medium text-sm xl:text-base
                               ${
                                 index === mentorFeedbacks?.rows.length - 1
                                   ? "border-none"
                                   : ""
                               }`}
                                  >
                                    <div className="flex justify-start items-center space-x-2">
                                      {/* Profile photo  */}
                                      <UserAvatar
                                        name={firstName}
                                        classNames="w-9 h-9 xl:text-xl"
                                        profilePhoto={profilePhoto}
                                        imgClassNames="w-9 h-9"
                                      />
                                      <p className="oneLineContent">
                                        {firstName} {lastName}
                                      </p>
                                    </div>
                                    <p className="oneLineContent">{email}</p>

                                    <div className="flex items-center">
                                      <p>{rating}</p>
                                      <RatingIcon className="mt-1.5" />
                                    </div>
                                    <RcTooltip content={review}>
                                      <p className="oneLineContent">
                                        {review}{" "}
                                      </p>
                                    </RcTooltip>
                                  </div>
                                );
                              }
                              return "";
                            }
                          )
                        ) : (
                          <div className=" rounded-lg ">
                            <NoListToShowContainer text="There are no ratings under this mentor" />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <Scaleloader />
                  )}
                </div>
                <div className="relative m-12">
                  <PageNumbers
                    count={mentorFeedbacks?.count}
                    currentPage={currentPage}
                    setcurrentPage={setcurrentPage}
                    loading={getMentorFeedbackBegin}
                    limit={10}
                    onPageNumberClick={(number) => {
                      dispatch(getMentorFeedback(id, number));
                    }}
                  />
                </div>
              </>
            ) : (
              cell === "sessions" && (
                <div className="px-4">
                  {!getMentorSessionDetailsBegin && mentorSessionDetails ? (
                    <>
                      <div className="flex flex-row items-center space-x-4 w-full">
                        <UserAvatar
                          name={mentorProfileDetials?.firstName}
                          classNames="w-9 h-9 xl:text-xl"
                          profilePhoto={mentorProfileDetials.profilePhoto}
                          imgClassNames="w-9 h-9"
                        />
                        <h1
                          className="font-semibold text-lg xl:text-2xl "
                          style={{ color: "#656565" }}
                        >
                          {`${mentorProfileDetials?.firstName} ${mentorProfileDetials?.lastName}`}
                        </h1>
                      </div>
                      <div className="mt-6 p-4 grid grid-cols-2 gap-4 gap-y-10">
                        {details.map(({ heading, data }, index) => (
                          <div key={index}>
                            <h1
                              className="text-sm mb-2"
                              style={{ color: "#0000007a" }}
                            >
                              {heading}
                            </h1>

                            <div className=" flex space-x-4">
                              <h1 className="text-xl font-medium">{data}</h1>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <Scaleloader />
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMentorDetailsPage;

const cells = ["Profile", "Feedback", "Sessions"];
const headers = ["Name", "E-mail", "Rating", "Feedback"];
