import { scrollToTop, DeleteIcon } from "../../../utilities";
import { InputGroup } from "../../../utilities/newFormInputs";

const CustomFormValidation = (formData) => {
  const {
    universities,
    degrees,
    certifications,
    coachingPrograms,
    mentor_linkedin,
    mentor_website,
    mentor_resume,
  } = formData;
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );

  let validationErrors = {};

  Object.values(universities).forEach((value, index) => {
    if (value === null || value.trim() === "") {
      const inputKey = `university${index}`;
      validationErrors = {
        ...validationErrors,
        universities: {
          ...validationErrors.universities,
          [inputKey]: "University is required.",
        },
      };
    }
  });
  Object.values(degrees).forEach((value, index) => {
    const inputKey = `degree${index}`;
    if (value === null || value.trim() === "") {
      validationErrors = {
        ...validationErrors,
        degrees: {
          ...validationErrors.degrees,
          [inputKey]: "Degree is required.",
        },
      };
    }
  });
  Object.values(certifications).forEach((value, index) => {
    const inputKey = `certification${index}`;
    if (value === null || value.trim() === "") {
      validationErrors = {
        ...validationErrors,
        certifications: {
          ...validationErrors.certifications,
          [inputKey]: "Certification is required.",
        },
      };
    }
  });
  Object.values(coachingPrograms).forEach((value, index) => {
    const inputKey = `coachingProgram${index}`;
    if (value === null || value.trim() === "") {
      validationErrors = {
        ...validationErrors,
        coachingPrograms: {
          ...validationErrors.coachingPrograms,
          [inputKey]: "Coaching program is required.",
        },
      };
    }
  });

  if (mentor_linkedin?.trim() !== "" && !regex.test(mentor_linkedin)) {
    validationErrors.mentor_linkedin = "Invalid url format";
  }

  if (mentor_website?.trim() !== "" && !regex.test(mentor_website)) {
    validationErrors.mentor_website = "Invalid url format";
  }

  if (mentor_resume === "") {
    validationErrors.mentor_resume = "Resume is required.";
  }

  return validationErrors;
};

export const onMentorProfessionalDetailsSubmit = (
  e,
  formData,
  changeToNextPage,
  setFormError,
  formError
) => {
  e.preventDefault();
  const validationErrors = CustomFormValidation(formData);
  if (Object.keys(validationErrors).length === 0) {
    const pageData = {
      ...formData,
    };
    changeToNextPage("area_of_focus_page", pageData);
    scrollToTop("50", "16.66");
  } else {
    setFormError({ ...formError, ...validationErrors });
  }
};

const handleChangeInUniversityInput = (e, formError, setFormData, formData) => {
  let sm = formError.universities;
  delete sm[[e.target.name]];

  const { name, value } = e.target;
  setFormData({
    ...formData,
    universities: {
      ...formData.universities,
      [name]: value,
    },
  });
};

const handleChangeInDegreeInput = (e, formError, setFormData, formData) => {
  let sm = formError.degrees;
  delete sm[[e.target.name]];

  const { name, value } = e.target;
  setFormData({
    ...formData,
    degrees: {
      ...formData.degrees,
      [name]: value,
    },
  });
};
const handleChangeInCertificationInput = (
  e,
  formError,
  setFormData,
  formData
) => {
  let sm = formError.certifications;
  delete sm[[e.target.name]];

  const { name, value } = e.target;
  setFormData({
    ...formData,
    certifications: {
      ...formData.certifications,
      [name]: value,
    },
  });
};
const handleChangeInCoachingProgramInput = (
  e,
  formError,
  setFormData,
  formData
) => {
  let sm = formError.coachingPrograms;
  delete sm[[e.target.name]];

  const { name, value } = e.target;
  setFormData({
    ...formData,
    coachingPrograms: {
      ...formData.coachingPrograms,
      [name]: value,
    },
  });
};

export const handleInputOnchange = (
  e,
  formError,
  setFormData,
  formData,
  inputType
) => {
  let sm = formError;
  delete sm[[e.target.name]];

  setFormData({
    ...formData,
    [e.target.name]:
      inputType === "file"
        ? e.target.files[0]
          ? e.target.files[0]
          : ""
        : e.target.value,
  });
};

export const UniversityInputsContainer = ({
  numberOfUniversityInputGroup,
  formData,
  formError,
  setFormData,
  setNumberOfUniversityInputGroup,
}) => {
  return (
    <>
      {Array(numberOfUniversityInputGroup)
        .fill()
        .map((_, index) => {
          const currentDegreeKey = `degree${index}`;
          const currentDegreeValue = formData.degrees[currentDegreeKey];
          const currentUniversityKey = `university${index}`;
          const currentUniversityValue =
            formData.universities[currentUniversityKey];
          return (
            <div
              key={index}
              className={`relative md:grid md:grid-cols-1 950px:grid-cols-2 950px:gap-x-14  ${index >= 1 ? "animate-navbar" : ""
                }`}
              id={`universityInputGroup${index}`}
            >
              <InputGroup
                label="University"
                name={currentUniversityKey}
                onChange={(e) =>
                  handleChangeInUniversityInput(
                    e,
                    formError,
                    setFormData,
                    formData
                  )
                }
                value={currentUniversityValue}
                errorMessage={formError.universities[currentUniversityKey]}
              />

              <InputGroup
                label="Degree"
                name={currentDegreeKey}
                onChange={(e) =>
                  handleChangeInDegreeInput(e, formError, setFormData, formData)
                }
                value={currentDegreeValue}
                errorMessage={formError.degrees[currentDegreeKey]}
              />

              {index >= 1 && (
                <DeleteIcon
                  classNames="ml-6"
                  input={true}
                  onClick={() => {
                    document
                      .getElementById(`universityInputGroup${index}`)
                      ?.remove();
                    const { universities, degrees } = formData;
                    delete universities[[currentUniversityKey]];
                    delete degrees[[currentDegreeKey]];
                  }}
                />
              )}
            </div>
          );
        })}
      <button
        className="text-left font-semibold text-sm mb-4 activeState buttonHover"
        style={{ color: "#093B5DBF" }}
        type="button"
        onClick={() => {
          setNumberOfUniversityInputGroup(numberOfUniversityInputGroup + 1);
          setFormData({
            ...formData,
            degrees: {
              ...formData.degrees,
              [`degree${numberOfUniversityInputGroup}`]: "",
            },
            universities: {
              ...formData.universities,
              [`university${numberOfUniversityInputGroup}`]: "",
            },
          });
        }}
      >
        + Add University
      </button>
    </>
  );
};

export const CertificationInputContainer = ({
  numberOfCertificationInput,
  formData,
  formError,
  setFormData,
  setNumberOfCertificationInput,
}) => {
  return (
    <>
      {Array(numberOfCertificationInput)
        .fill()
        .map((_, index) => {
          const currentCertificationInputKey = `certification${index}`;
          const currentCertificationInputValue =
            formData.certifications[currentCertificationInputKey];
          return (
            <div
              key={index}
              id={`memberCertificationInput${index}`}
              className={`relative ${index >= 1 ? "animate-navbar" : ""}`}
            >
              <InputGroup
                label="Certification"
                name={currentCertificationInputKey}
                onChange={(e) =>
                  handleChangeInCertificationInput(
                    e,
                    formError,
                    setFormData,
                    formData
                  )
                }
                value={currentCertificationInputValue}
                errorMessage={
                  formError.certifications[currentCertificationInputKey]
                }
              />

              {index >= 1 && (
                <DeleteIcon
                  classNames="ml-6"
                  input={true}
                  onClick={() => {
                    document
                      .getElementById(`memberCertificationInput${index}`)
                      ?.remove();
                    const { certifications } = formData;
                    delete certifications[[currentCertificationInputKey]];
                  }}
                />
              )}
            </div>
          );
        })}

      <button
        className="text-left font-semibold text-sm mb-4 activeState buttonHover"
        style={{ color: "#093B5DBF" }}
        type="button"
        onClick={() => {
          setNumberOfCertificationInput(numberOfCertificationInput + 1);
          setFormData({
            ...formData,
            certifications: {
              ...formData.certifications,
              [`certification${numberOfCertificationInput}`]: "",
            },
          });
        }}
      >
        + Add Certification
      </button>
    </>
  );
};

export const CoachingProgramInputContainer = ({
  numberOfCoachingProgramInput,
  formData,
  formError,
  setFormData,
  setNumberOfCoachingProgramInput,
}) => {
  return (
    <>
      {Array(numberOfCoachingProgramInput)
        .fill()
        .map((_, index) => {
          const currentCoachingProgramInputKey = `coachingProgram${index}`;
          const currentCoachingProgramInputValue =
            formData.coachingPrograms[currentCoachingProgramInputKey];
          return (
            <div
              key={index}
              id={`memberCoachingProgramInput${index}`}
              className={` relative ${index >= 1 ? "animate-navbar" : ""}`}
            >
              <InputGroup
                label="Coaching Program"
                name={currentCoachingProgramInputKey}
                onChange={(e) =>
                  handleChangeInCoachingProgramInput(
                    e,
                    formError,
                    setFormData,
                    formData
                  )
                }
                value={currentCoachingProgramInputValue}
                errorMessage={
                  formError.coachingPrograms[currentCoachingProgramInputKey]
                }
              />
              {index >= 1 && (
                <DeleteIcon
                  classNames="ml-6"
                  input={true}
                  onClick={() => {
                    document
                      .getElementById(`memberCoachingProgramInput${index}`)
                      ?.remove();
                    const { coachingPrograms } = formData;
                    delete coachingPrograms[[currentCoachingProgramInputKey]];
                    setFormData({
                      ...formData,
                      coachingPrograms: {
                        ...formData.coachingPrograms,
                        [currentCoachingProgramInputKey]: false,
                      },
                    });
                  }}
                />
              )}
            </div>
          );
        })}
      <button
        className="text-left font-semibold text-sm mb-4 activeState buttonHover"
        style={{ color: "#093B5DBF" }}
        type="button"
        onClick={() => {
          setNumberOfCoachingProgramInput(numberOfCoachingProgramInput + 1);
          setFormData({
            ...formData,
            coachingPrograms: {
              ...formData.coachingPrograms,
              [`coachingProgram${numberOfCoachingProgramInput}`]: "",
            },
          });
        }}
      >
        + Add Coaching program
      </button>
    </>
  );
};
