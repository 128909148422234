import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import "../components/utilities/styles/cometChat.css";
import { scrollToTop } from "../components/utilities";
import { CalendarBackArrow } from "../components/pageComponents/MyCalendarGeneralItems";
import { getMemberDashboardDataFromLocalStorage } from "../components/utilities";
import { Scaleloader } from "../components/utilities";

const FeedSection = ({
  mentorUid,
  isAdmin,
  isMentor,
  isManager,
  managerUid,
}) => {
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const { member } = getMemberDashboardDataFromLocalStorage();
  const { getAppResourcesSuccessData } = useSelector(
    ({ homeReducer }) => homeReducer
  );

  let userId;
  if (member) userId = member?.User.uid;

  if (isAdmin) userId = "18258d46-329f-4829-890a-c18b06c00539";

  if (isMentor) userId = mentorUid;

  if (isManager) userId = managerUid;

  useEffect(() => {
    // comet chat initialising for comet chat widget

    window.CometChatWidget.init({
      appID: getAppResourcesSuccessData?.cometAppId,
      appRegion: "us",
      authKey: getAppResourcesSuccessData?.cometAuthKey,
    }).then(
      (response) => {
        console.log("Initialization completed successfully");
        //You can now call login function.
        window.CometChatWidget.login({
          uid: userId,
        }).then(
          (response) => {
            window.CometChatWidget.launch({
              widgetID: "f365349a-7b6b-43fa-ab4c-8e297dd3f5bb",
              alignment: "right", //left or right
              target: "#cometchatFeeds",
              roundedCorners: "true",
              defaultID: "meritygroup",
              defaultType: "group",
            }).then((response) => {
              setLoader(false);
            });
          },
          (error) => {
            console.log("User login failed with error:", error);
            //Check the reason for error and take appropriate action.
          }
        );
      },
      (error) => {
        console.log("Initialization failed with error:", error);
        //Check the reason for error and take appropriate action.
      }
    );
  }, [getAppResourcesSuccessData, userId]);

  return (
    <div className="min-h-screen">
      <CalendarBackArrow
        onClick={() => {
          scrollToTop("50", "16.66");
          navigate(-1);
        }}
      />
      {loader && (
        <div className="w-full h-80">
          <Scaleloader />
        </div>
      )}
      <div
        className="w-full flex justify-center mt-2"
        style={{ backgroundColor: "#f3f3f3" }}
      >
        <div className="cometchatFeeds" id="cometchatFeeds" />
      </div>
    </div>
  );
};

export default FeedSection;
