import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { homeReducer } from "./Home";
import { memberLogin } from "./MemberLogin";
import { memberDashboard } from "./MemberDashboard";
import { memberRegistration } from "./MemberRegistration";
import { mentorRegistration } from "./MentorRegistration";
import { mentorCalendar } from "./MentorCalendar";
import { memberCalendar } from "./MemberCalendar";
import { memberPlanSubscription } from "./MemberPlanSubscription";
import { newsLetterAndContactReducer } from "./NewsLetterAndContact";
import { mentorLogin } from "./MentorLogin";
import { mentorMyClients } from "./MentorMyClients";
import { memberProfile } from "./MemberProfile";
import { MentorMemberMeetings } from "./MentorMemberMeetings";
import { mentorDashboard } from "./MentorDashboard";
import { memberCometChat } from "./MemberCometChat";
import { TellAfriendReducer } from "./MemberReferal";
import { adminAuth } from "./AdminAuth";
import { adminDashboard } from "./AdminDashboard";
import { AdminPrompts } from "./AdminPrompts";
import { adminMember } from "./AdminMember";
import { adminMentor } from "./AdminMentor";
import { adminManager } from "./AdminManager";
import { adminCommunity } from "./AdminCommunity";
import { adminSettings } from "./AdminSettings";
import { mentorProfile } from "./MentorProfile";

const appReducer = combineReducers({
  homeReducer,
  memberLogin,
  memberDashboard,
  memberRegistration,
  memberPlanSubscription,
  newsLetterAndContactReducer,
  mentorLogin,
  mentorRegistration,
  mentorCalendar,
  memberCalendar,
  mentorMyClients,
  memberProfile,
  memberCometChat,
  MentorMemberMeetings,
  mentorDashboard,
  mentorProfile,
  adminAuth,
  adminDashboard,
  AdminPrompts,
  adminMember,
  adminMentor,
  adminManager,
  adminCommunity,
  adminSettings,
  TellAfriendReducer,
});

/**
 * This is root state handler
 *
 * @param state
 * @param action
 *
 */
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: [""],
};

export default persistReducer(persistConfig, rootReducer);
