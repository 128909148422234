import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ModalOuter from "./ModalOuter";
import { CardButton, removeUserDataFromLS, successToast } from "..";

export default function UnsubscribeModal(props) {
    const {
        state,
        setState,
        heading,
        buttonText,
        cancelButtonText,
        confirmClick,
        blockOutsideClick,
        cancelButtonClick,
        buttonLoader,
    } = props;

    const navigate = useNavigate();

    const { cancelSubscriptionSuccessData } = useSelector(
        ({ memberLogin }) => memberLogin
    );

    useEffect(() => {
        if (cancelSubscriptionSuccessData) {
            successToast("Unsubscribed from Merity Platform");
            removeUserDataFromLS();
            navigate("/");
        }
    }, [cancelSubscriptionSuccessData, navigate]);

    return (
        <ModalOuter
            state={state}
            setState={setState}
            blockOutsideClick={blockOutsideClick}
            classNames="p-4 text-center animate-modalInitial w-full"
        >
            <h1 className="font-medium text-lg text-left p-2 text-brandDarkBlue">
                {heading}
            </h1>
            <div className="flex justify-around mt-4 w-full ">
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        !buttonLoader && cancelButtonClick();
                    }}
                    disabled={buttonLoader}
                    className={`bg-white text-brandBlue font-semibold text-lg px-2 ${buttonLoader ? "cursor-not-allowed" : "dashboardListHover"
                        }`}
                >
                    {cancelButtonText}
                </button>
                <CardButton
                    onClick={confirmClick}
                    text={buttonText}
                    loader={buttonLoader}
                    classNames="bg-brandBlue py-1 px-4 font-semibold text-lg flex justify-between items-center mt-0"
                />
            </div>
        </ModalOuter>
    );
}
