import React from "react";

import FormBackAndContinueButton from "../MemberRegisterForms/FormBackAndContinueButton";
import { scrollToTop } from "../../utilities";
import { FullYellowSmallSun } from "../../utilities/svg";

const MentorRegistrationFeatures = ({
  changeToNextPage,
  onBackButtonClick,
  continueButtonloader,
  backButtonLoader,
  //   mentorRegisterData,
}) => {
  const handleBackButtonClick = () => {
    onBackButtonClick("personal_information_page");
    scrollToTop("50", "16.66");
  };

  window.location.hash = 'step2';

  return (
    <section className="homepageWidth md:w-70% mx-auto pt-4 mb-6 ">
      <h1
        className="font-bold text-3xl text-center sm:mt-8 my-8"
        style={{ color: "#093B5D" }}
      >
        Merity Mentor Compensation and Fees
      </h1>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          changeToNextPage("professional_information_page");
        }}
        className="px-2"
      >
        <div className="flex flex-col gap-4">
          {opportunities.map(({ title, description }, index) => (
            <div className="flex gap-2" key={index}>
              <FullYellowSmallSun
                style={{ minWidth: "34px", minHeight: "34px" }}
              />
              <p
                className="font-extrabold text-brandDarkBlue text-base 500px:text-lg"
                key={index}
              >
                <b className="font-bold">{title} </b>
                <span className="font-medium">{description}</span>
              </p>
            </div>
          ))}
        </div>

        <div className="w-96% md:w-1/2 mx-auto my-12">
          <FormBackAndContinueButton
            onBackButtonClick={handleBackButtonClick}
            actionBegin={continueButtonloader}
            backButtonLoader={backButtonLoader}
          />
        </div>
      </form>
    </section>
  );
};

export default MentorRegistrationFeatures;

const opportunities = [
  {
    title: "Mentor Minutes:",
    description:
      "Sessions to support and guide members as they work through the Merity curriculum. Mentor compensation is $50 per hour.",
  },
  {
    title: "Curriculum Support Sessions:",
    description:
      "Mentor will moderate a weekly curriculum support group on Zoom covering one Reflection topic. Each session is 45 minutes. Mentor compensation is $75 per session.",
  },
  {
    title: "Community Feed Monitor:",
    description:
      "Monitoring, commenting, and supporting conversations on the community feed. Keep the feed active and interesting by starting new discussion on the feed as necessary. Mentor compensation is $50 per hour.",
  },
  {
    title: "Premium Content:",
    description:
      "Active Merity mentors currently working with members may submit ideas for materials or events including webinars, workshops, or toolkits, at a mutually agreeable rate. Merity will market them to its members and retain a 20% commission.",
  },
  {
    title: "Application fee:",
    description:
      "$50 (applies to cover background checks and administrative set up in the system).",
  },
  {
    title: "Monthly admin fee: ",
    description:
      "$35 monthly administrative fee (begins when clients are assigned to mentors).",
  },
];
