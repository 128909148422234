import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import postalCodes from "postal-codes-js";

import { getStates } from "../../../AppRedux/actions/Home";
import {
  getUserDataFromLocalStorage,
  storeMemberDetailsInLocalStorage,
  getUserTokenFromLocalStorage,
} from "../../utilities";
import { url } from "../../utilities/generalItems";
import { getMentorDashboardDetails } from "../../../AppRedux/actions/MentorDashborad";
import { mentorBasicDetailsValidation } from "../formValidationsMentor";
import PageData from "../../utilities/content/pages.json";
import {
  infoToast,
  successToast,
  Scaleloader,
  scrollToTop,
} from "../../utilities";
import {
  getMentorInfo,
  updateMentorDetails,
} from "../../../AppRedux/actions/MentorProfile";
import {
  ProfileCoverPhotos,
  BasicInformations,
  EducationalInformation,
  ProfessionalInformation,
  SaveChangesButton,
  ProfileEditButton,
  educationalDetailsFormValidation,
  FocusAreaSelect,
  focusArea,
} from "../../pageComponents/myInfoProfileGeneralItems";
import { updateCometChatUser } from "../../../AppRedux/actions/MemberCometChat";
import { country } from "../../utilities/content/country";

const MentorProfileInfo = () => {
  const dispatch = useDispatch();

  const [focusAreaPick, setFocusAreaPick] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const { selectInput } = PageData.memberProfileInfoPageData;
  const [editIconfirstClick, setEditIconfirstClick] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [formError, setFormError] = useState({});
  const [mentorUploadedResumeName, setMentorUploadedResumeName] = useState("");
  const [others, setOthers] = useState("");
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [userAvatarLetter, setUserAvatarLetter] = useState("");
  const [fileBeforeUpload, setFileBeforeUpload] = useState({
    profilePhoto: "",
    coverPhoto: "",
  });

  const [mentorProfileData, setMentorProfileData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    profilePhoto: "null",
    coverPhoto: "null",
    university: [],
    certification: [{ certification: "" }],
    coachingProgram: [{ program: "" }],
    professionalInfo: { linkedin: "", website: "" },
    resume: "",
    statement: "",
    focusArea: [{ title: "" }],
  });

  const {
    getMentorProfileDetailsBegin,
    getMentorProfileDetailsSuccessData,
    updateMentorProfileDetailsBegin,
    updateMentorProfileDetailsSuccessData,
  } = useSelector(({ mentorProfile }) => mentorProfile);

  const { getStatesSuccessData } =
    useSelector(({ homeReducer }) => homeReducer);

  useEffect(() => {
    if (mentorProfileData.country) {
      dispatch(getStates(mentorProfileData.country));
    }
  }, [dispatch, mentorProfileData.country]);

  useEffect(() => {
    if (getStatesSuccessData) {
      selectInput[1].optionsArray = getStatesSuccessData?.states;
    }
  }, [getStatesSuccessData, selectInput]);

  // selectInput[0].optionsArray = getCountriesResourcesSuccessData?.countries;
  selectInput[0].optionsArray = country;

  useEffect(() => {
    if (getMentorProfileDetailsSuccessData) {
      setCheckedOptions([]);
      const {
        firstName,
        lastName,
        phone,
        email,
        address1,
        address2,
        country,
        state,
        city,
        zip,
        coverPhoto,
        profilePhoto,
      } = getMentorProfileDetailsSuccessData?.data;

      const {
        university,
        certification,
        coachingProgram,
        professionalInfo,
        resume,
        statement,
        focusArea,
      } = getMentorProfileDetailsSuccessData?.data?.mentorship;

      //ignoring id value in payload
      let universitiesArray = [];
      university?.map(({ university, program }) =>
        universitiesArray.push({ university: university, program: program })
      );

      let certificatesArray = [];
      certification?.map(({ certification }) =>
        certificatesArray.push({ certification: certification })
      );

      let coachingProgramsArray = [];
      coachingProgram?.map(({ program }) =>
        coachingProgramsArray.push({ program: program })
      );

      //setting checked options for focus area
      focusArea?.map(({ id, title }) => {
        setCheckedOptions((options) => [...options, id]);
        if (id === 6) {
          setOthers(title);
        }
        return "";
      });

      //comparing null for google and facebook users

      setMentorProfileData({
        firstName,
        lastName,
        email,
        phone: phone === null ? "" : phone,
        address1: address1 === null ? "" : address1,
        address2: address2 === null ? "" : address2,
        country: country === null ? "" : country,
        state: state === null ? "" : state,
        city: city === null ? "" : city,
        zip: zip === null ? "" : zip,
        coverPhoto,
        profilePhoto,
        certification: certificatesArray,
        coachingProgram: coachingProgramsArray,
        professionalInfo,
        resume,
        statement,
        university: universitiesArray,
        focusArea,
      });

      setUserAvatarLetter(getMentorProfileDetailsSuccessData?.data?.firstName);
    }
  }, [mentorProfileData, getMentorProfileDetailsSuccessData]);

  const [educationalDetailsformError, setEducationalDetailsFormError] =
    useState({
      universities: {},
      degrees: {},
      certifications: {},
      coachingPrograms: {},
    });

  useEffect(() => {
    if (updateMentorProfileDetailsSuccessData) {
      successToast("Details Updated");
      setInputDisabled(true);
      scrollToTop("50", "16.66");
      dispatch(getMentorInfo());
      dispatch(getMentorDashboardDetails());
      const { firstName, lastName, uid, email, phone, profilePhoto } =
        updateMentorProfileDetailsSuccessData.data;
      // update in cometchat
      const cometChatData = {
        uid: uid,
        name: `${firstName} ${lastName}`,
        avatar: `${url}/file?path=${profilePhoto}`,
        role: "1",
        metadata: {
          email: email,
          phoneNumber: phone,
        },
        withAuthToken: true,
      };
      dispatch(
        updateCometChatUser(
          "13f5bc6ef89bb9725ec04558defd0bb00343fcbf",
          uid,
          cometChatData
        )
      );

      storeMemberDetailsInLocalStorage({
        user: { ...updateMentorProfileDetailsSuccessData.data },
        token: getUserTokenFromLocalStorage(),
      });
    }
  }, [updateMentorProfileDetailsSuccessData, dispatch]);

  useEffect(() => {
    dispatch(getMentorInfo());
  }, [dispatch]);

  const handleInputOnchange = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z\s]/gi, "");
    }

    let sm = formError;
    delete sm[[e.target.name]];

    if (
      postalCodes.validate(mentorProfileData.country, mentorProfileData.zip) !==
      true
    ) {
      setFormError({ ...formError, ...sm, zip: "" });
    } else {
      setFormError({ ...formError, ...sm });
    }
    setMentorProfileData({
      ...mentorProfileData,
      [e.target.name]: value,
    });
  };

  //educational details onchange
  const handleInputEducationalInfo = (e, onlyLetters) => {
    let value = e.target.value;
    if (onlyLetters) {
      value = value.replace(/[^A-Za-z\s]/gi, "");
    }
    var index = e.target.name.substr(e.target.name.length - 1);
    if (e.target.name === `university${index}`) {
      let error = educationalDetailsformError.universities;
      delete error[[e.target.name]];

      setEducationalDetailsFormError({
        ...educationalDetailsformError,
        ...error,
      });
      let newArr = [...mentorProfileData?.university];
      newArr[index] = {
        university: value,
        program: mentorProfileData?.university[index].program,
      };
      setMentorProfileData({
        ...mentorProfileData,
        university: newArr,
      });
    }

    if (e.target.name === `degree${index}`) {
      let error = educationalDetailsformError.degrees;
      delete error[[e.target.name]];
      setEducationalDetailsFormError({
        ...educationalDetailsformError,
        ...error,
      });
      let newArr = [...mentorProfileData?.university];
      newArr[index] = {
        university: mentorProfileData?.university[index].university,
        program: value,
      };

      setMentorProfileData({
        ...mentorProfileData,
        university: newArr,
      });
    }

    if (e.target.name === `certification${index}`) {
      let error = educationalDetailsformError.certifications;
      delete error[[e.target.name]];
      setEducationalDetailsFormError({
        ...educationalDetailsformError,
        ...error,
      });
      let newArr = [...mentorProfileData?.certification];
      newArr[index] = {
        certification: value,
      };
      setMentorProfileData({
        ...mentorProfileData,
        certification: newArr,
      });
    }

    if (e.target.name === `coachingProgram${index}`) {
      let error = educationalDetailsformError.coachingPrograms;
      delete error[[e.target.name]];
      setEducationalDetailsFormError({
        ...educationalDetailsformError,
        ...error,
      });
      let newArr = [...mentorProfileData?.coachingProgram];
      newArr[index] = {
        program: value,
      };
      setMentorProfileData({
        ...mentorProfileData,
        coachingProgram: newArr,
      });
    }
    if (e.target.name === "linkedin") {
      let error = educationalDetailsformError;
      delete error[[e.target.name]];
      setEducationalDetailsFormError({
        ...educationalDetailsformError,
        ...error,
      });
      setMentorProfileData({
        ...mentorProfileData,
        professionalInfo: {
          linkedin: value,
          website: mentorProfileData?.professionalInfo?.website,
        },
      });
    }
    if (e.target.name === "website") {
      let error = educationalDetailsformError;
      delete error[[e.target.name]];
      setEducationalDetailsFormError({
        ...educationalDetailsformError,
        ...error,
      });
      setMentorProfileData({
        ...mentorProfileData,
        professionalInfo: {
          linkedin: mentorProfileData?.professionalInfo?.linkedin,
          website: value,
        },
      });
    }
  };

  const handleUploadFile = (e) => {
    //validation
    var type = e.target.files[0].type.split("/").pop().toLowerCase();
    if (e.target.name === "resume") {
      if (
        type !== "pdf" &&
        type !== "jpeg" &&
        type !== "jpg" &&
        type !== "png"
      ) {
        infoToast("Please select a valid file");
      } else {
        setMentorProfileData({
          ...mentorProfileData,
          [e.target.name]: e.target.files[0] ? e.target.files[0] : "",
        });
      }
    } else {
      if (type !== "jpeg" && type !== "jpg" && type !== "png") {
        infoToast("Please select a valid image");
      } else {
        setImageLoaded(true);
        setProfileLoaded(true);
        setFileBeforeUpload({
          ...fileBeforeUpload,
          [e.target.name]: URL.createObjectURL(e.target.files[0]),
        });
        setMentorProfileData({
          ...mentorProfileData,
          [e.target.name]: e.target.files[0] ? e.target.files[0] : "",
        });
      }
    }
  };

  const handleSubmit = () => {
    const validationErrors = mentorBasicDetailsValidation(mentorProfileData);
    const educationalInfoValidations = educationalDetailsFormValidation(
      mentorProfileData,
      others
    );
    if (
      Object.keys(validationErrors).length === 0 &&
      Object.keys(educationalInfoValidations).length === 0
    ) {
      const {
        university,
        certification,
        coachingProgram,
        focusArea,
        professionalInfo,
        resume,
      } = mentorProfileData;

      if (resume === "") {
        infoToast("Please upload your resume");
      } else {
        let universitiesArray = [];
        university?.map(({ university, program }) =>
          universitiesArray.push({ university: university, program: program })
        );

        let certificatesArray = [];
        certification?.map(({ certification }) =>
          certificatesArray.push({ certification: certification })
        );

        let coachingProgramsArray = [];
        coachingProgram?.map(({ program }) =>
          coachingProgramsArray.push({ program: program })
        );

        const formData = {
          ...mentorProfileData,
          university: JSON.stringify(universitiesArray),
          certification: JSON.stringify(certificatesArray),
          coachingProgram: JSON.stringify(coachingProgramsArray),
          professionalInfo: JSON.stringify(professionalInfo),
          focusArea: JSON.stringify(focusArea),
        };
        dispatch(updateMentorDetails(formData));
      }
    } else {
      setFormError({ ...formError, ...validationErrors });
      setEducationalDetailsFormError({
        ...educationalDetailsformError,
        ...educationalInfoValidations,
      });
    }
  };
  const onEditIconClick = () => {
    if (!updateMentorProfileDetailsBegin) {
      if (editIconfirstClick) {
        setFormError({});
        setInputDisabled(!inputDisabled);
        setEditIconfirstClick(!editIconfirstClick);
      } else {
        //second click
        setFormError({});
        setInputDisabled(!inputDisabled);
        dispatch(getMentorInfo());
        setEditIconfirstClick(!editIconfirstClick);
      }
    }
  };

  // console.log(mentorProfileData);
  console.log(getUserDataFromLocalStorage());

  return (
    <div className="relative 2xl:w-11/12 p-6 pt-0 min-h-80vh">
      <ProfileEditButton
        onEditIconClick={onEditIconClick}
        loader={updateMentorProfileDetailsBegin}
        inputDisabled={inputDisabled}
      />

      {!getMentorProfileDetailsBegin && mentorProfileData ? (
        <>
          <ProfileCoverPhotos
            userAvatarLetter={userAvatarLetter}
            profileData={mentorProfileData}
            fileBeforeUpload={fileBeforeUpload}
            imageLoaded={imageLoaded}
            setImageLoaded={setImageLoaded}
            profileLoaded={profileLoaded}
            setProfileLoaded={setProfileLoaded}
            getProfileBegin={getMentorProfileDetailsBegin}
            inputDisabled={inputDisabled}
            handleUploadImage={handleUploadFile}
          />

          <BasicInformations
            profileData={mentorProfileData}
            setProfileData={setMentorProfileData}
            formError={formError}
            selectInput={selectInput}
            inputDisabled={inputDisabled}
            handleInputOnchange={handleInputOnchange}
          />

          <EducationalInformation
            mentorProfileData={mentorProfileData}
            inputDisabled={inputDisabled}
            setMentorProfileData={setMentorProfileData}
            educationalDetailsformError={educationalDetailsformError}
            handleInputEducationalInfo={handleInputEducationalInfo}
          />

          <ProfessionalInformation
            mentorProfileData={mentorProfileData}
            setMentorProfileData={setMentorProfileData}
            handleInputEducationalInfo={handleInputEducationalInfo}
            educationalDetailsformError={educationalDetailsformError}
            setEducationalDetailsFormError={setEducationalDetailsFormError}
            inputDisabled={inputDisabled}
            focusAreaPick={focusAreaPick}
            setFocusAreaPick={setFocusAreaPick}
            mentorUploadedResumeName={mentorUploadedResumeName}
            setMentorUploadedResumeName={setMentorUploadedResumeName}
            handleUploadFile={handleUploadFile}
            handleInputOnchange={handleInputOnchange}
          />
          {!inputDisabled && focusAreaPick && (
            <div className="flex flex-col mt-2 w-1/2 animate-navbar">
              <FocusAreaSelect
                focusArea={focusArea}
                setMentorProfileData={setMentorProfileData}
                formError={educationalDetailsformError}
                setFormError={setEducationalDetailsFormError}
                others={others}
                setOthers={setOthers}
                checkedOptions={checkedOptions}
                setCheckedOptions={setCheckedOptions}
              />
            </div>
          )}
          <p className="text-red-600 text-base">
            {educationalDetailsformError?.focusArea}
          </p>
          {!inputDisabled && (
            <SaveChangesButton
              loader={updateMentorProfileDetailsBegin}
              onBackButtonClick={() => {
                scrollToTop("50", "16.66");
                setInputDisabled(!inputDisabled);
                setFormError({});
                setEducationalDetailsFormError({
                  ...educationalDetailsformError,
                  universities: {},
                  degrees: {},
                  certifications: {},
                  coachingPrograms: {},
                  focusArea: "",
                });
                dispatch(getMentorInfo());
                setEditIconfirstClick(!editIconfirstClick);
              }}
              classNames="w-55%"
              saveButtonClassnames="370px:text-sm"
              cancelButtonClassnames="text-sm text-brandBlue border-brandBlue hover:opacity-90 font-semibold dashboardListHover "
              onSubmitClick={handleSubmit}
            />
          )}
        </>
      ) : (
        <Scaleloader />
      )}
    </div>
  );
};

export default MentorProfileInfo;
