import { useState } from "react";
import { Link } from "react-router-dom";

const LoginCards = ({ loginPageData }) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <section className="relative">
      <div className="homepageWidth min-h-90vh py-1 370px:py-8 sm:py-0 flex flex-col items-center justify-center w-full gap-10">
        <h2 className="text-brandDarkBlue text-4xl font-extrabold">
          LOG IN AS
        </h2>
        <div className="w-full flex flex-col sm:flex-row items-center justify-center gap-8 lg:gap-16">
          {loginPageData.map(({ title, link, Icon, color }, index) => (
            <Link
              to={link}
              key={index}
              onMouseEnter={() => setOnHover(title)}
              onMouseLeave={() => setOnHover(false)}
              className={`activeState w-full 370px:w-11/12 500px:w-4/5 sm:w-auto px-8 md:px-12 lg:px-14 py-8 border-5px  hover:shadow-loginCard cursor-pointer transition75 duration-150 bg-white relative z-10 ${
                color === "yellow"
                  ? "border-brandYellow hover:bg-brandYellowTenPercentage"
                  : "border-brandBlue hover:bg-brandBlueTenPercentage"
              } ${onHover && onHover !== title ? "opacity-30" : ""}`}
            >
              <div className="my-2 flex space-y-6 sm:space-y-10 flex-col items-center justify-center">
                <img
                  style={{ width: "206px" }}
                  src={Icon}
                  alt=""
                  className="h-full"
                  loading="lazy"
                  decoding="async"
                />
                <h6 className="text-brandDarkBlue font-semibold text-2xl text-center">
                  {title}
                </h6>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LoginCards;
