export const SUBMIT_MENTOR_REGISTRATION_FORM_BEGIN =
  "SUBMIT_MENTOR_REGISTRATION_FORM_BEGIN";
export const SUBMIT_MENTOR_REGISTRATION_FORM_SUCCESS =
  "SUBMIT_MENTOR_REGISTRATION_FORM_SUCCESS";
export const SUBMIT_MENTOR_REGISTRATION_FORM_FAILURE =
  "SUBMIT_MENTOR_REGISTRATION_FORM_FAILURE";
export const RESET_SUBMIT_MENTOR_REGISTRATION_FORM =
  "RESET_SUBMIT_MENTOR_REGISTRATION_FORM";

  export const MENTOR_TOKEN_VERIFICATION_BEGIN = "MENTOR_TOKEN_VERIFICATION_BEGIN";
  export const MENTOR_TOKEN_VERIFICATION_SUCCESS = "MENTOR_TOKEN_VERIFICATION_SUCCESS";
  export const MENTOR_TOKEN_VERIFICATION_FAILURE = "MENTOR_TOKEN_VERIFICATION_FAILURE";
  export const RESET_MENTOR_TOKEN_VERIFICATION = "RESET_MENTOR_TOKEN_VERIFICATION";
