import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Cookies from 'universal-cookie';

import ModalOuter from "../../../utilities/modals/ModalOuter";
import data from "../../../utilities/content/statusAndRoleManagement.json";
import memberDashboardData from "../../../utilities/content/memberDashboard.json";
import { getMemberDashboardDetails } from "../../../../AppRedux/actions/MemberDashboard";
// import LoginToCometChatMeet from "../../../../pages/memberDashboard/cometChat/login";
import {
  getMemberDashboardDataFromLocalStorage,
  scrollToTop,
  SmallButton,
  successToast,
  UserAvatar,
} from "../../../utilities";
import {
  AppTimePickerContainer,
  CancellationPolicyContent,
  CancelMeetingTextBox,
  convertTimeTo12HoursFromat,
  ModalCancellationText,
  ModalDateContainer,
  ModalHeader,
  // utcTimeToLocalTime,
} from "../../MyCalendarGeneralItems";
import {
  cancelMemberMeeting,
  getMemberCalendarPreference,
  viewMemberMeeting,
} from "../../../../AppRedux/actions/MemberCalendar";

const LoginToCometChatMeet = React.lazy(() =>
  import("../../../../pages/memberDashboard/cometChat/login")
);

const MeetingViewModal = ({
  state,
  setState,
  dayInfo,
  meetingInfo,
  setMeetingInfo,
  calendarCurrentMonthAndYear,
}) => {
  const dispatch = useDispatch(),
    { mentor } = getMemberDashboardDataFromLocalStorage(),
    [openCancellationPolicyModal, setOpenCancellationPolicyModal] =
      useState(false),
    [meetingStarted, setMeetingStarted] = useState(false),
    [meetingEnded, setMeetingEnded] = useState(false),
    {
      cancelMemberMeetingBegin,
      cancelMemberMeetingSuccessData,
      cancelMemberMeetingFailureData,
    } = useSelector(({ memberCalendar }) => memberCalendar),
    { getMemberDashboardDetailsSuccessData } = useSelector(
      ({ memberDashboard }) => memberDashboard
    );

  const cookies   = new Cookies(null, { path: '/' });

  useEffect(() => {
    const { meetingDate, fromTime, toTime } = meetingInfo[0];
    

    const MeetingStartingTime = moment.utc(new Date(meetingDate))
      .hour(fromTime.split(":")[0])
      .minute(fromTime.split(":")[1]);

    const MeetingEndingTime = moment.utc(new Date(meetingDate))
      .hour(toTime.split(":")[0])
      .minute(toTime.split(":")[1]);

    var now = new Date();
    var durationToStartMeet = new Date(MeetingStartingTime) - now;
    var durationToEndMeet = new Date(MeetingEndingTime) - now;

    if (durationToStartMeet < 0) {
      durationToStartMeet += 86400000;
    }
    if (durationToEndMeet < 0) {
      durationToEndMeet += 86400000;
    }

    setTimeout(function () {
      setMeetingStarted(true);
    }, durationToStartMeet);

    setTimeout(function () {
      setMeetingEnded(true);
    }, durationToEndMeet);

    if (MeetingStartingTime.isBefore() && MeetingEndingTime.isAfter()) {
      setMeetingStarted(true);
    }

    // update meeting status to completed when meeting time is end
    if (MeetingEndingTime.isBefore()) {
      setMeetingEnded(true);
    }
  }, [meetingInfo]);

  useEffect(() => {
    if (cancelMemberMeetingSuccessData) {
      dispatch(getMemberDashboardDetails());
    }
    if (cancelMemberMeetingFailureData) {
      if (cancelMemberMeetingFailureData.message === "Meeting Not Found") {
        dispatch(getMemberDashboardDetails());
        // deafultToast("Meeting is already cancelled");
      }
    }
  }, [
    cancelMemberMeetingSuccessData,
    cancelMemberMeetingFailureData,
    dispatch,
  ]);

  useEffect(() => {
    if (getMemberDashboardDetailsSuccessData) {
      // close modal when user submitting
      setState(false);
      scrollToTop("50", "16.66");
      successToast("Meeting Cancelled Successfully!");
      dispatch(
        getMemberCalendarPreference(
          calendarCurrentMonthAndYear.month,
          calendarCurrentMonthAndYear.year,
          mentor.UserMentor.userId
        )
      );
      dispatch(viewMemberMeeting(1, data.MEETING.STATUS.PENDING));
    }
  }, [
    getMemberDashboardDetailsSuccessData,
    calendarCurrentMonthAndYear,
    dispatch,
    mentor,
    setState,
  ]);

  return (
    <ModalOuter
      state={state}
      setState={setState}
      style={{ minWidth: "470px" }}
      classNames="p-7 text-left animate-modalInitial"
    >
      {openCancellationPolicyModal ? (
        <CancellationPolicyContent
          goBackClick={() =>
            setOpenCancellationPolicyModal(!openCancellationPolicyModal)
          }
        />
      ) : (
        <div className="animate-dashboardInitial">
          <ModalHeader heading="Your meeting" />
          <ModalDateContainer dayInfo={dayInfo} />

          <p
            className="font-semibold text-sm mb-2"
            style={{ color: "#828282" }}
          >
            Your Meeting scheduled with
          </p>
          <div className="flex items-center gap-2 mb-6">
            <UserAvatar
              name={meetingInfo[0].userName}
              classNames="w-5 h-5 text-sm"
              profilePhoto={meetingInfo[0].mentorProfilePhoto}
              imgClassNames="w-5 h-5"
            />
            <p className="text-base font-bold" style={{ color: "#003547" }}>
              {meetingInfo[0].userName}
            </p>
          </div>

          <h1
            className=" text-base font-semibold mb-2"
            style={{ color: "#0D0D0D" }}
          >
            Scheduled Meeting time
          </h1>
          <div className="flex items-center gap-3">
            <AppTimePickerContainer classNames="w-1/2 mb-3" label="">
              <p className="font-semibold text-base">{`${convertTimeTo12HoursFromat(
                (meetingInfo[0]?.fromTime)
              )} - ${convertTimeTo12HoursFromat(
                (meetingInfo[0]?.toTime)
              )}`}</p>
            </AppTimePickerContainer>

            {meetingStarted || meetingEnded ? (
              <div className="bg-brandBlue   text-white py-1 px-2 rounded-md">
                {meetingEnded ? (
                  <>
                    <p className="animate-pulse">Meeting ended...</p>
                    <SmallButton
                      onClick={() => {
                        setState(false);
                        setMeetingInfo(false);
                      }}
                      text="Go back"
                      classNames="bg-brandBlue underline w-full"
                    />
                  </>
                ) : (
                  <>
                    <p className="animate-pulse">Meeting started...</p>
                    <Suspense fallback={<></>}>
                      <LoginToCometChatMeet
                        meetingData={meetingInfo}
                        userType="member"
                        text="Join to call"
                        classNames="underline"
                      />
                    </Suspense>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </div>

          {!meetingStarted && !meetingEnded ? (
            <>
              <ModalCancellationText
                onClick={() =>
                  setOpenCancellationPolicyModal(!openCancellationPolicyModal)
                }
              />

              <div
                className="border-b border-solid my-6"
                style={{ borderColor: "#BCBCBC" }}
              />

              <CancelMeetingTextBox
                NoClick={() => setState(false)}
                yesClick={() => {
                  const { id, slotId } = meetingInfo[0];
                  dispatch(cancelMemberMeeting(id, slotId, cookies.get('geolocation')));
                }}
                actionBegin={cancelMemberMeetingBegin}
              />
              <p className="messageContainer mt-2">
                {memberDashboardData?.cancellationPolicy[1]}
              </p>
            </>
          ) : (
            <div
              className="border-b border-solid my-6"
              style={{ borderColor: "#BCBCBC" }}
            />
          )}
        </div>
      )}
    </ModalOuter>
  );
};

export default MeetingViewModal;
