import moment from 'moment-timezone';
import TimePicker from "react-time-picker/dist/entry.nostyle";
import memberDashboardData from "../utilities/content/memberDashboard.json";
import { dayNames, monthNames } from "../utilities/Data";
import {
  CardButton,
  RcTooltip,
  ScaleloaderWhite,
  SmallButton,
} from "../utilities";
import {
  AdminCloseIcon,
  BackArrow,
  CancelTimeButton,
  ClockIcon,
  DetailsIcon,
} from "../utilities/svg";

export const functionAfterDateSet = (
  dateInfo,
  setCalendarCurrentMonthAndYear
) => {
  // checking current month & updating month number based on that
  const month = dateInfo.view.title.split(" ")[0];
  setCalendarCurrentMonthAndYear({
    month:
      month === "January"
        ? 1
        : month === "February"
          ? 2
          : month === "March"
            ? 3
            : month === "April"
              ? 4
              : month === "May"
                ? 5
                : month === "June"
                  ? 6
                  : month === "July"
                    ? 7
                    : month === "August"
                      ? 8
                      : month === "September"
                        ? 9
                        : month === "October"
                          ? 10
                          : month === "November"
                            ? 11
                            : month === "December" && 12,
    year: dateInfo.view.title.split(" ")[1],
  });
};



// day cell content (day number)
export const DayCellContent = ({ dayInfo }) => {
  const { dayNumberText, isToday } = dayInfo;

  return (
    <p
      className={`w-6 h-6 ${isToday ? "bg-brandBlue  flexCenter text-white rounded-full" : ""
        }`}
    >
      {dayNumberText < 10 ? `0${dayNumberText}` : dayNumberText}
    </p>
  );
};

// conveting 24HR -> 12HR
export const convertTimeTo12HoursFromat = (time) => {
  const hours = time?.substring(0, 2),
    minutes = time?.substring(3, 5);

  if (hours < 12) {
    return `${hours === "00" ? "12" : hours}.${minutes} AM`;
  }
  if (hours >= 12) {
    if (hours === "12") {
      return `${hours}.${minutes} PM`;
    } else if (hours === "24") {
      return `${hours - 12}.${minutes} AM`;
    } else {
      return `${hours - 12 < 10 ? `0${hours - 12}` : hours - 12}.${minutes} PM`;
    }
  }
};

// conveting UTC -> LOCAL // community
export const utcTimeToLocal = (utcTime) => {
  const now = new Date(utcTime);

  return now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false
  });
};


// conveting UTC -> LOCAL
export const local = (time) => {
  const now = new Date(time);
  return moment(now).format("HH:mm");
};

// conveting UTC -> LOCAL
export const localTime = (time) => {
  return moment(time).format("HH:mm");
};

// stays the same
export const relativeTime = (time) => {
  return moment(time).utcOffset(0).format("HH:mm");
};

// conveting UTC -> LOCAL
export const utcTimeToLocalTime = (utcTime, hour12 = false) => {
  const [hour, minute] = utcTime.split(":").map((v) => parseInt(v, 10));

  const now = new Date();
  now.setUTCHours(hour);
  now.setUTCMinutes(minute);

  return now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12,
  });
};


export const convertUTCToLocalTime = (UTCtime) => {
  // Convert the input UTC time string to a moment object
  let date = moment.tz(UTCtime, 'UTC');

  // Convert the moment object to the local time zone
  date.tz(moment.tz.guess());

  // Get the hour and minute
  let hour = date.format('HH');
  let minute = date.format('mm');

  // Return the hour and minute in the format "HH:MM"
  return hour + ":" + minute;
}


// conveting TO UTC
export const utcTime = (utcTime) => {
  const [hour, minute] = utcTime.split(":").map((v) => parseInt(v, 10));

  const now = new Date();
  now.setHours(hour);
  now.setMinutes(minute);
  now.setSeconds(0);
  now.setMilliseconds(0);
  let utcTimeConverted = now.toISOString();

  return utcTimeConverted;
};

export const utc = (time) => {
  const [hour, minute] = time.split(":").map((v) => parseInt(v, 10));

  const now = new Date();
  now.setUTCHours(hour);
  now.setUTCMinutes(minute);

  return now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

// conveting TO UTC
export const utcDateTimeString = (utcTime) => {
  const [hour, minute] = utcTime.split(":").map((v) => parseInt(v, 10));

  const now = new Date();
  now.setHours(hour);
  now.setMinutes(minute);
  let utcTimeConverted = now.toISOString();


  return utcTimeConverted;
};

// react time picker
export const AppTimePicker = ({
  value,
  label,
  onChange,
  errorMessage,
  className,
  required,
  focus,
  backgroundColor,
  onSelect,
}) => (
  <AppTimePickerContainer
    classNames={`w-1/2 ${className}`}
    label={label}
    errorMessage={errorMessage}
    backgroundColor={backgroundColor}
  >
    <TimePicker
      onChange={onChange}
      value={value}
      clearIcon={null}
      clockIcon={null}
      required={required === false ? false : true}
      hourPlaceholder="hh"
      minutePlaceholder="mm"
      amPmAriaLabel="Select AM/PM"
      onSelect={onSelect}
      autoFocus={focus}
    />
  </AppTimePickerContainer>
);

// react time picker container
export const AppTimePickerContainer = ({
  label,
  errorMessage,
  children,
  classNames,
  onClick,
  backgroundColor,
}) => (
  <div className={`${classNames} flex flex-col`} onClick={onClick}>
    <div
      className="flexCenter gap-1 rounded-7px py-3 px-1 text-base"
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "#F4F4F4",
        color: "#0D0D0D",
      }}
    >
      <p className="font-medium">{label}</p>
      {children}
    </div>
    <p className="text-red-600 text-sm px-1">{errorMessage}</p>
  </div>
);

export const ModalHeader = ({
  heading,
  description,
  closeIcon,
  setState,
  hideBorder,
  classNames,
  iconClass,
}) => (
  <>
    <h1
      className={`relative text-xl font-semibold mb-2 text-brandDarkBlue ${classNames}`}
    >
      {heading}
      {closeIcon && (
        <div
          className={`dashboardIcon absolute right-0 top-0 ${iconClass}`}
          onClick={(e) => {
            e.stopPropagation();
            setState(false);
          }}
        >
          <AdminCloseIcon className=" fill-current" />
        </div>
      )}
    </h1>
    <p className=" text-base font-semibold pt-10 lg:pt-0" style={{ color: "#565656" }}>
      {description}
    </p>
    {!hideBorder && (
      <div
        className="border-b border-solid my-3"
        style={{ borderColor: "#BCBCBC" }}
      />
    )}
  </>
);

export const ModalDateContainer = ({ dayInfo }) => {
  const dayData = dayInfo?.date
    ? {
      day: dayInfo?.date.getDay(),
      date: dayInfo?.date.getDate(),
      month: dayInfo?.date.getMonth(),
      year: dayInfo?.date.getFullYear(),
    }
    : {
      day: dayInfo?.event.start.getDay(),
      date: dayInfo?.event.start.getDate(),
      month: dayInfo?.event.start.getMonth(),
      year: dayInfo.event.start.getFullYear(),
    };

  return (
    <div className="flex flex-row items-center space-x-2 mt-3 mb-7 text-brandDarkBlue">
      <ClockIcon />
      <h1 className="font-semibold text-base items-center">
        {dayNames[dayData.day]} , {monthNames[dayData.month]}{" "} {dayData.date}{" "}
        {dayData.year}
      </h1>
    </div>
  );
};

export const ModalErrorMessage = ({ modalError, setModalError }) => (
  <div className="flex justify-between items-center p-3 bg-red-400 text-white text-sm rounded-md  mb-3 animate-mobileMenu">
    <p className="flex items-start gap-1">
      <span>⚠️</span> <span>{modalError}</span>
    </p>
    <div
      className="dashboardIcon w-6 h-6"
      style={{ minWidth: "24px", minHeight: "24px" }}
      onClick={() => setModalError("")}
    >
      <CancelTimeButton />
    </div>
  </div>
);

export const CalendarEventContent = ({ event }) => {
  const title = event?.event?.title;
  const time = event?.event?.extendedProps?.time;

  return (
    <RcTooltip
      content={
        <p className="flex flex-col gap-2">
          <span>{title}</span>
          {time && <span>{time}</span>}
        </p>
      }
    >
      <div className="max-w-min">
        <p
          className={` ${title === "Available"
            ? ""
            : "w-full overflow-hidden text-ellipsis text-10px xl:text-xs"
            }`}
        >
          {title}
        </p>
        <p
          className={` ${title === "Available" ? "" : "w-full overflow-hidden text-ellipsis"
            } text-10px xl:text-xs`}
        >
          {time}
        </p>
      </div>
    </RcTooltip>
  );
};

export const CalendarBackArrow = ({ onClick }) => (
  <div onClick={onClick} className="dashboardIcon">
    <BackArrow className="fill-current" />
  </div>
);

export const ModalCancellationText = ({ onClick, classNames }) => (
  <p
    className={` ${classNames} flex items-center gap-1 activeState buttonHover text-sm font-semibold cursor-pointer `}
    style={{ color: "#828282" }}
    onClick={onClick}
  >
    <DetailsIcon className="w-4 h-4" /> Know our{" "}
    <span className="underline ">cancellation policy</span>
  </p>
);

export const CancellationPolicyContent = ({ goBackClick }) => (
  <div className="animate-dashboardInitial">
    <ModalHeader
      heading=" Cancellation Policy"
      description="Appointment Cancellation Policy for members "
    />
    <p className="mb-3">
      {memberDashboardData.cancellationPolicy.map((text, index) => (
        <span key={index}>
          <span className="block">{text}</span>
          {index !== memberDashboardData.cancellationPolicy.length - 1 && (
            <br />
          )}
        </span>
      ))}
    </p>
    <CardButton
      text="Go Back"
      classNames="bg-brandBlue w-1/2 justify-center"
      onClick={goBackClick}
    />
  </div>
);

export const CalendarLoader = () => (
  <div
    className="absolute top-0 left-1/2 transform -translate-x-1/2 translate-y-full z-10 w-36 h-36 rounded-lg shadow-xl flexCenter"
    style={{ backgroundColor: "#007CA580" }}
  >
    <ScaleloaderWhite />
  </div>
);

export const CancelMeetingTextBox = ({ NoClick, yesClick, actionBegin }) => (
  <div className="flex items-center justify-between animate-navbar">
    <p className="font-semibold text-base">
      Do you want to cancel this meeting?
    </p>
    <div className="flex gap-3 font-semibold text-base">
      <SmallButton
        onClick={NoClick}
        text="No"
        classNames="border bg-white border-brandBlue text-brandBlue"
      />
      <SmallButton
        onClick={yesClick}
        loader={actionBegin}
        text="Yes"
        classNames="bg-brandBlue"
      />
    </div>
  </div>
);

export const showChatWidget = (cometAppId, cometAuthKey, meetingData) =>
  // comet chat initialising for comet chat widget
  window.CometChatWidget.init({
    appID: cometAppId,
    appRegion: "us",
    authKey: cometAuthKey,
  }).then(
    () => {
      window.CometChatWidget.login({
        uid: meetingData[0].mentorUid,
      }).then(
        () => {
          window.CometChatWidget.launch({
            widgetID: "5e724e26-111b-42bb-8610-4e255a89edad",
            docked: "true",
            alignment: "right", //left or right
            target: "#cometchat",
            roundedCorners: "true",
            height: "400px",
            width: "500px",
            defaultID: meetingData[0].memberUid, //default UID (user) or GUID (group) to show,
            defaultType: "user", //user or group
          });
        },
        (error) => {
          console.log("User login failed with error:", error);
        }
      );
    },
    (error) => {
      console.log("Initialization failed with error:", error);
    }
  );
