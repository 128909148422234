import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { convertFromRaw, EditorState } from "draft-js";

import { deleteCurriculum } from "../../../AppRedux/actions/AdminDashboard";
import ConfirmModal from "../../utilities/modals/ConfirmModal";
import { DeleteIcon, errorToast, successToast } from "../../utilities";
import { EditIcon } from "../../utilities/svg";
import ViewCurriculumModal from "./community/ViewCommunityModal";
import pageData from "../../utilities/content/AdminDashboard.json";
import { AdminFilterIcon } from "../../utilities/svg";
import {
  viewAllExercise,
  viewAllTakeItFurther,
} from "../../../AppRedux/actions/AdminDashboard";
import { ListHeader } from "../memberDashboard/tableComponents/ExercisesList";
import { NoListToShowContainer, PageNumbers } from "../dashboardGeneralItems";
import OutsideClickCloseContainer from "../../utilities/OutsideClickCloseContainer";
import CreateCurriculumModal from "./CreateCurriculumModal";
import { CardButton, Scaleloader } from "../../utilities";
import { AdminCardContainer, FilterDropdown } from "./AdminGeneralComponents";

const ViewCurriculumDetailsCard = () => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [exercises, setExercises] = useState("");
  const [takeItFurthers, setTakeItFurthers] = useState("");
  const [editEditorState, setEditEditorState] = useState("");
  const [editEditorStateList, setEditEditorStateList] = useState([]);
  const [detailedView, setDetailedView] = useState("");
  const [questionsView, setQuestionsView] = useState("");
  const [createCurriculumModal, setCreateCurriculumModal] = useState(false);
  const [exercisesCount, setExercisesCount] = useState(null);
  const [tifCount, setTifCount] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [dropdownSelectedItem, setDropdownSelectedItem] = useState("");
  const [filtering, setfiltering] = useState("");
  const headers = ["Question", "Question number", "Curriculum category"];
  const [viewCurriculumModal, setViewCurriculumModal] = useState(false);
  const [editCurriculumModal, setEditCurriculumModal] = useState(false);
  const [takeItFurtherClicked, setTakeItFurtherClicked] = useState(false);
  const [tifId, setTifId] = useState("");
  const [tifTitle, setTifTitle] = useState("");
  const [takeItFurther, setTakeItFurther] = useState(false);
  const [curriculumId, setCurriculumId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formData, setformData] = useState({
    title: "",
    exercise: "",
    emotional: false,
    career: false,
    passion: false,
  });

  const {
    viewAllExercisesSuccessData,
    viewAllExercisesBegin,
    viewAllTifBegin,
    viewAllTifSuccessData,
    deleteCurriculumBegin,
    deleteCurriculumFailureData,
    deleteCurriculumSuccessData,
  } = useSelector(({ adminDashboard }) => adminDashboard);

  const confirmDeleteModalData = {
    heading: "Are you sure you want to delete this curriculum ?",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();
      dispatch(deleteCurriculum(curriculumId));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowDeleteModal(false);
    },
    buttonLoader: deleteCurriculumBegin,
  };

  useEffect(() => {
    if (viewAllExercisesSuccessData) {
      setExercises(viewAllExercisesSuccessData?.data.rows);
      setExercisesCount(viewAllExercisesSuccessData?.data.count);
    }
    if (viewAllTifSuccessData) {
      setTakeItFurthers(viewAllTifSuccessData?.data.rows);
      setTifCount(viewAllTifSuccessData?.data.count);
    }
    if (deleteCurriculumSuccessData) {
      setShowDeleteModal(false);
      successToast("Details updated successfully");
      dispatch(viewAllExercise(1));
    }
    if (deleteCurriculumFailureData) {
      errorToast(deleteCurriculumFailureData?.data.message);
    }
  }, [
    dispatch,
    viewAllExercisesSuccessData,
    takeItFurtherClicked,
    viewAllTifSuccessData,
    deleteCurriculumSuccessData,
    deleteCurriculumFailureData,
  ]);

  useEffect(() => {
    if (takeItFurtherClicked) {
      if (currentPage === 1 && !filtering) {
        dispatch(viewAllTakeItFurther(1));
      } else {
        dispatch(viewAllTakeItFurther(currentPage));
      }
    } else {
      if (currentPage === 1 && !filtering) {
        dispatch(viewAllExercise(1));
      } else {
        dispatch(viewAllTakeItFurther(currentPage));
      }
    }
  }, [dispatch, takeItFurtherClicked, currentPage, filtering]);
  return (
    <AdminCardContainer>
      <div className="flex items-center justify-between pl-5">
        <p className="font-semibold text-xl text-brandDarkBlue">
          {takeItFurtherClicked
            ? "Take it Further "
            : "View Curriculum Details"}
        </p>
        <div className="flexCenter gap-6">
          <CardButton
            text={!takeItFurtherClicked ? "Take It Further" : "Reflections"}
            linearBg={true}
            classNames="mx-auto 370px:mx-0 font-semibold text-sm px-6 rounded-md"
            onClick={() => {
              setcurrentPage(1);
              setTakeItFurtherClicked(!takeItFurtherClicked);
              setfiltering("");
              setDropdownSelectedItem("");
            }}
          />
          <CardButton
            text="Create"
            linearBg={true}
            classNames="mx-auto 370px:mx-0 font-semibold text-sm px-6 rounded-md"
            onClick={() => {
              setformData({
                ...formData,
                title: "",
                exercise: "",
                emotional: false,
                career: false,
                passion: false,
              });
              setCreateCurriculumModal(true);
              setTakeItFurther(false);
              setTifId("");
            }}
          />
          <OutsideClickCloseContainer
            classNames="relative"
            setState={setFilterDropDown}
          >
            <CardButton
              text={
                <div className="flexCenter gap-2">
                  <span>
                    {dropdownSelectedItem ? dropdownSelectedItem : "Filter"}
                  </span>
                  <AdminFilterIcon />
                </div>
              }
              classNames="mx-auto 370px:mx-0 font-semibold text-sm px-6 bg-white rounded-md border border-brandBlue text-brandBlue"
              style={{
                boxShadow: "0px 13px 50px rgba(0, 0, 0, 0.03)",
              }}
              onClick={() => setFilterDropDown(!filterDropDown)}
            />
            {filterDropDown && (
              <FilterDropdown
                options={pageData.CurriculumfilterDropdown}
                handleOnClick={(text, params) => {
                  takeItFurtherClicked
                    ? dispatch(viewAllTakeItFurther(1, params))
                    : dispatch(viewAllExercise(1, params));
                  setcurrentPage(1);
                  setfiltering(params);
                  setDropdownSelectedItem(text);
                  setFilterDropDown(false);
                }}
              />
            )}
          </OutsideClickCloseContainer>
        </div>
      </div>
      <div className="border-b-2 border-faq mt-3 mb-4" />

      <div>
        <ListHeader
          headers={headers}
          gridClass="grid-cols-xlmentorMyClientsTaskHeader xl:grid-cols-mentorMyClientsTaskHeader"
        />
        {takeItFurtherClicked ? (
          <div className="member-table-row-items">
            {!viewAllTifBegin ? (
              takeItFurthers && takeItFurthers?.length > 0 ? (
                takeItFurthers?.map(
                  (
                    {
                      id,
                      exercise,
                      career,
                      emotional,
                      passion,
                      tifQuestions,
                      title,
                    },
                    index
                  ) => (
                    <div
                      className={`cursor-pointer grid-cols-xlmentorMyClientsTaskHeader xl:grid-cols-mentorMyClientsTaskHeader member-table-row-item dashboardListHover gap-1 ${
                        index === takeItFurthers.length - 1 ? "border-none" : ""
                      }`}
                      onClick={() => {
                        setViewCurriculumModal(true);
                        setDetailedView(exercise);
                        setQuestionsView(tifQuestions);
                        setTitle(title);
                      }}
                      key={id}
                    >
                      <div className="oneLineContent w-9/12  ">
                        <h1>{title}</h1>
                      </div>

                      <p className="w-1/2 text-center">{id}</p>
                      <p>
                        {career && "Career"}
                        {career && emotional && ", "}
                        {emotional && "Emotional"}
                        {emotional && passion && ", "}
                        {passion && "Passion"}
                      </p>
                      <div className="flex space-x-2 items-center">
                        <div
                          className="dashboardIcon w-6 h-6"
                          onClick={(e) => {
                            e.stopPropagation(); //for prevent click function of it's parent
                            setEditCurriculumModal(id);

                            setformData({
                              ...formData,
                              title,
                              emotional,
                              career,
                              passion,
                            });
                            setEditEditorState(
                              EditorState.createWithContent(
                                convertFromRaw(exercise)
                              )
                            );

                            const questionsArray = tifQuestions?.map(
                              ({ question }) =>
                                EditorState.createWithContent(
                                  convertFromRaw(question)
                                )
                            );

                            setEditEditorStateList(questionsArray);
                            setTitle(title);
                          }}
                        >
                          <EditIcon className="fill-current" />
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                takeItFurthers &&
                takeItFurthers.length === 0 && (
                  <NoListToShowContainer
                    text={`${
                      filtering === "" || !filtering
                        ? "Currently there are no Take it further questions!"
                        : `There is no TIFs in  
         ${
           pageData.CurriculumfilterDropdown.find(
             ({ params }) => params === filtering
           )?.text
         }`
                    }`}
                  />
                )
              )
            ) : (
              <Scaleloader />
            )}
          </div>
        ) : (
          <>
            <div className="member-table-row-items">
              {!viewAllExercisesBegin ? (
                exercises && exercises?.length > 0 ? (
                  exercises?.map(
                    (
                      {
                        id,
                        exercise,
                        career,
                        emotional,
                        passion,
                        questions,
                        title,
                        tifQuestions,
                        number,
                      },
                      index
                    ) => (
                      <div
                        className={`cursor-pointer grid-cols-xlmentorMyClientsTaskHeader xl:grid-cols-mentorMyClientsTaskHeader member-table-row-item dashboardListHover gap-1 ${
                          index === exercises.length - 1 ? "border-none" : ""
                        }`}
                        onClick={() => {
                          setViewCurriculumModal(true);
                          setDetailedView(exercise);
                          setQuestionsView(questions);
                          setTitle(title);
                        }}
                        key={id}
                      >
                        <div className="oneLineContent w-9/12  ">
                          <h1>{title}</h1>
                        </div>

                        <p className="w-1/2 text-center">{number}</p>
                        <p>
                          {career && "Career"}
                          {career && emotional && ", "}
                          {emotional && "Emotional"}
                          {emotional && passion && ", "}
                          {passion && "Passion"}
                        </p>
                        <div className="flex space-x-2 items-center">
                          <div
                            className="dashboardIcon w-6 h-6"
                            onClick={(e) => {
                              e.stopPropagation(); //for prevent click function of it's parent
                              setEditCurriculumModal(id);

                              setformData({
                                ...formData,
                                title,
                                emotional,
                                career,
                                passion,
                              });
                              setEditEditorState(
                                EditorState.createWithContent(
                                  convertFromRaw(exercise)
                                )
                              );

                              const questionsArray = questions?.map(
                                ({ question }) =>
                                  EditorState.createWithContent(
                                    convertFromRaw(question)
                                  )
                              );
                              setEditEditorStateList(questionsArray);
                              if (typeof tifQuestions[0] === "object") {
                                setTakeItFurther(true);
                                setTitle(title);
                                setTifId(tifQuestions[0]?.id);
                                setTifTitle(tifQuestions[0]?.title);
                              }
                            }}
                          >
                            <EditIcon className="fill-current" />
                          </div>
                          <DeleteIcon
                            classNames={`mr-2.5`}
                            onClick={(e) => {
                              e.stopPropagation();
                              setCurriculumId(id);
                              setShowDeleteModal(true);
                            }}
                          />
                        </div>
                      </div>
                    )
                  )
                ) : (
                  exercises &&
                  exercises.length === 0 && (
                    <NoListToShowContainer
                      text={`${
                        filtering === "" || !filtering
                          ? "Currently there are no Exercises!"
                          : `There is no exercises in  
           ${
             pageData.CurriculumfilterDropdown.find(
               ({ params }) => params === filtering
             )?.text
           }`
                      }`}
                    />
                  )
                )
              ) : (
                <Scaleloader />
              )}
            </div>
          </>
        )}
      </div>

      <PageNumbers
        count={takeItFurtherClicked ? tifCount : exercisesCount}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={takeItFurtherClicked ? viewAllTifBegin : viewAllExercisesBegin}
        limit={10}
        onPageNumberClick={(number) => {
          if (takeItFurtherClicked) {
            dispatch(viewAllTakeItFurther(number, filtering));
          } else {
            dispatch(viewAllExercise(number, filtering));
          }
        }}
      />
      {/* Create curricculum Modal */}
      {createCurriculumModal && (
        <CreateCurriculumModal
          state={createCurriculumModal}
          setState={setCreateCurriculumModal}
          formData={formData}
          setformData={setformData}
          setExercises={setExercises}
          currentPage={currentPage}
          takeItFurtherClicked={takeItFurtherClicked}
          tifId={tifId}
          setTifId={setTifId}
          tifTitle={tifTitle}
          setTifTitle={setTifTitle}
          takeItFurther={takeItFurther}
          setTakeItFurther={setTakeItFurther}
        />
      )}

      {editCurriculumModal && (
        <CreateCurriculumModal
          state={editCurriculumModal}
          setState={setEditCurriculumModal}
          formData={formData}
          setformData={setformData}
          setExercises={setExercises}
          editEditorState={editEditorState}
          editEditorStateList={editEditorStateList}
          editClicked={editCurriculumModal}
          currentPage={currentPage}
          takeItFurtherClicked={takeItFurtherClicked}
          tifId={tifId}
          setTifId={setTifId}
          tifTitle={tifTitle}
          setTifTitle={setTifTitle}
          takeItFurther={takeItFurther}
          setTakeItFurther={setTakeItFurther}
        />
      )}

      {viewCurriculumModal && (
        <ViewCurriculumModal
          title={title}
          state={viewCurriculumModal}
          setState={setViewCurriculumModal}
          detailedView={detailedView}
          questionsView={questionsView}
          editEditorState={editEditorState}
          editEditorStateList={editEditorStateList}
        />
      )}
      {showDeleteModal && (
        <OutsideClickCloseContainer setState={setShowDeleteModal}>
          <ConfirmModal
            {...confirmDeleteModalData}
            state={showDeleteModal}
            setState={setShowDeleteModal}
          />
        </OutsideClickCloseContainer>
      )}
    </AdminCardContainer>
  );
};

export default ViewCurriculumDetailsCard;
