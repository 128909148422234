import { useMemo, useEffect } from "react";
import Navbar from "../components/pageComponents/Navbar";
import FaqPageLanding from "../components/pageComponents/FaqPageLanding";
import FaqQuestionListing from "../components/pageComponents/FaqQuestionListing";
import Footer from "../components/pageComponents/footer";
import SeoData from "../components/utilities/seo/seoData.json";
import {
  ReactHelmet,
  ProgressLoader,
  progressLoaderFunction,
} from "../components/utilities";

const Faq = () => {
  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.faq} />
          <ProgressLoader />
          <Navbar whiteNavbar={false} fixedNavbar={true} />
          <FaqPageLanding />
          <FaqQuestionListing />
          <Footer />
        </>
      ),
      []
    );

  return <Content />;
};
export default Faq;
