export const GET_MENTOR_DASHBOARD_DETAILS_BEGIN =
  "GET_MENTOR_DASHBOARD_DETAILS_BEGIN";
export const GET_MENTOR_DASHBOARD_DETAILS_SUCCESS =
  "GET_MENTOR_DASHBOARD_DETAILS_SUCCESS";
export const GET_MENTOR_DASHBOARD_DETAILS_FAILURE =
  "GET_MENTOR_DASHBOARD_DETAILS_FAILURE";
export const RESET_GET_MENTOR_DASHBOARD_DETAILS =
  "RESET_GET_MENTOR_DASHBOARD_DETAILS";

export const GET_MENTOR_PROFILE_BEGIN = "GET_MENTOR_PROFILE_BEGIN";
export const GET_MENTOR_PROFILE_SUCCESS = "GET_MENTOR_PROFILE_SUCCESS";
export const GET_MENTOR_PROFILE_FAILURE = "GET_MENTOR_PROFILE_FAILURE";
export const RESET_GET_MENTOR_PROFILE = "RESET_GET_MENTOR_PROFILE";
