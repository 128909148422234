import React from "react";
import { Outlet } from "react-router-dom";

import SeoData from "../../components/utilities/seo/seoData.json";
import AdminLeftSidebar from "../../components/pageComponents/adminDashboard/AdminLeftSidebar";
import AdminNavbar from "../../components/pageComponents/adminDashboard/AdminNavbar";
import { ReactHelmet } from "../../components/utilities";

const AdminDashboardLayout = ({ children }) => {
  return (
    <div className="min-h-screen" style={{ backgroundColor: "#f3f3f3" }}>
      <ReactHelmet data={SeoData.admin} />

      <div className="flex">
        <AdminLeftSidebar />
        <div className="w-full overflow-hidden">
          <AdminNavbar />
          {children}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminDashboardLayout;
