import {
  GET_CLIENT_LIST_BEGIN,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAILURE,
  RESET_GET_CLIENT_LIST,
  GET_CLIENT_EXERCISE_BEGIN,
  GET_CLIENT_EXERCISE_SUCCESS,
  GET_CLIENT_EXERCISE_FAILURE,
  RESET_GET_CLIENT_EXERCISE,
  GET_CLIENT_ASSESSMENT_BEGIN,
  GET_CLIENT_ASSESSMENT_SUCCESS,
  GET_CLIENT_ASSESSMENT_FAILURE,
  RESET_GET_CLIENT_ASSESSMENT,
  ADD_CLIENT_NOTES_BEGIN,
  ADD_CLIENT_NOTES_SUCCESS,
  ADD_CLIENT_NOTES_FAILURE,
  RESET_ADD_CLIENT_NOTES,
  VIEW_CLIENT_NOTES_BEGIN,
  VIEW_CLIENT_NOTES_SUCCESS,
  VIEW_CLIENT_NOTES_FAILURE,
  RESET_VIEW_CLIENT_NOTES,
  EDIT_CLIENT_NOTE_BEGIN,
  EDIT_CLIENT_NOTE_SUCCESS,
  EDIT_CLIENT_NOTE_FAILURE,
  RESET_EDIT_CLIENT_NOTE,
  DELETE_CLIENT_NOTE_BEGIN,
  DELETE_CLIENT_NOTE_SUCCESS,
  DELETE_CLIENT_NOTE_FAILURE,
  RESET_DELETE_CLIENT_NOTE,
  ADD_CLIENT_TASKS_BEGIN,
  ADD_CLIENT_TASKS_SUCCESS,
  ADD_CLIENT_TASKS_FAILURE,
  RESET_ADD_CLIENT_TASKS,
  VIEW_CLIENT_TASKS_BEGIN,
  VIEW_CLIENT_TASKS_SUCCESS,
  VIEW_CLIENT_TASKS_FAILURE,
  RESET_VIEW_CLIENT_TASKS,
  ASSESSMENT_DOWNLOAD_BEGIN,
  ASSESSMENT_DOWNLOAD_SUCCESS,
  ASSESSMENT_DOWNLOAD_FAILURE,
  RESET_ASSESSMENT_DOWNLOAD,
} from "../constants/MentorMyClients";

const initialState = {
  getClientListBegin: false,
  getClientListSuccess: false,
  getClientListSuccessData: null,
  getClientListFailure: false,
  getClientListFailureData: null,

  getClientExerciseBegin: false,
  getClientExerciseSuccess: false,
  getClientExerciseSuccessData: null,
  getClientExerciseFailure: false,
  getClientExerciseFailureData: null,

  getClientAssessmentBegin: false,
  getClientAssessmentSuccess: false,
  getClientAssessmentSuccessData: null,
  getClientAssessmentFailure: false,
  getClientAssessmentFailureData: null,

  addClientNotesBegin: false,
  addClientNotesSuccess: false,
  addClientNotesSuccessData: null,
  addClientNotesFailure: false,
  addClientNotesFailureData: null,

  viewClientNotesBegin: false,
  viewClientNotesSuccess: false,
  viewClientNotesSuccessData: null,
  viewClientNotesFailure: false,
  viewClientNotesFailureData: null,

  editClientNoteBegin: false,
  editClientNoteSuccess: false,
  editClientNoteSuccessData: null,
  editClientNoteFailure: false,
  editClientNoteFailureData: null,

  deleteClientNoteBegin: false,
  deleteClientNoteSuccess: false,
  deleteClientNoteSuccessData: false,
  deleteClientNoteFailure: false,
  deleteClientNoteFailureData: false,

  addClientTaskBegin: false,
  addClientTaskSuccess: false,
  addClientTaskSuccessData: null,
  addClientTaskFailure: false,
  addClientTaskFailureData: null,

  viewClientTaskBegin: false,
  viewClientTaskSuccess: false,
  viewClientTaskSuccessData: null,
  viewClientTaskFailure: false,
  viewClientTaskFailureData: null,

  assessmentDownloadBegin: false,
  assessmentDownloadSuccess: false,
  assessmentDownloadSuccessData: null,
  assessmentDownloadFailure: false,
  assessmentDownloadFailureData: null,
};

export const mentorMyClients = (state = initialState, action) => {
  const data = action.data;
  switch (action.type) {
    //Get client list
    case GET_CLIENT_LIST_BEGIN:
      return {
        ...state,
        getClientListBegin: true,
        getClientListSuccess: false,
        getClientListSuccessData: null,
        getClientListFailure: false,
        getClientListFailureData: null,
      };
    case GET_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        getClientListBegin: false,
        getClientListSuccess: true,
        getClientListSuccessData: data,
        getClientListFailure: false,
        getClientListFailureData: null,
      };
    case GET_CLIENT_LIST_FAILURE:
      return {
        ...state,
        getClientListBegin: false,
        getClientListSuccess: false,
        getClientListSuccessData: null,
        getClientListFailure: true,
        getClientListFailureData: data,
      };
    case RESET_GET_CLIENT_LIST:
      return {
        ...state,
        getClientListBegin: false,
        getClientListSuccess: false,
        getClientListSuccessData: null,
        getClientListFailure: false,
        getClientListFailureData: null,
      };

    //Get client exercise by id
    case GET_CLIENT_EXERCISE_BEGIN:
      return {
        ...state,
        getClientExerciseBegin: true,
        getClientExerciseSuccess: false,
        getClientExerciseSuccessData: null,
        getClientExerciseFailure: false,
        getClientExerciseFailureData: null,
      };

    case GET_CLIENT_EXERCISE_SUCCESS:
      return {
        ...state,
        getClientExerciseBegin: false,
        getClientExerciseSuccess: true,
        getClientExerciseSuccessData: data,
        getClientExerciseFailure: false,
        getClientExerciseFailureData: null,
      };

    case GET_CLIENT_EXERCISE_FAILURE:
      return {
        ...state,
        getClientExerciseBegin: false,
        getClientExerciseSuccess: false,
        getClientExerciseSuccessData: null,
        getClientExerciseFailure: true,
        getClientExerciseFailureData: data,
      };

    case RESET_GET_CLIENT_EXERCISE:
      return {
        ...state,
        getClientExerciseBegin: false,
        getClientExerciseSuccess: false,
        // getClientExerciseSuccessData: null,
        getClientExerciseFailure: false,
        getClientExerciseFailureData: null,
      };

    //Get client assessment by id
    case GET_CLIENT_ASSESSMENT_BEGIN:
      return {
        ...state,
        getClientAssessmentBegin: true,
        getClientAssessmentSuccess: false,
        getClientAssessmentSuccessData: null,
        getClientAssessmentFailure: false,
        getClientAssessmentFailureData: null,
      };

    case GET_CLIENT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        getClientAssessmentBegin: false,
        getClientAssessmentSuccess: true,
        getClientAssessmentSuccessData: data,
        getClientAssessmentFailure: false,
        getClientAssessmentFailureData: null,
      };

    case GET_CLIENT_ASSESSMENT_FAILURE:
      return {
        ...state,
        getClientAssessmentBegin: false,
        getClientAssessmentSuccess: false,
        getClientAssessmentSuccessData: null,
        getClientAssessmentFailure: true,
        getClientAssessmentFailureData: data,
      };

    case RESET_GET_CLIENT_ASSESSMENT:
      return {
        ...state,
        getClientAssessmentBegin: false,
        getClientAssessmentSuccess: false,
        // getClientAssessmentSuccessData: null,
        getClientAssessmentFailure: false,
        getClientAssessmentFailureData: null,
      };

    //add client notes

    case ADD_CLIENT_NOTES_BEGIN:
      return {
        ...state,
        addClientNotesBegin: true,
        addClientNotesSuccess: false,
        addClientNotesSuccessData: null,
        addClientNotesFailure: false,
        addClientNotesFailureData: null,
      };

    case ADD_CLIENT_NOTES_SUCCESS:
      return {
        ...state,
        addClientNotesBegin: false,
        addClientNotesSuccess: true,
        addClientNotesSuccessData: data,
        addClientNotesFailure: false,
        addClientNotesFailureData: null,
      };

    case ADD_CLIENT_NOTES_FAILURE:
      return {
        ...state,
        addClientNotesBegin: false,
        addClientNotesSuccess: false,
        addClientNotesSuccessData: null,
        addClientNotesFailure: true,
        addClientNotesFailureData: data,
      };

    case RESET_ADD_CLIENT_NOTES:
      return {
        ...state,
        addClientNotesBegin: false,
        addClientNotesSuccess: false,
        addClientNotesSuccessData: null,
        addClientNotesFailure: false,
        addClientNotesFailureData: null,
      };

    //View client notes

    case VIEW_CLIENT_NOTES_BEGIN:
      return {
        ...state,
        viewClientNotesBegin: true,
        viewClientNotesSuccess: false,
        viewClientNotesSuccessData: null,
        viewClientNotesFailure: false,
        viewClientNotesFailureData: null,
      };

    case VIEW_CLIENT_NOTES_SUCCESS:
      return {
        ...state,
        viewClientNotesBegin: false,
        viewClientNotesSuccess: true,
        viewClientNotesSuccessData: data,
        viewClientNotesFailure: false,
        viewClientNotesFailureData: null,
      };

    case VIEW_CLIENT_NOTES_FAILURE:
      return {
        ...state,
        viewClientNotesBegin: false,
        viewClientNotesSuccess: false,
        viewClientNotesSuccessData: null,
        viewClientNotesFailure: true,
        viewClientNotesFailureData: data,
      };

    case RESET_VIEW_CLIENT_NOTES:
      return {
        ...state,
        viewClientNotesBegin: false,
        viewClientNotesSuccess: false,
        // viewClientNotesSuccessData: null,
        viewClientNotesFailure: false,
        viewClientNotesFailureData: null,
      };

    //edit client note

    case EDIT_CLIENT_NOTE_BEGIN:
      return {
        ...state,
        editClientNoteBegin: true,
        editClientNoteSuccess: false,
        editClientNoteSuccessData: null,
        editClientNoteFailure: false,
        editClientNoteFailureData: null,
      };

    case EDIT_CLIENT_NOTE_SUCCESS:
      return {
        ...state,
        editClientNoteBegin: false,
        editClientNoteSuccess: true,
        editClientNoteSuccessData: data,
        editClientNoteFailure: false,
        editClientNoteFailureData: null,
      };

    case EDIT_CLIENT_NOTE_FAILURE:
      return {
        ...state,
        editClientNoteBegin: false,
        editClientNoteSuccess: false,
        editClientNoteSuccessData: null,
        editClientNoteFailure: true,
        editClientNoteFailureData: data,
      };

    case RESET_EDIT_CLIENT_NOTE:
      return {
        ...state,
        editClientNoteBegin: false,
        editClientNoteSuccess: false,
        editClientNoteSuccessData: null,
        editClientNoteFailure: false,
        editClientNoteFailureData: null,
      };

    //delete client note

    case DELETE_CLIENT_NOTE_BEGIN:
      return {
        ...state,
        deleteClientNoteBegin: true,
        deleteClientNoteSuccess: false,
        deleteClientNoteSuccessData: null,
        deleteClientNoteFailure: false,
        deleteClientNoteFailureData: null,
      };
    case DELETE_CLIENT_NOTE_SUCCESS:
      return {
        ...state,
        deleteClientNoteBegin: false,
        deleteClientNoteSuccess: true,
        deleteClientNoteSuccessData: data,
        deleteClientNoteFailure: false,
        deleteClientNoteFailureData: null,
      };
    case DELETE_CLIENT_NOTE_FAILURE:
      return {
        ...state,
        deleteClientNoteBegin: false,
        deleteClientNoteSuccess: false,
        deleteClientNoteSuccessData: null,
        deleteClientNoteFailure: true,
        deleteClientNoteFailureData: data,
      };
    case RESET_DELETE_CLIENT_NOTE:
      return {
        ...state,
        deleteClientNoteBegin: false,
        deleteClientNoteSuccess: false,
        deleteClientNoteSuccessData: null,
        deleteClientNoteFailure: false,
        deleteClientNoteFailureData: null,
      };

    //add client tasks

    case ADD_CLIENT_TASKS_BEGIN:
      return {
        ...state,
        addClientTaskBegin: true,
        addClientTaskSuccess: false,
        addClientTaskSuccessData: null,
        addClientTaskFailure: false,
        addClientTaskFailureData: null,
      };

    case ADD_CLIENT_TASKS_SUCCESS:
      return {
        ...state,
        addClientTaskBegin: false,
        addClientTaskSuccess: true,
        addClientTaskSuccessData: data,
        addClientTaskFailure: false,
        addClientTaskFailureData: null,
      };
    case ADD_CLIENT_TASKS_FAILURE:
      return {
        ...state,
        addClientTaskBegin: false,
        addClientTaskSuccess: false,
        addClientTaskSuccessData: null,
        addClientTaskFailure: true,
        addClientTaskFailureData: data,
      };
    case RESET_ADD_CLIENT_TASKS:
      return {
        ...state,
        addClientTaskBegin: false,
        addClientTaskSuccess: false,
        addClientTaskSuccessData: null,
        addClientTaskFailure: false,
        addClientTaskFailureData: null,
      };

    //view client assigned task to member

    case VIEW_CLIENT_TASKS_BEGIN:
      return {
        ...state,
        viewClientTaskBegin: true,
        viewClientTaskSuccess: false,
        viewClientTaskSuccessData: null,
        viewClientTaskFailure: false,
        viewClientTaskFailureData: null,
      };

    case VIEW_CLIENT_TASKS_SUCCESS:
      return {
        ...state,
        viewClientTaskBegin: false,
        viewClientTaskSuccess: true,
        viewClientTaskSuccessData: data,
        viewClientTaskFailure: false,
        viewClientTaskFailureData: null,
      };

    case VIEW_CLIENT_TASKS_FAILURE:
      return {
        ...state,
        viewClientTaskBegin: false,
        viewClientTaskSuccess: false,
        viewClientTaskSuccessData: null,
        viewClientTaskFailure: true,
        viewClientTaskFailureData: data,
      };

    case RESET_VIEW_CLIENT_TASKS:
      return {
        ...state,
        viewClientTaskBegin: false,
        viewClientTaskSuccess: false,
        // viewClientTaskSuccessData: null,
        viewClientTaskFailure: false,
        viewClientTaskFailureData: null,
      };

    //download assessment
    case ASSESSMENT_DOWNLOAD_BEGIN:
      return {
        ...state,
        assessmentDownloadBegin: true,
        assessmentDownloadSuccess: false,
        assessmentDownloadSuccessData: null,
        assessmentDownloadFailure: false,
        assessmentDownloadFailureData: null,
      };

    case ASSESSMENT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        assessmentDownloadBegin: false,
        assessmentDownloadSuccess: true,
        assessmentDownloadSuccessData: data,
        assessmentDownloadFailure: false,
        assessmentDownloadFailureData: null,
      };

    case ASSESSMENT_DOWNLOAD_FAILURE:
      return {
        ...state,
        assessmentDownloadBegin: false,
        assessmentDownloadSuccess: false,
        assessmentDownloadSuccessData: null,
        assessmentDownloadFailure: true,
        assessmentDownloadFailureData: data,
      };

    case RESET_ASSESSMENT_DOWNLOAD:
      return {
        ...state,
        assessmentDownloadBegin: false,
        assessmentDownloadSuccess: false,
        assessmentDownloadSuccessData: null,
        assessmentDownloadFailure: false,
        assessmentDownloadFailureData: null,
      };

    default:
      return state;
  }
};
