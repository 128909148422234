import {
  UPDATE_MEETING_STATUS_BEGIN,
  UPDATE_MEETING_STATUS_SUCCESS,
  UPDATE_MEETING_STATUS_FAILURE,
  RESET_UPDATE_MEETING_STATUS,
} from "../constants/MentorMemberMeetings";

const initialState = {
  updateMeetingStatusBegin: false,
  updateMeetingStatusSuccess: false,
  updateMeetingStatusSuccessData: null,
  updateMeetingStatusFailure: false,
  updateMeetingStatusFailureData: null,
};

export const MentorMemberMeetings = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    //create comet chat user
    case UPDATE_MEETING_STATUS_BEGIN:
      return {
        ...state,
        updateMeetingStatusBegin: true,
        updateMeetingStatusSuccess: false,
        updateMeetingStatusSuccessData: null,
        updateMeetingStatusFailure: false,
        updateMeetingStatusFailureData: null,
      };
    case UPDATE_MEETING_STATUS_SUCCESS:
      return {
        ...state,
        updateMeetingStatusBegin: false,
        updateMeetingStatusSuccess: true,
        updateMeetingStatusSuccessData: data,
        updateMeetingStatusFailure: false,
        updateMeetingStatusFailureData: null,
      };
    case UPDATE_MEETING_STATUS_FAILURE:
      return {
        ...state,
        updateMeetingStatusBegin: false,
        updateMeetingStatusSuccess: false,
        updateMeetingStatusSuccessData: null,
        updateMeetingStatusFailure: true,
        updateMeetingStatusFailureData: data,
      };
    case RESET_UPDATE_MEETING_STATUS:
      return {
        ...state,
        updateMeetingStatusBegin: false,
        updateMeetingStatusSuccess: false,
        updateMeetingStatusFailure: false,
        updateMeetingStatusFailureData: null,
      };

    default:
      return state;
  }
};
