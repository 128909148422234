import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import { utcTimeToLocal } from "../../MyCalendarGeneralItems";
import {
  deleteCommunityById,
  getCommunityList,
} from "../../../../AppRedux/actions/AdminCommunity";
import { GeneralImage } from "../../../pageComponents/memberDashboard/memberProfile/ProfileInfo";
import RescheduleCommunityModal from "./RescheduleCommunityModal";
import CreateCommunityModal from "./CreateCommunityModal";
import { AdminCardContainer } from "../AdminGeneralComponents";
import { ListHeader } from "../../memberDashboard/tableComponents/ExercisesList";
import { AdminScheduleIcon } from "../../../utilities/svg";
import SingleCommunityDetailsPage from "./SingleCommunityDetailsPage";
import {
  RcTooltip,
  CardButton,
  Scaleloader,
  scrollToTop,
  DeleteIcon,
  errorToast,
  successToast,
} from "../../../utilities";
import {
  NoListToShowContainer,
  PageNumbers,
} from "../../dashboardGeneralItems";
import OutsideClickCloseContainer from "../../../utilities/OutsideClickCloseContainer";
import ConfirmModal from "../../../utilities/modals/ConfirmModal";

const ViewAdminCommunity = () => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const tab = params.getAll("tab").toString();
  const [currentPage, setcurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [communityList, setCommunityList] = useState("");
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const [createCommunityModal, setCreateCommunityModal] = useState(false);
  const [communityId, setCommunityId] = useState("");
  const [rescheduleCommunityModal, setRescheduleCommunityModal] =
    useState(false);
  const {
    getCommunityListBegin,
    getCommunityListSuccessData,
    deleteCommunityBegin,
    deleteCommunityFailureData,
    deleteCommunitySuccessData,
  } = useSelector(({ adminCommunity }) => adminCommunity);

  const confirmDeleteModalData = {
    heading: "Are you sure you want to delete this community ?",
    buttonText: "Yes",
    confirmClick: (e) => {
      e.stopPropagation();

      dispatch(deleteCommunityById(communityId));
    },
    cancelButtonText: "No",
    cancelButtonClick: () => {
      setShowDeleteModal(false);
    },
    buttonLoader: deleteCommunityBegin,
  };
  useEffect(() => {
    dispatch(getCommunityList(currentPage));
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (getCommunityListSuccessData) {
      setCommunityList(getCommunityListSuccessData?.data);
    }
  }, [getCommunityListSuccessData]);

  useEffect(() => {
    if (deleteCommunitySuccessData) {
      successToast(deleteCommunitySuccessData.message);
      setShowDeleteModal(false);
      dispatch(getCommunityList(currentPage));
    }
    if (deleteCommunityFailureData) {
      errorToast(deleteCommunityFailureData.message);
    }
  }, [
    currentPage,
    deleteCommunitySuccessData,
    deleteCommunityFailureData,
    dispatch,
  ]);
  const handleCommunityClick = (id) => {
    scrollToTop("50", "16.66");
    setParams({
      tab: "details",
      id: id,
    });
  };

  if (tab === "details") {
    return <SingleCommunityDetailsPage />;
  }

  return (
    <AdminCardContainer>
      <div className="flex items-center justify-between pl-5">
        <p className="font-semibold text-xl text-brandDarkBlue">
          Community Details
        </p>
        <div className="flexCenter gap-6">
          <CardButton
            text="Create"
            linearBg={true}
            classNames="mx-auto 370px:mx-0 font-semibold text-sm px-6 rounded-md"
            onClick={() => setCreateCommunityModal(true)}
          />
        </div>
      </div>
      <ListHeader
        headers={headers}
        gridClass="text-left grid-cols-adminCommunityListing mt-4"
      />

      {/*Community Listing */}
      <div className="member-table-row-items ">
        {!getCommunityListBegin && communityList ? (
          communityList?.rows.length !== 0 ? (
            communityList?.rows.map(
              (
                {
                  id,
                  title,
                  description,
                  onDate,
                  banner,
                  startTime,
                  endTime,
                  mentor,
                },
                index
              ) => {

                const eventStartDate = moment(new Date(onDate))
                return (
                  <div
                    key={index}
                    onClick={() => handleCommunityClick(id)}
                    className={`dashboardListHover member-table-row-item grid-cols-adminCommunityListing gap-2 font-medium text-sm xl:text-base items-center ${index === communityList?.rows.length - 1 ? "border-none" : ""}`}
                  >
                    <div className="flex  justify-start items-center space-x-2 ">
                      {banner && (
                        <div style={{ minWidth: "90px" }}>
                          <GeneralImage
                            photo={banner}
                            classNames="w-20 h-12 rounded-lg"
                            loaded={bannerLoaded}
                            setLoaded={setBannerLoaded}
                          />
                        </div>
                      )}
                      <RcTooltip content={title}>
                        <p className="oneLineContent">{title}</p>
                      </RcTooltip>
                    </div>
                    <RcTooltip content={description}>
                      <p className="oneLineContent">{description}</p>
                    </RcTooltip>
                    {mentor !== null ? (
                      <RcTooltip
                        content={mentor.firstName + " " + mentor.lastName}
                      >
                        <p className="oneLineContent">
                          {mentor.firstName} {mentor.lastName}
                        </p>
                      </RcTooltip>
                    ) : (
                      "Mentor Removed"
                    )}

                    <div className="flex justify-between items-center">
                      <RcTooltip
                        content={`${moment(onDate).format("DD-MM-YYYY")} from
                        ${utcTimeToLocal(startTime)} to  ${utcTimeToLocal(endTime)}`}
                      >
                        <p className="oneLineContent">
                          {`${moment(onDate).format("DD-MM-YYYY")} at
                        ${utcTimeToLocal(startTime)}`}
                        </p>
                      </RcTooltip>
                      {eventStartDate.isAfter() && (
                        <RescheduleIcon
                          id={id}
                          setRescheduleCommunityModal={
                            setRescheduleCommunityModal
                          }
                          setCommunityId={setCommunityId}
                        />
                      )}
                    </div>

                    <DeleteIcon
                      classNames={`mr-2.5`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCommunityId(id);
                        setShowDeleteModal(true);
                      }}
                    />
                  </div>
                );
              }
            )
          ) : (
            <NoListToShowContainer text={"There are no community events"} />
          )
        ) : (
          <Scaleloader />
        )}
      </div>
      <PageNumbers
        count={communityList?.count}
        currentPage={currentPage}
        setcurrentPage={setcurrentPage}
        loading={getCommunityListBegin}
        limit={10}
        onPageNumberClick={(number) => {
          dispatch(getCommunityList(number));
        }}
      />
      {/* Create curricculum Modal */}
      {createCommunityModal && (
        <CreateCommunityModal
          state={createCommunityModal}
          setState={setCreateCommunityModal}
          currentPage={currentPage}
        />
      )}
      {rescheduleCommunityModal && (
        <RescheduleCommunityModal
          communityId={communityId}
          communityList={communityList}
          state={rescheduleCommunityModal}
          setState={setRescheduleCommunityModal}
          currentPage={currentPage}
        />
      )}

      {showDeleteModal && (
        <OutsideClickCloseContainer setState={setShowDeleteModal}>
          <ConfirmModal
            {...confirmDeleteModalData}
            state={showDeleteModal}
            setState={setShowDeleteModal}
          />
        </OutsideClickCloseContainer>
      )}
    </AdminCardContainer>
  );
};

export default ViewAdminCommunity;

const headers = ["Title", "Description", "Mentor", "Event date"];

const RescheduleIcon = ({
  id,
  setRescheduleCommunityModal,
  setCommunityId,
}) => {
  return (
    <div
      className="text-adminIcon dashboardIcon w-6 h-6 xl:w-6 xl:h-6"
      onClick={(e) => {
        e.stopPropagation(); //for prevent click function of it's parent
        setRescheduleCommunityModal(true);
        setCommunityId(id);
      }}
    >
      <RcTooltip content={"Rescedule"}>
        <AdminScheduleIcon className="fill-current " />
      </RcTooltip>
    </div>
  );
};
