import {
  SUBMIT_MEMBER_REGISTRATION_FORM_BEGIN,
  SUBMIT_MEMBER_REGISTRATION_FORM_SUCCESS,
  SUBMIT_MEMBER_REGISTRATION_FORM_FAILURE,
  RESET_SUBMIT_MEMBER_REGISTRATION_FORM,
  RESEND_USER_REGISTER_OTP_BEGIN,
  RESEND_USER_REGISTER_OTP_SUCCESS,
  RESEND_USER_REGISTER_OTP_FAILURE,
  RESET_RESEND_USER_REGISTER_OTP,
  VERIFY_USER_REGISTER_OTP_BEGIN,
  VERIFY_USER_REGISTER_OTP_SUCCESS,
  VERIFY_USER_REGISTER_OTP_FAILURE,
  RESET_VERIFY_USER_REGISTER_OTP,
  CHECK_USER_REGISTER_EMAIL_BEGIN,
  CHECK_USER_REGISTER_EMAIL_SUCCESS,
  CHECK_USER_REGISTER_EMAIL_FAILURE,
  RESET_CHECK_USER_REGISTER_EMAIL,
  SHOW_POP_UP,
  UNSHOW_POP_UP
} from "../constants/MemberRegistration";

const initialState = {
  submitMemberRegistrationFormBegin: false,
  submitMemberRegistrationFormSuccess: false,
  submitMemberRegistrationFormSuccessData: null,
  submitMemberRegistrationFormFailure: false,
  submitMemberRegistrationFormFailureData: null,

  resendUserRegisterOtpBegin: false,
  resendUserRegisterOtpSuccess: false,
  resendUserRegisterOtpSuccessData: null,
  resendUserRegisterOtpFailure: false,
  resendUserRegisterOtpFailureData: null,

  verifyUserRegisterOtpBegin: false,
  verifyUserRegisterOtpSuccess: false,
  verifyUserRegisterOtpSuccessData: null,
  verifyUserRegisterOtpFailure: false,
  verifyUserRegisterOtpFailureData: null,

  checkUserRegisterEmailBegin: false,
  checkUserRegisterEmailSuccess: false,
  checkUserRegisterEmailSuccessData: null,
  checkUserRegisterEmailFailure: false,
  checkUserRegisterEmailFailureData: null,
  showPopup: false,
};

export const memberRegistration = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    // Submit registration form
    case SUBMIT_MEMBER_REGISTRATION_FORM_BEGIN:
      return {
        ...state,
        submitMemberRegistrationFormBegin: true,
        submitMemberRegistrationFormSuccess: false,
        submitMemberRegistrationFormSuccessData: null,
        submitMemberRegistrationFormFailure: false,
        submitMemberRegistrationFormFailureData: null,
      };
    case SUBMIT_MEMBER_REGISTRATION_FORM_SUCCESS:
      return {
        ...state,
        submitMemberRegistrationFormBegin: false,
        submitMemberRegistrationFormSuccess: true,
        submitMemberRegistrationFormSuccessData: data,
        submitMemberRegistrationFormFailure: false,
        submitMemberRegistrationFormFailureData: null,
      };
    case SUBMIT_MEMBER_REGISTRATION_FORM_FAILURE:
      return {
        ...state,
        submitMemberRegistrationFormBegin: false,
        submitMemberRegistrationFormSuccess: false,
        submitMemberRegistrationFormSuccessData: null,
        submitMemberRegistrationFormFailure: true,
        submitMemberRegistrationFormFailureData: data,
      };
    case RESET_SUBMIT_MEMBER_REGISTRATION_FORM:
      return {
        ...state,
        submitMemberRegistrationFormBegin: false,
        submitMemberRegistrationFormSuccess: false,
        submitMemberRegistrationFormSuccessData: null,
        submitMemberRegistrationFormFailure: false,
        submitMemberRegistrationFormFailureData: null,
      };

    // Resend user otp
    case RESEND_USER_REGISTER_OTP_BEGIN:
      return {
        ...state,
        resendUserRegisterOtpBegin: true,
        resendUserRegisterOtpSuccess: false,
        resendUserRegisterOtpSuccessData: null,
        resendUserRegisterOtpFailure: false,
        resendUserRegisterOtpFailureData: null,
      };
    case RESEND_USER_REGISTER_OTP_SUCCESS:
      return {
        ...state,
        resendUserRegisterOtpBegin: false,
        resendUserRegisterOtpSuccess: true,
        resendUserRegisterOtpSuccessData: data,
        resendUserRegisterOtpFailure: false,
        resendUserRegisterOtpFailureData: null,
      };
    case RESEND_USER_REGISTER_OTP_FAILURE:
      return {
        ...state,
        resendUserRegisterOtpBegin: false,
        resendUserRegisterOtpSuccess: false,
        resendUserRegisterOtpSuccessData: null,
        resendUserRegisterOtpFailure: true,
        resendUserRegisterOtpFailureData: data,
      };
    case RESET_RESEND_USER_REGISTER_OTP:
      return {
        ...state,
        resendUserRegisterOtpBegin: false,
        resendUserRegisterOtpSuccess: false,
        resendUserRegisterOtpSuccessData: null,
        resendUserRegisterOtpFailure: false,
        resendUserRegisterOtpFailureData: null,
      };

    // Verify user otp
    case VERIFY_USER_REGISTER_OTP_BEGIN:
      return {
        ...state,
        verifyUserRegisterOtpBegin: true,
        verifyUserRegisterOtpSuccess: false,
        verifyUserRegisterOtpSuccessData: null,
        verifyUserRegisterOtpFailure: false,
        verifyUserRegisterOtpFailureData: null,
      };
    case VERIFY_USER_REGISTER_OTP_SUCCESS:
      return {
        ...state,
        verifyUserRegisterOtpBegin: false,
        verifyUserRegisterOtpSuccess: true,
        verifyUserRegisterOtpSuccessData: data,
        verifyUserRegisterOtpFailure: false,
        verifyUserRegisterOtpFailureData: null,
      };
    case VERIFY_USER_REGISTER_OTP_FAILURE:
      return {
        ...state,
        verifyUserRegisterOtpBegin: false,
        verifyUserRegisterOtpSuccess: false,
        verifyUserRegisterOtpSuccessData: null,
        verifyUserRegisterOtpFailure: true,
        verifyUserRegisterOtpFailureData: data,
      };
    case RESET_VERIFY_USER_REGISTER_OTP:
      return {
        ...state,
        verifyUserRegisterOtpBegin: false,
        verifyUserRegisterOtpSuccess: false,
        verifyUserRegisterOtpSuccessData: null,
        verifyUserRegisterOtpFailure: false,
        verifyUserRegisterOtpFailureData: null,
      };

    //check for email existance
    case CHECK_USER_REGISTER_EMAIL_BEGIN:
      return {
        ...state,
        checkUserRegisterEmailBegin: true,
        checkUserRegisterEmailSuccess: false,
        checkUserRegisterEmailSuccessData: null,
        checkUserRegisterEmailFailure: false,
        checkUserRegisterEmailFailureData: null,
      };

    case CHECK_USER_REGISTER_EMAIL_SUCCESS:
      return {
        ...state,
        checkUserRegisterEmailBegin: false,
        checkUserRegisterEmailSuccess: true,
        checkUserRegisterEmailSuccessData: data,
        checkUserRegisterEmailFailure: false,
        checkUserRegisterEmailFailureData: null,
      };

    case CHECK_USER_REGISTER_EMAIL_FAILURE:
      return {
        ...state,
        checkUserRegisterEmailBegin: false,
        checkUserRegisterEmailSuccess: false,
        checkUserRegisterEmailSuccessData: null,
        checkUserRegisterEmailFailure: true,
        checkUserRegisterEmailFailureData: data,
      };

    case RESET_CHECK_USER_REGISTER_EMAIL:
      return {
        ...state,
        checkUserRegisterEmailBegin: false,
        checkUserRegisterEmailSuccess: false,
        checkUserRegisterEmailSuccessData: null,
        checkUserRegisterEmailFailure: false,
        checkUserRegisterEmailFailureData: null,
      };
    case SHOW_POP_UP:
      return {
        ...state,
        showPopup: true,
      };
    case UNSHOW_POP_UP:
      return {
        ...state,
        showPopup: false,
      };
    default:
      return state;
  }
};
