export const SUBMIT_MEMBER_LOGIN_FORM_BEGIN = "SUBMIT_MEMBER_LOGIN_FORM_BEGIN";
export const SUBMIT_MEMBER_LOGIN_FORM_SUCCESS =
  "SUBMIT_MEMBER_LOGIN_FORM_SUCCESS";
export const SUBMIT_MEMBER_LOGIN_FORM_FAILURE =
  "SUBMIT_MEMBER_LOGIN_FORM_FAILURE";
export const RESET_SUBMIT_MEMBER_LOGIN_FORM = "RESET_SUBMIT_MEMBER_LOGIN_FORM";

export const SEND_MEMBER_LOGIN_OTP_BEGIN = "SEND_MEMBER_LOGIN_OTP_BEGIN";
export const SEND_MEMBER_LOGIN_OTP_SUCCESS = "SEND_MEMBER_LOGIN_OTP_SUCCESS";
export const SEND_MEMBER_LOGIN_OTP_FAILURE = "SEND_MEMBER_LOGIN_OTP_FAILURE";
export const RESET_SEND_MEMBER_LOGIN_OTP = "RESET_SEND_MEMBER_LOGIN_OTP";

export const MEMBER_GOOGLE_LOGIN_BEGIN = "MEMBER_GOOGLE_LOGIN_BEGIN";
export const MEMBER_GOOGLE_LOGIN_SUCCESS = "MEMBER_GOOGLE_LOGIN_SUCCESS";
export const MEMBER_GOOGLE_LOGIN_FAILURE = "MEMBER_GOOGLE_LOGIN_FAILURE";
export const RESET_MEMBER_GOOGLE_LOGIN = "RESET_MEMBER_GOOGLE_LOGIN";

export const VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_BEGIN =
  "VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_BEGIN";
export const VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_SUCCESS =
  "VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_OTP_SUCCESS";
export const VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP_FAILURE =
  "VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP_FAILURE";
export const RESET_VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP =
  "RESET_VERIFY_MEMBER_LOGIN_FORGOT_PASSWORD_LOGIN_OTP";

export const SET_NEW_PASSWORD_BEGIN = "SET_NEW_PASSWORD_BEGIN";
export const SET_NEW_PASSWORD_SUCCESS = "SET_NEW_PASSWORD_SUCCESS";
export const SET_NEW_PASSWORD_FAILURE = "SET_NEW_PASSWORD_FAILURE";
export const RESET_SET_NEW_PASSWORD = "RESET_SET_NEW_PASSWORD";

export const MEMBER_FACEBOOK_LOGIN_BEGIN = "MEMBER_FACEBOOK_LOGIN_BEGIN";
export const MEMBER_FACEBOOK_LOGIN_SUCCESS = "MEMBER_FACEBOOK_LOGIN_SUCCESS";
export const MEMBER_FACEBOOK_LOGIN_FAILURE = "MEMBER_FACEBOOK_LOGIN_FAILURE";
export const RESET_MEMBER_FACEBOOK_LOGIN = "RESET_MEMBER_FACEBOOK_LOGIN";

export const SUBMIT_USER_LOGOUT_BEGIN = "SUBMIT_USER_LOGOUT_BEGIN";
export const SUBMIT_USER_LOGOUT_SUCCESS = "SUBMIT_USER_LOGOUT_SUCCESS";
export const SUBMIT_USER_LOGOUT_FAILURE = "SUBMIT_USER_LOGOUT_FAILURE";
export const RESET_SUBMIT_USER_LOGOUT = "RESET_SUBMIT_USER_LOGOUT";

export const CANCEL_SUBSCRIPTION_BEGIN = "CANCEL_SUBSCRIPTION_BEGIN";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";
export const RESET_CANCEL_SUBSCRIPTION = "RESET_CANCEL_SUBSCRIPTION";
