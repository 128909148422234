export const GET_MENTOR_PROFILE_DETAILS_BEGIN =
  "GET_MENTOR_PROFILE_DETAILS_BEGIN";
export const GET_MENTOR_PROFILE_DETAILS_SUCCESS =
  "GET_MENTOR_PROFILE_DETAILS_SUCCESS";
export const GET_MENTOR_PROFILE_DETAILS_FAILURE =
  "GET_MENTOR_PROFILE_DETAILS_FAILURE";
export const RESET_GET_MENTOR_PROFILE_DETAILS =
  "RESET_GET_MENTOR_PROFILE_DETAILS";

export const UPDATE_MENTOR_PROFILE_DETAILS_BEGIN =
  "UPDATE_MENTOR_PROFILE_DETAILS_BEGIN";
export const UPDATE_MENTOR_PROFILE_DETAILS_SUCCESS =
  "UPDATE_MENTOR_PROFILE_DETAILS_SUCCESS";
export const UPDATE_MENTOR_PROFILE_DETAILS_FAILURE =
  "UPDATE_MENTOR_PROFILE_DETAILS_FAILURE";
export const RESET_UPDATE_MENTOR_PROFILE_DETAILS =
  "RESET_UPDATE_MENTOR_PROFILE_DETAILS";
