import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { scrollToTop, NameLoader } from "../../utilities";
import { PageBackArrow } from "../dashboardGeneralItems";
import { useEffect, useState } from "react";
import { getClientList } from "../../../AppRedux/actions/MentorMyClients";

const MainHeader = ({ title }) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [clientName, setClientName] = useState("");
  const id = params.getAll("id").toString();
  const tab = params.getAll("id").toString();
  const action = params.getAll("action").toString();

  const { getClientListSuccessData, getClientListBegin } = useSelector(
    ({ mentorMyClients }) => mentorMyClients
  );
  useEffect(() => {
    if (getClientListSuccessData) {
      const client = getClientListSuccessData?.data.rows.find(
        ({ UserMember }) =>
          UserMember !== null && UserMember.User.id === parseInt(id)
      ).UserMember.User;
      setClientName(client.firstName + " " + client.lastName);
    }
  }, [getClientListSuccessData, id]);
  useEffect(() => {
    if (clientName === "") {
      dispatch(getClientList());
    }
  }, [clientName, dispatch]);

  return (
    <div className=" flex items-center gap-5 rounded-10px">
      <PageBackArrow
        onClick={() => {
          scrollToTop("50", "16.66");
          //if all parameters are there then only navigate to previous page else navigate to main page
          tab && id && action ? navigate(-1) : navigate("/mentor/dashboard");
        }}
      />
      <div>
        <div className="text-brandDarkBlue text-base font-semibold">
          <div className="flex items-center">
            {title === "Your Notes" ||
            title === "Create Exercise" ||
            title === "edit" ? (
              <div style={{ color: "#C0C0C0" }}>
                {!getClientListBegin ? clientName : <NameLoader />}
              </div>
            ) : (
              <span style={{ color: "#C0C0C0" }}>My Clients</span>
            )}
            <span className="mx-3">&gt;</span>
            {title === "My Clients" ? (
              <>{!getClientListBegin ? clientName : <NameLoader />}</>
            ) : (
              <>
                {action === "add-notes"
                  ? "Add Notes"
                  : action === "assign-task"
                  ? "Assign Task"
                  : action === "view-exercise"
                  ? "Curriculum"
                  : action === "view-task"
                  ? "Tasks"
                  : action === "edit"
                  ? "Edit note"
                  : action === "view-note" && "View note"}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
