import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import PageData from "../../../utilities/content/memberDashboard.json";
import mentorPageData from "../../../utilities/content/mentorDashboard.json";

const Navbar = ({ selectedTab, dashboardData, type }) => {
  const [params, setParams] = useSearchParams();
  const { navbarContent } =
    type === "mentor"
      ? mentorPageData.mentorProfileSubNavbarData
      : PageData.memberProfileSubNavbarData;
  const tab = params.getAll("tab").toString();

  useEffect(() => {
    const registrationType = dashboardData?.member?.User.registrationType;
    if (registrationType === 2 || registrationType === 3) {
      navbarContent[2].header = "";
      navbarContent[2].tab = "";
    }
    if (registrationType === 1) {
      navbarContent[2].header = "Manage Password";
      navbarContent[2].tab = "manage-password";
    }

    if (
      tab === "manage-password" &&
      (registrationType === 2 || registrationType === 3)
    ) {
      setParams({
        tab: "my-info",
      });
    }
  }, [navbarContent, dashboardData, setParams, tab]);

  return (
    <div className="overflow-x-auto 500px:overflow-x-hidden ">
      <div
        className=" rounded-lg text-brandDarkBlue flex justify-start bg-white pt-8 px-8 "
        style={{
          boxShadow: "0px 13px 50px rgba(0, 0, 0, 0.03)",
          minWidth: "580px",
        }}
      >
        {navbarContent.map(({ header, tab }, index) => (
          <div key={index}>
            <Link
              to={
                type === "mentor"
                  ? `/mentor/dashboard/profile?tab=${tab}`
                  : `/member/dashboard/profile?tab=${tab}`
              }
              className={`font-medium text-lg mx-4 activeState transition duration-100 ease ${
                selectedTab === tab ? " font-bold brandBlueText" : "blueText"
              }`}
            >
              {header}
            </Link>
            {selectedTab === tab && (
              <div
                className="rounded-sm mt-2"
                style={{
                  width: "100%",
                  borderColor: "#007CA5",
                  backgroundColor: "#007CA5",
                  borderWidth: "1.5px",
                }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
