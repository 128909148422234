import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CardButton } from "../../utilities";
import AdminSettingsCard from "./AdminSettingsCard";
import ManagePasswordTab from "../../pageComponents/memberDashboard/memberProfile/ManagePasswordTab";
import ConfirmModal from "../../utilities/modals/ConfirmModal";
import { submitLogout } from "../../../AppRedux/actions/MemberLogin";
import AdminCreatePromocode from "./AdminCreatePromocode";

const AdminManageSettings = () => {
  const dispatch = useDispatch();
  const [tabClicked, setTabClicked] = useState("info");
  const [showLogoutModal, setshowLogoutModal] = useState(false);
  const { submitUserLogoutBegin } = useSelector(
    ({ memberLogin }) => memberLogin
  );

  const logoutModalData = {
    heading: "Do you want to logout from Merity platform ?",
    buttonText: "Yes",
    confirmClick: () => {
      dispatch(submitLogout());
    },
    cancelButtonText: "Cancel",
    cancelButtonClick: () => {
      setshowLogoutModal(false);
      setTabClicked("info");
    },
    buttonLoader: submitUserLogoutBegin,
  };

  return (
    <div className="overflow-x-scroll 950px:overflow-x-auto m-5 rounded-10px lg:px-8 min-h-80vh relative pb-14">
      <div style={{ minWidth: "650px" }}>
        <p className="font-semibold text-xl text-brandDarkBlue pl-5">
          Admin Settings
        </p>
        <div className="flex items-center justify-start mt-4 pl-5">
          <div className="flexCenter gap-6">
            <CardButton
              text="Manage Info"
              linearBg={tabClicked === "info" ? true : false}
              classNames={`${
                tabClicked === "info" ? "text-white" : "text-brandBlue"
              } mx-auto 370px:mx-0 font-semibold text-sm px-6 bg-white rounded-md border border-brandBlue dashboardHover dashboardActive`}
              style={{
                boxShadow: "0px 13px 50px rgba(0, 0, 0, 0.03)",
              }}
              onClick={() => setTabClicked("info")}
            />
            <CardButton
              text="Manage Password"
              linearBg={tabClicked === "password" ? true : false}
              classNames={`${
                tabClicked === "password" ? "text-white" : "text-brandBlue"
              } mx-auto 370px:mx-0 font-semibold text-sm px-6 bg-white rounded-md border border-brandBlue dashboardHover dashboardActive`}
              style={{
                boxShadow: "0px 13px 50px rgba(0, 0, 0, 0.03)",
              }}
              onClick={() => setTabClicked("password")}
            />
            <CardButton
              text="Activation Code"
              linearBg={tabClicked === "promo" ? true : false}
              classNames={`${
                tabClicked === "promo" ? "text-white" : "text-brandBlue"
              } mx-auto 370px:mx-0 font-semibold text-sm px-6 bg-white rounded-md border border-brandBlue dashboardHover dashboardActive`}
              style={{
                boxShadow: "0px 13px 50px rgba(0, 0, 0, 0.03)",
              }}
              onClick={() => {
                setTabClicked("promo");
              }}
            />
            <CardButton
              text="Logout"
              linearBg={tabClicked === "logout" ? true : false}
              classNames={`${
                tabClicked === "logout" ? "text-white" : "text-brandBlue"
              } mx-auto 370px:mx-0 font-semibold text-sm px-6 bg-white rounded-md border border-brandBlue dashboardHover dashboardActive`}
              style={{
                boxShadow: "0px 13px 50px rgba(0, 0, 0, 0.03)",
              }}
              onClick={() => {
                setTabClicked("logout");
                setshowLogoutModal(true);
              }}
            />
          </div>
        </div>
        {tabClicked === "password" ? (
          <ManagePasswordTab type="admin" />
        ) : tabClicked === "info" ? (
          <AdminSettingsCard />
        ) : tabClicked === "promo" ? (
          <AdminCreatePromocode />
        ) : (
          ""
        )}
      </div>
      {showLogoutModal && (
        <ConfirmModal
          {...logoutModalData}
          state={showLogoutModal}
          setState={setshowLogoutModal}
        />
      )}
    </div>
  );
};

export default AdminManageSettings;
