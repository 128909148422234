export const GET_MEMBERS_LIST_BEGIN = "GET_MEMBERS_LIST_BEGIN";
export const GET_MEMBERS_LIST_SUCCESS = "GET_MEMBERS_LIST_SUCCESS";
export const GET_MEMBERS_LIST_FAILURE = "GET_MEMBERS_LIST_FAILURE";
export const RESET_GET_MEMBERS_LIST = "RESET_GET_MEMBERS_LIST";

export const GET_SINGLE_MEMBER_DETAILS_BEGIN =
  "GET_SINGLE_MEMBER_DETAILS_BEGIN";
export const GET_SINGLE_MEMBER_DETAILS_SUCCESS =
  "GET_SINGLE_MEMBER_DETAILS_SUCCESS";
export const GET_SINGLE_MEMBER_DETAILS_FAILURE =
  "GET_SINGLE_MEMBER_DETAILS_FAILURE";
export const RESET_GET_SINGLE_MEMBER_DETAILS =
  "RESET_GET_SINGLE_MEMBER_DETAILS";

export const GET_MEMBER_PROFILE_DETAILS_BEGIN =
  "GET_MEMBER_PROFILE_DETAILS_BEGIN";
export const GET_MEMBER_PROFILE_DETAILS_SUCCESS =
  "GET_MEMBER_PROFILE_DETAILS_SUCCESS";
export const GET_MEMBER_PROFILE_DETAILS_FAILURE =
  "GET_MEMBER_PROFILE_DETAILS_FAILURE";
export const RESET_GET_MEMBER_PROFILE_DETAILS =
  "RESET_GET_MEMBER_PROFILE_DETAILS";

export const GET_SINGLE_MEMBER_PAYMENT_DETAILS_BEGIN =
  "GET_SINGLE_MEMBER_PAYMENT_DETAILS_BEGIN";
export const GET_SINGLE_MEMBER_PAYMENT_DETAILS_SUCCESS =
  "GET_SINGLE_MEMBER_PAYMENT_DETAILS_SUCCESS";
export const GET_SINGLE_MEMBER_PAYMENT_DETAILS_FAILURE =
  "GET_SINGLE_MEMBER_PAYMENT_DETAILS_FAILURE";
export const RESET_GET_SINGLE_MEMBER_PAYMENT_DETAILS =
  "RESET_GET_SINGLE_MEMBER_PAYMENT_DETAILS";

export const SEND_MEMBER_ALERT_BEGIN = "SEND_MEMBER_ALERT_BEGIN";
export const SEND_MEMBER_ALERT_SUCCESS = "SEND_MEMBER_ALERT_SUCCESS";
export const SEND_MEMBER_ALERT_FAILURE = "SEND_MEMBER_ALERT_FAILURE";
export const RESET_SEND_MEMBER_ALERT = "RESET_SEND_MEMBER_ALERT";

export const BLOCK_MEMBER_BEGIN = "BLOCK_MEMBER_BEGIN";
export const BLOCK_MEMBER_SUCCESS = "BLOCK_MEMBER_SUCCESS";
export const BLOCK_MEMBER_FAILURE = "BLOCK_MEMBER_FAILURE";
export const RESET_BLOCK_MEMBER = "RESET_BLOCK_MEMBER_BEGIN";

export const UNBLOCK_MEMBER_BEGIN = "UNBLOCK_MEMBER_BEGIN";
export const UNBLOCK_MEMBER_SUCCESS = "UNBLOCK_MEMBER_SUCCESS";
export const UNBLOCK_MEMBER_FAILURE = "UNBLOCK_MEMBER_FAILURE";
export const RESET_UNBLOCK_MEMBER = "RESET_UNBLOCK_MEMBER";

export const ASSIGN_MENTOR_BEGIN = "ASSIGN_MENTOR_BEGIN";
export const ASSIGN_MENTOR_SUCCESS = "ASSIGN_MENTOR_SUCCESS";
export const ASSIGN_MENTOR_FAILURE = "ASSIGN_MENTOR_FAILURE";
export const RESET_ASSIGN_MENTOR = "RESET_ASSIGN_MENTOR";

export const DELETE_MEMBER_BYID_BEGIN = "DELETE_MEMBER_BYID_BEGIN";
export const DELETE_MEMBER_BYID_SUCCESS = "DELETE_MEMBER_BYID_SUCCESS";
export const DELETE_MEMBER_BYID_FAILURE = "DELETE_MEMBER_BYID_FAILURE";
export const RESET_DELETE_MEMBER_BYID = "RESET_DELETE_MEMBER_BYID";
