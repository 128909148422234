import { UserAvatar } from "../../../utilities";

const MemberMentorDetailsPage = ({ MemberData }) => {
  const details = [
    {
      heading: "Name",
      data: MemberData?.User?.firstName + " " + MemberData?.User?.lastName,
    },
    {
      heading: "Country",
      data: MemberData.User.country
        ? MemberData?.User?.country
        : "Not available",
    },
    {
      heading: "State",
      data: MemberData.User.state ? MemberData?.User?.state : "Not available",
    },
    {
      heading: "City",
      data: MemberData.User.city ? MemberData?.User?.city : "Not available",
    },
    {
      heading: "Gender",
      data: MemberData.User.gender ? MemberData?.User?.gender : "Not available",
    },
  ];
  return (
    <div className="relative w-96% pt-7 pb-8 mx-4 500px:pb-16 flex flex-col">
      <div className="ml-4">
        <UserAvatar
          name={MemberData?.User?.firstName}
          classNames=" w-20 h-20 text-4xl "
          style={{
            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.1)",
            border: "2px solid #FFFFFF",
          }}
          profilePhoto={MemberData?.User?.profilePhoto}
          imgClassNames="w-32 h-32 "
        />
      </div>
      <div className="px-4">
        <div className="mt-10 p-4 grid grid-cols-3 gap-4 gap-y-10">
          {details.map(({ heading, data }, index) => (
            <div key={index}>
              <h1 className="text-sm mb-2" style={{ color: "#0000007a" }}>
                {heading}
              </h1>
              <div className=" flex space-x-4">
                {" "}
                <h1 className="text-xl font-medium">{data}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default MemberMentorDetailsPage;
