import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import SeoData from "../../../utilities/seo/seoData.json";
import { GeneralImage } from "../../../pageComponents/memberDashboard/memberProfile/ProfileInfo";
import { utcTimeToLocal } from "../../MyCalendarGeneralItems";
import { getSingleCommunityDetails } from "../../../../AppRedux/actions/AdminCommunity";
import { CopyIcon } from "../../../utilities/svg";
import { PageBackArrow } from "../../dashboardGeneralItems";
import {
  RcTooltip,
  Scaleloader,
  successToast,
  scrollToTop,
  ReactHelmet,
} from "../../../utilities";

const SingleCommunityDetailsPage = ({ type }) => {
  const dispatch = useDispatch();
  const [params, setParams] = useSearchParams();
  const communityId = params.getAll("id").toString();
  const [bannerLoaded, setBannerLoaded] = useState(false);
  const [communityDetails, setCommunityDetails] = useState("");
  const [showZoomLink, setShowZoomLink] = useState(false);

  const {
    getSingleCommunityDetailsBegin,
    getSingleCommunityDetailsSuccessData,
  } = useSelector(({ adminCommunity }) => adminCommunity);

  useEffect(() => {
    dispatch(getSingleCommunityDetails(communityId));
  }, [dispatch, communityId]);

  useEffect(() => {
    if (getSingleCommunityDetailsSuccessData) {
      setCommunityDetails(getSingleCommunityDetailsSuccessData?.data);
      const { onDate, startTime, endTime } = getSingleCommunityDetailsSuccessData?.data;

      if (moment(new Date(onDate)).isAfter() || (moment(new Date(startTime)).isBefore() && moment(new Date(endTime)).isAfter())) {
        setShowZoomLink(true);
      } else {
        setShowZoomLink(false);
      }
    }
  }, [getSingleCommunityDetailsSuccessData]);

  return (
    <div
      className={` ${type === "member"
        ? "bg-white rounded-10px p-4 min-h-80vh w-full shadow-memberDashboard"
        : "overflow-x-scroll 950px:overflow-x-auto bg-white rounded-10px shadow-memberDashboard flex-auto p-8 min-h-90vh m-10"
        }`}
    >
      <ReactHelmet
        data={
          communityDetails
            ? {
              title: `${communityDetails?.title} | Merity`,
              description: "description",
            }
            : SeoData.memberCommunity
        }
      />
      <div style={type !== "member" ? { minWidth: "600px" } : {}}>
        {!getSingleCommunityDetailsBegin && communityDetails ? (
          <>
            {type === "member" ? (
              <>
                <div className="flex items-center gap-5 rounded-10px shadow-memberDashboard">
                  <PageBackArrow
                    onClick={() => {
                      scrollToTop("50", "16.66");
                      setParams({
                        tab: "community",
                      });
                    }}
                  />
                  <p className="text-black text-base font-semibold">
                    <span style={{ color: "#C0C0C0" }}>Community</span>
                    <span className="mx-3">&gt;</span>
                    {communityDetails?.title}
                  </p>
                </div>
                <h1
                  style={{ color: "#001C35" }}
                  className="font-bold text-2xl mt-10"
                >
                  Community
                </h1>
              </>
            ) : (
              <>
                <h1
                  className="font-medium text-lg"
                  style={{ color: "#8F8F8F" }}
                >
                  Assigned Mentor
                </h1>
                <h1 className="font-bold text-xl text-brandDarkBlue">
                  {`${communityDetails?.mentor?.firstName} ${communityDetails?.mentor?.lastName}`}
                </h1>
              </>
            )}
            <div
              className={` ${type === "member"
                ? "grid 950px:grid-cols-2"
                : "grid 950px:grid-cols-2"
                }
                  mt-8 sm:mt-10 gap-3 sm:gap-6 px-0 py-3 370px:py-0 370px:p-3 sm:p-2 min-h-400px
                `}
            >
              <div className="mr-6">
                <GeneralImage
                  style={
                    type === "member"
                      ? { width: "591px", height: "535px" }
                      : { width: "450px", height: "250px" }
                  }
                  photo={communityDetails?.banner}
                  classNames="rounded-lg object-fill"
                  loaded={bannerLoaded}
                  setLoaded={setBannerLoaded}
                ></GeneralImage>
                {!bannerLoaded && <Scaleloader />}
              </div>
              <div
                className={`min-h-full mb-7 950px:mb-0 relative flex flex-col pt-2  ${type === "member" ? "950px:border-l 950px:pl-10" : ""
                  }  `}
                style={{ borderColor: "#E9E9E9" }}
              >
                <h1 className="font-bold text-2xl my-2">
                  {communityDetails?.title}
                </h1>

                <p className="text-sm font-medium" style={{ color: "#616161" }}>

                  {new Date(communityDetails.onDate).toLocaleDateString(
                    "en-US"
                  )}
                  {/* <span>
                    {` ${convertTimeTo12HoursFromat(
                      communityDetails?.onTime
                    )} - ${convertTimeTo12HoursFromat(
                      communityDetails?.toTime
                    )} `}
                  </span> */}
                  <span>
                    {` ${utcTimeToLocal(
                      communityDetails?.startTime
                    )} - ${utcTimeToLocal(
                      communityDetails?.endTime
                    )} `}

                  </span>
                </p>
                <p
                  className="mt-4 text-base font-medium mb-10  w-96% h-1/2"
                  style={{ color: "#181818" }}
                >
                  {communityDetails?.description}
                </p>
                <button
                  className={`flex text-sm font-normal w-full p-4 text-white
                  ${type === "member"
                      ? "items-center justify-center space-x-2 font-semibold "
                      : "absolute bottom-0 items-center "
                    }
                  ${showZoomLink ? "activeState" : ""}
                  `}
                  style={{
                    background: "#008DFF",
                    borderRadius: "7px",
                  }}
                  disabled={type === "member" && !showZoomLink}
                  onClick={() => {
                    if (showZoomLink) {
                      navigator.clipboard.writeText(
                        communityDetails?.zoomJoinLink
                      );
                      successToast("Link copied to clipboard");
                      setTimeout(() => {
                        toast.clearWaitingQueue();
                      }, 2000);
                    }
                  }}
                >
                  <RcTooltip
                    content={
                      type === "member"
                        ? showZoomLink
                          ? communityDetails?.zoomJoinLink
                          : ""
                        : communityDetails?.zoomJoinLink
                    }
                  >
                    <span
                      className={`oneLineContent text-left ${showZoomLink ? "hover:underline hover:font-bold" : ""
                        } `}
                    >
                      {type === "member"
                        ? showZoomLink
                          ? "Zoom Link"
                          : "This Community event has ended"
                        : communityDetails?.zoomJoinLink}
                    </span>
                  </RcTooltip>

                  {showZoomLink && <CopyIcon />}
                </button>
              </div>
            </div>
          </>
        ) : (
          <Scaleloader />
        )}
      </div>
    </div>
  );
};

export default SingleCommunityDetailsPage;
