import {
  SUBMIT_MEMBER_REGISTRATION_FORM_BEGIN,
  SUBMIT_MEMBER_REGISTRATION_FORM_SUCCESS,
  SUBMIT_MEMBER_REGISTRATION_FORM_FAILURE,
  RESET_SUBMIT_MEMBER_REGISTRATION_FORM,
  RESEND_USER_REGISTER_OTP_BEGIN,
  RESEND_USER_REGISTER_OTP_SUCCESS,
  RESEND_USER_REGISTER_OTP_FAILURE,
  RESET_RESEND_USER_REGISTER_OTP,
  VERIFY_USER_REGISTER_OTP_BEGIN,
  VERIFY_USER_REGISTER_OTP_SUCCESS,
  VERIFY_USER_REGISTER_OTP_FAILURE,
  RESET_VERIFY_USER_REGISTER_OTP,
  CHECK_USER_REGISTER_EMAIL_BEGIN,
  CHECK_USER_REGISTER_EMAIL_SUCCESS,
  CHECK_USER_REGISTER_EMAIL_FAILURE,
  RESET_CHECK_USER_REGISTER_EMAIL,
} from "../constants/MemberRegistration";

import { httpApp } from "../../axios/httpUser";

//  submit member registration form
export function submitMemberRegistrationForm(data) {
  return (dispatch) => {
    dispatch({
      type: SUBMIT_MEMBER_REGISTRATION_FORM_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/signup/member`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: SUBMIT_MEMBER_REGISTRATION_FORM_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_REGISTRATION_FORM,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: SUBMIT_MEMBER_REGISTRATION_FORM_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_SUBMIT_MEMBER_REGISTRATION_FORM,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * resend otp
 */
export function resendMemberRegistrationOtp(email) {
  return (dispatch) => {
    dispatch({
      type: RESEND_USER_REGISTER_OTP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/otp/send`, { email });

      doRequest.then(
        (res) => {
          dispatch({
            type: RESEND_USER_REGISTER_OTP_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_RESEND_USER_REGISTER_OTP,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: RESEND_USER_REGISTER_OTP_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_RESEND_USER_REGISTER_OTP,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * Verify otp
 */
export function verifyUserRegistrationOtp(data) {
  return (dispatch) => {
    dispatch({
      type: VERIFY_USER_REGISTER_OTP_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/otp/verify`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: VERIFY_USER_REGISTER_OTP_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_VERIFY_USER_REGISTER_OTP,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: VERIFY_USER_REGISTER_OTP_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_VERIFY_USER_REGISTER_OTP,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

/**
 * Check email existance
 */

export function checkUserEmail(data) {
  return (dispatch) => {
    dispatch({
      type: CHECK_USER_REGISTER_EMAIL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let doRequest = httpApp.post(`/auth/check/email`, data);

      doRequest.then(
        (res) => {
          dispatch({
            type: CHECK_USER_REGISTER_EMAIL_SUCCESS,
            data: res.data,
          });
          dispatch({
            type: RESET_CHECK_USER_REGISTER_EMAIL,
          });

          resolve(res);
        },
        (err) => {
          dispatch({
            type: CHECK_USER_REGISTER_EMAIL_FAILURE,
            data: err.response.data,
          });
          dispatch({
            type: RESET_CHECK_USER_REGISTER_EMAIL,
          });

          reject(err);
        }
      );
    });

    return promise;
  };
}

// Show pop up
export function Popup(type) {
  return (dispatch) => {
    dispatch({
      type,
    });
  };
}
