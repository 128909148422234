import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from 'universal-cookie';
import SeoData from "../../components/utilities/seo/seoData.json";
import { getMemberDashboardDetails } from "../../AppRedux/actions/MemberDashboard";
import SubNavbar from "../../components/pageComponents/memberDashboard/memberProfile/SubNavbar";
import MemberLeftSideProfileCard from "../../components/pageComponents/memberDashboard/MemberLeftSideProfileCard";
import Navbar from "../../components/pageComponents/memberDashboard/memberProfile/Navbar";
import ProfileInfo from "../../components/pageComponents/memberDashboard/memberProfile/ProfileInfo";
import ManagePasswordTab from "../../components/pageComponents/memberDashboard/memberProfile/ManagePasswordTab";
import { CalendarBackArrow } from "../../components/pageComponents/MyCalendarGeneralItems";
import { ReactHelmet, Scaleloader } from "../../components/utilities";
import MemberDashboardLayout from "./MemberDashboardLayout";
import ReferralTab from "../../components/pageComponents/memberDashboard/memberProfile/ReferralTab";

const MemberProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const tab = params.getAll("tab").toString();
  const [dashboardData, setDashboardData] = useState();

  const {
    getMemberDashboardDetailsBegin,
    getMemberDashboardDetailsSuccessData,
  } = useSelector(({ memberDashboard }) => memberDashboard);

  useEffect(() => {
    dispatch(getMemberDashboardDetails());
  }, [dispatch]);

  useEffect(() => {
    if (getMemberDashboardDetailsSuccessData) {
      setDashboardData(getMemberDashboardDetailsSuccessData?.data);
    }
  }, [getMemberDashboardDetailsSuccessData]);

  useEffect(() => {
    if (tab === "") {
      setParams({
        tab: "my-info",
      });
    }
    if (tab !== "") {
      if (tab !== "my-info" && tab !== "payment" && tab !== "manage-password" && tab !== "referrals") {
        setParams({
          tab: "my-info",
        });
      }
    }
  }, [tab, setParams]);

  return (
    <MemberDashboardLayout>
      <ReactHelmet data={SeoData.profile} />
      <div className="flex flex-col md:flex-row gap-6">
        <MemberLeftSideProfileCard />
        <div className="overflow-x-scroll 950px:overflow-x-auto relative bg-white rounded-10px shadow-memberDashboard w-full ">
          {!getMemberDashboardDetailsBegin && dashboardData ? (
            <div className=" md:min-w-600px">
              <div className="pt-4 pl-4">
                <CalendarBackArrow
                  onClick={() => navigate("/member/dashboard")}
                />
              </div>
              <Navbar selectedTab={tab} dashboardData={dashboardData} />
              {tab === "my-info" ? (
                <ProfileInfo />
              ) : tab === "payment" ? (
                <SubNavbar />
              ) : tab === "referrals" ? (<ReferralTab />) : (
                tab === "manage-password" && <ManagePasswordTab />
              )}
            </div>
          ) : (
            <Scaleloader />
          )}
        </div>
      </div>
    </MemberDashboardLayout>
  );
};

export default MemberProfile;
