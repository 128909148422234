import {
  GET_APP_STORIES_BEGIN,
  GET_SET_OF_STORIES_SUCCESS,
  GET_SET_OF_STORIES_FAILURE,
  RESET_APP_STORIES_DATA,
  GET_APP_RESOURCES_BEGIN,
  GET_APP_RESOURCES_SUCCESS,
  GET_APP_RESOURCES_FAILURE,
  RESET_GET_APP_RESOURCES,
  GET_COUNTRIES_RESOURCES_BEGIN,
  GET_COUNTRIES_RESOURCES_SUCCESS,
  GET_COUNTRIES_RESOURCES_FAILURE,
  RESET_GET_COUNTRIES_RESOURCES,
  GET_STATES_BEGIN,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
  RESET_GET_STATES,
} from "../constants/Home";

const initialState = {
  getAppStoriesDataBegin: false,
  getSetOfStoriesSuccess: false,
  getSetOfStoriesSuccessData: [],
  getSetOfStoriesFailure: false,
  getSetOfStoriesFailureData: null,

  getAppResourcesBegin: false,
  getAppResourcesSuccess: false,
  getAppResourcesSuccessData: [],
  getAppResourcesFailure: false,
  getAppResourcesFailureData: null,

  getCountriesResourcesBegin: false,
  getCountriesResourcesSuccess: false,
  getCountriesResourcesSuccessData: [],
  getCountriesResourcesFailure: false,
  getCountriesResourcesFailureData: null,

  getStatesBegin: false,
  getStatesSuccess: false,
  getStatesSuccessData: null,
  getStatesFailure: false,
  getStatesFailureData: null,
};

export const homeReducer = (state = initialState, action) => {
  const data = action.data;

  switch (action.type) {
    case GET_APP_STORIES_BEGIN:
      return {
        ...state,
        getAppStoriesDataBegin: true,
        getSetOfStoriesSuccess: false,
        getSetOfStoriesSuccessData: [],
        getSetOfStoriesFailure: false,
        getSetOfStoriesFailureData: null,
      };
    case GET_SET_OF_STORIES_SUCCESS:
      return {
        ...state,
        getAppStoriesDataBegin: false,
        getSetOfStoriesSuccess: true,
        getSetOfStoriesSuccessData: data,
        getSetOfStoriesFailure: false,
        getSetOfStoriesFailureData: null,
      };
    case GET_SET_OF_STORIES_FAILURE:
      return {
        ...state,
        getAppStoriesDataBegin: false,
        getSetOfStoriesSuccess: false,
        getSetOfStoriesSuccessData: [],
        getSetOfStoriesFailure: true,
        getSetOfStoriesFailureData: data,
      };
    case RESET_APP_STORIES_DATA:
      return {
        ...state,
        getAppStoriesDataBegin: false,
        getSetOfStoriesSuccess: false,
        getSetOfStoriesFailure: false,
      };

    // Get app resources
    case GET_APP_RESOURCES_BEGIN:
      return {
        ...state,
        getAppResourcesBegin: true,
        getAppResourcesSuccess: false,
        getAppResourcesSuccessData: [],
        getAppResourcesFailure: false,
        getAppResourcesFailureData: null,
      };
    case GET_APP_RESOURCES_SUCCESS:
      return {
        ...state,
        getAppResourcesBegin: false,
        getAppResourcesSuccess: true,
        getAppResourcesSuccessData: data,
        getAppResourcesFailure: false,
        getAppResourcesFailureData: null,
      };
    case GET_APP_RESOURCES_FAILURE:
      return {
        ...state,
        getAppResourcesBegin: false,
        getAppResourcesSuccess: false,
        getAppResourcesSuccessData: [],
        getAppResourcesFailure: true,
        getAppResourcesFailureData: data,
      };
    case RESET_GET_APP_RESOURCES:
      return {
        ...state,
        getAppResourcesBegin: false,
        getAppResourcesSuccess: false,
        getAppResourcesFailure: false,
      };

    //get countries list

    case GET_COUNTRIES_RESOURCES_BEGIN:
      return {
        ...state,
        getCountriesResourcesBegin: true,
        getCountriesResourcesSuccess: false,
        getCountriesResourcesSuccessData: null,
        getCountriesResourcesFailure: false,
        getCountriesResourcesFailureData: null,
      };

    case GET_COUNTRIES_RESOURCES_SUCCESS:
      return {
        ...state,
        getCountriesResourcesBegin: false,
        getCountriesResourcesSuccess: true,
        getCountriesResourcesSuccessData: data,
        getCountriesResourcesFailure: false,
        getCountriesResourcesFailureData: null,
      };

    case GET_COUNTRIES_RESOURCES_FAILURE:
      return {
        ...state,
        getCountriesResourcesBegin: false,
        getCountriesResourcesSuccess: false,
        getCountriesResourcesSuccessData: null,
        getCountriesResourcesFailure: true,
        getCountriesResourcesFailureData: data,
      };

    case RESET_GET_COUNTRIES_RESOURCES:
      return {
        ...state,
        getCountriesResourcesBegin: false,
        getCountriesResourcesSuccess: false,
        getCountriesResourcesFailure: false,
      };

    //get states list based on country code

    case GET_STATES_BEGIN:
      return {
        ...state,
        getStatesBegin: true,
        getStatesSuccess: false,
        getStatesSuccessData: null,
        getStatesFailure: false,
        getStatesFailureData: null,
      };

    case GET_STATES_SUCCESS:
      return {
        ...state,
        getStatesBegin: false,
        getStatesSuccess: true,
        getStatesSuccessData: data,
        getStatesFailure: false,
        getStatesFailureData: null,
      };

    case GET_STATES_FAILURE:
      return {
        ...state,
        getStatesBegin: false,
        getStatesSuccess: false,
        getStatesSuccessData: null,
        getStatesFailure: true,
        getStatesFailureData: data,
      };

    case RESET_GET_STATES:
      return {
        ...state,
        getStatesBegin: false,
        getStatesSuccess: false,
        getStatesSuccessData: null,
        getStatesFailure: false,
        getStatesFailureData: null,
      };

    default:
      return state;
  }
};
