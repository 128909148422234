import { Suspense, lazy, useMemo, useEffect } from "react";

import Navbar from "../components/pageComponents/Navbar";
import Footer from "../components/pageComponents/footer";
import HomeLandingSection from "../components/pageComponents/HomePageLanding";
import PlatformForChangeSection from "../components/pageComponents/PlatformForChangeSection";
import WhoWeSection from "../components/pageComponents/WhoWeSection";
import SeoData from "../components/utilities/seo/seoData.json";
import SubscriptionPlanCard from "../components/pageComponents/SubscriptionPlanCard";
import {
  ReactHelmet,
  ProgressLoader,
  progressLoaderFunction,
} from "../components/utilities";
// import InspiringStoriesSection from "../components/pageComponents/InspiringStoriesSection";
// import HowWeDoSection from "../components/pageComponents/HowWeDoSection";
// import ReinventYourSelfNote from "../components/pageComponents/ReinventYourSelfNote";

const HowWeDoSection = lazy(() =>
  import("../components/pageComponents/HowWeDoSection")
);
const InspiringStoriesSection = lazy(() =>
  import("../components/pageComponents/InspiringStoriesSection")
);

const Home = () => {
  useEffect(() => {
    progressLoaderFunction();
  });

  const Content = () =>
    useMemo(
      () => (
        <>
          <ReactHelmet data={SeoData.homePageSeoData} />
          <ProgressLoader />
          <Navbar whiteNavbar={false} fixedNavbar={true} />
          <HomeLandingSection />
          <PlatformForChangeSection />
          <WhoWeSection />
          <Suspense fallback={<ProgressLoader />}>
            <HowWeDoSection />
          </Suspense>
          <Suspense fallback={<ProgressLoader />}>
            <InspiringStoriesSection />
          </Suspense>
          <SubscriptionPlanCard />
          {/* <ReinventYourSelfNote /> */}
          <Footer />
        </>
      ),
      []
    );

  return <Content />;
};

export default Home;
