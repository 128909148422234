import React, { useState } from "react";
import Tooltip from "rc-tooltip";
import "../utilities/styles/toolTip.css";

export const CustomTooltip = (props) => {
  let timeout;
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, props.delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <div
      className="Tooltip-Wrapper"
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {props.children}
      {active && (
        <div
          title={props.content}
          className={`Tooltip-Tip ${props.direction || "top"} 
          ${props.content === "Facebook" ? "after:bg-facebookColor" : ""}
          ${props.content === "Instagram" ? "after:bg-instagramColor" : ""}
          ${
            props.content === "Twitter"
              ? "after:bg-twitterColor"
              : "after:bg-facebookColor"
          }
          }`}
        ></div>
      )}
    </div>
  );
};

export const RcTooltip = ({ content, children, checkLength = false }) => (
  <Tooltip
    placement="bottom"
    trigger={["hover"]}
    mouseEnterDelay="0.5"
    overlay={content}
    overlayStyle={{
      maxHeight: "90vh",
      overflowY: "auto",
      display: checkLength && content.length < 30 ? "none" : "visible",
    }}
  >
    {children}
  </Tooltip>
);


