import { useLocation } from "react-router-dom";
import { FullYellowSun, HalfLargeRegisterPageSun } from "../utilities/svg";

const LeftSideWelcomeSection = () => {
  const location = useLocation();

  return (
    <div
      className={`lg:w-1/2 flex flex-col items-center relative bg-brandBlue ${
        location.pathname === "/join" && "joinImage"
      }`}
    >
      {location.pathname !== "/join" ? (
        <>
          <HalfLargeRegisterPageSun className="w-52 sm:w-auto h-24 sm:h-40 lg:h-auto" />
          <h1 className="text-xl 370px:text-3xl sm:text-4xl xl:text-4xl font-bold uppercase my-2 lg:my-5 text-white ">
            Welcome To Merity
          </h1>
          <FullYellowSun className="mb-4 lg:mb-0" />
        </>
      ) : (
        <div className="w-96%  xl:w-11/12 2xl:w-4/5 text-center flexColCenter z-10 mt-8 ">
          <h1 className="text-xl 370px:text-2xl sm:text-2xl xl:text-2xl font-bold uppercase my-2 lg:my-5 text-white ">
            Your MERITY MEMBERSHIP <br /> GIVES YOU THE TOOLS TO CREATE <br />{" "}
            POSITIVE CHANGE IN YOUR LIFE.
          </h1>

          <div className="text-left my-8">
            <div className="text-start">
              <h1 className="text-xl text-amber-400">
                Affordable and fully vetted and certified Coaches
              </h1>
              <p className="text-xl text-white text-left">
                Merity Coaches provide accountability and will work with <br className="hidden lg:block" />{" "}
                you to dramatically improve outcomes
              </p>
            </div>
            <div className="text-start mt-8">
              <h1 className="text-xl text-amber-400">
                Personalized,thought provoking program
              </h1>
              <p className="text-xl text-white text-left">
                Merity's proprietary program provides the structure that <br className="hidden lg:block"/>{" "}
                will enable you to create a powerful new vision
              </p>
            </div>
            <div className="text-start mt-8">
              <h1 className="text-xl text-amber-400">
                A community of like minded women
              </h1>
              <p className="text-xl text-white text-left">
                The Merity community provides the opportunity to connect <br className="hidden lg:block" />{" "}
                with other members to share ideas, celebrate each other's 
                <br className="hidden lg:block"/>
                 &nbsp;progress and support each other through challenges
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftSideWelcomeSection;
